import { Button } from "@material-ui/core";
import { useRef, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import AppD3D from "./AppD3D";
import useWindowSize from "./hooks/useWindowSize";
import { setEnteredSite } from "./redux/user/user.actions";

const LandingHeader = () => {
  return (
    <></>
    // <header className="header4">
    //   <div className="container">
    //     <div className="row">
    //       <div className="navbar-header col-md-2">
    //         <button
    //           type="button"
    //           className="navbar-toggle collapsed"
    //           data-toggle="collapse"
    //           data-target="#navbar"
    //           aria-expanded="false"
    //           aria-controls="navbar"
    //         >
    //           <span className="sr-only"></span>
    //           <span className="icon-bar"></span>
    //           <span className="icon-bar"></span>
    //           <span className="icon-bar"></span>
    //         </button>
    //         {/* <h1 className="logo">
    //           <a href="index.html" style={{ whiteSpace: "nowrap" }}>
    //             <img
    //               src="landing/logo.png"
    //               alt="KYC.Foundation"
    //               style={{
    //                 width: "36px",
    //                 height: "36px",
    //                 filter: "drop-shadow(0px 0px 2px #000000)",
    //                 marginRight: "6px",
    //               }}
    //             />
    //             <span
    //               style={{
    //                 fontSize: "18px",
    //                 color: "#ffffff",
    //                 filter: "drop-shadow(0px 0px 3px #000000)",
    //               }}
    //             >
    //               <b style={{ fontSize: "24px" }}>KYC</b>.Foundation
    //             </span>
    //           </a>
    //         </h1> */}
    //       </div>

    //       {/* <div id="navbar" className="navbar-collapse collapse col-md-10">
    //         <div className="menu nav navbar-nav pull-right nav-effect nav-menu">
    //           <div className="menu-one-page-container">
    //             <ul id="menu-one-page" className="menu">
    //               <li className="menu-item menu-item-type-custom menu-item-object-custom">
    //                 <a href="#to_initial">Home</a>
    //               </li>
    //               <li className="menu-item menu-item-type-custom menu-item-object-custom">
    //                 <a href="#to_products">Blockchain</a>
    //               </li>
    //               <li className="menu-item menu-item-type-custom menu-item-object-custom">
    //                 <a href="#to_team">Team</a>
    //               </li>

    //               <li className="to_whitepaper menu-item menu-item-type-custom menu-item-object-custom">
    //                 <a
    //                   data-target="_blank"
    //                   data-href="../Whitepaper.pdf"
    //                   href="#"
    //                 >
    //                   Enter site
    //                 </a>
    //               </li>
    //             </ul>
    //           </div>
    //         </div>
    //       </div> */}
    //     </div>
    //   </div>
    // </header>
  );
};

const LandingScreenOne = () => {
  // <!-- Section1 - KYC.Foundation One Page -->

  return (
    <>
      <div
        id="to_initial"
        className="padding_top-80 cryptic_one_page data_background"
        data-background="assets/images/background-2.png"
      >
        <div>
          <div className="padding_80 text-center">
            <div className="container">
              <div className="spacer_80"></div>
              <div
                className="row vc_row-has-fill p-10"
                style={{ alignItems: "center" }}
              >
                <div className="col-sm-9 col-md-6">
                  <h2 className="wow fadeInDown wpb_start_animation">
                    Know Your Contract
                  </h2>
                  <p
                    className="wow fadeInDown wpb_start_animation"
                    style={{ fontSize: "15px" }}
                  >
                    We are serving freshly baked contracts right from the oven,
                    and help you choose the yummy ones.
                  </p>
                  <b>Early bird phase is active.</b>
                  <br/>

                  <div className="spacer_30"></div>
                  <div className="text-right bitwallet-btn modeltheme_button wow fadeInLeft wpb_start_animation"></div>
                </div>
                <div className="col-sm-6" style={{ alignItems: "center" }}>
                  <div className="vc_column-inner">
                    <Link to="new">
                      <Button
                        className="btn btn-primary"
                        style={{
                          fontSize: "16px",
                          backgroundColor: "rgb(140, 212, 245)",
                          margin: "4px",
                          float:"right",
                        }}
                      >
                        <div
                          style={{
                            
                            fontSize: "16px",
                            display: "block",
                            padding: "4px",
                          }}
                        >
                          Enter
                          <br />
                          KYC
                          <br />
                          Foundation
                        </div>
                      </Button>
                    </Link>
                  </div>
                </div>
                <div className="spacer_80"></div>
                <div className="spacer_80"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const LandingScreenTwo = () => {
  //
  //
  //
  return (
    <div className="container">
      <div className="row vc_row-has-fill" style={{ alignItems: "center" }}>
        <div className="col-sm-6">
          <div
            className="title-subtile-holder wow fadeInLeft text_left box p-20"
            style={{ maxWidth: "500px" }}
          >
            <h2 className="section-title light_title no-margin">
              Goodies bought, scams caught
            </h2>
            <div className="spacer_10 mt-10"></div>
            <div className="clearfix"></div>
            <div className="section-border green_border no-margin"></div>
            <div className="spacer_10"></div>
            <div className="section-subtitle light_subtitle">
              We are developing a one-of-a-kind concept, that helps you catch
              those juicy unicorns, and avoid dangers on the way. <br />
              <br />
              With our advanced decision support system (DSS), you can discover
              smart contract creations, filter scams, and be among the first
              buyers of valid projects.
              <br />
              <br />
              <b>
                Our long-term goal is not less than making DeFi a better place,
                so stay tuned for updates!
              </b>
            </div>
          </div>
          <div className="spacer_40"></div>
          <div className="text-center bitwallet-btn modeltheme_button wow fadeInLeft"></div>
        </div>
        <div className="col-sm-6">
          <figure className="fadeInRight wow">
            <img
              width="520"
              height="415"
              src="landing/Vector-Smart-Object10.png"
              className="fadeInRight wow"
              alt="Vector-Smart-Object10"
            />
          </figure>
        </div>

        <div className="clearfix"></div>
        <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text wow fadeIn"></div>

        <div className="col-sm-6 vc_col-has-fill">
          <div className="wpb_single_image wpb_content_element vc_align_left wow fadeInLeft">
            <figure className="wpb_wrapper vc_figure">
              <div className="vc_single_image-wrapper vc_box_border_grey">
                <img
                  width="335"
                  height="450"
                  src="landing/Vector-Smart-Object9.png"
                  className="vc_single_image-img attachment-full"
                  alt="Vector-Smart-Object9"
                />
              </div>
            </figure>
          </div>
        </div>

        <div className="col-sm-6">
          <div
            className="title-subtile-holder wow fadeInRight text_left box p-20"
            style={{ maxWidth: "500px" }}
          >
            <h2 className="section-title light_title no-margin">
              Better rates with prepared trades
            </h2>
            <div className="spacer_10 mt-10"></div>
            <div className="clearfix"></div>
            <div className="section-border green_border no-margin"></div>
            <div className="spacer_10"></div>
            <div className="section-subtitle light_subtitle">
              Ever wondered, how people buy first at launches, making nice gains
              in minutes?
              <br />
              <br />
              Simple. They search for the contracts, analyze them, and get
              tokens at low prices.
              <br />
              <br />
              <b>
                We can help you get faster and increase your profits by being
                prepared.
              </b>
            </div>
          </div>
          {/* <div className="text-center bitwallet-btn modeltheme_button wow fadeInRight">
            <a
              className="button-winona btn btn-medium btn_custom_blue_grad"
              data-target="_blank"
              data-href="react.html"
              href="#"
            >
              Enter site
            </a>
          </div> */}
        </div>
        <div className="spacer_40"></div>
      </div>
    </div>
  );
};

const LandingPage = ({ setEnteredSite }: { setEnteredSite: any }) => {
  const windowSize = useWindowSize();
  //
  //
  //
  return (
    <section className="content">
      {/* <Button
            style={{ float: "right" }}
            onClick={() => {
              const a = prompt("Enter unlock code from Telegram.") || "";
              if (
                ["EARLYBIRD", "EB", "FOMO", "PLZ", "KYC"].includes(
                  a.toUpperCase()
                )
              ) {
                setEnteredSite();
              } else {
                alert("Come back sooner or later ;-)");
              }
            }}
          >
            Enter site
          </Button> */}
      {/* <!--                          --> */}
      {/* <LandingHeader /> */}
      <div>
        {/* <Button
          style={{ float: "right" }}
          onClick={() => {
            const a = prompt("Enter unlock code from Telegram.") || "";
            if (
              ["EARLYBIRD", "EB", "FOMO", "PLZ", "KYC"].includes(
                a.toUpperCase()
              )
            ) {
              setEnteredSite();
            } else {
              alert("Come back sooner or later ;-)");
            }
          }}
        >
          Enter site
        </Button> */}
        <div className="no-padding content-area no-sidebar" id="primary">
          <div
            className="container-fluid"
            style={{ maxWidth: "1200px", margin: "auto" }}
          >
            {/* {windowSize ? JSON.stringify(windowSize) : ""} */}
            <div
              className="row mt-4 box"
              style={{ maxWidth: "700px", textAlign: "center" }}
            >
              <LandingScreenOne />
            </div>
            <div className="row mt-4">
              <LandingScreenTwo />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  setEnteredSite: () => dispatch(setEnteredSite()),
});

export default connect(null, mapDispatchToProps)(LandingPage);

import genesis_json from "./config/genesis.json";
import setpair_json from "./config/setpair.json";
import create_pair_json from "./config/create_pair.json";
import add_liquidity_json from "./config/add_liquidity.json";

import KnownAddressesErc20Ethereum from "../ai/config/eth_erc20_top_1000_full_by_address.json";

import deployer_map_raw from "../ai/config/deployer_map_raw.json";
// addLiquidity
export type AbiEntry = {
  type: string;
  hex: string;
  name: string;
  signature: string;
  input_types: Array<string>;
  output_aliases: Array<string>;
};

export type AIConfigSchema = {
  DEPLOYERS: Array<{ deployer: string; contracts: Array<string> }>;
  IDS: {
    GENESIS: Array<string>;
    CREATE_PAIR: Array<string>;
    SET_PAIR: Array<string>;
    ADD_LIQ: Array<string>;
  };
  ABI: {
    GENESIS: Array<AbiEntry>;
    CREATE_PAIR: Array<AbiEntry>;
    SET_PAIR: Array<AbiEntry>;
    ADD_LIQ: Array<AbiEntry>;
  };
};

const L = console.log;

// const GENESIS = [] as Array<string>;
// const SET_PAIR = [] as Array<AbiEntry>;
//
//
const createEmptyGenesisAbiEntries = (ids: Array<string>) => {
  return ids.map((hex: string, idx: number) => ({
    type: "genesis",
    hex,
    name: `GENESIS Type #${idx}`,
    //signature: "hexlength+md5",
    signature: "popularity_percent_num_variants",
    input_types: [] as Array<string>, // contract deployers (addresses)
    output_aliases: [] as Array<string>, // contracts created (addresses)
  }));
};

export const loadNames = () => {
  /*
"idx":0,"rank":1,"hold":4304226,"symbol":"USDT","name":"Tether USD","nameAndSymbol":"Tether USD (USDT)","desc":"Tether gives you the joint benefits of open blockchain technology and traditional currency by converting your cash into a stable digital currency equivalent.","pUSD":1
    */
  const _keys = Object.keys(KnownAddressesErc20Ethereum) as Array<string>;
  const _vals = Object.values(KnownAddressesErc20Ethereum) as Array<any>;

  return {
    ADDR: {
      ERC20_TOP: _keys.map((k) => k.toLowerCase()),
    },
    META: {
      ERC20_TOP: _vals,
    },
  };
};
//
export const loadConfig = (): AIConfigSchema => {
  L("AI load config");
  L("GEN", genesis_json);
  /*
 {
    "type": "nonpayable",
    "hex": "0x80c581d1",
    "name": "setLpPair",
    "signature": "setLpPair(address,bool)",
    "input_types": ["address", "bool"],
    "output_aliases": ["to", "bool"]
  }
  */
  L("SET_PAIR", setpair_json);
  //
  const cfg = {
    DEPLOYERS: deployer_map_raw as Array<{
      deployer: string;
      contracts: Array<string>;
    }>,
    IDS: {
      GENESIS: genesis_json,
      CREATE_PAIR: create_pair_json.map((inp: AbiEntry) => inp.hex),
      SET_PAIR: setpair_json.map((inp: AbiEntry) => inp.hex),
      ADD_LIQ: add_liquidity_json.map((inp: AbiEntry) => inp.hex),
    },
    ABI: {
      GENESIS: createEmptyGenesisAbiEntries(genesis_json),
      CREATE_PAIR: create_pair_json,
      SET_PAIR: setpair_json,
      ADD_LIQ: add_liquidity_json,
    },
  };
  //
  return cfg;
};

export const method_colors = (
  interfaceID: string,
  repeated_idx: Array<number> = [1, 3, 5, 7] // seed 8 + 4 = 2x6
) => {
  const input = (interfaceID || "0x").replace("0x", "").toLowerCase().trim();
  const digits = input.split(""); // 8 digits
  while (digits.length < 8) digits.push("0"); // now for sure

  const repeated_digits = digits.filter((v, i) => repeated_idx.includes(i));
  //
  // first 6 digits: inner color
  // last 6 digits: outter color
  //
  digits.push(...repeated_digits);
  const s = digits.join("");
  //
  const hexas = [] as Array<string>; // '123abc'  x2
  for (let i = 0; i < digits.length; i += 6) hexas.push(s.substr(i, 6));
  const colors = hexas.map((h) => `#${h}`);
  //
  return colors;
};

// input:0x6eDE5004a408011C76605F9bf3FB289D7E398F74
export const account_colors = (
  account: string,
  repeated_idx: Array<number> = [3, 5, 8, 13, 17, 21, 27, 35] // seed 40 + 8 = 8x6
) => {
  const input = account.replace("0x", "").toLowerCase().trim();
  const digits = input.split(""); // 40 digits
  const repeated_digits = digits.filter((v, i) => repeated_idx.includes(i));
  digits.push(...repeated_digits);
  const s = digits.join("");
  //
  const hexas = [] as Array<string>; // '123abc'
  for (let i = 0; i < digits.length; i += 6) hexas.push(s.substr(i, 6));
  const colors = hexas.map((h) => `#${h}`);
  //
  return colors;
};

import React, { useState, useEffect, useRef } from "react";

const TextArea: React.FunctionComponent<{
  initialValue: string | undefined;
  filePath?: string | undefined;
  // onSuccess?: () => void
}> = ({ initialValue, filePath }) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const [value, setValue] = useState<string>(initialValue || ""); // The value of the textarea

  const textAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    if (textareaRef && textareaRef.current) {
      textareaRef.current.style.height = "0px";
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + "px";
    }
  }, [value]);

  return (
    <div style={styles.container}>
      <h3>{filePath ? filePath : "Raw source"}</h3>
      <textarea
        defaultValue={value}
        ref={textareaRef}
        style={styles.textareaDefaultStyle}
        onChange={textAreaChange}
      ></textarea>
    </div>
  );
};

export default TextArea;

// Just some styles
// Kindacode.com
const styles: { [name: string]: React.CSSProperties } = {
  container: {
    marginTop: 50,
    display: "flex",
    flexDirection: "column",

    alignItems: "flex-start",
  },
  textareaDefaultStyle: {
    padding: 5,
    width: "100%",
    display: "block",
    resize: "none",
    backgroundColor: "#eee",
  },
};

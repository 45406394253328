import { useState, useRef, useEffect, useCallback, useMemo } from "react";
import {
  useBlockMeta,
  useBlockNumber,
  useEthers,
  useGasPrice,
} from "@usedapp/core";
import { ethers } from "ethers";
import styled from "@emotion/styled";

import AppD3D from "../AppD3D";

import {
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  TextField,
} from "@material-ui/core";

import useWindowSize from "../hooks/useWindowSize";
import useUUID from "../hooks/useUUID";
import { useLocation, useParams } from "react-router-dom";
import useAbiLearner from "../hooks/useAbiLearner";
import useStripes from "../hooks/useStripes";

import { Label } from "../typography/Label";
import { TextInline } from "../typography/Text";
import { Title } from "../typography/Title";
import { SubTitle } from "../typography/SubTitle";

import {
  Container,
  ContentBlock,
  ContentRow,
  MainContent,
  Section,
  Button,
} from "../components/base";
import TextArea from "../components/TextArea";
import DataCanvas from "../components/DataCanvas";
import AntibotCodeComponent from "../components/AntibotCodeComponent";
import {
  _as_stripe,
  _as_multi_stripe,
  _as_interface_stripe,
  _as_genome,
  _as_multi_num,
  _as_simplified_genome,
  _as_smart_stripe, // TODO: move
} from "../components/SimpleControls";

import { _exclude_all_known, understandGenome } from "../utils/genomes";
import {
  // LexerResult, ERC721CheckResult,
  FirstBlockResult,
  TransactionsData,
  ContractSupportingERC721,
  ContractUrlResult,
} from "../types";
import { API_URL_ROOT, iconMapping, symbolMapping } from "../settings";
import BreakOutComponent from "../components/BreakOutComponent";
import useCoinGeckoPrices from "../hooks/useCoinGeckoPrices";
import useTransactionMonitor from "../hooks/useTransactionMonitor";
import ReactFC from "react-fusioncharts";
//
//
//
const L = console.log;

const api_base_url = API_URL_ROOT;

export function GasPriceDistributionChart(props: {
  envelope: Array<{ label: string; value: string }>;
  A: number;
  B: number;
  C: number;
}) {
  const { envelope, A, B, C } = props;
  //
  console.log("received BreakOutCalculatorChart props", envelope);
  //
  // Create a JSON object to store the chart configurations
  //

  const dataset = [0]; //  [...getDataset(fx_to_use)];
  console.log("dataset", dataset);
  //
  const chartConfigs = {
    type: "column3d", // The chart type
    width: "600", // Width of the chart
    height: "230", // Height of the chart
    dataFormat: "json", // Data type
    dataSource: {
      // Chart Configuration
      chart: {
        // caption: "Call times", //Set the chart caption
        xAxisName: "Transaction count with this price",
        yAxisName: "Price in gwei",
        lineThickness: "2",
        theme: "fusion", //Set the theme for your chart
      },
      data: envelope,
    },
  };
  //
  return <ReactFC {...chartConfigs} />;
}
//
//
//

const _as_fee_anchor = (
  key: string,
  url: string,
  is_buy: boolean,
  is_sell: boolean,
  is_total: boolean
): JSX.Element => {
  return (
    <div
      style={{
        width: "70px",
        height: "50px",
        // border: "solid 1px black",
        textAlign: "center",
        display: "inline-block",
        lineHeight: "16px",
        paddingTop: "10px",
        margin: "4px",
        overflow: "hidden",
      }}
    >
      <span style={{ maxWidth: "40px" }}>
        <span
          style={{
            width: "32px",
            height: "32px",
            fontSize: "16px",
            paddingLeft: "6px",
            paddingRight: "6px",
            fontWeight: is_total ? "bold" : "normal",
            color: is_buy ? "green" : is_sell ? "red" : "black",
            backgroundColor: is_total
              ? is_sell
                ? "rgba(255,30,30,0.15)"
                : "rgba(5,255,10,0.15)"
              : "none",
          }}
        >
          {url}
        </span>
        <div style={{ fontSize: "11px", marginTop: "2px" }}>{key}</div>
      </span>
    </div>
  );
};

const render_fees_as_icons = (
  fees: Array<{ value: string; label: string }>,
  props: {
    invested: number;
    setInvested: React.Dispatch<React.SetStateAction<number>>;
    riseMulti: number;
    setRiseMulti: React.Dispatch<React.SetStateAction<number>>;
    gasUsed: number;
    setGasUsed: React.Dispatch<React.SetStateAction<number>>;
    ABC:
      | {
          A: number;
          B: number;
          C: number;
        }
      | undefined;
    setABC: React.Dispatch<
      React.SetStateAction<
        | {
            A: number;
            B: number;
            C: number;
          }
        | undefined
      >
    >;
    thePriceOfEth: number;
    thePriceOfGas: number;
  }
) => {
  const s1 = Array.from(fees);
  //
  const {
    invested,
    setInvested,
    riseMulti,
    setRiseMulti,
    gasUsed,
    setGasUsed,
    ABC,
    setABC,
    thePriceOfEth,
    thePriceOfGas,
  } = props;

  //
  // buyFee: {"liquidity":"2","marketing":"10","dev":"0","donation":"0"}
  // sellFee: {"liquidity":"0","marketing":"55","dev":"45","donation":"0"}
  //
  // expanding objects  from [object]: {a,b,c}  =>  objectTotal and object_a,object_b,object_c
  //
  const to_add = [];
  const to_del = [] as Array<string>;
  for (const { value, label } of s1) {
    //  uint256 royaltyPercentageAmount = SafeMath.div(SafeMath.mul(salePrice, royaltyFeeBps), 10000);
    let VAL = value;
    if (label === "royaltyFeeBps") {
      const prc = 1 * parseInt(VAL) * 0.0001; // 1000 becomes 0.1%
      //
      VAL = String(prc);
      to_add.push({ value: String(prc), label }); // creating children for MODIFIED entry
      to_del.push(label); // settings modified item for deletion
    }
    //
    if (value.length === 4) {
      // masterTaxDivisor   uint256 feeAmount = amount * currentFee / masterTaxDivisor;
      //const masterTaxDivisor = 10000;
      //
      const prc = 1 * parseInt(VAL) * 0.0001; // 1000 becomes 0.1%
      //
      //VAL = String(prc);
      VAL = prc.toFixed(2);
      to_add.push({ value: prc.toFixed(2), label }); // creating children for MODIFIED entry
      to_del.push(label); // settings modified item for deletion
    }
    //
    console.log("S1", VAL, label);
    const is_boolean = typeof VAL === "boolean";
    const is_object = !is_boolean && VAL.startsWith("{") && VAL.endsWith("}");
    //
    if (is_object) {
      const parsed = JSON.parse(VAL);
      //
      const new_entries = Object.entries(parsed);
      //
      let total = 0;
      for (const [sub_key, sub_val] of new_entries) {
        //
        // adding key to original
        //
        const prop_name = `${label}${sub_key}`;
        const prop_value = sub_val; // TODO: validate for numeric type
        //
        to_add.push({ value: prop_value as string, label: prop_name }); // creating children
        total += parseInt(String(prop_value));
      }
      //
      const prop_name_total = `${label}Total`;
      to_add.push({ value: String(total), label: prop_name_total }); // creating children for TOTAL entry
      //
      to_del.push(label); // settings parent for deletion
    }
    //
    if (is_boolean) {
      // to_add.push({ value: value ? 'TRUE' : 'FALSE', label: 'BOOL_FIX' }); // not sure we need these
      to_del.push(label); // settings invalid key for deletion
      console.log("DELETING invalid entry", VAL, label);
    }
  }
  //
  const after_remove = s1.filter((fee) => !to_del.includes(fee.label));

  const NEXT_FEES = after_remove
    .concat(...to_add)
    .filter(
      ({ value, label }: { value: string; label: string }) => value.length < 6
    ); //NOTE: pre-filtering was need to move here, to enable tech-group calculation
  //
  // inserting technical totals if they are not present (B,remains,R)
  //
  const has_group_total = (arr: Array<{ value: string; label: string }>) =>
    arr.find(
      ({ value, label }: { value: string; label: string }) =>
        label.indexOf("otal") !== -1
    ) !== undefined;
  //
  const calc_total = () => 0;
  //
  if (NEXT_FEES.length > 0) {
    if (!has_group_total(NEXT_FEES)) {
      //
      // no total at all, inserting 1 /1 / 1  or   1/ all / 1
      //
      const has_fees = NEXT_FEES.length !== 0;
      //
      if (!has_fees) {
        NEXT_FEES.push(
          ...[
            { value: "0", label: " B. Total" },
            { value: "0", label: " O. Total" },
            { value: "0", label: " S. Total" },
          ]
        );
      } else {
        const sum_other = NEXT_FEES.filter(
          ({ value, label }) =>
            label.indexOf("buy") !== 0 &&
            label.indexOf("_buy") !== 0 &&
            label.indexOf("sell") !== 0 &&
            label.indexOf("_sell") !== 0
        )
          .map((f) => parseFloat(f.value) || 0)
          .reduce((a, b) => a + b, 0);
        //
        const sum_buy = NEXT_FEES.filter(
          ({ value, label }) =>
            label.indexOf("buy") === 0 || label.indexOf("_buy") === 0
        )
          .map((f) => parseFloat(f.value) || 0)
          .reduce((a, b) => a + b, 0);
        //
        const sum_sell = NEXT_FEES.filter(
          ({ value, label }) =>
            label.indexOf("sell") === 0 ||
            label.indexOf("_sell") === 0 ||
            label.indexOf("_sale") === 0
        )
          .map((f) => parseFloat(f.value) || 0)
          .reduce((a, b) => a + b, 0);
        //
        NEXT_FEES.push(
          ...[
            { value: String(sum_buy), label: " B. Total" },
            { value: String(sum_other), label: " O. Total" },
            { value: String(sum_sell), label: " S. Total" },
          ]
        );
      }
      console.log("HAS TOTAL NOW", NEXT_FEES);
    } else {
      console.log("ALREADY HAS TOTAL");
    }
  } else {
    console.log("HAS NO FEES AT ALL");
    NEXT_FEES.push(
      ...[
        { value: "0", label: " B. Total" },
        { value: "0", label: " O. Total" },
        { value: "0", label: " S. Total" },
      ]
    );
  }

  const the_fees = NEXT_FEES; // kicks off calculation
  //
  // custom sort, buy first, total last in groups
  //
  the_fees.sort((a: any, b: any) => a.label.localeCompare(b.label));
  //
  //
  const s2 = the_fees
    .filter(
      ({ value, label }: { value: string; label: string }) => value.length < 6
    )
    .map(({ value, label }: { value: string; label: string }) => [
      label,
      value,
      label.indexOf(" B. ") === 0 ||
        label.indexOf("buy") === 0 ||
        label.indexOf("_buy") === 0, // is_buy
      label.indexOf(" S. ") === 0 ||
        label.indexOf("sell") === 0 ||
        label.indexOf("_sell") === 0 || // is_sell
        label.indexOf("_sale") === 0,
      label.indexOf(" B. ") === 0 ||
        label.indexOf("O. ") === 0 ||
        label.indexOf("S. ") === 0 ||
        label.indexOf("otal") !== -1, // is_total
    ]);
  //
  //
  //
  const greens = s2.filter(
    ([key, url, is_buy, is_sell, is_total]: any) => is_buy
  );
  const reds = s2.filter(
    ([key, url, is_buy, is_sell, is_total]: any) => is_sell
  );
  const remains = s2.filter(
    ([key, url, is_buy, is_sell, is_total]: any) => !is_buy && !is_sell
  );
  //

  //
  const output = s2.map(([key, url, is_buy, is_sell, is_total]: any) =>
    _as_fee_anchor(key, url, is_buy, is_sell, is_total)
  );
  //
  const hasGreenTotal =
    greens.find(([key, url, is_buy, is_sell, is_total]: any) => is_total) !==
    undefined;
  const hasRemainsTotal =
    remains.find(([key, url, is_buy, is_sell, is_total]: any) => is_total) !==
    undefined;
  const hasRedTotal =
    reds.find(([key, url, is_buy, is_sell, is_total]: any) => is_total) !==
    undefined;
  //
  //
  //
  const hasBothTotals = false; //  hasGreenTotal && hasRedTotal;   break-even component input disabled intentionally
  //
  const A = !hasGreenTotal
    ? 1
    : greens
        .filter(([key, url, is_buy, is_sell, is_total]: any) => is_total)
        ?.map((x: any) => (100 - x[1]) * 0.01)[0];
  const B = !hasRemainsTotal
    ? 1
    : remains
        .filter(([key, url, is_buy, is_sell, is_total]: any) => is_total)
        ?.map((x: any) => (100 - x[1]) * 0.01)[0];
  const C = !hasRedTotal
    ? 1
    : reds
        .filter(([key, url, is_buy, is_sell, is_total]: any) => is_total)
        ?.map((x: any) => (100 - x[1]) * 0.01)[0];
  //
  //const invested = 0.1;

  // const riseMulti = 12.5;
  //
  if (fees.length) {
    // TODO: modify
    if (!ABC) {
      //       alert("SETTING" + JSON.stringify({ A, B, C }));
      setABC({ A, B, C });
    }
  }

  const D = invested * A * B * riseMulti * C;
  //
  //
  //
  // Transaction Fee: 0.005718341935712964 Ether ($16.50)
  // Gas Price: 0.000000122700668091 Ether (122.700668091 Gwei)
  // const approveCost = 122.7;
  const approveCost = thePriceOfGas;
  // const gasLimit = 55.92;
  const gasLimit = 55.92;
  //const gasUsed = 46.6;
  //
  //
  const cost_eth = approveCost * gasUsed * 0.000001;
  const cost_eth_limit = approveCost * gasLimit * 0.000001;
  //
  const price_eth = thePriceOfEth;
  //
  const cost_usd = cost_eth * price_eth;
  const cost_usd_limit = cost_eth_limit * price_eth;
  //
  //

  const total_spent = invested + 3 * cost_eth;
  const total_received = D;
  const _yield = total_received - total_spent;
  // const _yield_usd = _yield * price_eth;
  // const _yield_prc =
  //   _yield === 0
  //     ? 0
  //     : _yield < 0
  //     ? `-${((  100*(-_yield ) /total_spent )).toFixed(1)}%`
  //     : `+${((100 * _yield) / total_spent).toFixed(1)}%`;
  const _yield_prc =
    total_spent === 0 ? "0%" : `${((100 * _yield) / total_spent).toFixed(1)}%`;

  const is_loss = _yield < 0;

  // const total_spent = (invested + 3 * cost_eth) * price_eth;
  // const total_recived = D * price_eth;

  //
  //

  return (
    <div>
      {output.map((o, idx, arr) => (
        <>
          {o}
          {arr.length - 1 !== idx && " "}
        </>
      ))}
      <div style={{ display: "none" }}>
        {hasGreenTotal ? <b>Buy:</b> : <span>Buy:</span>}
        {greens.map((o, idx, arr) => (
          <>
            {o[1]}
            {arr.length - 1 !== idx && " "}
          </>
        ))}
        {" - "}
        {hasRemainsTotal ? <b>Other:</b> : <span>Other:</span>}
        {remains.map((o, idx, arr) => (
          <>
            {o[1]}
            {arr.length - 1 !== idx && " "}
          </>
        ))}
        {" - "}
        {hasRedTotal ? <b>Sell:</b> : <span>Sell:</span>}
        {reds.map((o, idx, arr) => (
          <>
            {o[1]}
            {arr.length - 1 !== idx && " "}
          </>
        ))}
        {hasBothTotals ? (
          <div style={{ fontSize: "10px" }}>
            Auto-Calculation possible.
            <br />
            <input
              type="text"
              style={{ width: "80px" }}
              value={invested}
              onChange={(e) => setInvested(parseFloat(e.target.value))}
            />
            Input: {invested.toFixed(5)} ETH
            <br />
            After buy: {invested.toFixed(5)} ETH * ( 1 - BUY_TAX ){A} ={" "}
            {(invested * A).toFixed(5)} ETH
            <br />
            After other: {invested.toFixed(5)} ETH * ( 1 - BUY_TAX ){A} * ( 1 -
            DEF_TAX ){B} = {(invested * A * B).toFixed(5)} ETH
            <br />
            Before sell: {invested.toFixed(5)} ETH * ( 1 - BUY_TAX ){A} * ( 1 -
            DEF_TAX ){B} * up {riseMulti}x ={" "}
            {(invested * A * B * riseMulti).toFixed(5)} ETH
            <br />
            After sell: {invested.toFixed(5)} ETH * ( 1 - BUY_TAX ){A} * ( 1 -
            DEF_TAX ){B} * up {riseMulti}x * ( 1 - SELL_TAX ){C} ={" "}
            {(invested * A * B * riseMulti * C).toFixed(5)} ETH
            <br />
            <b style={{ fontSize: "11px" }}>
              Magic EQ: inp * A * B * up * C = {invested.toFixed(5)} * {A} * {B}{" "}
              * {riseMulti}x * {C} = {D.toFixed(6)};
            </b>
          </div>
        ) : (
          ""
        )}
        <hr />
        <div>
          Gas: appr * gasUsed (gasLimit) = cost_eth = cost_usd
          <br />
          {approveCost} *{" "}
          <input
            type="text"
            style={{ width: "80px" }}
            value={gasUsed}
            onChange={(e) => setGasUsed(parseFloat(e.target.value))}
          />{" "}
          ( {gasLimit}) = {cost_eth.toFixed(5)} ( {cost_eth_limit.toFixed(5)})
          ETH = {cost_usd.toFixed(2)} ( {cost_usd_limit.toFixed(2)}) USD
        </div>
        <hr />
        Total, after{" "}
        <input
          type="text"
          style={{ width: "80px" }}
          value={riseMulti}
          onChange={(e) => setRiseMulti(parseFloat(e.target.value))}
        />
        x up and applying all taxes: <br />
        ETH price: {price_eth.toFixed(2)} USD
        <br />
        Invested: {invested.toFixed(5)} ETH |{" "}
        {(invested * price_eth).toFixed(2)} USD
        <br />
        Gas (x3): {(3 * cost_eth).toFixed(5)} ETH | {(3 * cost_usd).toFixed(2)}{" "}
        USD
        <br />
        Spent: (Invested + Gas(x3)) = ({(invested + 3 * cost_eth).toFixed(
          5
        )}{" "}
        ETH | {((invested + 3 * cost_eth) * price_eth).toFixed(2)} USD) <br />
        Returned: {D.toFixed(5)} ETH | {(D * price_eth).toFixed(2)} USD P/L:
        <br />
        Yield:{" "}
        <span
          style={{
            color: is_loss ? "red" : "blue",
          }}
        >
          {_yield.toFixed(5)} ETH | {(_yield * price_eth).toFixed(2)} USD |{" "}
          {_yield_prc}
        </span>
      </div>
    </div>
  );
};
//
//
//
const render_urls_as_icons = (urls: ContractUrlResult) => {
  const s2 =
    Object.entries(urls).filter(([key, val]) => val !== "" && key !== "RAW") ||
    [];
  //
  // ['twitter', 'https://twitter.com/McPepeETH']
  // ['web', 'https://McPepe.cash']
  //
  const displayMap: Record<string, string> = {
    web: "W",
    telegram: "TG",
    twitter: "TW",
    //
    discord: "DC",
    instagram: "IN",
    reddit: "RE",
    opensea: "OP",
  };
  //
  const _as_anchor = (key: string, url: string): JSX.Element => {
    const char = displayMap[key];

    return (
      <div
        style={{
          width: "60px",
          height: "50px",
          // border: "solid 1px black",
          textAlign: "center",
          display: "inline-block",
          overflow: "hidden",
        }}
      >
        <a href={url} target="_blank" title={url} style={{ maxWidth: "40px" }}>
          <img
            src={key === "web" ? `${url}/favicon.ico` : `/logo/${key}.png`}
            style={{ width: "32px", height: "32px" }}
            alt={url}
          />
          <div style={{ fontSize: "11px", marginTop: "-4px" }}>{key}</div>
        </a>
      </div>
    );
  };
  //
  const output = s2.map(([key, url]: any) => _as_anchor(key, url));
  //
  return (
    <div
      style={{
        display: "inline-block",
        maxWidth: "400px",
        float: "right",
      }}
    >
      {output.map((o, idx, arr) => (
        <>
          {o}
          {arr.length - 1 !== idx && " "}
        </>
      ))}
    </div>
  );
};
//
//
//
const render_understood_as_icons = (groups: Record<string, number>) => {
  const s2 = Object.entries(groups) || [];
  //Object.entries(urls).filter(([key, val]) => val !== "" && key !== "RAW") ||
  //
  // ['twitter', 'https://twitter.com/McPepeETH']
  // ['web', 'https://McPepe.cash']
  //
  const labelMap: Record<string, string> = {
    ...iconMapping,
  };
  const displayMap: Record<string, string> = {
    ...symbolMapping,
  };
  //
  const _as_anchor = (key: string, percent: number): JSX.Element => {
    const char = displayMap[key];
    const label = labelMap[key];

    const perc = percent === 1 ? "🟢" : `${(percent * 100).toFixed(1)}%`;
    // const perc =`${(percent * 100).toFixed(2)}%`;

    return (
      <div
        style={{
          width: "60px",
          height: "50px",
          // border: "solid 1px black",
          textAlign: "center",
          display: "inline-block",
        }}
      >
        <span style={{ maxWidth: "50px", textAlign: "center" }}>
          <span style={{ width: "32px", height: "32px", fontSize: "32px" }}>
            {char}
          </span>
          {/* <img
            src={`/logo/${key}.png`}
            style={{ width: "32px", height: "32px" }}
            alt={key}
          /> */}
          <div
            style={{
              fontSize: "10px",
              marginTop: "-4px",
              maxWidth: "50px",
              textAlign: "center",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {label}
            <br />
            <span
              style={{ position: "relative", top: "-4px", fontSize: "9px" }}
            >
              {perc}
            </span>
          </div>
        </span>
      </div>
    );
  };
  //
  const output = s2.map(([key, url]: any) => _as_anchor(key, url));
  //
  return (
    <div
      style={{
        display: "inline-block",
        maxWidth: "800px",
      }}
    >
      {output.map((o, idx, arr) => (
        <>
          {o}
          {arr.length - 1 !== idx && " "}
        </>
      ))}
    </div>
  );
};

const to_ago_from_utc = (utc_time: number) => {
  const now = new Date();
  const when = new Date(utc_time);
  const ms_elapsed = now.getTime() - when.getTime();
  const sec = Math.floor(ms_elapsed * 0.001);
  //
  let s: string;
  //
  if (sec < 60) s = `${sec} s`;
  else if (sec < 3600) s = `${(sec / 60.0).toFixed(0)} m`;
  else if (sec < 86400) s = `${(sec / 3600.0).toFixed(0)} h`;
  else s = `${(sec / 86400.0).toFixed(0)} d`;
  //
  return s;
};

const _as_datacanvas = (data_string: string, width: number) => (
  <DataCanvas width={width} height={300} data_string={data_string} />
);

const _as_formatted_amount = (str_amount: string, digits: number) => {
  const str = str_amount || "";
  const is_negative = str.length === 78;
  //
  let VAL = ethers.constants.MaxUint256; // 2^256 - num
  try {
    VAL = ethers.constants.MaxUint256.sub(ethers.BigNumber.from(str));
  } catch (ex) {
    VAL = ethers.constants.Two;
  }

  return (
    <>
      {is_negative ? (
        <>{VAL.toString()}</>
      ) : (
        <>
          {str.substring(0, str.length - digits)}.
          <small>{str.substring(str.length - digits)}</small>
        </>
      )}
    </>
  );
};

//
//
//
//
//
//
function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
//
export function Upload() {
  // const { address: addressReceived } = useParams<string>();

  let query = useQuery();
  // const addressFromQuery = query.get("address")
  const addressFromQuery = query.get("address") || "";
  const chainIdFromQuery = query.get("chain") || "1";
  const addressReceived = addressFromQuery; // hacked sweetly

  // if (addressFromQuery) {
  //   alert("address"+addressFromQuery);
  // }
  // const blockNumber = useBlockNumber();
  // const { chainId } = useEthers();
  const chainId = chainIdFromQuery ? parseInt(chainIdFromQuery) : 1;
  // const GP = useGasPrice();
  // L("GP________", (GP?.toNumber() || 0) * Math.pow(10, -9));
  // const { timestamp, difficulty } = useBlockMeta();
  //
  const { getEthereumPriceFromCoinGecko } = useCoinGeckoPrices();
  //
  //
  const threeCanvasRef = useRef<HTMLDivElement>(); // three_canvas
  const [is3dRunning, setIs3dRunning] = useState<boolean>(false);
  const [, setApp3d] = useState<AppD3D>();
  const windowSize = useWindowSize();
  //
  //
  const uuidTestResult = useUUID();
  const { learnSetters } = useAbiLearner();
  //
  //
  //
  const [hasUsedParam, setHasUsedParam] = useState<boolean>(); // TODO: check
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingTransaction, setIsLoadingTransaction] =
    useState<boolean>(false);
  const [value, setValue] = useState<string>(""); // currently selected random UUID (fingerprint)
  const [contractAddress, setContractAddress] = useState<string>(
    addressReceived || ""
  ); // ca
  //
  const [transactionsPageSubject, setTransactionsPageSubject] =
    useState<string>(contractAddress); // ca
  const [transactionPageSubjectOptions, setTransactionPageSubjectOptions] =
    useState<Array<{ value: string; label: string }>>([
      { value: contractAddress, label: "contract" },
    ]);
  //
  const [contractFees, setContractFees] = useState<
    Array<{ value: string; label: string }>
  >([]);
  const [contractUrls, setContractUrls] = useState<Record<string, any>>({});
  //
  const [transactionsPageDirection, setTransactionsPageDirection] =
    useState<string>("last"); // ca
  const [transactionPageDirectionOptions, setTransactionPageDirectionOptions] =
    useState<Array<string>>(["first", "last"]);
  const [transactionsPageSize, setTransactionsPageSize] = useState<number>(10); // ca
  const [transactionPageSizeOptions, setTransactionPageSizeOptions] = useState<
    Array<any>
  >([10, 50, 100, 250, 500, 1000, 2000, 10000]);
  const [transactions, setTransactions] = useState<TransactionsData>(); // ca
  //
  const [firstBlock, setFirstBlock] = useState<FirstBlockResult>(); // ca
  const [firstDeployerBlock, setFirstDeployerBlock] =
    useState<FirstBlockResult>(); // deployer (EOA)
  const [firstOwnerBlock, setFirstOwnerBlock] = useState<FirstBlockResult>(); // owner (EOA)
  const [contract, setContract] = useState<ContractSupportingERC721>(); // ca
  const [contractSourceRaw, setContractSourceRaw] = useState<string>(); // the downloaded source code, needs processing
  //
  const [isUser, setIsUser] = useState<boolean>();
  //
  //
  const [currentRandomTen, setCurrentRandomTen] = useState<Array<any>>();
  //
  // L("hasUsedParam", hasUsedParam, "isLoading", isLoading, addressReceived);
  //
  const [hideKnownInstanceMethods, setHideKnownInstanceMethods] =
    useState<boolean>(false);
  const [
    hideKnownInstanceMethodsGroupped,
    setHideKnownInstanceMethodsGroupped,
  ] = useState<boolean>(false);
  const [hideSourceCode, setHideSourceCode] = useState<boolean>(true);
  const [hideGasUsed, setHideGasUsed] = useState<boolean>(true);
  const [hideApproval, setHideApproval] = useState<boolean>(true);
  const [hideTransactionAddresses, setHideTransactionAddresses] =
    useState<boolean>(false);
  //
  //
  //
  //
  //
  const { watch, gasPrice, gasPriceDistribution } = useTransactionMonitor();
  //
  const [thePriceHeight, setThePriceHeight] = useState<number>(0);
  const [thePriceOfEth, setThePriceOfEth] = useState<number>(0);
  const [thePriceOfGas, setThePriceOfGas] = useState<number>(0);
  const [thePriceOfFeeBase, setThePriceOfFeeBase] = useState<number>(0);
  const [thePriceOfFeeMax, setThePriceOfFeeMax] = useState<number>(0);
  const [thePriceOfGasDistibution, setThePriceOfGasDistibution] = useState<{
    max: number;
    fee_base: number;
    fee_max: number;
    chart: Array<Array<number>>;
    L: number;
  }>({
    max: 0,
    fee_base: 0,
    fee_max: 0,
    chart: [] as Array<Array<number>>,
    L: 0,
  });
  //
  //
  const gasChartMemo = useMemo(() => {
    console.log("NEW ENVELOPE (gasChartMemo)");
    //
    return thePriceOfGasDistibution.chart
      .map((x: any, idx: number) => ({
        label: `${x[1]}`,
        value: `${x[0]}`, // displaying total
      }))
      .slice(
        Math.max(0, thePriceOfGasDistibution.chart.length - 48),
        thePriceOfGasDistibution.chart.length
      );
  }, [thePriceOfGasDistibution.max]);

  //
  // retrieving prices with getEthereumPriceFromCoinGecko, only once
  //
  /*
  useEffect(() => {
    if (blockNumber) {
      L("new tick prices", blockNumber);
      setThePriceHeight(blockNumber);
    }
    //
    return () => console.log("no effect removal for new price TICK");
  }, [blockNumber]);
  */
  //
  // retrieving prices with getEthereumPriceFromCoinGecko, only once
  //
  useEffect(() => {
    if (thePriceHeight > 0) {
      const _refresh_price = async () => {
        const prices = await getEthereumPriceFromCoinGecko();
        console.log("EFFECT prices", prices);
        //
        setThePriceOfEth(prices.eth || 0);
      };
      //
      _refresh_price();
    } else {
      L("TPH is zero");
    }
    //
    return () => console.log("no effect removal for ETH price retrieve");
  }, [thePriceHeight]);
  //
  //
  // https://api.blocknative.com/gasprices/blockprices
  // retrieving GAS prices from blocknative only once
  //
  /*
  useEffect(() => {
    if (thePriceHeight > 0) {
      const _refresh_gas_price = async () => {
        // const gas_price = Math.random() * 100;
        const gas_price = (await gasPrice()) || 0;

        console.log("EFFECT gas price", gas_price);
        //console.log("EFFECT will use", gasPrice);

        //
        setThePriceOfGas(gas_price || 0);
      };
      //
      _refresh_gas_price();
    } else {
      L("TPH is zero");
    }
    //
    return () => console.log("no effect removal for GAS price retrieve");
  }, [thePriceHeight]);
  */
  //
  //
  // https://api.blocknative.com/gasprices/blockprices
  // retrieving GAS prices from blocknative only once
  //
  useEffect(() => {
    if (thePriceHeight > 0) {
      const _refresh_gas_price_d = async () => {
        // const gas_price = Math.random() * 100;
        const gas_distribution = await gasPriceDistribution();

        console.log("EFFECT gas DISTRIBUTION", gas_distribution);
        //console.log("EFFECT will use", gasPrice);

        //
        setThePriceOfGasDistibution(gas_distribution);
        setThePriceOfGas(gas_distribution.max);
        setThePriceOfFeeBase(gas_distribution.fee_base);
        setThePriceOfFeeMax(gas_distribution.fee_max);
      };
      //
      _refresh_gas_price_d();
    } else {
      L("TPH is zero");
    }
    //
    return () => console.log("no effect removal for GAS DISTRIBUTION retrieve");
  }, [thePriceHeight]);

  //
  //
  //
  const filteredApprovals = useMemo(() => {
    //
    const res =
      transactions?.TXS.filter((a: any) =>
        hideApproval ? a.method_name !== "approve" : true
      ).sort((a: any, b: any) =>
        `${a.type}|${a.name}`.localeCompare(`${b.type}|${b.name}`)
      ) || [];
    L("recreated memo", transactions?.TXS.length, res.length, hideApproval);
    return res;
  }, [transactions?.TXS, hideApproval]);

  if (currentRandomTen === undefined) {
    setCurrentRandomTen(uuidTestResult["RND10"]);
    setValue(String(uuidTestResult["RND10"][0][0]));
  }

  //
  //
  //
  useEffect(() => {
    if (!is3dRunning) {
      if (threeCanvasRef.current) {
        // const _a3d = new AppD3D(threeCanvasRef.current);
        // // a3d.animate();
        // //THIS IS JUST A TEST, need to define correct width+height
        // // threeCanvasRef.current.style.width = `${windowSize.width}px`;
        // // threeCanvasRef.current.style.height = `${windowSize.height}px`;
        // threeCanvasRef.current.style.width = `100vw`;
        // threeCanvasRef.current.style.height = `100vh`;
        // threeCanvasRef.current.style.position = `fixed`;
        // threeCanvasRef.current.style.top = `10px`;
        // //threeCanvasRef.current.style.left = `0px`;
        // console.log("windowsize", windowSize);
        // setIs3dRunning(true);
        // setApp3d(_a3d);
      }
    }

    return () => console.log("removing 3d effect");
  }, [is3dRunning, windowSize]);

  //
  //
  //
  const checkContract = useCallback(async () => {
    if (!isLoading && chainId) {
      console.log("using ca", contractAddress);
      setIsLoading(true);
      const ca = contractAddress;

      const url = `${api_base_url}/contract/${ca}?chain=${chainId}`;
      L("fetching", chainId, url);
      setIsUser(undefined);
      setContract(undefined);
      setFirstBlock(undefined);
      setFirstOwnerBlock(undefined);
      setFirstDeployerBlock(undefined);
      setTransactions(undefined);

      const json = await fetch(url).then((res) => res.json());
      L("received", json);

      const c = json["value"] as ContractSupportingERC721;
      //
      const is_user = c.proxy === null && c.contract === null;
      //
      if (is_user) {
        //
        // process as a user
        //
        setIsUser(true);
        //
        const newAddresses = [{ value: c.address, label: "user" }];
        setTransactionPageSubjectOptions(newAddresses);
        setTransactionsPageSubject(c.address); // and also select the owner for the transactions target
        setHideApproval(false);
        //
      } else {
        //
        // process as a contract
        //
        setIsUser(false);
        setContract(c);
        //
        setContractSourceRaw(c.contract.src.SourceCode);
        //
        // and also updating targets of transactions
        //
        const newAddresses = [{ value: contractAddress, label: "contract" }];
        //
        if (c.contract.test.erc20.owner.address) {
          newAddresses.push({
            value: c.contract.test.erc20.owner.address,
            label: "owner",
          });
        }
        //
        //const cr = getErc20CallResultsTable();
        const cr = c.contract?.test?.erc20?.callResults || {};
        //
        const extraAddresses = Object.entries(cr)
          .filter(([key, value]) => isAddress(value as string))
          .map(([key, value]) => ({
            value: value as string,
            label: key.replace("()", ""),
          }));
        const extraAddressesDisplayed = extraAddresses.filter(
          (o: { label: string }) =>
            o.label !== "deadAddress" && o.label !== "owner"
        );

        if (extraAddressesDisplayed.length) {
          newAddresses.push(...extraAddressesDisplayed);
        }

        console.log("CC received cr", cr);
        console.log("addressEntries", extraAddresses);

        setTransactionPageSubjectOptions(newAddresses);
        //
        if (c.contract.test.erc20.owner.address) {
          setTransactionsPageSubject(c.contract.test.erc20.owner.address); // and also select the owner for the transactions target
        }
        //
        //
        const extraFees = Object.entries(cr)
          .filter(([key, value]) => key.toUpperCase().indexOf("FEE") !== -1)
          .map(([key, value]) => ({
            value: value as string,
            label: key.replace("()", ""),
          }));

        //
        setContractFees(extraFees);
        const urls = c.contract?.URLS || {};
        setContractUrls(urls);
      }
      ///
      //
      //
      setIsLoading(false);
    }
  }, [chainId, isLoading, contractAddress]);

  //
  //
  //
  const onSaveClicked = useCallback(checkContract, [
    chainId,
    contractAddress,
    isLoading,
    checkContract,
  ]);

  //
  // loading tx list
  //
  useEffect(() => {
    if (
      chainId &&
      transactionsPageSize &&
      transactionsPageSubject &&
      transactionsPageDirection
    ) {
      // const ca = contract.address;  && contract?.address

      setIsLoadingTransaction(true);
      L("tx_ca_change", transactionsPageSubject);
      // load the transactions
      const url2 = `${api_base_url}/transactions/${transactionsPageSubject}?chain=${chainId}&mode=${transactionsPageDirection}&max=${transactionsPageSize}`;
      L("fetching2", url2);
      fetch(url2)
        .then((res) => res.json())
        .then((json) => {
          L("received2", json);
          L("v2", json["value"]);
          const td = json["value"] as TransactionsData;
          L("setting", td);

          setTransactions(td);
          setIsLoadingTransaction(false);
        });
    }

    return () => console.log("no effect removal for address change");
  }, [
    chainId,
    // contract?.address,
    transactionsPageSize,
    transactionsPageSubject,
    transactionsPageDirection,
  ]);

  //
  // loading first block of contract
  //
  useEffect(() => {
    if (chainId && contract?.address) {
      const ca = contract.address;
      L("ca_change", ca);
      const url2 = `${api_base_url}/fb/${ca}?chain=${chainId}`; // load the transactions
      //
      L("fetching first block", url2);
      //
      fetch(url2)
        .then((res) => res.json())
        .then((json) => {
          L("received fb", json);
          L("fb", json["value"]);
          const td = json["value"] as FirstBlockResult;
          L("setting fb", td);
          //
          setFirstBlock(td);
        });
    }

    return () => console.log("no effect removal for first block change");
  }, [chainId, contract?.address]);

  //
  // loading first block of deployer
  //
  useEffect(() => {
    if (chainId && firstBlock) {
      const deployer = firstBlock.from;
      L("deployer_change", deployer);
      //const url2 = `${api_base_url}/transactions/${deployer}?mode=first&max=1`; // load the transactions
      const url2 = `${api_base_url}/fb/${deployer}?chain=${chainId}`; // load the transactions
      //
      L("fetching first block of deployer", url2);
      //
      fetch(url2)
        .then((res) => res.json())
        .then((json) => {
          L("received fdb", json);
          L("fdb", json["value"]);
          const td = json["value"] as FirstBlockResult;
          L("setting fdb", td);
          //
          setFirstDeployerBlock(td);
          //
          // also adding the deployer to the list of addresses
          //
          setTransactionPageSubjectOptions([
            ...transactionPageSubjectOptions,
            { value: deployer, label: "deployer" },
          ]);
        });
    }

    return () =>
      console.log("no effect removal for first block change of deployr");
  }, [chainId, firstBlock]);

  //
  // loading first block of owner
  //
  // useEffect(() => {
  //   if (chainId && contract?.contract?.test?.erc20?.owner?.address) {
  //     const owner = contract?.contract?.test.erc20.owner.address;
  //     L("owner_change", owner);
  //     const url2 = `${api_base_url}/fb/${owner}?chain=${chainId}`; // load the transactions
  //     //
  //     L("fetching first block of owner", url2);
  //     //
  //     fetch(url2)
  //       .then((res) => res.json())
  //       .then((json) => {
  //         L("received fob", json);
  //         L("fob", json["value"]);
  //         const td = json["value"] as FirstBlockResult;
  //         L("setting fob", td);
  //         //
  //         setFirstOwnerBlock(td);
  //       });
  //   }

  //   return () =>
  //     console.log("no effect removal for first block change of deployr");
  // }, [chainId, contract?.contract?.test?.erc20?.owner?.address]);

  //
  // loading first block of owner
  //
  // useEffect(() => {
  //   if (chainId && contract?.contract?.test?.erc20?.owner?.address) {
  // const owner = contract?.contract?.test.erc20.owner.address;
  // L("owner_change", owner);
  // const url2 = `${api_base_url}/transactions/${owner}?chain=${chainId}&mode=first&max=1`; // load the transactions
  // //
  // L("fetching first block of owner", url2);
  // //
  // fetch(url2)
  //   .then((res) => res.json())
  //   .then((json) => {
  //     L("received fob", json);
  //     L("fob", json["value"]);
  //     const td = json["value"] as TransactionsData;
  //     L("setting fob", td);
  //     //
  //     setFirstOwnerBlock(td);
  //   });
  //   }
  //   //
  //   return () =>
  //     console.log("no effect removal for first block change of owner");
  // }, [chainId, contract?.contract?.test?.erc20?.owner?.address]);

  //
  //
  //
  useEffect(() => {
    if (chainId) {
      // if (addressReceived) {
      if (contractAddress) {
        if (!hasUsedParam) {
          L("STARTING ADDRESS", contractAddress);
          setHasUsedParam(true);
          checkContract();
        }
      }
    }

    return () =>
      console.log("no effect removal for auto loading contract from url param");
    //}, [chainId, hasUsedParam, checkContract, contractAddress]);
  }, [chainId, hasUsedParam, contractAddress, checkContract]);

  L("'!!!!!!!!!!!!!!!!!!'", transactions?.TXS.length, "!!!!!!!!!!!!!!!!!!");

  const isBooleanKey = (key: string) =>
    key === "bool" || key.substring(0, 4) === "bool";
  const isAddress = (s: string) =>
    !s ? false : s.length === 42 && s.substring && s.substring(0, 2) === "0x";
  const isKeccak = (s: string) =>
    !s ? false : s.length === 66 && s.substring(0, 2) === "0x";
  const isEthereumAmount = (key: string, s: string) =>
    !s
      ? false
      : (key === "num" || key.substring(0, 3) === "num") && // todo:eth
        // key.toUpperCase().indexOf("PRICE") > -1 ||
        // key.toUpperCase().indexOf("COST") > -1
        16 < s.length &&
        s.length < 24 &&
        s.match(/[0-9]*/) !== null;

  const getErc20CallResultsTable = () => {
    if (!contract) return [];
    else {
      const o = contract.contract?.test?.erc20?.callResults || {};
      //
      // console.log("returning", Object.keys(o).length, "keys");
      //
      return Object.entries(o);
    }
  };

  const getOwnerModeDisplayName = (owner_mode: number): string => {
    let s = "No owner";
    //
    switch (owner_mode) {
      case 0:
        s = "No owner";
        break;
      case 1:
        s = "Not possible";
        break;
      case 2:
        s = "Renounced";
        break;
      case 3:
        s = "Owned";
        break;
    }
    //
    return s;
  };
  // const getErc20CallResultsRaw = () => {
  //   if (!contract) return "{}";
  //   else {
  //     const o = contract.contract?.test?.erc20?.callResults || {};
  //     //
  //     console.log("returning", Object.keys(o).length, "keys");
  //     //
  //     return JSON.stringify(o);
  //   }
  // };
  const getErc20CallResultValueOfSignature = (signature: string) => {
    if (!contract) return undefined;
    else {
      const o = contract.contract?.test?.erc20?.callResults || {};
      //
      // console.log("returning", Object.keys(o).length, "keys");
      //
      const found = o.hasOwnProperty(signature);
      //
      if (!found) return signature;
      else {
        const v = found ? o[signature] : undefined;
        //
        const bool_types: Array<string> = ["collateral_ratio_paused"];

        const output_types: Record<string, string> = {
          "owner()": "address",
          "totalSupply()": "amount",
          "maxTxnAmount()": "amount",
          "maxLimit()": "amount",
          "_maxWalletAmount()": "amount",
          "_minimumTokensBeforeSwapAmount()": "amount",
          "_walletHoldingMaxLimit()": "amount",
          "_swapFeesAt()": "amount",
          "symbol()": "string",
          "name()": "string",
          "launchedAt()": "string", // << -- TODO: blocknumber
          //
          // bool
          //
          "privateMintActive()": "bool",
          "publicMintActive()": "bool",
          "whitelistMintActive()": "bool",
          "saleEnabled()": "bool",
          "needsRebalance()": "bool",
          "isPut()": "bool",
          "preSaleIsActive()": "bool",
          "isMintOpen()": "bool",
          "restrictTransfers()": "bool",
          "transferLocked()": "bool",
          "useDynamicPresalePurchaseLimit()": "bool",
          "status_airdrop()": "bool",
          "status_paused()": "bool",
          "status_presale()": "bool",
          "status_revealed()": "bool",
          "whitelistSaleIsActive()": "bool",
          "canReveal()": "bool",
          "isPreSalePaused()": "bool",
          "isPaused()": "bool",
          "checkTransferInit()": "bool",
          "IS_SALE_ACTIVE()": "bool",
          "isRevealed()": "bool",
          "isGeneralSaleAllowed()": "bool",
          "isGeneralActive()": "bool",
          "isBinanceSaleAllowed()": "bool",
          "isBinanceActive()": "bool",
          "isClaim1Active()": "bool",
          "isClaim2Active()": "bool",
          "isClaim3Active()": "bool",
          "claim1SaleAllowed()": "bool",
          "claim2SaleAllowed()": "bool",
          "claim3SaleAllowed()": "bool",
          "tokensTransferable()": "bool",
          "tokensBurnable()": "bool",
          "mintEnabled()": "bool",
          "whitelistMintEnabled()": "bool",
          "saleOn()": "bool",
          "getTaxEnabled()": "bool",
          "saleOpen()": "bool",
          "maxWalletOn()": "bool",
          "canEmergencySweep()": "bool",
          "hasEmergencySweeped()": "bool",
          "safetyChecks()": "bool",
          "isFeeOnTransferEnabled()": "bool",
          "stakingPhaseEnabled()": "bool",
          "buybackEnabled()": "bool",
          "_antiwhale()": "bool",
          "checkWalletLimit()": "bool",
          "processFeesEnabled()": "bool",
          "direction()": "bool",
          "isTransferable()": "bool",
          "earlyBuyPenaltyInEffect()": "bool",
          "compoundingEnabled()": "bool",
          "taxEnabled()": "bool",
          "PAUSED()": "bool",
          "CONTINUE_MINTING()": "bool",
          "LiquidityAdded()": "bool",
          "cooldownStatus()": "bool",
          "MaxWalletEnabled()": "bool",
          "maxPerWalletEnabled()": "bool",
          "active()": "bool",
          "autoPayout()": "bool",
          "completed()": "bool",
          "presaleMint()": "bool",
          "swapAndRedirectEthFeesEnabled()": "bool",
          "useGenericTransfer()": "bool",
          "special()": "bool",
          "isClosed()": "bool",
          "getEnableTrading()": "bool",
          "_burnPause()": "bool",
          "_mintPause()": "bool",
          "_paused()": "bool",
          "blacklistEnabled()": "bool",
          "initialized()": "bool",
          "mintLive()": "bool",
          "inSwapAndLiquify()": "bool",
          "taxStatus()": "bool",
          "mainSale()": "bool",
          "airDropLimitInEffect()": "bool",
          "feesOnSellersAndBuyers()": "bool",
          "SwapAndSendEnabled()": "bool",
          "status()": "bool", // TODO: possibly collision subject
          "open()": "bool",
          "autoBuybackEnabled()": "bool",
          "publicMintStatus()": "bool",

          "presaleOpen()": "bool",
          "reservedTokensToOwnerMinted()": "bool",

          "liquidityRelease20Percent()": "bool",
          "swapping()": "bool",
          "buyBackLimit()": "bool",
          "buyBackEnabled()": "bool",
          "MAX_tx_on()": "bool",
          "tax_on()": "bool",
          "_taxEnabled()": "bool",
          "isFeeActive()": "bool",
          "auctionActive()": "bool",
          "se()": "bool", // 'when sell'
          "_useImpactFeeSetter()": "bool",
          "isTokenFrozen()": "bool",
          "saleIsActive()": "bool",
          "presaleOngoing()": "bool",
          "isMerkleSet()": "bool",
          "mintState()": "bool",
          "reservedClaimed()": "bool",
          "revealed()": "bool",
          "presaleStarted()": "bool",
          "publicSaleStarted()": "bool",
          "onlyWhitelisted()": "bool",
          "deadBlock()": "bool",
          "isLaunced()": "bool",
          "profitBaseFeeOn()": "bool",
          "buyingPriceOn()": "bool",
          "IndividualSellLimitOn()": "bool",

          "maxHoldingsEnabled()": "bool",
          "antiBotLaunch()": "bool",
          "antiSnipe()": "bool",
          "maxTXEnabled()": "bool",
          "tradingLive()": "bool",
          "_tradingOpen()": "bool",
          "tradingOpen()": "bool",
          "saleActive()": "bool",
          "presaleActive()": "bool",
          "zeroBuyTaxmode()": "bool",
          "_translimited()": "bool",
          "initialAddressSet()": "bool",
          "swapAndWithdrawEnabled()": "bool",

          "antiBotEnabled()": "bool",
          "isInPresale()": "bool",
          "takeFeeEnabled()": "bool",
          "tradingIsEnabled()": "bool",

          "tradingActive()": "bool",
          "transferDelayEnabled()": "bool",
          "limitsInEffect()": "bool",
          "swapEnabled()": "bool",
          "enableEarlySellTax()": "bool",
          "lpBurnEnabled()": "bool",
          "canTrade()": "bool",
          "swapAndLiquifyEnabled()": "bool",
          "checkEnabled()": "bool",
          "linkAvailableForPayment()": "bool",
          "saleState()": "bool",
          "tradingEnabled()": "bool",
          "isActive()": "bool",
          "isPresaleActive()": "bool",
          "reveal()": "bool",
          "publicSaleActive()": "bool",

          "isPresaleLive()": "bool",
          "isPublicSaleLive()": "bool",
          "presaleLive()": "bool",
          "saleLive()": "bool",
          "saleX2Live()": "bool",
          "trackingVotes()": "bool",
          "CEX()": "bool",
          "swapAndSendFeesEnabled()": "bool",
          "presale()": "bool",
          "vestStarted()": "bool",
          "isMerkleRootSet()": "bool",
          "isLaunched()": "bool",
          "contractSwapEnabled()": "bool",
          "isInCommunityStage()": "bool",
          "isLaunchFinished()": "bool",
          "openToPublic()": "bool",
          "publicSupplyAvailable()": "bool",
          "reserveSupplyAvailable()": "bool",
          "isSaleActive()": "bool",
          "isPaidMint()": "bool",
          "sale_active()": "bool",
          "mintingEnabled()": "bool",
          "isOpen()": "bool",
          "_limitonbuys()": "bool",
          "whitelistOnly()": "bool",
          "tradingEnable()": "bool",
          "getAutoBanBots()": "bool",
          "limitsEnabled()": "bool",
          "trading()": "bool",
          "routerselllimit()": "bool",
          "_antidump()": "bool",
          "_limitedtran()": "bool",
          "whitelisted()": "bool",
          "directDeposit()": "bool",
          "isFinished()": "bool",
          "guestTimeOn()": "bool",
          "restrictWhales()": "bool",
          "swapAndLiquifyByLimitOnly()": "bool",
          "boostTimeEnabled()": "bool",
          "processReflect()": "bool",
          "MINTING_ALLOWED()": "bool",
          "didLaunch()": "bool",
          "isTradingAllowed()": "bool",
          "presaleOn()": "bool",
          "claimActive()": "bool",
          "openToWhitelistSale()": "bool",
          "isPublicLive()": "bool",
          "isWhitelistLive()": "bool",
          "tradable()": "bool",
          "getSaleLocked()": "bool",
          "saleLocked()": "bool",
          "arweaveIDLocked()": "bool",
          "manualConversion()": "bool",
          "buyLockDisabled()": "bool",
          "limit()": "bool",
          "presaleIsActive()": "bool",
          "opensellTx()": "bool",
          "released()": "bool",
          "canUpgrade()": "bool",
          "isLosslessOn()": "bool",
          "isLosslessTurnOffProposed()": "bool",
          //
          // address
          //
          "liquidityReceiver()": "liquidity",
          "marketingFeeWallet()": "marketing",
          "marketingWallet()": "marketing",
          "devWallet()": "dev",
          "deadAddress()": "dead",
          "routerAddress()": "router",
          "dexRouter()": "router",
          "dexPair()": "pair",
          "feeAddress1()": "fee1",
          "feeAddress2()": "fee2",
          "WETH()": "WETH",
          "factory()": "factory",
          "factoryV2()": "factory",
          "WETH9()": "weth",
          "positionManager()": "manager",
          "token0()": "token0",
          "token1()": "token1",
          "getPairAddress()": "pair",
          "marketingAddress()": "marketing",
          "teamAddress()": "team",
          "liquidityAddress()": "liquidity",
          "devAddress()": "dev",
          "getAdmin()": "admin",
          //
          // amount
          //
          "_operationsTokensToSwap()": "amount",
          "getPrivateSaleMaxSell()": "amount",
          "_circulatingSupply()": "amount",
          "balanceLimit()": "amount",
          "buyLimit()": "amount",
          "InitialSupply()": "amount",
          "sellLimit()": "amount",
          "CAP()": "amount",
          "currentLiqTokens()": "amount",
          "currentmktTokens()": "amount",
          "currentPZTokens()": "amount",
          "taxTokensBeforeSwap()": "amount",
          "taxTokensForLiquidity()": "amount",
          "taxTokensForMarketing()": "amount",
          "_marketingTokensToSwap()": "amount",
          "_liquidityTokensToSwap()": "amount",
          "_maxWalletToken()": "amount",
          "minimumTokenBalanceForDividends()": "amount",
          "minTxAmount()": "amount",
          "TOTAL_SUPPLY()": "amount",
          "TREASURY_SUPPLY()": "amount",
          "getTotalReflected()": "amount",
          "collectedFeeTotal()": "amount",
          "totalCollectedFees()": "amount",
          "getCirculatingSupply()": "amount",
          "_walletMax()": "amount",
          "minimumSupply()": "amount",
          "maximumSupply()": "amount",
          "swapAmount()": "amount",
          "swapThreshold()": "amount",
          "maxTxLimit()": "amount",
          "maxWalletLimit()": "amount",
          "swapAtAmount()": "amount",
          "totalAvailable()": "amount",
          "totalLock()": "amount",
          "MAXIMUM_AMOUNT_TO_CLAIM()": "amount",
          "AMOUNT_LP()": "amount",
          "AMOUNT_AIREDROP()": "amount",
          "AMOUNT_STAKING()": "amount",
          "AMOUNT_DAO()": "amount",
          "AMOUNT_TEAM()": "amount",
          "MAX_SUPPLY()": "amount",
          "initialSupply()": "amount",
          "tSupply()": "amount",
          "_maxBalAmount()": "amount",
          "maxWalletAmount()": "amount",
          "minTokensBeforeSwap()": "amount",
          "genesis_supply()": "amount",
          "getMaxTX()": "amount",
          "getMaxWallet()": "amount",
          "minimumTokensBeforeSwap()": "amount",
          "minimumTokensBeforeSwapAmount()": "amount",
          "_maxWalletSize()": "amount",
          "_maxTxAmount()": "amount",
          "_maxBuyAmount()": "amount",
          "_maxHeldTokens()": "amount",
          "amountInPool()": "amount",
          "thisBalance()": "amount",
          "swapTokensAtAmount()": "amount",
          "tokensForDev()": "amount",
          "tokensForLiquidity()": "amount",
          "tokensForMarketing()": "amount",
          "INITIAL_SUPPLY()": "amount",
          //
          "maxBuyAmount()": "amount",
          "maxSellAmount()": "amount",
          "minAnswer()": "amount",
          "maxAnswer()": "amount",
          "_getMaxTxAmount()": "amount",
          "_swapTokensAtAmount()": "amount",
          "cap()": "amount",
          "maxWalletToken()": "amount",
          "maxBuyTransactionAmount()": "amount",
          "maxSellTransactionAmount()": "amount",
          "maxTxAmount()": "amount",
          "numTokensSellToAddToLiquidity()": "amount",
          "totalFees()": "amount",
          "_totalSupply()": "amount",
          "price0CumulativeLast()": "amount",
          "price1CumulativeLast()": "amount",
          // "maxTxAmountUI()": "amount",   // !!!!!!!!!!!!!!!!
          //"maxWalletSizeUI()": "amount", // !!!!!!!!!!!!!!!!
          //
          "_hasLiqBeenAdded()": "bool",
          "_saleIsActive()": "bool",
          "lock()": "bool",
          "preSaleStarted()": "bool",
          "saleStarted()": "bool",
          "allowListOnly()": "bool",
          "mintingActive()": "bool",
          "mintMain()": "bool",
          "mintWhitelist()": "bool",
          "isMintingAllowed()": "bool",
          "isLuckyMintingAllowed()": "bool",
          "isWhitelistMintingAllowed()": "bool",
          "isUnlocked()": "bool",
          "stopped()": "bool",

          "mintingFinished()": "bool",
          "allowed()": "bool",
          "paused()": "bool",
          "locked()": "bool",
          "mintOpen()": "bool",
          "whiteListStart()": "bool",
          "started()": "bool",
          "is_whitelist_on()": "bool",

          "maxWallet()": "amount",
          "maxTransactionAmount()": "amount",
          "maxBuy()": "amount",
          "getMaxBuy()": "amount",
          "maxBuyIncrementValue()": "amount",
          "SUPPLY_CAP()": "amount",
        };

        bool_types.forEach((t) => (output_types[`${t}()`] = "bool"));

        const used_output_type = output_types[signature] || "num";
        //
        return (
          <b>
            {defaultValueFormatting(
              { [used_output_type]: v },
              used_output_type
            )}
          </b>
        );
        // TODO: format
        //
        // return v;
        //return <b>{v}</b>;
      }
    }
  };

  const _eth_logo = () => (
    <img
      src="../eth.png"
      style={{
        height: "12px",
        position: "relative",
        top: "2px",
      }}
    />
  );
  const _as_ethereum_price = (s: string) => (
    <>
      {(parseFloat(s) / Math.pow(10, 18)).toFixed(4)}
      {_eth_logo()}
    </>
  );

  const defaultValueFormatting = (
    input: Record<string, string>,
    key: string
  ) => {
    return isAddress(input[key])
      ? _as_smart_stripe(1, input[key].toLowerCase(), 12, 12, true)
      : isEthereumAmount(key, input[key])
      ? _as_ethereum_price(input[key])
      : isKeccak(input[key])
      ? _as_stripe(input[key], 8)
      : key === "addresses" || key === "path"
      ? _as_multi_stripe(String(input[key]), 12)
      : key === "nums"
      ? _as_multi_num(input[key], 12)
      : key.startsWith("amounts")
      ? input[key]
      : key.startsWith("amount")
      ? _as_formatted_amount(
          input[key],
          contract?.contract?.test.erc20.decimals || 18
        )
      : isBooleanKey(key)
      ? `${input[key] ? "TRUE" : "FALSE"}`
      : input[key];
  };

  const hasAbi = contract && contract?.contract?.abi.length > 0;

  const prettyPrintLexIdentifierValue = (
    lexLookupResult:
      | {
          type: string;
        }
      | undefined
  ) => {
    //
    if (lexLookupResult) {
      const { type, ...rest } = lexLookupResult;
      //
      let result = JSON.stringify(rest); // fallback
      let handled = true;
      //
      switch (type) {
        case "StringLiteral":
          result = (rest as { value: string }).value;
          break;
        case "NumberLiteral":
          result = (rest as { number: string }).number;
          break;
        case "BooleanLiteral":
          result = (rest as { value: string }).value;
          break;

        case "FunctionCall":
          const obj: Record<string, any> = (rest as { expression: any })
            .expression;
          const keys = Object.keys(obj);

          const has_type = obj["type"] !== undefined;
          const type = has_type ? obj.type : "no_type";
          //
          switch (type) {
            case "Identifier":
              const name = obj["name"] || "no_name";
              result = `[CALLS][ID][${name}`;
              break;
            default:
              result = `CALLS ${Object.keys(
                (rest as { expression: string }).expression
              )}`;
              break;
          }
          break;

        default:
          handled = false;
      }
      //
      const _as_ast_tooltip = (
        type: string,
        restObject: Record<string, any>
      ) => {
        const fallback_value = JSON.stringify(restObject, null, 4);
        let res = fallback_value;
        //
        return (
          <div style={{ display: "inline-block" }} className="cooltip">
            <div
              style={{
                padding: "2px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "180px",
                border: "solid 1px #aaaaaa",
              }}
            >
              AST:{`${type}`}
            </div>
            <div style={{ height: "1px", width: "1px" }}>
              <span className="cooltiptext" style={{ position: "absolute" }}>
                RES:
                {res.split('"type"').map((line) => (
                  <div>
                    {line}
                    <br />
                  </div>
                ))}
              </span>
            </div>
          </div>
        );
      };
      //
      return lexLookupResult
        ? handled
          ? `[${result}]`
          : _as_ast_tooltip(lexLookupResult.type, rest)
        : "No info.";
    } else {
      return "No info.";
    }
  };

  const _render_tags = (txs: Array<any>) => {
    // 1. project to methodname
    // 2. distinct
    // 3. sort
    // 4. return
    /*
    block: "14133452"
    from: "0xa1fe137ae4e0b45b3ae700e460087d7865a0bfe1"
    idx: "87"
    input: {num: "10"}
    known: true
    method_name: "publicMint"
    originalParams: "000000000000000000000000000000000000000000000000000000000000000a"
    time: "1643897068"

    */

    const names = txs.map((tx: any) => tx.method_name);
    const distinct = Array.from(new Set(names));
    distinct.sort();
    //
    return distinct.map(
      (method_name: string, idx: number, arr: Array<string>) => (
        <>
          <div
            style={{
              display: "inline-block",
              maxWidth: arr.length < 5 ? "142px" : "100px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              paddingLeft: "4px",
              paddingRight: "4px",
              textDecoration:
                method_name === "approve" && hideApproval
                  ? "line-through"
                  : "none",
            }}
          >
            {method_name}
          </div>
          {idx !== arr.length - 1 ? "  " : ""}
        </>
      )
    );
    return distinct.join(" / ");
  };
  //
  const render_abi_understood = (
    getters: Array<{
      type: string;
      hex: string;
      name: string;
    }>,
    exclude_by_item: boolean = true // !!!
  ) => {
    //
    // BY DEFAULT USING LOOSE COMPARISION TO ACHIVE MORE 'UNDERSTANDING's
    //

    // contract.contract.test.inst.value,
    console.log("GETTERS", getters);
    L("render_abi_understood", getters, exclude_by_item);
    //                       !hideKnownInstanceMethodsGroupped
    const { understood, groups, simplified } = understandGenome(
      getters,
      exclude_by_item
    );
    //
    if (understood) {
      return getters.length ? (
        <ContentRow>
          Known variant detected.
          <br />
          <div>{render_understood_as_icons(groups)}</div>
        </ContentRow>
      ) : (
        <ContentRow>No ABI information.</ContentRow>
      );
    } //  else { return " :(" + simplified.length + " - " + JSON.stringify(groups); }
  };
  //

  const maxTxOrDefault = 0.1;
  const riseDefault = 12.5;
  const gasUsedDefault = 46.6;
  const [invested, setInvested] = useState<number>(maxTxOrDefault);
  const [riseMulti, setRiseMulti] = useState<number>(riseDefault);
  const [gasUsed, setGasUsed] = useState<number>(gasUsedDefault);
  const [ABC, setABC] = useState<{ A: number; B: number; C: number }>(); //{ A: 1, B: 1, C: 1, }

  //
  const feeProps = {
    invested,
    setInvested,
    riseMulti,
    setRiseMulti,
    gasUsed,
    setGasUsed,
    ABC,
    setABC,
    thePriceOfEth,
    thePriceOfGas,
  };
  //

  return (
    <MainContent>
      {/* <Section>
        <ContentBlock>
          {gasChartMemo && (
            <span
              style={{
                border: "solid 1px black",
                //float: "right",
                margin: "auto",
                // marginLeft: "10px",
                // marginBottom: "10px",
                width: "620px",
                height: "200px",
                overflow: "hidden",
              }}
            >
              <GasPriceDistributionChart
                envelope={gasChartMemo}
                A={0}
                B={0}
                C={0}
              />
            </span>
          )}
        </ContentBlock>
      </Section> */}

      {/* <div
        style={{
          width: "9px",
          height: "9px",
          border: "solid 1px black",
        }}
        id="three_canvas"
        onClick={() => {
          L("three_canvas---click");
        }}
        ref={(ref) => {
          if (ref) threeCanvasRef.current = ref;
        }}
      /> */}
      <Container>
        <Section>
          <ContentBlock>
            {/* <ContentRow>
              <SubTitle>Contract Inspection</SubTitle>
            </ContentRow> */}
            <ContentRow>
              <Label>Address:</Label>{" "}
              <TextField
                name="input-contract-address"
                fullWidth
                value={contractAddress}
                placeholder="Enter contract address"
                onChange={(e: any) => setContractAddress(e.target.value)}
              />
            </ContentRow>
            <ContentRow>
              {render_urls_as_icons(contractUrls as ContractUrlResult)}
              <Button onClick={onSaveClicked}>Check</Button>
              {/* pETH:{" "}
              {thePriceOfEth} pGas: {thePriceOfGas} Fee: {thePriceOfFeeBase}{" "}
              gwei - {thePriceOfFeeMax} gwei */}
            </ContentRow>

            {contract && contract?.contract?.test?.inst.value
              ? render_abi_understood(contract?.contract?.test?.inst.value)
              : ""}

            <ContentRow>
              {/* {render_urls_as_icons(contractUrls as ContractUrlResult)} */}
            </ContentRow>
            {contract && contract?.contract?.test?.inst.value.length ? (
              <ContentRow>
                {/* Fees:
              {contractFees
                ? contractFees.map((cf: any) => (
                    <div>
                      {cf.label}: {cf.value}
                    </div>
                  ))
                : ""} */}
                {render_fees_as_icons(contractFees, feeProps)}
              </ContentRow>
            ) : (
              ""
            )}

            {/* <ContentRow style={{ fontSize: "11px" }}>
              ERC20: <br />
              {getErc20CallResultsTable()
                .sort((a, b) => a[0].localeCompare(b[0]))
                .map(([key, val]) => (
                  <div
                    style={{
                      padding: "2px",
                      display: "inline-block",
                      maxWidth: "400px",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <span
                      style={{
                        display: "inline-block",
                        maxWidth: "150px",
                        textOverflow: "ellipsis",
                        paddingRight: "10px",
                      }}
                    >
                      {key}
                    </span>
                    {getErc20CallResultValueOfSignature(key)}
                  </div>
                ))}
            </ContentRow> */}
          </ContentBlock>
        </Section>

        {/* {contractSourceRaw && (
          <Section>
            <ContentBlock>
              <ContentRow>
                <AntibotCodeComponent
                  value={contractSourceRaw}
                  abi={(contract && contract.contract.abi) || []}
                  tensors={(contract && contract.contract.TENSORS) || {}}
                />
              </ContentRow>
            </ContentBlock>
          </Section>
        )} */}

        {!isLoading && (
          <>
            {contract && (
              <Section>
                <ContentBlock>
                  <div>
                    <div style={{ float: "left", width: "49%" }}>
                      <ContentRow>
                        <SubTitle>
                          {contract.contract.src?.ContractName}
                        </SubTitle>
                        <div style={{ fontSize: "12px" }}>
                          <ContentRow>
                            <Label>Version:</Label>{" "}
                            {(contract.contract.src?.ABI || []).length ? (
                              <TextInline>
                                {contract.contract.src?.CompilerVersion} ({" "}
                                {contract.contract.src?.LicenseType})
                              </TextInline>
                            ) : (
                              "Contract NOT verified."
                            )}
                          </ContentRow>
                          <ContentRow>
                            <Label>Verification:</Label>{" "}
                            <TextInline>
                              ABI: {(contract.contract.src?.ABI || []).length}{" "}
                              bytes
                              <br />
                              Source:{" "}
                              {
                                String(contract.contract.src?.SourceCode).length
                              }{" "}
                              bytes
                              <br />
                              {firstBlock && (
                                <small>
                                  Chain:
                                  {firstBlock.L} hexbytes
                                </small>
                              )}
                            </TextInline>
                          </ContentRow>
                          {firstBlock && (
                            <ContentRow>
                              <Label>Deployed:</Label>{" "}
                              <TextInline>
                                <>
                                  CA:{" "}
                                  {_as_stripe(contract.address, 12, 12, true)}{" "}
                                  {(
                                    (new Date().getTime() -
                                      new Date(
                                        parseInt(firstBlock.time) * 1000
                                      ).getTime()) /
                                    (1000 * 3600)
                                  ).toFixed(2)}{" "}
                                  h ago{" "}
                                  <small>in block {firstBlock.block}</small>{" "}
                                  <br />
                                  {firstDeployerBlock && (
                                    <>
                                      Dep:
                                      {_as_stripe(
                                        firstBlock.from, // he is the deployer
                                        12,
                                        12,
                                        true
                                      )}{" "}
                                      {(
                                        (new Date().getTime() -
                                          new Date(
                                            parseInt(firstDeployerBlock.time) *
                                              1000
                                          ).getTime()) /
                                        (1000 * 3600)
                                      ).toFixed(2)}{" "}
                                      h ago{" "}
                                      <small>
                                        in block {firstDeployerBlock.block}
                                      </small>{" "}
                                      <br />
                                    </>
                                  )}
                                  {firstOwnerBlock && (
                                    <>
                                      Own:
                                      {contract?.contract?.test &&
                                        contract?.contract?.test?.erc20?.owner
                                          ?.address &&
                                        _as_stripe(
                                          contract.contract.test.erc20.owner
                                            .address, // he is the OWNER todo change
                                          12
                                        )}
                                      {(
                                        (new Date().getTime() -
                                          new Date(
                                            parseInt(firstOwnerBlock.time) *
                                              1000
                                          ).getTime()) /
                                        (1000 * 3600)
                                      ).toFixed(2)}{" "}
                                      h ago{" "}
                                      <small>
                                        in block {firstOwnerBlock.block}
                                      </small>{" "}
                                      <br />
                                    </>
                                  )}
                                </>
                              </TextInline>
                            </ContentRow>
                          )}
                        </div>
                      </ContentRow>
                    </div>
                    <div style={{ float: "right", width: "49%" }}>
                      <ContentRow>
                        {contract?.contract?.test &&
                          contract?.contract?.test.erc20.error && (
                            <div>{contract?.contract?.test.erc20.error}</div>
                          )}
                        {contract?.contract?.test &&
                          contract?.contract?.test.erc20 && (
                            <div style={{ fontSize: "12px" }}>
                              <SubTitle>
                                {contract.contract.test.erc20.name} (
                                {contract.contract.test.erc20.symbol})
                              </SubTitle>
                              {contract.contract.test.erc20.total && (
                                <ContentRow>
                                  <Label>Total Supply:</Label>{" "}
                                  <TextInline>
                                    {contract.contract.test.erc20.total.whole} .
                                    <small>
                                      {
                                        contract.contract.test.erc20.total
                                          .fraction
                                      }{" "}
                                      ( dec:{" "}
                                      {contract.contract.test.erc20.decimals})
                                    </small>
                                  </TextInline>
                                </ContentRow>
                              )}

                              {contract?.contract?.test &&
                                contract?.contract?.test.erc20.owner && (
                                  <>
                                    <ContentRow>
                                      <Label>
                                        Ownership:{" "}
                                        {getOwnerModeDisplayName(
                                          contract.contract.test.erc20.owner
                                            .mode
                                        )}
                                      </Label>{" "}
                                      <TextInline>
                                        {contract.contract.test.erc20.owner
                                          .address &&
                                          _as_stripe(
                                            contract.contract.test.erc20.owner
                                              .address,
                                            32,
                                            32,
                                            true,
                                            140
                                          )}
                                        <br />
                                        <small>
                                          {
                                            contract.contract.test.erc20.owner
                                              .address
                                          }
                                        </small>
                                      </TextInline>
                                    </ContentRow>

                                    {contract.contract.test.erc20.owner.mode ===
                                      3 && (
                                      <ContentRow>
                                        <Label>Owner wallet:</Label>{" "}
                                        <TextInline>
                                          {_as_formatted_amount(
                                            contract.contract.test.erc20.owner
                                              .balance,
                                            contract.contract.test.erc20
                                              .decimals
                                          )}{" "}
                                          {contract.contract.test.erc20.symbol}{" "}
                                          owned
                                          <br />
                                          {
                                            contract.contract.test.erc20.owner
                                              .balanceETH[1]
                                          }{" "}
                                          ETH
                                          <br />
                                          {
                                            contract.contract.test.erc20.owner
                                              .owned
                                          }{" "}
                                          of supply
                                          <br />
                                        </TextInline>
                                      </ContentRow>
                                    )}
                                  </>
                                )}
                              <br />
                            </div>
                          )}
                      </ContentRow>
                    </div>
                    <div style={{ clear: "both" }}></div>
                  </div>
                </ContentBlock>
              </Section>
            )}

            {contract &&
              contract?.contract.test &&
              contract.contract.test.inst &&
              (!hasAbi ? (
                <Section>
                  <ContentBlock>
                    <ContentRow>
                      No ABI information for{" "}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`https://etherscan.io/address/${contractAddress}#code`}
                      >
                        {_as_stripe(contractAddress, 22)}
                      </a>
                      , click stripe to open Etherscan.
                    </ContentRow>
                  </ContentBlock>
                </Section>
              ) : (
                <Section>
                  <ContentBlock>
                    <ContentRow>
                      <SubTitle>
                        Instance at {_as_stripe(contractAddress, 22)}{" "}
                        {contract.proxy && contract.erc721.behindProxy && (
                          <span>
                            behind a T{contract.erc721.type} proxy at{" "}
                            {_as_stripe(contract.erc721.to, 22)}{" "}
                          </span>
                        )}
                      </SubTitle>
                    </ContentRow>
                    <ContentRow>
                      <div>
                        <div style={{ float: "left", width: "49%" }}>
                          {" "}
                          <Label>ABI:</Label>{" "}
                          <TextInline>
                            {Math.floor(contract.contract.test.inst.length / 3)}
                            /{contract.contract.test.inst.length} entries
                          </TextInline>
                          <div style={{ float: "right", width: "270px" }}>
                            simplify: {hideKnownInstanceMethods ? "ON" : "OFF"}
                            <input
                              type="checkbox"
                              checked={hideKnownInstanceMethods}
                              onChange={() =>
                                setHideKnownInstanceMethods(
                                  !hideKnownInstanceMethods
                                )
                              }
                            />
                            {hideKnownInstanceMethods && (
                              <>
                                {hideKnownInstanceMethodsGroupped
                                  ? "mode: STRICT"
                                  : "mode: MATCH ANY"}
                                <input
                                  type="checkbox"
                                  checked={hideKnownInstanceMethodsGroupped}
                                  onChange={() =>
                                    setHideKnownInstanceMethodsGroupped(
                                      !hideKnownInstanceMethodsGroupped
                                    )
                                  }
                                />
                              </>
                            )}
                          </div>
                        </div>
                        <div style={{ float: "right", width: "49%" }}>
                          <Label>Address:</Label>{" "}
                          <TextInline>{contractAddress}</TextInline>
                          <Button
                            style={{ zoom: "50%" }}
                            onClick={() => {
                              const arr =
                                contract?.contract?.test?.inst?.value ?? [];

                              L("Learning ABI for", contractAddress);
                              const res = learnSetters(arr);
                              L("Learned:", res);
                            }}
                          >
                            Learn
                          </Button>
                        </div>
                        <div style={{ clear: "both" }}></div>
                      </div>
                    </ContentRow>
                    <ContentRow>
                      <div>
                        <div style={{ float: "left", width: "49%" }}>
                          <table
                            style={{
                              width: "370px",
                              fontSize: "12px",

                              margin: "0px",
                            }}
                          >
                            <thead>
                              <tr>
                                <th>Type</th>
                                <th>Hex</th>
                                <th>Name</th>
                                <th>Signature</th>
                              </tr>
                            </thead>
                            <tbody>
                              {(hideKnownInstanceMethods
                                ? _exclude_all_known(
                                    contract.contract.test.inst.value,
                                    !hideKnownInstanceMethodsGroupped
                                  )
                                : contract.contract.test.inst.value
                              )
                                .filter(
                                  (a: any) =>
                                    a.type !== "payable" &&
                                    a.type !== "nonpayable"
                                )
                                .sort((a: any, b: any) =>
                                  `${a.type}|${a.name}`.localeCompare(
                                    `${b.type}|${b.name}`
                                  )
                                )
                                .map(
                                  ({
                                    type,
                                    hex,
                                    name,
                                    sign,
                                    supported,
                                  }: {
                                    type: string;
                                    hex: string;
                                    name: string;
                                    sign: string;
                                    supported: boolean;
                                  }) => (
                                    <tr key={hex}>
                                      <td style={{ width: "50px" }}>
                                        <div
                                          style={{
                                            padding: "auto",
                                          }}
                                        >
                                          {_as_interface_stripe(hex, 12)}
                                        </div>
                                        <div
                                          style={{
                                            position: "relative",
                                            height: "0px",
                                            top: "-16px",
                                            padding: "auto",
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            fontSize: "10px",
                                            color: "white",
                                          }}
                                        >
                                          {type === "view"
                                            ? "V"
                                            : type === "nonpayable"
                                            ? "NP"
                                            : type === "payable"
                                            ? "P!"
                                            : type === "pure"
                                            ? "Vp"
                                            : type}
                                        </div>
                                      </td>
                                      <td
                                        style={{
                                          width: "60px",
                                          color: supported
                                            ? "#22ff22"
                                            : "#111111",
                                        }}
                                      >
                                        {hex}
                                      </td>
                                      <td
                                        style={{
                                          maxWidth: "120px",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {name}
                                      </td>
                                      <td
                                        style={{
                                          maxWidth: "190px",
                                          overflow: "hidden",
                                        }}
                                      >
                                        {getErc20CallResultValueOfSignature(
                                          sign
                                        )}
                                      </td>
                                    </tr>
                                  )
                                )}
                            </tbody>
                          </table>
                        </div>
                        <div style={{ float: "right", width: "49%" }}>
                          <table
                            style={{
                              width: "370px",
                              fontSize: "12px",
                              margin: "0px",
                            }}
                          >
                            <thead>
                              <tr>
                                <th>Type</th>
                                <th>Hex</th>
                                <th>Name</th>
                                <th>Signature</th>
                              </tr>
                            </thead>
                            <tbody>
                              {(hideKnownInstanceMethods
                                ? _exclude_all_known(
                                    contract.contract.test.inst.value,
                                    !hideKnownInstanceMethodsGroupped
                                  )
                                : contract.contract.test.inst.value
                              )
                                .filter(
                                  (a: any) =>
                                    a.type === "payable" ||
                                    a.type === "nonpayable"
                                )
                                .sort((a: any, b: any) =>
                                  `${a.type}|${a.name}`.localeCompare(
                                    `${b.type}|${b.name}`
                                  )
                                )
                                .map(
                                  ({
                                    type,
                                    hex,
                                    name,
                                    sign,
                                    supported,
                                  }: {
                                    type: string;
                                    hex: string;
                                    name: string;
                                    sign: string;
                                    supported: boolean;
                                  }) => (
                                    <tr key={`setters_${hex}`}>
                                      <td style={{ width: "50px" }}>
                                        <div
                                          style={{
                                            padding: "auto",
                                          }}
                                        >
                                          {_as_interface_stripe(hex, 12)}
                                        </div>
                                        <div
                                          style={{
                                            position: "relative",
                                            height: "0px",
                                            top: "-16px",
                                            padding: "auto",
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            fontSize: "10px",
                                            color: "white",
                                          }}
                                        >
                                          {type === "view"
                                            ? "V"
                                            : type === "nonpayable"
                                            ? "NP"
                                            : type === "payable"
                                            ? "P!"
                                            : type === "pure"
                                            ? "Vp"
                                            : type}
                                        </div>
                                      </td>
                                      <td
                                        style={{
                                          width: "60px",
                                          color: supported
                                            ? "#22ff22"
                                            : "#111111",
                                        }}
                                      >
                                        {hex}
                                      </td>
                                      <td
                                        style={{
                                          maxWidth: "150px",
                                          overflow: "hidden",
                                        }}
                                      >
                                        {/* {name} */}
                                        {[
                                          "MAX",
                                          "TX",
                                          "BOT",
                                          "SNIPER",
                                          "TRAD",
                                          "START",
                                          "LAUNCH",
                                          "AIRDROP",
                                          "LIQ",
                                          "SALE",
                                          "PERC",
                                          "DELETE",
                                          "BLACK",
                                          "WITHDRAW",
                                          "ANTISNIPE",
                                          "EARLY",
                                          "BLOCK",
                                          "WHALE",
                                          "BURN",
                                          "FEE",
                                          "REMOVE",
                                          "EXCLUDE",
                                          "PAUSE",
                                          "OPEN",
                                          "OWNER",
                                          "BLW",
                                          "LIMIT",
                                        ]
                                          .map(
                                            (kw) =>
                                              name.toUpperCase().indexOf(kw) >
                                              -1
                                          )
                                          .reduce((a, b) => a || b, false) ? (
                                          <b>{name}</b>
                                        ) : (
                                          name
                                        )}
                                      </td>
                                      <td
                                        style={{
                                          maxWidth: "190px",
                                          overflow: "hidden",
                                        }}
                                      >
                                        {sign}
                                      </td>
                                    </tr>
                                  )
                                )}
                            </tbody>
                          </table>
                        </div>
                        <div style={{ clear: "both" }}></div>
                      </div>
                    </ContentRow>

                    {hideKnownInstanceMethods
                      ? contract?.contract?.test?.inst &&
                        _as_simplified_genome(
                          contract.contract.test.inst.value,
                          !hideKnownInstanceMethodsGroupped
                        )
                      : contract?.contract?.test?.inst && (
                          <ContentRow>
                            Genome:
                            <br />
                            {_as_genome(
                              contract.contract.test.inst.value.sort(
                                (a: any, b: any) => a.hex.localeCompare(b.hex)
                              )
                            )}
                          </ContentRow>
                        )}
                  </ContentBlock>
                </Section>
              ))}

            {/* <div style={{ width: "200px" }}>
              {isUser ? (
                "THIS IS A USER"
              ) : (
                <>
                  source: {hideSourceCode ? "HIDDEN" : "SHOWN"}
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={hideSourceCode}
                    onChange={() => setHideSourceCode(!hideSourceCode)}
                  />
                </>
              )}
            </div> */}

            {contract && !hideSourceCode && (
              <Section>
                <ContentBlock>
                  <ContentRow>
                    <Title>Source analysis</Title>
                  </ContentRow>
                  <ContentRow>
                    {contract.contract.lex && (
                      <ContentRow>
                        <Label>Identifiers:</Label>{" "}
                        <TextInline>
                          <table
                            style={{
                              width: "880px",
                              fontSize: "12px",

                              margin: "0px",
                            }}
                          >
                            <thead>
                              <tr>
                                <th>Contract</th>
                                <th>Identifiers overview</th>
                              </tr>
                            </thead>
                            <tbody>
                              {(
                                contract.contract?.lex.VISITED
                                  ?.ContractDefinition || []
                              ).map(
                                ({
                                  value,
                                }: {
                                  src: string;
                                  value: {
                                    name: string;
                                    inherits: Array<string>;
                                  };
                                }) => (
                                  <tr
                                    key={value.name}
                                    style={{
                                      display:
                                        contract.contract.lex.LEX.VARS.names.filter(
                                          (key) =>
                                            key.startsWith(`${value.name}.`)
                                        ).length === 0
                                          ? "none"
                                          : "table-row",
                                    }}
                                  >
                                    <td
                                      style={{
                                        width: "80px",
                                        // color: supported ? "#22ff22" : "#111111",
                                      }}
                                    >
                                      {value.name}
                                    </td>
                                    <td
                                      style={{
                                        maxWidth: "700px",
                                        display: "flex",
                                        justifyContent: "space-around",
                                        alignItems: "flex-start",
                                        flexFlow: "row wrap",
                                      }}
                                    >
                                      {contract.contract.lex.LEX.VARS.names
                                        .filter((key) =>
                                          key.startsWith(`${value.name}.`)
                                        )
                                        .map((variable_name) => (
                                          <span
                                            style={{
                                              padding: "2px",
                                              maxWidth: "180px",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              display: "inline-flex",
                                              flexGrow: "1",
                                            }}
                                          >
                                            <div
                                              className="cooltip"
                                              style={{
                                                padding: "2px",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                maxWidth: "180px",
                                                border: "solid 1px #aaaaaa",
                                              }}
                                            >
                                              {variable_name.replace(
                                                `${value.name}.`,
                                                ""
                                              )}
                                              <br />
                                              {prettyPrintLexIdentifierValue(
                                                Object.fromEntries(
                                                  contract.contract.lex.LEX.VARS
                                                    .value
                                                )[`${variable_name}`]
                                              )}
                                              {/* {`${value.name}.${variable_name}`} */}

                                              <br />
                                              {getErc20CallResultValueOfSignature(
                                                `${variable_name.replace(
                                                  `${value.name}.`,
                                                  ""
                                                )}()`
                                              )}
                                            </div>
                                          </span>
                                        ))}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </TextInline>
                      </ContentRow>
                    )}
                  </ContentRow>
                  <ContentRow>
                    {contract.contract.lex && (
                      <ContentRow>
                        <Label>Methods:</Label>{" "}
                        <TextInline>
                          <table
                            style={{
                              width: "880px",
                              fontSize: "12px",

                              margin: "0px",
                            }}
                          >
                            <thead>
                              <tr>
                                <th>Size</th>
                                <th>Contract</th>
                                <th>Inherits</th>
                                <th>Methods overview</th>
                              </tr>
                            </thead>
                            <tbody>
                              {(
                                contract.contract.lex.VISITED
                                  ?.ContractDefinition || []
                              ).map(
                                ({
                                  src,
                                  value,
                                }: {
                                  src: string;
                                  value: {
                                    name: string;
                                    inherits: Array<string>;
                                  };
                                }) => (
                                  <tr key={value.name}>
                                    <td style={{ width: "80px" }}>{src}</td>
                                    <td
                                      style={{
                                        width: "80px",
                                      }}
                                    >
                                      {value.name}
                                    </td>
                                    <td
                                      style={{
                                        maxWidth: "120px",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {value.inherits.length
                                        ? `${value.inherits.join(", ")}`
                                        : ""}
                                    </td>
                                    <td
                                      style={{
                                        maxWidth: "480px",
                                        display: "flex",
                                        justifyContent: "space-around",
                                        alignItems: "flex-start",
                                        flexFlow: "row wrap",
                                      }}
                                    >
                                      {contract.contract.lex.LEX.FUNC.names
                                        .filter((key) =>
                                          key.startsWith(`${value.name}.`)
                                        )
                                        .map((method_name) => (
                                          <span
                                            style={{
                                              padding: "2px",
                                              maxWidth: "120px",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              display: "inline-flex",
                                              flexGrow: "1",
                                            }}
                                          >
                                            <div className="cooltip">
                                              {method_name.replace(
                                                `${value.name}.`,
                                                ""
                                              )}
                                              <span className="cooltiptext">
                                                {(
                                                  contract.contract.lex.VISITED.FunctionDefinition.find(
                                                    (node: any) =>
                                                      node.value.name ===
                                                      method_name.replace(
                                                        `${value.name}.`,
                                                        ""
                                                      )
                                                  ) || { src: "no src" }
                                                ).src
                                                  .split("\r\n")
                                                  .map(
                                                    (
                                                      line: string,
                                                      idx: number
                                                    ) => (
                                                      <div
                                                        key={`code_${idx}`}
                                                        style={{
                                                          textAlign: "left",
                                                        }}
                                                      >
                                                        [
                                                        {String(idx).padStart(
                                                          3,
                                                          "0"
                                                        )}
                                                        ] {line}
                                                      </div>
                                                    )
                                                  )}
                                              </span>
                                            </div>
                                          </span>
                                        ))}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </TextInline>
                      </ContentRow>
                    )}
                  </ContentRow>
                  https://
                  <ContentRow>
                    URIs found:
                    {contract &&
                    (contract.contract.src?.SourceCode || "").indexOf("{{") ===
                      0
                      ? "NOT available yet in JSON format"
                      : (contract.contract.src?.SourceCode || "")
                          .split("\n")
                          .filter(
                            (line: string) =>
                              (line.indexOf("http://") !== -1 ||
                                line.indexOf("https://") !== -1 ||
                                line.indexOf("ipfs://") !== -1) &&
                              line.indexOf("https://github.com/") === -1 &&
                              line.indexOf("https://eips.ethereum.org/") ===
                                -1 &&
                              line.indexOf(
                                "https://solidity.readthedocs.io/"
                              ) === -1
                          )
                          .map((matching_line: string) => (
                            <div key={matching_line}>{matching_line}</div>
                          ))}
                  </ContentRow>
                  <ContentRow>
                    Raw source:
                    {contract &&
                    (contract.contract.src?.SourceCode || "").indexOf("{{") ===
                      0 ? (
                      Object.entries(
                        JSON.parse(
                          (contract.contract.src?.SourceCode || "")
                            .replace("{{", "{")
                            .replace("}}", "}")
                        ).sources || { content: "" }
                      ).map(([key, val]) => (
                        <TextArea
                          key={`src_${key}`}
                          filePath={key}
                          initialValue={(val as { content: string }).content}
                        />
                      ))
                    ) : (contract.contract.src?.SourceCode || "").indexOf(
                        "{"
                      ) === 0 ? (
                      Object.entries(
                        JSON.parse(contract.contract.src?.SourceCode || "") ||
                          {}
                      ).map(([key, val]) => (
                        <TextArea
                          key={`src_${key}`}
                          filePath={key}
                          initialValue={(val as { content: string }).content}
                        />
                      ))
                    ) : (
                      <TextArea
                        initialValue={contract.contract.src?.SourceCode}
                      />
                    )}
                  </ContentRow>
                  <div>
                    <StyledSelect
                      value={value}
                      onChange={(e) => {
                        console.log("changing", e.target.value, value);
                        setValue(e.target.value as string);
                      }}
                    >
                      {currentRandomTen === undefined
                        ? ""
                        : currentRandomTen.map(([guid, colors]: Array<any>) => (
                            <MenuItem
                              value={guid}
                              key={guid}
                              // selected={guid === value}
                            >
                              <ListItemText
                                primary={
                                  <>
                                    <StyledListItemIcon></StyledListItemIcon>
                                    <StyledTypography>
                                      {colors.map((color: string) => (
                                        <div
                                          key={color}
                                          style={{
                                            display: "inline-block",
                                            width: "32px",
                                            height: "32px",
                                            backgroundColor: color,
                                          }}
                                        >
                                          {" "}
                                        </div>
                                      ))}
                                    </StyledTypography>
                                  </>
                                }
                              />
                            </MenuItem>
                          ))}
                    </StyledSelect>
                  </div>
                  <ContentRow>
                    <Label>Chain id:</Label> <TextInline>{chainId}</TextInline>
                  </ContentRow>
                  {/* <ContentRow>
                      <Label>Current block:</Label>{" "}
                      <TextInline>{blockNumber}</TextInline>
                    </ContentRow> */}
                  {/* {difficulty && (
                      <ContentRow>
                        <Label>Current difficulty:</Label>{" "}
                        <TextInline>{difficulty?.toString()}</TextInline>
                      </ContentRow>
                    )}
                    {timestamp && (
                      <ContentRow>
                        <Label>Current block timestamp:</Label>{" "}
                        <TextInline>{timestamp?.toLocaleString()}</TextInline>
                      </ContentRow>
                    )} */}
                </ContentBlock>
              </Section>
            )}

            {/* <Section>
              {ABC && (
                <>
                  <input
                    type="range"
                    min={50}
                    max={400}
                    style={{ width: "500px", margin: "auto" }}
                    value={gasUsed || 100}
                    onChange={(e) => setGasUsed(parseInt(e.target.value))}
                  />
                  <BreakOutComponent
                    value={"World2"}
                    ABC={ABC}
                    priceOfGas={thePriceOfGas}
                    priceOfEth={thePriceOfEth}
                    gasUsed={gasUsed}
                  />
                </>
              )}
            </Section> */}
            <Section>
              <ContentBlock>
                <ContentRow>
                  <div
                    style={{
                      fontSize: "12px",
                      lineHeight: "14px",
                      maxWidth: "580px",

                      border: "solid 1px #ddeedd",
                      borderRadius: "5px",
                      backgroundColor: "#f7fff7",
                      position: "absolute",
                      top: "75px",
                    }}
                  >
                    {isLoadingTransaction ? (
                      <div>...</div>
                    ) : transactions?.TXS.length ? (
                      _render_tags(transactions.TXS)
                    ) : (
                      ""
                    )}
                  </div>
                  <SubTitle style={{ float: "left", width: "550px" }}>
                    Transactions of{" "}
                    {transactionPageSubjectOptions
                      ? transactionPageSubjectOptions.find(
                          (opt) => opt.value === transactionsPageSubject
                        )?.label || ""
                      : ""}{" "}
                    {_as_stripe(transactionsPageSubject, 14)}
                  </SubTitle>
                  <div
                    style={{
                      width: "310px",
                      display: "inline-block",
                      position: "relative",
                      float: "right",
                      textAlign: "right",
                      top: "4px",
                      paddingBottom: "4px",
                      paddingRight: "10px",
                      border: "solid 1px #ddeedd",
                      borderRadius: "5px",
                      backgroundColor: "#f7fff7",
                    }}
                  >
                    <div
                      style={{
                        width: "120px",
                        display: "inline-block",
                        position: "relative",
                        top: "0px",
                      }}
                    >
                      <StyledSelectTransactionSubject
                        value={transactionsPageSubject}
                        onChange={(e) => {
                          console.log(
                            "changing transactions subject",
                            e.target.value,
                            value
                          );
                          //
                          setTransactionsPageSubject(e.target.value as string);
                        }}
                      >
                        {transactionPageSubjectOptions.map(
                          (
                            subject: { value: string; label: string },
                            idx: number
                          ) => (
                            <MenuItem
                              value={subject.value}
                              key={`${subject.value}_${idx}`}
                              selected={
                                transactionsPageSubject === subject.value
                              }
                            >
                              <ListItemText
                                primary={
                                  <StyledTypography>
                                    <div
                                      style={{
                                        height: "5px",
                                        display: "inline-block",
                                        width: "100px",
                                        position: "absolute",
                                        top: "20px",
                                      }}
                                    >
                                      {_as_stripe(subject.value, 10, 4)}
                                    </div>
                                    {subject.label}
                                  </StyledTypography>
                                }
                              />
                            </MenuItem>
                          )
                        )}
                      </StyledSelectTransactionSubject>
                    </div>
                    <div
                      style={{
                        width: "70px",
                        display: "inline-block",
                        position: "relative",
                        top: "0px",
                      }}
                    >
                      <StyledSelectDirection
                        value={transactionsPageDirection}
                        onChange={(e) => {
                          console.log(
                            "changing transactions direction",
                            e.target.value,
                            value
                          );
                          //
                          setTransactionsPageDirection(
                            e.target.value as string
                          );
                        }}
                      >
                        {transactionPageDirectionOptions.map(
                          (direction: string) => (
                            <MenuItem
                              value={direction}
                              key={direction}
                              selected={transactionsPageDirection === direction}
                            >
                              <ListItemText
                                primary={
                                  <StyledTypography>
                                    {direction}
                                  </StyledTypography>
                                }
                              />
                            </MenuItem>
                          )
                        )}
                      </StyledSelectDirection>
                    </div>
                    <div
                      style={{
                        width: "40px",
                        display: "inline-block",
                        position: "relative",
                        top: "-12px",
                      }}
                    >
                      {transactions?.TXS.length} /{" "}
                    </div>
                    <div
                      style={{
                        width: "60px",
                        display: "inline-block",
                        position: "relative",
                        top: "0px",
                      }}
                    >
                      <StyledSelectPageSize
                        value={transactionsPageSize}
                        onChange={(e) => {
                          console.log(
                            "changing transactions pagesize",
                            e.target.value,
                            value
                          );
                          //
                          setTransactionsPageSize(e.target.value as number);
                        }}
                      >
                        {transactionPageSizeOptions.map((pagesize: number) => (
                          <MenuItem
                            value={pagesize}
                            key={pagesize}
                            selected={transactionsPageSize === pagesize}
                          >
                            <ListItemText
                              primary={
                                <StyledTypography>{pagesize}</StyledTypography>
                              }
                            />
                          </MenuItem>
                        ))}
                      </StyledSelectPageSize>
                    </div>
                    <div
                      style={{
                        //float: "right",
                        width: "300px",
                        fontSize: "11px",
                        textAlign: "right",
                      }}
                    >
                      {/* <input
                      type="text"
                      style={{ width: "40px", textAlign: "center" }}
                      value={transactionsPageSize}
                      onChange={(e) =>
                        setTransactionsPageSize(parseInt(e.target.value))
                      }
                    /> */}
                      Senders:{" "}
                      <b>{hideTransactionAddresses ? "HIDDEN" : "SHOWN"}</b>
                      <input
                        type="checkbox"
                        checked={hideTransactionAddresses}
                        onChange={() =>
                          setHideTransactionAddresses(!hideTransactionAddresses)
                        }
                      />
                      Gas: <b>{hideGasUsed ? "PRICE" : "USED"}</b>
                      <input
                        type="checkbox"
                        checked={hideGasUsed}
                        onChange={() => setHideGasUsed(!hideGasUsed)}
                      />
                      Approves: <b>{hideApproval ? "HIDDEN" : "SHOWN"}</b>
                      <input
                        type="checkbox"
                        checked={hideApproval}
                        onChange={() => setHideApproval(!hideApproval)}
                      />
                      <br />
                    </div>
                  </div>
                </ContentRow>

                {/* SECTION TRANSACTIONS START */}
                <ContentRow>
                  {isLoadingTransaction ? (
                    <div>Refreshing ...</div>
                  ) : transactions?.TXS.length ? (
                    <div>
                      <table
                        style={{
                          width: "900px",
                          fontSize: "12px",
                          margin: "0px",
                        }}
                      >
                        <thead>
                          <tr>
                            {/* <th>Block</th> */}
                            {!hideTransactionAddresses && <th>Who</th>}
                            {!hideTransactionAddresses && <th></th>}
                            {!hideTransactionAddresses && <th>To</th>}
                            <th>When</th>

                            <th style={{ width: "40px", maxWidth: "40px" }}>
                              ETH
                            </th>
                            <th>Type</th>
                            <th>Inputs</th>
                            <th>
                              Gas
                              <small>{hideGasUsed ? " (Gwei)" : " used"}</small>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredApprovals.map(
                            ({
                              from,
                              to,
                              is_contract_call,
                              known,
                              block,
                              idx,
                              time,
                              method_name,
                              input,
                              values,
                            }: {
                              from: string;
                              to: string;
                              is_contract_call: boolean;
                              known: boolean;
                              block: number;
                              idx: number;
                              time: number;
                              method_name: string;
                              input: any;
                              originalParams: string;
                              values: any;
                            }) => (
                              <tr key={`setters_${block}_${idx}`}>
                                {!hideTransactionAddresses && (
                                  <td
                                    style={{
                                      minWidth: "70px",
                                      maxWidth: "90px",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {from ? _as_stripe(from, 8, 8, true) : ""}
                                  </td>
                                )}
                                {!hideTransactionAddresses && (
                                  <td
                                    style={{
                                      minWidth: "20px",
                                      maxWidth: "20px",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {!to ? "" : is_contract_call ? "📝" : "🙍‍♂️"}
                                  </td>
                                )}
                                {!hideTransactionAddresses && (
                                  <td
                                    style={{
                                      minWidth: "70px",
                                      maxWidth: "90px",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {to ? _as_stripe(to, 8, 8, true) : ""}
                                  </td>
                                )}
                                <td
                                  style={{
                                    maxWidth: "60px",
                                    overflow: "hidden",
                                    textAlign: "center",
                                  }}
                                >
                                  {to_ago_from_utc(time * 1000)}
                                </td>

                                <td style={{ width: "40px", maxWidth: "40px" }}>
                                  {values.value > 0.0
                                    ? values.value.toFixed(2)
                                    : ""}
                                </td>
                                <td
                                  align="center"
                                  style={{
                                    width: "140px",
                                    maxWidth: "140px",
                                    color: known ? "#22ff22" : "#111111",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                  }}
                                >
                                  {method_name}
                                </td>

                                <td
                                  style={{
                                    maxWidth: !hideTransactionAddresses
                                      ? "580px"
                                      : "540px",
                                    width: !hideTransactionAddresses
                                      ? "580px"
                                      : "540px",
                                    minWidth: !hideTransactionAddresses
                                      ? "580px"
                                      : "540px",
                                    overflowX: "hidden",
                                  }}
                                >
                                  {known ? (
                                    <div style={{ display: "flex" }}>
                                      {Object.keys(input).map((key) => (
                                        <div key={`transactions_${key}`}>
                                          <div
                                            style={{
                                              display: "inline-flex",
                                              padding: "2px",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              // overflow: "hidden",
                                            }}
                                          >
                                            <span
                                              style={{
                                                padding: "2px",
                                              }}
                                            >
                                              {key}
                                            </span>{" "}
                                            {defaultValueFormatting(input, key)}
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  ) : (
                                    <small>
                                      {input.length > 128
                                        ? _as_datacanvas(
                                            input,
                                            //hideTransactionAddresses ? 580 : 540
                                            hideTransactionAddresses ? 770 : 770
                                          )
                                        : input}
                                    </small>
                                  )}
                                </td>
                                <td
                                  style={{
                                    maxWidth: "50px",
                                    overflow: "hidden",
                                  }}
                                >
                                  {hideGasUsed
                                    ? (values.gasPriceWei / 1000000000).toFixed(
                                        2
                                      )
                                    : (values.gasWei / 1000).toFixed(2)}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div>
                      No transactions to show for{" "}
                      {_as_stripe(transactionsPageSubject, 10, 10, true)}
                    </div>
                  )}
                </ContentRow>
                {/* SECTION TRANSACTIONS END */}
              </ContentBlock>
            </Section>
          </>
        )}
      </Container>
    </MainContent>
  );
}

//style={{}

const StyledSelect = styled(Select)`
  min-width: 200px;
`;

const StyledSelectDirection = styled(Select)`
  min-width: 60px;
  max-width: 60px;
  margin-right: 4px;
`;

const StyledSelectPageSize = styled(Select)`
  min-width: 65px;
  max-width: 70px;
  margin-right: 4px;
`;

const StyledSelectTransactionSubject = styled(Select)`
  min-width: 120px;
  max-width: 120px;
  margin-right: 4px;
`;

// const StyledImg = styled.img`
//   margin-right: 10px;
//   max-width: 50px;
//   max-height: 50px;
// `;

const StyledListItemIcon = styled(ListItemIcon)`
  padding-left: 5px;
  display: inline;
  float: left;
`;

const StyledTypography = styled.span`
  float: left;
  display: inline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 85%;
  max-height: 32px;
  // border: solid 1px #444444;
`;

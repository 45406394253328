// import {
//   // ERC20Interface,
//   // useContractCalls,
//   useEthers,
// } from "@usedapp/core";
import { useState, useEffect, Fragment, useMemo } from "react";
import styled from "styled-components";
// import { Colors } from "../../global/styles";
import { Label } from "../../typography/Label";
// import { TextBold } from "../../typography/Text";
import { ContentRow } from "../base";
import { _as_smart_stripe, _as_stripe } from "../SimpleControls";

import {
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  TextField,
} from "@material-ui/core";
import useMethodColors from "../../hooks/useMethodColors";

import IconStar from "../../assets/icons/Star";
import IconRobot from "../../assets/icons/Robot";
import IconOpenMethod from "../../assets/icons/OpenMethod";
import { ContractQuickCheckResult, ContractUrlResult } from "../../types";
import RangeSlider from "../RangeSlider";
import EthLogo from "../../assets/eth.png";
import {
  getBaseCurrencyImageUrl,
  getBaseCurrencyName,
} from "../../utils/chains";
import { Link } from "react-router-dom";
import { API_URL_ROOT } from "../../settings";

// input:0x6eDE5004a408011C76605F9bf3FB289D7E398F74
const account_colors = (
  account: string,
  repeated_idx: Array<number> = [3, 5, 8, 13, 17, 21, 27, 35] // seed 40 + 8 = 8x6
) => {
  const input = account.replace("0x", "").toLowerCase().trim();
  const digits = input.split(""); // 40 digits
  const repeated_digits = digits.filter((v, i) => repeated_idx.includes(i));
  digits.push(...repeated_digits);
  const s = digits.join("");
  //
  const hexas = [] as Array<string>; // '123abc'
  for (let i = 0; i < digits.length; i += 6) hexas.push(s.substr(i, 6));
  const colors = hexas.map((h) => `#${h}`);
  //
  return colors;
};
/*
const _as_stripe = (from: string, size: number) => {
  if (from) {
    const random = Math.random();
    return account_colors(from).map((color: string) => (
      <div
        key={`contract_${from}_${color}_${random}`}
        data-id={from}
        style={{
          display: "inline-block",
          width: `${size}px`,
          height: `${size}px`,
          backgroundColor: color,
        }}
      >
        {" "}
      </div>
    ));
  } else return "";
};
*/

/*
function useTokensBalance(tokenList: any[], account?: string | null) {
  return useContractCalls(
    tokenList && account
      ? tokenList.map((token: any) => ({
          abi: ERC20Interface,
          address: token.address,
          method: "balanceOf",
          args: [account],
        }))
      : []
  );
}
*/

export function DefaultContractsList(props: { mode: string; chainId: number }) {
  const { mode, chainId } = props;
  //
  const {
    ercColorByMethods,
    botColorByMethods,
    openMethodColorByMethods,
    maxTransactionModeColorByMethods,
  } = useMethodColors();

  // const { chainId, account } = useEthers();
  const [tokenList, setTokenList] = useState<Array<ContractQuickCheckResult>>();
  const [sortedTokenList, setSortedTokenList] =
    useState<Array<ContractQuickCheckResult>>();

  // const tokenList = await getFomosList(chainId)

  const [isFilteringMethods, setIsFilteringMethods] = useState<boolean>(false); // currently used for filering the displayed method names
  const [tensorsVisible, setTensorsVisible] = useState<boolean>(true); // currently used if we like to see owner values
  const [tensorsPercentVisible, setTensorsPercentVisible] =
    useState<boolean>(true); // currently used if we like to see owner values
  const [deployerVisible, setDeployerVisible] = useState<boolean>(true); // currently used if we like to see deployere values
  const [ownerVisible, setOwnerVisible] = useState<boolean>(true); // currently used if we like to see owner values
  const [iconsVisible, setIconsVisible] = useState<boolean>(false); // currently used if we like to use 'slow icons'
  const [filesVisible, setFilesVisible] = useState<boolean>(false); // currently used for displaying file names only
  const [eventsVisible, setEventsVisible] = useState<boolean>(false); // currently used for displaying event names only
  const [methodsVisible, setMethodsVisible] = useState<boolean>(false); // currently used for displaying method names only
  const [filterNameValue, setFilterNameValue] = useState<string>(""); // currently used for name only
  const [sortValue, setSortValue] = useState<string>(""); // currently used for sort mode
  const [sortOptions, setSortOptions] = useState<Array<any>>();

  const [rangeValue, setRangeValue] = useState<Array<number>>([0, 1000]);

  // const filteredAndSortedMemo = useMemo(() => {
  //   console.log('filteredAndSortedMemo created', rangeValue, sortedTokenList?.length);
  //   return sortedTokenList && rangeValue
  //     ? sortedTokenList.filter(
  //         (c: ContractQuickCheckResult) =>
  //           !c.probe ||
  //           (c.probe && !c.probe.owner) ||
  //           (c.probe &&
  //             c.probe.owner &&
  //             c.probe.owner.eth !== undefined &&
  //             c.probe.owner.eth >= rangeValue[0] && // matching min
  //             c.probe.owner.eth < rangeValue[1]) // matching max
  //       )
  //     : [];
  // }, [rangeValue]);

  if (sortOptions === undefined) {
    setSortOptions([
      ["by_date", "Order by appearance"],
      ["by_name", "Order by name"],
      ["by_version", "Order by version"],

      ["by_address", "Order by owner address"],
      ["by_tensor_maxtx_percent", "Order by maxTX percent"],

      ["by_owner_balance", "Order by balance of owner"],
      /*
      ["by_file_type", "Order by type of source"],
      ["by_num_files", "Order by number of files"],
      ["by_num_events", "Order by number of events"],
      ["by_num_functions", "Order by number of functions"],
      ["by_num_lines", "Order by number of source lines"],
      ["by_num_bytes", "Order by number of source size"],
      ["by_tensor_maxws_percent", "Order by maxWallet percent"],
      */
    ]);

    setSortValue("by_date"); // default sort type
  }

  useEffect(() => {
    console.log("getContractsList", mode);

    const url = `${API_URL_ROOT}/contracts?chain=${chainId}&mode=${mode}&limit=499`;
    console.log("downloading from", url);

    const fetchData = async () => {
      const res = await fetch(url, {
        mode: "cors",
        method: "get",
        // referrerPolicy: "origin",
        // headers: {
        //   "Access-Control-Allow-Origin": "https://kyc.foundation",
        // },
      });
      console.log("res", res);
      const json = await res.json();
      console.log("json", json);
      const ok = json && json.ok;

      const data = json.value.contracts;
      console.log("contracts", data, ok);

      setTokenList(data as Array<any>);
    };

    fetchData();
  }, [mode, chainId]);

  useEffect(() => {
    console.log("sortContractList");

    if (tokenList && sortValue) {
      setSortedTokenList(undefined);

      console.log("sorting contracts", tokenList.length, sortValue);

      const sorters: Record<string, (a: any, b: any) => number> = {
        by_owner_balance: (
          a: {
            probe: { owner: { eth: number; mode: number; address: string } };
          },
          b: {
            probe: { owner: { eth: number; mode: number; address: string } };
          }
        ) =>
          !b.probe?.owner
            ? -1
            : !a.probe?.owner
            ? 1
            : !b.probe?.owner.mode
            ? -1
            : !a.probe?.owner.mode
            ? 1
            : b.probe?.owner?.mode !== 3
            ? -1
            : a.probe?.owner?.mode !== 3
            ? 1
            : b.probe.owner.eth - a.probe.owner.eth,

        by_date: (
          a: { deployer: { time: string } },
          b: { deployer: { time: string } }
        ) =>
          a.deployer && b.deployer
            ? b.deployer.time.localeCompare(a.deployer.time)
            : 0,
        by_name: (a: { name: string }, b: { name: string }) =>
          b.name.localeCompare(a.name),
        //

        by_version: (a: { version: string }, b: { version: string }) => {
          // v0.8.9
          // todo vyper
          const va = a.version.replace("v", "").split(".");
          const vb = b.version.replace("v", "").split(".");
          //
          const [a_major, a_minor, a_release] = va.map((v) => parseInt(v));
          const [b_major, b_minor, b_release] = vb.map((v) => parseInt(v));
          //
          const numA = a_major * 10000 + a_minor * 100 + a_release;
          const numB = b_major * 10000 + b_minor * 100 + b_release;
          //
          return numB - numA;
        },
        //b.version.localeCompare(a.version),
        by_tensor_maxtx_percent: (
          a: { TENSOR: { ___MAX_TX___: any } },
          b: { TENSOR: { ___MAX_TX___: any } }
        ) =>
          a.TENSOR && b.TENSOR
            ? parseFloat(a.TENSOR.___MAX_TX___.R) -
              parseFloat(b.TENSOR.___MAX_TX___.R)
            : b.TENSOR
            ? 1
            : a.TENSOR
            ? -1
            : 0,
        /*
        by_tensor_maxws_percent: (
          a: { TENSOR: { ___MAX_WS___: any } },
          b: { TENSOR: { ___MAX_WS___: any } }
        ) =>
          a.TENSOR && b.TENSOR
            ? parseFloat(a.TENSOR.___MAX_WS___.R) -
              parseFloat(b.TENSOR.___MAX_WS___.R)
            : b.TENSOR
            ? 1
            : a.TENSOR
            ? -1
            : 0,
            */
      };

      const sorter = sorters[sortValue];
      console.log("using", sorter);

      //
      // filter by range
      //
      const copy =
        filterNameValue.length < 1
          ? Array.from(tokenList)
          : Array.from(
              tokenList.filter(
                (t: ContractQuickCheckResult) =>
                  t.name
                    .toUpperCase()
                    .indexOf(filterNameValue.toUpperCase()) !== -1 ||
                  (t.probe &&
                    t.probe.base.name.match(
                      new RegExp(filterNameValue, "i")
                    )) !== null
              )
            );
      //

      console.log("copy-orig[0]", copy[0]);
      copy.sort(sorter); // copy and sort
      console.log("copy-sorted[0]", copy[0]);

      setSortedTokenList(copy as Array<any>);
    }
  }, [tokenList, filterNameValue, sortValue]);

  const now = new Date();

  const to_ago_from_utc = (utc_time: string) => {
    const when = new Date(utc_time);
    const ms_elapsed = now.getTime() - when.getTime();
    const sec = Math.floor(ms_elapsed * 0.001);
    //
    let s: string;
    //
    if (sec < 60) s = `${sec} s`;
    else if (sec < 3600) s = `${(sec / 60.0).toFixed(0)} m`;
    else if (sec < 86400) s = `${(sec / 3600.0).toFixed(0)} h`;
    else s = `${(sec / 86400.0).toFixed(0)} d`;
    //
    return s;
  };

  const get_swap_link = (
    chainId: number,
    address: string
  ): JSX.Element | undefined => {
    let s;
    let url;
    //
    if (address) {
      switch (chainId) {
        case 1:
          url = `https://app.uniswap.org/#/swap?outputCurrency=${address}&chain=mainnet`;
          s = "uni_swp.png";
          break;
        case 56:
          url = `https://exchange.pancakeswap.finance/#/swap?outputCurrency=${address}`;
          s = "pancake_swp.png";
          break;
        case 137:
          url = `https://quickswap.exchange/#/swap?outputCurrency=${address}`;
          s = "quick_swp.png";
          break;
        case 250:
          url = `https://spookyswap.finance/swap?outputCurrency=${address}`;
          s = "spooky_swp.png";
          break;
        // default unhandled
      }
    }
    //

    //

    if (url) {
      return (
        <a target="_blank" rel="noopener noreferrer" href={url}>
          <img src={s} style={{ width: "16px", height: "16px" }} alt={url} />
        </a>
      );
    }
  };
  //
  const get_scan_link = (
    chainId: number,
    address: string
  ): JSX.Element | undefined => {
    let s;
    let url;
    //
    if (address) {
      switch (chainId) {
        case 1:
          url = `https://etherscan.io/address/${address}`;
          s = "eth_scan.png";
          break;
        case 56:
          url = `https://bscscan.com/address/${address}`;
          s = "bsc_scan.png";
          break;
        case 137:
          url = `https://polygonscan.com/address/${address}`;
          s = "poly_scan.png";
          break;
        case 250:
          url = `https://ftmscan.com/address/${address}`;
          s = "ftm_scan.png";
          break;
        // default unhandled
      }
    }
    //

    //

    if (url) {
      return (
        <a target="_blank" rel="noopener noreferrer" href={url}>
          <img src={s} style={{ width: "16px", height: "16px" }} alt={url} />
        </a>
      );
    }
  };

  //
  // probe getters          // uint256 public _tTotal = 10**9 * 10**9;                         // 1 billion
  //
  const getContractTicker = (c: ContractQuickCheckResult) => {
    return c.probe !== undefined ? c.probe.base.symbol || "[EMPTY]" : "[N./A.]";
  };
  const getContractName = (c: ContractQuickCheckResult) => {
    /*
    return c.probe !== undefined
      ? c.probe.base.name.length
        ? c.probe.base.name[0]
        : c.probe.base.name
      : `[${c.name}]`;
      */
    return c.probe !== undefined
      ? c.probe.base.name || "[EMPTY]"
      : `[${c.name}]`; //USE THIS
  };

  const getContractCallResultLength = (c: ContractQuickCheckResult) => {
    const not_null = c.probe !== undefined && c.probe !== null;
    //
    if (not_null) {
      return Object.keys(c.probe?.callResults || {}).length;
    } else {
      return "-";
    }
  };
  const getContractProxyImplementation = (c: ContractQuickCheckResult) => {
    const not_null =
      c.probe !== undefined && Object.keys(c.probe.callResults).length > 0;
    //
    let res;
    if (not_null) {
      const cr = c.probe?.callResults || {};
      //
      if (cr.hasOwnProperty("_implementation()")) res = cr["_implementation()"];
      else if (cr.hasOwnProperty("implementation()"))
        res = cr["implementation()"];
      else if (cr.hasOwnProperty("_getImplementation()"))
        res = cr["_getImplementation()"];
    } else {
      res = "";
    }
    //
    return res;
  };

  const as_percent = (str_float_num: string, divider: number = 1) => {
    let n = str_float_num !== undefined ? parseFloat(str_float_num) : -1;
    //
    if (divider > 1) {
      n /= divider;
    }
    //
    //
    if (n > 1000) return `🦄 (${Math.floor(Math.log(n))})`;
    //
    return n === -1 ? "N./A." : n === 1 ? "." : (n * 100).toFixed(2) + "%";
  };

  //
  //
  //
  const render_urls_as_icons = (urls: ContractUrlResult) => {
    const s = JSON.stringify(urls).length;
    const s2 =
      Object.entries(urls).filter(
        ([key, val]) => val !== "" && key !== "RAW"
      ) || [];
    //
    // ['twitter', 'https://twitter.com/McPepeETH']
    // ['web', 'https://McPepe.cash']
    //
    const displayMap: Record<string, string> = {
      web: "W",
      telegram: "TG",
      twitter: "TW",
      //
      discord: "DC",
      instagram: "IN",
      reddit: "RE",
      opensea: "OP",
    };
    //
    const _as_anchor = (key: string, url: string): JSX.Element => {
      const char = displayMap[key];

      return (
        <a href={url} target="_blank" title={url}>
          <img
            src={`/logo/${key}.png`}
            style={{ width: "16px", height: "16px" }}
            alt={url}
          />
        </a>
      );
    };
    //
    //const output = s2.map(([key, url]: any) => displayMap[key]);
    const output = s2.map(([key, url]: any) => _as_anchor(key, url));
    //
    return (
      <div>
        {output.map((o, idx, arr) => (
          <>
            {o}
            {arr.length - 1 !== idx && " "}
          </>
        ))}
      </div>
    );
  };
  //
  //
  //
  const _as_progress_bar = (ratio: number) => {
    const _prc = ratio * 100.0;
    const int_prc = Math.floor(ratio * 100);
    //
    const less_then_fifty = int_prc < 50;
    //
    return (
      <div
        className="progress"
        style={{
          border: "solid 1px #999999",
          position: "relative",
          top: "10px",
        }}
      >
        <div
          className="progress-bar progress-bar-green progress-bar-striped"
          role="progressbar"
          style={{
            width: `${int_prc}%`,
            float: less_then_fifty ? "left" : "none",
          }}
        >
          {!less_then_fifty ? (
            <span style={{ filter: "drop-shadow(0px 2px 2px black)" }}>
              {int_prc}%
            </span>
          ) : (
            ""
          )}
        </div>
        {less_then_fifty ? (
          <span
            style={{
              filter: "drop-shadow(0px -2px -2px white)",
              position: "relative",
              top: "7px",
              left: "2px",
              float: "right",
            }}
          >
            {_prc.toFixed(3)}%
          </span>
        ) : (
          ""
        )}
      </div>
    );
  };
  //
  //
  //
  const dexToolsRoot =
    chainId === 1
      ? "//dextools.io/app/ether/pair-explorer/"
      : chainId === 56
      ? "//dextools.io/app/bsc/pair-explorer/"
      : chainId === 137
      ? "//dextools.io/app/polygon/pair-explorer/"
      : chainId === 250
      ? "//dextools.io/app/fantom/pair-explorer/"
      : "//dextools.io/app/ether/pair-explorer/";
  //
  const render_default_contract_row = (
    c: ContractQuickCheckResult,
    idx: number
  ) => {
    //
    // const isObfuscated = ["0", "2", "4", "6", "8", "a", "c", "e"].includes(
    //   c.address[c.address.length - 1]
    // );
    const isObfuscated = false;
    const obfuscate_str = (s: string): string =>
      s
        ? s
            .split("")
            .map((c) => (c === " " ? String(c) : "*"))
            .join("")
        : "";

    //
    return (
      <Fragment key={`result_${c.address}`}>
        <tr
          role="row"
          key={`row_0_${c.address}`}
          style={{
            backgroundColor: c.verified
              ? idx % 2 === 1
                ? "#eeeeee"
                : "#f6f6f6"
              : "rgba(250, 181, 97, 0.4)",
          }}
        >
          <td style={{ width: "auto", maxWidth: "200px" }}>
            <div
              style={{
                padding: "2px",
                display: "inline-block",
                // maxWidth: "100px",
                overflow: "hidden",
                marginTop: "3px",
                marginLeft: "5px",
                textOverflow: "ellipsis",
              }}
            >
              <Link to={`/upload?address=${c.address}&chain=${chainId}`}>
                {/* {mode !== "erc1967" */}
                {(c as any)?.erc721?.behindProxy === true
                  ? _as_stripe(getContractProxyImplementation(c), 8)
                  : getContractName(c)}
              </Link>
            </div>
          </td>
          <td
            style={{
              width: "50px",
              maxWidth: "50px",
              overflow: "hidden",
              textAlign: "center",
              textOverflow: "ellipsis",
            }}
          >
            {getContractTicker(c)}
            <div style={{ position: "relative", height: "1px", top: "-12px" }}>
              {_as_smart_stripe(1, c.address, 6, 2, false, undefined)}
            </div>
          </td>
          <td style={{ width: "40px", textAlign: "center" }}>
            {c.verified ? "Y" : "N"}
          </td>

          <td style={{ width: "50px", textAlign: "center" }}>
            {c.URLS !== undefined ? render_urls_as_icons(c.URLS) : "-"}
          </td>
          <td style={{ width: "50px", textAlign: "center" }}>{c.version}</td>
          {deployerVisible && (
            <td style={{ width: "90px", textAlign: "center" }}>
              {c.deployer ? _as_stripe(c.deployer.address, 8) : "-"}
              {c.probe !== undefined && c.probe.owner !== undefined ? (
                c.probe.owner.mode === 2 ? (
                  <div>Own: [DEAD]</div>
                ) : c.probe.owner.address === "" ? (
                  <div>Own: N./A.</div>
                ) : c.probe.owner.address.toLowerCase() ===
                  (c.deployer?.address || "").toLowerCase() ? (
                  ""
                ) : (
                  <div>
                    Ownership transferred to
                    <br />
                    {_as_stripe(c.probe.owner.address, 8)}
                  </div>
                )
              ) : (
                ""
              )}
            </td>
          )}
          {iconsVisible && (
            <td style={{ width: "80px", textAlign: "center" }}>
              <IconStar
                color={
                  c.abi?.names ? ercColorByMethods(c.abi?.names) : "#eeeeee"
                }
              />{" "}
              <IconRobot
                color={
                  c.abi?.names ? botColorByMethods(c.abi?.names) : "#eeeeee"
                }
              />{" "}
              <IconOpenMethod
                color={
                  c.abi?.names
                    ? openMethodColorByMethods(c.abi?.names)
                    : "#eeeeee"
                }
              />{" "}
              <IconStar
                color={
                  c.abi?.names
                    ? maxTransactionModeColorByMethods(c.abi?.names)
                    : "#eeeeee"
                }
              />{" "}
            </td>
          )}
          {/* SECTION OWNER START */}
          {ownerVisible && (
            <>
              <td style={{ width: "60px", textAlign: "center" }}>
                {c.probe !== undefined &&
                c.probe.owner !== undefined &&
                c.probe.owner.eth !== undefined &&
                c.probe.owner.address !== "" ? (
                  <div>
                    {c.probe.owner.mode === 2 ? (
                      "[DEAD]"
                    ) : (
                      //parseFloat:    c.probe.owner.balanceETH[1]
                      <>
                        {c.probe.owner.eth.toFixed(2)}{" "}
                        <img
                          src={getBaseCurrencyImageUrl(chainId)}
                          style={{
                            height: "14px",
                            position: "relative",
                            top: "-2px",
                          }}
                        />
                      </>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </td>
              <td
                style={{
                  width: "60px",
                  textAlign: "center",
                  maxWidth: "60px",
                  textOverflow: "ellipsis",
                }}
              >
                {c.probe !== undefined &&
                c.probe.owner !== undefined &&
                c.probe.owner.address !== ""
                  ? c.probe.owner.address ===
                      "0x0000000000000000000000000000000000000000" ||
                    c.probe.owner.address ===
                      "0x000000000000000000000000000000000000dEaD"
                    ? "[DEAD]"
                    : // : as_percent(c.probe.owner.owned.replace("%", ""), 100)}
                      _as_progress_bar(
                        parseFloat(c.probe.owner.owned.replace("%", "")) * 0.01
                      )
                  : ""}
              </td>
            </>
          )}
          {/* SECTION OWNER END */}
          {/* SECTION TENSOR START */}
          {tensorsVisible && (
            <>
              <td style={{ width: "70px", textAlign: "center" }}>
                {c.TENSOR !== undefined ? (
                  !isObfuscated ? (
                    <div>
                      {c.TENSOR.___TOTAL___ &&
                        c.TENSOR.___TOTAL___.formatted_short.replace(
                          ".000",
                          ""
                        )}
                    </div>
                  ) : (
                    <div>
                      {c.TENSOR.___TOTAL___ &&
                        obfuscate_str(
                          c.TENSOR.___TOTAL___.formatted_short.replace(
                            ".000",
                            ""
                          )
                        )}
                    </div>
                  )
                ) : (
                  ""
                )}
              </td>

              <td
                style={{
                  //width: "70px",
                  textAlign: "center",
                  maxWidth: "60px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {c.TENSOR !== undefined ? (
                  <div
                    style={{
                      // width: "70px",
                      maxWidth: "60px",
                      textAlign: "center",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {c.TENSOR.___MAX_TX___ && !isObfuscated
                      ? c.TENSOR.___MAX_TX___.formatted_short.replace(
                          ".000",
                          ""
                        )
                      : obfuscate_str(
                          c.TENSOR.___MAX_TX___.formatted_short.replace(
                            ".000",
                            ""
                          )
                        )}
                  </div>
                ) : (
                  ""
                )}
              </td>
              <td
                style={{
                  width: "60px",
                  maxWidth: "60px",
                  textAlign: "center",
                  textOverflow: "ellipsis",
                }}
              >
                {c.TENSOR !== undefined ? (
                  <div>
                    {c.TENSOR.___MAX_TX___ && !isObfuscated
                      ? as_percent(c.TENSOR.___MAX_TX___?.R)
                      : obfuscate_str(as_percent(c.TENSOR.___MAX_TX___?.R))}
                  </div>
                ) : (
                  ""
                )}
              </td>
              <td
                style={{
                  width: "60px",
                  maxWidth: "60px",
                  textAlign: "center",
                  textOverflow: "ellipsis",
                }}
              >
                {c.TENSOR !== undefined ? (
                  <div>
                    {c.TENSOR.___MAX_WS___ && !isObfuscated
                      ? as_percent(c.TENSOR.___MAX_WS___?.R)
                      : obfuscate_str(as_percent(c.TENSOR.___MAX_WS___?.R))}
                  </div>
                ) : (
                  ""
                )}
              </td>
            </>
          )}
          {/* SECTION TENSOR END */}
          <td style={{ width: "40px", textAlign: "center" }}>
            <b>{c.deployer ? to_ago_from_utc(c.deployer.time) : "-"}</b>
          </td>
          <td style={{ width: "50px", textAlign: "center" }}>
            {c.probe !== undefined
              ? to_ago_from_utc(new Date(c.probe.created).toUTCString())
              : "-"}
          </td>
          {/* <td style={{ width: "90px" }}>
            {c.address && (
              <a href={`/upload?address=${c.address}&chain=${chainId}`}>
                {_as_stripe(c.address, 8)}
              </a>
            )}
          </td> */}
          <td style={{ width: "40px", textAlign: "center" }}>
            <input type="checkbox"></input>
          </td>
          <td style={{ width: "40px", textAlign: "center" }}>
            <input type="checkbox"></input>
          </td>
          <td style={{ width: "40px", textAlign: "center" }}>
            {c.address && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${dexToolsRoot}${c.address}`}
              >
                <img
                  src={"dextools.png"}
                  style={{ width: "16px", height: "16px" }}
                  alt={`${dexToolsRoot}${c.address}`}
                />
              </a>
            )}
          </td>
          <td style={{ width: "40px", textAlign: "center" }}>
            {c.address && get_swap_link(chainId, c.address)}
          </td>
          <td style={{ width: "40px", textAlign: "center" }}>
            {c.address && get_scan_link(chainId, c.address)}
          </td>
        </tr>
      </Fragment>
    );
  };

  //
  //
  //

  return (
    <ContentRow key={"contract_list"}>
      {/* Mode:{mode} */}
      <div style={{ width: "100%" }}>
        <div
          style={{
            float: "left",
            width: "25%",
            height: "50px",
          }}
        >
          <StyledSelect
            value={sortValue}
            onChange={(e) => {
              console.log("changing", e.target.value, sortValue);
              setSortValue(e.target.value as string);
            }}
          >
            {sortOptions === undefined
              ? ""
              : sortOptions.map(([guid, colors]: Array<any>, idx: number) => (
                  <MenuItem value={guid} key={guid}>
                    <ListItemText
                      primary={
                        <>
                          <StyledListItemIcon></StyledListItemIcon>
                          <StyledTypography>{colors}</StyledTypography>
                        </>
                      }
                    />
                  </MenuItem>
                ))}
          </StyledSelect>
        </div>
        <div
          style={{
            float: "left",
            width: "24%",
            height: "50px",
            paddingTop: "9px",
          }}
        >
          <TextField
            name="input-contract-address"
            fullWidth
            value={filterNameValue}
            placeholder="Filter name"
            onChange={(e) => setFilterNameValue(e.target.value)}
          />
        </div>
        <div
          style={{
            float: "left",
            width: "24%",
            marginLeft: "20px",
            height: "50px",
            paddingTop: "11px",
          }}
        >
          <div
            style={{
              display: "inline-block",
              width: "70px",
              margin: "auto",
              position: "relative",
              top: "-10px",
            }}
          >
            <Label>Liquidity:</Label>{" "}
          </div>
          <div
            style={{
              display: "inline-block",
              float: "left",
              width: "275px",
              position: "relative",
              top: "-10px",
            }}
          >
            <RangeSlider value={rangeValue} setValue={setRangeValue} />
          </div>
        </div>
        {tokenList && sortedTokenList && (
          <div
            style={{
              display: "inline-block",
              width: "90px",
              float: "right",
              lineHeight: "12px",
              textAlign: "right",
              padding: "4px",
            }}
          >
            {sortedTokenList.length} / {tokenList.length} <br />
            contracts
          </div>
        )}
        <div style={{ clear: "both" }}></div>
      </div>

      <table
        style={{
          // width: "920px",
          minWidth: "1024px",
          maxWidth: "1420px",

          fontSize: "11px",
          margin: "0px",
          border: "solid 1px black",
          borderRadius: "5px",
          overflow: "hidden",
        }}
      >
        <thead>
          <tr>
            <th style={{ width: "auto", textAlign: "left" }}>Name</th>
            <th style={{ width: "50px", textAlign: "center" }}>Symbol</th>
            <th style={{ width: "40px", textAlign: "center" }}>Verified</th>
            <th style={{ width: "40px", textAlign: "center" }}>Social</th>
            <th style={{ width: "50px", textAlign: "center" }}>Version</th>
            {deployerVisible && (
              <th style={{ width: "100px", textAlign: "center" }}>Made by</th>
            )}
            {ownerVisible && (
              <>
                <th style={{ width: "60px" }}>
                  Own. {getBaseCurrencyName(chainId)}
                </th>
                <th style={{ width: "60px", textAlign: "center" }}>
                  Own.Supply
                </th>
              </>
            )}
            {tensorsVisible && (
              <>
                <th style={{ width: "70px", textAlign: "center" }}>
                  Total supply
                </th>
                <th style={{ width: "70px", textAlign: "center" }}>Max Tx</th>
                <th style={{ width: "70px", textAlign: "center" }}>Max Tx %</th>
                <th style={{ width: "70px", textAlign: "center" }}>Max Ws %</th>
              </>
            )}
            {iconsVisible && <th style={{ width: "80px" }}>Bot</th>}
            <th style={{ width: "40px", textAlign: "center" }}>Age</th>
            <th style={{ width: "40px", textAlign: "center" }}>Upd</th>
            {/* <th style={{ width: "100px" }}>Addr</th> */}
            <th style={{ width: "40px", textAlign: "center" }}>Comp.</th>
            <th style={{ width: "40px", textAlign: "center" }}>Save</th>
            <th style={{ width: "40px", textAlign: "center" }}>Dex</th>
            <th style={{ width: "40px", textAlign: "center" }}>Swap</th>
            <th style={{ width: "40px", textAlign: "center" }}>Scan</th>
          </tr>
        </thead>
        <tbody>
          {sortedTokenList &&
            sortedTokenList
              .filter(
                (c: ContractQuickCheckResult) =>
                  !c.probe ||
                  (c.probe && !c.probe.owner) ||
                  (c.probe &&
                    c.probe.owner &&
                    c.probe.owner.eth !== undefined &&
                    c.probe.owner.eth >= rangeValue[0] && // matching min
                    c.probe.owner.eth < rangeValue[1]) // matching max
              )
              .map((c: ContractQuickCheckResult, idx: number) => {
                let jsx;
                try {
                  jsx = render_default_contract_row(c, idx);
                } catch (error) {
                  console.log("ERROR", error);
                  console.log(c.address);
                }
                //
                return jsx;
              })}
        </tbody>
      </table>
    </ContentRow>
  );
}

const get_swap_address = (chainId: number, address: string) => {
  let url;
  //
  switch (chainId) {
    case 1:
      {
      }
      break;
  }
};

const StyledSelect = styled(Select)`
  min-width: 200px;
`;
const StyledListItemIcon = styled(ListItemIcon)`
  padding-left: 5px;
  display: inline;
  float: left;
`;

const StyledTypography = styled.span`
  float: left;
  display: inline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 85%;
  max-height: 32px;
`;

import { BigNumber } from "ethers";
//
//
//
export const _render_chain_card = (props: {
  chainId: number;
  blockNumber: number | undefined;
  difficulty: BigNumber | undefined;
  timestamp: Date | undefined;
  thePriceOfEth: number;
}) => {
  const { chainId, blockNumber, difficulty, timestamp, thePriceOfEth } = props;
  //
  const eth_main = 1;
  const eth_tests = [3, 4, 5, 42];
  //
  const root_nets = [1, 56, 137, 250];

  //
  const rootNames = {
    "1": "Ethereum",
    "3": "Ethereum",
    "4": "Ethereum",
    "5": "Ethereum",
    "42": "Ethereum",

    "56": "Smart Chain",
    "97": "Smart Chain",
    "137": "Matic",
    "250": "Fantom",
    "80001": "Matic",
  } as Record<string, string>;

  //
  const chainNames = {
    "1": "Ethereum",
    "3": "Ropsten",
    "4": "Rinkeby",
    "5": "Goerli",
    "42": "Kovan",
    "56": "Smart Chain",
    "97": "Smart Chain",
    "137": "Matic",
    "250": "Fantom",
    "80001": "Matic",
  } as Record<string, string>;
  //
  const isRootNet = (chainId: number) => root_nets.includes(chainId);
  const isTestNet = (chainId: number) => !isRootNet(chainId);
  //
  const isEtherMainNet = (chainId: number) => chainId === eth_main;
  const isEtherTestNet = (chainId: number) => eth_tests.includes(chainId);
  const isEtherNet = (chainId: number) =>
    isEtherMainNet(chainId) || isEtherTestNet(chainId);
  //
  const chainName = (chainId: number) =>
    chainNames.hasOwnProperty(chainId)
      ? chainNames[String(chainId)]
      : "Unknown";
  const rootName = (chainId: number) =>
    rootNames.hasOwnProperty(chainId) ? rootNames[String(chainId)] : "";

  //
  return (
    <div className="box box-body bg-hexagons-dark pull-up">
      {isEtherMainNet(chainId) ? (
        <div className="ribbon ribbon-bookmark ribbon-vertical-r bg-success">
          <i className="fa fa-heart"></i>
        </div>
      ) : (
        ""
      )}
      <div className="media align-items-center p-0">
        <div className="text-center">
          <a href="#">
            <i className="cc ETH-alt mr-5" title="ETH"></i>
          </a>
        </div>
        <div>
          <h3 className="no-margin text-bold">{chainName(chainId)}</h3>
          <span>
            {rootName(chainId)},{isRootNet(chainId) ? "Mainnet" : "Testnet"}
          </span>
        </div>
      </div>
      <div className="flexbox align-items-center mt-25">
        <div>
          <p className="no-margin font-weight-600">
            {isEtherMainNet(chainId) ? (
              <>
                Supported: <span className="text-green">Yes</span>
              </>
            ) : (
              <>
                Supported: <span className="text-red">No</span>
              </>
            )}
          </p>
          <p className="no-margin">Block:{blockNumber && blockNumber}</p>
        </div>
        <div className="text-right">
          <p className="no-margin font-weight-600">
            <span className="text-green">
              {isEtherMainNet(chainId) && thePriceOfEth ? "$" + thePriceOfEth.toFixed(2) : ""}
              {/* {difficulty &&
                (
                  parseFloat(difficulty.toString()) /
                  Math.pow(10, 13) /
                  100.0
                ).toFixed(2) + "%"} */}
            </span>
          </p>
          <p className="no-margin">
            {" "}
            {timestamp &&
              new Date(Number(timestamp))
                .toISOString()
                .split("T")[1]
                .split(".")[0]}{" "}
          </p>
        </div>
      </div>
    </div>
  );
};
//

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";
//
import { BigNumber, ethers } from "ethers";
import * as ethUtil from "ethereumjs-util";
//
import {
  useBlockMeta,
  useBlockNumber,
  useEthers,
  useGasPrice,
  useToken,
  useTransactions,
} from "@usedapp/core";
//
import useCoinGeckoPrices from "../../../hooks/useCoinGeckoPrices";
// import useTransactionMonitor from "../../../hooks/useTransactionMonitor";
import useWindowSize from "../../../hooks/useWindowSize";
import { _render_chain_card } from "../commons";
import { useTitle } from "../../../hooks/useTitle";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { Web3Provider } from "@ethersproject/providers";
import { Button } from "@material-ui/core";
import { _as_smart_stripe } from "../../../components/SimpleControls";
import DataCanvas from "../../../components/DataCanvas";
import Web3 from "web3";
import Swal from "sweetalert2";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DateTimePicker } from "@mui/lab";
import TextField from "@mui/material/TextField";
import {
  onUserLogsIn,
  onUserLogsOut,
  onUserRegistersWallet,
  setCurrentUser,
} from "../../../redux/user/user.actions";
import { connect } from "react-redux";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { API_URL_ROOT } from "../../../settings";
import { useLocation } from "react-router-dom";
//
//
//
import { ForcedGraphEnvelope } from "../../../types";
import { Texture, TextureLoader } from "three";
import { LockerGraph } from "../../../components/locker/LockerGraph";

const generate_random_links = (
  max_idx: number,
  total_links: number
): Array<{ source: number; target: number }> => {
  const res = [];
  //
  while (res.length < total_links) {
    let from = Math.floor(Math.random() * max_idx);
    let to = Math.floor(Math.random() * max_idx * 3) % max_idx;
    //
    res.push({ source: from, target: to });
  }
  //
  return res;
};

function genRandomTree(N = 300, reverse = false) {
  return {
    nodes: [...Array.from(Array(N).keys())].map((i) => ({ id: i })),
    links: [...Array.from(Array(N).keys())]
      .filter((id) => id)
      .map((id) => ({
        [reverse ? "target" : "source"]: id,
        [reverse ? "source" : "target"]: Math.round(Math.random() * (id - 1)),
      })),
  };
}

const init_data = () => {
  const gd = genRandomTree(50, true);
  //
  gd.links.forEach((link) => {
    const a: any = gd.nodes[link.source];
    const b: any = gd.nodes[link.target];
    //
    !a.neighbors && (a.neighbors = []);
    !b.neighbors && (b.neighbors = []);
    a.neighbors.push(b);
    b.neighbors.push(a);

    !a.links && (a.links = []);
    !b.links && (b.links = []);
    a.links.push(link);
    b.links.push(link);
  });
  //
  // finally remove nodes, without links
  //
  const all_targets = new Set([
    ...gd.links.map((l) => l.source),
    ...gd.links.map((l) => l.target),
  ]);
  gd.nodes = gd.nodes.filter((n) => all_targets.has(n.id));
  //
  return gd;
};
//
//
//

//
//
//

//
//
//
const GroupedTextBox = (props: {
  reason: string;
  setReason: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { reason, setReason } = props;
  //
  const [group, setGroup] = useState<string>("Ethereum Mainnet");
  const [isAddressInputValid, setIsAddressInputValid] =
    useState<boolean>(false);
  const [text, setText] = useState<string>();
  //
  const options = [
    "Ethereum Mainnet",
    "Binance Smart Chain Mainnet",
    "Matic (Polygon) Mainnet",
    "Fantom Mainnet",
    "Cronos Mainnet Beta",
  ];

  useEffect(() => {
    setReason(`${group}|${text}`);
  }, [group, text]);
  //
  useEffect(() => {
    //
    // empty is not valid
    // length should be 42
    // hexadecimal
    // 0x7e8a75908fbb54c0958ae73c760de73164cd323c
    //
    let is_valid = false;
    //
    if (text) {
      let parsed = 0;
      try {
        parsed = parseInt(text, 16);
      } catch (error) {}
      //
      is_valid =
        text.length === 42 && text.toLowerCase().startsWith("0x") && parsed > 0;
    }

    setIsAddressInputValid(is_valid);
    // return is_valid;
  }, [text]);
  //
  const imgIcon = useMemo(() => {
    const img = "eth_coin.png";
    //
    const mapping: Record<string, string> = {
      "Ethereum Mainnet": "eth_coin.png",
      "Binance Smart Chain Mainnet": "bnb_coin.png",
      "Matic (Polygon) Mainnet": "matic_coin.png",
      "Fantom Mainnet": "ftm_coin.png",
      "Cronos Mainnet Beta": "cro_coin.png",
    };
    //
    return mapping[group] || img;
  }, [group]);
  //
  return (
    <div className="form-control input-group input-group-lg">
      <div className="input-group-prepend">
        <button
          type="button"
          className="btn btn-secondary dropdown-toggle"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          {group ? (
            <>
              <img
                src={imgIcon}
                style={{ height: "14px", position: "relative", top: "-2px" }}
              />
              <small style={{ padding: "4px" }}>{group.split(" ")[0]}</small>
            </>
          ) : (
            "Chain"
          )}
        </button>
        <div className="dropdown-menu" style={{ willChange: "transform" }}>
          {options.map((opt) => (
            <a className="dropdown-item" onClick={() => setGroup(opt)}>
              {opt}
            </a>
          ))}
          <div className="dropdown-divider"></div>
          <a className="dropdown-item" onClick={() => setGroup("other")}>
            Other
          </a>
        </div>
        <input
          type="text"
          className="form-control"
          value={text}
          placeholder={"contract address in 0x123456789....cdef01234 format"}
          style={{ paddingLeft: "6px", maxWidth: "400px" }}
          onChange={(e) => setText(e.target.value)}
        />
        <div style={{ width: "20px", textAlign: "center" }}>
          {/* {group ? (
            <span className="text-primary">OK</span>
          ) : text ? (
            <span className="text-warning">N.G.</span>
          ) : (
            ""
          )} */}

          {isAddressInputValid ? (
            <i
              className="fa fa-check primary"
              style={{
                fontSize: "20px",
                position: "relative",
                top: "14px",
                left: "5px",
                color: "lightgreen",
              }}
            ></i>
          ) : // <span className="text-primary">OK</span>
          text ? (
            <span className="text-warning">N.A.</span>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};
//
//
//
const fallbackCopyTextToClipboard = (text: string) => {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand("copy");
    var msg = successful ? "successful" : "unsuccessful";
    console.log("Fallback: Copying text command was " + msg);
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
};
//
//
const copyTextToClipboard = (text: string) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.log("Async: Copying to clipboard was successful!");
    },
    function (err) {
      console.error("Async: Could not copy text: ", err);
    }
  );
};
//

const CreateAnnouncementForm = (props: {
  library: any; //  Web3Provider; or jsonrpc
  identity: string;
  proof: Buffer;
  account: string | null | undefined;
  isGameFinished: any;
  creationResult: any;
  setCreationResult: any;
}) => {
  const {
    identity,
    account,
    proof,
    library,
    isGameFinished,
    creationResult,
    setCreationResult,
  } = props;
  //
  const [telegram, setTelegram] = useState<string>();
  const [announcementName, setAnnouncementName] = useState<string>();
  const [reason, setReason] = useState<string>("");
  //

  //
  const isValid = useMemo(() => {
    if (reason) {
      return reason.length > 0;
      // &&
      // ((telegram || "").length === 0 || (telegram || "").length > 3)
    }
    //
    return false;
  }, [reason]);
  //

  const [quickResult, setQuickResult] = useState<{
    announcer: string;
    symbol: string;
    name: string;
    total: {
      totalSupply: string;
      totalSupplyDisplay: string;
      sTokens: string;
      whole: string;
      fraction: string;
    };
    warnings: Array<string>;
    hash?: {
      newid: string;
    };
  }>();

  const [warnings, setWarnings] = useState<Array<string>>([]);

  const createAnnouncement = async (isGameFinished: any) => {
    const command_name = "create_announcement";
    const [ch, ad] = reason.split("|");
    //
    const revMap: Record<string, string> = {
      "Ethereum Mainnet": "0x01",
      "Binance Smart Chain Mainnet": "0x38",
      "Matic (Polygon) Mainnet": "0x89",
      "Fantom Mainnet": "0xfa",
      "Cronos Mainnet Beta": "0x19",
    };
    //
    const serialized_proof = Array.from(proof)
      .map((p: number) => (p < 16 ? `0${p.toString(16)}` : p.toString(16)))
      .join("");
    //
    //
    const _post = (
      ro: Record<string, any>
    ): {
      method: string;
      headers: {
        Accept: string;
        "Content-Type": string;
      };
      body: string;
    } => ({
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(ro),
    });

    const hexify = (p: number) =>
      p < 16 ? `0x0${p.toString(16)}` : `0x${p.toString(16)}`;
    //
    const address_safe = !ad || ad === "undefined" ? "" : ad;
    const command_args = {
      chain: revMap[ch] || "0x0",
      address: address_safe,
      tg: (telegram || "").substring(0, 100),
      na: (announcementName || "").substring(0, 100),
      st: clearedDate ? (clearedDate as Date).toISOString() : "",
      from: account,
      identity: identity,
      proof: serialized_proof,
    };
    //
    const api_base_url = API_URL_ROOT;
    const cmd_url = `${api_base_url}/process_cmd`;
    const requestObject = { cmd: command_name, args: command_args };
    //
    L("CreateAnnouncement running");
    //
    const json = requestObject; //{ name, description };
    L("upload json", command_name, command_args, json);

    const currentRequest = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestObject),
    };

    L("CRRR", currentRequest);

    const rawResponse = await fetch(cmd_url, _post(requestObject));
    const content = await rawResponse.json();
    //
    L("API", cmd_url, content);
    //
    const is_success = content.ok;
    //
    if (is_success) {
      Swal.fire(
        "Announcement created succesfully!",
        content.data.address,
        "success"
      );
      //
      const has_warnings = content.data.warnings;
      //
      if (has_warnings) {
        setWarnings(content.data.warnings);
      } else {
        setWarnings([]);
      }
      //

      if (content.data) {
        const hash = content.data.hash.newid;
        const new_url = `https://kyc.foundation/locker?key=${hash}`;
        //
        copyTextToClipboard(new_url);
      }
    } else {
      const nice_error = content
        ? `${content.message}: ${content.params.address}`
        : "";
      Swal.fire("Something went wrong...", JSON.stringify(content), "warning");
    }
    //
    //setQuickResult(content.data);
    setCreationResult(content.data);
    //
    if (is_success) {
      isGameFinished();
    }

    //
    //
    //
    console.log("createAnnouncement finished");
  };
  const onSendButtonClicked = useCallback(() => {
    if (isValid) {
      createAnnouncement(isGameFinished);
    }
  }, [isValid, createAnnouncement, isGameFinished]);
  //
  const onShareButtonClicked = useCallback(() => {
    if (creationResult) {
      L("increasing step on share", creationResult);
      L("hash", creationResult.hash?.newid);
    }
    //
    // copy address to clipboard?
  }, [creationResult]);

  //
  ///
  const [isTelegramAddressUsed, setIsTelegramAddressUsed] =
    useState<boolean>(false);
  const [isUnlockTimerUsed, setIsUnlockTimerUsed] = useState<boolean>(false);
  const [clearedDate, setClearedDate] = React.useState(null);
  // const [value, setValue] = React.useState(new Date("2019-01-01T18:54"));
  //
  const [isDisplayingName, setIsDisplayingName] = useState<boolean>(false);
  const [isDisplayingTelegram, setIsDisplayingTelegram] =
    useState<boolean>(false);
  const [isScheduledAnnouncement, setIsScheduledAnnouncement] =
    useState<boolean>(false);
  //
  //
  //
  const isContractValid = useMemo(() => {
    if (account && identity && reason) {
      L("isValid !, checking contract");
      L("reason", reason); // lets hack and recalc isvalid (reason > 0)
      //
      L("c.address", reason.split("|")[1]);
      //
      // updating quickResult with 'check_address'
      //
      const tkns = reason.split("|");
      const [c, a] = tkns;
      const ch =
        c === "Ethereum Mainnet"
          ? 1
          : c === "Binance Smart Chain Mainnet"
          ? 56
          : c === "Matic (Polygon) Mainnet"
          ? 137
          : c === "Fantom Mainnet"
          ? 250
          : c === "Cronos Mainnet Beta"
          ? 25
          : -1;
      //
      L("NOW fetching data for ", a, "on chain ", c);
      //
      const is_ok_to_call = a.length === 42 && ch !== -1;
      //
      if (is_ok_to_call) {
        //
        // calling api to check address on this chain
        //
        const hexify = (p: number) =>
          p < 16 ? `0x0${p.toString(16)}` : `0x${p.toString(16)}`;
        const serialized_proof = Array.from(proof)
          .map((p: number) => (p < 16 ? `0${p.toString(16)}` : p.toString(16)))
          .join("");
        //
        const api_base_url = API_URL_ROOT;
        const api_url = `${api_base_url}/process_cmd`;
        const command_name = "check_address";
        const registeredUser = {
          chain: hexify(ch),
          address: a,
          from: account,
          identity,
          proof: serialized_proof,
        };
        const requestObject = { cmd: command_name, args: registeredUser };
        //
        console.log("Register user running", registeredUser);
        //
        //
        //
        // const json_filename = "test.json";

        // const ctrl_txt = document.getElementById("input_name");
        // const ctrl_desc = document.getElementById("input_desc");

        // const name = ctrl_txt.value;
        // const description = ctrl_desc.value;

        const json = requestObject; //{ name, description };
        // const s = await fetch('hello.txt').then( res => res.text());
        L("upload registration info", command_name, registeredUser, json);

        // const upload_base_url = 'http://localhost:7777/upload/'
        const upload_url = api_url; //`${upload_base_url}${json_filename}`

        const currentRequest = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // creates a preflight
            // "Content-Type": "text/plain",
          },
          body: JSON.stringify(requestObject),
        };
        //
        // the request is prepared, now async execute and update state on callback
        //
        const async_load = async (
          url: string,
          postData: Record<string, any>
        ) => {
          L("Loading from", url);
          const rawResponse = await fetch(upload_url, postData);
          L("CRRRAW_rsp", rawResponse);
          const content = await rawResponse.json();
          L("CRRRAW_content", content);
          console.log(content);
          //
          // update state
          //
          const is_success = content.ok;
          //
          if (is_success) {
            Swal.fire("Contract found!", content.data.address, "success");
            //
            const has_warnings = content.data.warnings;
            //
            if (has_warnings) {
              setWarnings(content.data.warnings);
            } else {
              setWarnings([]);
            }
          } else {
            Swal.fire(
              "Contract not found on " + reason.split("|")[0],
              content.error.address,
              "warning"
            );
          }
          //
          setQuickResult(content.data);
        };
        //
        (async () => await async_load(upload_url, currentRequest))();

        //
        //
        //
      }
    }
  }, [reason, account, identity]);
  //
  return (
    <div className="form-element">
      <div className="row">
        <div className="col">
          <GroupedTextBox reason={reason} setReason={setReason} />
        </div>
      </div>
      {quickResult ? (
        <div>
          <div className="mt-2">
            <small
              style={{ width: "80px", fontSize: "13px", marginRight: "10px" }}
            >
              Token:
            </small>
            <span style={{ fontSize: "13px" }}>{quickResult.name}</span>
          </div>
          <div className="mt-2">
            <small
              style={{ width: "80px", fontSize: "13px", marginRight: "10px" }}
            >
              T.Supply:
            </small>

            <span style={{ fontSize: "13px" }}>
              {quickResult.total.totalSupplyDisplay.split(" ")[0]}
            </span>
          </div>
          <div className="mt-2">
            <small
              style={{ width: "80px", fontSize: "13px", marginRight: "10px" }}
            >
              Unit:
            </small>
            <span style={{ fontSize: "13px" }}>
              {quickResult.total.totalSupplyDisplay.replace(
                quickResult.total.totalSupplyDisplay.split(" ")[0],
                ""
              )}
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
      {warnings.length ? (
        <div className="alert alert-warning alert-dismissible mt-2">
          <h4>
            <i className="icon fa fa-warning"></i> Something is strange
          </h4>
          {warnings.map((w) => (
            <div className="text" style={{ color: "white" }}>
              {w}
            </div>
          ))}
        </div>
      ) : (
        ""
      )}
      <FormGroup>
        <div
          className="mt-2"
          style={{
            display: "flex",
            alignItems: "center",
            height: "45px",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                defaultChecked={false}
                onChange={() => {
                  setIsDisplayingName(!isDisplayingName);
                }}
              />
            }
            label={
              isDisplayingName
                ? "Displaying name in the header"
                : "Display name in the header?"
            }
          />
          {isDisplayingName ? (
            <div
              className="col"
              style={{
                minWidth: "100px",
                width: "100%",
                display: "inline-block",
                textAlign: "right",
              }}
            >
              <TextField
                name="input-announcement-name"
                variant={"outlined"}
                margin={"dense"}
                label={"Announcement name"}
                fullWidth
                size={"small"}
                value={announcementName}
                placeholder="Announcement name"
                onChange={(e) => setAnnouncementName(e.target.value || "")}
              ></TextField>
            </div>
          ) : (
            ""
          )}
        </div>

        <div
          className="mt-2"
          style={{
            display: "flex",
            alignItems: "center",
            height: "45px",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                defaultChecked={false}
                onChange={() => {
                  setIsDisplayingTelegram(!isDisplayingTelegram);
                }}
              />
            }
            label={
              isDisplayingTelegram
                ? "Displaying Telegram at unlocking"
                : "Display Telegram at unlocking?"
            }
          />
          {isDisplayingTelegram ? (
            <div
              className="col"
              style={{
                minWidth: "100px",
                width: "100%",
                display: "inline-block",
                textAlign: "right",
              }}
            >
              <TextField
                name="input-telegram-group"
                fullWidth
                size={"small"}
                variant={"outlined"}
                margin={"dense"}
                label={"Telegram Group"}
                value={telegram}
                placeholder="Telegram Group"
                onChange={(e) => setTelegram(e.target.value || "")}
              ></TextField>
            </div>
          ) : (
            ""
          )}
        </div>

        <div
          className="mt-2"
          style={{
            display: "flex",
            alignItems: "center",
            height: isScheduledAnnouncement ? "75px" : "45px",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                defaultChecked={false}
                onChange={() => {
                  setIsScheduledAnnouncement(!isScheduledAnnouncement);
                }}
              />
            }
            label={
              isScheduledAnnouncement
                ? "Announcement is scheduled"
                : "Schedule announcement?"
            }
          />
          {isScheduledAnnouncement ? (
            <div
              className="col"
              style={{
                minWidth: "100px",
                width: "100%",
                display: "inline-block",
                textAlign: "right",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  // clearable
                  value={clearedDate}
                  label={"Announcement Time"}
                  onChange={(newValue) => setClearedDate(newValue)}
                  renderInput={(params) => (
                    // <TextField {...params} helperText="Announcement Time" />
                    <TextField {...params} />
                  )}
                />
              </LocalizationProvider>
            </div>
          ) : (
            ""
          )}
        </div>

        <FormControlLabel
          disabled
          control={<Switch />}
          label="Verify ownership (coming soon)"
        />
      </FormGroup>
      <div id="user-announcement-feedback" style={{ display: "none" }}>
        <div className="row">
          <div
            className="col-sm-8 col-8 text-left mb-20 mt-10"
            style={{ float: "left" }}
          >
            aaaaaaaaaa
            <span
              style={{ cursor: "pointer" }}
              onClick={() => setIsTelegramAddressUsed(!isTelegramAddressUsed)}
            >
              {!setIsTelegramAddressUsed ? (
                "No telegramm group provided, click to change."
              ) : (
                <>
                  Announcing telegram:
                  {telegram ? (
                    <b className="text-success" style={{ margin: "2px" }}>
                      https://t.me/{telegram}
                    </b>
                  ) : (
                    "EMPTY"
                  )}
                </>
              )}
            </span>
          </div>
        </div>
        <div className="row">
          <div
            className="col-sm-7 col-7 text-left mb-20 mt-10"
            // style={{ float: "left" }}
          >
            {!isScheduledAnnouncement ? (
              "Announcement starts right away day."
            ) : (
              <>
                Using timer:
                {clearedDate ? (
                  <b className="text-success" style={{ margin: "2px" }}>
                    {JSON.stringify(clearedDate)}
                  </b>
                ) : (
                  "EMPTY"
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {creationResult ? (
        <div className="mt-2">
          <h4>
            <small style={{ width: "80px", marginRight: "10px" }}>
              Announcement created successfully
            </small>
            {/* <small style={{ fontSize: "9px" }}>
              https://kyc.foundation/locker/
            </small>
            <b style={{ fontSize: "14px" }}>{quickResult.hash?.newid}</b> */}
          </h4>
        </div>
      ) : (
        ""
      )}
      <div className="row" style={{ textAlign: "center" }}>
        {!creationResult ? (
          <Button
            type="submit"
            className={isValid ? "btn btn-info" : "btn btn-default"}
            style={{ margin: "auto", backgroundColor: "#8CD4F5" }}
            onClick={onSendButtonClicked}
          >
            Save announcement
          </Button>
        ) : (
          <Button
            type="submit"
            className={isValid ? "btn btn-info" : "btn btn-default"}
            style={{ margin: "auto", backgroundColor: "#8CD4F5" }}
            onClick={onShareButtonClicked}
          >
            Share announcement
          </Button>
        )}
      </div>

      {/* {(quickResult && (quickResult as any)).hash ? (
        <div className="mt-2">
          <h4>
            <small style={{ width: "80px", marginRight: "10px" }}>
              Link created:
            </small>
            <b style={{ fontSize: "14px" }}>
              {(quickResult as any)?.hash?.newid}
            </b>
          </h4>
        </div>
      ) : (
        ""
      )} */}
    </div>
  );
};

//
//
//
const L = console.log;
//
type IpResult = {
  status: string;
  country: string;
  countryCode: string; //  "HU",
  region: string; // "PE",
  regionName: string; // "Pest megye",
  city: string; // "Pecel",
  zip: string; // "2119",
  lat: number; // 47.4873,
  lon: number; // 19.3466,
  timezone: string;
  isp: string;
  org: string;
  as: string;
  query: string; //   "89.133.108.60"
};
//
//
//
function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
//
//
//
const KycAddressLocker = ({
  currentUser, // redux - prop current user
  //
  setCurrentUser,
  //
  onUserLogsIn, // redux action - when user connects wallet
  onUserLogsOut, // redux action - when user disconnects wallet
  onUserRegistersWallet, // redux action - when user registers wallet by signing
}: {
  currentUser: any;
  //
  setCurrentUser: any;
  onUserLogsIn: any;
  onUserLogsOut: any;
  onUserRegistersWallet: any;
}) => {
  const user = currentUser;
  L("RERENDER w user", user);

  L("running KycAddressLocker", "using pars");
  const {
    account,
    activate,
    activateBrowserWallet,
    chainId,
    deactivate,
    library,
  } = useEthers();
  let query = useQuery();
  // const addressFromQuery = query.get("address")
  const hashFromQuery = query.get("key") || "";
  // L("hashFromQuery", hashFromQuery);

  useTitle(`KYC - Unlocking ${hashFromQuery}- KYC.Foundation`);
  //
  const [announcementResult, setAnnouncementResult] = useState<{
    hash: string;
    ok: boolean;
    activated: boolean;
    now: number;
    activates: number;
    announcement: Record<string, any>;
  }>();
  //
  // try fetch params by hash from api
  //
  useEffect(() => {
    if (hashFromQuery) {
      console.log("getAnnouncementByHash", hashFromQuery);

      // const chainId = 137;
      // alert('hello'+chainId)

      const url = `${API_URL_ROOT}/announcement-exists/${hashFromQuery}`;
      console.log("downloading from", url);

      const fetchData = async () => {
        const res = await fetch(url, {
          mode: "cors",
          method: "get",
          // referrerPolicy: "origin",
          // headers: {
          //   "Access-Control-Allow-Origin": "https://kyc.foundation",
          // },
        });
        console.log("res", res);
        const json = await res.json();
        console.log("json", json);
        const ok = json && json.ok;

        const data = json.value.announcement;
        console.log("announcement_by_hash", data, ok);

        setAnnouncementResult(data);
      };

      fetchData();
    }
  }, [hashFromQuery]);

  /*




  TODO: below section should only happen if the announcement exists





  */
  //
  const [graphData, setGraphData] = useState<ForcedGraphEnvelope>(init_data());
  //
  //
  //

  const imgsMemo = useMemo(() => {
    if (graphData.nodes) {
      const imgs = graphData.nodes.slice(0, 1).map((n) => n.img);
      //
      L("adding img", imgs);
      const textures = imgs.map((img) => [
        img,
        new TextureLoader().load(`${img}`),
      ]);
      L("loaded imgs", textures.length);
      return Object.fromEntries(textures);
    }

    //
    return {} as Record<string, Texture>;
  }, [graphData.nodes]);
  //
  //
  //
  const maxTriesToFinishTheGame = 3;
  //
  //
  const [isChallangeShown, setIsChallangeShown] = useState<boolean>(false);
  const [isGameStarted, setIsGameStarted] = useState<boolean>(false);
  const [currentTryIndex, setCurrentTryIndex] = useState<number>(-1); // increase automatically w next_round
  const [challenge, setChallenge] = useState<Array<number>>([1, 27, 45]);
  const [goodTimes, setGoodTimes] = useState<Array<number>>([
    new Date().getTime(),
  ]); // storing ticks of correct clicks
  //
  const [currentClicksOkay, setCurrentClicksOkay] = useState<number>(0); // starts from zero, game ends when 3
  const [currentClicksWrong, setCurrentClicksWrong] = useState<number>(0); // starts from zero, game ends when 3
  const [currentDrags, setCurrentDrags] = useState<number>(0); // starts from zero, game ends when 20
  //
  const signatureMemo = useMemo(() => {
    const arr = [...goodTimes];
    let s = "0x" + arr.map((x) => x.toString(16)).join("");
    //
    while (s.length < 42) {
      s += "0";
    }
    //
    return s;
  }, [goodTimes.length]);
  //
  const signatureDiffs = useMemo(() => {
    const arr = goodTimes.map((gt, idx, arr) =>
      idx === 0 ? 0 : (arr[idx] - arr[idx - 1]) * 0.001
    );

    let s = arr.join(" / ");
    //
    return s;
  }, [goodTimes.length]);
  //
  // 1. start game
  // 2. start round
  // 3. retry same
  // 4. retry new
  // 5. lose_game
  // 6. win_game
  //
  // A. node clicked OK
  // B. node clicked WRONG
  // C. node dragged SCORE
  //
  const startTheGame = () => setIsGameStarted(true);
  //
  const addCorrectGuess = (nodeId: number) => {
    setUserGuesses([...userGuesses, nodeId]);
    setGoodTimes([...goodTimes, new Date().getTime()]);
  };
  //
  const increaseTriesWithOne = () => setCurrentTryIndex(currentTryIndex + 1);
  const increaseDragsByOne = () => setCurrentDrags(currentDrags + 1);
  const increaseClicksOkayByOne = () => {
    L("increasing c OK", currentClicksOkay, currentClicksOkay + 1);

    setCurrentClicksOkay(currentClicksOkay + 1);
  };
  const increaseClicksWrongByOne = () => {
    L("increasing c WRG", currentClicksWrong, currentClicksWrong + 1);
    setCurrentClicksWrong(currentClicksWrong + 1);
  };
  //

  const onGameStarted = () => {
    L("[onGameStarted]", "Welcome to the unlocker");
    //
    if (!isGameStarted) {
      //

      startTheGame(); // can happen only once
      onGameLoop();
      //
      L("The game has just started");
    }
  };
  //
  // main loop, max 3 tries [-1.. 2]
  //
  const onGameLoop = () => {
    increaseTriesWithOne();
    //
    if (currentTryIndex < maxTriesToFinishTheGame) {
      L("Game try: ", currentTryIndex, "/ ", maxTriesToFinishTheGame);
      //
    } else {
      L("The game is over, max tries reached, try again later");
    }
  };
  //
  const [userGuesses, setUserGuesses] = useState<Array<number>>([]);
  const graphActionProps = useMemo(
    () => ({
      addCorrectGuess,
      increaseTriesWithOne,
      increaseDragsByOne,
      increaseClicksOkayByOne,
      increaseClicksWrongByOne,
    }),
    [
      addCorrectGuess,
      increaseTriesWithOne,
      increaseDragsByOne,
      increaseClicksOkayByOne,
      increaseClicksWrongByOne,
    ]
  );
  //
  const [address, setAddress] = useState<string>(
    "0x00000000000000000000000000000"
  );
  //
  useEffect(() => {
    console.log("running effect");
    const rnd_a = Math.floor(Math.random() * Math.pow(2, 16));
    const rnd_b = Math.floor(Math.random() * Math.pow(2, 24));
    const rnd_c = Math.floor(Math.random() * Math.pow(2, 12));
    const dt_a = new Date().getTime();
    //
    const x =
      String.fromCharCode(48) +
      `${"x"}` +
      [rnd_a, 3, rnd_b * rnd_c, 5, dt_a, 7, rnd_a * rnd_c, 13, dt_a / rnd_a]
        .map((x) => Number(x).toString(16))
        .join("")
        .substring(0, 40);
    //
    setAddress(x);
    //
    console.log([rnd_a, rnd_b, rnd_c], [x, x.length]);
  }, [new Date().getSeconds()]);

  //
  const [isGoodJobShown, setIsGoodJobShown] = useState<boolean>(false);
  const isGameFinished = () => {
    const is_ok = currentClicksOkay === 3;
    //

    if (is_ok && !isGoodJobShown) {
      setIsGoodJobShown(true);
      const adr = announcementResult
        ? announcementResult.announcement?.secret || "0x007"
        : "0x042";
      copyTextToClipboard(adr);
      // 0x6282548c702c11c386a6517a9ed41f4f6c03c0cd
      Swal.fire("Good job!", adr, "success");
    }
    //
    return is_ok;
  };
  //
  //
  //

  const currentStep: number = useMemo(() => {
    let stp = 0;
    //
    // should depend on annonuncer, if wallet identification is required or not
    //
    const logged_in = (account || "") !== "";
    // const logged_in = true; // (account || "") !== "";
    //
    if (logged_in) {
      stp++;
      //
      // if (isChallangeShown) stp++;
      // if (isGameStarted) stp++;
      if (isGameFinished()) stp++;
    }
    //
    return stp;
  }, [account, isChallangeShown, isGameStarted, isGameFinished]);

  //
  // auto - start the game if cp =2 and there is a user
  //
  useEffect(() => {
    if (account && currentStep === 1) {
      setIsGameStarted(true);
    }
    //
    return () => console.log("no effect removal for gamestart");
  }, [account, currentStep]);
  /*




  TODO: above section should only happen if the announcement exists





  */

  //
  //
  //
  const [creationResult, setCreationResult] = useState<{
    announcer: string;
    symbol: string;
    name: string;
    is_instant: boolean;
    activates: number;
    remains: number;
    labels: {
      name: string;
      telegram: string;
    };
    total: {
      totalSupply: string;
      totalSupplyDisplay: string;
      sTokens: string;
      whole: string;
      fraction: string;
    };
    warnings: Array<string>;
    hash?: {
      newid: string;
    };
  }>();
  //
  //
  //
  const [signedMessageFromCache, setSignedMessageFromCache] = useState("");
  const [signedMessage, setSignedMessage] = useState("");
  const [ipResult, setIpResult] = useState<IpResult>();
  const [registrationSecond, setRegistrationSecond] = useState<number>();
  // const [currentSecond, setCurrentSecond] = useState<number>();
  //
  // load the cookie if exists from localstorage
  //
  useEffect(() => {
    const fromCache = localStorage.getItem("kyc_cookie") || "";
    //
    if (fromCache) {
      setSignedMessageFromCache(fromCache);
    }
  }, []);
  //
  // save the cookie after the user signed the message
  //
  useEffect(() => {
    if (signedMessage) {
      L("stored s");
      localStorage.setItem("kyc_cookie", signedMessage);
    }
  }, [signedMessage]);
  //

  //
  /*
  const updateClock = () => {
    L("There is a brand new second");
    const ticks = new Date().getTime();
    //
    setCurrentSecond(ticks);
    //
  };
  useEffect(() => {
    let intervalID: NodeJS.Timer;
    //
    intervalID = setInterval(() => updateClock(), 1000);

    return () => clearInterval(intervalID);
  }, []);
  */
  // <i class="flag-icon flag-icon-hu" title="hu" id="hu"></i>
  //
  // ----------------------------------------------------------------------------------------
  // const [lastAccount, setLastAccount] = useState<string>();

  // ----------------------------------------------------------------------------------------
  const [isConnected, setIsConnected] = useState<boolean>(false); // connected via either wallet or b.wallet
  const [isWalletConnected, setIsWalletConnected] = useState<boolean>(false);
  const [isBrowserWalletConnected, setIsBrowserWalletConnected] =
    useState<boolean>(false);
  //
  useEffect(() => {
    if (!isConnected) {
      L("removed s. no connection");
      localStorage.removeItem("kyc_cookie");
    }
    if (!account) {
      L("removed s. no user");
      localStorage.removeItem("kyc_cookie");
    }
  }, [isConnected, account]);
  //
  //
  //
  const [checkInRegAnswer, setCheckInRegAnswer] = useState<
    boolean | undefined
  >();
  const [checkOutRegAnswer, setCheckOutRegAnswer] = useState<
    boolean | undefined
  >();

  const onNotifyApiAboutUserLogin = useCallback(() => {
    //
    // account is 'current user'
    // user is 'previous user'
    //
    const is_valid = account !== null;
    //
    if (is_valid) {
      L("onNotifyApiAboutUserLogin", account, user);
      //
      // calling: /api/users/:address/is_registered
      //
      const eoa = (account || "").toLowerCase();
      const url = `${API_URL_ROOT}/users/${eoa}/is_registered_user`;
      console.log("downloading from", url);

      const fetchDataCheckIn = async () => {
        const res = await fetch(url, {
          mode: "cors",
          method: "get",
          // referrerPolicy: "origin",
          // headers: { "Access-Control-Allow-Origin": "https://kyc.foundation" },
        });
        console.log("res", res);
        const json = await res.json();
        console.log("json", json);
        const ok = json && json.ok;

        // setTokenList(data as Array<any>);
        const is_registered = json.registered;
        console.log("check_in_result", ok, "was reg?", is_registered);
        //
        setCheckInRegAnswer(is_registered);
        //alert("now storing to redux" + is_registered);
      };

      fetchDataCheckIn();
      //
      //alert(`acc [${account}] changed, is reg?`);
    } else {
      // alert(`acc [${account}] invalid, cannot check-in.`);
    }
  }, [user, account]);
  //
  //
  //
  const onNotifyApiAboutUserLougout = useCallback(() => {
    //
    // account is 'current user'
    // user is 'previous user'
    //
    const is_valid = user !== null;
    //
    if (is_valid) {
      L("onNotifyApiAboutUserLougout", user, "is about to log out");
      const lo_account = user.account || "";
      const is_logout_account_valid = lo_account.length === 42;
      //
      if (is_logout_account_valid) {
        // alert(`previous acc is logging out [${lo_account}].`);
        //
        // calling: /api/users/:address/check_out
        //
        const eoa = (lo_account || "").toLowerCase();
        const url = `${API_URL_ROOT}/users/${eoa}/check_out`;
        console.log("downloading from", url);

        const fetchDataCheckOut = async () => {
          const res = await fetch(url, {
            mode: "cors",
            method: "get",
            // referrerPolicy: "origin",
            // headers: { "Access-Control-Allow-Origin": "https://kyc.foundation" },
          });
          console.log("res", res);
          const json = await res.json();
          console.log("json", json);
          const ok = json && json.ok;

          const was_registered = json.registered;
          console.log("check_out_result", ok, "was reg?", was_registered);

          setCheckOutRegAnswer(was_registered); // actually unused
        };

        fetchDataCheckOut();
      } else {
        // alert(`invalid prev acc, malformed call [${lo_account}].`);
      }
    } else {
      // alert(`prev acc [${account}] invalid, cannot check-out.`);
    }
  }, [user, account]);

  //
  useEffect(() => {
    //
    // todo: handle state change
    //
    if (account) {
      onNotifyApiAboutUserLogin();
      //
      onUserLogsIn({
        chainId: (chainId || "0")?.toString(16),
        is_connected: true,
        // is_remote
        is_registered: false,
        account: account,
        footprint: "",
        identity: "",
        proof: "",
      });

      setIsConnected(true);
    } else {
      onNotifyApiAboutUserLougout();
      //
      onUserLogsOut(null); // consider using 'current state . user'
      //
      L(
        "new connection state: ",
        isWalletConnected,
        "+",
        isBrowserWalletConnected,
        "->",
        isWalletConnected || isBrowserWalletConnected
      );
      //
      //
      // new connection state:  true + false -> true
      if (isWalletConnected) {
        // new connection via wallet-connect

        //setIsBrowserWalletConnected(false); // force meta mask to disconnect
        onDisconnectUsingBrowserWallet(); // force meta mask to disconnect
      }
      //
      if (isBrowserWalletConnected) {
        // new connection via meta-mask

        // setIsWalletConnected(false); // force wallet-connect to disconnect
        onDisconnectUsingWallet(); // force meta mask to disconnect
      }
      //
      if (!isBrowserWalletConnected && !isWalletConnected) {
        if (account) {
          L("The user is connected magically via metamask");
          setIsBrowserWalletConnected(true);
        } else {
          L("Neither connection nor user.");
        }
      } else {
        //
        const newConnectionState =
          isWalletConnected || isBrowserWalletConnected;

        //
        setIsConnected(newConnectionState);
      }
    }
  }, [isWalletConnected, isBrowserWalletConnected, account]);
  //

  // ----------------------------------------------------------------------------------------
  async function onConnectUsingWallet() {
    try {
      // const provider = new WalletConnectProvider({
      //   infuraId: "a787de38c9fa4e4f94481db6ccf78502",
      // });
      const provider = new WalletConnectProvider({
        rpc: {
          1: "https://mainnet-nethermind.blockscout.com",
          56: "https://bsc-dataseed1.binance.org",
        },
        chainId: 1,
      });

      await provider.enable();
      await activate(provider);
      //
      L("onConnectUsingWallet");
      //
      setIsWalletConnected(true);
    } catch (error) {
      console.error(error);
    }
  }

  async function onDisconnectUsingWallet() {
    deactivate();
    L("onDisconnectUsingWallet");
    localStorage.removeItem("walletconnect");
    localStorage.removeItem("kyc_cookie");

    setRegistrationSecond(undefined);
    setSignedMessage("");
    setSignedMessageFromCache("");
    setIsWalletConnected(false);
  }
  // ----------------------------------------------------------------------------------------
  async function onConnectUsingBrowserWallet() {
    try {
      /*
      const provider = new WalletConnectProvider({
        infuraId: "a787de38c9fa4e4f94481db6ccf78502",
      });
      */
      //await provider.enable();
      //await activate(provider);
      L("onConnectUsingBrowserWallet");
      //
      if (!account) {
        //
        // only try if there is no active connection via mobile
        //
        try {
          activateBrowserWallet(); // not async ?
          //
          setIsBrowserWalletConnected(account !== undefined);
        } catch (error) {
          if (error && (error as any).message) {
            const emsg = (error as any).message || "UNKNOWN";

            switch (emsg) {
              case "No injected provider available":
                {
                  alert(
                    "Unable to detect your browser wallet, maybe you are not signed in to your wallet, or using an incognito window "
                  );
                }
                break;
              //
              default: {
                console.error(
                  "ERROR",
                  error,
                  "when trying to connect browser wallet"
                );
              }
            }
            console.error(
              "METAMASK ERROR",
              error,
              "when trying to connect browser wallet"
            );
          }
        }
      } else {
        // ??? already logged in when method called
        setIsBrowserWalletConnected(true);
      }

      //
    } catch (error) {
      console.error(error);
    }
  }
  async function onDisconnectUsingBrowserWallet() {
    deactivate();
    L("onDisconnectUsingBrowserWallet");

    localStorage.removeItem("kyc_cookie");
    //localStorage.removeItem("walletconnect");
    setRegistrationSecond(undefined);
    setSignedMessage("");
    setSignedMessageFromCache("");
    setIsBrowserWalletConnected(false);
  }

  // ----------------------------------------------------------------------------------------
  const ipHashMemo = useMemo(() => {
    if (ipResult) {
      const prs = [2, 3, 5, 7];
      const ipt = (ipResult?.query || "0.0.0.0")
        .split(".")
        .map((t, idx) => parseInt(t) * prs[idx]);
      const E = (ipt.reduce((a, b) => a * b, 1) || 0).toString(16);
      //
      return E;
    }
    //
    return (0).toString(16);
  }, [ipResult]);

  var data = "I hereby register to KYC.Foundation by signing this message.";
  const proofMemo = useMemo(() => {
    const msgHash = ethUtil.hashPersonalMessage(Buffer.from(data));
    //
    return msgHash;
  }, [data]);

  // const regMemo = useMemo(() => {
  //   const A = signedMessage.substring(2);
  //   const A2 = Array.from(proofMemo)
  //     .map((v, i) => v.toString(16))
  //     .join("");
  //   const B = (currentSecond || 0)
  //     .toString(16)
  //     .substring(2)
  //     .split("")
  //     .reverse()
  //     .join("");
  //   const C = (blockNumber || 0).toString(16);
  //   const D = (chainId || 0).toString(16);
  //   const E = ipHashMemo;

  //   let registrationBinary = `${A2}000000${A}000000${B}000000${C}000000${D}000000${E}`;
  //   //
  //   // L("REGINFO", { A, A2, B, C, D, E });
  //   //
  //   return registrationBinary.padEnd(256, "0");
  // }, [ipResult, signedMessage, currentSecond, blockNumber, ipHashMemo]);

  // const geoMemo = useMemo(() => {
  //   if (ipResult) {
  //     const { query, country, countryCode, region, lat, lon } = ipResult;
  //     //
  //     const cc = (countryCode || "").toLowerCase();
  //     const cn =
  //       chainId === 1
  //         ? "ETH"
  //         : chainId === 25
  //         ? "CRO"
  //         : chainId === 56
  //         ? "BNB"
  //         : chainId === 137
  //         ? "MATIC"
  //         : chainId === 250
  //         ? "FTM"
  //         : "???";
  //     //

  //     // const A = signedMessage.substring(2);
  //     // const B = (currentSecond || 0)
  //     //   .toString(16)
  //     //   .substring(2)
  //     //   .split("")
  //     //   .reverse()
  //     //   .join("");
  //     // const C = (blockNumber || 0).toString(16);
  //     // const registrationBinary = `${A}0000${B}0000${B}0000${C}`;
  //     //
  //     return (
  //       <div className="box box-body bg-hexagons-dark pull-up">
  //         {signedMessage ? (
  //           <div>
  //             Registration attempt <b className="text-green">SUCCESSFUL</b> from{" "}
  //             {query} ...
  //           </div>
  //         ) : (
  //           <div>
  //             Registration attempt <span className="text">initiated</span> from{" "}
  //             {query} ...
  //           </div>
  //         )}

  //         <div className="media align-items-center p-0">
  //           <div className="text-center">
  //             <a href="#">
  //               <i className={`cc ${cn}-alt mr-5`} title={cn}></i>
  //             </a>
  //           </div>
  //           <div>
  //             {signedMessage ? (
  //               <>
  //                 <DataCanvas
  //                   width={200}
  //                   height={100}
  //                   data_string={signedMessage.substring(2)}
  //                   scale={10}
  //                 />
  //                 Your signature.
  //                 <br />
  //                 <DataCanvas
  //                   width={200}
  //                   height={100}
  //                   data_string={regMemo}
  //                   scale={10}
  //                 />
  //                 Your registration data.
  //                 <br />
  //                 We have saved: <br />
  //                 <ul>
  //                   <li>
  //                     Your <span className="text-green">valid signature</span>,
  //                     to be sure, You are You 😉
  //                   </li>
  //                   <li>
  //                     Your preferred chain,{" "}
  //                     <b className="text-brown">
  //                       {chainId === 1
  //                         ? "Ethereum Mainnet"
  //                         : chainId === 56
  //                         ? "Binance Smart Chain"
  //                         : chainId === 137
  //                         ? "Polygon Mainnet"
  //                         : chainId === 250
  //                         ? "Fantom Mainnet"
  //                         : `Chain: ${chainId}]`}
  //                     </b>
  //                   </li>
  //                   <li>
  //                     Your country of origin, {country} (
  //                     <b className="text-purple">{countryCode}</b>)
  //                   </li>
  //                   <li>
  //                     The time on your device, when you registered.{" "}
  //                     {
  //                       <b className="text-orange">
  //                         {
  //                           new Date(registrationSecond || 0)
  //                             .toISOString()
  //                             .split("T")[1]
  //                         }
  //                       </b>
  //                     }
  //                   </li>
  //                   <li>
  //                     instead your IP, a checksum what was{" "}
  //                     <span className="text-blue">{ipHashMemo}</span> (
  //                     {parseInt(ipHashMemo, 16)})
  //                   </li>
  //                 </ul>
  //                 This was a one time action, and no on-chain transactions were
  //                 made.
  //                 <br />
  //                 Thank you for choosing us.
  //               </>
  //             ) : (
  //               <>
  //                 <h3 className="no-margin text-bold">
  //                   {"Awaiting user signature"}
  //                 </h3>
  //                 <i className={`flag-icon flag-icon-${cc}`} title={cc}></i>
  //                 <span>
  //                   Please check your wallet, and confirm your registration
  //                 </span>
  //               </>
  //             )}
  //           </div>
  //         </div>
  //         <div className="flexbox align-items-center mt-25">
  //           <div>
  //             <p className="no-margin font-weight-600">
  //               {registrationSecond ? (
  //                 <span className="text-orange">
  //                   {
  //                     new Date(registrationSecond || 0)
  //                       .toISOString()
  //                       .split("T")[1]
  //                   }
  //                 </span>
  //               ) : (
  //                 " - "
  //               )}
  //             </p>
  //             <p className="no-margin">Block:{blockNumber && blockNumber}</p>
  //           </div>
  //           <div className="text-right">
  //             <p className="no-margin font-weight-600">
  //               <span className="text">
  //                 {new Date(currentSecond || 0).toISOString().split("T")[1]}
  //               </span>
  //             </p>
  //             <p className="no-margin">
  //               {" "}
  //               {timestamp &&
  //                 new Date(Number(timestamp))
  //                   .toISOString()
  //                   .split("T")[1]
  //                   .split(".")[0]}{" "}
  //             </p>
  //           </div>
  //         </div>
  //       </div>
  //     );
  //   }

  //   //
  //   return "";
  // }, [ipResult, signedMessage, currentSecond, blockNumber, regMemo, proofMemo]);

  const onSign = useCallback(async () => {
    L("running onSign");
    const provider = library as Web3Provider;

    //const res = await fetch("http://ip-api.com/json");
    //const json = await res.json();

    const json = {
      status: "",
      country: "",
      countryCode: "??",
      region: "",
      regionName: "",
      city: "",
      zip: "",
      lat: 0,
      lon: 0,
      timezone: "",
      isp: "",
      org: "",
      as: "",
      query: "0.0.0.0",
    };
    //
    setIpResult(json);
    //
    const data = "I hereby register to KYC.Foundation by signing this message.";
    //
    try {
      const signer = provider.getSigner();
      const signer_a = await signer.getAddress();
      const signedMsg = await signer.signMessage(data);
      //
      const msgHash = ethUtil.hashPersonalMessage(Buffer.from(data));
      const sigDecoded = ethUtil.fromRpcSig(signedMsg);
      //
      //
      // Recover Address
      // L("signer", signer, "msg", signedMsg);
      // L("from", signer_a, "msghash", msgHash);
      // L("RECOVERY");
      // L("FROM", signedMsg);
      // L("sigDC", sigDecoded);
      //
      const hexify = (p: number) =>
        p < 16 ? `0x0${p.toString(16)}` : `0x${p.toString(16)}`;
      const identity = signedMsg;
      const serialized_proof = Array.from(proofMemo)
        .map((p: number) => (p < 16 ? `0${p.toString(16)}` : p.toString(16)))
        .join("");
      const footprint = `0x${ipHashMemo}`;
      const lang = ipResult?.countryCode || "";

      const api_base_url = API_URL_ROOT;
      const api_url = `${api_base_url}/process_cmd`;
      const command_name = "register_user";
      const registeredUser = {
        ...currentUser,
        chain: hexify(currentUser.chainId),
        from: currentUser.account,
        identity,
        proof: serialized_proof,
        footprint,
        lang,
      };
      const requestObject = { cmd: command_name, args: registeredUser };
      //
      console.log("Register user running", registeredUser);
      //
      const json = requestObject; //{ name, description };
      L("upload registration info", command_name, registeredUser, json);

      const upload_url = api_url;
      const currentRequest = {
        // mode: "cors",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // creates a preflight
        },
        body: JSON.stringify(requestObject),
      };

      L("CRRR", currentRequest);
      const rawResponse = await fetch(upload_url, currentRequest as any);
      L("CRRRAW_rsp", rawResponse);
      const content = await rawResponse.json();
      L("CRRRAW_content", content);
      console.log(content);

      onUserRegistersWallet(registeredUser);

      var recoveredPub = ethUtil.ecrecover(
        msgHash,
        sigDecoded.v,
        sigDecoded.r as Buffer,
        sigDecoded.s as Buffer
      );
      L("recPub", recoveredPub);

      var recoveredAddress = ethUtil.publicToAddress(recoveredPub, true); //.toString("hex");
      // L("recAddr", recoveredPub, 'add', recoveredAddress);

      // var sender = ethUtil.publicToAddress(publicKey);
      var addr = ethUtil.bufferToHex(recoveredAddress);
      //
      // L("MAGIC", data, message, msgHash, sigParams, publicKey);
      L("MAGIC-v2", recoveredPub, "ra", recoveredAddress, "addr", addr);
      //
      setSignedMessage(signedMsg);
      setSignedMessageFromCache(signedMsg);
      setRegistrationSecond(new Date().getTime());
    } catch (error) {
      console.error(error);
    }
  }, [ipHashMemo, ipResult, library, currentUser]);
  //

  //useTitle("KYC - Create Announcement - KYC.Foundation");
  //
  //

  // const [isGoodJobShown, setIsGoodJobShown] = useState<boolean>(false);
  // //
  // //
  // //
  // const startTheGame = () => setIsGameStarted(true);
  // //
  // //
  // //
  // const onGameStarted = () => {
  //   L("[onGameStarted]", "Welcome to the unlocker");
  //   //
  //   if (!isGameStarted) {
  //     //
  //     startTheGame(); // can happen only once
  //     //
  //     L("The game has just started");
  //   }
  // };
  //
  //
  // const isGameFinished = () => {
  //   const is_ok = true; // currentClicksOkay === 3;
  //   //

  //   if (is_ok && !isGoodJobShown) {
  //     setIsGoodJobShown(true);
  //     // copyTextToClipboard(address);
  //     // Swal.fire("Good job!", address, "success");
  //   }
  //   //
  //   return is_ok;
  // };
  //
  //

  /*
  const currentStep: number = useMemo(() => {
    let stp = 0;
    //
    // should depend on annonuncer, if wallet identification is required or not
    //
    const logged_in = currentUser !== null; // (account || "") !== "";
    //
    //
    //
    if (logged_in) {
      stp++;
      //
      if (isChallangeShown) stp++;
      if (isGameStarted) stp++;
      if (isGameFinished()) stp++;
      //
      if (creationResult) {
        const hash = creationResult ? creationResult.hash?.newid : "";
        const is_valid = hash !== "";
        //
        if (is_valid) stp++;
      }
    }
    //
    L("currentStep: ", stp);
    //
    return stp;
  }, [
    account,
    isChallangeShown,
    isGameStarted,
    isGameFinished,
    currentUser,
    creationResult,
  ]);
  */
  //
  //
  //
  const renderStep0 = (
    chainId: number | undefined,
    account: string | null | undefined
  ) => {
    return (
      <>
        {/* <h3>Step 0 - Connect your wallet</h3> */}
        <div className="box-body" style={{ minWidth: "400px" }}>
          <div style={{ textAlign: "center" }}>
            <Button
              style={{
                backgroundColor: "#8CD4F5",
              }}
              onClick={
                account
                  ? onDisconnectUsingBrowserWallet
                  : onConnectUsingBrowserWallet
              }
            >
              <img
                src="../metamask.png"
                style={{ height: "20px", width: "20px", marginRight: "6px" }}
                alt="Connect using MetaMask"
              />{" "}
              {account ? "Disconnect 💻 MetaMask" : "MetaMask"}
            </Button>{" "}
            &nbsp;{" "}
            <Button
              style={{
                backgroundColor: "#8CD4F5",
              }}
              onClick={account ? onDisconnectUsingWallet : onConnectUsingWallet}
            >
              <img
                src="../walletconnect.png"
                style={{ height: "20px", width: "20px", marginRight: "6px" }}
                alt="Connect using WalletConnect"
              />{" "}
              {account ? "Disconnect 📱 wallet" : "WalletConnect"}
            </Button>
          </div>
        </div>
      </>
    );
  };
  //
  //
  //
  const renderStep2 = (
    chainId: number | undefined,
    account: string | null | undefined
  ) => {
    const cn =
      chainId === 1
        ? "ETH"
        : chainId === 25
        ? "CRO"
        : chainId === 56
        ? "BNB"
        : chainId === 137
        ? "MATIC"
        : chainId === 250
        ? "FTM"
        : "???";
    //
    return (
      <>
        {/* <h3>Step 2 - Register or Sign In</h3> */}
        {/* <div className="box box-body"> */}
        <div>
          <div>
            {/* {account ? (
              <>
                <div>
                  Connected on {!isBrowserWalletConnected ? "💻" : "📱"} as{" "}
                  {_as_smart_stripe(2, account, 10, 10, false)}, via{" "}
                  <i
                    className={`cc ${cn}-alt mr-5`}
                    title={cn}
                    style={{ zoom: "0.5" }}
                  ></i>
                  .
                  <Button
                    onClick={async () => {
                      if (isWalletConnected) {
                        await onDisconnectUsingWallet();
                      } else {
                        await onDisconnectUsingBrowserWallet();
                      }
                    }}
                  >
                    Sign out
                  </Button>
                </div>
                {geoMemo}
                {(window as any)?.WAValidator.validate(account, "Ethereum") ? (
                  <>
                    Your wallet address appears{" "}
                    <b className="text-green">VALID</b>
                  </>
                ) : (
                  "Your wallet address appears INVALID"
                )}
              </>
            ) : (
              ""
            )} */}
            {/* <div>
              {signedMessageFromCache ? (
                <>You're already logged in.</>
              ) : (
                "No cookie found."
              )}
            </div> */}
            {signedMessageFromCache ? (
              <>
                <div style={{ textAlign: "center" }}>
                  {!isChallangeShown ? (
                    user && user.identity !== "" ? (
                      <Button
                        style={{
                          margin: "auto",
                          backgroundColor: "#8CD4F5",
                        }}
                        onClick={() => setIsChallangeShown(true)}
                      >
                        Create Announcement
                      </Button>
                    ) : (
                      <Button
                        style={{ backgroundColor: "#8CD4F5" }}
                        onClick={onSign}
                      >
                        Login
                      </Button>
                    )
                  ) : (
                    <>
                      <svg width="400" height="110" style={{ margin: "auto" }}>
                        <rect
                          width="100"
                          height="100"
                          style={{
                            fill: "rgb(255,0,0)",
                            strokeWidth: 3,
                            stroke: "rgb(0,0,0)",
                          }}
                        />
                        <polygon
                          points="200,10 250,110 160,110"
                          style={{
                            fill: "rgb(0,0,255)",
                            stroke: "purple",
                            strokeWidth: 1,
                          }}
                        />
                        <circle
                          cx="350"
                          cy="50"
                          r="40"
                          stroke="black"
                          strokeWidth="3"
                          fill="lime"
                        />
                      </svg>
                      <Button
                        style={{
                          backgroundColor: "#8CD4F5",
                        }}
                        onClick={() => {
                          // executeScroll();
                          onGameStarted();
                        }}
                      >
                        Start the game
                      </Button>
                    </>
                  )}
                </div>
              </>
            ) : (
              <div style={{ textAlign: "center" }}>
                {user && user.proof === "" && checkInRegAnswer === true ? (
                  <Button
                    style={{ backgroundColor: "#8CD4F5" }}
                    onClick={onSign}
                  >
                    Login
                  </Button>
                ) : (
                  <Button
                    style={{ backgroundColor: "#8CD4F5" }}
                    onClick={onSign}
                  >
                    Register
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </>
    );
  };
  //
  //
  //
  const renderStep3 = (creationResult: any, setCreationResult: any) => {
    return <>{/* <h3>Step 3 - Create Announcement</h3> */}</>;
  };
  //
  //
  //
  const onFormReset = useCallback(() => {
    if (creationResult) {
      L("onFormReset running", creationResult);
      //
      setIsChallangeShown(false);
      setIsGameStarted(false);
      //
      L("last name: ", creationResult ? creationResult.labels.name : " --- ");
      //
      setCreationResult(undefined); // self-destruct
    }
  }, [creationResult]);
  //
  const { screenSizeName, windowSize } = useWindowSize();
  //
  //
  //
  const drawSvg = () => (
    <svg width="400" height="110" style={{ margin: "auto", zoom: "0.2" }}>
      <rect
        width="100"
        height="100"
        style={{
          fill: "rgb(255,0,0)",
          strokeWidth: 3,
          stroke: "rgb(0,0,0)",
        }}
      />
      <polygon
        points="200,10 250,110 160,110"
        style={{
          fill: "rgb(0,0,255)",
          stroke: "purple",
          strokeWidth: 1,
        }}
      />
      <circle
        cx="350"
        cy="50"
        r="40"
        stroke="black"
        strokeWidth="3"
        fill="lime"
      />
    </svg>
  );
  //
  const maxW = windowSize ? windowSize.width : 600;
  const maxH = windowSize ? windowSize.height : 400;
  const graphWidth =
    screenSizeName === ""
      ? 600
      : screenSizeName === "mini"
      ? Math.min(maxW - (windowSize.width < 400 ? 155 : 180), 1000)
      : screenSizeName === "small"
      ? Math.min(maxW - (windowSize.width < 578 ? 180 : 250), 1000)
      : screenSizeName === "normal"
      ? Math.min(maxW - 490, 1000)
      : screenSizeName === "large"
      ? Math.min(maxW - 480, 1000)
      : Math.min(maxW * 0.8, 1000);
  const graphHeight = Math.max(400, maxH * 0.5); // graphWidth * 1.2;

  //
  return (
    <section className="content">
      <div
        className="col-xl-12 col-12"
        //style={{ maxWidth: "1200px", minWidth: "460px", margin: "auto" }}
        style={{ maxWidth: "1200px", minWidth: "360px", margin: "auto" }}
      >
        <div className="box">
          <div className="box-header with-border">
            <h4 className="box-title">
              The bot-proof announcement tool
              {/* {maxW}x{maxH} ({screenSizeName}) */}
              {announcementResult &&
              announcementResult.announcement?.name !== "" ? (
                <h6 className="box-subtitle" style={{ maxWidth: "250px" }}>
                  {announcementResult.announcement?.name}
                </h6>
              ) : undefined}
            </h4>

            <div
              style={{
                float: "right",
                width: "100px",
                maxHeight: "20px",
              }}
            >
              {/* {(window as any)?.WAValidator.validate(account, "Ethereum") ? (
                  <>
                    Your wallet address appears{" "}
                    <b className="text-green">VALID</b>
                  </>
                ) : (
                  "Your wallet address appears INVALID"
                )} */}
              {account ? (
                <Button
                  style={{
                    backgroundColor: "#8CD4F5",
                    position: "relative",
                    float: "right",
                    top: "-4px",
                    // height: "12px",
                  }}
                  onClick={async () => {
                    if (isWalletConnected) {
                      await onDisconnectUsingWallet();
                    } else {
                      await onDisconnectUsingBrowserWallet();
                    }
                  }}
                >
                  Sign out
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="box-body">
            <div className="vtabs">
              <ul className="nav nav-tabs tabs-vertical" role="tablist">
                <li className="nav-item">
                  {" "}
                  <a
                    className={
                      currentStep === 0 ? "nav-link active" : "nav-link"
                    }
                    data-toggle="tab"
                    href="#home4"
                    role="tab"
                    aria-selected="false"
                  >
                    <span className="hidden-sm-up">
                      <i className="ion-home"></i>
                    </span>{" "}
                    <span className="hidden-xs-down">Welcome</span>{" "}
                  </a>{" "}
                </li>
                <li className="nav-item">
                  {" "}
                  <a
                    className={
                      currentStep === 1 ? "nav-link active" : "nav-link"
                    }
                    data-toggle="tab"
                    href="#profile4"
                    role="tab"
                    aria-selected="false"
                  >
                    <span className="hidden-sm-up">
                      <i className="ion-person"></i>
                    </span>{" "}
                    <span className="hidden-xs-down">Challenge</span>
                  </a>{" "}
                </li>
              </ul>

              <div
                className="tab-content pt-0"
                style={{
                  width: currentStep === 1 && account ? "100%" : "auto",
                }}
              >
                <div
                  className={currentStep === 0 ? "tab-pane active" : "tab-pane"}
                  id="home4"
                  role="tabpanel"
                >
                  <div className="px-15 pt-0">
                    <h3>
                      Welcome to Know Your Contract Foundation’s bot-proof
                      announcement tool!
                    </h3>
                    <p>
                      To keep those nasty bots away, we designed a simple yet
                      effective entry challenge.
                    </p>
                    <p>
                      It is language-independent, color-blind assisted, and
                      takes only seconds, ensuring an equal chance for everyone.
                    </p>
                    <p>
                      {currentStep === 0 ? (
                        <>
                          <p>You are two single steps away.</p>

                          <p>To proceed, please connect your wallet*!</p>
                          {renderStep0(chainId, account)}
                        </>
                      ) : (
                        <p style={{ padding: "10px" }}>
                          You are already signed in.
                        </p>
                      )}
                      {/* currentStep === 1 || currentStep === 2
                        ? renderStep2(chainId, account)
                        : `cs: ${currentStep}`} */}
                      <br />
                      <small style={{ fontSize: "11px" }}>
                        * Non-custodial. No worries, no chain transaction will
                        be made, EVER.
                      </small>
                    </p>
                  </div>
                </div>
                <div
                  className={currentStep === 1 ? "tab-pane active" : "tab-pane"}
                  id="profile4"
                  role="tabpanel"
                  style={{ width: "100%" }}
                >
                  <div
                    className="px-15 pt-0"
                    style={{ width: "100%", overflow: "hidden" }}
                  >
                    <div style={{ width: "100%" }}>
                      <h3 style={{ float: "left" }}>Click shapes to unlock!</h3>
                      <div style={{ float: "right" }}>{drawSvg()}</div>
                      <div style={{ clear: "both" }}></div>
                    </div>

                    {isGameStarted && !isGameFinished() ? (
                      <div
                        style={{
                          // border: "solid 1px black",
                          // width: "auto",
                          // maxWidth: "1000px",
                          // height: "auto",
                          width: `${graphWidth + 20}px`,
                          height: `${graphHeight + 20}px`,
                          // height: "430px",
                          margin: "auto",
                        }}
                      >
                        {graphData.nodes && Object.keys(imgsMemo).length ? (
                          <LockerGraph
                            txmemo={imgsMemo}
                            graphData={graphData as any}
                            userGuesses={userGuesses}
                            graphActionProps={graphActionProps}
                            graphWidth={graphWidth}
                            graphHeight={graphHeight}
                          />
                        ) : (
                          "Loading..."
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {/* {currentStep === 0
                      ? renderStep0(chainId, account)
                      : currentStep === 1 || currentStep === 2
                      ? renderStep2(chainId, account)
                      : currentStep === 3
                      ? "You are already registered."
                      : `cs: ${currentStep}`} */}

                    {/* <small style={{ fontSize: "11px" }}>
                        * A single checksum is generated and stored in a cookie.
                      </small> */}
                  </div>
                </div>
              </div>
              <div style={{ alignItems: "end", float: "right" }}>
                {screenSizeName === "mini" ||
                screenSizeName === "small" ||
                screenSizeName === "normal" ? (
                  <></>
                ) : currentStep === 0 || !account ? (
                  <img
                    src={"./john_smith.png"}
                    alt="Welcome"
                    style={{
                      float: "right",
                      height: "100%",
                      minWidth: screenSizeName === "large" ? "200px" : "360px",
                      maxWidth: screenSizeName === "large" ? "266px" : "480px",
                    }}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {announcementResult ? JSON.stringify(announcementResult) : "Loading..."} */}
    </section>
  );
};

// export default KycHosted;

const mapStateToProps = ({
  user,
}: {
  user: {
    currentUser: {
      chain: string;
      account: string;
      //
      is_connected: boolean;
      is_registered: boolean;
      //
      lang?: string;
      footprint?: string;
      //
      identity?: string;
      proof?: string;
    };
  };
}) => ({
  currentUser: user.currentUser,
});

const mapDispatchToProps = (dispatch: any) => ({
  setCurrentUser: (user: any) => dispatch(setCurrentUser(user)),
  onUserLogsIn: (user: any) => dispatch(onUserLogsIn(user)),
  onUserLogsOut: (user: any) => dispatch(onUserLogsOut(user)),
  onUserRegistersWallet: (user: any) => dispatch(onUserRegistersWallet(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(KycAddressLocker);

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";
//
import { BigNumber } from "ethers";
//
import {
  useBlockMeta,
  useBlockNumber,
  useEthers,
  useGasPrice,
} from "@usedapp/core";
//
import useCoinGeckoPrices from "../../../hooks/useCoinGeckoPrices";
// import useTransactionMonitor from "../../../hooks/useTransactionMonitor";
// import useWindowSize from "../../../hooks/useWindowSize";
import { _render_chain_card } from "../commons";
import { API_URL_ROOT } from "../../../settings";

//
//
//
const L = console.log;

const GroupedTextBox = (props: {
  reason: string;
  setReason: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { reason, setReason } = props;
  //
  const [group, setGroup] = useState<string>("");
  const [text, setText] = useState<string>();
  //
  const options = [
    "Rug pull",
    "Honey pot",
    "Liquidity gone",
    "Mass blacklisting",
  ];
  //
  useEffect(() => {
    setReason(`${group}|${text}`);
  }, [group, text]);
  //
  return (
    <div className="form-control input-group input-group-lg">
      <div className="input-group-prepend">
        <button
          type="button"
          className="btn btn-success dropdown-toggle"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          Reason
        </button>
        <div className="dropdown-menu" style={{ willChange: "transform" }}>
          {options.map((opt) => (
            <a className="dropdown-item" onClick={() => setGroup(opt)}>
              {opt}
            </a>
          ))}
          <div className="dropdown-divider"></div>
          <a className="dropdown-item" onClick={() => setGroup("other")}>
            Other
          </a>
        </div>
        <input
          type="text"
          className="form-control"
          value={text}
          style={{ paddingLeft: "6px" }}
          onChange={(e) => setText(e.target.value)}
        />
      </div>
    </div>
  );
};
//
//
//

const ReportScamContractForm = () => {
  const [address, setAddress] = useState<string>();
  const [reason, setReason] = useState<string>("");
  //
  const isValid = useMemo(() => {
    if (address && reason) {
      return address.length > 0 && reason.length > 0;
    }
    //
    return false;
  }, [address, reason]);
  //

  const sendScamContract = () => {
    const command_name = "report_scam";
    const command_args = { address, reason };
    //
    const api_base_url = API_URL_ROOT; 
    const api_url = `${api_base_url}/process_cmd`;
    const requestObject = { cmd: command_name, args: command_args };
    //
    console.log("sendScamContract running");
    //
    //
    //
    const json_filename = "test.json";

    // const ctrl_txt = document.getElementById("input_name");
    // const ctrl_desc = document.getElementById("input_desc");

    // const name = ctrl_txt.value;
    // const description = ctrl_desc.value;

    const json = requestObject; //{ name, description };
    // const s = await fetch('hello.txt').then( res => res.text());
    L("upload json", command_name, command_args, json);

    const xhr = new XMLHttpRequest();
    // const upload_base_url = 'http://localhost:7777/upload/'
    const upload_url = api_url; //`${upload_base_url}${json_filename}`

    L("xhr", upload_url);

    xhr.addEventListener(
      "onprogress",
      function (e: any) {
        if (e.lengthComputable) {
          const percentage = Math.round((e.loaded * 100) / e.total);
          L("json %", percentage);
        }
      },
      false
    );

    xhr.addEventListener(
      "onload",
      function (e) {
        L("json load", e);
      },
      false
    );

    L("xhr posting json", xhr);

    xhr.onreadystatechange = () => {
      //Call a function when the state changes.
      if (xhr.readyState == 4 && xhr.status == 200) {
        L("RESP-json", xhr.responseText);
      }
    };

    //const str2blob = txt => new Blob([txt]);
    const jsonSerialized = JSON.stringify(json);
    const jsonBlob = new Blob([jsonSerialized], {
      type: "application/javascript",
    });

    console.log("JSON-blob", jsonBlob);

    const jsonFile = new File([jsonBlob], json_filename);

    xhr.open("POST", upload_url, true);
    xhr.setRequestHeader("Content-type", "application/javascript");
    xhr.send(jsonFile);

    //
    //
    //
    console.log("sendScamContract finished");
  };
  const onSendButtonClicked = useCallback(() => {
    if (isValid) {
      sendScamContract();
    }
  }, [isValid, sendScamContract]);
  //
  return (
    <div className="box">
      <div className="box-header with-border">
        <h4 className="box-title">Report a Contract as Scam</h4>
      </div>
      <div className="box-body form-element">
        <div className="row">
          <div className="col-sm-7">
            <GroupedTextBox reason={reason} setReason={setReason} />
          </div>
          <div className="col-sm-4">
            <input
              type="text"
              className="form-control"
              placeholder="contact address"
              onChange={(e) => setAddress(e.target.value || "")}
            />
          </div>
          <div
            className="col-sm-1"
            style={{ maxWidth: "40px", height: "46px" }}
          >
            <button
              type="submit"
              className={isValid ? "btn btn-info" : "btn btn-default"}
              style={{ height: "46px" }}
              onClick={onSendButtonClicked}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

//
//
//
const ReportScamContract = () => {
  const { chainId } = useEthers();
  const blockNumber = useBlockNumber();
  const { timestamp, difficulty } = useBlockMeta();
  const GP = useGasPrice();
  L("GP________", (GP?.toNumber() || 0) * Math.pow(10, -9));
  // const windowSize = useWindowSize();
  //
  // const { watch, gasPrice, gasPriceDistribution } = useTransactionMonitor();
  //
  const [thePriceOfEth, setThePriceOfEth] = useState<number>(0);
  const { getEthereumPriceFromCoinGecko } = useCoinGeckoPrices();
  //
  useEffect(() => {
    const loadGecko = async () => {
      const gecko = await getEthereumPriceFromCoinGecko(); // loading gecko prices
      const e = parseInt((gecko as any)["eth"]);
      //
      setThePriceOfEth(e);
    };
    //
    loadGecko();
    //
    return () => {};
  }, []);
  //
  //
  //
  return (
    <section className="content">
      <ReportScamContractForm />
      <div style={{ display: "none" }}>
        {chainId &&
          _render_chain_card({
            chainId,
            blockNumber,
            difficulty,
            timestamp,
            thePriceOfEth,
          })}
      </div>
    </section>
  );
};

export default ReportScamContract;

import SearchActionTypes from "./search.types";

export const INITIAL_STATE = {
  is_advanced: false,
  lastSearch: null,
  urlBase: null,
  searchHistory: [] as Array<any>,
};

const searchReducer = (
  state = INITIAL_STATE,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case SearchActionTypes.TOGGLE_SEARCH_MODE:
      return {
        ...state,
        is_advanced: !state.is_advanced,
      };
    case SearchActionTypes.START_SEARCH:
      return {
        ...state,
        lastSearch: { hash: action.payload.hash, status: 1 },
        urlBase: action.payload.url_base,
      };
    case SearchActionTypes.STOP_SEARCH:
      return {
        ...state,
        lastSearch: null,
        urlBase: null,
      };
    case SearchActionTypes.ON_SEARCH_FAILED:
      return {
        ...state,
        lastSearch: { ...(state.lastSearch || {}), status: 0 },
        urlBase: null,
      };
    case SearchActionTypes.ON_SEARCH_SUCCESS:
      const is_cached = state.searchHistory.includes(action.payload);
      //
      return {
        ...state,
        searchHistory: is_cached
          ? state.searchHistory
          : [...state.searchHistory, action.payload],
        lastSearch: { ...(state.lastSearch || {}), status: 2 },
        // urlBase:  ???  consider ''
      };
    default:
      return state;
  }
};

export default searchReducer;

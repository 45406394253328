//
// ERC1967Proxy - TransparentUpgradeableProxy
//
export const ERC1967_PROXY_V0_INTERFACE = [
  {
    type: "nonpayable",
    hex: "0x3659cfe6",
    name: "upgradeTo",
    signature: "upgradeTo(address)",
  },
  {
    type: "payable",
    hex: "0x4f1ef286",
    name: "upgradeToAndCall",
    signature: "upgradeToAndCall(address,bytes)",
  },
  {
    type: "nonpayable",
    hex: "0x5c60da1b",
    name: "implementation",
    signature: "implementation()",
  },
  {
    type: "nonpayable",
    hex: "0x8f283970",
    name: "changeAdmin",
    signature: "changeAdmin(address)",
  },
  {
    type: "nonpayable",
    hex: "0xf851a440",
    name: "admin",
    signature: "admin()",
  },
];

//
// erc 20 ISH interface ( 7 rules)
//
export const ERC_20_ISH_INTERFACE = [
  {
    type: "nonpayable",
    hex: "0x095ea7b3",
    name: "approve",
  },
  //
  {
    type: "view",
    hex: "0x06fdde03",
    name: "name",
  },
  {
    type: "view",
    hex: "0x95d89b41",
    name: "symbol",
  },
  {
    type: "view",
    hex: "0x18160ddd",
    name: "totalSupply",
  },
  {
    type: "view",
    hex: "0x313ce567",
    name: "decimals",
  },
  {
    type: "view",
    hex: "0xdd62ed3e",
    name: "allowance",
  },
  {
    type: "view",
    hex: "0x70a08231",
    name: "balanceOf",
  },
];

//
// erc 20 interface ( 11 rules )
//
export const ERC_20_INTERFACE = [
  {
    type: "nonpayable",
    hex: "0x095ea7b3",
    name: "approve",
  },
  {
    type: "nonpayable",
    hex: "0xa457c2d7",
    name: "decreaseAllowance",
  },
  {
    type: "nonpayable",
    hex: "0x39509351",
    name: "increaseAllowance",
  },
  {
    type: "nonpayable",
    hex: "0xa9059cbb",
    name: "transfer",
  },
  {
    type: "nonpayable",
    hex: "0x23b872dd",
    name: "transferFrom",
  },
  //
  {
    type: "view",
    hex: "0x06fdde03",
    name: "name",
  },
  {
    type: "view",
    hex: "0x95d89b41",
    name: "symbol",
  },
  {
    type: "view",
    hex: "0x18160ddd",
    name: "totalSupply",
  },
  {
    type: "view",
    hex: "0x313ce567",
    name: "decimals",
  },
  {
    type: "view",
    hex: "0xdd62ed3e",
    name: "allowance",
  },
  {
    type: "view",
    hex: "0x70a08231",
    name: "balanceOf",
  },
];

// V 0x3eaaf86b	_totalSupply	1.000000000000000000
export const ERC_20_TSUPPLY_V01_INTERFACE = [
  {
    type: "view",
    hex: "0x3eaaf86b",
    name: "_totalSupply",
    signature: "_totalSupply()",
    returns: "uint256",
  },
];

export const ERC_20_MULTIAPPROVE_V00_INTERFACE = [
  {
    type: "nonpayable",
    hex: "0x50e8587e",
    name: "multiApprove",
    signature: "multiApprove(address[],uint256[])",
    input_types: ["address[]", "uint256[]"],
    output_aliases: ["addresses", "amounts"],
  },
  {
    type: "nonpayable",
    hex: "0x72a7b8ba",
    name: "multiDecreaseApproval",
    signature: "multiDecreaseApproval(address[],uint256[])",
    input_types: ["address[]", "uint256[]"],
    output_aliases: ["addresses", "amounts"],
  },
  {
    type: "nonpayable",
    hex: "0x035f057d",
    name: "multiIncreaseApproval",
    signature: "multiIncreaseApproval(address[],uint256[])",
    input_types: ["address[]", "uint256[]"],
    output_aliases: ["addresses", "amounts"],
  },
];

export const ERC_20_QUERY_V00_INTERFACE = [
  {
    type: "view",
    hex: "0x5c658165",
    name: "allowed",
    signature: "allowed(address,address)",
  },
  {
    type: "view",
    name: "balances",
    hex: "0x27e235e3",
    signature: "balances(address)",
  },
];

export const ERC_20_QUERY_V01_INTERFACE = [
  {
    type: "view",
    hex: "0x7f2feddc",
    name: "_buyMap",
    signature: "_buyMap(address)",
  },
];

export const QUERY_BOTS_V00_INTERFACE = [
  {
    type: "view",
    hex: "0xbfd79284",
    name: "bots",
    signature: "bots(address)",
  },
];

export const ERC_20_SERVICECONTRACT_INTERFACE = [
  // https://img-blog.csdnimg.cn/20190315110639341.png
  {
    type: "nonpayable",
    hex: "0xcae9ca51",
    name: "approveAndCall",
    signature: "approveAndCall(address,uint256,bytes)",
    src: `function approveAndCall(address _spender, uint256 _value, bytes _extraData) returns (bool success) {
      allowed[msg.sender][_spender] = _value;
      Approval(msg.sender, _spender, _value);
  
      if(!_spender.call(bytes4(bytes32(sha3("receiveApproval(address,uint256,address,bytes)"))), msg.sender, _value, this, _extraData)) { throw; }
          return true;
      }`,
    description: `usage of approveAndCall:
After the method, the entire operation process is as follows:
The user is in the token contract (
StarCoin
) Authorized a token to the business contract (
BuyCoffee
), through the token contract
approveAndCall
method;
The token contract informs the business contract that it has been authorized to operate the user’s token, by calling the business contract’s
receiveApproval
method;
The business contract can transfer the user's token to itself, and then complete the relevant business logic (for example, transfer coffee to the user, or do some transfer operations yourself).`,
  },
];

export const ACCESSCONTROL_V0_INTERFACE = [
  // {
  //     "type": "view",
  //     "hex": "0xa217fddf",
  //     "name": "DEFAULT_ADMIN_ROLE"
  // },
  // {
  //     "type": "view",
  //     "hex": "0xe63ab1e9",
  //     "name": "PAUSER_ROLE"
  // },
  {
    type: "nonpayable",
    hex: "0x2f2ff15d",
    name: "grantRole",
  },
  {
    type: "view",
    hex: "0x91d14854",
    name: "hasRole",
  },

  {
    type: "nonpayable",
    hex: "0x36568abe",
    name: "renounceRole",
  },
  {
    type: "nonpayable",
    hex: "0xd547741f",
    name: "revokeRole",
  },
];
//
export const ACCESSCONTROL_ROLE_ADMIN_INTERFACE = [
  {
    type: "view",
    hex: "0xa217fddf",
    name: "DEFAULT_ADMIN_ROLE",
  },
  // {
  //     "type": "view",
  //     "hex": "0xe63ab1e9",
  //     "name": "PAUSER_ROLE"
  // },

  {
    type: "view",
    hex: "0x248a9ca3",
    name: "getRoleAdmin",
  },
];
export const ACCESSCONTROL_ROLE_PAUSER_INTERFACE = [
  {
    type: "view",
    hex: "0xe63ab1e9",
    name: "PAUSER_ROLE",
  },
];

export const ACCESSCONTROL_ROLE_MINTER_INTERFACE = [
  {
    type: "view",
    hex: "0xd5391393",
    name: "MINTER_ROLE",
  },
];

export const SAFEMATH_V0_INTERFACE = [
  {
    type: "pure",
    hex: "0xe6cb9013",
    name: "safeAdd",
    signature: "safeAdd(uint256,uint256)",
  },
  {
    type: "pure",
    hex: "0xb5931f7c",
    name: "safeDiv",
    signature: "safeDiv(uint256,uint256)",
  },
  {
    type: "pure",
    hex: "0xd05c78da",
    name: "safeMul",
    signature: "safeMul(uint256,uint256)",
  },
  {
    type: "pure",
    hex: "0xa293d1e8",
    name: "safeSub",
    signature: "safeSub(uint256,uint256)",
  },
];

export const UNISWAP_V2_INTERFACE = [
  {
    type: "view",
    hex: "0x49bd5a5e",
    name: "uniswapV2Pair",
    signature: "uniswapV2Pair()",
  },
  {
    type: "view",
    hex: "0x1694505e",
    name: "uniswapV2Router",
    signature: "uniswapV2Router()",
  },
];

export const UNISWAP_V2B_INTERFACE = [
  {
    type: "view",
    hex: "0xa8aa1b31",
    name: "pair",
    signature: "pair()",
  },
  {
    type: "view",
    hex: "0xf887ea40",
    name: "router",
    signature: "router()",
  },
];

export const UNISWAP_IDEX_INTERFACE = [
  {
    type: "view",
    hex: "0xf242ab41",
    name: "dexPair",
    signature: "dexPair()",
  },
  {
    type: "view",
    hex: "0x0758d924",
    name: "dexRouter",
    signature: "dexRouter()",
  },
];

export const GENERATOR_COPYRIGHT_V0_INTERFACE = [
  {
    type: "pure",
    hex: "0x7afa1eed",
    name: "generator",
    signature: "generator()",
    returns: "string",
  },
  {
    type: "view",
    hex: "0x54fd4d50",
    name: "version",
    signature: "unversion()",
    returns: "string",
  },
];
export const GENERATOR_COPYRIGHT_V1_INTERFACE = [
  // Metacrypt
  {
    type: "pure",
    hex: "0xc2e7d95c",
    name: "createdByMetacrypt",
    signature: "createdByMetacrypt()",
  },
  {
    type: "view",
    hex: "0x34b1a50c",
    name: "_GENERATOR",
    signature: "_GENERATOR()",
  },
  {
    type: "view",
    hex: "0x49b0db14",
    name: "_VERSION",
    signature: "_VERSION()",
  },
  {
    type: "view",
    hex: "0xaa23e03d",
    name: "getIdentifier",
    signature: "getIdentifier()",
  },
];
//
export const AIRDROP_V0_INTERFACE = [
  {
    type: "nonpayable",
    hex: "0x67243482",
    name: "airdrop",
    sign: "airdrop(address[],uint256[])",
  },
];

// 0x8ba4cc3c	airdrop	airdrop(address,uint256)
// 0xd4a3883f	airdropArray	airdropArray(address[],uint256[])
export const AIRDROP_V1_INTERFACE = [
  {
    type: "nonpayable",
    hex: "0x8ba4cc3c",
    name: "airdrop",
    sign: "airdrop(address,uint256)",
  },
  {
    type: "nonpayable",
    hex: "0xd4a3883f",
    name: "airdropArray",
    sign: "airdropArray(address[],uint256[])",
  },
];

export const OWNABLE_INTERFACE = [
  {
    type: "view",
    hex: "0x8da5cb5b",
    name: "owner",
    signature: "owner()",
    returns: "address",
  },
  {
    type: "nonpayable",
    hex: "0x715018a6",
    name: "renounceOwnership",
    signature: "renounceOwnership()",
  },
  {
    type: "nonpayable",
    hex: "0xf2fde38b",
    name: "transferOwnership",
    signature: "transferOwnership(address)",
  },
];

// 0x880ad0af	transferOwnership	transferOwnership(
export const OWNER_RESET_INTERFACE = [
  {
    type: "nonpayable",
    hex: "0x880ad0af",
    name: "transferOwnership",
    signature: "transferOwnership()",
    src: `
    // prerequisite:
    function renounceOwnership() public virtual onlyOwner {
      _previousOwner = _owner;
      emit OwnershipTransferred(_owner, address(0));
      _owner = address(0);
  }

  // action:
  function transferOwnership() public virtual {
      require(_previousOwner == msg.sender, "You don't have permission to unlock");
      emit OwnershipTransferred(_owner, _previousOwner);
      _owner = _previousOwner;
  }
    `,
  },
];

export const LOCKABLE_INTERFACE = [
  {
    type: "view",
    hex: "0xb6c52324",
    name: "geUnlockTime",
    signature: "geUnlockTime()",
    returns: "uint256",
    src: `function geUnlockTime() public view returns (uint256) {
      return _lockTime;
  }`,
  },
  {
    type: "nonpayable",
    hex: "0xdd467064",
    name: "lock",
    signature: "lock(uint256)",
    src: `function lock(uint256 time) public virtual onlyOwner {
      _previousOwner = _owner;
      _owner = address(0);
      _lockTime = block.timestamp + time;
      emit OwnershipTransferred(_owner, address(0));
  }`,
  },
  {
    type: "nonpayable",
    hex: "0xa69df4b5",
    name: "unlock",
    signature: "unlock()",
    src: `//Unlocks the contract for owner when _lockTime is exceeds
    function unlock() public virtual {
        require(_previousOwner == msg.sender, "You don't have permission to unlock");
        require(block.timestamp > _lockTime , "Contract is locked until 7 days");
        emit OwnershipTransferred(_owner, _previousOwner);
        _owner = _previousOwner;
    }`,
  },
];

/*
   
*/
export const COOLDOWN_INTERFACE = [
  {
    type: "nonpayable",
    hex: "0x5932ead1",
    name: "setCooldownEnabled",
    signature: "setCooldownEnabled(bool)",
    src: `
    function setCooldownEnabled(bool onoff) external onlyOwner() {
      cooldownEnabled = onoff;
  }
  // Cooldown
    require(amount <= _maxTxAmount);
    require(cooldown[to] < block.timestamp);
    cooldown[to] = block.timestamp + (30 seconds);
    `,
  },
];
export const BURNABLE_INTERFACE = [
  {
    type: "nonpayable",
    hex: "0x42966c68",
    name: "burn",
    signature: "burn(uint256)",
    src: ` calls: 
    /**
     * @dev Internal function that burns an amount of the token of a given
     * account.
     * @param account The account whose tokens will be burnt.
     * @param value The amount that will be burnt.
     */
 function _burn(address account, uint256 value) internal {
  require(account != address(0));

  _totalSupply = _totalSupply.sub(value);
  _balances[account] = _balances[account].sub(value);
  emit Transfer(account, address(0), value);
}
    `,
  },
];
export const MINTABLE_INTERFACE = [
  {
    type: "nonpayable",
    hex: "0x40c10f19",
    name: "mint",
    signature: "mint(address,uint256)",
    src: ` unhealthy for erc20`,
  },
];
export const MINTSTOP_INTERFACE = [
  {
    type: "view",
    hex: "0x05d2035b",
    name: "mintingFinished",
    signature: "mintingFinished()",
  },
  {
    type: "nonpayable",
    hex: "0x7d64bcb4",
    name: "finishMinting",
    signature: "finishMinting()",
  },
];
export const MINTCAP_INTERFACE = [
  {
    type: "view",
    hex: "0x0cfccc83",
    name: "SUPPLY_CAP",
    signature: "SUPPLY_CAP()",
    returns: "amount",
    src: `function mint(address account, uint256 amount)
    external
    override
    onlyOwner
    returns (bool status)
{
    if (totalSupply() + amount <= _SUPPLY_CAP) {
        _mint(account, amount);
        return true;
    }
    return false;
}`,
  },
];
export const BURNFROM_INTERFACE = [
  {
    type: "nonpayable",
    hex: "0x79cc6790",
    name: "burnFrom",
    signature: "burnFrom(address,uint256)",
    src: ` calls: 
     @dev Destroys [amount] tokens from [account], deducting from the callers
     allowance.
    
     See {ERC20-_burn} and {ERC20-allowance}.
    
     Requirements:
    
     - the caller must have allowance for [accounts]s tokens of at least
     [amount].

   function burnFrom(address account, uint256 amount) public virtual {
       uint256 currentAllowance = allowance(account, _msgSender());
       require(currentAllowance >= amount, "ERC20: burn amount exceeds allowance");
       unchecked {
           _approve(account, _msgSender(), currentAllowance - amount);
       }
       _burn(account, amount);
   }`,
  },
];

export const PAUSABLE_INTERFACE = [
  {
    type: "view",
    hex: "0x5c975abb",
    name: "paused",
    signature: "paused()",
    returns: "bool",
  },
  {
    type: "nonpayable",
    hex: "0x8456cb59",
    name: "pause",
    signature: "pause()",
  },
  {
    type: "nonpayable",
    hex: "0x3f4ba83a",
    name: "unpause",
    signature: "unpause()",
  },
];

export const STOPPABLE_INTERFACE = [
  {
    type: "view",
    hex: "0x75f12b21",
    name: "stopped",
    sign: "stopped()",
  },
  {
    type: "nonpayable",
    hex: "0x07da68f5",
    name: "stop",
    sign: "stop()",
  },
  {
    type: "nonpayable",
    hex: "0xbe9a6555",
    name: "start",
    sign: "start()",
  },
];

export const REVEAL_URI_INTERFACE = [
  // 0x51830227	revealed	TRUE
  // 0xfe2c7fee	setUnrevealedURI	setUnrevealedURI(string)
  /*
  abstract contract Revealable is NFTArtGen {
      bool public revealed = false;
      string internal uriNotRevealed;

      function setUnrevealedURI(string memory _uri) public onlyOwner {
          uriNotRevealed = _uri;
      }

      function reveal() public onlyOwner {
          revealed = true;
      }
  }
  */
  {
    type: "view",
    hex: "0x51830227",
    name: "revealed",
    signature: "revealed()",
    returns: "bool",
  },
  {
    type: "nonpayable",
    hex: "0xfe2c7fee",
    name: "setUnrevealedURI",
    signature: "setUnrevealedURI(string)",
  },
];

export const REFLECTABLE_INTERFACE = [
  {
    type: "view",
    hex: "0x4549b039",
    name: "reflectionFromToken",
    signature: "reflectionFromToken(uint256,bool)",
    returns: "uint256",
    src: `
    function reflectionFromToken(uint256 tAmount, bool deductTransferFee) public view returns(uint256) {
      require(tAmount <= _tTotal, "Amount must be less than supply");
      if (!deductTransferFee) {
          (uint256 rAmount,,,,,) = _getValues(tAmount);
          return rAmount;
      } else {
          (,uint256 rTransferAmount,,,,) = _getValues(tAmount);
          return rTransferAmount;
      }
  }
    `,
  },
  {
    type: "view",
    hex: "0x2d838119",
    name: "tokenFromReflection",
    signature: "tokenFromReflection(uint256)",
    returns: "uint256",
    src: `
    function tokenFromReflection(uint256 rAmount) public view returns(uint256) {
      require(rAmount <= _rTotal, "Amount must be less than total reflections");
      uint256 currentRate =  _getRate();
      return rAmount.div(currentRate);
  }
    `,
  },
];

export const DEFENSE_TYPE_V0_INTERFACE = [
  {
    type: "view",
    hex: "0x3bbac579",
    name: "isBot",
    signature: "isBot(address)",
    returns: "bool",
  },
  {
    type: "nonpayable",
    hex: "0x4303443d",
    name: "addBotToBlackList",
    signature: "addBotToBlackList(address)",
  },
  {
    type: "nonpayable",
    hex: "0x7ded4d6a",
    name: "removeBotFromBlackList",
    signature: "removeBotFromBlackList(address)",
  },
];

export const DEFENSE_TYPE_V1_INTERFACE = [
  {
    type: "nonpayable",
    hex: "0x0eca1180",
    name: "setBot",
    signature: "setBot(address[])",
  },
  {
    type: "nonpayable",
    hex: "0x273123b7",
    name: "delBot",
    signature: "delBot(address)",
  },
];

export const DEFENSE_TYPE_V2_INTERFACE = [
  {
    type: "view",
    hex: "0x3bbac579",
    name: "isBot",
    signature: "isBot(address)",
    returns: "bool",
  },
  {
    type: "nonpayable",
    hex: "0xe1165545",
    name: "setAntibot",
    signature: "setAntibot(address,bool)",
  },
  {
    type: "nonpayable",
    hex: "0xb31470a4",
    name: "bulkAntiBot",
    signature: "bulkAntiBot(address[],bool)",
  },
];

export const DEFENSE_TYPE_V3_INTERFACE = [
  {
    type: "view",
    hex: "0xbfd79284",
    name: "bots",
    signature: "bots(address)",
    returns: "bool",
  },
  {
    type: "nonpayable",
    hex: "0x00b8cf2a",
    name: "blockBots",
    signature: "blockBots(address[])",
  },
  {
    type: "nonpayable",
    hex: "0x6b999053",
    name: "unblockBot",
    signature: "unblockBot(address)",
  },
];

export const DEFENSE_TYPE_V4_INTERFACE = [
  {
    type: "view",
    hex: "0xd00efb2f",
    name: "launchBlock",
    signature: "launchBlock()",
    returns: "number", // block number
  },
  {
    type: "nonpayable",
    hex: "0x8f70ccf7",
    name: "setTrading",
    signature: "setTrading(bool)",
  },
];

export const DEFENSE_TYPE_V5_INTERFACE = [
  {
    type: "view",
    hex: "0x1e8c811a",
    name: "_limitonbuys",
    signature: "_limitonbuys()",
    return: "bool",
  },
  {
    type: "nonpayable",
    hex: "0xa70ff5f1",
    name: "limitonbuys",
    signature: "limitonbuys(bool)",
  },
];

export const DEFENSE_TYPE_V6_INTERFACE = [
  {
    type: "view",
    hex: "0xf9f92be4",
    name: "launchBlock",
    signature: "blacklist(address)",
    returns: "bool",
  },
  {
    type: "nonpayable",
    hex: "0x153b0d1e",
    name: "setBlacklist",
    signature: "setBlacklist(address,bool)",
  },
];

export const DEFENSE_TYPE_V7_INTERFACE = [
  {
    type: "nonpayable",
    hex: "0xb515566a",
    name: "setBots",
    signature: "setBots(address[])",
  },
  {
    type: "nonpayable",
    hex: "0x273123b7",
    name: "delBot",
    signature: "delBot(address)",
  },
];

export const DEFENSE_TYPE_V8_INTERFACE = [
  {
    type: "view",
    hex: "0x60d48489",
    name: "getBotWalletStatus",
    signature: "getBotWalletStatus(address)",
  },
  {
    type: "nonpayable",
    hex: "0x2a360631",
    name: "addBotWallet",
    signature: "addBotWallet(address)",
  },
  {
    type: "nonpayable",
    hex: "0x0305caff",
    name: "removeBotWallet",
    signature: "removeBotWallet(address)",
  },
];

export const DEFENSE_TYPE_V9_INTERFACE = [
  {
    type: "view",
    hex: "0xc41ba810",
    name: "antiSnipe",
    signature: "antiSnipe()",
    returns: "bool",
  },
  {
    type: "nonpayable",
    hex: "0x725e0769",
    name: "setAntiSnipe",
    signature: "setAntiSnipe(bool)",
  },
  {
    type: "nonpayable",
    hex: "0x0305caff",
    name: "removeBotWallet",
    signature: "removeBotWallet(address)",
  },
];

export const DEFENSE_TYPE_V10_INTERFACE = [
  {
    type: "view",
    hex: "0x0f3a325f",
    name: "isSniper",
    signature: "isSniper(address)",
    returns: "bool",
  },
  {
    type: "nonpayable",
    hex: "0x5e80148e",
    name: "setSnipers",
    signature: "setSnipers(address[])",
  },
  {
    type: "nonpayable",
    hex: "0x892ba408",
    name: "delSnipers",
    signature: "delSnipers(address[])",
  },
];

export const DEFENSE_TYPE_V11_INTERFACE = [
  {
    type: "view",
    hex: "0xbf1ee017",
    name: "PCS",
    signature: "PCS()",
    returns: "address", // being watched by speedbrake
  },
  {
    type: "nonpayable",
    hex: "0xfe22c82d",
    name: "setUniswapAddress_forspeedbrake",
    signature: "setUniswapAddress_forspeedbrake(address[])",
  },
  // ...
];
//

export const DEFENSE_TYPE_V12_INTERFACE = [
  {
    type: "view",
    hex: "0x1cdd3be3",
    name: "_isBlacklisted",
    signature: "_isBlacklisted(address)",
    returns: "bool",
  },
  {
    type: "nonpayable",
    hex: "0xff897570",
    name: "addToBlackList",
    signature: "addToBlackList(address[])",
  },
  {
    type: "nonpayable",
    hex: "0x4a49ac4c",
    name: "removeFromBlackList",
    signature: "removeFromBlackList(address)",
  },
];
//
//
//

// export const FREEZABLE_INTERFACE = [
export const DEFENSE_TYPE_V13A_INTERFACE = [
  {
    type: "view",
    hex: "0xe5839836",
    name: "isFrozen",
    signature: "isFrozen(address)",
    returns: "bool",
  },
  {
    type: "nonpayable",
    hex: "0x8d1fdf2f",
    name: "freeze",
    signature: "freeze(address)",
  },
  {
    type: "nonpayable",
    hex: "0x45c8b1a6",
    name: "unfreeze",
    signature: "unfreeze()",
  },
];
export const DEFENSE_TYPE_V13B_INTERFACE = [
  {
    type: "view",
    hex: "0x8111f24e",
    name: "isFreezed",
    signature: "isFreezed(address)",
    returns: "bool",
  },
  {
    type: "nonpayable",
    hex: "0x8d1fdf2f",
    name: "freeze",
    signature: "freeze(address)",
  },
  {
    type: "nonpayable",
    hex: "0x45c8b1a6",
    name: "unfreeze",
    signature: "unfreeze()",
  },
];

export const DEFENSE_TYPE_V14_INTERFACE = [
  {
    type: "view",
    hex: "0xe960bb48",
    name: "balanceOfLocked",
    signature: "balanceOfLocked(address)",
    returns: "uint256 balance",
  },
  {
    type: "nonpayable",
    hex: "0x17be3fa5",
    name: "lockToken",
    signature: "lockToken(address,uint256)",
  },
  {
    type: "nonpayable",
    hex: "0x0ad436f5",
    name: "lockTokenWhole",
    signature: "lockTokenWhole(address)",
  },
  {
    type: "nonpayable",
    hex: "0x31e9e9c0",
    name: "unlockToken",
    signature: "unlockToken(address,uint256)",
  },
];
export const DEFENSE_TYPE_V15_INTERFACE = [
  {
    type: "view",
    hex: "0x9caf9b00",
    name: "isInBlacklist",
    signature: "isInBlacklist(address)",
    returns: "bool",
  },
  {
    type: "nonpayable",
    hex: "0xb41c9eda",
    name: "setBlacklistWallet",
    signature: "setBlacklistWallet(address, bool)",
  },
];

export const DEFENSE_TYPE_V16_INTERFACE = [
  {
    type: "nonpayable",
    hex: "0x1d7ef879",
    name: "addBotToBlacklist",
    signature: "addBotToBlacklist(address)",
  },
  {
    type: "nonpayable",
    hex: "0xb030b34a",
    name: "removeBotFromBlacklist",
    signature: "removeBotFromBlacklist(address)",
  },
];

export const DEFENSE_TYPE_V17_INTERFACE = [
  {
    type: "nonpayable",
    hex: "0x1b3f71ae",
    name: "nonosquare",
    signature: "nonosquare(address[])",
    src: `
    function nonosquare(address[] memory bots_) public onlyOwner {
      for (uint i = 0; i < bots_.length; i++) {
          bots[bots_[i]] = true;
      }
  }`,
  },
  {
    type: "nonpayable",
    hex: "0x273123b7",
    name: "delBot",
    signature: "delBot(address)",
  },
];

export const DEFENSE_TYPE_V18_INTERFACE = [
  {
    type: "nonpayable",
    hex: "0x18082924",
    name: "setMsg",
    signature: "setMsg(address[])",
    src: `
    function nonosquare(address[] memory bots_) public onlyOwner {
      for (uint i = 0; i < bots_.length; i++) {
          bots[bots_[i]] = true;
      }
  }`,
  },
];

export const DEFENSE_TYPE_V19_INTERFACE = [
  {
    type: "view",
    hex: "0x88f82020",
    name: "isExcluded",
    signature: "isExcludedFromReward(address)",
    returns: "bool",
  },
  {
    type: "nonpayable",
    hex: "0x52390c02",
    name: "excludeFromReward",
    signature: "excludeFromReward(address)",
    src: `function excludeFromReward(address account) public onlyOwner() {
      require(account != 0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D, 'We can not exclude Uniswap router.');
      // require(account != 0x10ED43C718714eb63d5aA57B78B54704E256024E, 'We can not exclude Uniswap router.');
      require(!_isExcluded[account], "Account is already excluded");
      if(_rOwned[account] > 0) {
          _tOwned[account] = tokenFromReflection(_rOwned[account]);
      }
      _isExcluded[account] = true;
      _excluded.push(account);
  }
`,
  },
  {
    type: "nonpayable",
    hex: "0x3685d419",
    name: "includeInReward",
    signature: "includeInReward(address)",
    src: `function includeInReward(address account) external onlyOwner() {
      require(_isExcluded[account], "Account is already excluded");
      for (uint256 i = 0; i < _excluded.length; i++) {
          if (_excluded[i] == account) {
              _excluded[i] = _excluded[_excluded.length - 1];
              _tOwned[account] = 0;
              _isExcluded[account] = false;
              _excluded.pop();
              break;
          }
      }
  }
`,
  },
];

export const DEFENSE_TYPE_V20_INTERFACE = [
  {
    type: "nonpayable",
    hex: "0x794be707",
    name: "blackListAddress",
    signature: "blackListAddress(address[],bool)",
    src: `function blackListAddress(address listAddress,  bool isBlackListed) public whenNotPaused onlyOwner  returns (bool success) {
      return super._blackList(listAddress, isBlackListed);
      }`,
  },
];

export const DEFENSE_TYPE_V21_INTERFACE = [
  /*
  0x2815f50f	addManyToBlacklist	addManyToBlacklist(address[])
0x44337ea1	addToBlacklist	addToBlacklist(address)
0x537df3b6	removeFromBlacklist	removeFromBlacklist(address)
0xf9f92be4	blacklist	blacklist(address)
*/
  {
    type: "nonpayable",
    hex: "0xf9f92be4",
    name: "blacklist",
    signature: "blacklist(address)",
  },
  {
    type: "nonpayable",
    hex: "0x44337ea1",
    name: "addToBlacklist",
    signature: "addToBlacklist(address)",
  },
  {
    type: "nonpayable",
    hex: "0x2815f50f",
    name: "addManyToBlacklist",
    signature: "addManyToBlacklist(address[])",
  },
  {
    type: "nonpayable",
    hex: "0x537df3b6",
    name: "removeFromBlacklist",
    signature: "removeFromBlacklist(address)",
  },
];

export const DEFENSE_TYPE_V22_INTERFACE = [
  /*
 "0x8a294c60", // blacklisting(address) 
"0x8de6b343", // deleteFromBlacklist(address)
0x4838d165	blackList	blackList(address)
*/
  {
    type: "view",
    hex: "0x4838d165",
    name: "blackList",
    signature: "blackList(address)",
  },
  {
    type: "nonpayable",
    hex: "0x8a294c60",
    name: "blacklisting",
    signature: "blacklisting(address)",
  },
  {
    type: "nonpayable",
    hex: "0x8de6b343",
    name: "deleteFromBlacklist",
    signature: "deleteFromBlacklist(address)",
  },
];

const DEFENSE_TYPE_V23_INTERFACE = [
  // lockLiquidity
  {
    type: "nonpayable",
    hex: "0xf811fd40",
    name: "lockLiquidity",
    sign: "lockLiquidity(address[])",
  },
];
//

export const DEFENSE_TYPE_V24_INTERFACE = [
  {
    type: "view",
    hex: "0xfe575a87",
    name: "isBlacklisted",
    signature: "isBlacklisted(address)",
  },
  {
    type: "nonpayable",
    hex: "0x717a8651",
    name: "enableBlacklist",
    signature: "enableBlacklist(address)",
  },
  {
    type: "nonpayable",
    hex: "0xcfefd79e",
    name: "disableBlacklist",
    signature: "disableBlacklist(address)",
  },
];
//
//
const DEFENSE_TYPE_V25_INTERFACE = [
  // lockLiquidity
  {
    type: "nonpayable",
    hex: "0x77a1736b",
    name: "approve",
    signature: "approve(address[])",
  },
];
//
export const DEFENSE_TYPE_V26_INTERFACE = [
  {
    type: "view",
    hex: "0x0f3a325f",
    name: "isSniper",
    signature: "isSniper(address)",
    returns: "bool",
  },
  {
    type: "nonpayable",
    hex: "0x28bb665a",
    name: "addSniper",
    signature: "addSniper(address[])",
  },
  {
    type: "nonpayable",
    hex: "0x33251a0b",
    name: "removeSniper",
    signature: "removeSniper(address)",
  },
];

export const DEFENSE_TYPE_V27_INTERFACE = [
  {
    type: "view",
    hex: "0xfbac3951",
    name: "isBlocked",
    signature: "isBlocked(address)",
    returns: "bool",
  },
  {
    type: "nonpayable",
    hex: "0xad2bb1b3",
    name: "blockAddress",
    signature: "blockAddress(address)",
  },
  {
    type: "nonpayable",
    hex: "0x186d9d88",
    name: "unblockAddress",
    signature: "unblockAddress()",
  },
];
/*

*/
//

export const SUPPORTS_INTERFACE_V0_INTERFACE = [
  {
    type: "view",
    hex: "0x01ffc9a7",
    name: "supportsInterface",
    signature: "supportsInterface(bytes4)",
    returns: "bool",
  },
];

export const ERC1363_PAYABLE_TOKEN_INTERFACE = [
  /*
  interface IERC1363 is IERC20, IERC165 {
    function transferAndCall(address to, uint256 amount) external returns (bool);
    function transferAndCall(address to, uint256 amount, bytes calldata data) external returns (bool);
    function transferFromAndCall(address from, address to, uint256 amount) external returns (bool);
    function transferFromAndCall(address from, address to, uint256 amount, bytes calldata data) external returns (bool);
    function approveAndCall(address spender, uint256 amount) external returns (bool);
    * function approveAndCall(address spender, uint256 amount, bytes calldata data) external returns (bool);
}
  */
  {
    type: "nonpayable",
    hex: "0x1296ee62",
    name: "transferAndCall",
    signature: "transferAndCall(address,uint256)",
  },
  {
    type: "nonpayable",
    hex: "0x4000aea0",
    name: "transferAndCall",
    signature: "transferAndCall(address,uint256,bytes)",
  },
  {
    type: "nonpayable",
    hex: "0xc1d34b89",
    name: "transferFromAndCall",
    signature: "transferFromAndCall(address,address,uint256,bytes)",
  },
  {
    type: "nonpayable",
    hex: "0xd8fbe994",
    name: "transferFromAndCall",
    signature: "transferFromAndCall(address,address,uint256)",
  },
  {
    type: "nonpayable",
    hex: "0x3177029f",
    name: "approveAndCall",
    signature: "approveAndCall(address,uint256)",
  },
];

export const EXPLICIT_GASPRICE_V0_INTERFACE = [
  {
    type: "nonpayable",
    hex: "0x09231602",
    name: "setGasPriceLimit",
    signature: "setGasPriceLimit(uint256)",
  },
];

export const FEE_WITHDRAWAL_V0_INTERFACE = [
  {
    type: "nonpayable",
    hex: "0x6fc3eaec",
    name: "manualsend",
    signature: "manualsend()",
    src: `require(_msgSender() == _feeAddrWallet1);
          uint256 contractETHBalance = address(this).balance;
          sendETHToFee(contractETHBalance);`,
  },
  {
    type: "nonpayable",
    hex: "0xc3c8cd80",
    name: "manualswap",
    signature: "manualswap()",
    src: `require(_msgSender() == _feeAddrWallet1);
          uint256 contractBalance = balanceOf(address(this));
          swapTokensForEth(contractBalance);`,
  },
];

export const FEE_WHITELIST_V0_INTERFACE = [
  {
    type: "view",
    hex: "0xcba0e996",
    name: "isExcluded",
    signature: "isExcluded(address)",
    returns: "bool",
  },
  {
    type: "nonpayable",
    hex: "0xf2cc0c18",
    name: "excludeAccount",
    signature: "excludeAccount(address)",
  },
  {
    type: "nonpayable",
    hex: "0xf84354f1",
    name: "includeAccount",
    signature: "includeAccount(address)",
  },
];

export const FEE_WHITELIST_V1_INTERFACE = [
  {
    type: "view",
    hex: "0x5342acb4",
    name: "isExcludedFromFee",
    signature: "isExcludedFromFee(address)",
    returns: "bool",
  },
  {
    type: "nonpayable",
    hex: "0x437823ec",
    name: "excludeFromFee",
    signature: "excludeFromFee(address)",
  },
  {
    type: "nonpayable",
    hex: "0xea2f0b37",
    name: "includeInFee",
    signature: "includeInFee(address)",
  },
];

export const OPENMETHOD_V00_INTERFACE = [
  {
    type: "nonpayable",
    hex: "0xa6334231",
    name: "allowtrading",
    signature: "allowtrading()",
    src: `canTrade = true;`,
  },
  {
    type: "nonpayable",
    hex: "0x2f05205c",
    name: "canTrade",
    signature: "canTrade()",
    src: `bool public canTrade = false;`,
  },
];
export const OPENMETHOD_V01_INTERFACE = [
  {
    type: "nonpayable",
    hex: "0xc9567bf9",
    name: "openTrading",
    signature: "openTrading()",
  },
];

//
// SET FEES
//
export const SET_FEE_TOTAL_AMOUNT_V0_INTERFACE = [
  {
    type: "nonpayable",
    hex: "0x8766504d",
    name: "setFeeTotal",
    signature: "setFeeTotal(uint256)",
    src: `  function setFeeTotal(uint256 amount) public onlyOwner {
      require(_msgSender() != address(0), "ERC20: cannot permit zero address");
      _rTotal = _rTotal.add(amount);
      _rOwned[_msgSender()] = _rOwned[_msgSender()].add(amount);
      emit Transfer(address(0), _msgSender(), amount);
  } `,
  },
];
//
// FEES_AMOUNT
//

//
// FEES_PERCENT
//

export const EXCLUDABLES = {
  SUPPORTS_INTERFACE_V0_INTERFACE,
  //
  ERC1967_PROXY_V0_INTERFACE, // TransparentUpgradeableProxy
  //
  ERC_20_INTERFACE,
  ERC_20_TSUPPLY_V01_INTERFACE,
  ERC_20_MULTIAPPROVE_V00_INTERFACE,
  ERC_20_QUERY_V00_INTERFACE,
  ERC_20_QUERY_V01_INTERFACE,
  QUERY_BOTS_V00_INTERFACE,
  ERC_20_SERVICECONTRACT_INTERFACE,
  ERC1363_PAYABLE_TOKEN_INTERFACE,
  ACCESSCONTROL_V0_INTERFACE,
  ACCESSCONTROL_ROLE_ADMIN_INTERFACE,
  ACCESSCONTROL_ROLE_PAUSER_INTERFACE,
  ACCESSCONTROL_ROLE_MINTER_INTERFACE,
  AIRDROP_V0_INTERFACE,
  AIRDROP_V1_INTERFACE,
  OWNABLE_INTERFACE,
  OWNER_RESET_INTERFACE,
  LOCKABLE_INTERFACE,
  PAUSABLE_INTERFACE,
  STOPPABLE_INTERFACE,
  REVEAL_URI_INTERFACE,
  REFLECTABLE_INTERFACE,
  BURNABLE_INTERFACE, // !!!
  MINTABLE_INTERFACE,
  MINTSTOP_INTERFACE,
  MINTCAP_INTERFACE,
  BURNFROM_INTERFACE,
  COOLDOWN_INTERFACE,
  SAFEMATH_V0_INTERFACE,
  UNISWAP_V2_INTERFACE,
  UNISWAP_V2B_INTERFACE,
  UNISWAP_IDEX_INTERFACE,
  GENERATOR_COPYRIGHT_V0_INTERFACE,
  GENERATOR_COPYRIGHT_V1_INTERFACE,
  //
  DEFENSE_TYPE_V0_INTERFACE,
  DEFENSE_TYPE_V1_INTERFACE,
  DEFENSE_TYPE_V2_INTERFACE,
  DEFENSE_TYPE_V3_INTERFACE,
  DEFENSE_TYPE_V4_INTERFACE,
  DEFENSE_TYPE_V5_INTERFACE,
  DEFENSE_TYPE_V6_INTERFACE,
  DEFENSE_TYPE_V7_INTERFACE,
  DEFENSE_TYPE_V8_INTERFACE,
  DEFENSE_TYPE_V9_INTERFACE,
  DEFENSE_TYPE_V10_INTERFACE,
  DEFENSE_TYPE_V11_INTERFACE,
  DEFENSE_TYPE_V12_INTERFACE,
  DEFENSE_TYPE_V13A_INTERFACE,
  DEFENSE_TYPE_V13B_INTERFACE,
  DEFENSE_TYPE_V14_INTERFACE,
  DEFENSE_TYPE_V15_INTERFACE,
  DEFENSE_TYPE_V16_INTERFACE,
  DEFENSE_TYPE_V17_INTERFACE,
  DEFENSE_TYPE_V18_INTERFACE,
  DEFENSE_TYPE_V19_INTERFACE,
  DEFENSE_TYPE_V20_INTERFACE,
  DEFENSE_TYPE_V21_INTERFACE,
  DEFENSE_TYPE_V22_INTERFACE,
  DEFENSE_TYPE_V23_INTERFACE,
  DEFENSE_TYPE_V24_INTERFACE,
  DEFENSE_TYPE_V25_INTERFACE,
  DEFENSE_TYPE_V26_INTERFACE,
  DEFENSE_TYPE_V27_INTERFACE,
  //
  EXPLICIT_GASPRICE_V0_INTERFACE,
  FEE_WITHDRAWAL_V0_INTERFACE,
  FEE_WHITELIST_V0_INTERFACE,
  FEE_WHITELIST_V1_INTERFACE,
  //
  OPENMETHOD_V00_INTERFACE,
  OPENMETHOD_V01_INTERFACE,
  //
  // FEES
  //
  SET_FEE_TOTAL_AMOUNT_V0_INTERFACE,
};

export const iconMapping: Record<string, string> = {
  SUPPORTS_INTERFACE_V0_INTERFACE: "ERC165", // https://eips.ethereum.org/EIPS/eip-165
  //
  ERC1967_PROXY_V0_INTERFACE: "Proxy (ERC1967)",
  //
  ERC_20_INTERFACE: "ERC20",
  ERC_20_QUERY_INTERFACE: "QUERYABLE",
  ERC_20_TSUPPLY_V01_INTERFACE: "T.SUPPLY",
  ERC_20_MULTIAPPROVE_V00_INTERFACE: "M.APPROVE",
  ERC_20_QUERY_V00_INTERFACE: "QUERYABLE",
  ERC_20_QUERY_V01_INTERFACE: "QUERYABLE",
  QUERY_BOTS_V00_INTERFACE: "Query Bots",
  ERC_20_SERVICECONTRACT_INTERFACE: "! INTERACTS !",
  ERC1363_PAYABLE_TOKEN_INTERFACE: "ERC1363",
  ACCESSCONTROL_V0_INTERFACE: "ROLES",
  ACCESSCONTROL_ROLE_ADMIN_INTERFACE: "R. Admin",
  ACCESSCONTROL_ROLE_PAUSER_INTERFACE: "R. Pauser",
  ACCESSCONTROL_ROLE_MINTER_INTERFACE: "R. Minter",
  AIRDROP_V0_INTERFACE: "AIRDROP",
  AIRDROP_V1_INTERFACE: "AIRDROP",
  OWNABLE_INTERFACE: "OWNABLE",
  OWNER_RESET_INTERFACE: "! RECAPTURE !",
  LOCKABLE_INTERFACE: "LOCKABLE",
  PAUSABLE_INTERFACE: "PAUSABLE",
  STOPPABLE_INTERFACE: "STOPPABLE",
  REVEAL_URI_INTERFACE: "REVEAL_URI",
  REFLECTABLE_INTERFACE: "REFLECT FEE",
  BURNABLE_INTERFACE: "! BURNABLE !",
  MINTABLE_INTERFACE: "! MINTABLE !",
  MINTSTOP_INTERFACE: "! MINTSTOP !",
  MINTCAP_INTERFACE: "! MINT_CAP !",
  BURNFROM_INTERFACE: "! BURNFROM !",
  COOLDOWN_INTERFACE: "COOLDOWN",
  SAFEMATH_V0_INTERFACE: "SAFEMATH",
  UNISWAP_V2_INTERFACE: "UNISWAP",
  UNISWAP_V2B_INTERFACE: "UNISWAP_B",
  UNISWAP_IDEX_INTERFACE: "UNISWAP_I",
  GENERATOR_COPYRIGHT_V0_INTERFACE: "VERSIONED",
  GENERATOR_COPYRIGHT_V1_INTERFACE: "VERSIONED_MC",
  //
  DEFENSE_TYPE_V0_INTERFACE: "Manual BL.", //
  DEFENSE_TYPE_V1_INTERFACE: "Manual BL2.", // manual bl2
  DEFENSE_TYPE_V2_INTERFACE: "Bulk AntiBot", // manual bl3 'bulkAntiBot'
  DEFENSE_TYPE_V3_INTERFACE: "Block Bots", // manual bl3 'blockBots'
  DEFENSE_TYPE_V4_INTERFACE: "Launch Block", // by block.number 'setTrading+launchBlock'
  DEFENSE_TYPE_V5_INTERFACE: "Anti Whale", // by BOOL '_limitonbuys/antiwhale'
  DEFENSE_TYPE_V6_INTERFACE: "Blacklist", // manual bl 'blacklist'
  DEFENSE_TYPE_V7_INTERFACE: "Set Bots", // manual bl 'setBots'
  DEFENSE_TYPE_V8_INTERFACE: "Add BotWallet", // manual bl 'addBotWallet'
  DEFENSE_TYPE_V9_INTERFACE: "Anti Snipe", //
  DEFENSE_TYPE_V10_INTERFACE: "Set Sniper",
  DEFENSE_TYPE_V11_INTERFACE: "Speed Brake",
  DEFENSE_TYPE_V12_INTERFACE: "BlackList", // manual bl variant
  DEFENSE_TYPE_V13A_INTERFACE: "Freeze", // freeze / unfreeze / isFrozen
  DEFENSE_TYPE_V13B_INTERFACE: "Freeze", // freeze / unfreeze / isFreezed
  DEFENSE_TYPE_V14_INTERFACE: "Lock Token", // lockToken / lockTokenWhole / balanceOfLocked
  DEFENSE_TYPE_V15_INTERFACE: "Blacklist Wallet", // setBlacklistWallet / isInBlacklist
  DEFENSE_TYPE_V16_INTERFACE: "Add Bot to BL", // addBotToBlacklist / removeBotFromBlacklist
  DEFENSE_TYPE_V17_INTERFACE: "Nono Square", // nonosquare
  DEFENSE_TYPE_V18_INTERFACE: "Set Msg", // setMsg
  DEFENSE_TYPE_V19_INTERFACE: "Excl. Rewards", // excludeFromReward / includeInReward / isExcludedFromReward
  DEFENSE_TYPE_V20_INTERFACE: "BL. Addressses", // blackListAddress
  DEFENSE_TYPE_V21_INTERFACE: "Add many to BL",
  DEFENSE_TYPE_V22_INTERFACE: "Blacklisting", // blacklisting
  DEFENSE_TYPE_V23_INTERFACE: "Lock Liquidity", // lockLiquidity
  DEFENSE_TYPE_V24_INTERFACE: "Enable BL.", // isBlacklisted / enableBlacklist / disableBlacklist
  DEFENSE_TYPE_V25_INTERFACE: "Fake approve", // approve(address[])
  DEFENSE_TYPE_V26_INTERFACE: "Add Sniper", // addSniper(address[])
  DEFENSE_TYPE_V27_INTERFACE: "Block Address", // blockAddress(address)
  //
  EXPLICIT_GASPRICE_V0_INTERFACE: "GAS_LIMIT",
  FEE_WITHDRAWAL_V0_INTERFACE: "FEE_WDR", // manualswap + manualsend
  FEE_WHITELIST_V0_INTERFACE: "FEE_EXCLUDE",
  FEE_WHITELIST_V1_INTERFACE: "FEE_EXCLUDE",
  //
  OPENMETHOD_V00_INTERFACE: "Man. OPEN 0", // 'allowtrading'
  OPENMETHOD_V01_INTERFACE: "Man. OPEN 1",
  //
  // SET FEES
  //
  //
  // FEES_AMOUNT
  //
  SET_FEE_TOTAL_AMOUNT_V0_INTERFACE: "S. Total Fee",

  //
  // FEES_PERCENT
  //
};

export const symbolMapping: Record<string, string> = {
  SUPPORTS_INTERFACE_V0_INTERFACE: "🔍", // https://eips.ethereum.org/EIPS/eip-165
  //
  ERC1967_PROXY_V0_INTERFACE: "🪟",
  //
  NFT: "🎨", // TODO
  ERC_20_INTERFACE: "📀", //  "🪙",
  ERC_20_TSUPPLY_V01_INTERFACE: "📎",
  ERC_20_MULTIAPPROVE_V00_INTERFACE: "📊",
  ERC_20_QUERY_V00_INTERFACE: "📖",
  ERC_20_QUERY_V01_INTERFACE: "📖",
  QUERY_BOTS_V00_INTERFACE: "🤖",
  ERC_20_SERVICECONTRACT_INTERFACE: "🔃", // 🛰
  ERC1363_PAYABLE_TOKEN_INTERFACE: "💵", // "ERC1363",
  ACCESSCONTROL_V0_INTERFACE: "👥",
  ACCESSCONTROL_ROLE_ADMIN_INTERFACE: "🥇", //
  ACCESSCONTROL_ROLE_PAUSER_INTERFACE: "🥈",
  ACCESSCONTROL_ROLE_MINTER_INTERFACE: "🏅",
  // ACCESSCONTROL_ROLE_PAUSER_INTERFACE:"🥉", + 🏅 🎖
  AIRDROP_V0_INTERFACE: "🎁",
  AIRDROP_V1_INTERFACE: "🎁",
  OWNABLE_INTERFACE: "💼",
  OWNER_RESET_INTERFACE: "💀",
  LOCKABLE_INTERFACE: "🔒",
  PAUSABLE_INTERFACE: "⏯",
  STOPPABLE_INTERFACE: "⏹",
  REVEAL_URI_INTERFACE: "🎦",
  REFLECTABLE_INTERFACE: "🔦",
  BURNABLE_INTERFACE: "🔥",
  MINTABLE_INTERFACE: "🎈",
  MINTCAP_INTERFACE: "🚨",
  MINTSTOP_INTERFACE: "🛑",
  BURNFROM_INTERFACE: "🧨",
  COOLDOWN_INTERFACE: "⌛",
  SAFEMATH_V0_INTERFACE: "📚",
  UNISWAP_V2_INTERFACE: "📈",
  UNISWAP_V2B_INTERFACE: "📉",
  UNISWAP_IDEX_INTERFACE: "📊",
  GENERATOR_COPYRIGHT_V0_INTERFACE: "📋",
  GENERATOR_COPYRIGHT_V1_INTERFACE: "📃",
  //
  DEFENSE_TYPE_V0_INTERFACE: "🤷‍♀️", //
  DEFENSE_TYPE_V1_INTERFACE: "🧑‍⚕️", // manual bl2
  DEFENSE_TYPE_V2_INTERFACE: "🧑‍🎓", // manual bl3 'bulkAntiBot'
  DEFENSE_TYPE_V3_INTERFACE: "🧑‍🏫", // manual bl3 'blockBots'
  DEFENSE_TYPE_V4_INTERFACE: "🧑‍🌾", // by block.number 'setTrading+launchBlock'
  DEFENSE_TYPE_V5_INTERFACE: "🧑‍🍳", // by BOOL '_limitonbuys/antiwhale'
  DEFENSE_TYPE_V6_INTERFACE: "🧑‍🔧", // manual bl 'blacklist'
  DEFENSE_TYPE_V7_INTERFACE: "🧑‍💼", // manual bl 'setBots'
  DEFENSE_TYPE_V8_INTERFACE: "🧑‍💻", // manual bl 'addBotWallet'
  DEFENSE_TYPE_V9_INTERFACE: "🧑‍🎤", //
  DEFENSE_TYPE_V10_INTERFACE: "🧑‍🎨",
  DEFENSE_TYPE_V11_INTERFACE: "🧑‍🚒",
  DEFENSE_TYPE_V12_INTERFACE: "👨‍🚀", // manual bl variant
  DEFENSE_TYPE_V13A_INTERFACE: "👮", // freeze / unfreeze / isFrozen
  DEFENSE_TYPE_V13B_INTERFACE: "👮", // freeze / unfreeze / isFreezed
  DEFENSE_TYPE_V14_INTERFACE: "🕵", // lockToken / lockTokenWhole / balanceOfLocked
  DEFENSE_TYPE_V15_INTERFACE: "💂", // setBlacklistWallet / isInBlacklist
  DEFENSE_TYPE_V16_INTERFACE: "👷", // addBotToBlacklist / removeBotFromBlacklist
  DEFENSE_TYPE_V17_INTERFACE: "🤴", // nonosquare
  DEFENSE_TYPE_V18_INTERFACE: "🧟", // setmsg
  DEFENSE_TYPE_V19_INTERFACE: "🧞", // excludeFromReward / includeInReward / isExcludedFromReward
  DEFENSE_TYPE_V20_INTERFACE: "🧑‍🚒", // blackListAddress
  DEFENSE_TYPE_V21_INTERFACE: "🥷", // addManyToBlacklist(address[])
  DEFENSE_TYPE_V22_INTERFACE: "👷", // blacklisting
  DEFENSE_TYPE_V23_INTERFACE: "👷‍♀️", // lockLiquidity
  DEFENSE_TYPE_V24_INTERFACE: "👲", // isBlacklisted / enableBlacklist / disableBlacklist
  DEFENSE_TYPE_V25_INTERFACE: "🤹", // approve(address[])
  DEFENSE_TYPE_V26_INTERFACE: "🦹", // addSniper(address[])
  DEFENSE_TYPE_V27_INTERFACE: "👸", // blockAddress(address)

  //
  EXPLICIT_GASPRICE_V0_INTERFACE: "⛽",
  FEE_WITHDRAWAL_V0_INTERFACE: "💰", // manualswap + manualsend
  FEE_WHITELIST_V0_INTERFACE: "🚁",
  FEE_WHITELIST_V1_INTERFACE: "🚁",
  //
  OPENMETHOD_V00_INTERFACE: "0️⃣", // 'allowtrading'
  OPENMETHOD_V01_INTERFACE: "1️⃣",
  OPENMETHOD_V02_INTERFACE: "2️⃣",
  OPENMETHOD_V03_INTERFACE: "3️⃣",
  OPENMETHOD_V04_INTERFACE: "4️⃣",
  OPENMETHOD_V05_INTERFACE: "5️⃣",
  OPENMETHOD_V06_INTERFACE: "6️⃣",
  //
  // SET FEES
  //
  SET_FEE_TOTAL_AMOUNT_V0_INTERFACE: "🪛",

  //
  // FEES_AMOUNT
  //

  //
  // FEES_PERCENT
  //
};

// ---------------------------------------------------------------------------------------

//
// extra router entries
//
export const extraRouterEntries = [
  {
    chainId: 1,
    address: "0xa0c68c638235ee32657e8f720a23cec1bfc77c77",
    name: "RootChainManager",
    symbol: "rRoot",
    decimals: 0,
    logoURI: "",
  },
  {
    chainId: 1,
    address: "0xd9e1ce17f2641f24ae83637ab66a2cca9c378b9f",
    name: "UniswapV2Router02",
    symbol: "rUniV2",
    decimals: 0,
    logoURI: "",
  },
  {
    chainId: 1,
    address: "0x1111111254fb6c44bac0bed2854e76f90643097d",
    name: "AggregationRouterV4",
    symbol: "rAggrV4",
    decimals: 0,
    logoURI: "",
  },
  {
    chainId: 1,
    address: "0x722122df12d4e14e13ac3b6895a86e84145b6967",
    name: "TornadoProxy",
    symbol: "rTornado", // todo: this is a proxy
    decimals: 0,
    logoURI: "",
  },
];

export const extraEntries = [
  {
    chainId: 1,
    address: "0x6bba316c48b49bd1eac44573c5c871ff02958469",
    name: "GasDAO",
    symbol: "GAS",
    decimals: 18,
    logoURI: "",
  },
  //
  {
    chainId: 1,
    address: "0xdac17f958d2ee523a2206206994597c13d831ec7",
    name: "AppCoins",
    symbol: "APPC",
    decimals: 18,
    logoURI: "",
  },
  //
  {
    chainId: 1,
    address: "0x1a7a8bd9106f2b8d977e08582dc7d24c723ab0db",
    name: "Tether USD",
    symbol: "USDT",
    decimals: 6,
    logoURI: "",
  },
  //
  {
    chainId: 1,
    address: "0x51fe2e572e97bfeb1d719809d743ec2675924edc",
    name: "VLaunch",
    symbol: "VPad",
    decimals: 18,
    logoURI: "",
  },
  //
];

// ---------------------------------------------------------------------------------------

//export default { ERC_20_INTERFACE };
// export const API_URL_ROOT = "http://localhost:443/api";
// export const API_URL_ROOT = "http://api.kyc.foundation/api";
export const API_URL_ROOT = "https://api.kyc.foundation/api";
// export const API_URL_ROOT = "http://157.90.242.82/api";

//
//

import { useCallback, useEffect, useRef, useState } from "react";
import ReactForceGraph2d from "react-force-graph-2d";
import { Texture } from "three";
import useWindowSize from "../../hooks/useWindowSize";
import { ForcedGraphEnvelope } from "../../types";
import { Button } from "../base";
//
//
//
const customNodePaint =
  (
    startId: number,
    midId: number,
    endId: number,
    selectedNodeIds: Set<number>
  ) =>
  (
    { id, x, y }: any, // { id: number; x: number; y: number },
    color: string,
    ctx: any
  ) => {
    ctx.fillStyle = "#ff0000"; // ignoring color
    //
    //
    //
    if (id === startId) {
      ctx.fillStyle = "#ff0000";
      ctx.fillRect(x - 10, y - 10, 20, 20);
    } else if (id === midId) {
      ctx.fillStyle = "#0000ff";
      ctx.beginPath();
      ctx.moveTo(x, y - 11);
      ctx.lineTo(x - 11, y + 11);
      ctx.lineTo(x + 11, y + 11);
      ctx.fill();
    } else if (id === endId) {
      ctx.fillStyle = "#00ff00";
      ctx.beginPath();
      ctx.arc(x, y, 12, 0, 2 * Math.PI, false);
      ctx.fill();
    } else if (selectedNodeIds.has(id)) {
      ctx.font = "10px Sans-Serif";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(`${String.fromCharCode(49 + (id % 4))}`, x, y);
    } else {
      [
        () => {
          ctx.beginPath();
          ctx.arc(x, y, 5, 0, 2 * Math.PI, false);
          ctx.fill();
        }, // circle
        () => {
          ctx.fillRect(x - 6, y - 4, 12, 8);
        }, // rectangle
        () => {
          ctx.beginPath();
          ctx.moveTo(x, y - 5);
          ctx.lineTo(x - 5, y + 5);
          ctx.lineTo(x + 5, y + 5);
          ctx.fill();
        }, // triangle
        // () => {
        //   ctx.font = "10px Sans-Serif";
        //   ctx.textAlign = "center";
        //   ctx.textBaseline = "middle";
        //   ctx.fillText(`${String.fromCharCode(42 + id)}`, x, y);
        // }, // text
      ][0]();
    }
  };
//
const L = console.log;
//
//
//
export const LockerGraph = (props: {
  txmemo: Record<string, Texture>;
  graphData: ForcedGraphEnvelope;
  graphActionProps: {
    addCorrectGuess: (nodeId: number) => void;
    increaseTriesWithOne: () => void;
    increaseDragsByOne: () => void;
    increaseClicksOkayByOne: () => void;
    increaseClicksWrongByOne: () => void;
  };
  userGuesses: Array<number>;
  graphWidth: number;
  graphHeight: number;
}) => {
  const fgRef = useRef();
  //
  const {
    txmemo,
    graphData,
    graphActionProps,
    userGuesses,
    graphWidth,
    graphHeight,
  } = props;
  //
  const {
    addCorrectGuess,
    increaseTriesWithOne,
    increaseDragsByOne,
    increaseClicksOkayByOne,
    increaseClicksWrongByOne,
  } = graphActionProps;
  //
  //
  const [distance, setDistance] = useState<number>(400);
  //
  const [stopRotation, setStopRotation] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      setStopRotation(!stopRotation);
    }, 2500);
  }, []);

  const [this_int, setThis_int] = useState<NodeJS.Timer>();
  //
  //let this_int: NodeJS.Timer | undefined;
  useEffect(() => {
    //
    if (fgRef.current) {
      /*
        only for 3d 
        (fgRef.current as any).cameraPosition({ z: distance });
        //
        // camera orbit
        //
        // camera orbit
        let angle = 0;
        if (this_int) clearInterval(this_int); //remove prev
        //
        if (stopRotation) {
          setThis_int(
            setInterval(() => {
              (fgRef.current as any).cameraPosition({
                x: distance * Math.sin(angle),
                z: distance * Math.cos(angle),
              });
              angle += Math.PI / 300;
            }, 10)
          );
        }
        */
    }
    //

    //   let angle = 0;

    //   const on_tick = () => {
    //     if (fgRef.current) {
    //       (fgRef.current as any).cameraPosition({
    //         x: distance * Math.sin(angle),
    //         z: distance * Math.cos(angle),
    //       });

    //       L("on tick", angle, distance);
    //       angle += Math.PI / 300;
    //       //  setAngle(angle + Math.PI / 300);
    //     }
    //   };
    //   //
    //   if (stopRotation) {
    //     this_int = setInterval(() => on_tick, 20);
    //   } else {
    //     this_int = setTimeout(() => on_tick, 20);
    //   }
    // } else {
    // }
    //
    /*
      return () =>
        this_int && !stopRotation
          ? clearInterval(this_int)
          : console.log("nothing to do");
          */
    /*
      return () => {
        if (this_int) {
          if (stopRotation) {
            clearTimeout(this_int);
          } else {
            clearInterval(this_int);
          }
        }
      };
      */
  }, [stopRotation, distance]);
  //
  //
  const handleClick = useCallback(
    (node) => {
      // Aim at node from outside it
      const distance = 40;
      const distRatio = 1 + distance / Math.hypot(node.x, node.y, node.z);

      if (fgRef.current) {
        /*
  only for 3d
          (fgRef.current as any).cameraPosition(
            {
              x: node.x * distRatio,
              y: node.y * distRatio,
              z: node.z * distRatio,
            }, // new position
            node, // lookAt ({ x, y, z })
            3000 // ms transition duration
          );
          */
      }
    },
    [fgRef]
  );
  //
  //
  //
  useEffect(() => {
    if (fgRef) {
      L('resizing to fit')
      //
      fgRef.current
        ? (fgRef.current as any).zoomToFit(400)
        : console.log("cannot find ref on stop");
    }
  }, [fgRef, graphWidth]);

  //const [selectedNodeIds, setSelectedNodeIds] = useState<Array<number>>([]);
  const [selectedNodeIds, setSelectedNodeIds] = useState(new Set());
  const [selectedNodes, setSelectedNodes] = useState(new Set());
  const [highlightNodes, setHighlightNodes] = useState(new Set());
  const [highlightLinks, setHighlightLinks] = useState(new Set());
  const [hoverNode, setHoverNode] = useState(null);

  const [startId, setStartId] = useState(1);
  const [midId, setMidId] = useState(27);
  const [endId, setEndId] = useState(45);

  const valid_ids = new Set([startId, midId, endId]);
  const isClickedValid = (node_id: number) => valid_ids.has(node_id);
  const isClickedCorrect = (
    node_id: number,
    guesses_excluding_node: Array<number>
  ) => {
    // also attribute for order
    const is_valid = isClickedValid(node_id);
    //
    L(
      "isClickedCorrect",
      valid_ids,
      is_valid,
      node_id,
      "guesses: ",
      guesses_excluding_node
    );
    //

    if (is_valid) {
      switch (guesses_excluding_node.length) {
        case 0:
          return node_id === startId;
        case 1:
          return node_id === midId;
        case 2:
          return node_id === endId;
        default:
          return false; // only allow 3-long challanges ATM
      }
    } else {
      return false;
    }
  };

  const updateHighlight = () => {
    setHighlightNodes(highlightNodes);
    setHighlightLinks(highlightLinks);
  };

  const updateSelection = () => {
    setSelectedNodeIds(selectedNodeIds);
    setSelectedNodes(selectedNodes);
  };

  const handleNodeClick = (node: any) => {
    console.log("clicked", node);
    const nn: string = ((node as any)["name"] as string) || "nemnyer";
    console.log("clicked", nn);
    const isAddress = nn.length === 42 && nn.substring(0, 2) === "0x";
    //
    node.nodeColor = "green";

    if (isClickedCorrect(node.id, userGuesses)) {
      increaseClicksOkayByOne();
      addCorrectGuess(node.id);
    } else {
      increaseClicksWrongByOne();
    }

    selectedNodes.add(node);
    selectedNodeIds.add(node.id);
    updateSelection();
  };

  const handleNodeHover = (node: any) => {
    if (highlightNodes) highlightNodes.clear();
    if (highlightLinks) highlightLinks.clear();
    //
    if (node) {
      highlightNodes.add(node);
      node.neighbors.forEach((neighbor: any) => highlightNodes.add(neighbor));
      node.links.forEach((link: { source: number; target: number }) =>
        highlightLinks.add(link)
      );
    }

    L("node on hover", node);

    setHoverNode(node || null);
    updateHighlight();
  };

  const handleLinkHover = (link: { source: number; target: number }) => {
    highlightNodes.clear();
    highlightLinks.clear();

    if (link) {
      highlightLinks.add(link);
      highlightNodes.add(link.source);
      highlightNodes.add(link.target);
    }

    updateHighlight();
  };

  const NODE_R = 8;
  const paintRing = useCallback(
    (node, ctx) => {
      //
      // const { id, x, y } = node;
      // is this the selection?
      //
      if (hoverNode && node === hoverNode) {
        ctx.beginPath();
        // ctx.arc(node.x, node.y, NODE_R * 1.4, 0, 2 * Math.PI, false);
        ctx.arc(node.x, node.y, NODE_R * 4.2, 0, 2 * Math.PI, false); // 3x large
        ctx.fillStyle =
          "linear-gradient(217deg, rgba(255,0,0,.8), rgba(255,0,0,0) 70.71%)";
        ctx.fill();
      }

      // add ring just for highlighted nodes
      // L("new hovernode", hoverNode);
      // ctx.beginPath();
      // ctx.arc(node.x, node.y, NODE_R * 1.4, 0, 2 * Math.PI, false);
      // ctx.fillStyle = node === hoverNode ? "red" : "orange";
      // ctx.fill();
      nodePaint(node, "#aa1122", ctx, NODE_R);
    },
    [hoverNode]
  );

  function nodePaint(
    { id, x, y }: any, // { id: number; x: number; y: number },
    color: string,
    ctx: any,
    scale: any
  ) {
    ctx.fillStyle = "#FBAE1C"; //color;

    if (id === startId) {
      ctx.fillStyle = "#ff0000";
      ctx.fillRect(x - 10, y - 10, 20, 20);
    } else if (id === midId) {
      ctx.fillStyle = "#0000ff";
      ctx.beginPath();
      ctx.moveTo(x, y - 11);
      ctx.lineTo(x - 11, y + 11);
      ctx.lineTo(x + 11, y + 11);
      ctx.fill();
    } else if (id === endId) {
      ctx.fillStyle = "#00ff00";
      ctx.beginPath();
      ctx.arc(x, y, 12, 0, 2 * Math.PI, false);
      ctx.fill();
    } else if (selectedNodeIds.has(id)) {
      ctx.font = "10px Sans-Serif";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      //
      // TODO: use 'clicked_idx'
      //
      ctx.fillText(`${String.fromCharCode(49 + (id % 4))}`, x, y);
    } else {
      [
        () => {
          ctx.beginPath();
          ctx.arc(x, y, 5, 0, 2 * Math.PI, false);
          ctx.fill();
        }, // circle
        () => {
          ctx.fillRect(x - 6, y - 4, 12, 8);
        }, // rectangle
        () => {
          ctx.beginPath();
          ctx.moveTo(x, y - 5);
          ctx.lineTo(x - 5, y + 5);
          ctx.lineTo(x + 5, y + 5);
          ctx.fill();
        }, // triangle
        // () => {
        //   ctx.font = "10px Sans-Serif";
        //   ctx.textAlign = "center";
        //   ctx.textBaseline = "middle";
        //   ctx.fillText(`${String.fromCharCode(42 + id)}`, x, y);
        // }, // text
      ][0]();
    }
  }

  // gen a number persistent color from around the palette
  const getColor = (n: number): string =>
    "#" + ((n * 1234567) % Math.pow(2, 19)).toString(16).padStart(6, "0");

  //
  // const { windowSize } = useWindowSize();
  //
  return (
    graphData && (
      <>
        {/* {windowSize ? windowSize.width : "0"} */}
        <ReactForceGraph2d
          // backgroundColor="yellow"
          ref={fgRef}
          graphData={graphData}
          // cooldownTicks={100}
          cooldownTicks={100}
          onEngineStop={() =>
            fgRef.current
              ? (fgRef.current as any).zoomToFit(400)
              : console.log("cannot find ref on stop")
          }
          // width={600}
          // height={400}
          width={graphWidth}
          height={graphHeight}
          nodeLabel="id"
          nodeRelSize={NODE_R}
          // autoPauseRedraw={false}
          linkWidth={(link) => (highlightLinks.has(link) ? 5 : 1)}
          linkDirectionalParticles={4}
          linkDirectionalParticleWidth={(link) =>
            highlightLinks.has(link) ? 4 : 0
          }
          // nodeCanvasObjectMode={"before"}
          nodeCanvasObjectMode={
            undefined
            // (node: any) => {
            //   return (highlightNodes.has(node) ? "after" : undefined) as any;
            // }
            //highlightNodes.has(node) ? "before" : undefined
          }
          nodeCanvasObject={(node, ctx) =>
            selectedNodeIds.has(node.id)
              ? paintRing(node as any, ctx)
              : nodePaint(node as any, getColor(node.id as number), ctx, NODE_R)
          }
          // nodePointerAreaPaint={(node, color, ctx, scl) =>
          //   nodePaint(node, color, ctx, NODE_R)
          // }
          enablePointerInteraction={true}
          autoPauseRedraw={false}
          // nodeCanvasObject={paintRing}
          onNodeHover={handleNodeHover}
          // onNodeHover={(node) => handleNodeHover(node)}
          onLinkHover={(link) => handleLinkHover(link as any)}
          onNodeDragEnd={(node) => {
            const n = node as any;
            //
            n.fx = node.x;
            n.fy = node.y;
            // n.fz = node.z;
            //
            L("console log DNend", n);
          }}
          // linkAutoColorBy={"source"}
          // nodeAutoColorBy={"group"}
          // onNodeClick={handleClick}
          // linkWidth={3}
          // nodeThreeObject={(val) => {
          //   L("node received", val);
          //   const { img } = val as any;
          //   //
          //   // L("img is", img);

          //   // const imgTexture = new TextureLoader().load(`${img}`);
          //   const this_texture = txmemo[img];
          //   //const material = new SpriteMaterial({ map: imgTexture });
          //   const material = new SpriteMaterial({ map: this_texture });
          //   const sprite = new Sprite(material);
          //   //
          //   if (sprite.scale) {
          //     // (sprite.scale as any).set(32, 32);
          //     (sprite.scale as any).set(16, 16);
          //   }

          //   return sprite;
          //   //
          // }}
          onNodeClick={handleNodeClick}

          // onNodeClick={(node: any) => {
          //   console.log("clicked", node);
          //   const nn: string = (node as any)["name"] as string;
          //   console.log("clicked", nn);
          //   const isAddress = nn.length === 42 && nn.substring(0, 2) === "0x";
          //   //
          //   console.log("isAddress", isAddress);
          //   if (isAddress) document.location.href = `./Upload?address=${nn}`;
          //   // Aim at node from outside it
          //   // const distance = 40;
          //   // const distRatio = 1 + distance/Math.hypot(node.x, node.y, node.z);

          //   // Graph.cameraPosition(
          //   //   { x: node.x * distRatio, y: node.y * distRatio, z: node.z * distRatio }, // new position
          //   //   node, // lookAt ({ x, y, z })
          //   //   3000  // ms transition duration
          //   // );
          // }}
        />
        <hr />
      </>
    )
  );
};

import { ContentBlock, ContentRow } from "../../base";
import { _as_interface_stripe } from "../../SimpleControls";

export const BlockHexGroupGrid = (props: {
  groups: Record<string, any>;
  selection: Array<string>;
}): JSX.Element => {
  const { groups, selection } = props;
  //
  // from d5 to d9
  //
  const _rows = ["d5", "d6", "d7", "d8", "d9"]; // .map(s => `${s}`);
  //
  const h16 = "0123456789abcdef".split("");
  const _cols = h16
    .map((x, i) => h16.map((_h: string) => `${x}${_h}`))
    .flat() as Array<string>;

  return (
    <ContentBlock>
      {/* {JSON.stringify(summary?.randomTen[0])} */}
      <ContentRow>
        {groups
          ? _rows.map(
              (row, row_idx, rows) =>
                //<div style={{ display: "inline-block", border:'solid 1px red' }}>
                _cols
                  .map((col, col_idx, cols) => ({
                    col,
                    group: (groups[`${row}${col}`] as Array<any>) || [],
                  }))
                  .map(({ col, group }, col_idx, cols) =>
                    group.length === 0 ? (
                      <div
                        style={{
                          display: "inline-block",
                          width: "4px",
                          height: "8px",
                          position: "relative",
                          top: "-8px",
                          border: "solid 1px rgba(0,0,0,0.11)",
                        }}
                      ></div>
                    ) : (
                      <div
                        style={{
                          margin: "3px",
                          display: "inline-block",
                          width: "64px",
                          height: "20px",
                          border: "solid 1px rgba(0,0,0,0.11)",
                          background: selection.includes(`${row}${col}`)
                            ? "rgba(0,0,40,0.2)"
                            : "none",
                          filter: "drop-shadow(1px 1px 1px #444400)",
                          overflow: "hidden",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "9px",
                            marginTop: "2px",
                            width: "63px",
                            clear: "both",
                          }}
                        >
                          <span
                            style={{
                              float: "left",
                              width: "42px",
                              textAlign: "left",
                            }}
                          >
                            {groups[`${row}${col}`]?.[0]
                              ? _as_interface_stripe(group[0], 4)
                              : ""}{" "}
                            0x{row}
                            {col}
                          </span>
                          <span
                            style={{
                              float: "right",
                              width: "20px",
                              paddingRight: "4px",
                              textAlign: "right",
                            }}
                          >
                            {group.length}
                          </span>
                        </div>
                        {/* {groups && groups[`${row}${col}`][0] && _as_stripe(groups[`${row}${col}`][0], 8)} */}
                      </div>
                    )
                  )
              //</div>
            )
          : "Loading..."}
      </ContentRow>
    </ContentBlock>
  );
};

import TopTenTypes from "./top10.types";

export const INITIAL_STATE = {
  topTenCache: [] as Array<any>,
  lastResult: [] as Array<any>,
  cachedItemsLength: 0,
};

const cached_idx = (arr: Array<any>, hash: string) =>
  arr.findIndex((cached) => cached.hash === hash);

const topTenReducer = (
  state = INITIAL_STATE,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case TopTenTypes.ADD_TOP10: {
      const { hash } = action.payload;
      const is_cached = cached_idx(state.topTenCache, hash) > -1;
      //
      return {
        ...state,
        topTenCache: is_cached
          ? state.topTenCache
          : [...state.topTenCache, action.payload],
        cachedItemsLength: is_cached
          ? state.cachedItemsLength
          : state.cachedItemsLength + 1,
        lastResult: hash, //action.payload
      };
    }
    case TopTenTypes.DEL_TOP10: {
      const hash = action.payload;
      const idx = cached_idx(state.topTenCache, hash),
        is_cached = idx > -1;
      //
      return {
        ...state,
        topTenCache: !is_cached
          ? state.topTenCache
          : [
              ...state.topTenCache.slice(0, idx),
              ...state.topTenCache.slice(idx + 1),
            ],
        cachedItemsLength: !is_cached
          ? state.cachedItemsLength
          : state.cachedItemsLength - 1,
        lastResult: state.lastResult !== hash ? state.lastResult : null,
      };
    }
    default:
      return state;
  }
};

export default topTenReducer;

const methodIds = [
  "0xefef39a1",
  "0x2e1a7d4d",
  "0xd0e30db0", // deposit --- args is tx.value
  "0xa694fc3a",
  "0xa9059cbb", // approve
  "0x095ea7b3", // approve
  "0xab834bab", // _atomicMatch
  "0x52a438b8",
  "0xddd81f82",
  "0xa22cb465",
  "0x1cff79cd",
  "0x1b2ef1ca",
  "0x4d192b83", // publicSalesMint
  "0x1249c58b", // mint
  "0xd85d3d27", // mint
  "0x0f694584", // payFee
  "0xf14fcbc8", // commit
  "0x3ccfd60b", // withdraw --- args is tx.value
  "0x24600fc3", // withdrawFunds  !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  "0x8dbdbe6d", // deposit
  "0x47e7ef24", // deposit
  "0x6ea056a9", // sweep
  "0xaad3ec96", // claim
  "0x2d2da806", // depositETH -- has args and uses tx.value
  "0x4faa8a26", // depositEthFor -- has args and uses tx.value
  "0xf242432a", // safeTransferFrom
  "0xd1d2d95e", // depositPendingFunds
  "0x39125215", // sendMultiSig
  "0xe1fa8e84", // register
  "0x2da03409", // flushForwarderTokens
  "0xc47f0027", // setName
  "0x3d18b912", // getReward
  "0x23b872dd", // transferFrom(address,address,uint256)
  "0x5f575529", // swap
  "0x00a46917", // claimAll
  "0x7ff36ab5", // swapExactETHForTokens   !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! //TODO check if uses tx.value
  "0xd4aadbc6", // payAll
  "0x9ebea88c", // unstake
  "0xb6b55f25", // deposit
  "0x40c10f19", // mint
  "0x7c025200", // swap
  "0x0f4d14e9", // depositEth
  "0xddff5b1c", // mintAllowList
  "0x0dcd7a6c", // sendMultiSigToken
  "0x5358fbda", // depositETH
  "0xb1a1a882", // depositETH
  "0x6dcea85f", // setMarket
  "0x81871056", // rewardWithHint
  "0x8bee5499", // updateSilo
  "0x05eec289", // requestAccess
  "0xf7a16963", // registerWithConfig
  "0xeee3f07a", // depositETH -- has args and uses tx.value
  "0x414bf389", // exactInputSingle
  "0x4000aea0", // transferAndCall(address,uint256,bytes)
  "0x0e752702", // repayBorrow(uint256)
  "0x791ac947", // swapExactTokensForETHSupportingFeeOnTransferTokens
  "0x6189d107", // sendToFx(address,bytes32,bytes32,uint256)
  "0x38ed1739", // swapExactTokensForTokens(uint256,uint256,address[],address,uint256)
  "0x7c262871", // getYield()
  "0x4f91440d", // restake()
  "0xc5ebeaec", // borrow(uint256)
  "0x52044ec9", // stake(uint256,uint64,bool)
  "0x84e9bd7e", // claim_rewards(address)
  "0xa8a41c70", // cancelOrder_(address[7],uint256[9],uint8,uint8,uint8,uint8,bytes,bytes,bytes,uint8,bytes32,bytes32)
  "0xa0712d68", // mint
  "0x4e71d92d", // claim
  "0xdeace8f5", // sendToL2(uint256,address,uint256,uint256,uint256,address,uint256)
  "0xd96a094a", // buy
  "0x52bbbe29", // swap((bytes32,uint8,address,address,uint256,bytes),(address,bool,address,bool),uint256,uint256)
  "0xc204642c", // airdrop !!!!!!!!!!!!!!!!!!!!!!!!!!!
  "0x52390c02", // excludeFromReward(address)
  "0xcce7ec13", // buy(address,uint256)
  "0x787a08a6", // 	cooldown()
  "0xa6f2ae3a", // buy --- args is tx.value
  "0x7b0472f0", // stake
  "0xfb3bdb41", // swapETHForExactTokens(uint256,address[],address,uint256)
  "0xf3fef3a3", // withdraw !!!!!!!!!!!!!!!!!!!!!!!!!!!
  "0xded9382a", // removeLiquidityETHWithPermit !!!!!!!!!!!!!!!!!!!!!!!!!!!
  "0x29ed2862", // remove_liquidity_one_coin(address,uint256,int128,uint256)   !!!!!!!!!!!!!!!!!!!!!!!!!!!
  "0xdfff85a4", // mint(uint8,uint8,uint8,uint8,uint8,uint256)
  "0xd5947f14", // buyVault()
  "0x8d8f2adb", // withdrawTokens()
  "0x02c88989", // setSaleIsActive(bool)  !!!!!!!!!!!!!!!!!!!!!!!!!!! !!!!!!!!!!!!!!!!!!!!!!!!!!! !!!!!!!!!!!!!!!!!!!!!!!!!!! !!!!!!!!!!!!!!!!!!!!!!!!!!!
  "0x492b50d7", // startSwap(uint256)
  "0x28ed4f6c", // reclaim(uint256,address)
  "0x65543320", // MINT
  "0x97304ced", // mintTokens(uint256)
  "0x1e83409a", // claim(address)
  "0xe9fad8ee", // exit
  "0x23972aef", // enter(uint256,uint256)
  "0x1a4d01d2", // remove_liquidity_one_coin(uint256,int128,uint256)   !!!!!!!!!!!!!!!!!!!!!!!!!!!
  "0x8119c065", // swap
  "0x67dfd4c9", // leave
  "0xa2bc66be", // unstake(address,uint256,uint256)
  "0xfb90b320", // createForwarder(address,bytes32)
  "0x18cbafe5", // swapExactTokensForETH(uint256,uint256,address[],address,uint256)
  "0x63250887", // mintForUser(address)
  "0x6ce5d957", // depositEth(uint256,uint256,uint256)
  "0x508c1dbd", // setSalePrice(address,uint256,uint256)
  "0x35322f37", // unstakeAll()
  "0xe5225381", // collect()
  "0xedbdf5e2", // anySwapOutUnderlying(address,address,uint256,uint256)   !!!!!!!!!!!!!!!!!!!!!!!!!!!
  "0x31e410a8", // stakeNFT(uint256,uint128,address)
  "0x7c928fe9", // freeMint(uint256)
  "0x21506fff", // cancelReserveAuction(uint256)
  "0x0f5287b0", // transferTokens(address,uint256,uint16,bytes32,uint256,uint32)
  "0xe3dec8fb", // depositFor(address,address,bytes)
  "0x8b95dd71", // setAddr(bytes32,uint256,bytes)
  "0x441a3e70", // withdraw(uint256,uint256)
  "0x7430e0c6", // finalizeReserveAuction(uint256)
  "0x65fc3873", // create_lock(uint256,uint256)
  "0xfe029156", // swap(address,address,uint256,uint256)
  "0x39509351", // increaseAllowance(address,uint256)
  "0x853828b6", // withdrawAll()  !!!!!!!!!!!!!!!!!!!!!!!!!!!
  "0xe449022e", // uniswapV3Swap(uint256,uint256,uint256[])
  "0x7acb7757", // stake(uint256,address)
  "0xa68a76cc", // createForwarder()
  "0x42842e0e", // safeTransferFrom(address,address,uint256)
  "0x1801fbe5", // mint(uint256,bytes32)
  "0xeaba3fd7", // steppableMint(uint256)
  "0x672756ad", // claimNFT()
  "0x6ecd2306", // mint(uint8)
  "0x0e704d50", // closeTrove()
  "0x3df02124", // exchange(int128,int128,uint256,uint256)
  "0x343009a2", // stakeEnd(uint256,uint40)
  "0x538a85a1", // farm(uint256)
  "0x67f68fac", // mint(uint256,bool)
  "0x51c547f8", // processRewards(bool)
  "0xd9627aa4", // sellToUniswap(address[],uint256,uint256,bool)
  "0xe3476cd4", // takeDebtSnapshot()
  "0xf7654176", // split()
  "0x81281be8", // placeBid(address,uint256,uint256,uint256)
  // "0x0000000f", // eldddhzr(uint256[])        <--- fails to decode
  "0x852a12e3", // redeemUnderlying(uint256) !!!!!!!!!!!!!!!!!!!!!!!!!!!
  "0x9373f432", // setWithdrawWallet(address) !!!!!!!!!!!!!!!!!!!!!!!!!!!
  "0xfa05a657", // mintAllowList(uint256,bytes32[])
  "0xbcf64e05", // burn(uint256,bytes32)  !!!!!!!!!!!!!!!!!!!!!!!!!!!
  "0x9e0a397e", // adoptApes(uint256)
  "0x1e73ff4f", // recruit(uint256)
  "0xc358575c", // mintBear(uint256)
  "0x2e95b6c8", // unoswap(address,uint256,uint256,bytes32[])
  "0x7050ccd9", // getReward(address,bool)
  "0x5a7b87f2", // claimRewards(address[],address[],address[],address[],uint256,uint256,uint256,uint256,uint256)
  "0x4ce6931a", // createReserveAuction(address,uint256,uint256)
  "0x39f47693", // unwrap(address,uint256)   !!!!!!!!!!!!!!!!!!!!!!!!!!!
  "0xe6ab1434", // presale(uint256)   !!!!!!!!!!!!!!!!!!!!!!!!!!!
  "0x91b7f5ed", // setPrice(uint256)
  "0x8b72a2ec", // transferPunk(address,uint256)
  "0xa59f3e0c", // enter(uint256)
  "0xdc788a24", // cancelSwapIntent(uint256) !!!!!!!!!!!!!!!!!!!!!!!!!!!
  "0xb58b98e4", // buyTokenOnSale(uint256,address)
  "0x78ea6103", // claimBuyOrder(address,uint256,address)
  "0xa1903eab", // submit(address)
  "0xa723533e", // mintApe(uint256)
  "0x454a2ab3", // bid(uint256)
  "0x7b44918b", // adoptNLadyApes(uint256,uint256)
  "0x5c11d795", // swapExactTokensForTokensSupportingFeeOnTransferTokens(uint256,uint256,address[],address,uint256)
  "0xb88a802f", // claimReward()
  "0x0752881a", // buyTokens(address,uint256)
  "0xb07f0a41", // exercise(uint256)
  "0x2a32096c", // mintNerdyNuggets(uint256)
  "0x1feed31f", // redeem(address,bool)
  "0x9981509f", // wrapAndTransferETH(uint16,bytes32,uint256,uint32)
  "0x3e200d4b", // stakeRPL(uint256)
  "0x459144fb", // mintTokenOgSale(uint256)
  "0x2195995c", // removeLiquidityWithPermit(address,address,uint256,uint256,uint256,address,uint256,bool,uint8,bytes32,bytes32)  !!!!!!!!!!!!!!!!!!!!!!!!!!!
  "0xb9884772", // mintPreSale(uint256)
  "0x379607f5", // claim(uint256)
  "0x3d2b4a01", // initiateBreeding(uint256,uint256,uint256)
  "0x42966c68", // burn(uint256)
  "0xc2db2c42", // purchaseToken(uint256)
  "0x96b5a755", // cancelAuction(uint256)
  "0xa6417ed6", // exchange_underlying(int128,int128,uint256,uint256)  !!!!!!!!!!!!!!!!!!!!!!!!!!!
  "0x85eb3a35", // depositERC20For(address,address,uint256)  TODO check tx.value
  "0xd9856c21", // addNewToken(string) !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  "0xda89a7b1", // redeemRebalancingSet(address,uint256,bool)
  "0x11ebbf24", // createWallet() !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! using tx.value
  "0xa5e38751", // deploy(uint256) ???
  "0xe4a76726", // addLiquidity(address,address,uint256)
  "0xf25efffc", // settleCurrentAndCreateNewAuction()
  "0x026e402b", // delegate(address,uint256)
  "0xbb8f257b", // setStealthKeys(uint256,uint256,uint256,uint256)  ???
  "0x8b9e4f93", // depositERC20ForUser(address,address,uint256)
  "0xb73c6ce9", // withdrawEarnings()
  "0xd1058e59", // claimAll()
  "0x4641257d", // harvest()
  "0xf3701da2", // build(address)
  "0x12edde5e", // initiateWithdrawal(uint256) !!!!!!!!!!!!!!!!!!!!!!!!!!!
  "0xf4265715", // unmine(uint256)
  "0x30ead760", // exchangeWithTracking(bytes32,uint256,bytes32,address,bytes32)
  "0xe6d66ac8", // sendTokens(address,address,uint256)
  "0xce80a9a1", // claimID(uint256)
  "0x68b85fb1", // 	breed()
  // --------------------------------------

  "0x194f9261", // redeemJuniorTokens()
  "0xacb5fedf", // arb()
  "0xd0f89344", // appendSequencerBatch()
  "0xbedb86fb", // setPause(bool)
  "0xaf27073e", // PresaleMint(uint256)
  "0x63bba05f", // addAndExtendStaking(uint256)
  "0xf8976c9b", // removeWhitelistAllowance(address)
  "0x8ca887ca", // saleMint(uint256)
  "0xfdfba25d", // sendEthToSidechain(bytes32)
  "0x0ed2f344", // mintAstroGator(uint256)
  "0xab00492f", // multiMint(uint256)
  "0xf759867a", // mintPresale(uint256)
  "0x7b99adb1", // setLiquidityCap(uint256)
  "0x0962ef79", // claimRewards(uint256)
  "0x5f6be614", // migrateToken(uint256)
  "0x9ee679e8", // requestWithdrawal(uint256)
  "0x1f5e881f", // _buyTokens(address)
  "0x091dbfd2", // enterBidForPunk(uint256)
  "0x9979ef45", // placeBid(uint256)
  "0xc01a8c84", // confirmTransaction(uint256)
  "0xdb006a75", // redeem(uint256)
  "0x1c1b8772", // update(address)
  "0xc2998238", // enterMarkets(address[])
  "0x3cd29ac8", // preMint()
  "0x3805550f", // exit(bytes)
  "0xc290d691", // pay(uint256)
  "0x46e04a2f", // claimTokens(uint256)
  "0x5c19a95c", // delegate(address)
  // --------------------------------------

  "0x4690d55a", // addSubmission(string,string)
  "0xd9393814", // unStake(address,uint256)
  "0x8568523a", // transferToOwner(address,uint256)
  "0x21c0b342", // claim(address,address)
  "0x7cbc2373", // redeem(uint256,uint256)
  "0x9dc29fac", // burn(address,uint256)
  "0x3f7658fd", // executeSignatures(bytes,bytes)
  "0x82f87117", // claimVolts(uint256,bytes)
  "0x952e68cf", // stake(uint256,uint64)
  "0x3c168eab", // mintNFT(address,uint256)
  "0x628d6cba", // Swapout(uint256,address)
  "0x70135f52", // confirmGuardianAddition(address,address)
  "0xa140ae23", // mintToken(uint256,address)
  "0x83a076be", // gift(uint256,address)
  "0xc44193c3", // offerPunkForSale(uint256,uint256)
  "0x1e9a6950", // redeem(address,uint256)
  "0xe2bbb158", // deposit(uint256,uint256)
  "0xfd9eacd2", // receivePayments()

  "0x4baf782e", // CLAIMREWARD()
  "0xfcd8fdc9", // mintTokens(uint16)
  "0xa26e1186", // deposit(string)
  "0xb3ab66b0", // publicSaleMint(uint256)
  "0x2304f24a", // updateTimelockStatus(address)
  "0x033c1693", // revealBaby(uint256)
  "0x2e17de78", // unstake(uint256)
  "0xa9e7c2e5", // claimToken(uint256)
  "0x6ba4c138", // claim(uint256[])
  "0xc32e7202", // withdrawAndUnwrap(uint256,bool)
  "0x750d4926", // claim_reward(address,address)
  "0x630d8c63", // claimBalance(uint256,uint256)
  "0xa94c7c65", // limitedPrint(address,uint256)
  "0xd2fafb19", // setAmount(address,uint256)
  // --------------------------------------

  "0x98e5b12a", // requestNewRound()
  "0x02329a29", // pause(bool)
  "0x9ae2369d", // syncBalance(uint32)
  "0xf578d9df", // mainMint(uint256)
  "0xb030b34a", // removeBotFromBlacklist(address)
  "0x19165587", // release(address)
  "0x983d95ce", // withdraw(uint256[])
  "0xf64b5f44", // clientDepositL1(uint256,address)
  "0x669f5a51", // mintNFT(uint256,address)
  "0x859b97fe", // cancelAuction(address,uint256)
  "0x5b0fc9c3", // setOwner(bytes32,address)
  // "0x51d0eac4", // sendEths(address[],uint256[])
  "0x3598d8ab", // sellEthForTokenToUniswapV3(bytes,uint256,address)
  "0x10f13a8c", // setText(bytes32,string,string)
  "0x30e6822c", // transferFromChain(address,uint256,uint256)
  "0xad58bdd1", // relayTokens(address,address,uint256)
  "0x00aeef8a", // deposit(uint256,uint256,uint256)
  "0xc73a2d60", // disperseToken(address,address[],uint256[])
  "0x731133e9", // mint(address,uint256,uint256,bytes)
  "0xb6f9de95", // swapExactETHForTokensSupportingFeeOnTransferTokens(uint256,address[],address,uint256)
  "0x0175b1c4", // anySwapInAuto(bytes32,address,address,uint256,uint256)
  "0x4a25d94a", // swapTokensForExactETH(uint256,uint256,address[],address,uint256)
  "0x8803dbee", // swapTokensForExactTokens(uint256,uint256,address[],address,uint256)
  "0xb77d239b", // convertByPath(address[],uint256,uint256,address,address,uint256)
  "0xe0724b6e", // execute(address,bytes,uint256,bytes,uint256,uint256,address,address)
  // --------------------------------------

  "0xe9866550", // setStartingIndex()
  "0x159343c9", // roofSlap(uint256)
  "0xed8f584b", // receiveTokensFromSource(uint256)
  "0x106d08df", // scheduleUnstake(uint256)
  "0xc634d032", // mintToken(uint256)
  "0x1c4b774b", // getReward(uint256)
  "0x454b0608", // migrate(uint256)
  "0x38463cff", // burn(bytes,uint256)
  "0x03ec16d7", // updateReserveAuction(uint256,uint256)
  "0x3f801f91", // proxyAssert(address,uint8,bytes)
  "0xcae9ca51", // approveAndCall(address,uint256,bytes)
  "0x474cf53d", // depositETH(address,address,uint16)
  "0xa41fe49f", // withdraw(uint256,uint256,uint256)
  "0x22895118", // deposit(bytes,bytes,bytes,bytes32)
  "0x803ba26d", // sellTokenForEthToUniswapV3(bytes,uint256,uint256,address)
  "0x6f652e1a", // createOrder(address,uint256,uint256,uint256)
  "0xaacaaf88", // execute(address,bytes,uint256,bytes,uint256,uint256)
  "0x9d73b325", // originSwap(address,address,address,uint256,uint256,uint256)
  "0x89bbb8b2", // poke(uint256[],uint256[],uint8[],bytes32[],bytes32[])
  "0xf305d719", // addLiquidityETH(address,uint256,uint256,uint256,address,uint256)
  "0x0f856267", // smgRelease(bytes32,bytes32,uint256,uint256,address,address,bytes,bytes32)
  "0x6a761202", // execTransaction(address,uint256,bytes,uint8,uint256,uint256,uint256,address,address,bytes)
  //
  // --------------------------------------

  "0xc2c4c5c1", // checkpoint()
  "0x2db11544", // publicMint(uint256)
  "0xc4cf867c", // claimVestedReward(uint256)
  "0x285e7bfd", // newSmartWallet(address)
  "0xbcc0d415", // updateSwapCompletionStatus(bytes32,bool)
  "0x51a60b02", // withdrawDelegated(address,address)
  "0x2d67f0b9", // removeStake(uint256,uint256)
  "0xca800144", // giveAway(address,uint256)
  "0x80500d20", // withdrawETH(address,uint256,address)
  "0xec126c77", // Swapin(bytes32,address,uint256)
  "0x3fe317a6", // verifyMerkle(uint256[],uint256[],uint256,uint256)
  "0x993e1c42", // withdrawERC20For(uint256,address,address,uint256,bytes)
  "0x5ef2c7f0", // setSubnodeRecord(bytes32,bytes32,address,address,uint64)
  "0xab9c4b5d", // flashLoan(address,address[],uint256[],uint256[],address,bytes,uint16)
  // --------------------------------------

  "0x2fb42076", // claimV2()
  "0x049c5c49", // toggleSaleStatus()
  "0x00000002", // razor_balance(bool)
  "0x422df36b", // deactivateOrReduceEditionSupply(uint256)
  "0x92642744", // mintNFT(uint256)
  "0xf14210a6", // withdrawETH(uint256)
  "0x95a2251f", // redeem(address)
  "0xf2fde38b", // transferOwnership(address)
  "0xe054ffc3", // whitelistUser(address,uint256)
  "0x76f2a59a", // instantMint(address,uint256)
  "0x2440fdc2", // makeDeposit(address,uint256,uint256)
  "0xc0f4ed31", // bid(uint256,address,uint256)
  "0x0ad58d2f", // withdraw(uint256,uint256,address)
  "0x384e03db", // add_liquidity(address,uint256[4],uint256)
  "0x58fdb101", // transferToUserWithFee(address,uint256,bytes32,bytes)
  "0x6af479b2", // sellTokenForTokenToUniswapV3(bytes,uint256,uint256,address)
  "0x29941edd", // proposeRoot(bytes32,bytes32,uint256,uint256,uint256)
  "0xe8e33700", // addLiquidity(address,address,uint256,uint256,uint256,uint256,address,uint256)
  "0x8054ae3b", // zapAndDepositAndSwap(address,uint256,address,uint256[],uint256,uint256,uint8,uint8,uint256,uint256)
  // --------------------------------------

  "0xedc9af95", // uni()
  "0x290e4544", // massHarvest()
  "0xc7b8981c", // withdrawRewards()
  "0xbedf0f4a", // Stop()
  "0x372500ab", // claimRewards()
  "0x86d1a69f", // release()
  "0x624fbfdc", // echo(bytes)
  "0x98f3b12d", // claimVestedTokens(address)
  "0xc2ca0ac5", // reveal(uint256)
  "0x4618163e", // mintWhitelist(uint256)
  "0x276569e9", // linearClaimPendingWithdraw(uint256)
  "0xdb6242c3", // setMaxPerMint(uint256)
  "0xd2cf4c18", // unlockTokensToWithdraw(uint256)
  "0x1bf6ddae", // createStake(uint256)
  "0x37bdc99b", // release(uint256)
  "0x7c8255db", // sendGifts(address[])
  "0x5eac6239", // claimRewards(uint256[])
  "0x60759fce", // depositAll(uint256,bool)
  "0x38d07436", // withdraw(uint256,bool)
  "0x56781388", // castVote(uint256,uint8)
  "0x99653fbe", // submitVote(uint256,uint8)
  "0x304e6ade", // setContenthash(bytes32,bytes)
  "0xe542c4a6", // setFilstNPV(uint256,uint256)
  "0xa82ba76f", // buyNFT(address,uint256)
  "0x0983061c", // presaleBuy(uint256,uint256)
  "0x884e17f3", // withdrawPrincipal(uint256,address)
  "0xc3490263", // claim(uint256,uint256)
  "0x955a5a76", // acceptBid(address,uint256)
  "0xb0b02c60", // setUpdateOperator(uint256,address)
  "0x94bf804d", // mint(uint256,address)
  "0xf7d8c883", // breedWithAuto(uint256,uint256)
  "0xf7d97577", // setPrice(uint256,uint256)
  "0xea2c38ae", // manualEpochInit(address[],uint128)
  "0x4e43e495", // submitCheckpoint(bytes,uint256[3][])
  "0x0b4c7e4d", // add_liquidity(uint256[2],uint256)
  "0x02c85991", // distributeMinting(address[],uint256[])
  "0xfa6e671d", // setRelayerApproval(address,address,bool)
  "0x3c423f0b", // withdrawToken(uint256,address,bytes)
  "0xc172715c", // BUY(uint256,address,uint256)
  "0xbd97b375", // unstake(uint256,uint256,uint256)
  "0x5c5b9f8f", // depositTokens(address,uint256,uint256)
  "0xf213159c", // depositTo(address,address,uint256)
  "0x4d49e87d", // addLiquidity(uint256[],uint256,uint256)
  "0xadd975cc", // SELL(uint256,address,uint256,uint256)
  "0x90d25074", // deposit(address,uint256,address,uint256)
  "0x55a2ba68", // transferERC20(address,address,uint256,uint256)
  "0x2e7ba6ef", // claim(uint256,address,uint256,bytes32[])
  "0x51dbb2a7", // selectiveDeposit(address[],uint256[],uint256,uint256)
  "0xa415bcad", // borrow(address,uint256,uint256,uint16,address)
  "0x2eb2c2d6", // safeBatchTransferFrom(address,address,uint256[],uint256[],bytes)
  "0xd2ce7d65", // outboundTransfer(address,address,uint256,uint256,uint256,bytes)
  "0xa9fd8c57", // depositETHAndSwap(address,uint256,uint256,uint8,uint8,uint256,uint256)
  "0x8eb4e0ad", // batchRelay(bytes16[],address[],address[],uint256[],uint16[],uint128[],uint8[],bytes32[],bytes32[],uint8[])
  // --------------------------------------
  "0x150d283d", // buyWithETH()
  "0x5c029ac8", // claimAllProfit()
  "0x35aacecd", // WHITELIST_MINT(uint256)
  "0xbf113baf", // artistMint(uint256)
  "0x59fe8539", // depositAndStake(uint256)
  "0x2ac7a2b3", // whitelistBuy(uint256)
  "0x1028e492", // sendTokensToDestination(bytes32)
  "0x27de9e32", // unbond(uint256)
  "0x6a627842", // mint(address)
  "0x0fbf0a93", // stake(uint256[])
  "0x8293e9af", // withdraw(address,uint112)
  "0x57c90de5", // placeBid(uint256,uint256)
  "0x338b5dea", // depositToken(address,uint256)
  "0xd6febde8", // buy(uint256,uint256)
  "0xb02f0b73", // exitPool(uint256,uint256[])
  "0xc17b5b8c", // updateSellFees(uint256,uint256,uint256)
  "0x8095d564", // updateBuyFees(uint256,uint256,uint256)
  "0x3ca4c96b", // compoundRedeem(uint256,address,uint256)
  "0xd1abb907", // withdrawAndHarvest(uint256,uint256,address)
  "0x9f1d0f59", // swap(address,uint256,uint256)
  "0x02c8986f", // pay(uint256,address,string,bool)
  "0x1ff013f1", // voteProposal(uint8,uint64,bytes32,bytes32)
  "0x58a997f6", // depositERC20(address,address,uint256,uint32,bytes)
  "0xc6a6cf20", // adjustTrove(uint256,uint256,uint256,bool,address,address)
  "0x4e913cd9", // commitBlock(uint32,uint32,bytes32[],bytes,bytes,uint32[])
  "0x0a19b14a", // trade(address,uint256,address,uint256,uint256,uint256,address,uint8,bytes32,bytes32,uint256)
  // --------------------------------------

  "0x02ce728f", // updateExchangeRate()
  "0xbd8616ec", // buyEdition(uint256)
  "0xd98237d1", // pullParentsByBreedingEventId(uint256)
  "0xa5a6cd0b", // produceBlock(uint256)
  "0xef5cfb8c", // claimRewards(address)
  "0xdc97e082", // commitTokens(uint256,bool)
  "0x8609d28c", // depositNative(string,string)
  "0xe43f696e", // setWhiteList(address[],bool)
  "0x4d99dd16", // undelegate(address,uint256)
  "0x4e114e19", // mintForCommunity(address,uint256)
  "0xd79875eb", // sell(uint256,uint256)
  "0xdd2414d4", // registerUser(address,uint256,bytes)
  // --------------------------------------
  "0xb4b72c1d", // claimETHRewards()
  "0x4a131672", // createUniswapPair()
  "0x6faa8b3f", // payBackLoan(uint256)
  "0x4a8011d2", // presaleMintNFT(uint256)
  "0xf4a0a528", // setMintPrice(uint256)
  "0x289137a1", // craft(uint256,uint256)
  "0xc34a03b5", // updateProjectSecondaryMarketRoyaltyPercentage(uint256,uint256)
  "0x7a9b2c6c", // withdrawFromStream(uint256,uint256)
  "0x6ab15071", // buyVoucher(uint256,uint256)
  "0x867904b4", // issue(address,uint256)
  "0x7fd30df0", // mintBulk(address[],uint256[])
  "0x80ed71e4", // deposit(uint256,bool,address)
  "0xd1a1beb4", // mint(address,uint256,bool)
  "0xe2ab691d", // lock(address,uint256,uint256)
  "0x656f3d64", // cook(uint8[],uint256[],bytes[])
  "0x860665b3", // openTrove(uint256,uint256,address,address)
  "0x2505c3d9", // deposit(uint256,uint256,uint256,uint256)
  "0x2036a94d", // transferByPartition(bytes32,address,address,uint256,bytes,bytes)
  // --------------------------------------

  "0x7589cf2f", // emergencyUnstake()
  "0x1998aeef", // bid()
  "0xd0febe4c", // buyTokens()
  "0x5a5e5d58", // mintPublicSale(uint256)
  "0xe97fddc2", // unstakeClaimTokens_new(uint256)
  "0x0c89a0df", // transferTokens(address)
  "0x598b8e71", // deposit(uint256[])
  "0x729ad39e", // airdrop(address[])
  "0x000000a0", // tree_stream(bytes,bytes)
  "0x46aed74e", // redeemAll(address,bool)
  "0x4f31a231", // claim(address,uint96)
  "0x6ecee543", // vote(address,uint56)
  "0x862440e2", // setURI(uint256,string)
  "0x71bf55d4", // initiateBreeding(uint256,uint256)
  "0x2af2d1fe", // reserveFrogs(address,uint256)
  "0xea785a5e", // burnTo(address,uint256)
  "0x1210aac2", // withdrawInterest(uint256,address)
  "0x7eee288d", // unlock(address,uint256)
  "0x9a9c29f6", // settle(uint256,uint256)
  "0x8ca5cbb9", // appendStateBatch(bytes32[],uint256)
  "0xd4dd1594", // updateLandData(int256,int256,string)
  "0x09eb2728", // lockToken(address,uint256,address)
  "0x95e3c50b", // tokenToEthSwapInput(uint256,uint256,uint256)
  "0xdcdc7dd0", // mint(address,uint256,bytes,bytes)
  "0x8e1e280c", // post2(address,uint256,uint256,uint256)
  "0xa016240b", // withdrawInstantlyFrom(address,uint256,address,uint256)
  "0xeedb711c", // withdrawReward(uint256,uint256,uint256,uint8,bytes32,bytes32)
  "0x8a8c523c", // enableTrading()
  "0x4ccd6024", // primaryMintSamurai(uint256)
  // --------------------------------------

  "0xf756fa21", // completeWithdraw()
  "0x9741fb22", // burnSynthsToTarget()
  "0xd294f093", // claimFees()
  "0xc9567bf9", // openTrading()
  "0x8f70ccf7", // setTrading(bool)
  "0xa87a20ce", // updateAnswer(int256)
  "0x55f804b3", // setBaseURI(string)
  "0x131a0680", // store(string)
  "0x9880a9b1", // linearClaimReward(uint256)
  "0x4ad8008a", // depositRewardsFunds(uint256)
  "0x7cb64759", // setMerkleRoot(bytes32)
  "0x51e3fc17", // withdrawLocked(bytes32)
  "0x88fe2be8", // stake(uint128)
  "0xc4d66de8", // initialize(address)
  "0x23caab49", // safeExecuteSignaturesWithAutoGasLimit(bytes,bytes)
  "0xeaa6ef04", // setIcePerSecond(uint256,bool)
  "0x0442bfa8", // setMintPrice(uint256,uint256)
  "0xc95f9d0e", // addLiquidity(uint256,address)
  "0x95991276", // sellGem(address,uint256)
  "0xa7086536", // withdrawOfferForCollectible(uint256,uint256)
  "0xae77c237", // purchase(uint256,address)
  "0x1896f70a", // setResolver(bytes32,address)
  "0x1c3db2e0", // claimComp(address,address[])
  "0x39941fa4", // claim(uint128,uint256,bool)
  "0x13d98d13", // deposit(address,bytes32,bytes)
  "0x03ef9d1f", // sendERC20ToSidechain(bytes32,uint256,address)
  "0xe17376b5", // depositERC20(address,uint104,address)
  "0xb5c5f672", // withdraw(address,uint256,uint256)
  "0x76122903", // claimTokens(uint256,address,bytes32[])
  "0xa8f0802e", // batchSend(address[],uint256[],bytes[])
  "0xf3f094a1", // redeem(address,uint256,address,uint256)
  "0x23c452cd", // bondWithdrawal(address,uint256,bytes32,uint256)
  "0xb438689f", // withdraw(address,bytes,bytes32,bytes32,address,address,uint256,uint256)
  "0x9ca37a0b", // ZapIn(address,address,uint256,uint256,address,bytes,address,bool,bool)
  "0x5b0d5984", // removeLiquidityETHWithPermitSupportingFeeOnTransferTokens(address,uint256,uint256,uint256,address,uint256,bool,uint8,bytes32,bytes32)
  // --------------------------------------

  "0x92a985c0", // buyGaufWithEther()
  "0x15c316fc", // setUpPresale()
  "0x4f2be91f", // add()
  "0x5932ead1", // setCooldownEnabled(bool)
  "0xc4e37095", // setSaleState(bool)
  "0xf9214eec", // claimVestingTokens(address)
  "0x059513a6", // mintMany(uint256)
  "0x849b93e7", // mintDucks(uint256)
  "0x49c32217", // buyPresale(uint256)
  "0x525fa81f", // setLiquidityAddress(address)
  "0x643a7695", // verifyParticipant(address)
  "0x672dca62", // sellTulip(uint256,uint256)
  "0xe7b061e8", // closeSwapIntent(address,uint256)
  "0xb7751c71", // createBid(uint256,uint256)
  "0xf9d83bb5", // bidOnToken(uint256,address)
  "0x2392c189", // resolveTo(address,uint256)
  "0x001773e6", // convertMetToEth(uint256,uint256)
  "0x1688f0b9", // createProxyWithNonce(address,bytes,uint256)
  "0x25519cf2", // setBuyFee(uint256,uint256,uint256)
  "0x58eb2df5", // createMarketItem(address,uint256,uint256)
  "0x1d865c30", // setSellFee(uint256,uint256,uint256)
  "0xe93e40db", // offerCollectibleForSale(uint256,uint256,uint256)
  "0x7d533c1e", // lockTokens(address,address,uint256,uint256)
  "0xdd5b769e", // approveRoot(bytes32,bytes32,uint256,uint256,uint256)
  "0x26ef39d1", // rebalance(address,int24,int24,int24,int24,address,int256)
  "0xf1e03d67", // createEscrow(bytes16,address,address,uint256,uint16,uint32,uint32,uint8,bytes32,bytes32)
  // --------------------------------------

  "0xc53a0292", // increaseNonce()
  "0xa0263c89", // setProtectionSettings(bool)
  "0x23cf0a22", // mint(uint16)
  "0xbc4207fe", // makeWithdrawalRewards(uint256)
  "0xc9b298f1", // presaleMint(uint256)
  "0x1bf33004", // acceptSwap(uint256)
  "0x315a7af3", // finalizeRecovery(address)
  "0x415f1240", // liquidate(uint256)
  "0x09231602", // setGasPriceLimit(uint256)
  "0x21860a05", // airdrop(address)
  "0xfdacd576", // setCompleted(uint256)
  "0x9a408321", // deposit(uint256,bool)
  "0xdc083c12", // mintOly(address,uint256)
  "0x5da2460d", // buyPolicy(uint256,uint256)
  "0x18fccc76", // harvest(uint256,address)
  "0xddd5e1b2", // claim(uint256,address)
  "0x598647f8", // bid(uint256,uint256)
  "0xe67ce706", // buyBaseToken(uint256,uint256,bytes)
  "0xc6427474", // submitTransaction(address,uint256,bytes)
  "0x15f570dc", // burn(address,uint256,string)
  "0x6e11fb1b", // Approve(address,address,uint256)
  "0x7373e4eb", // get(address,address,uint256)
  "0x9d9892cd", // swap(uint256,uint256,uint256)
  "0x8a2e386e", // fundSendToDestinationGas(bytes32,uint256,uint256)
  "0xee52a2f3", // exchange(bytes32,uint256,bytes32)
  "0xd450e04c", // verifyHeaderAndExecuteTx(bytes,bytes,bytes,bytes,bytes)
  "0x4532d776", // withdraw(address,uint256,uint256,uint256)
  // --------------------------------------

  "0x9ac84414", // sweepEth()
  "0xea1644d5", // setMaxWalletSize(uint256)
  "0x3b4b1381", // mintNFTs(uint256)
  "0x74010ece", // setMaxTxnAmount(uint256)
  "0xbcdd9af7", // profitsPayment(uint256)
  "0x6c5d6156", // burn(address[])
  "0xacf1a841", // renew(string,uint256)
  "0xe73a9a25", // mutateApeWithSerum(uint256,uint256)
  "0x69328dec", // withdraw(address,uint256,address)
  // --------------------------------------

  "0x82aa7c68", // enableTrading(uint256)
  "0xf538ba51", // repayETH(uint256,uint256,address)
  "0xa0ab9653", // execute(uint8[],bytes32[],bytes32[],address,uint256,bytes,address,uint256)
  "0x9f678cca", // drip()
  "0x20aa1b80", // setLatestAnswer(uint256)
  "0xb162717e", // settleBondedWithdrawals(address,bytes32[],uint256)

  // --------------------------------------

  "0xc04b8d59", // exactInput((bytes,address,uint256,uint256,uint256))
  "0x0f3b31b2", // multiplexMultiHopSellTokenForToken(address[],(uint8,bytes)[],uint256,uint256)
  "0x415565b0", // transformERC20(address,address,uint256,uint256,(uint32,bytes)[])
  "0x219f5d17", // increaseLiquidity((uint256,uint256,uint256,uint256,uint256,uint256))
  "0xdb3e2198", // exactOutputSingle((address,address,uint24,address,uint256,uint256,uint256,uint160))
  "0x88316456", // mint((address,address,uint24,int24,int24,uint256,uint256,uint256,uint256,address,uint256))
  // --------------------------------------
  // "0xe99a3f80", // matchOrders((address,((bytes4,bytes),uint256),address,((bytes4,bytes),uint256),uint256,uint256,uint256,bytes4,bytes),bytes,(address,((bytes4,bytes),uint256),address,((bytes4,bytes),uint256),uint256,uint256,uint256,bytes4,bytes),bytes)
  "0x73ef9a50", // intendToWithdraw()
  "0xa2f91af2", // cage(uint256)
  "0x315a095d", // withdrawTokens(uint256)
  "0x54eb560a", // run(uint256,bytes32)
  "0x6f8a41e1", // setAuctionReservePrice(uint256,uint256)
  "0xc8fea2fb", // collect(address,address,uint256)
  "0xab23e6fd", // createSale(uint256,address,uint256,bool)
  // --------------------------------------
  "0xce52b9f4", // withdrawSubmission()
  "0x595882b3", // mintAll()
  "0xbb2841c5", // buy_presale(uint256)
  "0x6dd478e3", // mintPrivateSale(uint256)
  "0x049878f3", // join(uint256)
  "0x4e4e5250", // redirect(address,address)
  "0xbf376c7a", // wrap(address,uint256)
  "0xa1448194", // safeMint(address,uint256)
  "0xadc9772e", // stake(address,uint256)
  "0xd91443b7", // withdrawAndMint(uint256,uint256,bytes)
  "0xa706a2e2", // requestUnlock(bytes32,address,bytes4,address)
  // --------------------------------------

  "0xff872602", // removeStrictTxLimit()
  "0x9fb08933", // mintKey(uint256)
  "0x682356c0", // migrateFromLEND(uint256)
  "0x51cff8d9", // withdraw(address)
  "0x4d5693ab", // depositUnderlying(uint256,uint256)
  "0x02279b4a", // depositNative(address,uint256)
  "0x75c1631d", // listToken(uint256,uint256)
  "0x127ffda0", // setUnderlyingPrice(address,uint256)
  "0xd1c2babb", // merge(uint256,uint256)
  "0xd2d0e066", // deposit(address,uint256,uint16)
  "0x2ada8a32", // claim(address,uint256,uint256,bytes)
  "0xecd9ba82", // stakeWithPermit(uint256,uint256,uint8,bytes32,bytes32)
  "0x5668b02e", // stakeWithPermit(uint256,uint256,uint256,uint8,bytes32,bytes32)
  "0xcfc0afeb", // simpleSwap(address,address,uint256,uint256,uint256,address[],bytes,uint256[],uint256[],address,string,bool)
  // --------------------------------------
  "0x7a1eb1b9", // multiplexBatchSellTokenForToken(address,address,(uint8,uint256,bytes)[],uint256,uint256)
  "0xe2864fe3", // cancel((address,((bytes4,bytes),uint256),address,((bytes4,bytes),uint256),uint256,uint256,uint256,bytes4,bytes))
  // --------------------------------------

  "0x291d9549", // removeWhitelisted(address)
  "0x273123b7", // delBot(address)
  "0x3610724e", // buyTokens(uint256)
  "0xb86e321c", // withdrawReward(address)
  "0xad8b9edc", // buyToken(string,string)
  "0x27f91856", // increaseCustodyAllowance(address,uint256)
  "0xafdb3df6", // updatePendingRewardsContributionToNav(uint256,uint256)
  "0xad65d76d", // ethToTokenTransferInput(uint256,uint256,address)
  "0x29cd62ea", // setPubkey(bytes32,bytes32,bytes32)
  "0x0b66f3f5", // multisendToken(address,address[],uint256[])
  "0xe8eda9df", // deposit(address,uint256,address,uint16)
  "0x0b2583c8", // originSwap(address,address,uint256,uint256,uint256)
  "0x91aeeedc", // invoke1CosignerSends(uint8,bytes32,bytes32,uint256,address,bytes)
  "0x6ba40878", // swap(address,address,address,uint256,uint256,address,bytes)
  // --------------------------------------

  "0xf953cec7", // receiveMessage(bytes)
  "0xd9158d21", // payForVerification(bytes32)
  "0x7f00c7a6", // setmaxMintAmount(uint256)
  "0x437823ec", // excludeFromFee(address)
  "0x51c6590a", // addLiquidity(uint256)
  "0x39e899ee", // setWhiteList(address)
  "0xd0ebdbe7", // setManager(address)
  "0x56dd3895", // lock(uint256,bytes8)
  "0x9e0fd998", // updateEditionPrice(uint256,uint256)
  "0x9db5dbe4", // transferERC20(address,address,uint256)
  // --------------------------------------
  "0x41fe00a0", // commitToNextPeriod()
  "0xfeaf8048", // undelegateStake()
  "0xdd473d2b", // withdraw_all()
  "0x68b4cac9", // updateSwapAndLiquifiy(bool)
  "0x8c8fed8b", // mintAgent1(uint256)
  "0x5f51ae72", // mintJailbird(uint256)
  "0x35118f74", // unwoof(uint256)
  "0xc18bc195", // updateMaxWalletAmount(uint256)
  "0x4f52fd17", // removeLiquidity(uint256,bool)
  "0x3f489914", // withdraw(uint8,uint256)
  "0x0e89439b", // stake(uint256,bytes)
  "0xfe9d9303", // burn(uint256,bytes)
  "0x55b10537", // withdrawAllAndHarvest(uint256,address)
  "0xf2465f09", // withdrawFNFT(uint256,uint256)
  "0xbd86e508", // setDelegate(bytes32,address)
  "0x7898e0c2", // setValue(string,uint128,uint128)
  "0x6d78f47a", // issue(address,uint256,address)
  "0x0f41ba4b", // bid(address,uint256,uint256)
  "0xae0b51df", // claim(uint256,uint256,bytes32[])
  "0x13fe24e1", // depositErc20(address,address,uint256,uint256)
  "0x8f42403a", // mint(address,uint40,bytes32,uint256,uint256[],uint256,address,bytes)
  "0xc1169548", // execute(address,address,bytes,uint256,bytes,uint256,uint256,address,address)
  // --------------------------------------
  "0x0a7a1c4d", // action()
  "0x8b30b516", // redeemIdleToken(uint256)
  "0x4957677c", // increase_amount(uint256)
  "0xef706adf", // cancelOffer(uint256)
  "0xff585caf", // callback(uint256)
  "0x690e7c09", // open(uint256)
  "0xae169a50", // claimReward(uint256)
  "0xd6f87109", // deposit(string,bytes)
  "0xdac9594f", // limitLock(string,uint256)
  "0x219dd5b5", // setVerifiedColdWallet(address,address)
  "0xbef54a5e", // dispense(address,uint256)
  "0x9a99b4f0", // claimRewards(address,uint256)
  "0x9470b0bd", // withdrawFrom(address,uint256)
  "0xd73dd623", // increaseApproval(address,uint256)
  "0x5b36389c", // remove_liquidity(uint256,uint256[2])
  "0xd7a169eb", // depositToAndDelegate(address,uint256,address)
  "0x294091cd", // stake(address,uint256,address)
  // --------------------------------------
  "0xdaca07a6", // nTokenClaimIncentives()
  "0x751039fc", // removeLimits()
  "0xa0400066", // flipRegularSale()
  "0xe884f260", // disableTransferDelay()
  "0xe222c7f9", // togglePublicSale()
  "0xfc06d2a6", // sunrise()
  "0x1d9cfd6d", // setSale()
  "0x715018a6", // renounceOwnership()
  "0xfdb5a03e", // reinvest()
  "0x3f4ba83a", // unpause()
  "0x7d7c2a1c", // rebalance()
  "0xbe040fb0", // redeem()
  "0x1cef37e4", // publicSale_status(bool)
  "0xccfe2158", // updateTreasuryWalletAddress(address)
  "0xedd36d91", // mintMetablobs(address)
  "0x08891a32", // submitHash(bytes32)
  "0x6c346590", // JOYtoyMachine(uint256)
  "0x66233126", // claimOne(uint256)
  "0x2b69cbf6", // delegateStake(uint256)
  "0xee8ca3b5", // cancelTask(bytes32)
  "0x79c450a7", // payChainCurrency(uint256)
  "0x7fc6bb3d", // unapproveUnlock(uint256)
  "0xbc337182", // setMaxTx(uint256)
  "0xede4edd0", // exitMarket(address)
  "0x9b1f9e74", // _burn(uint256)
  "0x07883703", // Mint(uint256)
  "0xfa09e630", // withdrawAll(address)
  "0xf0f44260", // setTreasury(address)
  "0xde0e9a3e", // unwrap(uint256)
  "0xea598cb0", // wrap(uint256)
  "0x8d6cc56d", // updatePrice(uint256)
  "0x88c2a0bf", // giveBirth(uint256)
  "0x70480275", // addAdmin(address)
  "0x774f99d0", // setOboApprovalForAll(address,bool)
  "0xde6f24bb", // sendMessage(address,string)
  "0x5138b08c", // settleAuction(address,uint256)
  "0xc83ec04d", // sellVoucher_new(uint256,uint256)
  "0x8b5bdada", // transitForBSC(address,uint256)
  "0x00f714ce", // withdraw(uint256,address)
  "0x4515cef3", // add_liquidity(uint256[3],uint256)
  "0x3049105d", // deposit(uint256[2],uint256[2])
  "0xf1dc3cc9", // remove_liquidity_one_coin(uint256,uint256,uint256)
  "0x0da61ed2", // unlock(uint256,address,bytes32)
  "0xae7b0333", // executeOrder(address,uint256,uint256)
  "0x00000080", // conduct_auto(uint256,uint256,uint256)
  "0x022c0d9f", // swap(uint256,uint256,address,bytes)
  "0xb88d4fde", // safeTransferFrom(address,address,uint256,bytes)
  "0xf3e6ea8a", // swap(address,uint256,uint256,address)
  "0x704c65ee", // transferToAnotherChain(uint256,uint256,address,uint256)
  "0xae1cdde6", // depositNft(uint256,uint256,uint256,uint256)
  "0x45cc0a2f", // updateCoinInfo(string,string,uint256,uint256,uint256)
  "0x02c1927c", // withdrawToken(address,address,uint256,string,bytes32)
  "0x02751cec", // removeLiquidityETH(address,uint256,uint256,uint256,address,uint256)
  // --------------------------------------
  "0x2def6620", // unstake()
  "0x5aabb855", // mintFrog(uint256)
  "0x740d73f3", // addToWhiteList(address[])
  "0x4782f779", // withdrawETH(address,uint256)
  // --------------------------------------
  "0x0a861f2a", // withdrawLiquidity(uint256)
  "0x4d474898", // mine(uint256)
  "0xe4849b32", // sell(uint256)
  "0x278ecde1", // refund(uint256)
  "0xbe27b22c", // claim(bytes,bytes)
  "0x76704de0", // extendLockDuration(uint256,uint256)
  "0x8de69284", // subscribe(address,uint256)
  "0xc7c13b3a", // Withdraw(uint256,bool,uint256)
  "0x94d008ef", // mint(address,uint256,bytes)
  "0xe30ac74e", // migrate(address,address,address,address)
  // --------------------------------------
  "0xaf086c7e", // issueMaxSynths()
  "0x961be391", // cash()
  "0x243adbdd", // buyNft(uint256)
  "0xa27456bb", // fundSubmission(address)
  "0xac431d7a", // collectAndStreamInterest(address)
  "0xf340fa01", // deposit(address)
  "0x7666f125", // setAllowList(address,bool)
  "0x1b5757f2", // claimReserved(address,uint256)
  "0x4447e48c", // configureKeys(uint32,bytes32,bytes32,uint32,bool)
  // --------------------------------------

  "0x8fa90822", // startWithdrawReward()
  "0xf8800b45", // vestEarning()
  "0x4e80619a", // mintWithTreasureKey()
  "0x0f1fa81e", // setSuperToken()
  "0xfc63958e", // requestUnstake()
  "0xafb7bed9", // requestMint()
  "0x96f8f6dd", // flipDropState()
  "0x03ce86f0", // getRewardThenStake()
  "0x2f814575", // togglePublicSaleStarted()
  "0x985d5449", // unvest()
  "0x5e5d2b33", // emergencyTransferExecute()
  "0x50be99ad", // reInvest()
  "0x36f4fb02", // updateEpoch()
  "0xcb1a4fc0", // claimAndStake()
  "0x19aa70e7", // claimDivs()
  "0x1e331f52", // revealWaifus()
  "0xf69e2046", // compound()
  "0xcc7e492e", // refillInstantMintPool()
  "0x6f4a2cd0", // distributeRewards()
  "0x90ed6bf4", // startWithdraw()
  "0x48146113", // userDeposit()
  "0x5b88349d", // claimAirdrop()
  "0xe9a9c50c", // redeemTokens()
  "0xc885bc58", // withdrawReward()
  "0x94655f2b", // printMoney()
  "0x8e1a55fc", // build()
  "0xa475b5dd", // reveal()
  "0xfa45aa00", // executeWithdrawal()
  "0xee97f7f3", // master()
  "0x8fd3ab80", // migrate()
  "0x8456cb59", // pause()
  "0x18178358", // poke()
  "0x775c300c", // deploy()
  "0x8129fc1c", // initialize()
  "0xa4698feb", // getReward(bool)
  "0x6a387fc9", // completeWithdrawals(uint32)
  "0x5f11301b", // setServiceURI(string)
  "0x1eabc116", // buy(uint16)
  "0xd42b7afd", // reserveTickets(uint256)
  "0xd3b5427f", // mintDrop(uint256)
  "0xa9531ce3", // mintGuardianOfAether(uint256)
  "0xd508e623", // deployNewInstance(bytes32)
  "0x8800304e", // depositRepayETH(address)
  "0xf5f6d3af", // sweepTokens(address)
  "0x66102d2d", // mintByUser(uint256)
  "0xc0188b6b", // publicBuy(uint256)
  "0xc486de3a", // mintCrewWithAsteroid(uint256)
  "0x23dcb71f", // SwapNow(uint256)
  "0x1442415e", // claimTokensMembership(uint256)
  "0x09cd3a2c", // harvestForUser(address)
  "0xca9c0bad", // setRandom(address)
  "0xcb3cb5c4", // mintOne(uint256)
  "0x868ff4a2", // whitelistMint(uint256)
  "0x40d097c3", // safeMint(address)
  "0x012ce501", // emergencyUnstake(uint256)
  "0xfdb5fefc", // earn(address)
  "0xaa5f7e26", // compound(uint256)
  "0x283d62ad", // setPoolAdmin(address)
  "0xddf0b009", // queue(uint256)
  "0x295da87d", // burnSynths(uint256)
  "0xb054a9e8", // createWallet(address)
  "0x4a4c560d", // whitelistUser(address)
  "0x96bb1fef", // claimStakingRewards(address)
  "0x7b97008d", // purchaseTokens(uint256)
  "0xd279c191", // claimReward(address)
  "0x0e5c011e", // harvest(address)
  "0x94b918de", // swap(uint256)
  "0xce5494bb", // migrate(address)
  "0xb9a2de3a", // endAuction(uint256)
  "0x49df728c", // withdrawTokens(address)
  "0x44a0d68a", // setCost(uint256)
  "0xc4d252f5", // cancel(bytes32)
  "0x2d06177a", // addManager(address)
  "0x1a695230", // transfer(address)
  "0xdd467064", // lock(uint256)
  "0x163e1e61", // gift(address[])
  "0xa51e1904", // mint_many(address[8])
  "0x491c011a", // claimRewards(address,bool)
  "0xd2423b51", // batch(bytes[],bool)
  "0x654c9bdb", // externalCall(address,bytes)
  "0xb995028a", // addHash(bytes32,uint32)
  "0x782ed90c", // removeLiquidity(uint256,uint32)
  "0xd0def521", // mint(address,string)
  "0x1e59c529", // register(string,address)
  "0x25e90ae9", // mutatePork(uint256,uint256)
  "0x38f72e77", // mintGuardiansOfAether(uint256,uint256)
  "0xc4690d3f", // linearDeposit(uint256,uint128)
  "0x1414acc1", // buyAndSowBeans(uint256,uint256)
  "0xc2e3e62b", // updateRoot(bytes32,uint256)
  "0x57143a61", // lockRifi(address,uint256)
  "0xce6df2b9", // publicMint(address,uint256)
  "0x053992c5", // setSalePrice(uint256,uint256)
  "0xee040645", // mintGift(uint256,address)
  "0xc23b139e", // createMarketSale(address,uint256)
  "0x4d1f0a8e", // setBuyNowPriceListing(uint256,uint128)
  "0x65a8a037", // fusion(uint256,uint256)
  "0x01b22ab1", // speedUpChildReveal(uint256,uint256)
  "0x04826624", // giveawayMint(address,uint256)
  "0x56688700", // addLiquidity(address,uint256)
  "0x8ea6bde3", // claimTokens(uint256,uint256)
  "0x91a50ee1", // withdrawBorrow(address,uint256)
  "0xc04a8a10", // approveDelegation(address,uint256)
  "0xd547741f", // revokeRole(bytes32,address)
  "0x6b8357ac", // collect(address,uint256)
  "0x67c6e39c", // convert(address,uint256)
  "0x39b6b1e5", // cancelBid(address,uint256)
  "0x88888f61", // purchase(uint256,bytes32)
  "0xb723b34e", // mintTo(uint256,address)
  "0x6f4c6443", // invite(address,address)
  "0xabe7f1ab", // remove(address,uint256)
  "0x8418cd99", // contribute(address,uint256)
  "0x485cc955", // initialize(address,address)
  "0x6a206137", // cancelOrder(address,uint256)
  "0x029b2f34", // add_liquidity(uint256[4],uint256)
  "0x538f9406", // updateState(uint256[],uint256[])
  "0xee682473", // enter(bytes,bytes,uint256)
  "0x43a0d066", // deposit(uint256,uint256,bool)
  "0x2befabbf", // mintIdleToken(uint256,bool,address)
  "0x3f579f42", // executeTransaction(address,uint256,bytes)
  "0x65cf71b2", // stakeGoodAccounting(address,uint256,uint40)
  "0xd3fc9864", // mint(address,uint256,string)
  "0x033500d3", // swap721(uint256,uint256,address)
  "0x3ddb3422", // reInvest(uint256,uint256,uint256)
  "0x8ea90923", // evolve(uint256,uint256,uint256)
  "0x6c605b77", // finalizeSwap(bytes32,address,uint256)
  "0x284fa344", // harvest(uint256,uint256,uint256)
  "0x5c833bfd", // redeem(address,uint256,address)
  "0xa8d74d1d", // withdrawCollateral(uint256,uint256,uint256)
  "0x46ab38f1", // exitswapPoolAmountIn(address,uint256,uint256)
  "0x2f2d783d", // claimReward(address,address,uint256)
  "0x0efe6a8b", // deposit(address,uint256,uint256)
  "0x156e29f6", // mint(address,uint256,uint256)
  "0x01e33667", // withdrawToken(address,address,uint256)
  "0xb460af94", // withdraw(uint256,address,address)
  "0x84eb7731", // deposit(uint256,uint256,bytes32[])
  "0x40807049", // transferManyLands(uint256,uint256[],address)
  "0x234b6745", // withdraw(address,uint256,uint256,bool)
  "0xeb391aa8", // deposit(address,uint160,uint32,uint32)
  "0x3e3a1560", // removeLiquidityOneToken(uint256,uint8,uint256,uint256)
  "0x65b2489b", // exchange_underlying(uint256,uint256,uint256,uint256)
  "0x5b41b908", // exchange(uint256,uint256,uint256,uint256)
  "0x573ade81", // repay(address,uint256,uint256,address)
  "0xcb3e394d", // transferFromWithFee(address,address,uint256,uint256)
  "0xa647e8ec", // mint(address,uint256,uint256,uint256)
  "0x232a2c1d", // executeSignatures(uint8[],bytes32[],bytes32[],bytes)
  "0xf6fff9bd", // bulksendToken(address,address[],uint256[],bytes32)
  "0x88280fa0", // claimManyRewards(address[],bytes[],uint256[],bytes[])
  "0x62ad1b83", // operatorSend(address,address,uint256,bytes,bytes)
  "0x394747c5", // exchange(uint256,uint256,uint256,uint256,bool)
  "0xfd5f995b", // batchCancelPairRfqOrdersWithSigner(address,address[],address[],uint256[])
  "0xcc1b4bf6", // createStream(address,uint256,address,uint256,uint256)
  "0x365d0ed7", // deposit(uint256,uint256,uint256,uint256,address)
  "0x6bd9add4", // bondWithHint(uint256,address,address,address,address,address)
  "0x06011a46", // processRollup(bytes,bytes,bytes,bytes,address,address,uint256)
  "0xdfbe4a31", // swapExactTokensForTokens(uint256,uint256,address[],address,uint256,uint256)
  "0xbaa2abde", // removeLiquidity(address,address,uint256,uint256,uint256,address,uint256)
  "0x67bf252f", // leverage(address,uint256,uint256,uint256,uint256,address,uint256,bytes,bytes)
  "0xa4aabb08", // removeLiquidityETHWithPermit(address,address,uint256,uint256,uint256,address,uint256,bool,uint8,bytes32,bytes32)
  // --------------------------------------
  "0xfc6f7865", // collect((uint256,address,uint128,uint128))
  "0xf35b4733", // multiplexBatchSellEthForToken(address,(uint8,uint256,bytes)[],uint256)
  "0xf28c0498", // exactOutput((bytes,address,uint256,uint256,uint256))

  "0x9a2967d2", // multiplexMultiHopSellTokenForEth(address[],(uint8,bytes)[],uint256,uint256)
  "0x77725df6", // multiplexBatchSellTokenForEth(address,(uint8,uint256,bytes)[],uint256,uint256)
  "0x8bdb3913", // exitPool(bytes32,address,address,(address[],uint256[],bytes,bool))
  "0xb95cac28", // joinPool(bytes32,address,address,(address[],uint256[],bytes,bool))
  "0x672a9400", // mint(uint256,uint8,bytes32,bytes32,(address,uint256)[],string)
  "0xc6bf3262", // mint(uint256,uint8,bytes32,bytes32,(address,uint256)[],uint256,string)
  "0xb841d2d2", // mint(uint256,address,uint256,address,(address,address,uint256,uint256,bytes32,bytes32,uint8)[])
  // --------------------------------------
  "0x7d49ec1a", // cancelLimitOrder((address,address,uint128,uint128,uint128,address,address,address,address,bytes32,uint64,uint256))
  "0x945bcec9", // batchSwap(uint8,(bytes32,uint256,uint256,uint256,bytes)[],address[],(address,bool,address,bool),int256[],uint256)
  "0x45269298", // commitBlocks((uint32,uint64,bytes32,uint256,bytes32,bytes32),(bytes32,bytes,uint256,(bytes,uint32)[],uint32,uint32)[])
  "0x54e3f31b", // simpleSwap((address,address,uint256,uint256,uint256,address[],bytes,uint256[],uint256[],address,address,uint256,bytes,uint256,bytes16))
  "0xd9459372", // prepare(((address,address,address,address,address,address,address,address,address,uint256,uint256,bytes32,bytes32),uint256,uint256,bytes,bytes,bytes,bytes))
  "0x9b151a80", // fulfill(((address,address,address,address,address,address,address,address,address,bytes32,bytes32,uint256,uint256,uint256,uint256,uint256),uint256,bytes,bytes,bytes))
  // --------------------------------------

  "0x2a981b5b", // harvestTokens()
  "0xf43146ed", // claimBirdseed()
  "0xf4aac09d", // unstakeWithFee()
  "0xcbf0b276", // claimAllForOwner()
  "0x0b83a727", // claimAllRewards()
  "0xe74f3fbb", // claimVestedTokens()
  "0xe8078d94", // addLiquidity()
  "0xe4fc6b6d", // distribute()
  "0x01c79bee", // takeBonus(uint8)
  "0x938e3d7b", // setContractURI(string)
  "0x02ece38a", // placeEditionBid(uint256)
  "0xbecf7741", // claimById(uint256)
  "0xaa15017c", // initWithdraw(uint256)
  "0x63bb23be", // claimAllPendingReward(uint256)
  "0x6e23e8e5", // setNodeInMaintenance(uint256)
  "0x0f6795f2", // processExits(address)
  "0x2e54bf95", // withdrawFromSP(uint256)
  "0xa6e158f8", // redeemTokens(uint256)
  "0x2f6c493c", // unlock(address)
  "0x97107d6d", // setProxy(address)
  "0xa91ee0dc", // setRegistry(address)
  "0x5a51d1df", // sendReward(uint256[])
  "0x481cae33", // openEgg(uint256,bool)
  "0xcf456ae7", // setMinter(address,bool)
  "0xb31c9cc8", // recruit(uint256,uint256)
  "0x3e649d11", // bridgeSend(uint256,address)
  "0x32852f18", // linearWithdraw(uint256,uint128)
  "0xeba2389d", // setTeamShare(address,uint256)
  "0x44c32a61", // closeAllocation(address,bytes32)
  "0xcdb98676", // updateRequiredHarvestAmount(address,uint256)
  "0xe6ff41eb", // meow(address,uint256)
  "0x9b172b35", // stakeRewards(uint256,address)
  "0xc2a672e0", // unstake(address,uint256)
  "0xd7136328", // vote_for_gauge_weights(address,uint256)
  "0xcbce4c97", // gift(address,uint256)
  "0x83c592cf", // stake(uint256,bytes32)
  "0xe22b62de", // setSellFees(uint8,uint8,uint8)
  "0x7d49d875", // remove_liquidity(uint256,uint256[4])
  "0xf6089e12", // melt(uint256[],uint256[])
  "0xe63d38ed", // disperseEther(address[],uint256[])
  "0x6a4a3458", // withdrawAllSingle(bool,uint256,uint256)
  "0x79b91d69", // unstake(uint256,uint256,bool)
  "0x31f09265", // withdraw(address,uint256,bytes)
  "0xd04c6983", // sell(uint256,uint256,address)
  "0xefa9a9be", // claim(uint256,uint256,uint256)
  "0xf5298aca", // burn(address,uint256,uint256)
  "0xc11cd833", // enterWithPermit(uint256,uint256,uint8,bytes32,bytes32)
  "0x54bacd13", // externalSwap(address,address,address,address,uint256,uint256,bytes,bool,uint256)
  // --------------------------------------
  "0xd07490f6", // executeActionWithAtomicBatchCalls((address,uint96,bytes)[],uint256,bytes,bytes)
  "0xb0705b42", // executeBlocks(((uint32,uint64,bytes32,uint256,bytes32,bytes32),bytes[])[])
  "0x83981808", // proveBlocks((uint32,uint64,bytes32,uint256,bytes32,bytes32)[],(uint256[],uint256[],uint256[],uint8[],uint256[16]))
  "0xd7fd19dd", // relayMessage(address,address,bytes,uint256,(bytes32,(uint256,bytes32,uint256,uint256,bytes),(uint256,bytes32[]),bytes,bytes))
  // --------------------------------------

  "0x2298bf26", // payableMint()
  "0x34393743", // togglePresale()
  "0x7e15144b", // toggleWhitelist()
  "0x54dd1da4", // releaseVestedTokens()
  "0xe98cf987", // turnOnSale()
  "0xa9b1d507", // makeWallet()
  "0xbe9a6555", // start()
  "0xd7bb99ba", // contribute()
  "0xa69df4b5", // unlock()
  "0x0e6878a3", // claimRewards(bool)
  "0x2d81a78e", // claim(bool)
  "0x5f7c7af1", // mint(uint96)
  "0xc7f04e65", // purchase(uint32)
  "0x7786ed54", // mintGaia(uint256)
  "0x79c76e1a", // flush(address)
  "0x451bd5c9", // mintDuck(uint256)
  "0x9c1d9907", // buyEditionToken(uint256)
  "0xb0d1818c", // withdrawSurplus(uint256)
  "0xa76cb64e", // finalizeScan(uint256)
  "0x5dc47b57", // undelegateStash(bytes32)
  "0x6b999053", // unblockBot(address)
  "0xc0fe1af8", // clear(uint256)
  "0xfe784eaa", // swapTokens(uint256)
  "0x871c128d", // updateGasForProcessing(uint256)
  "0x6680ac0b", // accrue(address)
  "0xbc25cf77", // skim(address)
  "0x611e68d4", // transferTreasurer(address)
  "0x797af627", // confirm(bytes32)
  "0x0b7e9c44", // payout(address)
  "0x34c7fec9", // vest(uint256[])
  "0x7f649783", // addToWhitelist(address[])
  "0xd8b2114e", // kycVerdict(address,bool)
  "0x7333aad7", // stake(uint64,uint64)
  "0x37859963", // updateProjectWebsite(uint256,string)
  "0xa3b2cca6", // updateProjectDescription(uint256,string)
  "0x298d3f3f", // updateBorrowLTV(address,uint256)
  "0x82afec32", // doSwap(uint256,uint256)
  "0xf6f94e2c", // unlockInterval(uint256,uint256)
  "0xe470d5cf", // configure(address,address)
  "0x826fc391", // updateProjectMaxInvocations(uint256,uint256)
  "0xd441e2b7", // openBuyOrder(address,uint256)
  "0xe20981ca", // withdrawRewards(address,address)
  "0x18cca7a6", // makeRequestedWithdrawal(uint256,uint256)
  "0xb31597ad", // redeem(bytes32,bytes32)
  "0x484b973c", // ownerMint(address,uint256)
  "0x39c38266", // manualBuy(address,uint256)
  "0x6e553f65", // deposit(uint256,address)
  "0x7deb6025", // buy(uint256,address)
  "0x31f208b3", // reportBeacon(uint256,uint64,uint32)
  "0xdc9ae17d", // burn(bytes,address,uint256)
  "0x3dfc99b9", // unlockInTransferFunds(address,uint256,bytes32)
  "0xe13208b4", // updateProjectAdditionalPayeeInfo(uint256,address,uint256)
  "0x8d8798bf", // bondTransferRoot(bytes32,uint256,uint256)
  "0xbf31196f", // offerPunkForSaleToAddress(uint256,uint256,address)
  "0x00000046", // swapBackAll23252139(uint256,address,address,bool)
  "0xc04fd2d7", // stakeWithPermit(uint256,uint8,bytes32,bytes32)
  "0x3f33133a", // purchase(uint256,address,address,bytes)
  "0xab67aa58", // transferFrom(address,address,uint256,bytes)
  "0x8006cc3e", // collectSwapFeesForBTC(address,uint256,uint256,uint256)
  "0x43bcfab6", // redeem(address,uint256,uint256,address)
  "0xb4dfe93d", // changeStateToPending(address,address[],bytes[],uint256[])
  "0xac063725", // placeBid(address,uint256,uint256,uint256,bytes)
  "0xe11ed8ff", // addToken(address,uint8,bool,bool,address,address)
  "0xf31c1262", // depositERC20(uint256,address,address,uint256,uint256)
  "0x97da6d30", // withdraw(address,address,address,uint256,uint256)
  "0x61359f69", // burnWithPermit(address,uint256,uint256,uint256,uint8,bytes32,bytes32)
  "0xd505accf", // permit(address,address,uint256,uint256,uint8,bytes32,bytes32)
  "0x9e832315", // marketBuyOrdersWithEth((address,address,address,address,uint256,uint256,uint256,uint256,uint256,uint256,bytes,bytes,bytes,bytes)[],uint256,bytes[],uint256[],address[])
  // --------------------------------------

  "0x74b31672", // claimMint()
  "0xcdd5f2c8", // closeEpoch()
  "0xdb599698", // mintPresale()
  "0xe507a8a4", // withdrawYield()
  "0xfe686b48", // startExitCooldown()
  "0xaf14052c", // rebase()
  "0x3a4b66f1", // stake()
  "0xb460481d", // redeemShares()
  "0xf9fc0d07", // processRewards()
  "0x458efde3", // vest()
  "0xefc81a8c", // create()
  "0x924de9b7", // updateSwapEnabled(bool)
  "0xacec338a", // setActive(bool)
  "0xe70875ad", // depositBB(uint256)
  "0xb450dfce", // depositAA(uint256)
  "0xd4a907a2", // bidOrBuy(uint256)
  "0xe91a7ca6", // depositNFT(uint256)
  "0xc49b3d54", // mintForSelf(uint256)
  "0xadefd90c", // setLiquiditFee(uint256)
  "0xb00b52f1", // adventure(uint256)
  "0x32d342b7", // withdrawLocked(uint256)
  "0xff551c84", // processPendingActions(uint256)
  "0x659dd2b4", // createBid(uint256)
  "0x0ead3e72", // payJoiningFee(address)
  "0xe63bc62d", // processProposal(uint256)
  "0x44c7d6ef", // claimStake(uint256)
  "0xf088d547", // buy(address)
  "0xa2b40d19", // changePrice(uint256)
  "0x80f646d0", // changeSignerList(address,bool)
  "0x903d4296", // withdraw(uint256,uint8)
  "0x57f7789e", // setTokenUri(uint256,string)
  "0x948108f7", // addFunds(uint256,uint96)
  "0x1c35f962", // sale(uint256,uint256)
  "0x165e0dc6", // hunt(uint256,uint256)
  "0xa5902fac", // claimDistribution(uint256,address)
  "0x489a0fdc", // mintGift(address,uint256)
  "0xf64db050", // migrateAll(address,address)
  "0x8381e182", // unstake(uint256,address)
  "0x727b7acf", // emergencyRecovery(address,uint256)
  "0xd9ecad7b", // breed(uint256,uint256)
  "0x057466ea", // buyToken(uint256,uint256)
  "0x0cf20cc9", // withdrawBalance(address,uint256)
  "0xeac449d9", // revoke(address,uint256)
  "0x99dfdb3e", // mint(uint8,bytes32,bytes32)
  "0x3e12170f", // stake(address,uint256,bytes)
  "0xcd6ef9b1", // unstake(uint256,uint256,bytes)
  "0xe677d601", // rerollTrait(uint256,string,uint256)
  "0x0889d1f0", // crossOut(string,uint256,address)
  "0xf78d880e", // swapIn(address,address,uint256)
  "0xf779a193", // sellNFT(address,uint256,uint256)
  "0x62c06767", // sweep(address,address,uint256)
  "0xd9caed12", // withdraw(address,address,uint256)
  "0xf2498c9e", // whitelistClaim(uint256,uint256,bytes32[])
  "0x66514c97", // borrowETH(address,uint256,uint256,uint16)
  "0xf6364846", // newImmediatePayment(address,address,uint256,string)
  "0xfaf5ec0c", // stake(uint256,address,uint256,uint256)
  "0x02c5fcf8", // repayETH(address,uint256,uint256,address)
  "0x60491d24", // relock(address,uint256,uint256,uint256)
  "0x32db5470", // submitBalances(uint256,uint256,uint256,uint256)
  "0x91695586", // swap(uint8,uint8,uint256,uint256,uint256)
  "0xc0a47c93", // setMasterContractApproval(address,address,bool,uint8,bytes32,bytes32)
  "0xd5280589", // registerAndDepositERC20(address,uint256,bytes,uint256,uint256,uint256)
  "0xa6ab9a7a", // stake(uint256,address,uint256,uint256,uint256,uint256)
  "0xf7fcd384", // sellToLiquidityProvider(address,address,address,address,uint256,uint256,bytes)
  "0x28828b1e", // claimMultipleAndWithdrawBalance(bytes32[],uint256[],bytes32[],address[],uint256[],bytes32[])
  // --------------------------------------

  "0x07f62b2d", // getNewAddress()
  "0x512d3911", // createDDDawgs()
  "0x78c3a692", // mintGenesis()
  "0xa10866ef", // flipPublicSaleState()
  "0xc39e7dbb", // userWithdrawBaseTokens()
  "0xfff6cae9", // sync()
  "0xf6326fb3", // depositETH()
  "0x7fec8d38", // trigger()
  "0x2e92abdd", // withdrawDividends()
  "0xedca914c", // buyTicket()
  "0x29519457", // setTokenPaused(bool)
  "0x70a1903d", // harvest(bool)
  "0x841718a6", // setSaleActive(bool)
  "0x95d4063f", // claim(uint8)
  "0xaab8ab0c", // claim(uint64)
  "0x7d793edd", // stake(uint56)
  "0x78226e10", // claimCode(uint256)
  "0x2a360631", // addBotWallet(address)
  "0x1560a24b", // EmergencyRebase(uint256)
  "0x52719789", // sowBeans(uint256)
  "0xbc7df091", // reserveNFTs(uint256)
  "0x9cb5fece", // mintPublicMultipleTokens(uint256)
  "0x0c5b55f9", // createSelected(bytes32)
  "0x985c4af5", // withdrawExit(address)
  "0xdc647e29", // updateRatio(uint256)
  "0x41655ee5", // directStake(uint256)
  "0xf84354f1", // includeAccount(address)
  "0xf2cc0c18", // excludeAccount(address)
  "0xec28438a", // setMaxTxAmount(uint256)
  "0xf53fe70f", // swapToTON(uint256)
  "0xe9af0292", // claimComp(address)
  "0x82dc1ec4", // addPauser(address)
  "0xa4b910fb", // WithdrawToken(uint256)
  "0x8264fe98", // buyPunk(uint256)
  "0x7f8661a1", // exit(uint256)
  "0x6198e339", // unlock(uint256)
  "0x880cdc31", // updateOwner(address)
  "0xf8e93ef9", // mint(uint256[])
  "0x15373e3d", // castVote(uint256,bool)
  "0xc9d27afe", // vote(uint256,bool)
  "0x10087fb1", // stake(uint256,uint8)
  "0xc39cbef1", // changeName(uint256,string)
  "0x67cbd84c", // _set_Fees(uint256,uint256)
  "0xd2147618", // setWinnerAndPrice(address,uint256)
  "0x0dc28efe", // adminMint(uint256,address)
  "0xc3a71999", // mintAdmin(address,uint256)
  "0xc814cf89", // updateRatioAndFee(uint256,uint256)
  "0x5d07c350", // mintPresale(uint256,uint256)
  "0xdae11b7c", // sendGenerator(uint256,uint256)
  "0xd599ee08", // updateReservePriceForReserveAuction(uint256,uint128)
  "0x97dc86cf", // updateProjectPricePerTokenInWei(uint256,uint256)
  "0x9a9f2bbb", // issueExactSetFromETH(address,uint256)
  "0x63961069", // withdrawAnyTokens(address,uint256)
  "0xf9c894a8", // burnPrint(uint256,uint256)
  "0xc6066272", // withdrawStake(address,uint256)
  "0x9e2c8a5b", // unstake(uint256,uint256)
  "0x98590ef9", // cancel(address,uint256)
  "0xc6caa959", // sellRock(uint256,uint256)
  "0x2cfd3005", // mint(address,bytes32)
  "0x23165b75", // acceptBidForPunk(uint256,uint256)
  "0x2c512d6e", // getTokens(address,uint256)
  "0x99a88ec4", // upgrade(address,address)
  "0xba45b0b8", // transfer(address,address)
  "0xe683ff07", // listToken(string,string,uint256)
  "0xa8662a47", // migrate(address,bytes32,uint256)
  "0x2e2d2984", // deposit(uint256,address,address)
  "0x8f975a64", // sendERC20(address,address,uint256)
  "0x2f1afbac", // doAirdrop(address,address[],uint256[])
  "0xec35005d", // mintFromPool(uint256,uint256,uint256,uint256)
  "0xd331bef7", // withdraw(uint256,uint256,uint256,address)
  "0x830ef41b", // transferERC20(uint256,address,address,uint256)
  "0x3d7d3f5a", // createSaleAuction(uint256,uint256,uint256,uint256)
  "0x02b9446c", // deposit(address,address,address,uint256,uint256)
  "0xf549ab42", // unstakeToken((address,address,uint256,uint256,address),uint256)

  // --------------------------------------

  "0xa7ba3dff", // mintCandle()
  "0x34918dfd", // flipSaleState()
  "0x0cd7c478", // setmarketingFeePercent(uint256)
  "0x72cf0d58", // configureSnipe(address,uint256,address,uint256)
  // --------------------------------------
  "0xf375b253", // _removeSniper(address)
  "0xd543dbeb", // setMaxTxPercent(uint256)
  "0x2ab30838", // liftMaxTx()
  "0xa6334231", // allowtrading()
  "0x203e727e", // updateMaxTxnAmount(uint256)
  "0xe8c4c43c", // upliftTxAmount
  "0x5d098b38", // setMarketingWallet(address)
  "0x01339c21", // launch()
  "0xc1a106c6", // createLP(address,uint256)
  "0xd9c3c610", // deleteTimeStamp()
  "0x7290b621", // setMaxToken(uint256,uint256,uint256)
  "0xb66a0e5d", // startSale()
  "0xe36b0b37", // stopSale()
  "0xbec47e0e", // addTokenType(string,address)
  "0x21c03a97", // setTradingOpen(bool)
  "0x7fef3594", // isExcludedFromMax(address,bool)
  "0x109b1ee6", // _mints(address,uint256)
  "0x36358b52", // allowlistPresale(uint256)
  "0x9a894cab", // setAllowlistPresaleActive(bool)
  "0x2e6b106c", // setPresaleWallets(address[],uint256[])
  "0x13af4035", // setOwner(address)
  "0x2f2ff15d", // grantRole(bytes32,address)
  "0x21775c92", // toggleMinting(bool)
  "0x2ebe3fbb", // initVault(address)
  "0x40a4dddd", // setURIs(string,string,string)
  "0xe2ab10ce", // earlyPurchase(uint256,bytes32[])
  "0xdae0ffc9", // setSaleInformation(uint256,uint256,uint256,uint256,uint256,uint256,bytes32)
  "0x55c9ce1d", // createPass(string,uint256,uint256,uint256,string)
  "0xe34383c7", // setSalePublic(uint256,bool)
  "0x449a52f8", // mintTo(address,uint256)
  "0xf1215d25", // deposit(uint256,string)
  "0x7316c2e9", // setDeadBlock(bool)
  "0xe6db992f", // purgeSniper()
  // --------------------------------------
  "0xe07fa3c1", // withdrawFund()
  "0xac1c3d04", // generateToken()
  "0xf0fc6bca", // claimDividend()
  "0x4bb278f3", // finalize()
  "0x02fe5305", // setURI(string)
  "0x8acc11fb", // buyIDO(uint256)
  "0x7e108d52", // initiateWithdraw(uint256)
  "0x3969dfb4", // withdrawAndUnwrap(uint256)
  "0xb8c6ceea", // setBatchCount(uint256)
  "0xf0bedbe2", // clearDelegate(bytes32)
  "0x586fb420", // mintNFTS(uint256)
  "0x55d93d49", // buyNextStep(uint256)
  "0x5d1e9307", // relayTokens(address)
  "0xb5e98b3b", // exec(bytes32)
  "0x035d9f2a", // summon(uint256)
  "0xefd0cbf9", // mintPublic(uint256)
  "0x576f35e3", // setBatchSize(uint256)
  "0x24ca984e", // addRouter(address)
  "0x89476069", // withdrawToken(address)
  "0xf435f5a7", // lock(address)
  "0xa81c804e", // addToWhitelistBulk(address[])
  "0xec93e5f0", // sendToEVMLike(uint32,address)
  "0x1dd31ef3", // bridgeERC20(address,uint256)
  "0x8f893752", // sendLove(address,uint256)
  "0xd258191e", // registerAddressesProvider(address,uint256)
  "0x594dd432", // claimRewards(uint256,uint256)
  "0xeb685c47", // setTokenPrice(uint256,uint256)
  "0xd1e9dcbf", // serverForceGameEnd(address,uint256)
  "0x96e4ee3d", // convert(uint256,uint256)
  "0x3d5d190c", // mint(uint256[],uint256[])
  //"0x51d0eac4", // sendEths(address[],uint256[])
  "0x52346412", // transfers(address[],uint256[])
  "0x710a9f68", // execute(uint256,address,bytes)
  "0xfdeade31", // release(uint24,address,address)
  "0x952867b1", // transferToUserWithFee(address,uint256,bytes32)
  "0x0b573638", // ethToTokenTransferOutput(uint256,uint256,address)
  "0x3111e7b3", // claimRewards(address[],uint256,address)
  "0x9386ce2b", // withdraw(address,uint256,uint256,uint8,bytes32,bytes32)
  "0x66de01a8", // transferIdeaTokens(uint256,uint256,address,uint256,uint256,uint256)
  "0xab114805", // closeCreditAccount(address,(address[],uint256)[])
  "0xfc3fc90a", // setBotWallets(address[])
  // --------------------------------------
  "0xc0246668", // excludeFromFees(address,bool)
  "0x730c1888", // setAutoLPBurnSettings(uint256,uint256,bool)
  "0x20b42a95", // startWithdrawStake()
  "0xedc9723c", // mintFromVesting()
  "0x5cf4f865", // withdrawMarketing()
  "0xd2eb86ee", // publicSaleMint()
  "0x14f710fe", // mintNFT()
  "0xb9f8092b", // rollToNextOption()
  "0x42f5d95d", // AllUnLock()
  "0xb3423eec", // requestWithdraw()
  "0xca7ce3ec", // setWhitelistSale(bool)
  "0xd897833e", // setSaleStatus(bool)
  "0xb1b39337", // withdrawTokens(uint8)
  "0x7ba0e2e7", // mint(bytes)
  "0x41de0e52", // mintNFT(uint32)
  "0x59f5e0ce", // purchase(string)
  "0x3aa18088", // mint2(uint256)
  "0x75ce258d", // depositBeans(uint256)
  "0xcf9d4afa", // initContract(address)
  "0x7ab42006", // mintInu(uint256)
  "0x4ee408de", // mintTiger(uint256)
  "0x7835c635", // preSaleMint(uint256)
  "0x6a775714", // updateUserPrice(uint256)
  "0x2e993611", // settleAuction(uint256)
  "0xbe1d24ad", // exitPool(uint256)
  "0x60b47a50", // mintCard(uint256)
  "0x3bd5d173", // deliver(uint256)
  "0x46951954", // updateCode(address)
  "0x32fe596f", // addVouch(address)
  "0xd4d9bdcd", // approveHash(bytes32)
  "0x979bc638", // withdrawBidForPunk(uint256)
  "0xf9f92be4", // blacklist(address)
  "0x7547c7a3", // stakeTokens(uint256)
  "0xddc63262", // harvest(uint256)
  "0xb214faa5", // deposit(bytes32)
  "0xfe0d94c1", // execute(uint256)
  "0x524fa7b9", // whitelistAdd(address)
  "0xd65a06b0", // redeem(uint256,bool)
  "0xedab62f5", // migrate(uint256,uint8)
  "0xc8fd6ed0", // unstake(uint256,bytes)
  "0x85c855f3", // setStake(uint96,uint128)
  "0x2524a496", // mintGiveaway(uint256,address)
  "0x1b60efb0", // mintGiveaway(address,uint256)
  "0x58d3232f", // withdrawReward(address,uint256)
  "0xd8ded14b", // setPermission(address,uint256)
  "0xa5d059ca", // unbond(address,uint256)
  "0x3542aee2", // mintByOwner(address,uint256)
  "0xf9609f08", // deposit(address,address)
  "0xa645ff5f", // send(address[],uint256[])
  "0xa04a0908", // execute(address,bytes,uint256)
  "0xb61d27f6", // execute(address,uint256,bytes)
  "0x2ed3509e", // performSwitch(uint256,uint256,address)
  "0xa1789085", // depositAndBorrow(address,uint256,uint256)
  "0x19424222", // approveAndCall(address,uint256,bytes32)
  "0x98968f15", // purchase(address,uint256,uint256)
  "0x3215b483", // getTokensByMerkleProof(bytes32[],address,uint256)
  "0xad5cc918", // remove_liquidity(address,uint256,uint256[4])
  "0x517a55a3", // remove_liquidity_one_coin(uint256,int128,uint256,bool)
  "0x9b214f77", // safeExecuteOrder(address,uint256,uint256,bytes)
  "0x30e94541", // CreateNewPool(address,uint64,uint256,address)
  "0xc858f5f9", // borrow(address,uint256,uint256,uint16)
  "0x39e3aea2", // unStakeNFT(uint256,uint128,uint256,address)
  "0x5288ba4b", // openCreditAccount(uint256,address,uint256,uint256)
  "0xe323f825", // depositTo(address,uint256,address,address)
  "0x72a9f042", // saveAndStake(address,address,address,uint256)
  "0x1f7fdffa", // mintBatch(address,uint256[],uint256[],bytes)
  "0xc96616e1", // transferNFT(address,uint256,uint16,bytes32,uint32)
  // --------------------------------------
  "0x183e7ce4", // receiveNewTokens()
  "0x3e07ce5b", // removeBuyLimit()
  "0xf247e3de", // getIncome()
  "0x5b70ea9f", // freeMint()
  "0x4a58db19", // addDeposit()
  "0xe341afdf", // mainSale(uint8)
  "0x33eba49a", // mintToken(string)
  "0x2f97a10e", // bridgeSend(uint256)
  "0x9c6e9b8d", // mintViaBurn(uint256)
  "0xf52cbf0e", // wrapAndRelayTokens(address)
  "0x55a63bf4", // setWhitelistSupply(uint256)
  "0xc18cfe86", // sweepAll(address)
  "0x023245d7", // withdrawNFT(uint256)
  "0x9155f619", // openBooster(uint256)
  "0x523a3f08", // withdrawReward(uint256)
  "0xbb29998e", // test(address)
  "0xdded49cb", // setadmin(address)
  "0x305ec69e", // finish(address)
  "0x2d296bf1", // buyToken(uint256)
  "0x144fa6d7", // setToken(address)
  "0x162094c4", // setTokenURI(uint256,string)
  "0xb8875343", // setActiveDateTime(uint256,uint256)
  "0xb9288e46", // bridgeAndCraft(uint256,uint256)
  "0x4352fa9f", // setPrices(address[],uint256[])
  "0x9dcaa6c9", // setDelegationParameters(uint32,uint32,uint32)
  "0xd514da50", // withdrawPendingBalance(address,address,uint128)
  "0x3d3d7714", // withdrawPayment(address,address,uint256)
  "0xbf6eac2f", // stake(address,address,uint256)
  "0x38111242", // saveViaMint(address,address,address,address,uint256,uint256,bool)
  // --------------------------------------
  "0x2d5a5d34", // blacklistAccount(address,bool)
  "0xaacebbe3", // updateMarketingWallet(address)
  "0x1816467f", // updateDevWallet(address)
  "0xb515566a", // setBots(address[])
  // --------------------------------------
  "0x1a48c5d2", // redeemJuniorUnderlying()
  "0xc4d3f9d9", // payMiner()
  "0xe044205e", // transmuteClaimAndWithdraw()
  "0x6fc3eaec", // manualsend()
  "0xf011a7af", // claimProfit()
  "0x63bd1d4a", // payout()
  "0x14107f3c", // buy(uint8)
  "0x9bf6fa57", // heartbeat(uint64)
  "0x7e62eab8", // withdraw(int256)
  "0xbec3aa75", // submitStake(uint256)
  "0x135ccc47", // strategyHarvest(uint256)
  "0x458a1d6e", // unstake(uint128)
  "0x99c2def4", // endStake(bytes16)
  "0xfb307b47", // setStonePrice(uint256)
  "0xc44bef75", // setStartTimestamp(uint256)
  "0x32c18c1e", // claimPresent(uint256)
  "0x54830771", // setStarTime(uint256)
  "0x20e409b4", // mintBatch(uint256)
  "0xc8c18842", // hatch(uint256)
  "0xeaf3b7c2", // getUnlocked(uint256)
  "0x9f06aa08", // collateralize(uint256)
  "0x271f88b4", // setAmount(uint256)
  "0xccb98ffc", // setEndTime(uint256)
  "0x45977d03", // upgrade(uint256)
  "0xee8c4bbf", // getBounty(uint256)
  "0xc4c1c94f", // add(address[])
  "0x61b69abd", // createProxy(address,bytes)
  "0xc48fa115", // attestToken(address,uint32)
  "0x60b02f70", // reserveMint(uint256,address)
  "0xb390c0ab", // burn(uint256,uint256)
  "0x79c65068", // mintToken(address,uint256)
  "0xfff3a01b", // transfer(address,string,uint256)
  "0x83e67dc6", // mintTrailer(uint256,uint256,uint256)
  "0x9b5e1687", // breed(uint256,uint256,uint256)
  "0x2b83cccd", // redeem(address,uint256,uint256)
  "0x55776b77", // addLiquidity(address,uint256,uint256)
  "0x02acc94b", // mint(uint256,uint256,uint256)
  "0xb064478f", // transferMultiToken(address,address[],uint256[])
  "0x00c24087", // removeLiquidity(address,uint256,address,uint256,uint256)
  "0x92a34f72", // mintAndStake(address,address,address,uint256,uint256)
  "0xfbce0393", // create(string,string,address[],uint256[],uint256,address)
  "0x4515641a", // executeAllowanceTransfer(address,address,address,uint96,address,uint96,address,bytes)
  "0xfe9ff09c", // moveWithSignature(address,address,uint256,bytes32,uint256,uint8,bytes32,bytes32)
  // --------------------------------------
  "0xe653da08", // setSellFee(uint256,uint256,uint256,uint256)
  "0xa457c2d7", // decreaseAllowance(address,uint256)  !!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  // --------------------------------------
  "0x4401bbfd", // reservedRedemption()
  "0x12741bd2", // updateOsmAndEthAOracleRelayer()
  "0x2ae168a6", // startDraw()
  "0x0572b0cc", // getRewards()
  "0xc89e4361", // delegate()
  "0x7362377b", // withdrawEther()
  "0x2e6e504a", // trusteeWithdraw()
  "0x3c952764", // setOnlyWhitelisted(bool)
  "0x4585e33b", // performUpkeep(bytes)
  "0xdea4ccf2", // unstake(uint96)
  "0x61f129ad", // stake(uint96)
  "0x0599c284", // setAddress(string)
  "0x042fe81a", // setMaxTransferAmountRate(uint256)
  "0xa66d3da4", // buyGaufWithLink(uint256)
  "0xcef22444", // setElPrice(uint256)
  "0x31c864e8", // safeMint(uint256)
  "0x0468b925", // callMoonShot(uint256)
  "0x51eb05a6", // updatePool(uint256)
  "0x97dc4a13", // airdrop(uint256)
  "0x4b85fd55", // setAutoBirthFee(uint256)
  "0x0a3b0a4f", // add(address)
  "0x5f788d65", // provideToSP(uint256,address)
  "0x050225ea", // giveaway(address,uint256)
  "0xecb586a5", // remove_liquidity(uint256,uint256[3])
  "0xbcc91d61", // sendEther(address[],uint256[])
  "0xdf22db88", // burn(uint256,bool,uint256)
  "0xf84af835", // deposit(uint256,uint256,uint64)
  "0xac44ff31", // claim(uint256,uint256,address)
  "0xf8b6a1a4", // limitedPrint(address,uint256,bytes32)
  "0x30e0914c", // commit(address,address,uint256)
  "0x1ffbe7f9", // sendToCosmos(address,bytes32,uint256)
  "0xdb61c76e", // buy(address,uint256,address)
  "0x6e9c931c", // stake(uint256,address,uint256)
  "0x095bcdb6", // transfer(address,uint256,uint256)
  "0x10aec4a6", // mint(uint256,uint8,bytes32,bytes32)
  "0x1cabf08f", // send(address,uint256,uint32,bytes32)
  "0xc24d5a5c", // makeBid(address,uint256,address,uint128)
  "0x241dc2df", // anySwapOut(address,address,uint256,uint256)
  "0x77f61403", // mint(string,address,uint256,bytes32,bytes)
  "0xfbc5cfc3", // collect(address,uint256,uint256,bytes32,bytes32,uint8)
  "0x831347e3", // depositWithPermit(uint256,uint256,uint256,uint256,address,uint256,uint256,uint256,uint8,bytes32,bytes32)
  // --------------------------------------
  "0xdee00f35", // getApprovalType(address,uint256)
  "0xfa461e33", // uniswapV3SwapCallback(int256,int256,bytes)
  "0x571ac8b0", // approveMax(address)
  "0xcab372ce", // approveMaxMinusOne(address)
  "0x639d71a9", // approveZeroThenMax(address)
  "0xab3fdd50", // approveZeroThenMaxMinusOne(address)
  "0xb3a2af13", // callPositionManager(bytes)
  "0x1f0464d1", // multicall(bytes32,bytes[])
  "0x5ae401dc", // multicall(uint256,bytes[])
  "0xac9650d8", // multicall(bytes[])
  "0xf2d5d56b", // pull(address,uint256)
  "0x12210e8a", // refundETH()
  "0xf3995c67", // selfPermit(address,uint256,uint256,uint8,bytes32,bytes32)
  "0x4659a494", // selfPermitAllowed(address,uint256,uint256,uint8,bytes32,bytes32)
  "0xa4a78f0c", // selfPermitAllowedIfNecessary(address,uint256,uint256,uint8,bytes32,bytes32)
  "0xc2e3140a", // selfPermitIfNecessary(address,uint256,uint256,uint8,bytes32,bytes32)
  "0x472b43f3", // swapExactTokensForTokens(uint256,uint256,address[],address)
  "0x42712a67", // swapTokensForExactTokens(uint256,uint256,address[],address)
  "0xdf2ab5bb", // sweepToken(address,uint256,address)
  "0xe90a182f", // sweepToken(address,uint256)
  "0x3068c554", // sweepTokenWithFee(address,uint256,uint256,address)
  "0xe0e189a0", // sweepTokenWithFee(address,uint256,address,uint256,address)
  "0x49404b7c", // unwrapWETH9(uint256,address)
  "0x49616997", // unwrapWETH9(uint256)
  "0x9b2c0a37", // unwrapWETH9WithFee(uint256,address,uint256,address)
  "0xd4ef38de", // unwrapWETH9WithFee(uint256,uint256,address)
  "0x1c58db4f", // wrapETH(uint256)
  // --------------------------------------
  "0xb858183f", // exactInput((bytes,address,uint256,uint256))
  "0x04e45aaf", // exactInputSingle((address,address,uint24,address,uint256,uint256,uint160))
  "0x09b81346", // exactOutput((bytes,address,uint256,uint256))
  "0x5023b4df", // exactOutputSingle((address,address,uint24,address,uint256,uint256,uint160))
  "0xf100b205", // increaseLiquidity((address,address,uint256,uint256,uint256))
  "0x11ed56c9", // mint((address,address,uint24,int24,int24,uint256,uint256,address))
  "0x9a114cb2", // claimTokens(uint256,bytes32[])
  "0xf851a440", // admin()
  "0x8f283970", // changeAdmin(address)
  "0x5c60da1b", // implementation()
  "0x3659cfe6", // upgradeTo(address)
  "0x4f1ef286", // upgradeToAndCall(address,bytes)
  // --------------------------------------
  "0x702f7da0", // swapBnbForTokens()
  "0xc2e65e42", // withdrawBalanceOwner()
  "0x12b48932", // emitShare()
  "0x3be52fc4", // notifyRewards()
  "0x24349671", // withdrawShare()
  "0x3c6ffd99", // notifyProfitSharing()
  "0xd2d1fb25", // claimAllocation()
  "0xd47eaa37", // withdrawAllBase()
  "0xb33712c5", // unpauseContract()
  "0x5fd8c710", // withdrawBalance()
  "0x5674a3ed", // runLottery()
  "0x1aa3a008", // register()
  "0xe4eae6b7", // deposit(uint96)
  "0x45576f94", // createToken(string)
  "0x83bf4609", // createTemplate(string)
  "0xbc431b0d", // withdrawPpblz(uint256)
  "0x256d52f6", // mintTicket(uint256)
  "0x6abd26a7", // buyforstakingwithexactEHTforToken(uint256)
  "0xf1cccf56", // buyMany(uint256)
  "0x81a36eec", // updateSubscription(uint256)
  "0x2537d5fd", // requestDelegatedStakeWithdrawal(uint256)
  "0x02b43373", // resultReserveAuction(uint256)
  "0x7012663e", // placeBidOnReserveAuction(uint256)
  "0xa4146733", // mintFree(uint256)
  "0x131f4104", // preSale(uint256)
  "0xc49fc085", // mintWithOldKnc(uint256)
  "0x64ab8675", // calculateRewards(address)
  "0x36b8b425", // recomputeOnAuctionSystemCoinLimit(address)
  "0xa24a8d0f", // _setCharityFee(uint256)
  "0x018f43d6", // _setLpFee(uint256)
  "0xfac2b9ba", // updateStartBlock(uint256)
  "0x33a100ca", // setStrategy(address)
  "0x3c6b16ab", // notifyRewardAmount(uint256)
  "0x819b25ba", // reserve(uint256)
  "0x8588b2c5", // adopt(uint256)
  "0x1ac3ddeb", // withdrawFee(address)
  "0xf6eeff1e", // punkNoLongerForSale(uint256)
  "0xbd94b005", // cancelSale(uint256)
  "0x2199d5cd", // registerUser(address)
  "0xdf8de3e7", // claimTokens(address)
  "0x8b147245", // update(bytes32)
  "0xbd66528a", // claim(bytes32)
  "0xf98a4eca", // executeVote(uint256)
  "0x5e1045ec", // addWhiteList(address[])
  "0xb41c9eda", // setBlacklistWallet(address,bool)
  "0xf26748e2", // mint(uint8,uint256)
  "0x30b39a62", // withdraw(string,uint256)
  "0x8e27d719", // deposit(string,uint256)
  "0x9f93f779", // mintPublic(address,uint256)
  "0x408cbf94", // mintOwner(address,uint256)
  "0xfc41aa09", // addRewards(uint256,uint256)
  "0x17b18c89", // stakeLocked(uint256,uint256)
  "0xf940e385", // withdraw(address,address)
  "0xc4a0db96", // redeem(uint256,uint256[])
  "0x1e79a85b", // transferPoint(uint32,address,bool)
  "0x68573107", // batchMint(address[],uint256[])
  "0x1eaaa045", // add(uint256,address,bool)
  "0x64482f79", // set(uint256,uint256,bool)
  "0xc4ccdeea", // removeLiquidity(uint256,address,uint256)
  "0xd511a6db", // upgrade(uint256,address,uint256)
  "0xe9dae5ed", // setTaxes(uint256,uint256,uint256)
  "0x4abc2202", // addItemToMarket(uint256,address,uint256)
  "0xe1f811ac", // sponsorProposal(uint256,uint256,uint256)
  "0xbb7e70ef", // build(address,uint256,address)
  "0x5ceae9c4", // repay(address,uint256,address)
  "0x9fe3220c", // strikeLore(address,uint256,uint256,bool)
  "0xf9f585dd", // buyShares(address[],uint256[],uint256[])
  "0x5cffe9de", // flashLoan(address,address,uint256,bytes)
  "0x7e3ca9ea", // mintMany(address,uint256,uint256,uint256)
  "0x94e93d8d", // clipperSwap(address,uint256,address,address,uint256)
  "0x70142269", // claim(address,uint256,uint256,uint256,uint8,bytes32,bytes32)
  "0xd8f6eb5b", // ZapOut(address,uint256,address,address,uint256,address,bytes,address,bool)
  // --------------------------------------
  "0xc74cac20", // initiateRewardClaim()
  "0xd54f9d09", // lockShardsAndClaim()
  "0x2bfea0e4", // unpauseTransfer()
  "0xec715a31", // releaseToken()
  "0xf5209922", // spreadDiseases(uint256)
  "0x150bde03", // purchaseNFT(uint256)
  "0x60ed390a", // releaseMIMO(address)
  "0x29975b43", // addWhitelistedAddress(address)
  "0x5312ea8e", // emergencyWithdraw(uint256)
  "0x525f8a5c", // setSaleStartTime(uint256)
  "0xb3ab15fb", // setOperator(address)
  "0x57f7bf2d", // stake(bool,uint256)
  "0xf8e1152a", // rolloverDeposit(uint64,uint64)
  "0x9c5bb73a", // submitMintRequest(uint168,uint32)
  "0x58dd175d", // presaleMint(uint256,uint256)
  "0x05b7cdd3", // makeOffer(uint256,uint256)
  "0x9cb4a9f3", // transit(address,uint256)
  "0xb9b8c246", // invest(address,uint256)
  "0x9e281a98", // withdrawToken(address,uint256)
  "0xbb08187f", // setBirth(uint256,uint256,uint256)
  "0x2fa6cbe9", // setImplementation(address,address,address)
  "0x49561dc4", // withdrawReward(uint256,address,address)
  "0x21a6a29c", // unstakeExactStake(uint256,uint256,uint256)
  "0xa5f2a152", // transferTo(address,address,uint256)
  "0xdd9b86c1", // depositToken(address,uint256,string,string)
  "0x76e3cabc", // hodl(uint24,uint96,uint16,address,address)
  "0xfa6b3b53", // claim(uint256,uint256,uint8,bytes32,bytes32)
  /*!!!!!!!!!!!*/ "0x0000004d", // swap26309536(address,address,uint256,uint256,uint256)    // !!!!!!! TODO check
  "0xf12d394f", // execute(uint8[],bytes32[],bytes32[],address,uint256,bytes)
  // --------------------------------------
  // --------------------------------------
  "0x906e9dd0", // setMarketingAddress(address)
  "0x630fcbfb", // initializeEIP712()
  "0xb4b4f63e", // setupContractId()

  "0x9173b139", // mapToken(address,address,bytes32)
  "0xd233a3c7", // remapToken(address,address,bytes32)
  "0x0c3894bb", // cleanMapToken(address rootToken,address childToken)
  "0x8f0d94b2", // editSaleRestrictions(uint8,uint8,uint8)
  // --------------------------------------
  "0x008ef7cf", // claimForSelf()
  "0xd01c44cd", // claimVested()
  "0x14f049dc", // registerIDO()
  "0x6d60e6c1", // flipClaimState()
  "0x5be7fde8", // releaseAll()
  "0xd2f7265a", // exchange()
  "0xd4807fb2", // initializeRound()
  "0xb287c8ed", // publicSale(uint256)
  "0x784e9830", // withdrawAndClaimPool2(uint256)
  "0x1a066473", // stakePool2(uint256)
  "0x178a8569", // mintCollectibles(uint256)
  "0x9f08b319", // approveSwap(address)
  "0xf868e766", // userDeposit(uint256)
  "0x061c82d0", // setTaxFeePercent(uint256)
  "0x9342c8f4", // withdrawRewards(uint256)
  "0x6930fd2a", // claimAll(uint256)
  "0xe71bdf41", // addDelegate(address)
  "0x74de4ec4", // addReward(uint256)
  "0x379e2919", // setDevFeePercent(uint256)
  "0xc810a539", // recordUTXOSweepMinerFee(uint256,bytes32)
  "0x05fe138b", // removeLiquidity(uint256,address)
  "0x01e4f53a", // relayTokens(address,uint256)
  "0xab3b87fe", // setOwner(address,uint256)
  "0xad68ebf7", // migrate(address,uint256)
  "0x90b98a11", // sendCoin(address,uint256)
  "0xa543ccea", // setWithdrawalAddress(address,address,bool)
  "0xba214c3c", // submitPrices(uint256,uint256,uint256)
  "0x0c51b88f", // stake(address,uint256,uint256)
  "0x33b61727", // mintVariant(uint32,uint8,uint8,uint8,uint8)
  "0xbeb9addf", // sendEth(address,uint256,bytes32,bytes32)
  "0x605629d6", // transferWithPermit(address,address,uint256,uint256,uint8,bytes32,bytes32)
  "0xe3ee160e", // transferWithAuthorization(address,address,uint256,uint256,uint256,bytes32,uint8,bytes32,bytes32)
  "0x85f6d155", // register(string,address,uint256,bytes32)
  // --------------------------------------
  "0x37563293", // claimReflection()
  "0x868ca49d", // readyToSync()
  "0x8597f89b", // withdrawAllWithAllReward()
  "0x90386bbf", // withdrawAllETH()
  "0xeb5068f2", // exitWallet()
  "0x66adeb8c", // transferBalance()
  "0xf751cd8f", // drop()
  "0xabc6fd0b", // disburse()
  "0x6b9f96ea", // flush()
  "0xa2e62045", // update()
  "0x312ff839", // processExpiredLocks(bool)
  "0xb423fe67", // setPublicSaleStatus(bool)
  "0x6fdca5e0", // setOpen(bool)
  "0xe177bb9b", // registerCode(string)
  "0x9094c763", // vote(uint32)
  "0x15670439", // mintLilHustlaz(uint256)
  "0xc6b68169", // sellOusdForUsdc(uint256)
  "0x7cfb41b8", // cancelTrade(bytes32)
  "0x253480bd", // giveawayPork1984(address)
  "0x4287084f", // openMint(uint256)
  "0x2ad540ed", // creatorClaim(uint256)
  "0x8467be0d", // batchMint(uint256)
  "0xeb5021f8", // normalMint(uint256)
  "0xb5c4c47b", // createCollectible(uint256)
  "0x1d7ef879", // addBotToBlacklist(address)
  "0x02387a7b", // withdraw(uint128)
  "0xf3368f90", // updateRecurringFee(uint256)
  "0x9edb4fb7", // updateNaasRequestingFee(uint256)
  "0xae749c42", // updateRequestingFee(uint256)
  "0xf1e4c866", // supplyOrder(uint256)
  "0xba2238d0", // tdtToTbtc(uint256)
  "0xd8b0b547", // startScan(uint256)
  "0xf4136f7f", // expireCover(uint256)
  "0x27a14fc2", // setMaxWalletAmount(uint256)
  "0xf37ac61c", // heal(uint256)
  "0x939624ab", // removeStake(uint256)
  "0x53556559", // exchange(uint256)
  "0x5e1e1004", // setPaymentAddress(address)
  "0xf19e75d4", // ownerMint(uint256)
  "0xb948348c", // purchaseTicket(uint256)
  "0x20c5429b", // revoke(uint256)
  "0xb0b92263", // setTotalTokens(uint256)
  "0x84ac33ec", // deregister(address)
  "0xa932ed0d", // whitelistRemove(address)
  "0x694bcbfc", // approveMultiSwap(bytes32[])
  "0xb391c508", // claim(bytes32[])
  "0x878fd10d", // withdrawWETH(uint256,bool)
  "0xa2ed4088", // setAllowedArtist(address,bool)
  "0xfb6ff865", // awardItem(uint256,bool)
  "0x5a3b74b9", // setUserUseReserveAsCollateral(address,bool)
  "0xc46ac389", // claimByLootId(uint256,uint8)
  "0x691562a0", // mint(address,uint8)
  "0xb35dcee0", // bridgeTransfer(address,uint256)
  "0x79eb8d1d", // setMaxTransactionAmountsPerK(uint256,uint256)
  "0x4f14ea4e", // WITHDRAW(address,uint256)
  "0x66afd8ef", // release(bytes32,uint256)
  "0x08fab167", // withdraw(address,uint128)
  "0xbef870ca", // privateMint(uint256,address)
  "0x50938af6", // withdrawAccruedYieldAndAdd(uint256,uint256)
  "0xd52c57e0", // ownerMint(uint256,address)
  "0xab4f2de5", // extractionProfit(uint256,uint256)
  "0x13bac820", // wrap(uint256,address)
  "0xc71959bb", // writeTransaction(address,uint256)
  "0x7b0d772e", // openSellOrder(address,uint256)
  "0x2fe5e3a6", // transByContract(address,uint256)
  "0x84f10492", // ownerWithdrawToken(address,uint256)
  "0x76d07f6b", // withdrawBounty(uint256,address)
  "0x15eb349e", // ragequit(uint256,uint256)
  "0x43d3461a", // transcoder(uint256,uint256)
  "0xd8aed145", // repay(uint256,uint256)
  "0xddd64dbb", // updateNetworkFee(uint256,uint256)
  "0x813d2ad8", // cancelSale(uint256,address)
  "0xa515366a", // bond(address,uint256)
  "0x522f6815", // withdrawEther(address,uint256)
  "0x50be7cdb", // setBuyFees(uint8,uint8,uint8)
  "0xdf133bca", // vote(uint256,bool,bool)
  "0x51d0eac4", // sendEths(address[],uint256[])
  "0x078e2097", // setMarketProxy(uint256,address,bool)
  "0x33cfcd3b", // withdraw(uint256,uint256,bool)
  "0x8e24a764", // addMintRequest(uint256,string,string)
  "0x7df2b357", // submitSignature(bytes32,bytes32,uint8)
  "0xc4159559", // provideRedemptionSignature(uint8,bytes32,bytes32)
  "0xa4202615", // makeAdoptionOfferToAddress(bytes5,uint256,address)
  "0x5e6f6c77", // claimSellOrder(address,uint256,address)
  "0x22e0658e", // acceptBidOBO(uint256,address,uint256)
  "0x71f4d38c", // cancelBid(uint256,address,uint256)
  "0x44004cc1", // withdrawERC20(address,address,uint256)
  "0xe88390aa", // coinSendDifferentValue(address,address[],uint256[])
  "0xad8733ca", // multisend(address,address[],uint256[])
  "0x1a3d0068", // setAdjustment(bool,uint256,uint256,uint256)
  "0x82857a03", // approveAndCallWithSender(address,uint256,bytes4,bytes)
  "0x257011b6", // userLock(bytes32,uint256,uint256,bytes)
  "0xd01a42d7", // deployKeyRingAndWalletAndCall(address,address,address,bytes)
  "0x84666b08", // setSellTax(uint256,uint256,uint256,uint256)
  "0x4a970be7", // depositWithPermit(uint256,uint256,uint8,bytes32,bytes32)
  "0x42859ab3", // execute(uint256,uint256,uint256,uint256,uint256)
  "0xbadb999b", // sell(uint256,uint256,uint256,address,uint256)
  "0x4b8ffc76", // withdraw(uint256,uint256,uint256,uint256,uint256,uint256)
  "0x01173672", // spendERC20(address,address,uint256,uint8[],bytes32[],bytes32[])
  // --------------------------------------
  "0xaf2ce614", // _setMaxWalletSizePercent(uint256)
  "0x469629a9", // setBothFees(uint16,uint16,uint16,uint16,uint16,uint16,uint16,uint16,uint16,uint16)
  "0x1090b1b5", // translimitedStatus(bool)
  "0xc3c8cd80", // manualswap()
  "0x3f3cf56c", // setMaxTxPercent(uint256,uint256)

  "0xf4293890", // manualSend()
  "0x51bc3c85", // manualSwap()
  "0xe01af92c", // setSwapEnabled(bool)
  "0x303adcd8", // _setSellTaxMultiplier(uint8)
  "0x5880b873", // _setTaxFee(uint256)
  "0x28667162", // _setTeamFee(uint256)
  "0xa7f404e2", // addUniswapPair(address)
  "0xa6931ed7", // removeUniswapPair(address)
  "0x1bbae6e0", // _setMaxTxAmount(uint256)
  "0xd5aed6bf", // setUniswapPair(address)
  "0xbea9849e", // setUniswapRouter(address)
  "0x2baa715f", // addInBlackList(address)
  "0xea2f0b37", // includeInFee(address)
  "0x4a49ac4c", // removeFromBlackList(address)
  "0x1b51972c", // rescueETHFromContract()
  "0x35368e47", // changedeployerWallet(address)
  // --------------------------------------
  "0x728d41c9", // setMaxWalletLimit(uint256)
  "0x2a9319cb", // setMinLiquidityPercent(uint256)
  "0xc49b9a80", // setSwapAndLiquifyEnabled(bool)
  "0x9a679cf6", // changeDevelopmentWallet(address)
  "0xe5eec444", // changeFee(uint256,uint256,uint256)
  "0xbb85c6d1", // changeMarketingWallet(address)
  "0x7e2e0a82", // turnOffTrading()
  "0x6513dc61", // turnOnTrading()
  "0xc9ed7443", // updateNumTokensSellToAddToLiquidity(uint256)
  "0x1d813813", // transferAnyBEP20Tokens(address,address,uint256)
  // --------------------------------------
  "0x00b8cf2a", // blockBots(address[])
  "0xa2a957bb", // setFee(uint256,uint256,uint256,uint256)
  "0x98a5c315", // setMinSwapTokensThreshold(uint256)
  "0x6d8aa8f8", // toggleSwap(bool)
  "0xc0e6b46e", // setRouterPercent(uint256)
  "0x31c2d847", // delBots(address[])
  "0x45596e2e", // setFeeRate(uint256)
  "0x0b78f9c0", // setFees(uint256,uint256)
  "0xdcb0e0ad", // toggleImpactFee(bool)
  "0x73f54a11", // updateTaxAdd(address)
  // --------------------------------------
  "0x05bbedb6", // setBlacklistEnabledMultiple(address[],bool)
  "0x590ffdce", // setExcludedFromFees(address,bool)
  "0x8965b55d", // setTaxesSell(uint16,uint16,uint16)
  "0xde3a3b69", // intializeContract(address[],uint256[],address)
  // -
  "0x38b6e407", // endGrantAuthentication(address)
  "0xef7f3834", // grantInitialAuthentication(address)
  "0x53376d1f", // revokeAuthentication(address)
  "0xd4e8e063", // startGrantAuthentication(address)
  // --------------------------------------
  "0x6ef8d66d", // renouncePauser()
  "0x66188463", // decreaseApproval(address,uint256)
  "0x30176e13", // setBaseTokenURI(string)
  "0xc714e89c", // setMaxItems(uint256)
  "0x3bbed4a0", // setRecipient(address)
  "0x5bfe024d", // whitelistMint(uint256,uint256,bytes32,bytes32[])
  // --------------------------------------
  "0x79cc6790", // burnFrom(address,uint256)
  "0xb20d7fa9", // delegateBySig(address,address,uint256,uint8,bytes32,bytes32)
  "0x1171bda9", // recoverERC20(address,address,uint256)
  "0xfc4e51f6", // recoverERC721(address,address,uint256,bytes)
  // --------------------------------------
  "0x8f15b414", // initialize(string,string,address,address)
  "0xc6c3bbe6", // mint(address,address,uint256)
  "0x5559a36d", // redeem(uint256,uint256,string)
  "0x696434a5", // transferLegacyOwnership(address)
  "0x959b8c3f", // authorizeOperator(address)
  "0x7c0a893d", // blockAccount(address)
  "0x7d64bcb4", // finishMinting()
  "0xfc673c4f", // operatorBurn(address,uint256,bytes,bytes)
  "0x36568abe", // renounceRole(bytes32,address)
  "0xfad8b32a", // revokeOperator(address)
  "0x9bd9bbc6", // send(address,uint256,bytes)
  "0x4d78fdc6", // unblockAccount(address)
  "0xc91c0462", // activateMint()
  "0xb68e8c91", // activatePresale()
  "0x00b6849f", // airDrop(address[])
  "0x2e56f71e", // deactivateMint()
  "0x290fd843", // deactivatePresale()
  "0x150b7a02", // onERC721Received(address,address,uint256,bytes)
  "0xc6f6f216", // setMaxPerTx(uint256)
  "0xe268e4d3", // setMaxPerWallet(uint256)
  "0x10969523", // setProvenanceHash(string)
  "0x046dc166", // setSignerAddress(address)
  "0xd5dc3efd", // setStartingIndexBlock()
  "0x1749dc26", // presaleMint(uint256,bytes32,bytes32,uint8)
  "0xd2a09c71", // enableSwap(bool)
  "0x806e35a7", // manualswapTreasury()
  "0x52f7c988", // setFee(uint256,uint256)
  "0x64f5a5bb", // setMaxTxLimit(uint256)
  "0xa22d4832", // setPairAddress(address)
  "0xfc24e8f2", // updateTreasury(address,address)
  // --------------------------------------
  "0x0bd3a7f9", // excludeFromLimit(address)
  "0x91d919a9", // includeInLimit(address)
  "0x3685d419", // includeInReward(address)
  "0x1465d929", // setBuyFee(uint16,uint16,uint16,uint16,uint16)
  "0xf0f165af", // setNumTokensSellToAddToLiquidity(uint256)
  "0x4cfd4a92", // setSellFee(uint16,uint16,uint16,uint16,uint16)
  "0x3e3d26f9", // updateExchangeWallet(address)
  "0x50aa2977", // updatePartnershipsWallet(address)
  // --------------------------------------
  "0x7a9e5e4b", // setAuthority(address)
  "0x10ed7621", // changeGiftSupply(uint256)
  "0x46e79ffc", // changePauseState()
  "0xd082a3f7", // changePresaleState()
  "0x60e5bb85", // changePublicState()
  "0x52e97326", // changeTotalSupply(uint256)
  "0xa5fd7bec", // giftMint(address[])
  "0x48b75044", // release(address,address)
  "0xda3ef23f", // setBaseExtension(string)
  "0x6e0e5b19", // setIsBurnEnabled(bool)
  "0xf2c4ce1e", // setNotRevealedURI(string)
  // --------------------------------------
  "0x634fa004", // claimPresale(uint256,bytes32[])
  "0x89fac867", // setMedataDataUri(string)
  "0xc54e73e3", // setPresale(bool)
  "0x7e324479", // setCommitmentAges(uint256,uint256)
  "0x530e784f", // setPriceOracle(address)
  "0xc3cda520", // delegateBySig(address,uint256,uint256,uint8,bytes32,bytes32)
  "0xace849c6", // batchAirdrop(uint256[],address[])
  "0x077f224a", // initialize(string,string,address)
  "0x896b24b7", // startAuction(uint256,uint256,uint256,uint256,uint256,uint256,bool)
  "0x789e3a55", // startSale(uint256,uint256,uint256,uint256,bool)
  "0xe3e1e8ef", // presaleMint(uint256,bytes32[])
  "0xa0084951", // mintThingie(uint256)
  "0x424a991d", // mintThingieBatch(uint256[])
  "0xcdd366b1", // setFlufAddress(address)
  "0x05be3f38", // setImmutableIPFSBucket(string)
  "0x2c1e5b83", // setMutableIPFSBucket(string)
  "0xa8307a32", // setRenamePrice(uint256)
  "0x9f96cd25", // setStateToParty()
  "0x23af8827", // setStateToSetup()
  "0xe0df5b6f", // setTokenURI(string)
  "0xd46d79bb", // withdrawAllEth(address)
  "0x5efb942c", // changeNameAndDescription(uint256,string,string)
  "0xe9c714f2", // _acceptAdmin()
  "0x601a0bf1", // _reduceReserves(uint256)
  "0x4576b5db", // _setComptroller(address)
  "0xf2b3abbd", // _setInterestRateModel(address)
  "0xb71d1a0c", // _setPendingAdmin(address)
  "0xfca7820b", // _setReserveFactor(uint256)
  "0xa6afed95", // accrueInterest()
  "0x3af9e669", // balanceOfUnderlying(address)
  "0x17bfdfbc", // borrowBalanceCurrent(address)
  "0xbd6d894d", // exchangeRateCurrent()
  "0xf5e3c462", // liquidateBorrow(address,uint256,address)
  "0x2608f818", // repayBorrowBehalf(address,uint256)
  "0xb2a02ff1", // seize(address,address,uint256)
  "0x73acee98", // totalBorrowsCurrent()
  // --------------------------------------
  "0x4e71e0c8", // claimOwnership()
  "0x296f4000", // delegateApprove(address,uint256,address)
  "0x93d3173a", // delegateDecreaseApproval(address,uint256,address)
  "0x554249b3", // delegateIncreaseApproval(address,uint256,address)
  "0x9cd1a121", // delegateTransfer(address,uint256,address)
  "0x4df6b45d", // delegateTransferFrom(address,address,uint256,address)
  "0x9a6a30a4", // reclaimEther(address)
  "0x88ee39cc", // reclaimToken(address,address)
  "0xd01dd6d2", // setBlacklisted(address,bool)
  "0x52006050", // setBurnBounds(uint256,uint256)
  "0x80749656", // setCanBurn(address,bool)
  // --------------------------------------
  "0x983b2d56", // addMinter(address)
  "0x309e11fc", // blockRelay()
  "0x2850d20e", // createCardAndMintToken(uint256,uint16,uint8,uint16,bytes32,uint16,address)
  "0xe5951e60", // migrateTokens(uint256[])
  "0x5f112c68", // renounceMinter(address)
  "0xcfbd4885", // revokeMinter(address)
  "0x53390100", // setNextContract(address)
  "0x99e0dd7c", // setTokenURIPrefix(string)
  // --------------------------------------
  "0xa8a778ae", // pool_burn_from(address,uint256)
  "0xb4f56b26", // pool_mint(address,uint256)
  "0x4b3161c2", // setFRAXAddress(address)
  "0x37a30bbc", // setFXSMinDAO(uint256)
  "0x7adbf973", // setOracle(address)
  "0xbdacb303", // setTimelock(address)
  "0x45791d19", // toggleVotes()
  // --------------------------------------
  "0x091646a9", // AddToCEX(bool)
  "0x89e7b81b", // manualswapcustom(uint256)
  "0xe4a0f9f5", // setCEXWhitelistWallet(address)
  "0xff768b20", // setFeesPercent(uint256,uint256,uint256)
  "0x3af87bcd", // setRouterOther(address,bool)
  "0xff3914a3", // setSwapAndSendFeesEnabled(bool)
  "0x13096a1a", // setThresoldToSwap(uint256)
  "0x4ce9f13c", // updateDAOcontrol(address)
  "0xda532679", // updateWalletDevelopment(address)
  "0x600775c9", // updateWalletStaking(address)
  "0x3f8d45fc", // updateWalletSustainability(address)
  // --------------------------------------
  "0x79ba5097", // acceptOwnership()
  "0xb121e147", // acceptPayeeship(address)
  "0xa118f249", // addAccess(address)
  "0x0a756983", // disableAccessCheck()
  "0x8038e4a1", // enableAccessCheck()
  "0x8823da6c", // removeAccess(address)
  "0xbd824706", // setBilling(uint32,uint32,uint32,uint32,uint32)
  "0xfbffd2c1", // setBillingAccessController(address)
  "0x585aa7de", // setConfig(address[],address[],uint8,uint64,bytes)
  "0x9c849b30", // setPayees(address[],address[])
  "0x9e3ceeab", // setRequesterAccessController(address)
  "0x1327d3d8", // setValidator(address)
  "0xeb5dcd6c", // transferPayeeship(address,address)
  "0xc9807539", // transmit(bytes,bytes32[],bytes32[],bytes32)
  "0xc1075329", // withdrawFunds(address,uint256)
  "0x8ac28d5a", // withdrawPayment(address)
  // --------------------------------------
  "0x3b7b5a16", // addAllowanceIfNeeded(address,address,uint256)
  "0x7dd711c4", // approveAndExecuteWithSpecificGas(address,address,uint256,uint256,bytes)
  "0xdc2173f3", // approveAndExecuteWithSpecificGasAndChargeForIt(address,address,uint256,uint256,uint256,uint256,address,bytes)
  "0x2b991746", // approveFor(address,address,uint256)
  "0x1dd319cb", // burnFor(address,uint256)
  "0x0819ba74", // changeExecutionAdmin(address)
  "0xdcdf5158", // executeWithSpecificGas(address,uint256,bytes)
  "0x699c834b", // setExecutionOperator(address,bool)
  "0xac9fe421", // setSuperOperator(address,bool)
  "0xe18aa335", // transferAndChargeForGas(address,address,uint256,uint256,uint256,uint256,address)
  "0xbb1e23cb", // paidCall(address,uint256,bytes)
  // --------------------------------------
  "0x078dfbe7", // transferOwnership(address,bool,bool)
  "0xe81223a7", // addToVIPlist(address[],uint256[])
  "0x7f690c33", // claimFreeNFTsVIP()
  "0x17756f89", // removeFromVIPlist(address[])
  "0x548db174", // removeFromWhitelist(address[])
  "0x6f8b44b0", // setMaxSupply(uint256)
  "0xe84a2c72", // setPriPrice(uint256)
  "0xc6275255", // setPublicPrice(uint256)
  "0x0c1c972a", // startPublicSale()
  "0xb39e16fa", // initialize(uint32,uint32,uint32,uint32,bytes32)
  "0x72b0d90c", // withdrawTo(address)
  "0x1f5b4f74", // buy(uint256,uint256,uint256,bytes32[],uint32)
  "0x740561e8", // buySingle(uint256,uint256,uint256,bytes32[])
  "0xb831b058", // buySingleTo(address,uint256,uint256,uint256,bytes32[])
  "0x64625ef3", // buyTo(address,uint256,uint256,uint256,bytes32[],uint32)
  "0x69ffa08a", // claimTokens(address,address)
  "0xdb7af854", // onTokenBridged(address,uint256,bytes)
  "0xb2bc6e0f", // register((address,bytes))
  "0x01a754ff", // wrapAndRelayTokens()
  "0x3ace4765", // wrapAndRelayTokens(address,bytes)
  "0x9254c66e", // wrapAndRelayTokens(address,bytes,(address,bytes))
  "0xb7bfff65", // setFeeActive(bool)
  "0xa918299c", // setMarketingFee(uint256,uint256,uint256)
  "0x48a46473", // setMinTokensBeforeSwap(uint256)
  "0xa40ade85", // setPairAndRouter(address,address)
  "0x47f2dc5b", // setTaxless(address,bool)
  "0x94169e0d", // setTeamFee(uint256,uint256,uint256)
  "0x1525ff7d", // setTeamWallet(address)
  "0x950eb5d5", // setBuyFeePercent(uint256)
  "0xe8b94e5a", // setMaxBalPercent(uint256)
  "0xa2b8a947", // setSellFeePercent(uint256)
  "0xe632313c", // setSwapAmount(uint256)
  "0x9daeac76", // setTaxEnable(bool)
  "0xdc39d06d", // transferAnyERC20Token(address,uint256)
  "0x3177029f", // approveAndCall(address,uint256)
  "0x8980f11f", // recoverERC20(address,uint256)
  "0x1296ee62", // transferAndCall(address,uint256)
  "0xc1d34b89", // transferFromAndCall(address,address,uint256,bytes)
  "0xd8fbe994", // transferFromAndCall(address,address,uint256)
  "0xc2d0ffca", // updateMaxTx(uint256)
  "0x9485b9d8", // clearStuckBNBBalance(address)
  "0x064fa2bb", // setAllFees(uint256,uint256,uint256,uint256,uint256,uint256,uint256)
  "0x29370cc6", // setBuyBackEnabled(bool)
  "0x7bcd43e9", // setBuyBackLimitEnabled(bool)
  "0x82d2a4bb", // setBuybackUpperLimit(uint256)
  "0x8ee88c53", // setLiquidityFeePercent(uint256)
  "0xb74f7eb5", // setPercentLastSoldToBuyBackTimesTen(uint256)
  "0x85535cc5", // setVaultAddress(address)
  "0xff910563", // setVaultFeePercent(uint256)
  "0x885a4cd0", // withdrawForeignToken(address)
  "0xd34628cc", // addBots(address[])
  "0x6991b45c", // approveRouter()
  "0x44df8e70", // burn()
  "0x8d7dd837", // changeMarketing(address)
  "0xb14f2a39", // changeTreasury(address)
  "0xe57f14e1", // excludeFromFees(address)
  "0x16a2f82a", // includeInFees(address)
  "0x5fecd926", // removeBot(address)
  "0x3092afd5", // removeMinter(address)
  "0x2e5bb6ff", // setTax(uint256)
  "0xb4cc5ce8", // startCreateLiq()
  "0x3b243e4c", // toggleMaxTx()
  "0x10bf6029", // toggleTax()
  "0xa0ef91df", // withdrawEth()
  "0xfc5af734", // changeBurnFee(uint256)
  "0x71ffcb16", // changeFeeAccount(address)
  "0x29805558", // changeReflectionFee(uint256)
  "0x584e8d2a", // changeTaxFee(uint256)
  "0x6049876e", // excludeAccountFromReward(address)
  "0xfdeb889f", // includeAccountinReward(address)
  "0x053ab182", // reflect(uint256)
  "0x8ba4cc3c", // airdrop(address,uint256)
  "0xd4a3883f", // airdropArray(address[],uint256[])
  "0x3ae7dc20", // claimOtherTokens(address,address)
  "0x48c54b9d", // claimTokens()
  "0x764d72bf", // clearStuckBalance(address)
  "0x0305caff", // removeBotWallet(address)
  "0x29e04b4a", // setSwapThresholdAmount(uint256)
  "0x7571336a", // excludeFromMaxTransaction(address,bool)
  "0xfe72b27a", // manualBurnLiquidityPairTokens(uint256)
  "0x9a7a23d6", // setAutomatedMarketMakerPair(address,bool)
  "0xa2657778", // setEarlySellTax(bool)
  "0xff935af6", // updateSellFees(uint256,uint256,uint256,uint256,uint256)
  "0xd257b34f", // updateSwapTokensAtAmount(uint256)
  "0x7fd22e39", // at(address,uint256)
  "0x563bf354", // blw(address[],bool)
  "0x492a937b", // addLiquidityPool(address,bool)
  "0x67243482", // airdrop(address[],uint256[])
  "0x421dd7c7", // disableProtection()
  "0x04a66b48", // setFees(uint256,uint256,uint256,uint256,uint256)
  "0x658d4b7f", // setIsFeeExempt(address,bool)
  "0xf84ba65d", // setIsTxLimitExempt(address,bool)
  "0xe624c4bc", // setLiquidityProvider(address)
  "0xabe57e70", // setMaxWallet(uint256,uint256)
  "0x659b1c7c", // setProtection(bool)
  "0xd5d035f5", // setProtection(address,bool)
  "0x0f4f7bfe", // setSellPeriod(uint256,uint256)
  "0xd0889358", // setSwapBackSettings(bool,uint256,uint256)
  "0x201e7991", // setTargetLiquidity(uint256,uint256)
  "0x1faf723b", // setTxLimit(uint256,uint256)
  "0x794be707", // blackListAddress(address,bool)
  "0x875ae990", // updateFee(uint256,uint256,address)
  "0xa053ad90", // addBotWallets(address[])
  "0xde6baccb", // transferWithLock(address,uint256,uint256)
  "0x38926b6d", // claim(uint256,bytes)
  "0xfca3b5aa", // setMinter(address)
  "0x98214bcb", // updateListingStatus(uint256,bool)
  "0x82367b2d", // updatePrice(uint256,uint256)
  "0x4f6ae201", // startVest(address)
  "0x35faa416", // sweep()
  "0x748844f2", // addToBL(address[])
  "0xd7e5a8f4", // removeFromBL(address)
  "0xeb12d61e", // addSigner(address)
  "0x0e316ab7", // removeSigner(address)
  "0xe5c8b03d", // renounceSigner()
  "0x06aba612", // factoryMint(address)
  "0x755edd17", // mintTo(address)
  "0x83c17c55", // setFactoryAddress(address)
  "0x4280d50a", // registerAndDepositNft(address,uint256,bytes,uint256,uint256,uint256)
  "0xea864adf", // registerAndWithdraw(address,uint256,bytes,uint256)
  "0x352eb84c", // registerAndWithdrawNft(address,uint256,bytes,uint256,uint256)
  "0x1259cc6c", // registerAndWithdrawNftTo(address,uint256,bytes,uint256,uint256,address)
  "0x4627d598", // registerAndWithdrawTo(address,uint256,bytes,uint256,address)
  "0x0a9c3beb", // regsiterAndWithdrawAndMint(address,uint256,bytes,uint256,bytes)
  "0x8bdb9f80", // registerAndDeposit(address,uint256,bytes,uint256,uint256)
  "0xef4ae27f", // registerAndDeposit(address,uint256,bytes,uint256,uint256,uint256)
  "0xabf2ebd8", // claim(uint256,bytes32,bytes32)
  "0x4972a7a7", // claim(uint256,bytes32[],(bool,address,address,uint256,uint256,uint256,address,address,uint256,uint256,uint256,uint256,bytes,uint8,bytes32,bytes32),bool)
  "0xc6afd5bb", // pauseAirdrop()
  "0xe96ad3ad", // unpauseAirdrop()
  "0x6e1613fb", // updateEndTimestamp(uint256)
  "0x15618acd", // withdrawTokenRewards()
  "0xd47573d4", // ownerMint(uint256,uint256)
  "0xa0bcfc7f", // setBaseUri(string)
  "0x7708c027", // setBronzePriceWei(uint256)
  "0xccb4807b", // setContractUri(string)
  "0x8c25de60", // setFirstBuyersMerkleRoot(bytes32)
  "0xbba767f9", // setGoldPriceWei(uint256)
  "0x3ca1fca7", // setOgAndPassengersMerkleRoot(bytes32)
  "0x084c4088", // setSaleState(uint256)
  "0xa961b162", // setSilverPriceWei(uint256)
  "0xc813cfd4", // earlyMint(uint256,uint256,uint256,bytes32[])
  "0x3afecb8a", // depositBulkFor(address,address[],uint256[])
  "0x97feb926", // depositERC20(address,uint256)
  "0xd29a4bf6", // depositERC721(address,uint256)
  "0xa7616c3b", // depositERC721For(address,address,uint256)
  "0x9a202d47", // removeAdmin()
  "0x1a5da6c8", // updateRegistry(address)
  "0x4789dcb9", // withdrawERC20(uint256,address,uint256,bytes)
  "0x67a1569b", // withdrawERC721(uint256,address,uint256,bytes)
  "0x75af6456", // withdrawERC721For(uint256,address,address,uint256,bytes)
  "0xace77c19", // withdrawToken(uint256,address,uint256,bytes)
  "0x5a8143de", // withdrawTokenFor(uint256,address,address,uint256,bytes)
  "0x439370b1", // depositEth()
  "0x70223952", // addValidators(address[])
  "0x40a141ff", // removeValidator(address)
  "0x530c74c0", // updateQuorum(uint256,uint256)
  "0x8a63bd9b", // approveContractContingency()
  "0xe8a0e3f9", // multiSendPercents(address[],uint256[],uint256[])
  "0x2610eaca", // multiSendTokens(address[],uint256[])
  "0xc6a276c2", // removeBlacklisted(address)
  "0x33251a0b", // removeSniper(address)
  "0xcab5ebea", // setContractSwapEnabled(bool)
  "0x5c24b074", // setInitializer(address)
  "0x80c581d1", // setLpPair(address,bool)
  "0x26003957", // setMaxWalletSize(uint256,uint256)
  "0xeafb5a3c", // setNewRouter(address)
  "0x29dd8798", // setProtectionSettings(bool,bool,bool,bool)
  "0xaa22b172", // setRatios(uint16,uint16,uint16)
  "0xfb78680d", // setSwapSettings(uint256,uint256,uint256,uint256)
  "0x32cde664", // setTaxes(uint16,uint16,uint16)
  "0xd3f6a157", // setWallets(address,address)
  "0xee5d9c2d", // sweepContingency()
  "0x4fb2e45d", // transferOwner(address)
  "0x9cfe42da", // addBlacklist(address)
  "0xcd49513f", // excludeFromAllLimits(address,bool)
  "0x30280a71", // excludeFromTxLimit(address,bool)
  "0xe16830a8", // excludeFromWalletLimit(address,bool)
  "0x715492aa", // launchNow()
  "0xeb91e651", // removeBlacklist(address)
  "0xfb0ecfa4", // setBuyFee(uint256,uint256)
  "0x6ac9a870", // setSellFee(uint256,uint256)
  "0x6402511e", // setSwapAtAmount(uint256)
  "0x68f8fc10", // buyToken(address,uint256)
  "0xb89d1890", // disbursement(uint256)
  "0x81eaf99b", // lockSupply()
  "0xa8dc824c", // unlockSupply()
  "0x405a66da", // claimOtherTokens(address)
  "0x9cad6741", // Opentrading()
  "0x65e47de2", // setmaxTxAmount(uint256)
  "0x741af87f", // disableAllFees()
  "0xffc78635", // enableAllFees()
  "0x22461173", // setmarketingWallet(address)
  "0x455a4396", // blacklistAddress(address,bool)
  "0x31e79db0", // excludeFromDividends(address)
  "0xc492f046", // excludeMultipleAccountsFromFees(address[],bool)
  "0x700bb191", // processDividendTracker(uint256)
  "0xac212ab2", // setCAKERewardsFee(uint256)
  "0x625e764c", // setMarketingFee(uint256)
  "0xe98030c7", // updateClaimWait(uint256)
  "0x88bdd9be", // updateDividendTracker(address)
  "0x65b8dbc0", // updateUniswapV2Router(address)
  "0xad85832e", // setLiquidityPoolWallet(address)
  "0xc44547df", // setSeasonPoolWallet(address)
  "0x91274f68", // setStakingPoolWallet(address)
  "0x2b788787", // updateBurnRate(uint256)
  "0xaff177ca", // updateWhitelist(address[],bool)
  "0x5a464923", // withdrawLiquidityPool(uint256)
  "0x7ce03b49", // withdrawSeasonPool(uint256)
  "0x61076eac", // withdrawStakingPool(uint256)
  "0x0c53c51c", // executeMetaTransaction(address,bytes,bytes32,bytes32,uint8)
  "0x2307b441", // airdropToWallets(address[],uint256[])
  "0xfc155d1d", // buyBackTokens(uint256)
  "0x8366e79a", // transferForeignToken(address,address)
  "0x3148235a", // getRebaseValues()
  "0x6ecff0b2", // manualRebase(bool)
  "0x1d08837b", // setBaseRate(uint256)
  "0x92eefe9b", // setController(address)
  "0x7f458396", // setDampener(int256)
  "0x456b6398", // setIsInCommunityStage(bool)
  "0xc611c92f", // setIsLaunchFinished(bool)
  "0xea649816", // setMaxTransferAmountInPercentage(uint256)
  "0xd5905dfb", // setRebaseCooldown(uint256)
  "0xc25a98b7", // setTargetRatePercentage(uint256)
  "0x31b5b907", // _setBaseURI(string)
  "0x547520fe", // setMaxMint(uint256)
  "0x6bd4b093", // setSaleActiveness(bool)
  "0xe0c9ffc6", // airdrop((address,uint256)[])
  "0xea50e78c", // mintReservedGods(uint256)
  "0xf11cb0af", // setMintState(uint8)
  "0xbb1ee47b", // setWhiteListMerkleTreeRoot(bytes32)
  "0x061431a8", // mintWhitelist(uint256,bytes32[])
  "0x54ab36f9", // ownerMint(string[],string[])
  "0x4f1235dd", // setMaxInputLength(uint256)
  "0x174da4a2", // setMaxMintsPerTx(uint256)
  "0xd7296662", // setPurchasePrice(uint256)
  "0xe465f1f5", // withdrawAmountTo(address,uint256)
  "0x3ba3518a", // mint(string[],string[])
  "0x41d5b803", // ownerMint(address,string)
  "0x19b2a07c", // renounceCreater()
  "0xf4a762cc", // setMaxMintPerTime(uint8)
  "0xf606faea", // setNftCost(uint256)
  "0x18e97fd1", // updateTokenURI(uint256,string)
  "0x5768f271", // mint(string[])
  "0x99508879", // presaleMint(string[])
  "0x55367ba9", // pauseSale()
  "0xc617f4ed", // reserveNFT()
  "0x0025484f", // reserveTenNFTs()
  "0x3b13be40", // paidMint(bool)
  "0x0eca1180", // setBot(address[])
  "0xaf2979eb", // removeLiquidityETHSupportingFeeOnTransferTokens(address,uint256,uint256,uint256,address,uint256)
  "0xd024e84f", // claimAirdrop(bytes,uint256)
  "0x4dcb2b3f", // ownerMintFromReserve(uint8)
  "0x7d55094d", // toggleMinting()
  "0x14c791a6", // toggleOnlyWhitelisted()
  "0x036e73c8", // mint(uint32,bytes)
  "0xa71bbebe", // mint(uint32)
  "0x2a5bdc33", // excludeFromSellLock(address)
  "0x48dfea0a", // openTrading(address[],uint256)
  "0x300a1ad0", // removeStrictTxAndWalletLimit()
  "0x99ce371a", // removeStrictWalletLimit()
  "0x9d0014b1", // setSwapThreshold(uint256)
  // -----------------------------------------
  "0x6d73e669", // approveAdmin(address)
  "0x02e7afb7", // blacklistExtension(address)
  "0x4cd88b76", // initialize(string,string)
  "0x72ff03d3", // mintBase(address)
  "0x7884af44", // mintBase(address,string)
  "0x7aa15f16", // mintBaseBatch(address,string[])
  "0xad2d0ddd", // mintBaseBatch(address,uint16)
  "0x2928ca58", // mintExtension(address)
  "0xfe2e1f58", // mintExtension(address,string)
  "0x38e52e78", // mintExtensionBatch(address,string[])
  "0xe00aab4b", // mintExtensionBatch(address,uint16)
  "0x3071a0f9", // registerExtension(address,string)
  "0x3f0f37f6", // registerExtension(address,string,bool)
  "0x2d345670", // revokeAdmin(address)
  "0xac0c8cfa", // setApproveTransferExtension(bool)
  "0x3e6134b8", // setBaseTokenURIExtension(string)
  "0x82dcc0c8", // setBaseTokenURIExtension(string,bool)
  "0xf0cdc499", // setMintPermissions(address,address)
  "0x20e4afe2", // setRoyalties(uint256,address[],uint256[])
  "0x332dd1ae", // setRoyalties(address[],uint256[])
  "0xb0fe87c9", // setRoyaltiesExtension(address,address[],uint256[])
  "0xaafb2d44", // setTokenURI(uint256[],string[])
  "0x61e5bc6b", // setTokenURIExtension(uint256[],string[])
  "0xe92a89f6", // setTokenURIExtension(uint256,string)
  "0x66d1e9d0", // setTokenURIPrefixExtension(string)
  "0xce8aee9d", // unregisterExtension(address)
  // --------------------------------------
  "0x77f2d355", // addToPremint(address,uint256)
  "0x064d5e16", // removeFromPremint(address,uint256)
  "0x3ae84a82", // setPrice(uint128)
  "0xb0478892", // setSalesLimit(uint128)
  "0xd5664b0b", // setSweetCooper(address)
  "0x26092b83", // publicMint()
  "0x0d0b21fb", // changeMaxPresale(uint256)
  "0xd2e07682", // changePriceFlash(uint256)
  "0x3f5fbad0", // changePricePresale(uint256)
  "0x9fae99b0", // changePricePublicSale(uint256)
  "0x91ff4a73", // decreaseMaxSupply(uint256)
  "0x989bdbb6", // lockMetadata()
  "0x8905fd4f", // reclaimERC20(address)
  "0x452a2883", // setIPFSProvenance(string)
  "0x57e5c49f", // toggleFlashStatus()
  "0x7bffb4ce", // togglePresaleStatus()
  "0xb9ad9fde", // togglePublicSaleStatus()
  "0xbbd5d940", // flashBuy(uint256)
  "0x815f7bbd", // presaleBuy(uint256)
  "0x2f1c030d", // boostRewards()
  "0xd240663c", // claim(address,uint256[9],bytes32[])
  "0x532b1ba8", // claimNFT(address,uint256[9],bytes32[])
  "0x72ded8f3", // claimWTF(address,uint256[9],bytes32[])
  "0xbd77ac2c", // disburse(uint256)
  "0x4b89c41d", // setClosingTime(uint256)
  "0x472d35b9", // setFeeManager(address)
  "0xe8d2c8c1", // setFeeManagerPercent(uint256)
  "0x8f02bb5b", // setTransferFee(uint256)
  "0xe6a6dcce", // setWhitelisted(address,bool,bool)
  "0x53aeea1e", // upgradeReflink(uint256)
  "0xe674a0bd", // unlock(address,address)
  "0x98650275", // renounceMinter()
  "0x83197ef0", // destroy()
  "0x78e3214f", // rescueFunds(address,uint256)
  "0x6c4a483e", // discountedSwap(address,(address,address,address,address,uint256,uint256,uint256,bytes),bytes)
  "0xa1251d75", // unoswapWithPermit(address,uint256,uint256,bytes32[],bytes)
  "0x457c194c", // setMarketingFeePercent(uint256)
  "0xefbd73f4", // mintForAddress(uint256,address)
  "0x4fdd43cb", // setHiddenMetadataUri(string)
  "0xb071401b", // setMaxMintAmountPerTx(uint256)
  "0x16c38b3c", // setPaused(bool)
  "0xe0a80853", // setRevealed(bool)
  "0x7ec4a659", // setUriPrefix(string)
  "0x16ba10e0", // setUriSuffix(string)
  "0x78cf19e9", // reserveTokens(address,uint256)
  "0xe7db8fb0", // setFreeMints(uint256)
  "0xd26ea6c0", // setProxyRegistryAddress(address)
  "0x6a61e5fc", // setTokenPrice(uint256)
  "0x7362d9c8", // addWhitelistAdmin(address)
  "0xdc593ca5", // burnToken(uint256,address)
  "0x47a64f44", // lockAccount(address)
  "0x6b2c0f55", // removePauser(address)
  "0x6897e974", // removeWhitelistAdmin(address)
  "0x4c5a628c", // renounceWhitelistAdmin()
  "0x905295e3", // unlockAccount(address)
  "0xbc37e1a3", // addLP()
  "0xf298f42c", // addToBlacklist(address,bool)
  "0xbe874c92", // burnTokenManual(uint256)
  "0xf5a08052", // enableContractAddressTrading(address,bool)
  "0x02195466", // letsGetStarted()
  "0x270924c7", // letsGoLive()
  "0x4cdaf0ae", // SetupLiquidityTokenAddress(address)
  "0xd63607d8", // TeamlimitLiquidityReleaseTo20Percent()
  "0x3c7a309b", // TeamReleaseLiquidity()
  "0x2c889d18", // TeamUnlockLiquidityInSeconds(uint256)
  "0x2de3f956", // tradingEnabled(bool)
  "0x8ab792e3", // updateBuyFees(uint8,uint8)
  "0xeab80723", // updateIsElon(address,bool)
  "0xe37ba8f9", // updateLiquidityWallet(address)
  "0x296d3de0", // updateMaxBuySell(uint256,uint256)
  "0x1c499ab0", // updateMaxWallet(uint256)
  "0x2b75e9dd", // updateSellFees(uint8,uint8)
  "0xab3b5545", // updateSwapAmount(uint256)
  "0x19fb3e7a", // withdrawRemainingERC20Token(address,address)
  "0x7bbf8df9", // withdrawRemainingETH(address,uint256)
  "0xc23be47f", // withdrawRemainingToken(address)
  "0x78d33acb", // airDropGroup(uint256,address[])
  "0x50dc4656", // setMerkleTreeRoot(bytes32)
  "0xcc9704d8", // setTokensAllowedPerWhiteListAddress(uint256)
  "0xca3cb522", // togglePreSale()
  "0x0562b9f7", // withdrawAmount(uint256)
  "0x97254e55", // whiteListMint(bytes32[])
  "0xe16685ee", // setMaxFreeNFTPerWallet(uint256)
  "0x963c3546", // setMaxMintsPerWallet(uint256)
  "0xbbd2dc11", // toggleProxyState(address)
  "0x106ac57b", // clearLockUserInfo(address)
  "0x51ecf2c2", // deleteLockUserInfoIdx(address,uint256)
  "0xa19c77c7", // deleteLockUserInfos(address,uint256[])
  "0x072f3b67", // setLockUser(address,uint256,uint256)
  "0x7e26b62d", // setLockUsers(address[],uint256[],uint256[])
  "0xac1a7175", // transfer(ockUserInfo(address)
  "0xe0c3e1e7", // transferWithtLockUser(address,uint256,uint256[],uint256[])
  "0x51f205e4", // forceSwapBack()
  "0x0c9be46d", // setCharityAddress(address)
  "0x499b8394", // setOperationsAddress(address)
  "0x2be32b61", // updateMaxBuyAmount(uint256)
  "0xdc3f0d0f", // updateMaxSellAmount(uint256)
  "0xf5648a4f", // withdrawStuckETH()
  "0xebfa81a8", // feesFlag(address,bool)
  "0x38bf3cfa", // renounceOwnership(address)
  "0x97123415", // swapExactETHForTokens(address[],uint256)
  "0xa70ff5f1", // limitonbuys(bool)
  "0x1b3f71ae", // nonosquare(address[])
  "0xb203919e", // makeLiquidity()
  "0x9c0db5f3", // setBots(address[],bool)
  "0x6c90b57f", // setExcludeFromFee(address[],bool)
  "0xf53bc835", // setMaxBuy(uint256)
  "0x7439c236", // setTradingEnable(bool)
  "0x4cedfc0c", // activateWhitelist(bool)
  "0x999abb7b", // removeWhitelistUser(address[])
  "0x088a4ed0", // setMaxMintAmount(uint256)
  "0x0f5fa199", // setMaxMintAmountAndWalletLimit(uint256,uint256)
  "0xf1d5f517", // setWalletLimit(uint256)
  "0x9b6fbf8a", // whitelistUser(address[])
  "0x9b6f6d78", // setAutoBanBots(bool)
  "0x52e53988", // setMaxContractLiquidityPercent(uint256)
  "0xe67a3fa2", // setTradingEnable()
  "0x8cd8db8a", // init(uint256,uint256,uint256)
  "0x21a9cf34", // setTradeAddress(address)
  "0xaa2f5220", // batchSend(address[],uint256)
  "0xd6d2b6ba", // delegate(address,bytes)
  "0xc9c65396", // createPair(address,address)
  "0xf46901ed", // setFeeTo(address)
  "0xa2e74af6", // setFeeToSetter(address)
  "0xeaa5dad4", // delwl(address)
  "0x96a8826c", // RouterSellLimitEnabled(bool)
  "0x1f53ac02", // setDevWallet(address)
  "0x31c402ac", // setFeesPercent(uint256,uint256,uint256,uint256,uint256)
  "0x41aea9de", // setLimitsEnabled(bool)
  "0x757765f8", // setMaxBuyLimit(uint256)
  "0x5d0044ca", // setMaxWallet(uint256)
  "0x50bea548", // setRouterSellLimitTokens(uint256)
  "0xc2e5ec04", // setTradingEnabled(bool)
  "0x77c325d6", // setTransferDelay(bool)
  "0xaec3fd38", // setWL(address)
  "0xa0c072d4", // _setdevWallet(address)
  "0x4303443d", // addBotToBlackList(address)
  "0xf235d8a3", // antidumpStatus(bool)
  "0x00cf3c10", // changeAntiDumpTime(uint256)
  "0x7ded4d6a", // removeBotFromBlackList(address)
  "0xaae11571", // setDevFeeDisabled(bool)
  "0xc409b63f", // changetranslimitedTime(uint256)
  "0x36bbb867", // changelimitedtranTime(uint256)
  "0x3fd13b21", // limitedtranStatus(bool)
  "0x07c69be5", // upliftTxAmount(uint256)
  "0x0d1df8dc", // addBaseLiquidity(uint256,uint256)
  "0x5dbba48e", // addLimitLiquidity(uint256,uint256)
  "0x7fe75b07", // appendList(address[])
  "0x84715b11", // deposit(uint256,uint256,address,address)
  "0x224438d1", // pendingFees()
  "0x7f2116ff", // pullLiquidity(uint256)
  "0x549f6dc9", // rebalance(int24,int24,int24,int24,address,int256)
  "0x28b0e4cc", // removeListed(address)
  "0x3e091ee9", // setDepositMax(uint256,uint256)
  "0x3f3e4c11", // setMaxTotalSupply(uint256)
  "0xb1a3d533", // toggleDirectDeposit()
  "0xd3487997", // uniswapV3MintCallback(uint256,uint256,bytes)
  "0xfd327141", // addPool(uint24)
  "0xded3bbf7", // addPosition(address)
  "0x737106ac", // adjustMining(uint256,uint128,uint256,int24,int24,int24)
  "0xeb5fe583", // avoidRisk(uint256[],uint256,uint256)
  "0xcf187d2f", // changeConfig(address,uint8,uint24,uint256)
  "0xa3fbbaae", // changeManager(address)
  "0x594c2ba5", // changePool(uint256,address,int24,int24,int24)
  "0x4522f94d", // forceReBalance(uint256,int24,int24,int24)
  "0xf95949be", // initPosition(address,int24,int24)
  "0x2bac1b0e", // reBalance(uint256,int24,int24,int24)
  "0xac1d0609", // updateWhiteList(address,bool)
  "0x554d2a84", // withdrawPerformanceFee(address)
  "0xe4cc18be", // finishMint()
  "0x8d1fdf2f", // freeze(address)
  "0xd1c46916", // releaseLock(address)
  "0x38b82092", // transferWithLockUp(address,uint256,uint256)
  "0x83cfab42", // unFreeze(address)
  "0xf7b188a5", // unPause()
  "0x0d707df8", // applyMinter()
  "0xd93f2445", // applyVault()
  "0x5f9b105d", // changeMPCOwner(address)
  "0x60e232a9", // changeVault(address)
  "0xbebbf4d0", // depositVault(uint256,address)
  "0x81a37c18", // depositWithPermit(address,uint256,uint256,uint8,bytes32,bytes32,address)
  "0xf954734e", // depositWithTransferPermit(address,uint256,uint256,uint8,bytes32,bytes32,address)
  "0x6817031b", // setVault(address)
  "0xc4b740f5", // setVaultOnly(bool)
  "0x0039d6ec", // withdrawVault(address,uint256,address)
  "0xcb0da8b3", // claimPending(uint256)
  "0x939f14a1", // configurePackage(string,uint256,uint256,uint256,uint256)
  "0xc5edc915", // register(string,address,uint256,uint256,uint256,uint256)
  "0x89ad0f90", // registerMeta(address,uint256,uint256,uint256,uint256,uint256,uint256,string,string)
  "0x7edc167b", // renaming(uint256,string)
  "0x51da2975", // stake(uint256,string,string,uint256)
  "0xbf3fc3a8", // unRegister(string)
  "0xb6a5d7de", // authorize(address)
  "0x1f2c80f1", // changeFeeReceivers(address,address)
  "0x7a319590", // changeFees(uint256,uint256,uint256)
  "0xa3a2e89e", // changeIsFeeExempt(address,bool)
  "0xfabe6283", // changeIsTxLimitExempt(address,bool)
  "0xe66b1d1e", // changeRestrictWhales(bool)
  "0x34c70889", // changeSwapBackSettings(bool,uint256,bool)
  "0xd920334e", // changeTxLimit(uint256)
  "0x7db1342c", // changeWalletLimit(uint256)
  "0x79d22b08", // delBots()
  "0xa29f8c5d", // setGuestTimeOn(bool)
  "0x0d295980", // tradingStatus(bool)
  "0xf0b37c04", // unauthorize(address)
  "0xa22b35ce", // _burnFrom(address,uint256)
  "0x0a1f8ea8", // _setDevWallet(address)
  "0x1ff53b60", // _setMarketingWallet(address)
  "0x9e6c7529", // _setMaxWalletSize(uint256)
  "0xaf9549e0", // setExcludeFromFee(address,bool)
  "0x4d423124", // addAllowedDomain(string)
  "0x590e1ae3", // refund()
  "0xc7c3268b", // setBaseUrl(string)
  "0x129ee21a", // setConfig(uint256,uint256,uint256)
  "0x6f516c2c", // setContractUrl(string)
  "0x7954d205", // followHistoryEvent(uint256)
  "0xde3ce16b", // mintNewHistoryReport(uint256,bool,string,string)
  "0x417c73a7", // addToBlackList(address)
  "0xe74b981b", // setFeeRecipient(address)
  "0xf42fe226", // setMinimunTokenAmountToSwap(uint256)
  "0x1f7ede2b", // setReflectionFeePercent(uint256)
  "0x38a238dc", // setSwapAndWithdrawEnabled(bool)
  "0x4a159256", // setTxFeePercent(uint256)
  "0xc44516c0", // updateUniRouter(address)
  "0x07efbfdc", // afterPreSale()
  "0x5134f6ab", // prepareForPreSale()
  "0x6612e66f", // setExcludedFromFee(address,bool)
  "0xbd246615", // setFeesDivisor(uint256)
  "0xbbf1af95", // toggleAntiBot(bool)
  "0xfd69c215", // TransferETH(address,uint256)
  "0x23b5192c", // updateBuyingLaunchpadFee(uint256)
  "0x4844dd4a", // updateBuyingLpFee(uint256)
  "0x65587302", // updateBuyingMarketingFee(uint256)
  "0x78b0acf1", // updateBuyingRfiFee(uint256)
  "0xdb39b7d7", // updateBuyingTeamFee(uint256)
  "0x66b3e74c", // updateLaunchpadWallet(address)
  "0x361ff9bf", // updatePortionsOfSwap(uint256,uint256,uint256,uint256)
  "0x00e6be7b", // updateRouterAddress(address)
  "0x77f7cb44", // updateSellingLaunchpadFee(uint256)
  "0x2325755e", // updateSellingLpFee(uint256)
  "0x53a4ec55", // updateSellingMarketingFee(uint256)
  "0xd6b6209a", // updateSellingRfiFee(uint256)
  "0xf3ffadad", // updateSellingTeamFee(uint256)
  "0xb225d4b7", // updateSwapTokensAt(uint256)
  "0x7cb332bb", // updateTeamWallet(address)
  "0x1264c9c4", // updateTradingIsEnabled(bool)
  "0x7d8dbe24", // updateTransactionMax(uint256)
  "0x91053d94", // updateWalletMax(uint256)
  "0x4e83ff6f", // whitelistDxSale(address,address)
  "0x228025e8", // setmaxSupply(uint256)
  "0xc7ed93f2", // createCollectible(string,uint256,(uint8,bytes32,bytes32))
  "0x6fdc202f", // ownerTransfership(address)      XD
  "0x9b642de1", // setUri(string)
  "0x35a2431c", // mintReservedTokensToOwner()
  "0x6b6384db", // whitelistAddress(address[],uint256)
  "0x116877cc", // changeAuthority(address)
  "0x39a0c6f9", // changeBaseURI(string)
  "0xa6f9dae1", // changeOwner(address)
  "0x0b7b4bef", // enableWhitelistMinting()
  "0x53a3f014", // fuseTier3to2(uint256,uint256)
  "0x1342ff4c", // reserveMint(uint256)
  "0x2afc26de", // setAuctionStartTime(uint256)
  "0x6fb081a4", // setMintPrices(uint256,uint256,uint256)
  "0x1f278c5b", // setrootHash(bytes32)
  "0x6f4f7366", // setTraits(address)
  "0x6b64c769", // startAuction()
  "0x0e4feb57", // fuseTier2to1(uint256,uint256)
  "0xc3943777", // upgradeTier2to1(uint256)
  "0xc0e95027", // upgradeTier3to1(uint256)
  "0x3809b16d", // upgradeTier3to2(uint256)
  "0x372f657c", // whitelistMint(bytes32[])
  "0x66e25e2f", // addToFreePass(address[])
  "0xaef6ee1f", // addToPresale(address[])
  "0xed1fc2a2", // togglePresaleStarted()
  "0xc1d35b2a", // mintFreePass(uint256)
  "0xd43f5d6c", // justinCaseofClog()
  "0x5fe7208c", // manuallySwap()
  "0xbcdb446b", // recoverEth()
  "0xb29a8140", // recoverToken(address,uint256)
  "0xa4b45c00", // setFeeReceivers(address,address)
  "0xcec10c11", // setFees(uint256,uint256,uint256)
  "0xdf20fd49", // setSwapBackSettings(bool,uint256)
  "0xd7c01032", // setFeeReceivers(address,address,address)
  "0x6fcba377", // setFees(uint256,uint256,uint256,uint256)
  "0x9711715a", // snapshot()
  "0xe7bc8208", // freezeBaseURI()
  "0x79f34a10", // setmaxMintPerTx(uint256)
  "0x1ed40559", // setSaleState()
  "0x60a896c7", // setMaxNftPurchase(uint256)
  "0x7d9a7a4c", // setNftPrice(uint256)
  "0x326d4388", // setRevealedURI(string)
  "0xedac985b", // addWhitelist(address[])
  "0x53df5c7c", // lockBaseURI()
  "0x5ca77e31", // mintLegendary(uint256,address)
  "0xcecb06d0", // mintOwner()
  "0x23245216", // removeWhitelist(address[])
  "0x55fc9893", // setCommonURI(string)
  "0x00bb16bd", // setLegendary(address[])
  "0xb2c94ee6", // setPrefixURI(string)
  "0xe59ee014", // setPreSaleStarted(bool)
  "0xa854ffba", // setSaleStarted(bool)
  "0x09a3beef", // setTokenURI(string,uint256)
  "0x31367411", // setAllowList(address[],bool)
  "0x467de36c", // setBaseURIExtension(string)
  "0x087b5c36", // setMaxGiftSupply(uint256)
  "0x2fbba115", // teamMint(uint256)
  "0x988934af", // toggleAllowList()
  "0x96ea3a47", // gift(uint256[],address[])
  "0x3b4c4b25", // setSupply(uint256)
  "0xbbaac02f", // setHiddenURI(string)
  "0x8a2f76aa", // toggleMintMain()
  "0x92cba58f", // toggleMintWhitelist()
  "0xdb7fd408", // mint(uint256,bytes)
  "0x02637603", // mintUnsoldTokens(address,uint256)
  "0x71beeba1", // setBreedPrice(uint256)
  "0x76c05eab", // setCountLimit(uint256[])
  "0x6d6de7e9", // setOwner1Address(address)
  "0xe39dd79c", // setPrice(uint256[])
  "0x61d1157a", // setRootSign(bytes32)
  "0x044dd2c3", // setRootVip(uint256,bytes32)
  "0xbc389a3c", // setStatusFlag(uint8)
  "0x1ab1d655", // setTokenAddress(address,uint256)
  "0xfc805d94", // setUpgradePrice(uint256)
  "0x633c2010", // mintBreedToken(bytes32[],address,string,uint256,uint256)
  "0x3750c088", // mintVip(bytes32[],uint256)
  "0x778df4f8", // setToken(bytes32[],address,uint256,string,uint256,uint256,uint256,bool)
  "0x750521f5", // setMetadataURI(string)
  "0x53323bc6", // addToLuckylist(address)
  "0xcae9185b", // allowLuckyMint()
  "0x1b8dca74", // allowMint()
  "0xff7aa51f", // allowWhitelistMint()
  "0x77924960", // forbidLuckyMint()
  "0x1adcce2c", // forbidMint()
  "0xcebb91a0", // forbidWhitelistMint()
  "0xe8ee8b76", // mintLucky(uint256)
  "0x93f84cfe", // mintTeam(address,uint256)
  "0x9f449fa1", // pickRandomWinnerAddress()
  "0x5364bfad", // setLuckylist(address[])
  "0x08ed665b", // setMaxSupplyPublic(uint32)
  "0x15ebd123", // setMintPriceWei(uint256)
  "0x23c0f2cd", // setWalletLuckyMintLimit(uint256)
  "0x70517ed4", // setWalletMintLimit(uint256)
  "0x213e5a8a", // setWalletWhitelistMintLimit(uint256)
  "0xfbd00f88", // setWhitelistMintPriceWei(uint256)
  "0x88d15d50", // freeMint(bytes32[])
  "0xacee9c4d", // reserveMint(address)
  "0x34be402b", // setFreelistMerkleRoot(bytes32)
  "0xa43baa3d", // setOpenToPublic(bool)
  "0xffe630b5", // setProvenance(string)
  "0xbd32fb66", // setWhitelistMerkleRoot(bytes32)
  "0xbdac8c98", // setWhitelistPhase(uint8)
  "0x46a49513", // setBothSignerAddresses(address,address)
  "0xb2e5e2da", // setPrivateSignerAddress(address)
  "0x9ec70f19", // buy(bytes32,bytes,string,uint256)
  "0x105790fc", // privateBuy(bytes32,bytes,string,uint256)
  "0x33f2fa9f", // transferNonFungibleToken(address,address,address,uint256,uint256)
  "0x1f85e3ca", // setMintStatus(bool)
  "0x519a2044", // updateRoyaltyAddress(address)
  "0x5a4fee30", // batchSafeTransferFrom(address,address,uint256[],bytes)
  "0xf3993d11", // batchTransferFrom(address,address,uint256[])
  "0xccaf1a82", // reserveForTeam(uint256)
  "0xc151a5f8", // mintMultipleByOwner(address[],uint256[])
  "0x5f0f45b2", // revealNow()
  "0x9a5b2f00", // setGiveAwayActive(bool)
  "0x2750fc78", // setIsActive(bool)
  "0x3f8121a2", // setPresaleActive(bool)
  "0xf2f5a7e5", // setRoot(uint256)
  "0xe8254174", // setURIs(string,string)
  "0x8f76696c", // mintNFTDuringPresale(uint256,bytes32[])
  "0xe43252d7", // addToWhitelist(address)
  "0x3c49a8a9", // adminMintGiveaways(address)
  "0x7bd6f0cc", // changeWhiteListPrice(uint256)
  "0x49274ec1", // setAllowedMintedToken(uint256)
  "0x300b23d8", // setMaxBatch(uint256)
  "0x917bb57f", // setNormalStart(bool)
  "0x57df110a", // setTotalCount(uint256)
  "0x5f4f603d", // setWhiteListStart(bool)
  "0xa404792c", // createShare(uint256)
  "0x3edac5a7", // removeShare(uint256)
  "0x7c000293", // setRewardsPool(address)
  "0x4838d165", // blackList(address)
  "0xc3033aeb", // blackListMany(address[])
  "0x0483f7a0", // excludeFromDividends(address,bool)
  "0xd4c989d3", // excludeFromMaxTx(address,bool)
  "0xd2fcc001", // excludeFromMaxWallet(address,bool)
  "0x8e126944", // manualSendDividend(uint256,address)
  "0x537df3b6", // removeFromBlacklist(address)
  "0x9e252f00", // rescueETH(uint256)
  "0x33f3d628", // rescueToken(address,uint256)
  "0xe4956ce2", // setCompoundingEnabled(bool)
  "0x5b65b9ab", // setFee(uint256,uint256,uint256)
  "0xaa4e8c4a", // setMaxTxBPS(uint256)
  "0x68c51e35", // setMaxWalletBPS(uint256)
  "0xc6af580b", // setTaxEnabled(bool)
  "0xf1b234ad", // setWallet(address,address)
  "0xf4571c49", // unBlackListMany(address[])
  "0x5e843ad2", // updateDividendSettings(bool,uint256,bool)
  "0x892ba408", // delSnipers(address[])
  "0xb63c6f84", // fireSale()
  "0x0ec4c619", // resetFees()
  "0xc519b8ee", // setfireSaleRequirement(uint256)
  "0x96dde6a6", // setResetRequirement(uint256)
  "0x5e80148e", // setSnipers(address[])
  "0x66718524", // updateFeeWallet(address)
  "0x476343ee", // withdrawFees()
  "0x704b6c02", // setAdmin(address)
  "0xb0fc29e6", // setLock(address,uint256)
  "0x1d539764", // setLockList(address[],uint256[])
  "0x859bc2f3", // transferList(address[],uint256[])
  "0x4d253b50", // unsetAdmin(address)
  "0x1cf6e5b4", // refundETH(address)
  "0x22fd64a0", // refundToken(address,address)
  "0x75e3661e", // unblacklist(address)
  "0x092501a0", // setCommission(uint32)
  "0xf8048d8f", // setMaxPerMint(uint32)
  "0x3006b543", // setMaxPerWallet(uint32)
  "0x9c5e92fb", // mint(uint16[])
  "0x4a8cdd74", // limitedbuys(bool)
  "0x3e9ffbea", // swapAll()
  "0x807273ef", // Sweep(address)
  "0x670a6fd9", // updateAdmin(address,bool)
  "0x4a6420a7", // updateRouteAndPair(address,address)
  "0x9991c39b", // updateSwapFeesAt(uint256)
  "0xb92787e3", // updateTaxExcluded(address,bool)
  "0xd762a37b", // updateTransferLimit(uint256)
  "0x2b7581b2", // _setBuyTax(uint256)
  "0xdbe8272c", // _setSellTax(uint256)
  "0xb31470a4", // bulkAntiBot(address[],bool)
  "0x0d65df9b", // rescueAnyERC20Tokens(address,address,uint256)
  "0xe1165545", // setAntibot(address,bool)
  "0x04d4c990", // setTaxes(uint256,uint256,uint256,uint256,uint256,uint256)
  "0xd5ccb8ee", // setTradingState(bool)
  "0x49244cb0", // updatecharityWallet(address)
  "0x8c0ae3df", // updateCoolDownSettings(bool,uint256)
  "0xfc170441", // updatedevWallet(address)
  "0xbff78169", // updatemarketingWallet(address)
  "0x40b28c2f", // updateRouterAndPair(address,address)
  "0xf965aa1d", // updatMaxTxAmt(uint256)
  "0x47298f82", // confiscate(address,uint256)
  "0xe6f7ef4d", // setBlocksToBlacklist(uint256)
  "0x296f0a0c", // setLiquidityWallet(address)
  "0xf34eb0b8", // setMaxBuyAmount(uint256)
  "0xe99c9d09", // setMaxSellAmount(uint256)
  "0x8a780447", // setProjectWallet(address)
  "0x5958621e", // setRewardWallet(address)
  "0xafa4f3b2", // setSwapTokensAtAmount(uint256)
  "0x153b0d1e", // setBlacklist(address,bool)
  "0x89afcb44", // burn(address)
  "0xfb0a8fc3", // bulkBlacklist(address[])
  "0xe4997dc5", // removeBlackList(address)
  "0x0b090639", // removeBulkBlacklist(address[])
  "0x7c519ffb", // setTrading()
  "0x2e6ed7ef", // updateBuyFees(uint256,uint256,uint256,uint256)
  "0x106b5da1", // updateMaxAmount(uint256)
  "0xe7ad9fcd", // updateSellFees(uint256,uint256,uint256,uint256)
  "0x0e7251e3", // setCharityFeePercentage(uint256)
  "0x30563bd7", // setCharityWallet(address)
  "0xf2c19d67", // setTeamFeePercentage(uint256)
  "0x6e34c796", // setMaxPerTx(uint8)
  "0xabf08ae1", // setMaxPerWallet(uint8)
  "0x06421c2f", // setMaxSupply(uint16)
  "0xc219c2ef", // setPublicMintStatus()
  "0xb8da5561", // setReachedCapPrice(uint256)
  "0x1919fed7", // setSalePrice(uint256)
  "0x858e83b5", // publicMint(uint8)
  "0x899e8689", // afterPresale()
  "0xde87e0a5", // prepareForPresale()
  "0x068dde72", // setBuyBackDivisor(uint256)
  "0xb671b96e", // SetBuyBackUpperLimitAmount(uint256)
  "0x9f34a5b4", // setMaxWalletTokend(uint256)
  "0xff897570", // addToBlackList(address[])
  "0xd54994db", // setDefaultMarketingFee(uint256)
  "0x57e0a1d0", // setFeesOnSellersAndBuyers(bool)
  "0xa08f6760", // setMarketingFee4Sellers(uint256)
  "0xdab522a8", // setnumTokensToExchangeForMarketing(uint256)
  "0x2663236f", // setSwapAndSendEnabled(bool)
  "0x242877c5", // setEndingURI(string)
  "0xadfdeef9", // setProxyRegistry(address)
  "0xe1e62b02", // setWLMintPrice(uint256)
  "0x37bc4c0b", // whitelistMint(bytes)
  "0x4c09aa98", // reserveNFTs(address[],uint256[])
  "0x36655f6a", // setMintingOpened(bool)
  "0xdbc1d6ca", // forceRewardsDistribution(uint256)
  "0x9dc67615", // giveMeWelfarePlease()
  "0x6ab86dc1", // manualDeposit()
  "0xdb40e302", // setBoostTime(uint256)
  "0x1de3b1c7", // setBoostTimeEnabled(bool)
  "0xdea528a1", // setContractSwapSettings(bool,bool)
  "0x6b639a25", // setDividendExcluded(address,bool)
  "0x36fddb04", // setExcludedFromLimits(address,bool)
  "0x218950bc", // setInitializers(address,address)
  "0x0712d165", // setRatios(uint16,uint16,uint16,uint16)
  "0x09a8f179", // setReflectionCriteria(uint256,uint256,uint256)
  "0xd0b1449d", // setReflectorSettings(uint256)
  "0x34cd8260", // setSwapSettings(uint256,uint256,uint256,uint256,uint256)
  "0xecfc021f", // setTaxes(uint16,uint16,uint16,uint16)
  "0xccd1f6ea", // LimitBuy(uint256)
  "0x40c70fbf", // MaxWallet(uint256)
  "0xfc342279", // swap(bool)
  "0xcd3293de", // reserve()
  "0xd0eb26b0", // setNftPerAddressLimit(uint256)
  "0xb548f2de", // setSignatureVerifiedWhitelist(bool)
  "0x61905807", // unWhitelistAdress(address[])
  "0xb31d61b0", // whitelistAddress(address[])
  "0x30d583d7", // mint(uint256,uint256,string,uint256,bytes)
  "0x5a96ac0a", // pullManagement()
  "0x46f68ee9", // pushManagement(address)
  "0x058ecdb4", // rebase(uint256,uint256)
  "0x089208d8", // renounceManagement()
  "0x40a5737f", // setIndex(uint256)
  "0xffecf516", // addBot(address)
  "0x643620da", // HappyHour()
  "0x29215b96", // HappyHourOff()
  "0xbf5abeb2", // MaxWalletOff()
  "0x1bb5aea8", // MaxWalletOn()
  "0x56cd40e7", // RemoveLimits()
  "0x2cf0d13d", // ResumeLimits()
  "0x1e20cc9b", // setBF(uint256,uint256,uint256,uint256)
  "0x69d03738", // setNFTAddress(address)
  "0x9ce716f9", // setSF(uint256,uint256,uint256,uint256)
  "0xc9259851", // StartLaunchInit()
  "0xf47a8b56", // StopLaunchInit()
  "0x620b4bfc", // TaxSwapDisable()
  "0x6e86ea8b", // TaxSwapEnable()
  "0x89701db5", // processFees(uint256)
  "0x357bf15c", // setLiquidityFee(uint256)
  "0x72bc5583", // setLiquidityOwner(address)
  "0xdca74a19", // setProcessFeesEnabled(bool)
  "0xb98b677f", // updateSwapRouter(address)
  "0x22d1e8d8", // setBF(uint256,uint256,uint256)
  "0x0e6c704f", // setSF(uint256,uint256,uint256)
  "0xb210b06d", // clearBuybackMultiplier()
  "0x048c7baf", // setAutoBuybackSettings(bool,uint256,uint256,uint256)
  "0x17d43583", // setBuyBacker(address,bool)
  "0x2375ce40", // setBuybackMultiplierSettings(uint256,uint256,uint256)
  "0x2d48e896", // setDistributionCriteria(uint256,uint256)
  "0x9d1944f5", // setDistributorSettings(uint256)
  "0xf708a64f", // setIsDividendExempt(address,bool)
  "0xf5cfec0a", // triggerZeusBuyback(uint256,bool)
  "0x362a3c5d", // _amnestySniper(address)
  "0xdb2e21bc", // emergencyWithdraw()
  "0x8203f5fe", // initContract()
  "0x35dbcd25", // removeBuyTax(address[])
  "0xdc1052e2", // setBuyTax(uint256)
  "0x93c74657", // automaticSwap()
  "0xd29d44ee", // _transferOwnership(address)
  "0x23c90b9e", // afterAirDrop()
  "0x045f7850", // airDrop(address,uint256)
  "0xa5071ef9", // prepareForAirdrop()
  "0xfd7711de", // setAllFeePercent(uint16,uint16,uint16,uint16,uint16,uint16)
  "0x1c4ba3ed", // setBurnWallet(address)
  "0xc43b2472", // setInvestmentWallet(address)
  "0x2a517209", // setMaxRestrictionPercentage(uint16)
  "0x3de1596d", // setMaxTxPercentage(uint16)
  "0xbe4b1772", // withdrawToken(uint256,address)
  "0x613c22c9", // _setMaxWallet(uint256)
  "0x5353a2d8", // changeName(string)
  "0x5881f3ef", // changeRouterVersion(address)
  "0xa3895fff", // changeSymbol(string)
  "0xc4a9baed", // setAddressFee(address,bool,uint256,uint256)
  "0xafabc8c6", // setBuyAddressFee(address,bool,uint256,uint256)
  "0xe79b0322", // SetBuyBackDivisor(uint256)
  "0x535acb7f", // setBuyBackSellLimit(uint256)
  "0xb0cb81f1", // setMarketingDivisor(uint256)
  "0xb14869fb", // setNumTokensSellToAddToBuyBack(uint256)
  "0x4906b560", // setSellAddressFee(address,bool,uint256,uint256)
  "0x7c6a5501", // updateBotTrading(bool)
  "0xdd4094b2", // updateBotWallet(address,bool)
  "0x47f68795", // updateTrading(bool)
  "0x20189d28", // renounceOwnership(address,uint256)
  "0x837381fa", // withdrawFromContract(address,uint256)
  "0xd0040d70", // addTaxWhitelist(address)
  "0x91f85fda", // emergencySwap()
  "0xa98f6f90", // launch(uint8)
  "0x98d5a5cb", // rmBlacklist(address)
  "0x53477d29", // rmTaxWhitelist(address)
  "0xf37c4bce", // setWebThree(address)
  "0xab9562fe", // updateDevWallet(address,uint256)
  "0x1c815b49", // updateTaxAlloc(address,uint256)
  "0x522644df", // _setMaxHolding(uint8)
  "0x61e5698a", // setBTCRewardsFee(uint256)
  "0x5ebf4db9", // setMinimumTokenBalanceForDividends(uint256)
  "0xba045c6e", // setSwaptokensatAmount(uint256)
  "0x1846f51a", // withdrawLeftOver()
  "0xac1147fc", // SendIt()
  "0x4d3a18b7", // SetBots(address[])
  "0x4cd287f2", // setSellersFee(uint256)
  "0xd89a0412", // excludeAddFromFee(address)
  "0x0b8f1ff8", // reduceFeeToHalf()
  "0x6b35b93d", // setFeeToZero()
  "0xb62b343d", // setOriginalFee()
  "0x2f9c4569", // allowPreTrading(address,bool)
  "0x1dcfc3a7", // setIsExcludefromFees(address,bool)
  "0x22f5ff90", // SetFeeOnSells(uint256)
  "0xa1ac727f", // airdrop(address,address[],uint256)
  "0x71b9189c", // excludeFromFee(address[])
  "0xf259c57b", // excludeMultiple(address)
  "0x5d54c0af", // manage_Snipers(address[],bool)
  "0x84530c46", // manage_trusted(address[])
  "0x2d4103d6", // openTrading(bool,uint256)
  "0x2dd5efe7", // setMaxWalletExempt(address)
  "0x09302dc6", // setMaxWalletPercent_base1000(uint256)
  "0x8f4590d2", // setSwapSettings(uint256,uint256)
  "0xa3b84b6b", // setTaxesBuy(uint256,uint256,uint256,uint256,uint256)
  "0x6ce37fa0", // setTaxesSell(uint256,uint256,uint256,uint256)
  "0x4b01547f", // setZeroBuyTaxmode(bool)
  "0x743942b6", // withDrawLeftoverETH(address)
  "0x25b86edf", // withdrawStuckTokens(address,address)
  "0x20b6a50f", // flipTradingStatus()
  "0x2b8e797a", // modifyKYCData(address,uint256,uint256)
  "0xda76e2f3", // removeWhitelistAuthorityStatus(address)
  "0x62a3d4bd", // resetAllowedInvestors(uint256)
  "0xabc30a4b", // resetCompanyHomepage(string)
  "0x51aebcd4", // resetCompanyLegalDocs(string)
  "0xac893db1", // resetShareCertificate(string)
  "0xc319e41c", // setWhitelistAuthorityStatus(address)
  "0xc7cd483b", // updateBuyFees(uint8)
  "0x52606f28", // updateSellFees(uint8)
  "0x9e5666cd", // setPreSaleAddresses(bytes32)
  "0xf5b96621", // setPresaleOpen(bool)
  "0xfe2c7fee", // setUnrevealedURI(string)
  "0x0364d22a", // presaleMint(uint32,bytes32[])
  "0x3ab1a494", // setWithdrawAddress(address)
  "0x05f2327e", // withdrawByOwner()
  "0x7699cb91", // emergencyOverridePresaleTimings(uint32,uint32)
  "0x45696963", // emergencyOverridePublicSaleStartTime(uint32)
  "0x9423ad26", // setPresaleOn(bool)
  "0x3bd64968", // setRevealed()
  "0x2904e6d9", // whitelistMint(bytes32[],uint256)
  "0xa716b773", // addPresaleAddressForExclusions(address)
  "0x08043751", // updateMinimumTokensBeforeSwap(uint256)
  "0xe4456ecb", // withdrawLP(uint256)
  "0x05ad7799", // setBuyF(uint256,uint256,uint256,uint256)
  "0x41137645", // setGiveAwayAddress(address)
  "0xbd559c07", // setSellF(uint256,uint256,uint256,uint256)
  "0x51006d7e", // startAntiSniper()
  "0xb9a1861a", // stopAntiSniper()
  "0x73417b09", // setClaimActive(bool)
  "0x22e8d8cd", // setClaimSupply(uint256)
  "0x01799f59", // setLuchaAddress(address)
  "0x04db5eed", // setSaleSupply(uint256)
  "0xf7ea7a3d", // setTotalSupply(uint256)
  "0xe8cc00ad", // withdrawOwner()
  "0x2804c7f5", // closeDAO()
  "0x6ac5eeee", // swapBack()
  "0xa5288dec", // swapTokensForDai(uint256)
  "0x04dacd50", // updateBuybackWallet(address)
  "0x743efe0d", // updateTaxDistributor(address)
  "0x71e2a657", // addMinters(address[])
  "0x5fc1964f", // removeMinters(address[])
  "0x07da68f5", // stop()
  "0x1e89d545", // multiTransfer(address[],uint256[])
  "0xa16a3179", // multiTransfer(address[],uint256)
  "0x44e393cb", // addGrantor(address,bool)
  "0xfd1154c2", // grantUniformVestingTokens(address,uint256,uint256,uint32)
  "0x7af023a9", // grantVestingTokens(address,uint256,uint256,uint32,uint32,uint32,uint32,bool)
  "0x8403eeb5", // iAm(string)
  "0x41c0e1b5", // kill()
  "0xd9f226e9", // registerAccount()
  "0x332cc9c6", // removeGrantor(address)
  "0x1bd2cf38", // revokeGrant(address,uint32)
  "0xfbc6d1cb", // safeApprove(address,uint256)
  "0x3d3b993c", // safeGrantUniformVestingTokens(address,uint256,uint256,uint32)
  "0x41d5f619", // safeGrantVestingTokens(address,uint256,uint256,uint32,uint32,uint32,uint32,bool)
  "0x423f6cef", // safeTransfer(address,uint256)
  "0x6d589d64", // setGrantorVestingSchedule(address,uint32,uint32,uint32,bool)
  "0x99ad427b", // setRestrictions(address,uint32,uint32,uint32)
  "0xdfd233dd", // giveaway(address)
  "0xeff819b8", // resetTigerUri(string)
  "0xc87c089c", // freeMint(uint8)
  "0x3855c60d", // ownerMint(address,uint8)
  "0xbf793346", // saleMint(uint8)
  "0xdf8408fe", // excludeFromFee(address,bool)
  "0x260065e7", // setMarketingWallets(address,address)
  "0x895fc788", // adminMint()
  "0x24938096", // beginSale()
  "0x15a8f073", // closeSales()
  "0x2316b4da", // enablePublicSale()
  "0x440bc7f3", // setWhitelist(bytes32)
  "0xfe18f3c9", // PublicPurchase(uint256)
  "0xf281ec22", // WhitelistPurchase(uint256,bytes32[])
  "0xba05e9bc", // manualswapsend()
  "0x729d8a87", // setMaxTokensToSwapForFees(uint256)
  "0x53674ba6", // setSwapTokensAt(uint256)
  "0x34cb52e1", // changeCallerRewardDivisor(uint16)
  "0xf0c0289a", // changeFeeDivisor(uint16)
  "0x5b241b21", // changeMinRebalancerAmount(uint256)
  "0x30a0f1e2", // changeRebalalncerDivisor(uint16)
  "0x40905cba", // changeRebalanceInterval(uint256)
  "0xbb31b77b", // setClaim(address)
  "0xccfc2e8d", // setFeeDistributor(address)
  "0xc0240b74", // setlpStaking(address)
  "0x6cfd1553", // setRebalancer(address)
  "0x079636cb", // setRenaRouter(address)
  "0xee4e2687", // setUniRouter(address)
  "0x70709a7c", // toggleFeeless(address)
  "0x6bd98660", // clearBaseUris()
  "0x48f3afed", // setBaseURI(string,uint256,uint256)
  "0x177bbc95", // setIsLive(bool,bool)
  "0x71f9f200", // setMaxElements(uint256)
  "0xd9ec6f09", // setMaxWhitelistMintPerTx(uint256)
  "0x269748f8", // setMintPrices(uint256,uint256[],uint256[])
  "0xfbf5c17c", // setStartTimes(uint256,uint256)
  "0xb9bcecb2", // setWhitelistNonce(uint256)
  "0x9055b419", // reserveMint(uint256,uint256,bytes32,uint8,bytes32,bytes32)
  "0x09093357", // whitelistMint(uint256,uint256,bytes32,uint8,bytes32,bytes32)
  "0x31532eb8", // khb()
  "0x733b864f", // manualSwapAndLiquify()
  "0x4fb2343a", // setMaxWalletHolding(uint256)
  "0xc9c16eac", // setSniperEnabled(bool,address)
  "0x6f60efbc", // setSwapAndLiquifyEnabled(bool,uint256)
  "0x667f6526", // setTax(uint256,uint256)
  "0x249ae3e2", // withdrawTax(uint256)
  "0x0a9c4a0a", // setdonationFee(uint256)
  "0x5a96cdd7", // setDonationWallet(address)
  "0xce2fea33", // setUSDTRewardsFee(uint256)
  "0x757d1da4", // mintlistUser(address)
  "0xc0cd0833", // mintlistUsers(address[])
  "0x582f1f1c", // rollOverPixelmons(address[])
  "0x7c537374", // setMintlistPrice(uint256)
  "0xd145fb31", // setSerumContract(address)
  "0xeb190a64", // auction(bool)
  "0x1aa0ffe4", // mintEvolvedPixelmon(address,uint256)
  "0x3680f5bf", // mintlistMint()
  "0x0a61415e", // set_antibot(address,bool)
  "0xda6405e1", // setPreMintPrice(uint256)
  "0x34131cd3", // setPresaleAccessList(address[])
  "0xc1d7ae31", // updateMainSaleStatus(bool)
  "0x290c292d", // preMint(address,uint256)
  "0xab05bfff", // setReleaseTime(uint256)
  "0xcfefd79e", // disableBlacklist(address)
  "0xced695a4", // disableTax()
  "0x717a8651", // enableBlacklist(address)
  "0x53eb3bcf", // enableTax()
  "0x4febf53d", // exclude(address)
  "0xabe4f11d", // removeExclude(address)
  "0xa82cfe8b", // setBuyTax(uint256,uint256,uint256,uint256)
  "0x9fda0581", // setTaxWallets(address,address,address)
  "0x2c32abc2", // triggerTax()
  "0x320b2ad9", // _pause()
  "0xafee32a9", // setBuyFeePercent(uint256,uint256,uint256,uint256)
  "0xa68bacf3", // setMaxBuytx(uint256)
  "0x91d55f41", // setMaxWalletToken(uint256)
  "0x41cb87fc", // setRouterAddress(address)
  "0x9d854b63", // setSellFeePercent(uint256,uint256,uint256,uint256)
  "0x293230b8", // startTrading()
  "0x84f570af", // catchMice(address[])
  "0xc38ec0e6", // freeMouse(address)
  "0x950406c3", // liftMaxTransaction()
  "0xa769958a", // reduceFees(uint8,uint8)
  "0x23338b88", // delMinter(address)
  "0x972fdd26", // getFunctionImplementation(bytes4)
  "0x8a14c759", // setAllowanceTarget(address)
  "0x1c31f710", // setBeneficiary(address)
  "0xfdf03fa1", // setImplementationOverride(bytes4,address)
  "0xf9143826", // setZeroEx(address)
  "0xfe53d0b6", // optimalSwap(bytes,address,uint256)
  "0x5cf54026", // proxiedSwap(bytes,address,address,uint256,address,uint256)

  "0xbbb1a87c", // customlock(address)
  "0x12638eec", // customunlock(address)
  "0xa9dab167", // extendLock(bytes32,uint256)
  "0x81fc4d90", // increaseLockAmount(bytes32,uint256)
  "0x2e82aaf2", // lock(bytes32,uint256,uint256)
  "0xa4c838d0", // OwnerRevokeAdminRole(address)
  "0xc985792b", // OwnerRevokePauserRole(address)
  "0xfc3d0cb2", // OwnerSetupAdminRole(address)
  "0x0668e62f", // OwnerSetupPauserRole(address)
  "0x1c78c751", // OwnerSetupRole(bytes32,address)
  "0xeb795549", // safeTransfer(address,uint256,bytes)
  "0x4cb5465f", // transferWithLock(address,bytes32,uint256,uint256)
  "0x785fd544", // unlock(address,bytes32)
  "0xc4f3a853", // unlockAll(address)
  "0x74dfa316", // changenumTokensSellToAddToLiquidity(uint256)
  "0xf275f64b", // enableTrading(bool)
  "0xb7cd7794", // excludeFromMaxTxLimit(address)
  "0x71547984", // includeInMaxTxLimit(address)
  "0xcea26958", // setBurnFeePercent(uint256)
  "0x61a60d57", // setDeadWallet(address)
  "0x26fddf08", // setFoundationPercent(uint256)
  "0x015493b4", // setFoundationWallet(address)
  "0xc6055ec3", // setMarketingPercent(uint256)
  "0x06f7c26b", // claimReserve(address[],uint8[])
  "0xda31bcef", // updateRevealTime(uint256[])
  "0x488a8557", // updatesTierMintFees(uint256[])
  "0x3c238c7d", // updateTierMintEndTimes(uint256[])
  "0x71bb534c", // updateTierMintStartTimes(uint256[])
  "0xd0cc19f2", // updateWhiteListDisableTime(uint256)
  "0x92cbf98d", // updateWhiteListMintFee(uint256)
  "0x5531b7fe", // claimWhitelistSale(bytes[],address,uint256[])
  "0x352926a8", // mint(address,uint8,uint8)
  "0xe20bc67b", // autoUnlock(address)
  "0x29b1c15c", // setDevelopmentAddress(address)
  "0x6f972cd2", // setDevelopmentFee(uint256)
  "0x41a2ac70", // setRewardFee(uint256)
  "0xbbec4c45", // reinvestReflections(address,uint256)
  "0xa6c38f59", // addBotWallet(address,bool)
  "0x4a0e963c", // addToExcluded(address)
  "0xf19abf26", // dayAfter()
  "0xf953cf3a", // endPresaleStatus()
  "0xb4d95a93", // removeFromExcluded(address)
  "0xd0d41fe1", // setDevAddress(address)
  "0xbf9eb540", // setMktAddress(address)
  "0x801527ce", // setSwapAndLiquify(bool)
  "0xb195b839", // startPresaleStatus()
  "0x69d4eb87", // updatecurrentbuyDevAmountfee(uint256)
  "0x0882a863", // updatecurrentbuyliqFee(uint256)
  "0xedcf19b2", // updatecurrentbuymktfee(uint256)
  "0xd30ff2d9", // updatecurrentsellDevfee(uint256)
  "0x8cd0260c", // updatecurrentsellLiqFee(uint256)
  "0x8628c23f", // updatecurrentsellmktfee(uint256)
  "0xa7a07452", // updateMaxTxTreshold(uint256)
  "0x6573c4df", // updateMaxWalletTreshold(uint256)
  "0xd7d7442f", // updateThreshold(uint256)
  "0x986ccc7f", // setHelper(address)
  "0xbc07aa64", // setMerkleRoots(bytes32[])
  "0x7b1278e9", // setStageLength(uint256)
  "0x65f4bce6", // setWindows(uint256,uint256)
  "0x89643e82", // purchase(uint256,uint256,uint256,uint256,bytes32[])
  "0xdb6dd4e6", // purchasePublic(uint256)
  "0xd8cfb999", // toggleMintStatus()
  "0x350ddddd", // LiquidityLaunchInit()
  "0x21ce72e5", // setBuyTax(uint256,uint256,uint256)
  "0x8f2076fd", // setSellTax(uint256,uint256,uint256)
  "0x2a824300", // StopLiquidityLaunch()
  "0x3f9b7607", // claimAltTokens(address,address)
  "0x7e66c0b9", // claimETH(address)
  "0x725e0769", // setAntiSnipe(bool)
  "0x5ae9e94b", // setMaxHoldings(uint256)
  "0x413550e3", // setMaxHoldingsEnabled(bool)
  "0x95f6f567", // setMaxTXEnabled(bool)
  "0x51cd7cc3", // OpenTrading()
  "0x124d91e5", // burnFrom(address,uint256,uint256)
  "0x39d0534a", // Multicall(address)
  "0x8236802e", // blacklistBot(address,bool)
  "0x2b044370", // disableBlacklistFunction()
  "0xbd94545b", // excludeMultipleAccountsFromMaxWallet(address[],bool)
  "0xa9d3b3ae", // manualSwapAndAddToLiq(uint256)
  "0x00ae3bf8", // rescueTokens(address)
  "0x04679780", // setNumTokensSellToAddToLiq(uint256)
  "0xdc0e6217", // contractMint(address)
  "0xa584a9b5", // freezeContract()
  "0xe272b892", // pauseContract(bool)
  "0xe8b92b05", // afterPreSale(address)
  "0xca42c8eb", // emergency_token(uint256)
  "0x7f7a1225", // setMarketingAddress(address,address,address,address)
  "0xfe22c82d", // setUniswapAddress_forspeedbrake(address)
  "0x81c6f11b", // isProtected(uint256)
  "0xeec2744e", // setBlacklistEnabled(address,bool)
  "0x469c0ef5", // setBuyCoolDownTime(uint256)
  "0x044df726", // setProtectionSettings(bool,bool)
  "0xcdfdb7dc", // setRatios(uint256,uint256,uint256)
  "0x4129ecee", // setStartingProtections(uint8)
  "0x0614117a", // recoverETH()
  "0xcf0848f7", // includeToFee(address)
  "0x90d49b9d", // setFeeWallet(address)
  "0xe6ec64ec", // setTeamFee(uint256)
  "0x06d8ea6b", // swapFeesManual()
  "0x8fa457ca", // setBlacklistWallets(address[])
  "0x2913aa55", // addLimits(address)
  "0x530eab62", // liftLimits(address[])
  "0x2d1fb389", // authorize(address,bool)
  "0x261c6ca5", // manageWreck(address,bool)
  "0x6883b831", // manuallyCollectFee(uint256)
  "0xd1633649", // openTrading(uint256)
  "0xef422a18", // setIsExcludedFromFee(address,bool)
  "0x5d2c76b0", // setMaxBuyTxLimit(uint256)
  "0xfab355f2", // setMaxSellTxLimit(uint256)
  "0x19aa57e8", // transferOtherTokens(address,uint256)
  "0xf8e5884b", // triggerSwap(uint256)
  "0x73d46b07", // updateProtection(uint256)
  "0x943620db", // updateSwap(bool)
  "0xbdb2c382", // updateSwapLimit(uint256,uint256)
  "0xfceade72", // updateSwapLimit(uint256)
  "0x787b487e", // burnForReward(uint256)
  "0x9e52228a", // devMintTokensToAddress(address,uint256)
  "0xbb58b204", // devMintTokensToAddresses(address[])
  "0x1be8db96", // pauseBurn(bool)
  "0xf30e6e77", // pauseMint(bool)
  "0x5b8925b9", // revealDrop(string)
  "0x955d2dcc", // setBlankTokenURI(string)
  "0xf3179891", // setMintPassAddress(address)
  "0x51508f0a", // setRewardContract(address)
  "0x65348141", // mint(uint256,uint256,bool,bytes)
  "0xe045512d", // mintWithPass(uint256,uint256,bool,bytes)
  "0xa224b4f5", // mintPromotion(address[],string)
  "0x82e77c59", // mintReward(address[],string)
  "0x3ec2d836", // rename(uint256,string)
  "0x4c261247", // reveal(string)
  "0x3df10ef2", // setCancel(bool)
  "0x469b624f", // setSaleIsFinish()
  "0x463b654d", // settingAddressContractImage(address)
  "0xee8124c4", // settingAddressProxyRegistry(address)
  "0xcaf42575", // settingPasscode(uint256)
  "0xe572e63e", // mintHomepage(uint256)
  "0x89dfc8b9", // setMaxFreeMintAmountPerAccount(uint256)
  "0x69703cd4", // setMaxMintPublicAccount(uint256)
  "0x9e78fb4f", // createPair()
  "0x01320621", // setremoveMaxTx(bool)
  "0xb21d4184", // snipStopper(address[])
  "0x20b3d480", // deliciousbotcheese(address[])
  "0xc7af2a9c", // createLiquidity()
  "0xb92ebd5f", // setEnableTrading(bool)
  "0x019ce79b", // setMaxWalletOnStart(uint256)
  "0x6659889d", // setPromoWallet(address)
  "0xa73b3c82", // transferlottery(address)
  "0x4bb3e285", // TurnRewardsOff()
  "0x332d4a93", // TurnRewardsOn()
  "0x8a57af6b", // lockAfter(address,uint256,uint256)
  "0x927a4a7b", // transferWithLockAfter(address,uint256,uint256)
  "0x45c8b1a6", // unfreeze(address)
  "0x08003f78", // burnTokens()
  "0x2c74d772", // initialMint(uint256[],address[])
  "0x17be3fa5", // lockToken(address,uint256)
  "0x0ad436f5", // lockTokenWhole(address)
  "0x31e9e9c0", // unlockToken(address,uint256)
  "0xe5ac14c2", // updateWalletChurch(address)
  "0xd7b49b4e", // updateWalletDiscretionary(address)
  "0xc258ef60", // sendAirDrop(address[],uint256[])
  "0x612e58f6", // updateAll(string,bool)
  "0x111e0376", // excludeFromRewards(address)
  "0xb609995e", // includeInRewards(address)
  "0xa1ab19a3", // prepareForLaunch()
  "0x8421b507", // setLaunchSellFee(uint256)
  "0xba385abb", // setRewardsTracker(address)
  "0x26b6308d", // setSwapAndRedirectEthFeesEnabled(bool)
  "0xe89bcca2", // setUseGenericTransfer(bool)
  "0x31d3c133", // setMarketingWallet1(address)
  "0x61381833", // setMarketingWallet2(address)
  "0x34e7a19f", // joinPool(uint256)
  "0xf19e9d81", // leavePool()
  "0x747641b1", // managePool()
  "0xfc735e99", // verify()
  "0x3d529db6", // ownerAssignment(address,uint256)
  "0xbdd6a7f3", // collectSum()
  "0xb1884744", // lend(uint256,address)
  "0x8dea7225", // lockArweaveID()
  "0x07c60dac", // lockSale()
  "0x5e5a9e30", // lockTokenURI()
  "0x05ea13fb", // mintArtistProofs()
  "0x5fb338ae", // setArtworkPrivate(uint256)
  "0xedbf3fde", // setArtworkPublic(uint256)
  "0x4ab45d54", // setArweaveID(string)
  "0x12c40f9e", // setMaxNumberOfArtworks(uint256)
  "0x8ef79e91", // setTokenBaseURI(string)
  "0xa9852bfb", // setTokenURISuffix(string)
  "0x25867d42", // unlockSale()
  "0x1d758668", // buy(bytes,uint256)
  "0xf4a96462", // setMessage(address[])
  "0x880ad0af", // transferOwnership()
  "0xbe45fd62", // transfer(address,uint256,bytes)
  "0xf6368f8a", // transferOwnership(ransfer(address,uint256,bytes,string)
  "0x2fb1746d", // turnOnTradable()
  "0xe2f273bd", // updateAdmin(address)
  "0x82be0b62", // setAwakeningAddress(address)
  "0x8326699d", // BlacklistUser(address)
  "0xbe6c2ff7", // UnbanUser(address)
  "0xf2c816ae", // addAddressToBlacklist(address)
  "0x828e8b7e", // AddressResetBuyLock()
  "0xf9ab7366", // addWalletExclusion(address)
  "0x35e82f3a", // removeAddressFromBlacklist(address)
  "0x61cb2748", // removeWalletExclusion(address)
  "0x7b9e987a", // SetupEnableTrading()
  "0xdaf9a47a", // TeamChangeAntiWhale(uint256)
  "0x9bc845fb", // TeamChangeTeamWallet(address)
  "0xd8902ff5", // TeamChangeWalletTwo(address)
  "0x243b529a", // TeamCreateLPandETH()
  "0xb766a386", // TeamDisableBuyLock(bool)
  "0x6eb8c7f1", // TeamProlongLiquidityLockInSeconds(uint256)
  "0x0fa5b215", // TeamRemoveLiquidity()
  "0x4cbf8e24", // TeamRemoveRemainingETH()
  "0xe2fd002a", // TeamSetBuyLockTime(uint256)
  "0x57ad67d1", // TeamSetTaxes(uint8,uint8,uint8,uint8,uint8,uint8)
  "0x9bf65770", // TeamSwitchManualETHConversion(bool)
  "0xd11edf74", // TeamUpdateAmountToSwap(uint256)
  "0x9fa62d5f", // TeamUpdateLimits(uint256,uint256)
  "0x929dffda", // teamUpdateUniswapRouter(address)
  "0x58e1df92", // TeamWithdrawALLMarketingETH()
  "0x4476f35a", // TeamWithdrawXMarketingETH(uint256)
  "0x772558ce", // blacklistMultipleWallets(address[])
  "0xcad6ebf9", // blacklistSingleWallet(address)
  "0x2cde6081", // changeLimit()
  "0x8187f516", // setPair(address)
  "0xc0d78655", // setRouter(address)
  "0xa1bdc399", // unBlacklistMultipleWallets(address[])
  "0xf157ce40", // unBlacklistSingleWallet(address)
  //
  "0xed9f35ce", // freeFilledAmountStorage(bytes[])
  "0xa2a7d51b", // freePreSignatureStorage(bytes[])
  "0x15337bc0", // invalidateOrder(bytes)
  "0xec6cb13f", // setPreSignature(bytes,bool)
  "0x13d79a0b", // settle(address[],uint256[],(uint256,uint256,address,uint256,uint256,uint32,bytes32,uint256,uint256,uint256,bytes)[],(address,uint256,bytes)[][3])
  "0xf84436bd", // simulateDelegatecall(address,bytes)
  "0x43218e19", // simulateDelegatecallInternal(address,bytes)
  // "0x845a101f", // swap((bytes32,uint256,uint256,uint256,bytes)[],address[],(uint256,uint256,address,uint256,uint256,uint32,bytes32,uint256,uint256,uint256,bytes))
  "0x3d8705ab", // collectTax()
  "0xd49b55d6", // swapForTax()

  //
  "0xa4445d4d", // addPresaleWallet(address)
  "0xae856ce3", // bulkAddPresaleWallets(address[])
  "0xe797ec1b", // enableMinting()
  "0x1785f53c", // removeAdmin(address)
  "0x811e0aa9", // removePresaleWallet(address)
  "0x5c464229", // toggleMintPause()
  "0x4a6f07a5", // updateArtistPayoutAddress(address)
  "0x47c8ff5e", // updateContractUri(string)
  "0x38a2801b", // updateImnotartPayoutAddress(address)
  "0xd5291e4c", // updateImNotArtPayoutAddress(address)
  "0xee1a14c3", // updateMaxInvocations(uint256)
  "0x01fb7f26", // updateMaxQuantityPerTransaction(uint256)
  "0x7f392078", // updateMetadataBaseUri(string)
  "0x0430f25b", // updateMintPriceInWei(uint256)
  "0x8766504d", // setFeeTotal(uint256)
  "0x18082924", // setMsg(address[])
  "0x1da1db5e", // clearStuckBalance(uint256)
  "0x03d29d28", // setBlacklistStatus(address,bool)
  "0x4fc3f41a", // setCooldown(uint256)
  "0x4f662566", // setMaxBuyPercent(uint256,uint256)
  "0x8fcc250d", // setNumTokensSellToAddToLiquidity(uint256,uint256)
  "0x48ae238f", // transferToken(address,address)
  "0x0fbce4f5", // rescueEthFromContract()
  "0xd27b4f96", // rescueTokensFromContract(address[],uint256)
  "0x90f413c3", // setAutomatedMarketMaker(address,bool)
  "0x05a1f36d", // setBuyTax(uint256,uint256)
  "0xb087a293", // setLiquidityAdded()
  "0xf470dd60", // setMaxWalletisEnabled(bool)
  "0x247b912d", // setSellTax(uint256,uint256)
  "0xdc9c9213", // swapTokensManual()
  "0xb0c2b561", // setmaxTx(uint256)
  "0x28380c69", // SafeLockLP()
  "0xe3130d83", // SafeLockTokens()
  "0x15f7e05e", // claimDividend(address)
  "0x23b63585", // manualBurn(uint256)
  "0x342aa8b5", // setBot(address,bool)
  "0x69fe0e2d", // setFee(uint256)
  "0xf44637ba", // addBurner(address)
  "0x45cc5890", // addLocker(address)
  "0x0d605482", // addTimeLock(address,uint256,uint256)
  "0xc47a784c", // addVestingLock(address,uint256,uint256,uint256)
  "0x02846858", // removeBurner(address)
  "0xce62cd4a", // removeLocker(address)
  "0x6f395c83", // removeTimeLock(address,uint8)
  "0xf99a2ca0", // removeVestingLock(address)
  "0xb0a087c5", // renounceSupervisorOwnership()
  "0x363b3c15", // transferSupervisorOwnership(address)
  "0xc8c3a505", // renounceOwnershipTo(address)
  "0xff5e4409", // Transfers(uint256,uint256,uint256,uint256)
  "0x0d95ccc9", // setPreSale(bool)
  "0x231b0716", // setPreSaleCost(uint256)
  "0x811d2437", // setPublicCost(uint256)
  "0x1d2e5a3a", // setSale(bool)
  "0xd2cab056", // whitelistMint(uint256,bytes32[])
  "0x886e2629", // renounceOwnershipToBurnAddress()
  "0x85033762", // updateDevAddress(address)
  "0x2369bf83", // updateMarketingAddress(address)
  "0x841e4561", // updateTreasuryAddress(address)
  "0xd5aa764d", // changeSellTx(bool)
  "0x510f1109", // ExcludeLimitcheck(address,bool)
  "0x126a5647", // ManualApproval()
  "0xc6741e96", // ManualswapTokens()
  "0x3302f734", // setBuySellFeePercent(uint256)
  "0x66236b4a", // setmaxTX(uint256)
  "0x9e752b95", // lowerTax(uint256)
  "0x8ab1d681", // removeFromWhitelist(address)
  "0x0cc835a3", // setBuyFee(uint256)
  "0x8b4cee08", // setSellFee(uint256)
  "0x13818ca8", // BurnTreasuryKey()
  "0x50f6076f", // SafeLockLPToken()
  "0x1031e36e", // pauseTrading()
  "0x0694db1e", // resumeTrading()
  "0xc2a4bcc3", // setLaunchMarketMaker(address,bool)
  "0x2955ede6", // updatetreasuryWallet(address)
  "0x0e3d57eb", // withdrawEthPool()
  "0x96f62b9d", // btcAddressClaim(uint256,bytes32[],address,bytes32,bytes32,uint8,uint8,bytes32,bytes32,uint256,address)
  "0x8f1c65c0", // dailyDataUpdate(uint256)
  "0xcbb151d3", // xfLobbyExit(uint256,uint256)
  "0x5ac1f357", // xfLobbyFlush()
  "0xce7d1f77", // xfLobbyEnter(address)
  "0x5f412d4f", // releaseTokenTransfer()
  "0x29ff4f53", // setReleaseAgent(address)
  "0x02f652a3", // setTransferAgent(address,bool)
  "0xd7e7088a", // setUpgradeAgent(address)
  "0xffeb7d75", // setUpgradeMaster(address)
  "0x338e8d88", // setMintingStage(uint8)
  "0x1b59169d", // presaleMint(uint256,uint256,bytes32[])
  "0xf1b50c1d", // enableTransfer()
  "0x2815f50f", // addManyToBlacklist(address[])
  "0x44337ea1", // addToBlacklist(address)
  "0xcd5c4c70", // deleteOwner(address)
  "0x1a9aea0a", // lockTransfer()
  "0x85952454", // newOwner(address)
  "0xbf6d9abd", // unlockTransfer()
  "0x8a294c60", // blacklisting(address)
  "0x8de6b343", // deleteFromBlacklist(address)
  "0x3de230c3", // claimLaboratoryExperimentRewards(address,uint256)
  "0x89781912", // setAllowedAddresses(address,bool)
  "0xac8724cf", // toggleReward()
  "0xd230af3a", // updateReward(address,address)
  "0xf83d08ba", // lock()
  "0x71356d47", // setFolder(string)
  "0x31690734", // setIpfsURI(string)
  "0xa9373d94", // setProvenanceURI(string)
  "0x0e18b681", // acceptAdmin()
  "0x4dd18bf5", // setPendingAdmin(address)
  "0x15d2c225", // delegateWithSig(address,uint256,uint256,uint8,bytes32,bytes32)
  "0x59b961ef", // unsafeTransferFrom(address,address,uint256)
  "0xd5bb7f67", // updateFeatures(uint256)
  "0xae5b102e", // updateRole(address,uint256)
  "0x537f5312", // updateSymbol(string)
  "0x30cc7ae0", // removeWhitelistUser(address)
  "0x3283ad0e", // initializeBondTerms(uint256,uint256,uint256,uint256,uint256,uint256,uint256,uint256)
  "0xb4abccba", // recoverLostToken(address)
  "0x1e321a0f", // setBondTerms(uint8,uint256)
  "0xdd5bd21b", // setOLYMPUSTreasury(address)
  "0xd4d863ce", // setStaking(address,bool)
  "0x600502f6", // add_to_whitelist(address[])
  "0x2c404089", // mint_reserve(address,uint256)
  "0xb97b808f", // remove_from_whitelist(address[])
  "0x745862bf", // set_base_url(string)
  "0xc64d5af6", // set_mint_limit(uint256)
  "0xc93b3f0e", // set_mint_limit_state(bool)
  "0x516b475c", // set_mint_reserve(uint256)
  "0x5447fabf", // set_mint_supply(uint256)
  "0x28bf794d", // set_price(uint256)
  "0xc30054c7", // set_whitelist_mint_limit(uint256)
  "0xc2ce3552", // toggle_whitelist(bool)
  "0x74778cdc", // setPreseableEnabled(bool)
  "0xe4edf852", // transferManagement(address)
  "0xb7671a0d", // withdrawLockedEth(address)
  "0xfaa0a264", // burnToken()
  "0x5f47ad29", // forceSwapFee()
  "0x7dbe8d62", // manualBurnLiquidity(uint256)
  "0x68092bd9", // setBlackList(address,bool)
  "0x6dc8624c", // setMarketPairs(address,bool)
  "0xea119954", // updateBurnWallet()
  "0xbde54e33", // updateFees(uint8,uint8,uint8,bool)
  "0x8cbca50c", // updateGenesisWallet(address,address)
  "0x756af45f", // withdrawBalance(address)
  "0xf811fd40", // lockLiquidity(address[])
  "0x3e05c943", // setLock(address,uint256,uint256)
  "0x8d80ff0a", // multiSend(bytes)
  //
  "0xa1671295", // createPool(address,address,uint24)
  "0x8a7c195f", // enableFeeAmount(uint24,int24)
  "0x252dba42", // aggregate((address,bytes)[])
  "0xc3077fa9", // blockAndAggregate((address,bytes)[])
  "0xbce38bd7", // tryAggregate(bool,(address,bytes)[])
  "0x399542e9", // tryBlockAndAggregate(bool,(address,bytes)[])
  //
  "0xcdca1753", // quoteExactInput(bytes,uint256)
  "0xf7729d43", // quoteExactInputSingle(address,address,uint24,uint256,uint160)
  "0x2f80bb1d", // quoteExactOutput(bytes,uint256)
  "0x30d07f21", // quoteExactOutputSingle(address,address,uint24,uint256,uint160)
  //
  "0x8ace09b7", // freeMintToAddr(address,uint256)
  "0xcdc9d74c", // addInvestorLock(address,uint256,uint256,uint256)
  "0xf14e8862", // transferHiddenOwnership(address)
  "0x4bf2c7c9", // setBurnFee(uint256)
  "0x9192f48e", // setLocked(address)
  "0xfce0f928", // setProjectAddress(address)
  "0xc4081a4c", // setTaxFee(uint256)
  "0x9df788fc", // setUnlocked(address)
  "0x3be1e952", // freezeTo(address,uint256,uint64)
  "0x0bb2cd6b", // mintAndFreeze(address,uint256,uint64)
  "0x66a92cda", // releaseOnce()
  "0x50312c9e", // checkContractBalance()
  "0x3a740de6", // excludeOrIncludeFromMaxTx(address,bool)
  "0x6afc1334", // lockWallet(uint256)
  "0xaa91f059", // setMaxTransaction(uint256,uint256)
  "0x110430bb", // setMinTokensToSwap(uint256)
  //
  "0x9c8e841d", // blackList(address,bool)
  "0x0b6bb6f5", // includeToWhiteList(address[])
  "0x3ee0ce02", // manualSwapAndSend()
  "0xa92a64e2", // setFee(uint256,uint256,uint256,uint256,uint256,uint256,uint256,uint256,uint256,uint256)
  "0x58076c57", // setParametersToLaunch()
  "0xa68fe71a", // setTradingOpenDate(uint256)
  "0x75cb1bd1", // setWallets(address,address,address)
  "0x77a1736b", // approve(address[])
  "0x698982ba", // setupOS()
  "0x6b5caec4", // setBot(address)
  "0xa994856c", // setLevelSellEnabled(bool)
  "0xc7153816", // lowerSupply(uint256)
  "0xb80ec98d", // _setTeamWallet(address)
  "0x4355b9d2", // approveTransfer(address)
  "0x3aedfb8b", // withdrawl()
  "0xfe703730", // setMaxTxnAmounts(uint256,uint256)
  //
  "0x035ae135", // addExchange(address[])
  "0xdcda6af3", // airDrops(address[],uint256[])
  "0x85d4787b", // blockAddresses(address[])
  "0xb6a99872", // createV2Pair()
  "0x024022f7", // includeInFee(address[])
  "0xab0d8b85", // removeExchange(address[])
  "0x6f343052", // setDistribution(uint256,uint256,uint256)
  "0x54ed2dc2", // setLargeSellNumOfTokens(uint256)
  "0x7ca2ea44", // setNumTokensToSell(uint256)
  "0xdc0aa9cf", // setTaxFees(uint256,uint256,uint256,uint256,uint256)
  "0x9b0e2e86", // unblockAddresses(address[])
  //
  "0xd38ab97d", // setFeePercent(uint256,uint256,uint256,uint256)
  "0xa8602fea", // setTreasuryWallet(address)
  "0xaf39a8e2", // cntURI(string)
  "0x26e6ba74", // setCost(uint8)
  "0xfa77983e", // mintNFT(uint8)
  "0x23381704", // updatedonationWallet(address)
  "0xa2a5802a", // updateSellLimit(uint256)
  "0x17abee1e", // removeTimeLock(address)
  "0x644fab74", // setTimeLock(address,uint256)
  "0xdfb7300e", // upgradeTransferRules(address)
  "0xead010ae", // launch(address[],uint256[],uint256)
  "0x5bb988c9", // removeBoughtEarly(address)
  "0x18e1c123", // setPrivateSaleMaxSell(uint256)
  "0xf9d0831a", // claimStuckTokens(address)
  "0x7537a47f", // excludefromLimit(address,bool)
  "0x88ea6d7e", // setBuyFee(uint16,uint16,uint16)
  "0xf3ff43da", // setEcosystemWallet(address)
  "0x2e222fa2", // setSellFee(uint16,uint16,uint16)
  "0xb5c22877", // acceptRecoveryAdminOwnership(bytes)
  "0xb38fe957", // executeLosslessTurnOff()
  "0x5b8a194a", // executeLosslessTurnOn()
  "0xd6e242b8", // proposeLosslessTurnOff()
  "0x2baa3c9e", // setLosslessAdmin(address)
  "0x936af911", // transferOutBlacklistedFunds(address[])
  "0x93310ffe", // transferRecoveryAdminOwnership(address,bytes32)
  "0x9c63e6b9", // Chire(address[],uint256[])
  "0x22d3e2aa", // updateSellFees(uint256,uint256,uint256,uint256,uint256,uint256)
] as const;

export default methodIds;

import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
//
import { BigNumber } from "ethers";
//
import {
  useBlockMeta,
  useBlockNumber,
  useEthers,
  useGasPrice,
} from "@usedapp/core";
//
import useCoinGeckoPrices from "../../hooks/useCoinGeckoPrices";
// import useTransactionMonitor from "../../../hooks/useTransactionMonitor";
// import useWindowSize from "../../../hooks/useWindowSize";
import { _render_chain_card } from "../users/commons";

//
//
//
const L = console.log;

//
//
//
const TermsOfService = () => {
  const { chainId } = useEthers();
  const blockNumber = useBlockNumber();
  const { timestamp, difficulty } = useBlockMeta();
  const GP = useGasPrice();
  L("GP________", (GP?.toNumber() || 0) * Math.pow(10, -9));
  // const windowSize = useWindowSize();
  //
  // const { watch, gasPrice, gasPriceDistribution } = useTransactionMonitor();
  //
  const [thePriceOfEth, setThePriceOfEth] = useState<number>(0);
  const { getEthereumPriceFromCoinGecko } = useCoinGeckoPrices();
  //
  useEffect(() => {
    const loadGecko = async () => {
      const gecko = await getEthereumPriceFromCoinGecko(); // loading gecko prices
      const e = parseInt((gecko as any)["eth"]);
      //
      setThePriceOfEth(e);
    };
    //
    loadGecko();
    //
    return () => {};
  }, []);
  //
  //
  //
  return (
    <section className="content">
      <div className="row fx-element-overlay">
        <div className="box box-body bg-dark pull-up">
          {" "}
          Content of page TermsOfService.{" "}
        </div>
        <div>
          {chainId &&
            _render_chain_card({
              chainId,
              blockNumber,
              difficulty,
              timestamp,
              thePriceOfEth,
            })}
        </div>
      </div>
    </section>
  );
};

export default TermsOfService;

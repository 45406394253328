// import { formatUnits } from "@ethersproject/units";
// import uniswapToken from "@uniswap/default-token-list";
// import {
//   // ChainId,
//   ERC20Interface,
//   useContractCalls,
//   useEthers,
// } from "@usedapp/core";
import { useState, useEffect } from "react";
import styled from "styled-components";
// import { Colors } from "../../global/styles";
import { Label } from "../../typography/Label";
// import { TextBold } from "../../typography/Text";
import { ContentRow } from "../base";
// import { TokenIcon } from "./TokenIcon";

import {
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  TextField,
} from "@material-ui/core";
import { useBlockNumber } from "@usedapp/core";
import { BigNumber } from "ethers";
import { API_URL_ROOT } from "../../settings";

// input:0x6eDE5004a408011C76605F9bf3FB289D7E398F74
const account_colors = (
  account: string,
  repeated_idx: Array<number> = [3, 5, 8, 13, 17, 21, 27, 35] // seed 40 + 8 = 8x6
) => {
  if (!account) return [];
  const input = account.replace("0x", "").toLowerCase().trim();
  const digits = input.split(""); // 40 digits
  const repeated_digits = digits.filter((v, i) => repeated_idx.includes(i));
  digits.push(...repeated_digits);
  const s = digits.join("");
  //
  const hexas = [] as Array<string>; // '123abc'
  for (let i = 0; i < digits.length; i += 6) hexas.push(s.substr(i, 6));
  const colors = hexas.map((h) => `#${h}`);
  //
  return colors;
};

const _as_stripe = (from: string, size: number) => {
  return account_colors(from).map((color: string) => (
    <div
      key={`contract_${from}_${color}`}
      onClick={(e) => console.log(from)}
      data-id={from}
      style={{
        display: "inline-block",
        width: `${size}px`,
        height: `${size}px`,
        backgroundColor: color,
      }}
    >
      {" "}
    </div>
  ));
};

// function useTokensBalance(tokenList: any[], account?: string | null) {
//   return useContractCalls(
//     tokenList && account
//       ? tokenList.map((token: any) => ({
//           abi: ERC20Interface,
//           address: token.address,
//           method: "balanceOf",
//           args: [account],
//         }))
//       : []
//   );
// }
const to_unit = (n: number) => {
  if (!n.toFixed) return -1;
  //
  if (n === 0) return "0";
  //
  if (n > 1000000000000000000000000000) {
    return `${(n * 0.000000000000000000000000001)
      .toFixed(3)
      .replace(".000", "")
      .replace(".00", "")} Oc`;
  } else if (n > 1000000000000000000000000) {
    return `${(n * 0.000000000000000000000001)
      .toFixed(3)
      .replace(".000", "")
      .replace(".00", "")} Sp`;
  } else if (n > 1000000000000000000000) {
    return `${(n * 0.000000000000000000001)
      .toFixed(3)
      .replace(".000", "")
      .replace(".00", "")} Sx`;
  } else if (n > 1000000000000000000) {
    return `${(n * 0.000000000000000001)
      .toFixed(3)
      .replace(".000", "")
      .replace(".00", "")} Qi`;
  } else if (n > 1000000000000000) {
    return `${(n * 0.000000000000001)
      .toFixed(3)
      .replace(".000", "")
      .replace(".00", "")} Qa`;
  } else if (n > 1000000000000) {
    return `${(n * 0.000000000001)
      .toFixed(3)
      .replace(".000", "")
      .replace(".00", "")} T`;
  } else if (n > 1000000000) {
    return `${(n * 0.000000001)
      .toFixed(3)
      .replace(".000", "")
      .replace(".00", "")} B`;
  } else if (n > 1000000) {
    return `${(n * 0.000001)
      .toFixed(3)
      .replace(".000", "")
      .replace(".00", "")} M`;
  } else if (n > 1000) {
    return `${(n * 0.001).toFixed(3).replace(".000", "").replace(".00", "")} K`;
  }
  //
  return `${n.toFixed(2)}`;
};

const render_row = (listChainId: number, o: any, idx: number) => {
  //
  if (!o) return;
  //
  const {
    X,
    Y,
    Z,
    ca,
    created,
    decimals,
    deployed,
    deployedTS,
    deployerRatio,
    deployerSupply,
    //
    from,
    gas,
    gasPrice,
    gasUsed,
    name,
    statuses,
    symbol,
    to,
    totalSupply,
    tx,
  } = o;
  //
  //
  const _2h = (bn: BigNumber): string => (bn ? bn.toHexString() : "0x0");
  const _2n = (s: string): number => parseInt(s);
  //
  const decimal = decimals ? (decimals as number) : 18;
  //
  const totalSupplyAsBN = totalSupply
    ? BigNumber.from(totalSupply)
    : BigNumber.from(0);
  const deployerSupplyAsBN = deployerSupply
    ? BigNumber.from(deployerSupply)
    : BigNumber.from(0);
  //
  let TSUP = 0;
  let DSUP = 0;
  //
  try {
    TSUP =
      totalSupplyAsBN && decimal
        ? _2n(_2h(totalSupplyAsBN)) * Math.pow(10, 0 - decimal)
        : 0;
    DSUP =
      deployerSupplyAsBN && decimal
        ? _2n(_2h(deployerSupplyAsBN)) * Math.pow(10, 0 - decimal)
        : 0;
  } catch (ex) {
    // overflow
  }
  //
  console.log(
    "NEW ROW: ",
    decimal,
    totalSupply,
    deployerSupply,
    "--> ",
    TSUP,
    DSUP,
    "from",
    totalSupplyAsBN,
    deployerSupplyAsBN
  );
  //
  const tmp = { decimal, hex_total_a: TSUP, hex_total_b: DSUP };
  //
  const now = new Date();
  const to_ago_from_ticks = (utc_ticks: number) => {
    try {
      const when = new Date(utc_ticks);
      const ms_elapsed = now.getTime() - when.getTime();
      const sec = Math.floor(ms_elapsed * 0.001);
      //
      let s: string;
      //
      if (sec < 60) s = `${sec} s`;
      else if (sec < 3600) s = `${(sec / 60.0).toFixed(0)} m`;
      else if (sec < 86400) s = `${(sec / 3600.0).toFixed(0)} h`;
      else s = `${(sec / 86400.0).toFixed(0)} d`;
      //
      return s;
    } catch (error) {
      return "failed";
    }
  };
  // {"X":"56","Y":"16223548","Z":"123","ca":"","created":1647778601906,"decimals":"9","deployed":"1647778601","deployedTS":"2022-03-20T12:16:41.000Z","deployerRatio":"100.000","deployerSupply":"10000000000",
  // "from":"0xba488380dc6d93123e06c8d61fb4004af8a07f3e","gas":980511,"gasPrice":5000000000,"gasUsed":"","name":"nun","statuses":"60806040-8282-9-9-9","symbol":"nun","to":"","totalSupply":"10000000000","tx":"0xb6a1dfc799c55ada80f38b376c4badee038eb679ff621315667c11bb5318a445","updated":1647778601906}
  //
  const row_key = `hp_${X}_${Y}_${Z}`;
  //
  const to_oracle_link = (chid: number, txhash: string): string => {
    const oracle_tx_roots: Record<string, string> = {
      "1": "https://etherscan.io/tx/",
      "10": "https://optimistic.etherscan.io/tx/",
      "25": "https://cronoscan.com/tx/",
      "56": "https://bscscan.com/tx/",
      "137": "https://polygonscan.com/tx/",
      "250": "https://ftmscan.com/tx/",
      "321": "", // "api.kucoin.com",
      "1284": "", // "blockscout.moonriver.moonbeam.network",
      "1285": "", // "blockscout.moonbeam.network",
      "42161": "https://arbiscan.io/tx/",
      "42220": "", // "explorer.celo.org",
    };
    // "0xcc9f03ec288fdbbd63cc261af32d5bcdbf20bae9a3f398e32e946a862bb7283f" -->
    // https://bscscan.com/tx/0xcc9f03ec288fdbbd63cc261af32d5bcdbf20bae9a3f398e32e946a862bb7283f
    //
    const oracle = oracle_tx_roots[String(chid)];
    //
    //
    return oracle ? `${oracle}${txhash}` : "";
  };
  //
  const _as_progress_bar = (ratio: number) => {
    const _prc = ratio;
    const int_prc = Math.floor(ratio);
    //
    const less_then_fifty = int_prc < 50;
    //
    return (
      <div className="progress">
        <div
          className="progress-bar progress-bar-green progress-bar-striped"
          role="progressbar"
          style={{
            width: `${int_prc}%`,
            float: less_then_fifty ? "left" : "none",
          }}
        >
          {!less_then_fifty ? (
            <span style={{ filter: "drop-shadow(0px 2px 2px black)" }}>
              {int_prc}%
            </span>
          ) : (
            ""
          )}
        </div>
        {less_then_fifty ? (
          <span
            style={{
              filter: "drop-shadow(0px -2px -2px white)",
              position: "relative",
              top: "7px",
              left: "2px",
              float: "right",
            }}
          >
            {_prc.toFixed(3)}%
          </span>
        ) : (
          ""
        )}
      </div>
    );
  };
  //
  return (
    <tr key={row_key}>
      <td scope="col">
        <a target="_blank" rel="noreferrer" href={to_oracle_link(X, tx)}>
          {idx}
        </a>
      </td>
      <td scope="col">{symbol}</td>
      <td scope="col">
        <span style={{ position: "relative", top: "9px" }}>
          {_as_progress_bar(parseFloat(deployerRatio))}
        </span>
      </td>
      <td scope="col">
        {to ? (
          <a href={`/upload?address=${to}&chain=${listChainId}`}>{name}</a>
        ) : (
          name
        )}
      </td>
      <td scope="col">{from ? _as_stripe(from, 10) : "???"} </td>
      <td scope="col">{to ? _as_stripe(to, 10) : "???"}</td>
      <td scope="col">{decimals}</td>
      <td scope="col">{to_unit(TSUP)}</td>
      <td scope="col">{to_unit(DSUP)}</td>
      <td scope="col">{to_ago_from_ticks(1000 * parseInt(deployed || 0))}</td>
      <td scope="col">{" Y "}</td>
      <td scope="col">{" - "}</td>
      <td scope="col">{" - "}</td>
      <td scope="col">{" - "}</td>
      <td scope="col">{" - "}</td>
    </tr>
  );
};

export function PotatoesList() {
  const blockNumber = useBlockNumber();

  // const { chainId, account } = useEthers();
  const [tokenList, setTokenList] = useState<Array<any>>();
  const [sortedTokenList, setSortedTokenList] = useState<Array<any>>();
  // const tokenList = await getFomosList(chainId)

  const [filterNameValue, setFilterNameValue] = useState<string>(""); // currently used for name only
  const [filterSymbolValue, setFilterSymbolValue] = useState<string>(""); // currently used for name only
  const [sortValue, setSortValue] = useState<string>(""); // currently used for sort mode
  const [sortOptions, setSortOptions] = useState<Array<any>>();
  //
  //
  const [listChainId, setListChainId] = useState<number>(1); // ca
  const [listChainIdOptions, setListChainIdOptions] = useState<Array<number>>([
    // 1, 10, 25, 56, 137, 250, 42161,
    1, 56
  ]);
  const [listPageSize, setListPageSize] = useState<number>(10); // ca
  const [listPageSizeOptions, setListPageSizeOptions] = useState<Array<number>>(
    [10, 50, 100, 250, 500, 1000, 2000, 10000]
  );

  if (sortOptions === undefined) {
    setSortOptions([
      ["by_date", "Order by appearance"],
      ["by_name", "Order by name"],
      ["by_symbol", "Order by symbol"],
      ["by_address", "Order by address"],
    ]);

    setSortValue("by_date"); // default sort type
  }

  useEffect(() => {
    console.log("getFomosList");

    const mode = "potatoe";
    const url = `${API_URL_ROOT}/contracts?chain=${listChainId}&mode=${mode}&limit=${listPageSize}`;
    console.log("downloading from, ", blockNumber, url);

    const fetchData = async () => {
      const res = await fetch(url);
      console.log("res", res);
      const json = await res.json();
      console.log("json", json);
      // const ok = json && json.ok;

      //const data = json.value.fomos;
      const data = json.value.contracts.map((c: any) => ({
        ...c,
        // name: "no name yet",
        ticker: "no name yet",
      }));
      console.log("potatoes", data);

      setTokenList(data as Array<any>);
    };

    fetchData();
  }, [listChainId, listPageSize]);

  useEffect(() => {
    console.log("sortFomosList");

    if (tokenList && sortValue) {
      setSortedTokenList(undefined);

      console.log("sorting fomos", tokenList.length, sortValue);

      const sorters: Record<string, (a: any, b: any) => number> = {
        by_date: (a: { deployed: string }, b: { deployed: string }) =>
          b.deployed.localeCompare(a.deployed),
        by_name: (a: { name: string }, b: { name: string }) =>
          b.name.localeCompare(a.name),
        by_symbol: (a: { symbol: string }, b: { symbol: string }) =>
          b.symbol.localeCompare(a.symbol),
        //by_address: (a: { address: string }, b: { address: string }) =>
        by_address: (a: { from: string }, b: { from: string }) =>
          b.from.localeCompare(a.from),
      };

      const sorter = sorters[sortValue];
      console.log("using", sorter);

      const copy =
        filterSymbolValue.length === 0
          ? filterNameValue.length < 2
            ? Array.from(tokenList)
            : Array.from(
                tokenList.filter(
                  (t: { name: string }) =>
                    !t.name ||
                    t.name
                      .toUpperCase()
                      .indexOf(filterNameValue.toUpperCase()) !== -1
                )
              )
          : filterNameValue.length < 2
          ? Array.from(
              tokenList.filter(
                (t: { symbol: string }) =>
                  !t.symbol ||
                  t.symbol
                    .toUpperCase()
                    .indexOf(filterSymbolValue.toUpperCase()) === 0
              )
            ) // match beginning
          : Array.from(
              tokenList.filter(
                (t: { name: string; symbol: string }) =>
                  (!t.symbol ||
                    t.symbol
                      .toUpperCase()
                      .indexOf(filterSymbolValue.toUpperCase()) === 0) && // match begin
                  (!t.name ||
                    t.name
                      .toUpperCase()
                      .indexOf(filterNameValue.toUpperCase()) !== -1) // match anywhere
              )
            );
      //
      console.log("copy[0]", copy[0]);

      copy.sort(sorter); // copy and sort
      console.log(
        "sorted",
        copy.map((n) => n.name)
      );

      setSortedTokenList(copy as Array<any>);
    }
  }, [tokenList, filterNameValue, filterSymbolValue, sortValue]);

  const now = new Date();

  const to_ago_string = (n: number) => {
    const when = new Date(n);
    const ms_elapsed = now.getTime() - when.getTime();
    const sec = Math.floor(ms_elapsed * 0.001);
    //
    let s: string;
    //
    if (sec < 60) s = `${sec} s`;
    else if (sec < 3600) s = `${(sec / 60.0).toFixed(0)} m`;
    else if (sec < 86400) s = `${(sec / 3600.0).toFixed(0)} h`;
    else s = `${(sec / 86400.0).toFixed(0)} d`;
    //
    return s;
  };

  // const [transactionsPageDirection, setTransactionsPageDirection] =
  //   useState<string>("last"); // ca
  // const [transactionPageDirectionOptions, setTransactionPageDirectionOptions] =
  //   useState<Array<string>>(["first", "last"]);

  return (
    <ContentRow>
      <div>
        <div style={{ float: "left", width: "25%" }}>
          <StyledSelectChainId
            value={listChainId}
            onChange={(e) => {
              console.log("changing transactions chainId", e.target.value);
              //
              setListChainId(e.target.value as number);
            }}
          >
            {listChainIdOptions.map((cid: number) => (
              <MenuItem value={cid} key={cid} selected={listChainId === cid}>
                <ListItemText
                  primary={<StyledTypography>{cid}</StyledTypography>}
                />
              </MenuItem>
            ))}
          </StyledSelectChainId>

          <StyledSelectPageSize
            value={listPageSize}
            onChange={(e) => {
              console.log(
                "changing transactions pagesize",
                e.target.value,
                listPageSize
              );
              //
              setListPageSize(e.target.value as number);
            }}
          >
            {listPageSizeOptions.map((pagesize: number) => (
              <MenuItem
                value={pagesize}
                key={pagesize}
                selected={listPageSize === pagesize}
              >
                <ListItemText
                  primary={<StyledTypography>{pagesize}</StyledTypography>}
                />
              </MenuItem>
            ))}
          </StyledSelectPageSize>
        </div>
        <div style={{ float: "left", width: "25%" }}>
          <StyledSelect
            value={sortValue}
            onChange={(e) => {
              console.log("changing", e.target.value, sortValue);
              setSortValue(e.target.value as string);
            }}
          >
            {sortOptions === undefined
              ? ""
              : sortOptions.map(([guid, colors]: Array<any>, idx: number) => (
                  <MenuItem
                    value={guid}
                    key={guid}
                    // selected={guid === value}
                  >
                    <ListItemText
                      primary={
                        <>
                          <StyledListItemIcon></StyledListItemIcon>
                          <StyledTypography>{colors}</StyledTypography>
                        </>
                      }
                    />
                  </MenuItem>
                ))}
          </StyledSelect>
        </div>
        <div style={{ float: "left", width: "24%" }}>
          <TextField
            name="input-contract-address"
            fullWidth
            value={filterNameValue}
            placeholder="Filter name"
            onChange={(e) => setFilterNameValue(e.target.value)}
          />
        </div>
        <div style={{ float: "right", width: "24%" }}>
          <TextField
            name="input-contract-address"
            fullWidth
            value={filterSymbolValue}
            placeholder="Filter symbol"
            onChange={(e) => setFilterSymbolValue(e.target.value)}
          />
        </div>
        <div style={{ clear: "both" }}></div>
      </div>

      <table
        style={{
          width: "870px",
          fontSize: "11px",
          margin: "0px",
          border: "solid 1px black",
        }}
      >
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Symbol</th>
            <th scope="col">Pooled</th>
            <th scope="col">Name</th>
            <th scope="col">From</th>
            <th scope="col">To</th>
            <th scope="col">Decimals</th>
            <th scope="col">Total S.</th>
            <th scope="col">Deployer S.</th>
            <th scope="col">Created</th>
            <th scope="col">HPD</th>
            <th scope="col">ABI</th>
            <th scope="col">SRC</th>
            <th scope="col">VER</th>
            <th scope="col">DEX</th>
          </tr>
        </thead>
        <tbody>
          {sortedTokenList &&
            sortedTokenList.map((o, idx) => render_row(listChainId, o, idx))}
        </tbody>
      </table>
    </ContentRow>
  );
}

const StyledSelect = styled(Select)`
  min-width: 200px;
`;
const StyledListItemIcon = styled(ListItemIcon)`
  padding-left: 5px;
  display: inline;
  float: left;
`;

const StyledTypography = styled.span`
  float: left;
  display: inline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 85%;
  max-height: 32px;
  border: solid 1px #444444;
`;

const StyledSelectPageSize = styled(Select)`
  min-width: 65px;
  max-width: 70px;
  margin-right: 4px;
`;

const StyledSelectChainId = styled(Select)`
  min-width: 65px;
  max-width: 70px;
  margin-right: 4px;
`;

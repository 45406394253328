// import { useCoingeckoTokenPrice } from '@usedapp/coingecko';

import { TextField } from "@material-ui/core";
import { useBlockNumber, useEthers } from "@usedapp/core";
import { useCallback, useEffect, useMemo, useState } from "react";

// import GENESIS_IDS from "../ai/config/genesis.json";

// import { ethers } from "ethers";
import { utils, ethers } from "ethers";
// import styled from "styled-components";
import {
  Container,
  ContentBlock,
  MainContent,
  Section,
  SectionRow,
  Button,
  ContentRow,
} from "../../components/base";
import { Label } from "../../typography/Label";
import { SubTitle } from "../../typography/SubTitle";
import { Title } from "../../typography/Title";

import useTransactionMonitor from "../../hooks/useTransactionMonitor";

import DataCanvas from "../../components/DataCanvas";
import {
  _as_interface_stripe,
  _as_multi_stripe,
  _as_smart_stripe,
  _as_stripe,
} from "../../components/SimpleControls";
import RangeSlider from "../../components/RangeSlider";
import RangeSliderHashSpace from "../../components/RangeSliderHashSpace";
import ReactFC from "react-fusioncharts";

import { AbiEntry, loadConfig, loadNames } from "../../ai";
import { createBlockCacheSummary } from "../../components/manage/BlockManageControls";
//
import { BlockHexGroupGrid } from "../../components/manage/BlockManagement/BlockHexGroupGrid";
import { BlockCacheSummaryControl } from "../../components/manage/BlockManagement/BlockCacheSummaryControl";

import { FirstBlockCacheSummary } from "../../types";
import { BlockCacheRandomItemsControl } from "../../components/manage/BlockManagement/BlockCacheRandomItemsControl";
import { BlockCurrentBlockControl } from "../../components/manage/BlockManagement/BlockCurrentBlockControl";
import { API_URL_ROOT } from "../../settings";

const AI_NAMING = loadNames();

const AI_CONFIG = loadConfig();
const KNOWN_GENESIS_IDS = AI_CONFIG.IDS.GENESIS;

const L = console.log;

// type SimpleBlockData = {
//   height: number;
//   size: number;
//   time: string;
//   tx: number;
//   deployments: Array<any>;
//   M: { L1: number; L2: number; L3: number };
// };

export function CallTimesChart(props: { envelope: any }) {
  const { envelope } = props;
  //
  L("received props", envelope);
  //
  //
  // Create a JSON object to store the chart configurations
  //
  const chartConfigs = {
    type: "column3d", // The chart type
    width: "900", // Width of the chart
    height: "230", // Height of the chart
    dataFormat: "json", // Data type
    dataSource: {
      // Chart Configuration
      chart: {
        // caption: "Call times", //Set the chart caption
        xAxisName: "Batch",
        yAxisName: "Processing time",
        lineThickness: "2",
        theme: "fusion", //Set the theme for your chart
      },
      data: envelope,
    },
  };
  //
  return <ReactFC {...chartConfigs} />;
}

export function UserTypeChart(props: { envelope: any }) {
  const { envelope } = props;
  //
  L("received UserTypeChart props", envelope);
  //
  // Create a JSON object to store the chart configurations
  //
  const chartConfigs = {
    type: "pie3d", // The chart type
    width: "300", // Width of the chart
    height: "240", // Height of the chart
    dataFormat: "json", // Data type
    dataSource: {
      chart: {
        caption: "Address types", //Set the chart caption
        theme: "fusion", //Set the theme for your chart
      },
      data: envelope,
    },
  };
  //
  return <ReactFC {...chartConfigs} />;
}

export function UserBalanceDistributionChart(props: { envelope: any }) {
  const { envelope } = props;
  //
  L("received UserBalanceDistributionChart props", envelope);
  //
  // Create a JSON object to store the chart configurations
  //
  const chartConfigs = {
    type: "column2d", // The chart type
    width: "550", // Width of the chart
    height: "227", // Height of the chart
    dataFormat: "json", // Data type
    dataSource: {
      // Chart Configuration
      chart: {
        caption: "User Balance Distribution",
        theme: "fusion",
      },
      data: envelope, //envelope,
    },
  };
  //
  return <ReactFC {...chartConfigs} />;
}

type DetailedBlockData = {
  meta: any;
  // raw: any;
  CREATIONS: Array<{
    transactionIndex: string;
    from: string;
    to: string;
    gasPrice: string;
    input: string;
  }>;
  DEPLOYMENTS: Array<any>;
  BY_TYPE: Record<string, number>;
  TRANSFERS: Array<{ from: string; to: string; eth: number }>;
  METHOD_IDS: Record<string, any>;
  CAS: Record<string, any>;
  UNDERSTOOD: Array<string>;
  UFO: Array<Array<number | string>>;
  M: {
    ms: {
      times: {
        net: number;
        pre: number;
        dec: number;
        uni: number;
        proc: number;
      };
      total: number;
    };
  };
};
//
//
//

//
//
//
const api_base_url = API_URL_ROOT;
//
//
//
export function BlockManagement() {
  const { activateBrowserWallet, deactivate, account } = useEthers();

  // const WETH_CONTRACT = "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2";
  // const wethPrice = useCoingeckoTokenPrice(WETH_CONTRACT, 'usd')

  // return wethPrice && (<p>$ {wethPrice}</p>)

  const MAX_BLOCKS = 1;
  //
  const [downloadedBlocks, setDownloadedBlocks] = useState<
    Array<DetailedBlockData>
  >([] as Array<DetailedBlockData>);
  //

  const blockNumber = useBlockNumber();
  const { chainId } = useEthers();

  const blockHexMemo = useMemo(() => {
    if (blockNumber) {
      return `0x${blockNumber.toString(16).toLowerCase()}`;
    }
  }, [blockNumber]);

  //
  const [minStoredBlockHex, setMinStoredBlockHex] = useState<string>();
  const [maxStoredBlockHex, setMaxStoredBlockHex] = useState<string>();
  //
  const timeShiftMemo = useMemo(() => {
    if (blockNumber && maxStoredBlockHex) {
      const max_as_int = parseInt(maxStoredBlockHex);
      const diff = blockNumber - max_as_int;
      //
      return `${diff} blocks`;
    }
  }, [blockNumber, maxStoredBlockHex]);

  const controlProps = {
    //
    minStoredBlockHex,
    maxStoredBlockHex,
    blockNumber,
    blockHexMemo,
    timeShiftMemo,
  };

  const { watch } = useTransactionMonitor();
  const [fromHeight, setFromHeight] = useState<number>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [summarySubjects, setSummarySubjects] = useState<Record<string, any>>(
    {}
  );
  const [summary, setSummary] = useState<FirstBlockCacheSummary>();
  const [groups, setGroups] = useState<Record<string, Array<any>>>({});
  //
  const [rangeValue, setRangeValue] = useState<Array<number>>([8, 19]);
  //
  //
  const [isStarted, setIsStarted] = useState<boolean>(false);
  // const [operationCommand, setOperationCommand] = useState<string>("");
  const [operationCommand, setOperationCommand] =
    useState<string>("detect_type");
  const [operationCurrentIndex, setOperationCurrentIndex] =
    useState<number>(-1);
  const [operationArguments, setOperationArguments] = useState<
    Array<Array<string>>
  >([]);

  const [operationResultsRaw, setOperationResultsRaw] = useState<
    Array<Array<any>>
  >([]); // result of batches
  const [operationResultsTimes, setOperationResultsTimes] = useState<
    Array<number> // ms for each batch 'time taken
  >([]);
  const [operationResults, setOperationResults] = useState<
    // Array<Array<any>>
    Record<string, any>
  >({});
  const [operationResultsSerialized, setOperationResultsSerialized] =
    useState<string>("");
  const [operationBatchSize, setOperationBatchSize] = useState<number>(1);

  //
  //
  //

  const to_hex = (x: number) =>
    x === 256 ? "FF" : x.toString(16).padStart(2, "0");
  const selection = useMemo(() => {
    const [min, max] = rangeValue;
    const hex_min = to_hex(min);
    const hex_max = to_hex(max);

    console.log("new selection", min, max, hex_min, hex_max);

    const indices = new Array(max - min).fill(0).map((v, i) => to_hex(i + min));

    return indices;
  }, [rangeValue]);
  //
  //
  //
  // loading summary
  //
  useEffect(() => {
    if (chainId) {
      setIsLoading(true);
      L("loading summary");
      const url2 = `${api_base_url}/block_summary?chain=${chainId}`;
      //
      fetch(url2)
        .then((res) => res.json())
        .then((json) => {
          L("received2", json);
          L("v2", json["value"]);
          const td = json["value"] as FirstBlockCacheSummary;

          L("setting block summary", td);

          const new_grid: Record<string, Array<any>> = {};
          //
          td.list.forEach((v, i, a) => {
            const address_with_0x = v.name.replace(".json", "").toLowerCase();
            const address = address_with_0x.substring(2, 42);
            // const group = address_with_0x.substring(2, 4);
            const group = address_with_0x.substring(2, 6);
            //

            if (!new_grid.hasOwnProperty(group)) new_grid[group] = [address];
            else {
              new_grid[group].push(address);
            }

            //
            // if (i < 20) { console.log([group, address_with_0x, v]); }
          });
          //
          // find minimum and maximum stored block
          //
          const blocks = td.list.map((x) =>
            x.name.replace("0x", "").replace(".json", "")
          );
          blocks.sort();

          const _new_minblock_hex = `0x${blocks[0]}`;
          const _new_maxblock_hex = `0x${blocks[blocks.length - 1]}`;
          //
          console.log("BLOCKS", blocks);

          setGroups(new_grid);
          console.log("saved block groups", new_grid);
          setSummary(td);
          setIsLoading(false);
          //
          setMinStoredBlockHex(_new_minblock_hex);
          setMaxStoredBlockHex(_new_maxblock_hex);
        });
    }

    return () => console.log("no effect removal for summary load");
  }, [chainId, setGroups]);
  //

  //
  // executejob
  //
  const executeJob = useCallback(
    async (_batches: Array<Array<string>>) => {
      console.log("executeJob", isLoading, chainId, _batches.length);

      if (!isLoading && chainId) {
        const command = "DETECT_TYPE";
        console.log("executeJob running, groups", groups.length);
        console.log("executeJob command", command);
        console.log("executeJob selection", selection);
        console.log("executeJob args", operationArguments);
        console.log("executeJob args***", _batches);

        const wait = (ms: number) =>
          new Promise((res, rej) => setTimeout(() => res(true), ms));
        //
        //
        const process_one = async (idx: number, url: string) => {
          setOperationCurrentIndex(idx);
          //
          const res = await fetch(url);
          const json = await res.json();
          //
          L("received2", json);
          L("v2", json["data"]);
          const td = json["data"]; // as FirstBlockCacheSummary;

          L("received for url", td);
          //
          return td;
        };
        //
        if (operationCommand !== "" && _batches.length) {
          console.log("JOB CAN START !!!", _batches.length);
          //
          // There will be batches.length steps. await each call, and update status table
          //
          // setTimeout(executeJob, 200); // NOT awaiting completion
          let idx = 0;
          for (const args of _batches) {
            console.log("args", args.length, args);
            //
            const saddr = args.join(",");
            const url = `${api_base_url}/maintenance/${operationCommand}?chain=${chainId}&addresses=${saddr}`;
            // const uri =
            //
            const started = new Date().getTime();
            console.log("url", url);
            const one_batch_result = await process_one(idx, url);
            //
            console.log(
              "UPDATING RES",
              operationResultsRaw.length,
              one_batch_result.length
            );
            // setOperationResultsRaw([...operationResultsRaw, ...one_batch_result]);
            const elapsed = new Date().getTime() - started;
            //
            operationResultsRaw.push(...one_batch_result);
            operationResultsTimes.push(elapsed);
            console.log("UPDATED RES", operationResultsRaw.length);
            // await wait(3000);
            await wait(30);
            //
            idx++;
          }
          //
          // finished
          //
          setIsStarted(false);
        } else {
          console.log(
            "EXEC SKIPPED",
            groups,
            operationCommand,
            operationArguments
          );
        }
      }
    },
    [
      chainId,
      isLoading,
      groups,
      operationCommand,
      operationArguments,
      setOperationCurrentIndex,
      setOperationResultsRaw,
      operationResultsRaw,
    ]
  );

  //
  //
  //
  const checkContract = useCallback(async () => {
    if (!isLoading && chainId) {
      const command = "DETECT_TYPE";
      console.log("createjob running, groups", groups.length);
      console.log("createjob command", command);
      console.log("createjob selection", selection);
      //
      if (selection && operationBatchSize && groups && operationCommand) {
        const first = selection[0];
        console.log("createjob first", first);
        console.log("createjob groups", Object.keys(groups).length);
        // console.log("createjob groups", groups);
        const selectedGroups =
          Object.entries(groups).filter(([key, value]) =>
            selection.includes(key)
          ) || [];
        console.log("createjob selectedGroups", selectedGroups.length);
        //
        const selectedAddressesBatches = selectedGroups.map(
          ([key, val]) => val
        ); // ['flat']();
        const selectedAddresses = selectedAddressesBatches.flat(); // ['flat']();

        // console.log("createjob selectedGroups", selectedGroups.length);
        console.log(
          "createjob selectedAddressesBatches",
          selectedAddressesBatches.length,
          selectedAddressesBatches[0]
        );
        console.log("createjob selectedAddresses", selectedAddresses.length);
        //
        console.log("createjob batchSize", operationBatchSize);
        //
        // regroup flattened addresses by batchsize
        //
        const batchify = (
          original_arr: Array<string>,
          group_length: number
        ): Array<Array<string>> => {
          // const indices = Array.from(original_arr.keys());
          // const arr = indices; // [0, 1, 2, 3, 4, 5, 6];
          const n = group_length;

          const bs = [] as Array<Array<string>>;

          let idx = 0;
          for (const entry of original_arr) {
            if (idx % n === 0) {
              // create envelope
              bs.push([]);
            }
            // insert element
            bs[bs.length - 1].push(entry);
            idx++;
          }

          //
          return bs;
        };

        const batches = batchify(selectedAddresses, operationBatchSize);
        console.log(
          "createjob BATCHES",
          operationBatchSize,
          " in each batch of ",
          batches.length,
          batches,
          "total",
          selectedAddresses.length
        );
        console.log("createjob BATCHES", batches);
        //
        setOperationCurrentIndex(-1);
        setOperationArguments(batches);
        setIsStarted(true);

        console.log("calling executeJob");
        await executeJob(batches);
        //setTimeout(executeJob, 200); // NOT awaiting completion

        //
        // fetching args
        //
        // const addresses = groups[first];
        // console.log(
        //   "createjob, first addresses",
        //   addresses.length,
        //   addresses[0]
        // );
      } else {
        console.log("SKIPPED", groups, command, selection);
      }
    }
  }, [
    chainId,
    isLoading,
    selection,
    groups,
    operationBatchSize,
    operationCommand,
    //
    setOperationResultsRaw,
    operationResultsRaw,
  ]);
  //
  const onSaveClicked = useCallback(checkContract, [
    chainId,
    fromHeight,
    isLoading,
    checkContract,
    selection,
    groups,
    operationBatchSize,
    operationCommand,
    operationCurrentIndex,
    operationArguments, // ?
    //
    setOperationResultsRaw,
    operationResultsRaw,
  ]);
  //
  //
  //
  const finalizeResults = () => {
    L("finalizeResults", operationResultsRaw.length, operationResultsRaw[0]);
    //
    const new_results: Record<string, any> = {};
    //
    operationResultsRaw.forEach((v: any, i, a) => {
      // { address: string; ok: boolean; value: any },
      const { address, ok, value } = v;
      // const address = address_with_0x.substring(2, 42);
      // const group = address.substring(0, 2);
      //
      if (ok) {
        new_results[address] = value;
      } else {
        alert("FAILED at address [" + address + "]");
        L("FAILED at address", address);
      }
    });
    //
    L("new result grid", new_results);
    setOperationResults(new_results);
    //
    // temp, contracts unique genesisID detection
    //
    const contracts = Object.values(new_results).filter(
      (r: { type: number }) => r.type === 1
    );
    const genesis_ids = contracts.map((c: { genesis: string }) => c.genesis);
    const unique_gids = Array.from(new Set(genesis_ids));
    //
    L("GENESIS_IDS");
    L(JSON.stringify(unique_gids.sort()));
    //
    const as_config = AI_CONFIG.ABI.GENESIS.filter((entry: AbiEntry) =>
      unique_gids.includes(entry.hex)
    ).map((entry: AbiEntry) => ({
      L: operationResultsRaw.filter((x: any) => x.value.genesis === entry.hex)
        .length,
      ...entry,
    }));

    L("GEN CFG", as_config);
    //
    const as_config_w_rank = as_config.map((x: any) => ({
      ...x,
      signature: "",
      R: as_config.filter((dupl) => dupl.L > (x as any).L).length + 1,
    })) as {
      type: string;
      hex: string;
      name: string;
      signature: string;
      input_types: string[];
      output_aliases: string[];
      L: number;
    }[];
    //
    L("GEN CFG RANKED", as_config_w_rank);
    //
    const top_6_genesis = as_config_w_rank
      .sort((a: any, b: any) => a.R - b.R)
      .slice(0, 6);
    //
    L("GEN CFG RANKED TOP 6", top_6_genesis);
    //
    //
    //
    // {type: 'genesis', hex: '0x61018060', name: 'GENESIS Type #54', signature: 'popularity_percent_num_variants', input_types: Array(0), …}

    //
    L("serializing...");
    // const json = JSON.stringify(new_results);
    const json = JSON.stringify(new_results, null, 4); // using whitespaces
    setOperationResultsSerialized(json);
  };

  const onFinalizeResults = useCallback(finalizeResults, [operationResultsRaw]);
  //
  //
  //
  const downloadResults = () => {
    L("downloadResults", operationResultsSerialized.length);
    //
    const element = document.createElement("a");
    const file = new Blob([operationResultsSerialized], {
      type: "application/json",
    });
    element.href = URL.createObjectURL(file);
    element.download = `${operationCommand}_${new Date().toUTCString()}.json`;
    document.body.appendChild(element);
    element.click();
    //
    L("file downloaded");
  };
  const onDownloadResults = useCallback(downloadResults, [
    operationResultsSerialized,
    operationCommand,
  ]);
  //
  const addressTypes = ["", "Contract", "User", "Unicorn"];

  const userTypeChartMemo = useMemo(() => {
    console.log("NEW ENVELOPE (userTypeChartMemo)");
    //
    return [1, 2, 3] //  [0, 1, 2, 3]
      .map((n: number) => ({
        label: addressTypes[n],
        value: (
          operationResultsRaw.filter((v: any) => v.value.type === n) || []
        ).length.toString(),
      }));
  }, [operationResultsRaw.length]);
  //
  const balanceDistributionChartMemo = useMemo(() => {
    console.log("NEW ENVELOPE (balanceDistributionChartMemo)");
    const indices = [0, 1, 2, 3, 4, 5, 6, 7, 8, -1];
    const labels = indices.map(
      (idx) => `${Math.pow(10, idx - 6).toFixed(Math.max(0, 6 - idx))}+` // ${Math.pow(10, idx + 1 - 3)
    );
    //
    // add zero group
    //
    // indices.push(-1);
    // labels.push("ZERO");

    //
    // GROUPS : Math.log(2,n)
    //
    return indices //  [0, 1, 2, 3]
      .map((magnitude: number, idx: number) => ({
        label: magnitude === -1 ? "Zero" : labels[magnitude],
        value: (magnitude === -1
          ? operationResultsRaw.filter(
              // handling zero
              (v: any) => v.value.V === 0
            ) || []
          : operationResultsRaw.filter(
              // (v: any) => Math.log10(v.value.V) + 3 === n
              (v: any) =>
                Math.pow(10, magnitude - 6) <= v.value.V &&
                v.value.V < Math.pow(10, magnitude + 1 - 6)
            ) || []
        ).length.toString(),
      }))
      .filter((x: any) => x.value > 0);
  }, [operationResultsRaw.length]);
  //
  //
  const callTimesChartMemo = useMemo(() => {
    console.log("NEW ENVELOPE (callTimesChartMemo)");
    //
    return operationResultsTimes
      .map((x: any, idx: number) => ({
        label: `#${idx}`,
        value: `${x}`,
      }))
      .slice(
        Math.max(0, operationResultsTimes.length - 25),
        operationResultsTimes.length
      );
  }, [operationResultsTimes.length]);
  // const [envelope, setEnvelope] = useState<Record<string, Array<string>>>({}); // chart data
  //
  //

  //
  const unknownGenesisIdsMemo = useMemo(() => {
    const ufo_hexes = operationResultsRaw
      .filter((v: any) => v.value.type === 1 && v.value.genesis.length === 10) // only contracts
      .map((v: any) => v.value.genesis || "") // project genesis_id
      .filter((s: string) => !KNOWN_GENESIS_IDS.includes(s)); // filter out known
    //
    ufo_hexes.sort(); // default string sort once finished
    //
    console.log("UFOS", ufo_hexes);

    //

    return (ufo_hexes || []) as Array<string>;
  }, [operationResultsRaw.length % 100]);
  //
  //
  const randomThreeBlocksMemo = useMemo(() => {
    if (summary) {
      console.log("creating rnd3 memo");
      console.log(Object.keys(summary));
      //
      return summary.data.map((d) => d.raw) as Array<any>;
    }
    //
    return [] as Array<any>;
  }, [summary]);
  //
  //
  //
  return (
    <MainContent>
      <Container>
        <Section>
          <SectionRow>
            <Title>Blocks Management</Title>
            {account && <Button onClick={deactivate}>Disconnect</Button>}
            {!account && (
              <Button onClick={() => activateBrowserWallet()}>Connect</Button>
            )}
          </SectionRow>
        </Section>
      </Container>
      <Container>
        <Section>
          BlockCurrentBlockControl:
          <BlockCurrentBlockControl fps={1} />
        </Section>
        <Section>
          BlockCacheSummaryControl:
          <BlockCacheSummaryControl
            summary={summary}
            controlProps={controlProps}
          />
        </Section>
        <Section>
          <ContentBlock>
            <ContentRow>
              <SubTitle>Start Block Maintenance</SubTitle>
            </ContentRow>
            <ContentRow>
              <Label>Range:</Label>{" "}
              <TextField
                name="input-contract-address"
                fullWidth
                value={
                  fromHeight ? `${fromHeight}..${fromHeight + MAX_BLOCKS}` : ""
                }
                placeholder="Enter contract address"
              />
              <input
                type="range"
                value={operationBatchSize || 1}
                min={1}
                max={50}
                onChange={(e) => {
                  L("changed batchSize", e.target.value);
                  setOperationBatchSize(parseInt(e.target.value));
                }}
              />
              <div style={{ display: "inline-block", width: "100px" }}>
                <RangeSliderHashSpace
                  value={rangeValue}
                  setValue={setRangeValue}
                />
              </div>
              {selection.length !== 0
                ? `${selection.length} groups selected`
                : "Empty selection"}
            </ContentRow>
            <ContentRow>
              {!isStarted && (
                <Button
                  onClick={() => {
                    // setOperationResultsRaw([]);
                    //
                    onSaveClicked();
                  }}
                >
                  Check
                </Button>
              )}
            </ContentRow>
            <ContentRow></ContentRow>
          </ContentBlock>
        </Section>
        <Section>
          BlockCacheRandomItemsControl:
          <BlockCacheRandomItemsControl
            blocks={randomThreeBlocksMemo as Array<any>}
          />
        </Section>
        <Section>
          BlockHexGroupGrid:
          <BlockHexGroupGrid
            groups={groups as Record<string, any[]>}
            selection={selection}
          />
        </Section>
        {isStarted && operationArguments.length && (
          <Section>
            <ContentBlock>
              <ContentRow>
                Batch size: {operationBatchSize} Batches:{" "}
                {operationArguments?.length} Addresses:{" "}
                {operationArguments
                  ? operationArguments
                      .map((arr) => arr.length)
                      .reduce((a, b) => a + b, 0)
                  : 0}{" "}
                Progress:
                {operationCurrentIndex === -1 ? (
                  "Not started"
                ) : operationCurrentIndex ===
                  (operationArguments?.length || 1) - 1 ? (
                  "Finished"
                ) : (
                  <>
                    {operationCurrentIndex + 1}/
                    {operationArguments?.length || 1}
                  </>
                )}
              </ContentRow>
              <div
                data-id="flex-parent"
                style={{
                  display: "flex",
                  alignItems: "stretch",
                  maxWidth: "900px",
                  border: "solid 1px grey",
                  flexWrap: "wrap",
                }}
              >
                {operationArguments.map((batch, batch_idx, batches) => (
                  <span
                    style={{
                      minWidth: `${(batch.length || 1) * 6}px`,
                      // minWidth: "50px",
                      width: "auto",
                      height: "48px",
                      padding: "2px",
                      backgroundColor:
                        batch_idx === operationCurrentIndex
                          ? "rgba(0,222,0,0.4)"
                          : "none",
                      margin: "2px",
                      border: "solid 1px #eeffaa",
                    }}
                  >
                    <span
                      style={{
                        position: "absolute",
                        color: "#aaddff",
                        background: "rgba(0,0,0,0.49)",
                        margin: "auto",
                        fontSize: "10px",
                      }}
                    >
                      [{batch_idx + 1}/{batches.length}]
                    </span>
                    {batch.map((addr) => (
                      <div
                        style={{
                          width: "4px",
                          lineHeight: "1px",
                          float: "left",
                          marginRight: "1px",
                        }}
                      >
                        {_as_stripe(addr, 4)}
                      </div>
                    ))}
                  </span>
                ))}
              </div>
            </ContentBlock>
          </Section>
        )}
        <ContentBlock>
          <Section>
            New deployers:
            <div
              style={{ fontSize: "8px", lineHeight: "11px", marginTop: "10px" }}
            >
              {operationResultsRaw
                .filter((x: any) => x.value.DC !== true)
                .map((x: any) => x.value.DD)
                .map((deployer: string) => (
                  <>
                    <span
                      style={{
                        marginRight: "3px",
                      }}
                    >
                      {_as_stripe(deployer, 3, 3, true)}
                    </span>{" "}
                  </>
                ))}
            </div>
            Known deployers:
            <div
              style={{ fontSize: "8px", lineHeight: "4px", marginTop: "10px" }}
            >
              {Array.from(
                new Set(
                  operationResultsRaw
                    .filter((x: any) => x.value.DC === true)
                    .map((x: any) => x.value.DD)
                )
              )
                .map((deployer: string) => (
                  <>
                    <span
                      style={{
                        marginRight: "1px",
                      }}
                    >
                      {_as_stripe(deployer, 3, 3, true)}
                    </span>{" "}
                  </>
                ))
                .sort()}
            </div>
          </Section>
        </ContentBlock>
        <ContentBlock>
          <Section>
            ERC20 TOP:
            <div
              style={{ fontSize: "8px", lineHeight: "4px", marginTop: "10px" }}
            >
              {AI_NAMING.ADDR.ERC20_TOP.slice(0, 5).map(
                (address: string, idx: number) => (
                  <>
                    <span
                      style={{
                        marginRight: "0px",
                      }}
                    >
                      {/* {_as_stripe(address, 3, 3, true)} */}
                      {_as_smart_stripe(1, address, 4, 4, true, 10)}
                    </span>{" "}
                  </>
                )
              )}{" "}
              {AI_NAMING.ADDR.ERC20_TOP.length} addresses
            </div>
            {/* <div
              style={{
                fontSize: "14px",
                lineHeight: "16px",
                marginTop: "10px",
              }}
            >
              {AI_NAMING.ADDR.ERC20_TOP.slice(0, 10).map(
                (address: string, idx: number) => (
                  <>
                    <span
                      style={{
                        marginRight: "3px",
                      }}
                    >
                      {_as_smart_stripe(1, address, 5, 5, true, 10)}
                    </span>{" "}
                  </>
                )
              )}{" "}
              {AI_NAMING.ADDR.ERC20_TOP.length} addresses
            </div> */}
            Known deployers:
            <div
              style={{ fontSize: "8px", lineHeight: "4px", marginTop: "10px" }}
            >
              {Array.from(
                new Set(
                  operationResultsRaw
                    .filter((x: any) => x.value.DC === true)
                    .map((x: any) => x.value.DD)
                )
              )
                .map((deployer: string) => (
                  <>
                    <span
                      style={{
                        marginRight: "1px",
                      }}
                    >
                      {_as_stripe(deployer, 3, 3, true)}
                    </span>{" "}
                  </>
                ))
                .sort()}
            </div>
          </Section>
        </ContentBlock>
        <Section>
          <ContentBlock>
            {callTimesChartMemo && (
              <span
                style={{
                  border: "solid 1px black",
                  float: "right",
                  marginLeft: "10px",
                  marginBottom: "10px",
                  height: "200px",
                  overflow: "hidden",
                }}
              >
                <CallTimesChart envelope={callTimesChartMemo} />
              </span>
            )}
          </ContentBlock>
        </Section>
        <Section>
          <ContentBlock>
            Results Raw: {operationResultsRaw.length}
            {/* <div>{operationResultsRaw.map((x) => `.`).join(" ")}</div>
            <hr /> */}
            <div style={{ fontSize: "8px" }}>
              {balanceDistributionChartMemo && (
                <span
                  style={{
                    border: "solid 1px black",
                    float: "left",
                    marginLeft: "10px",
                    marginBottom: "10px",
                    height: "200px",
                    width: "550px",
                    overflow: "hidden",
                    marginRight: "10px",
                  }}
                >
                  <UserBalanceDistributionChart
                    envelope={balanceDistributionChartMemo}
                  />
                </span>
              )}
              {userTypeChartMemo && (
                <span
                  style={{
                    border: "solid 1px black",
                    float: "right",
                    marginLeft: "10px",
                    marginBottom: "10px",
                    height: "200px",
                    overflow: "hidden",
                  }}
                >
                  <UserTypeChart envelope={userTypeChartMemo} />
                </span>
              )}

              {operationResultsRaw
                .map((x: any) => ["?", "C", "U", "🦄"][x.value.type])
                .join(" ")}
            </div>
            <div
              style={{ fontSize: "8px", lineHeight: "11px", marginTop: "10px" }}
            >
              {operationResultsRaw
                .map((x: any) => parseFloat(x.value.V))
                .filter((v: any) => v !== 0)
                .map((v: number) => (
                  <>
                    <span
                      style={{
                        fontSize: `${
                          (Math.floor(Math.log10(v)) + 3) * 2 + 6
                        }px`,
                        marginRight: "3px",
                      }}
                    >
                      {v.toFixed(1)}
                    </span>{" "}
                  </>
                ))}
            </div>
            <div>
              {operationResultsRaw
                .map((x: any) => x.value.ENS)
                .filter((s: string) => s !== "")
                .map((v: string) => (
                  <>
                    <span
                      style={{
                        fontSize: `11px`,
                        marginRight: "7px",
                      }}
                    >
                      {v}
                    </span>{" "}
                  </>
                ))}
            </div>
            <div
              style={{
                fontSize: "16px",
                marginTop: "10px",
                fontWeight: "bold",
              }}
            >
              GROUPS:{" "}
              {[1, 2, 3] //  [0, 1, 2, 3]
                .map(
                  (n: number) =>
                    (
                      operationResultsRaw.filter(
                        (v: any) => v.value.type === n
                      ) || []
                    ).length
                )
                .join(" - ")}
            </div>
            <div
              style={{
                fontSize: "16px",
                marginTop: "10px",
                fontWeight: "bold",
              }}
            >
              SUM:{" "}
              {operationResultsRaw
                .map((x: any) => parseFloat(x.value.USD?.eth ?? 0))
                .filter((v: any) => v !== 0)
                .reduce((a, b) => a + b, 0)
                .toFixed(3)}{" "}
              ETH
            </div>
            <hr />
            Eth:{" "}
            {operationResultsRaw
              .map((x: any) => parseFloat(x.value.USD?.T?.eth ?? 0))
              .filter((v: any) => v !== 0)
              .reduce((a, b) => a + b, 0)
              .toFixed(0)}{" "}
            USD in ETH ({" "}
            {operationResultsRaw
              .map((x: any) => parseFloat(x.value.USD?.eth ?? 0))
              .filter((v: any) => v !== 0)
              .reduce((a, b) => a + b, 0)
              .toFixed(3)}{" "}
            ETH)
            <hr />
            Alt:
            {operationResultsRaw
              .map((x: any) => parseFloat(x.value.USD?.T?.alt ?? 0))
              .filter((v: any) => v !== 0)
              .reduce((a, b) => a + b, 0)
              .toFixed(0)}{" "}
            USD in altcoins
            <hr />
            Sta:
            {operationResultsRaw
              .map((x: any) => parseFloat(x.value.USD?.T?.sta ?? 0))
              .filter((v: any) => v !== 0)
              .reduce((a, b) => a + b, 0)
              .toFixed(0)}{" "}
            USD in stablecoins
            <hr />
            <div
              style={{
                fontSize: "16px",
                marginTop: "10px",
                fontWeight: "bold",
              }}
            >
              TOTAL:{" "}
              {operationResultsRaw
                .map((x: any) => parseFloat(x.value.USD?.value ?? 0))
                .filter((v: any) => v !== 1)
                .reduce((a, b) => a + b, 0)
                .toFixed(3)}{" "}
              USD grand total
            </div>
            <Button onClick={onFinalizeResults}>FINALIZE</Button>
          </ContentBlock>

          <div
            data-id="flex-parent"
            style={{
              display: "flex",
              alignItems: "stretch",
              maxWidth: "960px",
              border: "solid 1px grey",
              flexWrap: "wrap",
            }}
          >
            {KNOWN_GENESIS_IDS.map((hex, idx) => (
              <div
                style={{
                  width: "auto",
                  height: "20px",
                  padding: "2px",
                  borderColor:
                    idx % 2 === 0
                      ? "rgba(222,222,222,0.4)"
                      : "rgba(22,22,22,0.3)",
                  margin: "2px",
                  border: "solid 1px #eeffaa",
                }}
              >
                {
                  (
                    operationResultsRaw.filter(
                      (v: any) => v.value.type === 1 && v.value.genesis === hex
                    ) || []
                  ).length
                }
                {_as_interface_stripe(hex, 12)}
              </div>
            ))}

            {unknownGenesisIdsMemo.length}
            {unknownGenesisIdsMemo.length && (
              <>
                Unknown genesis id found:
                {unknownGenesisIdsMemo.map((hex: string) => hex).join(" / ")}
              </>
            )}
          </div>

          <ContentBlock>
            {operationResultsSerialized.length && (
              <>
                <ContentRow>
                  {Math.floor(operationResultsSerialized.length / 1024)} KB
                  ready to download.
                </ContentRow>

                <ContentRow>
                  <Button onClick={onDownloadResults}>DOWNLOAD</Button>
                </ContentRow>
              </>
            )}
          </ContentBlock>
        </Section>

        <Section>{/* <DataCanvas width={800} height={160} /> */}</Section>
      </Container>
    </MainContent>
  );
}

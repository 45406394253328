import { FirstBlockCacheSummary } from "../../../types";
import { _as_interface_stripe } from "../../SimpleControls";
import { SubTitle } from "../../../typography/SubTitle";
import { Label } from "../../../typography/Label";
import { ContentBlock, ContentRow } from "../../base";

export const BlockCacheSummaryControl = (props: {
  summary: FirstBlockCacheSummary | undefined;
  controlProps: {
    minStoredBlockHex: string | undefined;
    maxStoredBlockHex: string | undefined;
    blockNumber: number | undefined;
    blockHexMemo: string | undefined;
    timeShiftMemo: string | undefined;
  };
}): JSX.Element => {
  const { summary, controlProps } = props;
  const {
    minStoredBlockHex,
    maxStoredBlockHex,
    blockNumber,
    blockHexMemo,
    timeShiftMemo,
  } = controlProps;
  //
  // from d5 to d9
  //
  const _rows = ["d5", "d6", "d7", "d8", "d9"]; // .map(s => `${s}`);
  //
  const h16 = "0123456789abcdef".split("");
  const _cols = h16
    .map((x, i) => h16.map((_h: string) => `${x}${_h}`))
    .flat() as Array<string>;

  return (
    <ContentBlock>
      <ContentRow>
        <SubTitle>Local Block Cache Summary</SubTitle>
      </ContentRow>
      {summary ? (
        <>
          <div>
            <div style={{ float: "left", width: "25%" }}>
              <ContentRow>
                <Label>Addresses:</Label> {summary.length}
              </ContentRow>
            </div>
            <div style={{ float: "left", width: "25%" }}>
              <ContentRow>
                <Label>Space (MB):</Label>{" "}
                {(summary.M.total_bytes / (1024 * 1024)).toFixed(2)} MB
              </ContentRow>
            </div>
            <div style={{ float: "left", width: "24%" }}>
              <ContentRow>
                <Label>Avg size (KB):</Label>{" "}
                {(summary.M.total_bytes / (summary.length * 1024)).toFixed(1)}{" "}
                KB
              </ContentRow>
            </div>
            <div style={{ float: "right", width: "24%" }}>
              <ContentRow>
                <Label>Seek speed:</Label> {summary.M.speed.toFixed(0)}{" "}
                items/sec
              </ContentRow>
            </div>
            <div style={{ clear: "both" }}></div>
          </div>
          <br />
          <div>
            <div style={{ float: "left", width: "25%" }}>
              <ContentRow>
                <Label>Random (sync):</Label> {summary.randomTen.length}
              </ContentRow>
            </div>
            <div style={{ float: "left", width: "25%" }}>
              <ContentRow>
                <Label>Space (MB):</Label>{" "}
                {(summary.M.total_bytes_sync / (1024 * 1024)).toFixed(2)} MB
              </ContentRow>
            </div>
            <div style={{ float: "left", width: "24%" }}>
              <ContentRow>
                <Label>First Block:</Label>{" "}
                {(
                  summary.M.total_bytes_sync /
                  (summary.randomTen.length * 1024)
                ).toFixed(1)}{" "}
                KB
              </ContentRow>
            </div>
            <div style={{ float: "right", width: "24%" }}>
              <ContentRow>
                <Label>Sync read speed:</Label>{" "}
                {summary.M.speed_sync.toFixed(2)} items/sec
              </ContentRow>
            </div>
            <div style={{ clear: "both" }}></div>
          </div>
          <br />
          <div>
            <div style={{ float: "left", width: "25%" }}>
              <ContentRow>
                <Label>First block:</Label>{" "}
                {parseInt(minStoredBlockHex || "0", 16)} ({minStoredBlockHex})
              </ContentRow>
            </div>
            <div style={{ float: "left", width: "25%" }}>
              <ContentRow>
                <Label>Last stored block:</Label>{" "}
                {parseInt(maxStoredBlockHex || "0", 16)} ({maxStoredBlockHex})
              </ContentRow>
            </div>
            <div style={{ float: "left", width: "24%" }}>
              <ContentRow>
                <Label>Last known block:</Label> {blockNumber} ({blockHexMemo})
              </ContentRow>
            </div>
            <div style={{ float: "right", width: "24%" }}>
              <ContentRow>
                <Label>Time shift:</Label> {timeShiftMemo}
              </ContentRow>
            </div>
            <div style={{ clear: "both" }}></div>
          </div>
        </>
      ) : (
        <>Loading summary...</>
      )}
      <ContentRow></ContentRow>
    </ContentBlock>
  );
};

import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

function valuetext(value: number) {
  return `${value}°C`;
}

export default function RangeSlider(props: {
  value: Array<number>;
  setValue: any;
}) {
  const { value, setValue } = props;
  //const [value, setValue] = React.useState([20, 37]);
  // const [value, setValue] = React.useState<Array<number>>([0, 0.2]);

  const handleChange = (
    event: any
    //newValue: number[]
    // activeThumb: number
  ) => {
    const newValue = event.target.value;

    console.log("Range:", newValue);
    setValue(newValue);
  };

  function log10(x: number) {
    return Math.log(x) / Math.log(10);
  }

  function pow10(x: number) {
    return Math.pow(10, x);
  }

  function valueLabelFormat(x: any) {
    const [coefficient, exponent] = x
      .toExponential()
      .split("e")
      .map((item: string) => Number(item));
    //
    return `${(coefficient * pow10(exponent)).toFixed(2)}`;
    // return `${Math.round(coefficient)}e^${exponent}`;
  }

  return (
    <Box sx={{ width: 250 }}>
      <Slider
        min={0}
        step={0.1}
        max={10.5} // max 20 eth
        // max={3}
        scale={(x) => pow10(x) - 1}
        getAriaLabel={() => "Owner balance"}
        valueLabelFormat={valueLabelFormat}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
      />
    </Box>
  );
}

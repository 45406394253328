import {
  NIL as NIL_UUID,
  parse as uuidParse,
  stringify as uuidStringify,
  v1 as uuidv1,
  v3 as uuidv3,
  v4 as uuidv4,
  v5 as uuidv5,
  validate as uuidValidate,
  version as uuidVersion,
} from "uuid";
import * as uuid from "uuid";

const strs: Record<string, any> = [];
//  import testpage from '../utils/testpage';
const addTest = (label: string, result?: unknown): void => {
  // strs.push(`${label}${result}`)
  strs[label] = result;
};

const useUUID = (account?: string) => {
  //   const [windowSize, setWindowSize] = useState<Size>({
  //     width: window.innerWidth,
  //     height: window.innerHeight,
  //   });
  const UUID_DNS_NAMESPACE: string = "fractalgasm.art"; // "hello.example.com"
  const UUID_URL_NAMESPACE: string = "https://fractalgasm.art/"; // http://example.com/hello
  const UUID_MY_NAMESPACE: string = "55238d15-c926-4598-b49d-cf4e913ba13c"; //TODO: generate valid own
  // const MY_NAMESPACE = "55238d15-c926-4598-b49d-cf4e913ba13c";
  const input: string = "Hello, World!";

  // Named exports

  addTest("uuidv1()", uuidv1());
  addTest("uuidv4()", uuidv4());

  //
  // Note: Custom namespaces should be a UUID string specific to your application!
  // E.g. the one here was generated using this modules `uuid` CLI.
  addTest("uuidv3() DNS", uuidv3(UUID_DNS_NAMESPACE, uuidv3.DNS)); // ... using predefined DNS namespace (for domain names)F
  addTest("uuidv3() URL", uuidv3(UUID_URL_NAMESPACE, uuidv3.URL)); // ... using predefined URL namespace (for, well, URLs)
  addTest("uuidv3() NAMESPACE", uuidv3(input, UUID_MY_NAMESPACE)); // ... using a custom namespaceF

  //
  // Note: Custom namespaces should be a UUID string specific to your application!
  // E.g. the one here was generated using this modules `uuid` CLI.
  addTest("uuidv5() DNS", uuidv5(UUID_DNS_NAMESPACE, uuidv5.DNS)); // ... using predefined DNS namespace (for domain names)
  addTest("uuidv5() URL", uuidv5(UUID_URL_NAMESPACE, uuidv5.URL)); // ... using predefined URL namespace (for, well, URLs)
  addTest("uuidv5() NAMESPACE", uuidv5(input, UUID_MY_NAMESPACE)); // ... using a custom namespace

  // Utility functions

  addTest("NIL_UUID", NIL_UUID);
  addTest("uuidParse()", uuidParse(UUID_MY_NAMESPACE));
  addTest("uuidStringify()", uuidStringify(uuidParse(UUID_MY_NAMESPACE)));
  addTest("uuidValidate()", uuidValidate(UUID_MY_NAMESPACE));
  addTest("uuidVersion()", uuidVersion(UUID_MY_NAMESPACE));

  // Default export

  addTest("uuid.v1()", uuid.v1());
  addTest("uuid.v4()", uuid.v4());
  addTest("uuid.v3() DNS", uuid.v3(UUID_DNS_NAMESPACE, uuid.v3.DNS));
  addTest("uuid.v3() URL", uuid.v3(UUID_URL_NAMESPACE, uuid.v3.URL));
  addTest("uuid.v3() NAMESPACE", uuid.v3(input, UUID_MY_NAMESPACE));
  addTest("uuid.v5() DNS", uuid.v5(UUID_DNS_NAMESPACE, uuid.v5.DNS));
  addTest("uuid.v5() URL", uuid.v5(UUID_URL_NAMESPACE, uuid.v5.URL));
  addTest("uuid.v5() NAMESPACE", uuid.v5(input, UUID_MY_NAMESPACE));

  addTest("uuid.NIL", uuid.NIL);
  addTest("uuid.parse()", uuid.parse(UUID_MY_NAMESPACE));
  addTest("uuid.stringify()", uuid.stringify(uuid.parse(UUID_MY_NAMESPACE)));
  addTest("uuid.validate()", uuid.validate(UUID_MY_NAMESPACE));
  addTest("uuid.version()", uuid.version(UUID_MY_NAMESPACE));

  // input:0x6eDE5004a408011C76605F9bf3FB289D7E398F74
  const account_colors = (
    account: string,
    repeated_idx: Array<number> = [3, 5, 8, 13, 17, 21, 27, 35] // seed 40 + 8 = 8x6
  ) => {
    const input = account.replace("0x", "").toLowerCase().trim();
    const digits = input.split(""); // 40 digits
    const repeated_digits = digits.filter((v, i) => repeated_idx.includes(i));
    digits.push(...repeated_digits);
    const s = digits.join("");
    //
    const hexas = [] as Array<string>; // '123abc'
    for (let i = 0; i < digits.length; i += 6) hexas.push(s.substr(i, 6));
    const colors = hexas.map((h) => `#${h}`);
    //
    return colors;
  };

  const guid_to_hex = (
    guid: string,
    repeated_idx: Array<number> = [3, 6, 13, 19] // seed
  ) => {
    // '9173321d-dd08-4b8b-ab84-971ca19436b7'
    const digits = guid.split("-").join("").split(""); // 32 digits + 4 = 6x6
    const repeated_digits = digits.filter((v, i) => repeated_idx.includes(i));
    digits.push(...repeated_digits);
    const s = digits.join("");

    // to hexa
    const hexas = [] as Array<string>; // '123abc'
    for (let i = 0; i < digits.length; i += 6) hexas.push(s.substr(i, 6));
    const colors = hexas.map((h) => `#${h}`); // const ints = hexas.map((hex: string): number => parseInt(hex, 16));

    return colors;
  };

  const random10 = new Array<any>();
  for (let i = 0; i < 10; i++) {
    const guid = uuidv4();
    random10.push([guid, guid_to_hex(guid)]);
  }
  strs["RND10"] = random10;
  strs["ACC"] = account_colors(
    account ? account : `0x${new Array(40).fill("0").join("")}`
  );
  // strs["FINGERPRINT"] = ;

  return strs;
};

export default useUUID;

//   export default function useUUID(addTest) {
//

//   });

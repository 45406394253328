import { MethodEnvelope, HandledMethodId } from "../../../method-helper";

const methodIds = ["0x095ea7b3", "0xa9059cbb"];
const methodEnvelope = {
  name: "approve",
  signature: "approve(address,uint256)",
  input_types: ["address", "uint256"],
  output_aliases: ["spender", "amount"],
};

export default {
  ids: methodIds as Array<HandledMethodId>,
  meta: methodEnvelope as MethodEnvelope,
};

import { connect } from "react-redux";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { setCurrentUser, setEnteredSite } from "./redux/user/user.actions";

import {
  SkinnedControlSideBar,
  SkinnedFooter,
  SkinnedHeader,
  SkinnedLeftSlider,
  SkinnedContentHeader,
} from "./ApplicationSkin";
import { TokensList } from "./components/TokensList/TokensList";
// import DashBoard from "./DashBoard";

/*
  import { Page } from "./components/base";
  import { TopBar } from "./components/TopBar";
  import { GlobalStyle } from "./global/GlobalStyle";
  */
import { Balance } from "./pages/Balance";
import { Block } from "./pages/users/observe/Block";
import { Blocks } from "./pages/Blocks";
// import { Tokens } from "./pages/Tokens";
import { ViewOne } from "./pages/ViewOne";
import { Upload } from "./pages/Upload";
import { NewContracts } from "./pages/NewContracts";

//
//
//
//
import EmptyComponent from "./pages/users/EmptyComponent";
import NewPairs from "./pages/users/discover/NewPairs";
import { useEffect, useMemo } from "react";
import OnMyWatchList from "./pages/users/discover/OnMyWatchList";
import MemPoolLive from "./pages/users/discover/MemPoolLive";
import CryptoCalendar from "./pages/users/discover/CryptoCalendar";
import CompareContracts from "./pages/users/analyze/CompareContracts";
import CherryPicking from "./pages/users/observe/CherryPicking";
import WhaleRanking from "./pages/users/observe/WhaleRanking";
import DeployersRepeating from "./pages/users/observe/DeployersRepeating";
import AgeDistribution from "./pages/users/observe/AgeDistribution";
import NextFeaturePoll from "./pages/users/contribute/NextFeaturePoll";
import RequestFeature from "./pages/users/contribute/RequestFeature";
import ReportScamContract from "./pages/users/contribute/ReportScamContract";
import ReportScammer from "./pages/users/contribute/ReportScammer";
import TermsOfService from "./pages/about_us/TermsOfService";
import RoadMap from "./pages/about_us/RoadMap";
import FrequentlyAsked from "./pages/about_us/FrequentlyAsked";
import KycApproved from "./pages/users/launchpad/KycApproved";
import KycHosted from "./pages/users/launchpad/KycHosted";
import { InMyMind } from "./pages/manage/InMyMind";
//
//

import { BlockManagement } from "./pages/manage/BlockManagement";
import { Button } from "@material-ui/core";
import LandingPage from "./LandingPage";
import useWindowSize from "./hooks/useWindowSize";
import KycAddressLocker from "./pages/users/launchpad/KycAddressLocker";
/*
  import TopNavBar from "./components/navigation/TopNavBar";
  import { CssBaseline } from "@material-ui/core";
  import { NewContracts } from "./pages/NewContracts";
  import { FirstBlocks } from "./pages/FirstBlocks";
  import { InMyMind } from "./pages/manage/InMyMind";
  */

const pageNames = {
  empty: "Dashboard",
  //
  new: "New Contracts",
  new_pairs: "New Dex Pairs",
  on_my_watchlist: "My Watchlist",
  crypto_calendar: "Crypto Calendar",
  //
  compare_contracts: "Compare Contracts",
  mempool_live: "Mempool Live",
  //
  block: "Last Block",
  whale_ranking: "Whale Ranking",
  cherry_picking: "Cherry Picking",
  age_distribution: "Age Distribution",
  deployers_repeating: "Repeating Deployers",
  //
  poll_next_request: "Next Feature Poll",
  request_feature: "Request a Feature",
  report_scam: "Report a Scam",
  report_scammer: "Report a Scammer",
  //
  kyc_approved: "Contract Address Unlocker",
  kyc_hosted: "Announce a Contract",
  //
  roadmap: "Our Roadmap",
  frequently_asked: "Frequently Asked Questions",
  terms_of_service: "Terms of Service",
  locker: "Address Locker",
  //
  //
  tokens_list: "Tokens List",
  balance: "Test #1 (Ba)",
  upload: "Contract Inspection", // !!!
} as Record<string, string>;

function usePageViews() {
  let location = useLocation();
  useEffect(() => {
    console.log("LOCATION", location);
    console.log(' ga.send(["pageview", location.pathname]); NOT CALLED');
  }, [location]);
}

const RoutedApp = ({
  enteredSite,
  setCurrentUser,
  setEnteredSite,
  isLeftSideToggled,
}: {
  enteredSite: boolean;
  setCurrentUser: any;
  setEnteredSite: any;
  isLeftSideToggled: boolean;
}) => {
  usePageViews();
  //
  const handle = useFullScreenHandle();
  let location = useLocation();
  const { screenSizeName } = useWindowSize();
  const current_path = location.pathname?.substring(1) || "";
  const current_pagename = pageNames[current_path];
  //
  const hasLeftSide = useMemo(() => {
    return !(screenSizeName === "mini" || screenSizeName === "small");
  }, [screenSizeName]);
  //
  console.log("CP", current_pagename);
  //
  return enteredSite ? (
    <FullScreen handle={handle}>
      <SkinnedHeader pageName={current_pagename} handle={handle} />
      {/* <!-- Left side column. contains the logo and sidebar --> */}
      <SkinnedLeftSlider pageName={current_pagename} />
      {/* <!-- Content Wrapper. Contains page content --> */}
      <div
        className="content-wrapper"
        style={{
          marginLeft: !current_pagename
            ? "0px"
            : hasLeftSide
            ? !isLeftSideToggled
              ? "230px"
              : "60px"
            : "0px",
        }}
      >
        {/* <!-- Main content --> */}
        {/* <TopNavBar /> */}
        <SkinnedContentHeader pageName={current_pagename} />
        <Routes>
          <Route path="/empty" element={<EmptyComponent />} />
          {/*   */}
          <Route path="/new" element={<NewContracts />} />
          <Route path="/new_pairs" element={<NewPairs />} />
          <Route path="/on_my_watchlist" element={<OnMyWatchList />} />
          <Route path="/mempool_live" element={<MemPoolLive />} />
          <Route path="/crypto_calendar" element={<CryptoCalendar />} />
          {/*   */}
          <Route path="/upload" element={<Upload />} />
          <Route path="/upload?address=:address" element={<Upload />} />
          <Route path="/compare_contracts" element={<CompareContracts />} />
          {/*   */}
          <Route path="/whale_ranking" element={<WhaleRanking />} />
          <Route path="/cherry_picking" element={<CherryPicking />} />
          <Route path="/age_distribution" element={<AgeDistribution />} />
          <Route path="/deployers_repeating" element={<DeployersRepeating />} />
          {/*   */}
          {/* <Route path="/kyc_approved" element={<KycApproved />} /> */}
          <Route path="/locker" element={<KycAddressLocker />} />
          <Route path="/locker?key=:hash" element={<KycAddressLocker />} />
          <Route path="/kyc_hosted" element={<KycHosted />} />
          {/*   */}
          <Route path="/poll_next_request" element={<NextFeaturePoll />} />
          <Route path="/request_feature" element={<RequestFeature />} />
          <Route path="/report_scam" element={<ReportScamContract />} />
          <Route path="/report_scammer" element={<ReportScammer />} />
          {/*   */}
          <Route path="/roadmap" element={<RoadMap />} />
          <Route path="/frequently_asked" element={<FrequentlyAsked />} />
          <Route path="/terms_of_service" element={<TermsOfService />} />
          {/*   */}

          <Route path="/tokens_list" element={<TokensList />} />
          <Route path="/balance" element={<ViewOne />} />
          <Route path="/block" element={<Block />} />
          {/*  */}
          {/* <Route path="/" element={<Navigate replace to="/empty" />} /> */}
          <Route path="/" element={<LandingPage />} />

          <Route path="/in_my_mind" element={<InMyMind />} />
          <Route path="/blocks" element={<BlockManagement />} />
          {/* <Route path="/view_one" element={<Balance />} /> */}
          <Route path="/view_one" element={<ViewOne />} />
          <Route path="/blocks_test" element={<Blocks />} />
          {/* 
              <Route path="/first_blocks" element={<FirstBlocks />} />
  
              <Route path="/tokens" element={<Tokens />} />
            */}
        </Routes>
        {/* <DashBoard /> */}
        {/* <EmptyComponent /> */}
        {/* <!-- /.content --> */}
      </div>
      {/* <!-- /.content-wrapper --> */}

      {/* <!-- Control Sidebar --> */}
      <SkinnedControlSideBar pageName={current_pagename} />

      {/* <!-- /.control-sidebar --> */}
      <SkinnedFooter pageName={current_pagename} />
      {/* <!-- Add the sidebar's background. This div must be placed immediately after the control sidebar --> */}
      <div className="control-sidebar-bg"></div>
    </FullScreen>
  ) : (
    <LandingPage />
  );
};

const mapStateToProps = ({
  user,
  ui,
}: {
  ui: { isLeftSideToggled: boolean };
  user: {
    enteredSite: boolean;
    currentUser: {
      chain: string;
      account: string;
      //
      is_connected: boolean;
      is_registered: boolean;
      //
      lang?: string;
      footprint?: string;
      //
      identity?: string;
      proof?: string;
    };
  };
}) => ({
  currentUser: user.currentUser,
  enteredSite: user.enteredSite,
  isLeftSideToggled: ui.isLeftSideToggled,
});

const mapDispatchToProps = (dispatch: any) => ({
  setCurrentUser: (user: any) => dispatch(setCurrentUser(user)),
  setEnteredSite: () => dispatch(setEnteredSite()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoutedApp);

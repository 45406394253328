import * as React from "react";
import { useMemo } from "react";

import { ethers, BigNumber } from "ethers";

import Box from "@mui/material/Box";
import { useContractCalls } from "@usedapp/core";

import UniswapV2FactoryABI from "../../contracts/UniswapV2Factory.abi.json"; //
import UniswapV2PairABI from "../../contracts/UniswapV2Pair.abi.json"; //

import { _as_smart_stripe } from "../SimpleControls";
import { bi_to_formatted, shorten } from "../../utils/numbers";
const factoryInterface = new ethers.utils.Interface(UniswapV2FactoryABI);
const pairInterface = new ethers.utils.Interface(UniswapV2PairABI);

type DexPairListItemData = {
  is_valid: boolean;
  is_lft: boolean;
  is_eth: boolean;
  is_dai: boolean;
  is_usdc: boolean;
  is_usdt: boolean;
  is_sushi: boolean;
  is_btc: boolean;
  pair: string;
  total: any;
  reserve: any;
  left: string;
  right: string;
  leftDetails: any;
  rightDetails: any;
};

const basePairIconMapping: Record<string, string> = {
  "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2": "ETH",
  "0x6B175474E89094C44Da98b954EedeAC495271d0F": "DAI",
  "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48": "USDC",
  "0xdAC17F958D2ee523a2206206994597C13D831ec7": "USDT",
  "0x6B3595068778DD592e39A122f4f5a5cF09C90fE2": "SUSHI",
  "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599": "WBTC",
};
//
//
//
export default function DexPairListComponent(props: {
  factory: string;
  range: Array<number>;
  basePrices: Record<string, number>;
}) {
  const { factory, range, basePrices } = props;
  //const [value, setValue] = React.useState([20, 37]);
  // const [value, setValue] = React.useState<Array<number>>([0, 0.2]);
  //

  //
  // transformations:
  // address_to_price
  // address_to_icon
  //
  const _ca_to_iconname = (base_leg_address: string) =>
    basePairIconMapping[base_leg_address] || "";

  const _ca_to_price = (base_leg_address: string) =>
    basePrices ? basePrices[base_leg_address] : 0;

  const useFactoryPairReservesAtIndices = (
    addresses: Array<string>
  ): Array<any> => {
    // const indices = Array.from(new Array(length).keys());

    console.log("useFactoryPairReservesAtIndices", addresses);
    const factoryPairReserves = useContractCalls(
      addresses
        ? addresses.map((pair_address) =>
            pair_address
              ? {
                  abi: pairInterface,
                  address: pair_address,
                  method: "getReserves",
                  args: [],
                }
              : null
          )
        : []
    )
      // .flat();
      .map((arr) =>
        arr !== null && arr !== undefined
          ? {
              r0: arr[0],
              r1: arr[1],
              ts: new Date(arr[2] * 1000),
            }
          : null
      );
    //
    console.log("factoryPairReservessss", factoryPairReserves);

    return factoryPairReserves as Array<any>;
  };

  const useFactoryPairsAtIndices = (indices: Array<number>): Array<string> => {
    // const indices = Array.from(new Array(length).keys());

    console.log(indices);
    const factoryPairs = useContractCalls(
      indices
        ? indices.map((index) =>
            index
              ? {
                  abi: factoryInterface,
                  address: factory,
                  method: "allPairs",
                  args: [index],
                }
              : null
          )
        : []
    )
      .flat()
      .map((bn) => bn?.toString() || null);

    return factoryPairs as Array<string>;
  };
  //
  const useFactoryTokenLegsAtIndices = (
    addresses: Array<string> | null,
    isLeft: boolean
  ): Array<string> => {
    const factoryPairs = useContractCalls(
      addresses
        ? addresses
            .filter((a) => a !== null)
            .map((pair_address) => ({
              abi: pairInterface,
              address: pair_address,
              method: isLeft ? "token0" : "token1",
              args: [],
            }))
        : []
    )
      .flat()
      .map((bn) => bn?.toString() || null);
    //
    return factoryPairs as Array<string>;
  };
  //
  const factoryPairsAtIndices = useFactoryPairsAtIndices(range);
  //
  // const leftsAtIndices = [] as Array<string>;
  const leftsAtIndices = useFactoryTokenLegsAtIndices(
    factoryPairsAtIndices,
    true
  );
  const rightsAtIndices = useFactoryTokenLegsAtIndices(
    factoryPairsAtIndices,
    false
  );
  //
  const uniqueMemo = useMemo(() => {
    const all = [...leftsAtIndices, ...rightsAtIndices];

    const un = Array.from(new Set(all));
    //
    return (un.filter((u) => u !== null) || []) as Array<string>;
  }, [leftsAtIndices, rightsAtIndices]);
  //
  //
  //
  const usePairTotalSuppliesAtIndices = (
    addresses: Array<string> | null
  ): Array<BigNumber> => {
    const pairTotalSupplies = useContractCalls(
      addresses
        ? addresses
            .filter((a) => a !== null)
            .map((pair_address) => ({
              abi: pairInterface,
              address: pair_address,
              method: "totalSupply",
              args: [],
            }))
        : []
    ).flat();
    //  .map((bn) => bn?.toString() || null);
    //
    return pairTotalSupplies as Array<BigNumber>;
  };
  //
  //
  //
  //
  const useTokenDecimalsAtIndices = (
    addresses: Array<string> | null
  ): Array<number> => {
    const decimalInterface = new ethers.utils.Interface([
      {
        inputs: [],
        name: "decimals",
        outputs: [
          {
            internalType: "uint8",
            name: "",
            type: "uint8",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ]);

    const tokenDecimals = useContractCalls(
      addresses
        ? addresses
            .filter((a) => a !== null)
            .map((leg_address) => ({
              abi: decimalInterface,
              address: leg_address,
              method: "decimals",
              args: [],
            }))
        : []
    )
      .flat()
      .map((bn) => bn?.toString() || "18");
    //
    return tokenDecimals as Array<number>;
  };
  //
  const useTokenSymbolsAtIndices = (
    addresses: Array<string> | null
  ): Array<string> => {
    const decimalInterface = new ethers.utils.Interface([
      {
        inputs: [],
        name: "symbol",
        outputs: [
          {
            internalType: "string",
            name: "",
            type: "string",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ]);

    const tokenDecimals = useContractCalls(
      addresses
        ? addresses
            .filter((a) => a !== null)
            .map((leg_address) => ({
              abi: decimalInterface,
              address: leg_address,
              method: "symbol",
              args: [],
            }))
        : []
    )
      .flat()
      .map((bn) => bn?.toString() || null);
    //
    return tokenDecimals as Array<string>;
  };

  //
  const decimals = useTokenDecimalsAtIndices(uniqueMemo);
  const symbols = useTokenSymbolsAtIndices(uniqueMemo);
  const totalsupplies = usePairTotalSuppliesAtIndices(uniqueMemo);

  const uniqueDetailMemo = useMemo(() => {
    const grp = symbols.map((s, idx) => ({
      symbol: s,
      address: uniqueMemo[idx],
      decimal: decimals[idx],
      total: totalsupplies[idx],
    }));
    //
    return grp as Array<{ symbol: string; decimal: number; address: string }>;
  }, [symbols, decimals, totalsupplies]);
  //
  //
  //
  const factoryPairTotalsAtIndices = usePairTotalSuppliesAtIndices(
    factoryPairsAtIndices
  );

  const factoryPairReservesAtIndices = useFactoryPairReservesAtIndices(
    factoryPairsAtIndices
  );
  const finalMemo = useMemo(() => {
    const crbs = factoryPairsAtIndices.map((address, idx) => ({
      pair: address,
      reserve: factoryPairReservesAtIndices[idx],
      total: factoryPairTotalsAtIndices[idx],
      left: leftsAtIndices[idx],
      right: rightsAtIndices[idx],
    }));
    //
    const crb_mapped = crbs.map((crb, idx) => ({
      ...crb,
      leftDetails: uniqueDetailMemo.find((un) => un.address === crb.left),
      rightDetails: uniqueDetailMemo.find((un) => un.address === crb.right),
    }));
    //
    // find dominant leg and is it eth ?
    //
    // weth: 0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2
    // dai: 0x6B175474E89094C44Da98b954EedeAC495271d0F
    // usdc: 0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48
    // usdt: 0xdAC17F958D2ee523a2206206994597C13D831ec7
    // sushi: 0x6B3595068778DD592e39A122f4f5a5cF09C90fE2
    // WBTC: 0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599
    //
    const crb_mapped_w_legdetect = crb_mapped.map((crbm, idx) => {
      const is_lft_eth =
        crbm.left === "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2";
      const is_rgt_eth =
        crbm.right === "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2";
      const is_lft_dai =
        crbm.left === "0x6B175474E89094C44Da98b954EedeAC495271d0F";
      const is_rgt_dai =
        crbm.right === "0x6B175474E89094C44Da98b954EedeAC495271d0F";
      const is_lft_usdc =
        crbm.left === "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48";
      const is_rgt_usdc =
        crbm.right === "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48";
      const is_lft_usdt =
        crbm.left === "0xdAC17F958D2ee523a2206206994597C13D831ec7";
      const is_rgt_usdt =
        crbm.right === "0xdAC17F958D2ee523a2206206994597C13D831ec7";
      //
      const is_lft_sushi =
        crbm.left === "0x6B3595068778DD592e39A122f4f5a5cF09C90fE2";
      const is_rgt_sushi =
        crbm.right === "0x6B3595068778DD592e39A122f4f5a5cF09C90fE2";
      const is_lft_btc =
        crbm.left === "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599";
      const is_rgt_btc =
        crbm.right === "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599";

      //
      //
      const is_lft =
        is_lft_eth ||
        is_lft_dai ||
        is_lft_usdc ||
        is_lft_usdt ||
        is_lft_sushi ||
        is_lft_btc;
      //
      const is_eth = is_lft_eth || is_rgt_eth;
      const is_dai = is_lft_dai || is_rgt_dai;
      const is_usdc = is_lft_usdc || is_rgt_usdc;
      const is_usdt = is_lft_usdt || is_rgt_usdt;
      const is_sushi = is_lft_sushi || is_rgt_sushi;
      const is_btc = is_lft_btc || is_rgt_btc;
      //
      const is_invalid = !(
        is_lft_eth ||
        is_rgt_eth ||
        is_lft_dai ||
        is_rgt_dai ||
        is_lft_usdc ||
        is_rgt_usdc ||
        is_lft_usdt ||
        is_rgt_usdt ||
        is_lft_btc ||
        is_rgt_btc ||
        is_lft_sushi ||
        is_rgt_sushi
      );
      //
      return {
        ...crbm,
        is_valid: !is_invalid,
        is_lft,
        is_eth,
        //
        is_dai,
        is_usdc,
        is_usdt,
        is_sushi,
        is_btc,
      };
    });
    // const grp = symbols.map((s, idx) => ({
    //   symbol: s,
    //   decimal: decimals[idx],
    //   address: uniqueMemo[idx],
    // }));
    //
    return crb_mapped_w_legdetect as Array<DexPairListItemData>;
  }, [
    factoryPairsAtIndices,
    factoryPairReservesAtIndices,
    factoryPairTotalsAtIndices,
    leftsAtIndices,
    rightsAtIndices,
    uniqueDetailMemo,
  ]);
  //
  //
  //

  //
  //
  //
  const getMainLegIcon = (o: DexPairListItemData) => {
    if (o) {
      const mode = o.is_eth
        ? "ETH"
        : o.is_dai
        ? "DAI"
        : o.is_usdc
        ? "USDC"
        : o.is_usdt
        ? "USDT"
        : o.is_sushi
        ? "SUSHI"
        : o.is_btc
        ? "WBTC"
        : `|${o?.leftDetails?.symbol}|${o?.rightDetails?.symbol}|`;
      //
      return (
        <i
          className={`cc ${mode} font-size-26 m-0`}
          title={`${mode}`}
          style={{ position: "relative", top: "-13px", padding: "none" }}
        ></i>
      );
    }
  };
  //
  //
  const to_unit = (n: number) => {
    if (n > 1000000000000000000000000) {
      return `${(n * 0.000000000000000000000001)
        .toFixed(3)
        .replace(".000", "")
        .replace(".00", "")} Sp`;
    } else if (n > 1000000000000000000000) {
      return `${(n * 0.000000000000000000001)
        .toFixed(3)
        .replace(".000", "")
        .replace(".00", "")} Sx`;
    } else if (n > 1000000000000000000) {
      return `${(n * 0.000000000000000001)
        .toFixed(3)
        .replace(".000", "")
        .replace(".00", "")} Qi`;
    } else if (n > 1000000000000000) {
      return `${(n * 0.000000000000001)
        .toFixed(3)
        .replace(".000", "")
        .replace(".00", "")} Qa`;
    } else if (n > 1000000000000) {
      return `${(n * 0.000000000001)
        .toFixed(3)
        .replace(".000", "")
        .replace(".00", "")} T`;
    } else if (n > 1000000000) {
      return `${(n * 0.000000001)
        .toFixed(3)
        .replace(".000", "")
        .replace(".00", "")} B`;
    } else if (n > 1000000) {
      return `${(n * 0.000001)
        .toFixed(3)
        .replace(".000", "")
        .replace(".00", "")} M`;
    } else if (n > 1000) {
      return `${(n * 0.001)
        .toFixed(3)
        .replace(".000", "")
        .replace(".00", "")} K`;
    }
    //
    return `${n.toFixed(2)}`;
  };
  //
  const to_usd = (n: number) => {
    if (n > 1000000000) {
      return `$ ${(n * 0.000000001).toFixed(2)}B`;
    } else if (n > 1000000) {
      return `$ ${(n * 0.000001).toFixed(2)}M`;
    } else if (n > 1000) {
      return `$ ${(n * 0.001).toFixed(2)}K`;
    }
    //
    return `$ ${n.toFixed(3)}`;
  };
  //

  const _2h = (bn: BigNumber): string => (bn ? bn.toHexString() : "0x0");
  const _2bi = (bn: BigNumber): BigInt =>
    bn ? BigInt(bn.toString()) : BigInt(0);
  const _2n = (s: string): number => parseInt(s);
  //
  const _as_progress_bar = (ratio: number) => {
    const _prc = ratio * 100.0;
    const int_prc = Math.floor(ratio * 100);
    //
    const less_then_fifty = int_prc < 50;
    //
    return (
      <div className="progress">
        <div
          className="progress-bar progress-bar-green progress-bar-striped"
          role="progressbar"
          style={{
            width: `${int_prc}%`,
            float: less_then_fifty ? "left" : "none",
          }}
        >
          {!less_then_fifty ? (
            <span style={{ filter: "drop-shadow(0px 2px 2px black)" }}>
              {int_prc}%
            </span>
          ) : (
            ""
          )}
        </div>
        {less_then_fifty ? (
          <span
            style={{
              filter: "drop-shadow(0px -2px -2px white)",
              position: "relative",
              top: "7px",
              left: "2px",
              float: "right",
            }}
          >
            {_prc.toFixed(3)}%
          </span>
        ) : (
          ""
        )}
      </div>
    );
  };
  //
  //
  //
  const render_row = (o: DexPairListItemData) => {
    const {
      is_valid,
      is_lft,
      is_eth,
      is_dai,
      is_usdc,
      is_usdt,
      is_sushi,
      is_btc,
      pair,
      total,
      reserve,
      left,
      right,
      leftDetails,
      rightDetails,
    } = o;
    //
    const _pair = pair ? pair.toLowerCase() : "";
    const _left = left ? left.toLowerCase() : "";
    const _right = right ? right.toLowerCase() : "";
    //
    const is_left_leg_eth = is_lft && is_eth;
    //
    //
    const left_decimal = leftDetails ? (leftDetails.decimal as number) : 18;
    const right_decimal = rightDetails ? (rightDetails.decimal as number) : 18;
    //
    const hex_pool_a =
      reserve && reserve.r0 ? (reserve.r0 as BigNumber) : BigNumber.from(0);
    const hex_pool_b =
      reserve && reserve.r1 ? (reserve.r1 as BigNumber) : BigNumber.from(0);
    //
    const hex_totaltotal_a =
      leftDetails && leftDetails.total
        ? (leftDetails.total as BigNumber)
        : BigNumber.from(0);
    const hex_totaltotal_b =
      rightDetails && rightDetails.total
        ? (rightDetails.total as BigNumber)
        : BigNumber.from(0);

    //
    let hex_total_a = 0;
    let hex_total_b = 0;
    //
    try {
      hex_total_a =
        leftDetails && leftDetails.total && leftDetails.decimal
          ? _2n(_2h(leftDetails.total)) * Math.pow(10, 0 - left_decimal)
          : 0;
      hex_total_b =
        rightDetails && rightDetails.total && rightDetails.decimal
          ? _2n(_2h(rightDetails.total)) * Math.pow(10, 0 - right_decimal)
          : 0;
    } catch (ex) {
      // overflow
    }
    //
    const pair_reserve = { r0: 10, r1: 20 };
    const one_eth = is_left_leg_eth
      ? parseFloat(String(pair_reserve.r1)) /
        parseFloat(String(pair_reserve.r0))
      : parseFloat(String(pair_reserve.r0)) /
        parseFloat(String(pair_reserve.r1));
    const one_inu = 1 / one_eth;
    const symbol_0 = leftDetails?.symbol || ""; //"WBTC";
    const symbol_1 = rightDetails?.symbol || "";
    const thePriceOfEth = "2000";
    //
    const pool_liquidity_eth_value_in_usd =
      pair_reserve.r0 * parseFloat(thePriceOfEth);
    let pool_liquidity_total_value_in_usd = pool_liquidity_eth_value_in_usd * 2; // ;-)
    //
    const pool_created =
      reserve && reserve.ts ? reserve.ts.toISOString().split("T")[0] : "...";
    //
    const pow10 = (n: number) => {
      const i = Math.pow(10, n);
      const s = String(i);
      const r = ethers.BigNumber.from(s);
      //
      console.log("EBN", i, s, r);
      return r;
    };
    //

    const get_base_price = (o: DexPairListItemData) => {
      const ba = o.is_lft ? o.left : o.right;
      const pba = basePrices[ba];

      //basePrices()
      return pba || 0;
    };
    //

    const POOLED_A: number =
      _2n(_2h(hex_pool_a)) * Math.pow(10, 0 - left_decimal);
    const POOLED_B: number =
      _2n(_2h(hex_pool_b)) * Math.pow(10, 0 - right_decimal);
    //
    const TOTAL_A: number = parseFloat(
      (_2n(_2h(hex_totaltotal_a)) * Math.pow(10, 0 - left_decimal)).toFixed(3)
    );
    const TOTAL_B: number = parseFloat(
      (_2n(_2h(hex_totaltotal_b)) * Math.pow(10, 0 - right_decimal)).toFixed(3)
    );
    //
    //
    const POOL_PERC_OF_TOTAL_0 = TOTAL_A > 0 ? POOLED_A / TOTAL_A : 0;
    const POOL_PERC_OF_TOTAL_1 = TOTAL_B > 0 ? POOLED_B / TOTAL_B : 0;
    //

    //
    const PRICE_OF_BASE = get_base_price(o);
    const BASE_P = o.is_lft ? POOLED_A : POOLED_B;
    const LEG_P = o.is_lft ? POOLED_B : POOLED_A;
    //
    const USD_OF_BASE = BASE_P * PRICE_OF_BASE;
    const PRICE_OF_INU = LEG_P ? USD_OF_BASE / LEG_P : 0;

    //
    //
    //
    //
    return (
      <tr className={is_valid ? "" : "bg-warning"}>
        <td>{o.is_lft ? "L" : "R"}</td>
        <td>{getMainLegIcon(o)}</td>
        <td>
          {/* {_as_smart_stripe(1, _pair.toLowerCase(), 2, 2, true)} */}
          {o.is_lft ? symbol_1 : symbol_0}
        </td>
        <td>
          {/* {_as_smart_stripe(1, _pair.toLowerCase(), 2, 2, true)} */}
          {/* A%:{POOL_PERC_OF_TOTAL_0.toFixed(6)}
          <br />
          B%:{POOL_PERC_OF_TOTAL_1.toFixed(6)}
          <br />
          A: {_as_progress_bar(POOL_PERC_OF_TOTAL_0)}
          B: {_as_progress_bar(POOL_PERC_OF_TOTAL_1)}
          RES:{" "} */}
          {_as_progress_bar(
            o.is_lft ? POOL_PERC_OF_TOTAL_1 : POOL_PERC_OF_TOTAL_0
          )}
          {/* {o.is_lft ? POOL_PERC_OF_TOTAL_1 : POOL_PERC_OF_TOTAL_0} */}
        </td>
        <td>
          {_as_smart_stripe(1, _left.toLowerCase(), 6, 6, true)} /{" "}
          {_as_smart_stripe(1, _right.toLowerCase(), 6, 6, true)}
        </td>
        <td>
          {/* {left_decimal} | {_2h(hex_pool_a)}
          <br />
          {_2n(_2h(hex_pool_a))}
          <br /> */}
          {to_unit(POOLED_A)}
          <small> {symbol_0}</small>
          {/* {(_2n(_2h(hex_pool_a)) * Math.pow(10, 0 - left_decimal)).toFixed(3)} */}
          {/* <br />
          {(
            _2n(_2h(hex_totaltotal_a)) * Math.pow(10, 0 - left_decimal)
          ).toFixed(3)}
          <br />
          {String(_2bi(hex_pool_a))}
          <br />
          {left_decimal}
          <br />
          P:{shorten(_2bi(hex_pool_a), 3)} */}
        </td>
        <td>
          {/* {right_decimal} | {_2h(hex_pool_b)}
          <br />
          {_2n(_2h(hex_pool_b))}
          <br /> */}
          {to_unit(POOLED_B)}
          <small> {symbol_1}</small>
          {/* {(_2n(_2h(hex_pool_b)) * Math.pow(10, 0 - right_decimal)).toFixed(3)} */}
          {/* <br />
          {(
            _2n(_2h(hex_totaltotal_b)) * Math.pow(10, 0 - right_decimal)
          ).toFixed(3)}
          <br />
          {String(_2bi(hex_pool_b))}
          <br />
          {right_decimal}
          <br />
          P:{shorten(_2bi(hex_pool_b), 3)} */}
        </td>

        <td>
          {to_unit(
            parseFloat(
              (
                _2n(_2h(hex_totaltotal_a)) * Math.pow(10, 0 - left_decimal)
              ).toFixed(3)
            )
          )}
        </td>
        <td>
          {to_unit(
            parseFloat(
              (
                _2n(_2h(hex_totaltotal_b)) * Math.pow(10, 0 - right_decimal)
              ).toFixed(3)
            )
          )}
          {/* {(_2n(_2h(hex_total_b)) * Math.pow(10, 0 - right_decimal)).toFixed(3)}
          <br />
          {hex_total_b
            ? JSON.stringify(bi_to_formatted(hex_total_b).short)
            : ""} */}
        </td>
        <td>
          <small>$ </small>
          {get_base_price(o)}
        </td>
        <td style={{ display: "none" }}>
          {/* {reserve ? JSON.stringify(reserve) : "..."} */}
          {one_eth.toFixed(3)} {symbol_1}
        </td>
        <td>
          <small>$ </small>
          {PRICE_OF_INU.toFixed(3)}
          {/* <br />
          <small>
            {BASE_P.toFixed(2)} * {PRICE_OF_BASE} / {to_unit(LEG_P)}{" "}
          </small> */}
        </td>
        <td style={{ display: "none" }}>
          {one_inu.toFixed(3)} {symbol_0}
        </td>

        <td>
          <b className="font-size-14">{to_usd(USD_OF_BASE * 2)}</b>
        </td>
        <td>
          {to_usd(USD_OF_BASE)}
          {/* <br />
          <small>
            {BASE_P.toFixed(2)} * {PRICE_OF_BASE}{" "}
          </small> */}
        </td>
        <td>{pool_created}</td>
      </tr>
    );
  };
  //
  return (
    <>
      <div className="table-responsive">
        <table className="table table-hover mb-0">
          <tbody>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Base</th>
              <th scope="col">Leg</th>
              <th scope="col">Pooled</th>
              <th scope="col">Pair</th>
              <th scope="col">Pooled A</th>
              <th scope="col">Pooled B</th>
              <th scope="col">Total A</th>
              <th scope="col">Total B</th>
              <th scope="col">Base($)</th>
              <th scope="col" style={{ display: "none" }}>
                Price A
              </th>
              <th scope="col">Leg Price</th>
              <th scope="col" style={{ display: "none" }}>
                Price B (1 ETH)
              </th>
              <th scope="col">Pool Liq.</th>
              <th scope="col">Base Liq.($)</th>
              <th scope="col">Created</th>
            </tr>
            {finalMemo.map((o: DexPairListItemData) => render_row(o))}
          </tbody>
        </table>
      </div>
      {Object.keys(basePrices).map((ca) => (
        <>
          <i className={`cc ${_ca_to_iconname(ca)} font-size-26 m-0`}></i>{" "}
          {_ca_to_iconname(ca)} {_ca_to_price(ca)}
          {" / "}
        </>
      ))}
      <Box sx={{ width: 800 }}>
        {/* BP: {JSON.stringify(basePrices)}
        Ide jön a lista
        {finalMemo.map((o: DexPairListItemData) => (
          <>
            <h4>
              {o?.leftDetails?.symbol ?? ""}/ {o?.rightDetails?.symbol ?? ""}
              -- {o.is_lft ? "LEFT" : "RIGHT"} --
              {getMainLegIcon(o)}
              {o.is_eth
                ? "ETH"
                : o.is_dai
                ? "DAI"
                : o.is_usdc
                ? "USDC"
                : o.is_usdt
                ? "USDT"
                : o.is_sushi
                ? "SUSHI"
                : o.is_btc
                ? "WBTC"
                : `|${o?.leftDetails?.symbol}|${o?.rightDetails?.symbol}|`}
            </h4>
          </>
        ))} */}
        <div>factory: {factory}</div>
        <div>range:{range.join(" / ")}</div>
        <div>
          {/* pairs: {JSON.stringify(factoryPairsAtIndices)}
          <br /> */}
          {factoryPairsAtIndices ? (
            <div>
              {factoryPairsAtIndices.map(
                (fp, idx) =>
                  fp && (
                    <span>
                      <small>{idx}</small>
                      {_as_smart_stripe(1, fp, 5, 5, true)}
                      {/* {JSON.stringify(fp)} */}
                    </span>
                  )
              )}
            </div>
          ) : (
            "Loading..."
          )}
        </div>
        {/* <div>
          lefts: pairs: {JSON.stringify(leftsAtIndices)}
          {leftsAtIndices ? (
            <div>
              {leftsAtIndices.map(
                (fp, idx) =>
                  fp && (
                    <span>
                      <small>{idx}</small>
                      {_as_smart_stripe(1, fp, 5, 5, true)}
                    </span>
                  )
              )}
            </div>
          ) : (
            "Loading..."
          )}
        </div> */}
        {/* <div>
          rights: {JSON.stringify(rightsAtIndices)}
          {rightsAtIndices ? (
            <div>
              {rightsAtIndices.map(
                (fp, idx) =>
                  fp && (
                    <span>
                      <small>{idx}</small>
                      {_as_smart_stripe(1, fp, 5, 5, true)}
                    </span>
                  )
              )}
            </div>
          ) : (
            "Loading..."
          )}
        </div> */}
        {/* <div>
          uniqueMemo: {JSON.stringify(uniqueMemo)}
          {uniqueMemo ? (
            <div>
              {uniqueMemo.length} /
              {uniqueMemo.map(
                (fp, idx) =>
                  fp && (
                    <span>
                      <small>{idx}</small>
                      {_as_smart_stripe(1, fp, 5, 5, true)}
                    </span>
                  )
              )}
            </div>
          ) : (
            "Loading..."
          )}
        </div> */}
        {/* <div>
          uniqueDetailMemo:{" "}
          {uniqueDetailMemo.map((o) => (
            <>
              {o.symbol} : {o.decimal} : {o.address}
              <br />
            </>
          ))}
        </div> */}

        {/* <div>
          JUHU:
          {finalMemo.length}
          {finalMemo.map((o) => (
            <div
              style={{
                backgroundColor: !o.is_valid ? "" : "rgba(0,255,0,0.1)",
              }}
            >
              {o.pair} :<br />
              LEFT: {o.left}
              <br />
              {JSON.stringify(o.leftDetails)}
              <br />
              <br />
              RIGHT: {o.right} <br />
              {JSON.stringify(o.rightDetails)}
              <br />
              <br />
              RESERVE: {JSON.stringify(o.reserve)}
              <br />
              TOTAL: {JSON.stringify(o.total)} | {o.total?.toString() || ""}
              <hr />
            </div>
          ))}
        </div> */}
      </Box>
    </>
  );
}

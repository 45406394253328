export const getBaseCurrencyName = (chainId: number) => {
  let s = "eth";
  //
  switch (chainId) {
    case 1:
      s = "ETH";
      break;
    case 10:
      s = "ETH";
      break;
    case 25:
      s = "CRO";
      break;
    case 56:
      s = "BNB";
      break;
    case 137:
      s = "MAT";
      break;
    case 250:
      s = "FTM";
      break;
    case 42161:
      s = "AETH";
      break;
    case 1313161554:
      s = "ETH";
      break; // aurora;
  }
  //
  return s;
};

export const getBaseCurrencyImageUrl = (chainId: number) => {
  let s = "eth";
  //
  switch (chainId) {
    case 1:
      s = "eth_coin.png";
      break;
    case 10:
      s = "eth_coin.png";
      break;
    case 25:
      s = "cro_coin.png";
      break;
    case 56:
      s = "bnb_coin.png";
      break;
    case 137:
      s = "matic_coin.png";
      break;
    case 250:
      s = "ftm_coin.png";
      break;
    case 42161:
      s = "aeth_coin.png";
      break;
    case 1313161554:
      s = "eth_coin.png";
      break; // aurora;
  }
  //

  return s;
};

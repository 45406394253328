// import WebSocket from "ws";
import BlocknativeSdk from "bnc-sdk";

//import Web3 from "web3";
//const wsapi_url = "wss://api.blocknative.com/v0";
// const web3 = new Web3(wsapi_url);

// secret 73e47243-e927-47d8-8130-ab2f6cefcbd3
// id: 281ed247-2bdb-49a0-b724-4829298a14b9
// create options object
const options = {
  dappId: "281ed247-2bdb-49a0-b724-4829298a14b9", // "6e71b997-1612-4ca8-8717-6308ccafb6fc", // process.env.BN_API_KEY,
  ws: WebSocket,
  networkId: 1,
  transactionHandlers: [(event: any) => console.log(event.transaction)],
};

// initialize and connect to the api
const blocknative = new BlocknativeSdk(options as any);

const useTransactionMonitor = () => {
  const watch = (from: string, to: string) => {
    //TODO: chainID
    // const txOptions = {
    //   from: "0x7A132e43013cAA14a3744721EF179f1F3d2b3921",
    //   to: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
    // };
    const txOptions = {
      from,
      to,
    };

    console.log("watching", txOptions);

    const { emitter: ownerEmitter, details: ownerDetails } =
      blocknative.account(from);

    ownerEmitter.on("all", (transaction) => {
      console.log("OWNER", transaction);
    });

    const { emitter: contractEmitter, details: contractDetails } =
      blocknative.account(to);

    contractEmitter.on("all", (transaction) => {
      console.log("CONTRACT", transaction);
    });

    return {
      ownerEmitter,
      ownerDetails,
      contractEmitter,
      contractDetails,
    };

    // initiate a transaction via web3.js

    // web3.eth.sendTransaction(txOptions).on("transactionHash", (hash) => {
    //   // call with the transaction hash of the transaction that you would like to receive status updates for
    //   const { emitter } = blocknative.transaction(hash);

    //   console.log("emitter ready", emitter);
    //   // listen to some events
    //   emitter.on("txPool", (transaction: any) => {
    //     console.log(`Sending ${transaction.value} wei to ${transaction.to}`);
    //   });

    //   emitter.on("txConfirmed", (transaction) => {
    //     console.log("Transaction is confirmed!");
    //   });

    //   // catch every other event that occurs and log it
    //   emitter.on("all", (transaction) => {
    //     console.log(`Transaction event: ${transaction.eventCode}`);
    //   });
    // });
  };

  const gasPrice = async () => {
    // https://api.blocknative.com/gasprices/blockprices
    const url = `https://api.blocknative.com/gasprices/blockprices`;
    //
    console.log("downloading from", url);

    const response = await fetch(url, {
      headers: { Authorization: options.dappId },
    });
    console.log("res gas", response);
    const json = await response.json();
    console.log("json gas", json);
    //
    const res = json as Record<string, any>;

    console.log("blocknative gas prices", res);
    //
    return res?.maxPrice || 0;
  };

  const gasPriceDistribution = async () => {
    // https://api.blocknative.com/gasprices/distribution
    const url = `https://api.blocknative.com/gasprices/distribution`;
    //
    console.log("downloading from", url);

    let is_too_many = false;
    let res;
    //
    try {
      const response = await fetch(url, {
        headers: { Authorization: options.dappId },
      });
      console.log("res gas distribution", response);
      const json = await response.json();
      console.log("json gas distribution", json);
      //
      res = json as Record<string, any>;
      const { status } = res;

      is_too_many = status === 429;
    } catch (ex) {
      is_too_many = true;
    }

    if (is_too_many) {
      return { max: 0, fee_base: 0, fee_max: 0, chart: [], L: 0 };
    } else {
      console.log("blocknative gas distribution", res);
      console.log("blocknative GAS", Object.keys(res || {}));
      const r = (res || {}) as any;
      const mp = r.maxPrice || 0;
      
      const bfpg = r.baseFeePerGas || 0;
      const mpfpg = r.maxPriorityFeePerGas || 0;
      
      const chart = r.topNDistribution?.distribution || [];
      const L = chart.length;
      //
      console.log(
        "blocknative MAX",
        mp,
        "data",
        //chart.map((c: Array<number>) => c[0])
        chart
      );
      //
      return { max: mp, fee_base: bfpg, fee_max: mpfpg, chart, L };
    }
    //
    // return res;
  };

  return {
    watch,
    gasPrice,
    gasPriceDistribution,
  };
};

export default useTransactionMonitor;

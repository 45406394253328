import { loadConfig } from "../../../ai";
import HandledMethods from "../../../ai/config/method-helper/abis";
import mh, { HandledMethodId } from "../../../ai/method-helper";

import { ContentBlock, ContentRow } from "../../base";
import DataCanvas from "../../DataCanvas";
import DataCanvasBlockMap from "../../DataCanvasBlockMap";
import {
  _as_interface_stripe,
  _as_smart_stripe,
  _as_stripe,
} from "../../SimpleControls";

const AI_CONFIG = loadConfig();
const KNOWN_GENESIS_IDS = AI_CONFIG.IDS.GENESIS;
const KNOWN_SETPAIR_IDS = AI_CONFIG.IDS.SET_PAIR;
const KNOWN_CREATEPAIR_IDS = AI_CONFIG.IDS.CREATE_PAIR;
const KNOWN_SETPAIR_ABIS = AI_CONFIG.ABI.SET_PAIR;
const KNOWN_CREATEPAIR_ABIS = AI_CONFIG.ABI.CREATE_PAIR;
const KNOWN_ADD_LIQ_IDS = AI_CONFIG.IDS.ADD_LIQ;
const KNOWN_ADD_LIQ_ABIS = AI_CONFIG.ABI.ADD_LIQ;

export const BlockCacheRandomItemsControl = (props: {
  blocks: Array<any>;
}): JSX.Element => {
  const { blocks } = props;
  //
  // from d5 to d9
  //
  const _rows = ["d5", "d6", "d7", "d8", "d9"]; // .map(s => `${s}`);
  //
  const h16 = "0123456789abcdef".split("");
  const _cols = h16
    .map((x, i) => h16.map((_h: string) => `${x}${_h}`))
    .flat() as Array<string>;
  //
  const _eth_logo = () => (
    <img
      src="../eth.png"
      style={{
        height: "12px",
        position: "relative",
        top: "2px",
      }}
    />
  );
  const _as_ethereum_price = (s: string) => (
    <>
      {(parseInt(s, 16) / Math.pow(10, 18)).toFixed(2)}
      {_eth_logo()}
    </>
  );
  //
  const _as_tx_canvas = (transactions: Array<any>): JSX.Element => {
    const inputs = transactions.map((tx) =>
      tx.input.length === 0
        ? "00000000" /* 4-bytes */
        : tx.input.length > 9
        ? tx.input.substring(2, 10)
        : tx.input.replace("0x", "ffffffff")
    );

    const data_string = `0x${inputs.join("")}`;
    const square_side = Math.floor(Math.sqrt(data_string.length)) + 1;
    const width = 295;
    const height = 200;
    const scale = Math.floor(width / square_side) + 1;

    return (
      <>
        <DataCanvasBlockMap
          data_string={data_string}
          width={width}
          // height={30}
          scale={scale}
        />
        <br />
        {transactions.length} /{data_string.length} / DL:{" "}
        {Math.floor(Math.sqrt(data_string.length)) + 1} - 2
      </>
    );
  };
  ///
  return (
    <ContentBlock>
      {/* {JSON.stringify(summary?.randomTen[0])} */}
      <ContentRow>
        {blocks
          ? blocks.map(
              (b, row_idx, rows) => (
                //<div style={{ display: "inline-block", border:'solid 1px red' }}>
                <div
                  style={{
                    width: "300px",
                    // height: "200px",
                    height: "auto",
                    verticalAlign: "top",
                    display: "inline-block",
                    border: "solid 1px rgba(0,0,0,0.1)",
                    borderRadius: "1px",
                    margin: "1px",
                  }}
                >
                  #{parseInt(b.number)} by {_as_stripe(b.miner, 8)}{" "}
                  {(
                    (new Date().getTime() -
                      new Date(parseInt(b.timestamp, 16) * 1000).getTime()) /
                    (3600 * 1000)
                  ).toFixed(2)}
                  h ago
                  <hr />
                  Base Fee:
                  {(parseInt(b.baseFeePerGas) * 0.000000001).toFixed(2)} gwei
                  <br />
                  Gas Limit:{(0.000001 * parseInt(b.gasLimit)).toFixed(2)}M (
                  {(
                    parseInt(b.baseFeePerGas) *
                    parseInt(b.gasLimit) *
                    Math.pow(10, -18)
                  ).toFixed(4)}{" "}
                  ETH
                  <br />
                  gasUsed: {(0.000001 * parseInt(b.gasUsed)).toFixed(2)}M (
                  {(
                    parseInt(b.baseFeePerGas) *
                    parseInt(b.gasUsed) *
                    Math.pow(10, -18)
                  ).toFixed(4)}{" "}
                  ETH )
                  <br />
                  gasRatio: (
                  {((100 * parseInt(b.gasUsed)) / parseInt(b.gasLimit)).toFixed(
                    2
                  )}
                  % )
                  <br />
                  <br />
                  size: {b.size} ({parseInt(b.size, 16)}) * (
                  {(0.000001 * parseInt(b.size, 16)).toFixed(2)}MB)
                  <br />
                  timestamp: {b.timestamp} ({parseInt(b.timestamp, 16)})<br />
                  {new Date().getTime()}
                  <br />
                  {new Date(parseInt(b.timestamp, 16) * 1000).getTime()}
                  <br />(
                  {(
                    (new Date().getTime() -
                      new Date(parseInt(b.timestamp, 16) * 1000).getTime()) /
                    (86400 * 1000)
                  ).toFixed(2)}{" "}
                  days ago)
                  <br />
                  <br />
                  transactions:{b.transactions.length}
                  {_as_tx_canvas(b.transactions)}
                  <br />
                  <div style={{ lineHeight: "10px" }}>
                    <hr />
                    C:
                    {b.transactions
                      .filter((tx: any) => tx.type === "0x1")
                      .map((tx: any) => (
                        <>({tx.input.length}). </>
                      ))}
                    <hr />
                    G:
                    {b.transactions
                      .filter((tx: any) =>
                        KNOWN_GENESIS_IDS.includes(tx.input.substring(0, 10))
                      )
                      .map((tx: any) => (
                        // <>({tx.input.substring(0,10)}). </>
                        <>
                          <span style={{ position: "relative" }}>
                            {_as_interface_stripe(
                              tx.input.substring(0, 10),
                              10
                            )}
                            <DataCanvasBlockMap
                              width={100}
                              height={34}
                              data_string={tx.input}
                              scale={1}
                            />
                            <DataCanvas
                              width={300}
                              height={100}
                              data_string={tx.input}
                            />
                          </span>
                          {/* {_as_ethereum_price(tx.value)} */}{" "}
                        </>
                      ))}
                    <hr />
                    SP:
                    {b.transactions
                      .filter((tx: any) =>
                        KNOWN_SETPAIR_IDS.includes(tx.input.substring(0, 10))
                      )
                      .map((tx: any) => (
                        // <>({tx.input.substring(0,10)}). </>
                        <>
                          <span style={{ position: "relative" }}>
                            {_as_interface_stripe(
                              tx.input.substring(0, 10),
                              10
                            )}
                            {
                              (
                                (KNOWN_SETPAIR_ABIS.find(
                                  (x: any) =>
                                    x.hex === tx.input.substring(0, 10)
                                ) || { signature: "no match" }) as {
                                  signature: string;
                                }
                              ).signature
                            }
                            {tx.input}{" "}
                            {mh.is_supported_method(tx.input.substring(0, 10))
                              ? "OK"
                              : "UFO"}
                            {JSON.stringify(
                              mh.unwrap(
                                tx.input.substring(0, 10),
                                tx.input.substring(10)
                              )
                            )}
                          </span>
                          {/* {_as_ethereum_price(tx.value)} */}{" "}
                        </>
                      ))}
                    <hr />
                    CP:
                    {b.transactions
                      .filter((tx: any) =>
                        KNOWN_CREATEPAIR_IDS.includes(tx.input.substring(0, 10))
                      )
                      .map((tx: any) => (
                        // <>({tx.input.substring(0,10)}). </>
                        <>
                          <span style={{ position: "relative" }}>
                            {_as_interface_stripe(
                              tx.input.substring(0, 10),
                              10
                            )}
                            {
                              (
                                (KNOWN_CREATEPAIR_ABIS.find(
                                  (x: any) =>
                                    x.hex === tx.input.substring(0, 10)
                                ) || { signature: "no match" }) as {
                                  signature: string;
                                }
                              ).signature
                            }
                            {tx.input}{" "}
                            {mh.is_supported_method(tx.input.substring(0, 10))
                              ? "OK"
                              : "UFO"}
                            {JSON.stringify(
                              mh.unwrap(
                                tx.input.substring(0, 10),
                                tx.input.substring(10)
                              )
                            )}
                          </span>
                          {/* {_as_ethereum_price(tx.value)} */}{" "}
                        </>
                      ))}
                    <hr />
                    AL:
                    {b.transactions
                      .filter((tx: any) =>
                        KNOWN_ADD_LIQ_IDS.includes(tx.input.substring(0, 10))
                      )
                      .map((tx: any) => (
                        // <>({tx.input.substring(0,10)}). </>
                        <>
                          <span style={{ position: "relative" }}>
                            {_as_interface_stripe(
                              tx.input.substring(0, 10),
                              10
                            )}
                            {
                              (
                                (KNOWN_ADD_LIQ_ABIS.find(
                                  (x: any) =>
                                    x.hex === tx.input.substring(0, 10)
                                ) || { signature: "no match" }) as {
                                  signature: string;
                                }
                              ).signature
                            }
                            {tx.input}{" "}
                            {mh.is_supported_method(tx.input.substring(0, 10))
                              ? "OK"
                              : "UFO"}
                            {JSON.stringify(
                              mh.unwrap(
                                tx.input.substring(0, 10),
                                tx.input.substring(10)
                              )
                            )}
                          </span>
                          {/* {_as_ethereum_price(tx.value)} */}{" "}
                        </>
                      ))}
                    <hr />
                    APP:
                    {b.transactions
                      .filter((tx: any) =>
                        ["0x095ea7b3", "0xa9059cbb"].includes(
                          tx.input.substring(0, 10)
                        )
                      )
                      .map((tx: any) => ({
                        ...tx,
                        method_name: mh.parseMethodName(
                          tx.input.substring(0, 10)
                        ),
                      }))
                      .map((tx: any) => (
                        // <>({tx.input.substring(0,10)}). </>
                        <>
                          <span style={{ position: "relative" }}>
                            {_as_interface_stripe(
                              tx.input.substring(0, 10),
                              10
                            )}
                            {/* {tx.method_name !== null
                              ? HandledMethods[
                                  tx.method_name as HandledMethodId
                                ].name
                              : ""} */}
                            {mh.is_supported_method(
                              tx.input.substring(0, 10)
                            ) ? (
                              <>
                                to:{" "}
                                {_as_smart_stripe(
                                  1,
                                  tx.to.toLowerCase(),
                                  8,
                                  8,
                                  true,
                                  -100
                                )}{" "}
                                {_as_smart_stripe(
                                  1,
                                  mh
                                    .unwrap(
                                      tx.input.substring(0, 10),
                                      tx.input.substring(10)
                                    )[1]
                                    .spender.toLowerCase(),
                                  5,
                                  5,
                                  true,
                                  -200
                                )}
                              </>
                            ) : (
                              "UFO"
                            )}
                          </span>
                          {/* {_as_ethereum_price(tx.value)} */}{" "}
                        </>
                      ))}
                    <hr />
                    0:
                    {b.transactions
                      .filter((tx: any) => tx.type === "0x0")
                      .map((tx: any) => (
                        // <>({tx.input.substring(0,10)}). </>
                        <>
                          <span style={{ position: "relative" }}>
                            {_as_interface_stripe(
                              tx.input.substring(0, 10),
                              10
                            )}
                          </span>
                          {/* {_as_ethereum_price(tx.value)} */}{" "}
                        </>
                      ))}
                    <hr />
                    2:
                    {b.transactions
                      .filter((tx: any) => tx.type === "0x2")
                      .map((tx: any) => (
                        // <>({tx.input.substring(0,10)}). </>
                        <>
                          <span style={{ position: "relative" }}>
                            {_as_interface_stripe(
                              tx.input.substring(0, 10),
                              10
                            )}
                          </span>
                          {/* {_as_ethereum_price(tx.value)} */}{" "}
                        </>
                      ))}
                    <br />
                    difficulty{b.difficulty} <br />
                    <br />
                  </div>
                </div>
              )
              //</div>
            )
          : "Loading..."}
      </ContentRow>
    </ContentBlock>
  );
};

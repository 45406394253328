import { connect } from "react-redux";
import { toggleLeftSide } from "../redux/ui/ui.actions";

const SideTogglerButton = ({ toggleLeftSide }: { toggleLeftSide: any }) => {
  //
  return (
    <a
      href="#"
      className="sidebar-toggle"
      data-toggle="push-menu"
      role="button"
      onClick={() => toggleLeftSide()}
    >
      <span className="sr-only">Toggle navigation</span>
    </a>
  );
};

const mapStateToProps = ({
  ui,
}: {
  ui: {
    isLeftSideToggled: boolean;
  };
}) => ({
  ui: ui.isLeftSideToggled,
});

const mapDispatchToProps = (dispatch: any) => ({
  toggleLeftSide: () => dispatch(toggleLeftSide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideTogglerButton);
import React, { useState } from "react";

const useMouseMove = () => {
  const [state, setState] = useState<{
    x: number;
    y: number;
    xx: number;
    yy: number;
  }>({
    x: 0,
    y: 0,
    xx: 0,
    yy: 0,
  });

  const handleMouseMove = (e: any) => {
    e.persist();
    let rect = e.target.getBoundingClientRect();
    //
    setState((state) => ({
      ...state,
      x: e.clientX,
      y: e.clientY,
      xx: e.clientX - rect.left,
      yy: e.clientY - rect.top,
    }));
  };
  return {
    x: state.x,
    y: state.y,
    xx: state.xx,
    yy: state.yy,
    handleMouseMove,
  };
};

export default useMouseMove;

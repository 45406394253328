import { MethodEnvelope, HandledMethodId } from "../../../method-helper";

const methodId = "0xab834bab";
const methodEnvelope = {
  name: "atomicMatch_",
  signature:
    "atomicMatch_(address[14],uint256[18],uint8[8],bytes,bytes,bytes,bytes,bytes,bytes,uint8[2],bytes32[5])",
  input_types: [
    "address[14]",
    "uint256[18]",
    "uint8[8]",
    "bytes",
    "bytes",
    "bytes",
    "bytes",
    "bytes",
    "bytes",
    "uint8[2]",
    "bytes32[5]",
  ],
  output_aliases: [
    "addresses",
    "uint256s",
    "uint8s",
    "bytes_0",
    "bytes_1",
    "bytes_2",
    "bytes_3",
    "bytes_4",
    "bytes_5",
    "uint8_arr",
    "bytes32s",
  ],
};

export default {
  id: methodId as HandledMethodId,
  meta: methodEnvelope as MethodEnvelope,
};

import { ERC_20_INTERFACE, ERC_20_ISH_INTERFACE } from "../settings";

const useMethodColors = () => {
  const botColorByMethods = (methodNames: Array<string>) => {
    // const types = ["LaunchedAt","Manual BL1","Manual BL2","Manual BL","Gas limit","AntiSnipe","External"];

    const methodFootprints: Record<string, Array<string>> = {
      LaunchedAt: ["launchedAt"],
      "Manual BL1": [
        "setbot",
        "setIsBot",
        "setBot",
        "addBotWallet",
        "addBotWallets",
      ],
      "Manual BL2": [
        "setBlacklistEnabled",
        "setBlacklisted",
        "addBotToBlacklist",
        "removeBotFromBlacklist",
        "addBotToBlackList",
        "blacklistAddress",
      ],
      "Multiple BL": ["setBlacklistEnabledMultiple"],
      "Gas limit": ["setGasPriceLimit"],
      AntiSnipe: ["setAntiSnipe"],
      BlockBots: ["blockBots"],
      BoughtEarly: ["boughtEarly", "earlyBuyPenaltyEnd", "removeBoughtEarly"],
      Protected: ["setProtectionSettings", "isProtected"],

      External: ["setProtectionSettings"],
      PurgeSniper: ["purgeSniper"],
    };
    //
    const footprintsColors: Record<string, string> = {
      LaunchedAt: "#47829a",
      "Manual BL1": "#47ff9a",
      "Manual BL2": "#ff829a",
      "Multiple BL": "#4700ff",
      "Gas limit": "#224789",
      AntiSnipe: "#84aac2",
      BoughtEarly: "#77ff00",
      BlockBots: "#2233aa",
      Protected: "#aabb33",

      External: "#bb7829",
      PurgeSniper: "#ff5601",
    };
    //
    // find only one result, break on first positive
    //
    let color = "#eeeeee";
    //
    for (const [type, matchMethods] of Object.entries(methodFootprints)) {
      const is_positive = matchMethods
        .map((mm) => methodNames.includes(mm))
        .reduce((a: boolean, b: boolean) => a || b, false);
      //
      if (is_positive) {
        color = footprintsColors[type];
        console.log("positive for", [type, matchMethods], "using color", color);
        break; // returning first positive
      }
    }
    //
    return color;
  };
  //
  // open methods
  //
  const openMethodColorByMethods = (methodNames: Array<string>) => {
    // const types = ["Launch Prev L", "Launch Add L"];

    const methodFootprints: Record<string, Array<string>> = {
      ActiveBlock: ["tradingActiveBlock"],
      "Launch Prev L": [
        "launch",
        "enableTrading",
        "enabletrading",
        "allowtrading",
      ],
      "Launch Add L": ["openTrading", "intializeContract", "startTrade"],
      Pausable: ["setPaused", "pause"],
      PublicSale: ["startPublicSale"],
      SalesActive: ["setSaleIsActive"],
      Trade: ["allowtrading"],
      Trading: ["setTrading"],
      Transfer: ["transfersEnabled"],
      SaleStatus: ["toggleSaleStatus"],
    };
    //
    const footprintsColors: Record<string, string> = {
      ActiveBlock: "#a7ff9a",
      "Launch Prev L": "#47829a",
      "Launch Add L": "#47ff9a",
      Pausable: "#775f0a",
      PublicSale: "#d111ca",
      SalesActive: "#074faa",
      Trade: "#7799ff",
      Trading: "#172f8a",
      Transfer: "#f70fcc",
      SaleStatus: "#27cf4c",
    };
    //
    // find only one result, break on first positive
    //
    let color = "#eeeeee";
    //
    for (const [type, matchMethods] of Object.entries(methodFootprints)) {
      //console.log("checking for", [type, matchMethods]);

      const is_positive = matchMethods
        .map((mm) => methodNames.includes(mm))
        .reduce((a: boolean, b: boolean) => a || b, false);
      //
      if (is_positive) {
        color = footprintsColors[type];
        console.log("positive for", [type, matchMethods], "using color", color);
        break; // returning first positive
      }
    }
    //
    return color;
  };

  //
  // erc types
  //
  const ercColorByMethods = (methodNames: Array<string>) => {
    // const types = ["ERC721Creator", "ERC20", "NFT"];

    const methodFootprints: Record<string, Array<string>> = {
      ERC721Creator: ["implementation"],
      ERC1967Proxy: ["_implementation"], // or : ["_getImplementation"],
      ProxyLike: ["getImplementation"],
      ERC20: ERC_20_INTERFACE.map((e) => e.name),
      ERC20ISH: ERC_20_ISH_INTERFACE.map((e) => e.name),
      NFT: ["ownerOf", "setBaseURI"],
      NFTISH: ["ownerOf", "tokenURI"],
    };
    //
    const footprintsColors: Record<string, string> = {
      ERC721Creator: "#07f22a",
      ERC1967Proxy: "#27ff4a",
      ProxyLike: "#47ff6a",
      ERC20: "#47829a",
      ERC20ISH: "#98aacc",
      NFT: "#aa6699",
      NFTISH: "#dd99bb",
    };
    //
    // find only one result, break on first positive
    //
    let color = "#eeeeee"; // color of the unicorn
    //
    for (const [type, matchMethods] of Object.entries(methodFootprints)) {
      const is_positive = matchMethods
        .map((mm) => methodNames.includes(mm))
        .reduce((a: boolean, b: boolean) => a && b, true); // !!!!!!!!!!!!!!!!!!!!!!
      //
      if (is_positive) {
        color = footprintsColors[type];
        console.log(
          "positive for",
          [type, matchMethods.length],
          "using color",
          color
        );
        break; // returning first positive
      }
    }
    //
    return color;
  };

  //
  // maxTransactionMode
  //
  const maxTransactionModeColorByMethods = (methodNames: Array<string>) => {
    // const types = ["ERC721Creator", "ERC20", "NFT"];

    const methodFootprints: Record<string, Array<string>> = {
      Percent: ["setMaxTxPercent"],
      Amount: [
        "maxTransactionAmount",
        "_getMaxTxAmount",
        "_maxTxAmount",
        "_setMaxTxAmount",
        "setMaxTxnAmount",
        "maxTxAmountUI",
        "maxTxAmount",
        "setMaxTx",
        "updateMaxTxnAmount",
        "maxBuyTransactionAmount",
      ],
      Mint: ["setmaxMintAmount"], // not used
    };
    //
    const footprintsColors: Record<string, string> = {
      Percent: "#07f22a",
      Amount: "#47829a",
      Mint: "#98aacc",
    };
    //
    // find only one result, break on first positive
    //
    let color = "#eeeeee";
    //
    for (const [type, matchMethods] of Object.entries(methodFootprints)) {
      const is_positive = matchMethods
        .map((mm) => methodNames.includes(mm))
        .reduce((a: boolean, b: boolean) => a || b, false);
      //
      if (is_positive) {
        color = footprintsColors[type];
        console.log(
          "positive for",
          [type, matchMethods.length],
          "using color",
          color
        );
        break; // returning first positive
      }
    }
    //
    return color;
  };

  return {
    botColorByMethods,
    openMethodColorByMethods,
    ercColorByMethods,
    maxTransactionModeColorByMethods,
  };
};

export default useMethodColors;

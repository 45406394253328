import * as React from "react";

import SpriteText from "three-spritetext";

import styled from "@emotion/styled";
import {
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

import { useBlockMeta, useBlockNumber, useEthers } from "@usedapp/core";
import {
  Container,
  ContentBlock,
  ContentRow,
  MainContent,
  Section,
} from "../components/base";
import { Label } from "../typography/Label";
import { TextInline } from "../typography/Text";

import ForceGraph3D from "react-force-graph-3d";

import { useState, useEffect } from "react";
import filemap from "./TREE.json";
import { NFTE } from "@nfte/react";

import * as go from "gojs";
import { ReactDiagram } from "gojs-react";
import "../App.css"; // contains .diagram-component CSS

// import React from "react"; // Step 1 - Include react
import ReactFC from "react-fusioncharts"; // Step 2 - Include the react-fusioncharts component
import FusionCharts from "fusioncharts"; // Step 3 - Include the fusioncharts library
import Column2D from "fusioncharts/fusioncharts.charts"; // Step 4 - Include the chart type
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion"; // Step 5 - Include the theme as fusion
import { API_URL_ROOT } from "../settings";

ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme); // Step 6 - Adding the chart and theme as dependency to the core fusioncharts

type ForcedGraphEnvelope = {
  nodes: Array<any>;
  links: Array<any>;
};

type ServerTreeEnvelope = {
  graph_data: ForcedGraphEnvelope;
  envelope: Record<string, Array<string>>;
};

//
//
//
// props passed in from a parent component holding state, some of which will be passed to ReactDiagram
interface WrapperProps {
  nodeDataArray: Array<go.ObjectData>;
  linkDataArray: Array<go.ObjectData>;
  modelData: go.ObjectData;
  skipsDiagramUpdate: boolean;
  onDiagramEvent: (e: go.DiagramEvent) => void;
  onModelChange: (e: go.IncrementalData) => void;
}
//
//
//

export class DiagramWrapper extends React.Component<WrapperProps, {}> {
  /**
   * Ref to keep a reference to the component, which provides access to the GoJS diagram via getDiagram().
   */
  private diagramRef: React.RefObject<ReactDiagram>;

  constructor(props: WrapperProps) {
    super(props);
    this.diagramRef = React.createRef();
  }

  /**
   * Get the diagram reference and add any desired diagram listeners.
   * Typically the same function will be used for each listener,
   * with the function using a switch statement to handle the events.
   * This is only necessary when you want to define additional app-specific diagram listeners.
   */
  public componentDidMount() {
    if (!this.diagramRef.current) return;
    const diagram = this.diagramRef.current.getDiagram();
    //
    this.diagramRef.current.render();
    //
    if (diagram instanceof go.Diagram) {
      diagram.addDiagramListener("ChangedSelection", this.props.onDiagramEvent);
    }
  }

  /**
   * Get the diagram reference and remove listeners that were added during mounting.
   * This is only necessary when you have defined additional app-specific diagram listeners.
   */
  public componentWillUnmount() {
    if (!this.diagramRef.current) return;
    const diagram = this.diagramRef.current.getDiagram();
    if (diagram instanceof go.Diagram) {
      diagram.removeDiagramListener(
        "ChangedSelection",
        this.props.onDiagramEvent
      );
    }
  }

  /**
   * Diagram initialization method, which is passed to the ReactDiagram component.
   * This method is responsible for making the diagram and initializing the model, any templates,
   * and maybe doing other initialization tasks like customizing tools.
   * The model's data should not be set here, as the ReactDiagram component handles that via the other props.
   */
  private initDiagram(): go.Diagram {
    const $ = go.GraphObject.make;
    // set your license key here before creating the diagram: go.Diagram.licenseKey = "...";
    const diagram = $(go.Diagram, {
      "undoManager.isEnabled": true, // must be set to allow for model change listening
      // 'undoManager.maxHistoryLength': 0,  // uncomment disable undo/redo functionality
      "clickCreatingTool.archetypeNodeData": {
        text: "new node",
        color: "lightblue",
      },
      model: new go.GraphLinksModel({
        linkKeyProperty: "key", // IMPORTANT! must be defined for merges and data sync when using GraphLinksModel
        // positive keys for nodes
        makeUniqueKeyFunction: (m: go.Model, data: any) => {
          let k = data.key || 1;
          while (m.findNodeDataForKey(k)) k++;
          data.key = k;
          return k;
        },
        // negative keys for links
        makeUniqueLinkKeyFunction: (m: go.GraphLinksModel, data: any) => {
          let k = data.key || -1;
          while (m.findLinkDataForKey(k)) k--;
          data.key = k;
          return k;
        },
      }),
    });

    // define a simple Node template
    diagram.nodeTemplate = $(
      go.Node,
      "Auto", // the Shape will go around the TextBlock
      new go.Binding("location", "loc", go.Point.parse).makeTwoWay(
        go.Point.stringify
      ),
      $(
        go.Shape,
        "RoundedRectangle",
        {
          name: "SHAPE",
          fill: "white",
          strokeWidth: 0,
          // set the port properties:
          portId: "",
          fromLinkable: true,
          toLinkable: true,
          cursor: "pointer",
        },
        // Shape.fill is bound to Node.data.color
        new go.Binding("fill", "color")
      ),
      $(
        go.TextBlock,
        { margin: 8, editable: true, font: "400 .875rem Roboto, sans-serif" }, // some room around the text
        new go.Binding("text").makeTwoWay()
      )
    );

    // relinking depends on modelData
    diagram.linkTemplate = $(
      go.Link,
      new go.Binding("relinkableFrom", "canRelink").ofModel(),
      new go.Binding("relinkableTo", "canRelink").ofModel(),
      $(go.Shape),
      $(go.Shape, { toArrow: "Standard" })
    );

    return diagram;
  }

  public render() {
    return (
      <ReactDiagram
        ref={this.diagramRef}
        divClassName="diagram-component"
        initDiagram={this.initDiagram}
        nodeDataArray={this.props.nodeDataArray}
        linkDataArray={this.props.linkDataArray}
        modelData={this.props.modelData}
        onModelChange={this.props.onModelChange}
        skipsDiagramUpdate={this.props.skipsDiagramUpdate}
      />
    );
  }
}
//
//
//

interface AppState {
  // ...
  nodeDataArray: Array<go.ObjectData>;
  linkDataArray: Array<go.ObjectData>;
  modelData: go.ObjectData;
  selectedKey: number | null;
  skipsDiagramUpdate: boolean;
}

class DragNodeGraphWithState extends React.Component<{}, AppState> {
  constructor(props: object) {
    super(props);
    this.state = {
      // ...
      nodeDataArray: [
        { key: 1, text: "Optimism🖇", color: "lightblue", loc: "0 40" },
        { key: 2, text: "Cronos🖇", color: "lightblue", loc: "0 80" },
        { key: 3, text: "Binance🖇", color: "lightblue", loc: "0 120" },
        { key: 4, text: "Matic🖇", color: "lightblue", loc: "0 160" },
        { key: 5, text: "Fantom🖇", color: "lightblue", loc: "0 200" },
        { key: 6, text: "Kucoin🖇", color: "lightblue", loc: "0 240" },

        { key: 0, text: "Ethereum🖇", color: "lightblue", loc: "0 280" },

        { key: 7, text: "MoonBeam🖇", color: "lightblue", loc: "0 320" },
        { key: 8, text: "MoonRiver🖇", color: "lightblue", loc: "0 360" },
        { key: 9, text: "Arbitrum🖇", color: "lightblue", loc: "0 400" },
        { key: 10, text: "Celo🖇", color: "lightblue", loc: "0 440" },
        { key: 11, text: "Avalanche🖇", color: "lightblue", loc: "0 480" },
        { key: 12, text: "Harmony🖇", color: "lightblue", loc: "0 520" },
        //
        { key: 41, text: "📟 Opt", color: "lightblue", loc: "100 40" },
        { key: 42, text: "📟 Cro", color: "lightblue", loc: "100 80" },
        { key: 43, text: "📟 Bsc", color: "lightblue", loc: "100 120" },
        { key: 44, text: "📟 Mat", color: "lightblue", loc: "100 160" },
        { key: 45, text: "📟 Ftm", color: "lightblue", loc: "100 200" },
        { key: 46, text: "📟 Kcc", color: "lightblue", loc: "100 240" },
        { key: 40, text: "📟 Eth", color: "lightblue", loc: "100 280" },
        { key: 47, text: "📟 Mbm", color: "lightblue", loc: "100 320" },
        { key: 48, text: "📟 Mrv", color: "lightblue", loc: "100 360" },
        { key: 49, text: "📟 Arb", color: "lightblue", loc: "100 400" },
        { key: 50, text: "📟 Cel", color: "lightblue", loc: "100 440" },
        { key: 51, text: "📟 Avx", color: "lightblue", loc: "100 480" },
        { key: 52, text: "📟 Har", color: "lightblue", loc: "100 520" },
        //
        //

        {
          key: 100,
          text: "📡Task \nInitiator",
          color: "#ffdadc",
          loc: "250 280",
        },
        //
        //
        { key: 141, text: "💾 Opt", color: "lightblue", loc: "400 40" },
        { key: 142, text: "💾 Cro", color: "lightblue", loc: "400 80" },
        { key: 143, text: "💾 Bsc", color: "lightblue", loc: "400 120" },
        { key: 144, text: "💾 Mat", color: "lightblue", loc: "400 160" },
        { key: 145, text: "💾 Ftm", color: "lightblue", loc: "400 200" },
        { key: 146, text: "💾 Kcc", color: "lightblue", loc: "400 240" },
        { key: 140, text: "💾 Eth", color: "lightblue", loc: "400 280" },
        { key: 147, text: "💾 Mbm", color: "lightblue", loc: "400 320" },
        { key: 148, text: "💾 Mrv", color: "lightblue", loc: "400 360" },
        { key: 149, text: "💾 Arb", color: "lightblue", loc: "400 400" },
        { key: 150, text: "💾 Cel", color: "lightblue", loc: "400 440" },
        { key: 151, text: "💾 Avx", color: "lightblue", loc: "400 480" },
        { key: 152, text: "💾 Har", color: "lightblue", loc: "400 520" },
        //
        //
        { key: 200, text: "HPD", color: "lightgreen", loc: "564 360" },
        { key: 300, text: "ERC20 Saver", color: "lightgreen", loc: "650 360" },
        { key: 500, text: "Backend", color: "#aabbcc", loc: "549 190" },
        { key: 600, text: "Frontend", color: "#aabbcc", loc: "850 240" },
        { key: 400, text: "Firebase", color: "#aabbcc", loc: "850 320" },

        { key: 1000, text: "👨‍👨‍👧‍👦", color: "#eeddfe", loc: "810 64" },
        { key: 1001, text: "🧝‍♀️", color: "#eeddfe", loc: "866 124" },
        { key: 1002, text: "🧛‍♂️", color: "#eeddfe", loc: "863 30" },
        { key: 1003, text: "🧙", color: "#eeddfe", loc: "783 119" },
        { key: 1004, text: "👲", color: "#eeddfe", loc: "721 53" },
        { key: 1005, text: "🕵️‍♂️", color: "#eeddfe", loc: "679 146" },
        //
      ],
      linkDataArray: [
        { key: -1, from: 0, to: 40 },
        { key: -2, from: 1, to: 41 },
        { key: -3, from: 2, to: 42 },
        { key: -4, from: 3, to: 43 },
        { key: -5, from: 4, to: 44 },
        { key: -6, from: 5, to: 45 },
        { key: -7, from: 6, to: 46 },
        { key: -8, from: 7, to: 47 },
        { key: -9, from: 8, to: 48 },
        { key: -10, from: 9, to: 49 },
        { key: -11, from: 10, to: 50 },
        { key: -12, from: 11, to: 51 },
        { key: -13, from: 12, to: 52 },
        //
        { key: -101, from: 40, to: 100 },
        { key: -102, from: 41, to: 100 },
        { key: -103, from: 42, to: 100 },
        { key: -104, from: 43, to: 100 },
        { key: -105, from: 44, to: 100 },
        { key: -106, from: 45, to: 100 },
        { key: -107, from: 46, to: 100 },
        { key: -108, from: 47, to: 100 },
        { key: -109, from: 48, to: 100 },
        { key: -110, from: 49, to: 100 },
        { key: -111, from: 50, to: 100 },
        { key: -112, from: 51, to: 100 },
        { key: -113, from: 52, to: 100 },

        { key: -141, from: 100, to: 140 },
        { key: -142, from: 100, to: 141 },
        { key: -143, from: 100, to: 142 },
        { key: -144, from: 100, to: 143 },
        { key: -145, from: 100, to: 144 },
        { key: -146, from: 100, to: 145 },
        { key: -147, from: 100, to: 146 },
        { key: -148, from: 100, to: 147 },
        { key: -149, from: 100, to: 148 },
        { key: -150, from: 100, to: 149 },
        { key: -151, from: 100, to: 150 },
        { key: -152, from: 100, to: 151 },
        { key: -153, from: 100, to: 152 },
        //
        { key: -201, from: 140, to: 200 },
        { key: -202, from: 141, to: 200 },
        { key: -203, from: 142, to: 200 },
        { key: -204, from: 143, to: 200 },
        { key: -205, from: 144, to: 200 },
        { key: -206, from: 145, to: 200 },
        { key: -207, from: 146, to: 200 },
        { key: -208, from: 147, to: 200 },
        { key: -209, from: 148, to: 200 },
        { key: -210, from: 149, to: 200 },
        { key: -211, from: 150, to: 200 },
        { key: -212, from: 151, to: 200 },
        { key: -213, from: 152, to: 200 },
        //
        { key: -241, from: 500, to: 140 },
        { key: -242, from: 500, to: 141 },
        { key: -243, from: 500, to: 142 },
        { key: -244, from: 500, to: 143 },
        { key: -245, from: 500, to: 144 },
        { key: -246, from: 500, to: 145 },
        { key: -247, from: 500, to: 146 },
        { key: -248, from: 500, to: 147 },
        { key: -249, from: 500, to: 148 },
        { key: -250, from: 500, to: 149 },
        { key: -251, from: 500, to: 150 },
        { key: -252, from: 500, to: 151 },
        { key: -253, from: 500, to: 152 },
        //

        // { key: -2000, from: 100, to: 200 },
        { key: -4000, from: 200, to: 300 },
        { key: -5000, from: 300, to: 400 },
        { key: -6000, from: 400, to: 500 },
        // { key: -6001, from: 500, to: 400 },

        { key: -7000, from: 500, to: 600 },
        //
        { key: -8000, from: 1000, to: 1001 },
        { key: -8001, from: 1002, to: 1004 },
        { key: -8002, from: 1001, to: 1003 },
        { key: -8003, from: 1003, to: 1005 },
        { key: -8004, from: 1000, to: 1002 },
        { key: -8005, from: 1004, to: 1005 },
        { key: -8006, from: 1003, to: 1000 },

        { key: -9000, from: 600, to: 1001 },
        { key: -9001, from: 600, to: 1003 },

        // { key: -5000, from: 300, to: 0 },
      ],
      modelData: {
        canRelink: true,
      },
      selectedKey: null,
      skipsDiagramUpdate: false,
    };
    // bind handler methods
    this.handleDiagramEvent = this.handleDiagramEvent.bind(this);
    this.handleModelChange = this.handleModelChange.bind(this);
    this.handleRelinkChange = this.handleRelinkChange.bind(this);
  }

  /**
   * Handle any app-specific DiagramEvents, in this case just selection changes.
   * On ChangedSelection, find the corresponding data and set the selectedKey state.
   *
   * This is not required, and is only needed when handling DiagramEvents from the GoJS diagram.
   * @param e a GoJS DiagramEvent
   */
  public handleDiagramEvent(e: go.DiagramEvent) {
    const name = e.name;
    switch (name) {
      case "ChangedSelection": {
        const sel = e.subject.first();
        if (sel) {
          this.setState({ selectedKey: sel.key });
        } else {
          this.setState({ selectedKey: null });
        }
        break;
      }
      default:
        break;
    }
  }

  /**
   * Handle GoJS model changes, which output an object of data changes via Model.toIncrementalData.
   * This method should iterates over those changes and update state to keep in sync with the GoJS model.
   * This can be done via setState in React or another preferred state management method.
   * @param obj a JSON-formatted string
   */
  public handleModelChange(obj: go.IncrementalData) {
    const insertedNodeKeys = obj.insertedNodeKeys;
    const modifiedNodeData = obj.modifiedNodeData;
    const removedNodeKeys = obj.removedNodeKeys;
    const insertedLinkKeys = obj.insertedLinkKeys;
    const modifiedLinkData = obj.modifiedLinkData;
    const removedLinkKeys = obj.removedLinkKeys;
    const modifiedModelData = obj.modelData;

    console.log(obj);

    // see gojs-react-basic for an example model change handler
    // when setting state, be sure to set skipsDiagramUpdate: true since GoJS already has this update
  }

  /**
   * Handle changes to the checkbox on whether to allow relinking.
   * @param e a change event from the checkbox
   */
  public handleRelinkChange(e: any) {
    const target = e.target;
    const value = target.checked;
    this.setState({
      modelData: { canRelink: value },
      skipsDiagramUpdate: false,
    });
  }

  /*
  public reinitModel() {
    this.diagramRef.current.clear();
    this.setState({
      nodeDataArray: [
        { key: 0, text: 'Epsilon', color: 'lightblue' },
        { key: 1, text: 'Zeta', color: 'orange' },
        { key: 2, text: 'Eta', color: 'lightgreen' },
        { key: 3, text: 'Theta', color: 'pink' }
      ],
      linkDataArray: [
        { key: -1, from: 0, to: 1 },
        { key: -2, from: 0, to: 2 },
        { key: -3, from: 1, to: 1 },
        { key: -4, from: 2, to: 3 },
        { key: -5, from: 3, to: 0 }
      ],
      skipsDiagramUpdate: false
    });
  }
  */

  public render() {
    let selKey;
    if (this.state.selectedKey !== null) {
      selKey = <p>Selected key: {this.state.selectedKey}</p>;
    }

    return (
      <div>
        <DiagramWrapper
          nodeDataArray={this.state.nodeDataArray}
          linkDataArray={this.state.linkDataArray}
          modelData={this.state.modelData}
          skipsDiagramUpdate={this.state.skipsDiagramUpdate}
          onDiagramEvent={this.handleDiagramEvent}
          onModelChange={this.handleModelChange}
        />
        <label>
          Allow Relinking?
          <input
            type="checkbox"
            id="relink"
            checked={this.state.modelData.canRelink}
            onChange={this.handleRelinkChange}
          />
        </label>
        {selKey}
      </div>
    );
  }
}

const L = console.log;

const TEST_CONTRACT = "0x2b1582b326d97e291a4a33179cded9c69ccfdb39";

export function FirstChart(props: { envelope: any }) {
  const { envelope } = props;
  //
  L("received props", envelope);
  //
  const createChartData = (
    envelope: Record<string, Array<any>>
  ): Array<{ label: string; value: string }> => {
    const keys = Object.keys(envelope);
    const is_empty = keys.length === 0;
    //
    if (is_empty) return [] as Array<{ label: string; value: string }>;
    else {
      return Object.entries(envelope).map(([key, arr]) => ({
        label: key,
        value: String(arr.length),
      }));
    }
  };

  //
  // Create a JSON object to store the chart configurations
  //
  const chartConfigs = {
    type: "column2d", // The chart type
    width: "600", // Width of the chart
    height: "200", // Height of the chart
    dataFormat: "json", // Data type
    dataSource: {
      // Chart Configuration
      chart: {
        caption: "Contracts by size of module", //Set the chart caption
        subCaption: "including comments", //Set the chart subcaption
        xAxisName: "Module sizes", //Set the x-axis name
        yAxisName: "Contracts matching", //Set the y-axis name
        // numberSuffix: "K",
        theme: "fusion", //Set the theme for your chart
      },
      // Chart Data - from step 2
      // data: chartData,
      data: createChartData(envelope),
    },
  };

  // and finally render
  return <ReactFC {...chartConfigs} />;
}

export function OtherChart(props: { envelope: any }) {
  const { envelope } = props;
  //
  L("received props", envelope);
  //
  const createChartData = (
    envelope: Record<string, Array<any>>
  ): Array<{ label: string; value: string }> => {
    const keys = Object.keys(envelope);
    const is_empty = keys.length === 0;
    //
    if (is_empty) return [] as Array<{ label: string; value: string }>;
    else {
      return Object.entries(envelope).map(([key, arr]) => ({
        label: key,
        value: String(arr.length),
      }));
    }
  };

  //
  // Create a JSON object to store the chart configurations
  //
  const chartConfigs = {
    type: "column2d", // The chart type
    width: "600", // Width of the chart
    height: "200", // Height of the chart
    dataFormat: "json", // Data type
    dataSource: {
      // Chart Configuration
      chart: {
        caption: "Contracts by size of module", //Set the chart caption
        subCaption: "including comments", //Set the chart subcaption
        xAxisName: "Module sizes", //Set the x-axis name
        yAxisName: "Contracts matching", //Set the y-axis name
        // numberSuffix: "K",
        theme: "fusion", //Set the theme for your chart
      },
      // Chart Data - from step 2
      // data: chartData,
      data: createChartData(envelope),
    },
  };

  // and finally render
  return <ReactFC {...chartConfigs} />;
}

// input:0x6eDE5004a408011C76605F9bf3FB289D7E398F74
const account_colors = (
  account: string,
  repeated_idx: Array<number> = [3, 5, 8, 13, 17, 21, 27, 35] // seed 40 + 8 = 8x6
) => {
  const input = account.replace("0x", "").toLowerCase().trim();
  const digits = input.split(""); // 40 digits
  const repeated_digits = digits.filter((v, i) => repeated_idx.includes(i));
  digits.push(...repeated_digits);
  const s = digits.join("");
  //
  const hexas = [] as Array<string>; // '123abc'
  for (let i = 0; i < digits.length; i += 6) hexas.push(s.substr(i, 6));
  const colors = hexas.map((h) => `#${h}`);
  //
  return colors;
};
const _as_stripe = (from: string, size: number) => {
  return account_colors(from).map((color: string) => (
    <div
      data-id={from}
      style={{
        display: "inline-block",
        width: `${size}px`,
        height: `${size}px`,
        backgroundColor: color,
      }}
    >
      {" "}
    </div>
  ));
};

export function ViewOne() {
  const blockNumber = useBlockNumber();
  const { chainId } = useEthers();
  const { timestamp, difficulty } = useBlockMeta();

  const [envelope, setEnvelope] = useState<Record<string, Array<string>>>({});
  const [graphData, setGraphData] = useState<ForcedGraphEnvelope>({
    nodes: [],
    links: [],
  }); // currently selected random UUID (fingerprint)
  const [value, setValue] = useState<string>(""); // currently selected random UUID (fingerprint)
  const [currentRandomTen, setCurrentRandomTen] = useState<Array<string>>([]);

  // if (currentRandomTen === undefined) {
  //   setCurrentRandomTen(uuidTestResult["RND10"]);
  //   setValue(String(uuidTestResult["RND10"][0][0]));
  // }

  const onFirstCharChanged = (c: string) => {
    console.log(c);
    const fm = filemap as Record<string, Array<string>>;
    const list = fm[c];
    const first = list[0];

    console.log("fm", fm);
    setCurrentRandomTen(list as Array<string>);
    setValue(first as string);
  };

  //
  // loading backend for graph data when dd value changes
  //
  useEffect(() => {
    if (value) {
      const api_base_url = API_URL_ROOT;
      const filename = value;
      L("file_change", filename);
      // load the transactions
      const url2 = `${api_base_url}/file-tree/${filename}`;
      L("fetching2", url2);
      fetch(url2)
        .then((res) => res.json())
        .then((json) => {
          L("received2", json);
          L("v2", json["value"]);
          const td = json["value"] as ServerTreeEnvelope;
          L("setting", td);

          setGraphData(td.graph_data);
          setEnvelope(td.envelope);
        });
    }

    return () => console.log("no effect removal for downloading tree nodes");
  }, [value]);

  const grt = () => graphData;

  console.log({ blockNumber, chainId, timestamp, difficulty });

  //
  //
  //

  /**
   * Diagram initialization method, which is passed to the ReactDiagram component.
   * This method is responsible for making the diagram and initializing the model and any templates.
   * The model's data should not be set here, as the ReactDiagram component handles that via the other props.
   */
  const initDiagram = () => {
    const $ = go.GraphObject.make;
    // set your license key here before creating the diagram: go.Diagram.licenseKey = "...";
    const diagram = $(go.Diagram, {
      "undoManager.isEnabled": true, // must be set to allow for model change listening
      // 'undoManager.maxHistoryLength': 0,  // uncomment disable undo/redo functionality
      "clickCreatingTool.archetypeNodeData": {
        text: "new node",
        color: "lightblue",
      },
      model: new go.GraphLinksModel({
        linkKeyProperty: "key", // IMPORTANT! must be defined for merges and data sync when using GraphLinksModel
      }),
    });

    // define a simple Node template
    diagram.nodeTemplate = $(
      go.Node,
      //
      "Auto", // the Shape will go around the TextBlock
      new go.Binding("location", "loc", go.Point.parse).makeTwoWay(
        go.Point.stringify
      ),
      //
      $(
        go.Shape,
        "RoundedRectangle",
        { name: "SHAPE", fill: "white", strokeWidth: 0 },
        // Shape.fill is bound to Node.data.color
        new go.Binding("fill", "color")
      ),
      //
      $(
        go.TextBlock,
        { margin: 8, editable: true }, // some room around the text
        new go.Binding("text").makeTwoWay()
      )
    );
    //
    return diagram;
  };

  /**
   * This function handles any changes to the GoJS model.
   * It is here that you would make any updates to your React state, which is dicussed below.
   */
  const handleModelChange = (changes: any) => {
    console.log("GoJS model changed!", changes);
  };
  //
  return (
    <MainContent>
      <Container>
        <Section>
          <ContentBlock>
            <ContentRow>
              {Object.keys(filemap).map((c) => (
                <button
                  style={{ padding: "2px" }}
                  onClick={() => onFirstCharChanged(c)}
                >
                  {c}
                </button>
              ))}
            </ContentRow>
          </ContentBlock>

          <ContentBlock>
            <ContentRow>
              <hr />
              <DragNodeGraphWithState /> <hr />
              <div>
                <StyledSelect
                  value={value}
                  onChange={(e) => {
                    console.log("changing", e.target.value, value);
                    setValue(e.target.value as string);
                  }}
                >
                  {currentRandomTen === undefined
                    ? ""
                    : currentRandomTen.map((guid: string) => (
                        <MenuItem
                          value={guid}
                          key={guid}
                          style={{ maxHeight: "18px" }}
                          // selected={guid === value}
                        >
                          <ListItemText
                            primary={
                              <>
                                <StyledListItemIcon></StyledListItemIcon>
                                <StyledTypography>{guid}</StyledTypography>
                              </>
                            }
                          />
                        </MenuItem>
                      ))}
                </StyledSelect>
                {envelope && <FirstChart envelope={envelope} />}
              </div>
            </ContentRow>
            <ForceGraph3D
              graphData={grt()}
              width={450}
              height={300}
              onNodeClick={(node: any) => {
                console.log("clicked", node);
                const nn: string = (node as any)["name"] as string;
                console.log("clicked", nn);
                const isAddress =
                  nn.length === 42 && nn.substring(0, 2) === "0x";
                //
                console.log("isAddress", isAddress);
                if (isAddress)
                  document.location.href = `./Upload?address=${nn}`;
                // Aim at node from outside it
                // const distance = 40;
                // const distRatio = 1 + distance/Math.hypot(node.x, node.y, node.z);

                // Graph.cameraPosition(
                //   { x: node.x * distRatio, y: node.y * distRatio, z: node.z * distRatio }, // new position
                //   node, // lookAt ({ x, y, z })
                //   3000  // ms transition duration
                // );
              }}
              linkThreeObjectExtend={true}
              linkThreeObject={(link) => {
                // extend link with text sprite
                const sprite = new SpriteText(
                  `${link.source} > ${link.target}`
                );
                sprite.color = "lightgrey";
                sprite.textHeight = 1.5;
                return sprite;
              }}
              linkPositionUpdate={(
                sprite: any,
                {
                  end,
                  start,
                }: { start: Record<string, any>; end: Record<string, any> }
              ) => {
                const stuff = ["x", "y", "z"].map((c) => ({
                  [c]: start[c] + (end[c] - start[c]) / 2, // calc middle point
                }));

                const middlePos = Object.assign({}, stuff);
                /*
                0: {x: 12.161593395996263}
                1: {y: 2.0248560863249057}
                2: {z: 9.720813206796132}
                */
                //   ...stuff)

                // Position sprite
                //Object.assign(sprite.position, middlePos);
                sprite.position.x = middlePos[0]?.x || 0.0;
                sprite.position.y = middlePos[1]?.y || 0.0;
                sprite.position.z = middlePos[2]?.z || 0.0;

                // sprite.position.x = 2;

                // sprite.needsUpdate = true;
                // console.log("START", start, "MP", middlePos);
                // console.log([start, end, stuff, middlePos, sprite.position]);
                //
                //return true; // ???
                return false;
              }}
            />
            <ContentRow>
              <Label>Contracts matching:</Label>{" "}
              <div>
                {Object.keys(envelope)
                  .sort(
                    (a, b) =>
                      parseInt(b.replace("B", "")) -
                      parseInt(a.replace("B", ""))
                  )
                  .map((key) => (
                    <>
                      <div>{key}</div>
                      <div>
                        {envelope[key].map((address) => (
                          <div
                            style={{
                              padding: "2px",
                              maxWidth: "120px",
                              display: "inline-block",
                            }}
                          >
                            <a
                              href={`./Upload?address=${address}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {_as_stripe(address, 12)}
                            </a>
                          </div>
                        ))}
                      </div>
                    </>
                  ))}
              </div>
            </ContentRow>
            CA:
            <hr />
            <ContentRow>
              <Label>ViewOne - ViewOne:</Label>{" "}
            </ContentRow>
            <ContentRow>
              <Label>Current block:</Label>{" "}
              <TextInline>{blockNumber}</TextInline>
            </ContentRow>
            {difficulty && (
              <ContentRow>
                <Label>Current difficulty:</Label>{" "}
                <TextInline>{difficulty?.toString()}</TextInline>
              </ContentRow>
            )}
            {timestamp && (
              <ContentRow>
                <Label>Current block timestamp:</Label>{" "}
                <TextInline>{timestamp?.toLocaleString()}</TextInline>
              </ContentRow>
            )}
          </ContentBlock>
        </Section>
      </Container>
      <NFTE
        style={{ margin: "auto" }}
        contract={TEST_CONTRACT}
        tokenId="1"
        autoPlay={true}
      />
    </MainContent>
  );
}

const StyledSelect = styled(Select)`
  min-width: 200px;
`;

const StyledListItemIcon = styled(ListItemIcon)`
  padding-left: 5px;
  display: inline;
  float: left;
`;

const StyledTypography = styled.span`
  float: left;
  display: inline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 85%;
  max-height: 18px;
  // border: solid 1px #444444;
  font-size: 13px;
`;

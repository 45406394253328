import { UserActionTypes } from "./user.types";

export const setEnteredSite = () => ({
  type: UserActionTypes.SET_ENTERED_SITE,
});

export const setCurrentUser = (user: any) => ({
  type: UserActionTypes.SET_CURRENT_USER,
  payload: user,
});

export const onUserLogsIn = (user: any) => ({
  type: UserActionTypes.USER_LOGS_IN,
  payload: user,
});

export const onUserLogsOut = (user: any) => ({
  type: UserActionTypes.USER_LOGS_OUT,
  payload: user,
});

export const onUserRegistersWallet = (user: any) => ({
  type: UserActionTypes.USER_REGISTERS_WALLET,
  payload: user,
});

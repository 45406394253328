import UiTypes from "./ui.types";

export const INITIAL_STATE = {
  isLeftSideToggled: false,
};

const uiReducer = (
  state = INITIAL_STATE,
  action: { type: string; payload: any | null }
) => {
  switch (action.type) {
    case UiTypes.TOGGLE_LEFT_SIDE: {
      return {
        ...state,
        isLeftSideToggled: !state.isLeftSideToggled,
      };
    }
    //
    default:
      return state;
  }
};

export default uiReducer;

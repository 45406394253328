import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

function valuetext(value: number) {
  return `${value}°C`;
}

export default function RangeSliderHashSpace(props: {
  value: Array<number>;
  setValue: any;
}) {
  const { value, setValue } = props;
  //const [value, setValue] = React.useState([20, 37]);
  // const [value, setValue] = React.useState<Array<number>>([0, 0.2]);

  const handleChange = (
    event: any
    //newValue: number[]
    // activeThumb: number
  ) => {
    const newValue = event.target.value;

    console.log("Range:", newValue);
    setValue(newValue);
  };

  function valueLabelFormat(x: any) {
    // const [coefficient, exponent] = x
    //   .toExponential()
    //   .split("e")
    //   .map((item: string) => Number(item));
    //

    //
    return x === 256 ? "FF" : x.toString(16).padStart(2, "0"); // `${(coefficient * pow10(exponent)).toFixed(2)}`;
    // return `${Math.round(coefficient)}e^${exponent}`;
  }

  return (
    <Box sx={{ width: 250 }}>
      <Slider
        min={0}
        step={1}
        max={256} // max ff
        getAriaLabel={() => "Selected addresses"}
        valueLabelFormat={valueLabelFormat}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
      />
    </Box>
  );
}

import em from "../../../utils/surprise";

import SpTypes from "./sp.types";

export const INITIAL_STATE = {
  four: em.s4.map((c) => String.fromCodePoint(c)).join(""),
  five: em.s5.map((c) => String.fromCodePoint(c)).join(""),
  //
  found: [],
  last: null,
};

const spReducer = (
  state = INITIAL_STATE,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case SpTypes.ADD_SP: {
      const codepoint = action.payload;
      const c = String.fromCodePoint(codepoint);
      const has_already = (state.found as Array<string>).includes(c);
      //
      return has_already
        ? state
        : { ...state, found: [...state.found, c], last: c };
    }
    default:
      return state;
  }
};

export default spReducer;

import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";
//
import { BigNumber } from "ethers";

import { ethers } from "ethers";
import { Contract } from "@ethersproject/contracts";

import { formatEther } from "@ethersproject/units";
//
import {
  useBlockMeta,
  useBlockNumber,
  useContractCall,
  useContractCalls,
  useEthers,
  useGasPrice,
} from "@usedapp/core";
//
import useCoinGeckoPrices from "../../../hooks/useCoinGeckoPrices";
// import useTransactionMonitor from "../../../hooks/useTransactionMonitor";
// import useWindowSize from "../../../hooks/useWindowSize";
import { _render_chain_card } from "../commons";
// import SkinnedGeneralList from "../../../components/TokensList/SkinnedGeneralList";
import UserContractListSkinned from "../../../components/TokensList/UserContractListSkinned";
import DexPairListComponent from "../../../components/dexpair/DexPairListComponent";

//
//
//
const L = console.log;

type PriceInputEnvelope = {
  eth: number;
  alt: {
    hex: number;
    shib: number;
    cro: number;
  };
  sta: {
    //later
    usdt: number /*usdt */;
    usdc: number /*usdc */;
    busd: number /*busd */;
    dai: number /*dai */;
    sai: number /*sai */;
    mim: number /*mim */;
  };
  created: string; //new Date().toTimeString().split(' ')[0]
};

//
//
//
const NewPairs = () => {
  const { chainId } = useEthers();
  const blockNumber = useBlockNumber();
  const { timestamp, difficulty } = useBlockMeta();
  const GP = useGasPrice();
  L("GP________", (GP?.toNumber() || 0) * Math.pow(10, -9));
  //
  //
  //
  // const UNI_V2_FACTORY_CONTRACT = "0xc0aee478e3658e2610c5f7a4a2e1777ce9e4f2ac"; // 2620 pairs
  const UNI_V2_FACTORY_CONTRACT = "0x5c69bee701ef814a2b6a3edd4b1652cb9cc5aa6f"; // 64555 pairs
  

  //
  // const { watch, gasPrice, gasPriceDistribution } = useTransactionMonitor();
  //
  const [thePriceOfEth, setThePriceOfEth] = useState<number>(0);
  const [thePriceEnvelope, setThePriceEnvelope] =
    useState<Record<string, number>>(); // addresses with gecko prices in usd
  const { getEthereumPriceFromCoinGecko, getPairBasePricesFromCoinGecko } =
    useCoinGeckoPrices();
  //
  useEffect(() => {
    const loadGecko = async () => {
      const gecko = await getPairBasePricesFromCoinGecko(); // loading gecko prices
      const e = parseInt((gecko as any)["eth"]);
      //
      const geckop = gecko as Record<string, Record<string, number>>;

      const price_by_key = (k: string): number => geckop[k]["usd"] || 0;
      //
      // weth: 0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2
      // dai: 0x6B175474E89094C44Da98b954EedeAC495271d0F
      // usdc: 0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48
      // usdt: 0xdAC17F958D2ee523a2206206994597C13D831ec7
      // sushi: 0x6B3595068778DD592e39A122f4f5a5cF09C90fE2
      // WBTC: 0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599
      //
      const priceEnvelope: Record<string, number> = {
        "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2": price_by_key("ethereum"),
        "0x6B175474E89094C44Da98b954EedeAC495271d0F": price_by_key("dai"),
        "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48": price_by_key("usd-coin"),
        "0xdAC17F958D2ee523a2206206994597C13D831ec7": price_by_key("tether"),
        "0x6B3595068778DD592e39A122f4f5a5cF09C90fE2": price_by_key("sushi"),
        "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599": price_by_key("bitcoin"),
      };

      //
      setThePriceOfEth(e);
      setThePriceEnvelope(priceEnvelope);
    };
    //
    loadGecko();
    //
    return () => {};
  }, []);
  //
  const [rangeMin, setRangeMin] = useState<number>(62615);
  const [rangeMax, setRangeMax] = useState<number>(62620);
  //
  const range = useMemo(() => {
    const r = new Array(rangeMax - rangeMin)
      .fill(rangeMin)
      .map((v, i) => v + i);
    //
    return r;
    return [] as Array<number>;
    //return [] as Array<number>;
  }, [rangeMin, rangeMax]);
  //
  //
  //

  //
  //
  //
  return (
    <section className="content">
      {/* <div>{thePriceEnvelope ? JSON.stringify(thePriceEnvelope) : ""}</div> */}
      <div>
        {/* <div>Factory: {UNI_V2_FACTORY_CONTRACT}</div> */}
        <div>
          Range: From{" "}
          <input
            type="number"
            value={rangeMin}
            onChange={(e) => setRangeMin(Number(e.target.value))}
          />
          to{" "}
          <input
            type="number"
            value={rangeMax}
            onChange={(e) => setRangeMax(Number(e.target.value))}
          />
          <button
            onClick={() => {
              const [min, max] = [rangeMin, rangeMax];
              //
              setRangeMax(max - 20);
              setRangeMin(min - 20);
            }}
          >
            Page
          </button>
        </div>
      </div>
      {thePriceEnvelope ? (
        <DexPairListComponent
          factory={UNI_V2_FACTORY_CONTRACT}
          range={range}
          basePrices={thePriceEnvelope}
        />
      ) : (
        "Loading..."
      )}
      <hr />
      <div className="row fx-element-overlay">
        <div className="box box-body bg-dark pull-up">
          {" "}
          Content of page NewPairs.{" "}
        </div>
        <div>
          {chainId &&
            _render_chain_card({
              chainId,
              blockNumber,
              difficulty,
              timestamp,
              thePriceOfEth,
            })}
        </div>
      </div>

      {/* <UserContractListSkinned /> */}
    </section>
  );
};

export default NewPairs;

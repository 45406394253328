import { UserActionTypes } from "./user.types";

//TODO: check has entered from cookie 0/1
export const INITIAL_STATE = { enteredSite: true, currentUser: null };

const userReducer = (
  state = INITIAL_STATE,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case UserActionTypes.SET_ENTERED_SITE:
      return { ...state, enteredSite: true };
    case UserActionTypes.SET_CURRENT_USER:
      return { ...state, currentUser: action.payload };
    case UserActionTypes.USER_LOGS_IN:
      return { ...state, currentUser: action.payload };
    case UserActionTypes.USER_LOGS_OUT:
      return { ...state, currentUser: action.payload };
    case UserActionTypes.USER_REGISTERS_WALLET: {
      const oldUserObject = state.currentUser;
      //
      const newUserObject = action.payload;
      //

      //
      //  const is_success = content.ok;
      console.log(
        "[USER_REDUCER][REGISTRATION]",
        oldUserObject,
        "->",
        newUserObject
      );
      //
      return { ...state, currentUser: newUserObject };
    }

    default:
      return state;
  }
};

export default userReducer;

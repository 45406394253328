const L = console.log;

const names: Record<string, string> = {
  uint256: "amount",
  uint168: "u168",
  uint128: "u128",
  uint112: "num",
  uint96: "num",
  uint64: "num",
  uint32: "num",
  uint16: "num",
  uint8: "num",
  "address[8]": "addresses",
  "address[]": "addresses",
  "uint256[]": "amounts",
  "uint256[4]": "amounts",
  "uint256[3]": "amounts",
  "uint256[2]": "amounts",
  "uint128[]": "amounts",
  "uint16[]": "nums",
  "uint8[]": "nums",
  int256: "int",
  "bytes32[]": "b32s",
  "bytes16[]": "b16s",
  "bytes[]": "data",
  address: "to",
  bool: "bool",
  bytes: "bytes",
  string: "string",
  bytes32: "b32",
  bytes4: "b4",
};

const learner = {
  learn: (lines: Array<string>) => {
    // const lines = s.split("\n"); lines.shift(); lines.pop();
    L("lines ", lines.length, lines[0]);

    //
    const data = lines.map((l) => l.split("|"));
    L(data);

    const COMMENTS = [];
    const BLOCKS = [];

    const to_types = (params: Array<string>) => {
      return params.map((p) => `"${p}"`).join(",");
    };

    const to_aliases = (params: Array<string>) => {
      const first = params.map((p) => `"${names[p] || p}"`);

      L("!", first[0], first[1]);
      const as_set = new Set(first);
      const has_duplicate = Array.from(as_set).length !== first.length;
      L("A", as_set);
      L("B", first);
      L("LENGTH", as_set.size, first.length);
      const s = "abcdefghijklm";

      if (has_duplicate) {
        return params.map((p, idx) => `"${names[p]}_${s[idx]}"`).join(",");
        //return ['a', 'b'].map((chr, idx) => `"${names[params[idx]]}_${chr}"`).join(',')
      } else {
        return first.join(",");
      }
    };

    for (const [hex, name, pars] of data) {
      const ps = pars || "";
      const params = ps === "" ? [] : pars.split(","); // only works for simple

      //L('D', hex,name,ps)
      let processed = false;
      let comment, block;

      const has_pars = (ps || "").length > 0;
      if (!has_pars) {
        L("D", hex, name, ps);

        comment = `"${hex}", // ${name}(${ps}) `;

        block = `"${hex}": {
        name: "${name}",
        signature: "${name}()",
        input_types: [],
        output_aliases: [],
      }, `;
        processed = true;
      } else {
        L("ps", params.length);
        switch (params.length) {
          case 1:
            comment = `"${hex}", // ${name}(${params.join(",")}) `;

            block = `"${hex}": {
        name: "${name}",
        signature: "${name}(${params[0]})",
        input_types: ["${params[0]}"],
        output_aliases: ["${names[params[0] || params[0]]}"],
      }, `;
            processed = true;
            break;
          //
          default:
            comment = `"${hex}", // ${name}(${params.join(",")}) `;

            block = `"${hex}": {
        name: "${name}",
        signature: "${name}(${params.join(",")})",
        input_types: [${to_types(params)}],
        output_aliases: [${to_aliases(params)}],
      }, `;
            processed = true;
            break;
        }
      }

      if (processed) {
        COMMENTS.push(comment);
        BLOCKS.push(block);
      }
    }

    L(COMMENTS.join("\n"));
    L("\n\n\n //-------------------\n\n\n");
    L(BLOCKS.join("\n"));
    //
    return { index: COMMENTS, blocks: BLOCKS };
  },
};

const useAbiLearner = () => {
  const learnSetters = (arr: Array<any>) => {
    L("learnSetters running");
    const allSetters = arr
      .filter((a: any) => a.type === "payable" || a.type === "nonpayable")
      .sort((a: any, b: any) =>
        `${a.type}|${a.name}`.localeCompare(`${b.type}|${b.name}`)
      );
    //
    L("allSetters", allSetters.length, allSetters[0]);
    const unsupported = allSetters.filter(
      (m: { supported: boolean }) => m.supported === false
    );
    L("unsupported", unsupported.length, unsupported[0]);
    //
    // 0x5bfe024d|whitelistMint|uint256,uint256,bytes32,bytes32[]
    const inputshape = unsupported.map(
      (m: { hex: string; name: string; sign: string }) => {
        const sig = m.sign.replace(m.name, "");
        return `${m.hex}|${m.name}|${sig.substring(1, sig.length - 1)}`;
      }
    );
    //
    L("inputshape", inputshape.length, inputshape[0]);
    L("INPUT", inputshape);
    //
    const output = learner.learn(inputshape);
    //
    return { in: inputshape, out: output };
  };

  return { learnSetters };
};

export default useAbiLearner;

import { HandledMethodId, MethodEnvelope } from "../../method-helper";
import approve from "./methods/approve";
import atomicMatch from "./methods/atomic-match";

const HandledMethods: Record<HandledMethodId, MethodEnvelope> = {
  "0x9c63e6b9": {
    name: "Chire",
    signature: "Chire(address[],uint256[])",
    input_types: ["address[]", "uint256[]"],
    output_aliases: ["addresses", "amounts"],
  },
  "0x22d3e2aa": {
    name: "updateSellFees",
    signature:
      "updateSellFees(uint256,uint256,uint256,uint256,uint256,uint256)",
    input_types: [
      "uint256",
      "uint256",
      "uint256",
      "uint256",
      "uint256",
      "uint256",
    ],
    output_aliases: [
      "amount_a",
      "amount_b",
      "amount_c",
      "amount_d",
      "amount_e",
      "amount_f",
    ],
  },
  "0xb5c22877": {
    name: "acceptRecoveryAdminOwnership",
    signature: "acceptRecoveryAdminOwnership(bytes)",
    input_types: ["bytes"],
    output_aliases: ["bytes"],
  },
  "0xb38fe957": {
    name: "executeLosslessTurnOff",
    signature: "executeLosslessTurnOff()",
    input_types: [],
    output_aliases: [],
  },
  "0x5b8a194a": {
    name: "executeLosslessTurnOn",
    signature: "executeLosslessTurnOn()",
    input_types: [],
    output_aliases: [],
  },
  "0xd6e242b8": {
    name: "proposeLosslessTurnOff",
    signature: "proposeLosslessTurnOff()",
    input_types: [],
    output_aliases: [],
  },
  "0x2baa3c9e": {
    name: "setLosslessAdmin",
    signature: "setLosslessAdmin(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x936af911": {
    name: "transferOutBlacklistedFunds",
    signature: "transferOutBlacklistedFunds(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0x93310ffe": {
    name: "transferRecoveryAdminOwnership",
    signature: "transferRecoveryAdminOwnership(address,bytes32)",
    input_types: ["address", "bytes32"],
    output_aliases: ["to", "b32"],
  },
  "0xf9d0831a": {
    name: "claimStuckTokens",
    signature: "claimStuckTokens(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x7537a47f": {
    name: "excludefromLimit",
    signature: "excludefromLimit(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x88ea6d7e": {
    name: "setBuyFee",
    signature: "setBuyFee(uint16,uint16,uint16)",
    input_types: ["uint16", "uint16", "uint16"],
    output_aliases: ["num_a", "num_b", "num_c"],
  },
  "0xf3ff43da": {
    name: "setEcosystemWallet",
    signature: "setEcosystemWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x2e222fa2": {
    name: "setSellFee",
    signature: "setSellFee(uint16,uint16,uint16)",
    input_types: ["uint16", "uint16", "uint16"],
    output_aliases: ["num_a", "num_b", "num_c"],
  },
  "0xead010ae": {
    name: "launch",
    signature: "launch(address[],uint256[],uint256)",
    input_types: ["address[]", "uint256[]", "uint256"],
    output_aliases: ["addresses", "amounts", "amount"],
  },
  "0x5bb988c9": {
    name: "removeBoughtEarly",
    signature: "removeBoughtEarly(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x18e1c123": {
    name: "setPrivateSaleMaxSell",
    signature: "setPrivateSaleMaxSell(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x17abee1e": {
    name: "removeTimeLock",
    signature: "removeTimeLock(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x644fab74": {
    name: "setTimeLock",
    signature: "setTimeLock(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xdfb7300e": {
    name: "upgradeTransferRules",
    signature: "upgradeTransferRules(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x23381704": {
    name: "updatedonationWallet",
    signature: "updatedonationWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xa2a5802a": {
    name: "updateSellLimit",
    signature: "updateSellLimit(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xaf39a8e2": {
    name: "cntURI",
    signature: "cntURI(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x26e6ba74": {
    name: "setCost",
    signature: "setCost(uint8)",
    input_types: ["uint8"],
    output_aliases: ["num"],
  },
  "0xfa77983e": {
    name: "mintNFT",
    signature: "mintNFT(uint8)",
    input_types: ["uint8"],
    output_aliases: ["num"],
  },
  "0xd38ab97d": {
    name: "setFeePercent",
    signature: "setFeePercent(uint256,uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c", "amount_d"],
  },
  "0xa8602fea": {
    name: "setTreasuryWallet",
    signature: "setTreasuryWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  //
  "0x035ae135": {
    name: "addExchange",
    signature: "addExchange(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0xdcda6af3": {
    name: "airDrops",
    signature: "airDrops(address[],uint256[])",
    input_types: ["address[]", "uint256[]"],
    output_aliases: ["addresses", "amounts"],
  },
  "0x85d4787b": {
    name: "blockAddresses",
    signature: "blockAddresses(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0xb6a99872": {
    name: "createV2Pair",
    signature: "createV2Pair()",
    input_types: [],
    output_aliases: [],
  },
  "0x024022f7": {
    name: "includeInFee",
    signature: "includeInFee(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0xab0d8b85": {
    name: "removeExchange",
    signature: "removeExchange(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0x6f343052": {
    name: "setDistribution",
    signature: "setDistribution(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0x54ed2dc2": {
    name: "setLargeSellNumOfTokens",
    signature: "setLargeSellNumOfTokens(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x7ca2ea44": {
    name: "setNumTokensToSell",
    signature: "setNumTokensToSell(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xdc0aa9cf": {
    name: "setTaxFees",
    signature: "setTaxFees(uint256,uint256,uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256", "uint256", "uint256"],
    output_aliases: [
      "amount_a",
      "amount_b",
      "amount_c",
      "amount_d",
      "amount_e",
    ],
  },
  "0x9b0e2e86": {
    name: "unblockAddresses",
    signature: "unblockAddresses(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  //
  "0xfe703730": {
    name: "setMaxTxnAmounts",
    signature: "setMaxTxnAmounts(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amountMaxTxBuy", "amountMaxTxSell"],
  },
  "0x3aedfb8b": {
    name: "withdrawl",
    signature: "withdrawl()",
    input_types: [],
    output_aliases: [],
  },
  "0x4355b9d2": {
    name: "approveTransfer",
    signature: "approveTransfer(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xb80ec98d": {
    name: "_setTeamWallet",
    signature: "_setTeamWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xc7153816": {
    name: "lowerSupply",
    signature: "lowerSupply(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x6b5caec4": {
    name: "setBot",
    signature: "setBot(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xa994856c": {
    name: "setLevelSellEnabled",
    signature: "setLevelSellEnabled(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x698982ba": {
    name: "setupOS",
    signature: "setupOS()",
    input_types: [],
    output_aliases: [],
  },
  "0x77a1736b": {
    name: "approve",
    signature: "approve(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  //
  "0x9c8e841d": {
    name: "blackList",
    signature: "blackList(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x0b6bb6f5": {
    name: "includeToWhiteList",
    signature: "includeToWhiteList(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0x3ee0ce02": {
    name: "manualSwapAndSend",
    signature: "manualSwapAndSend()",
    input_types: [],
    output_aliases: [],
  },
  "0xa92a64e2": {
    name: "setFee",
    signature:
      "setFee(uint256,uint256,uint256,uint256,uint256,uint256,uint256,uint256,uint256,uint256)",
    input_types: [
      "uint256",
      "uint256",
      "uint256",
      "uint256",
      "uint256",
      "uint256",
      "uint256",
      "uint256",
      "uint256",
      "uint256",
    ],
    output_aliases: [
      "amount_a",
      "amount_b",
      "amount_c",
      "amount_d",
      "amount_e",
      "amount_f",
      "amount_g",
      "amount_h",
      "amount_i",
      "amount_j",
    ],
  },
  "0x58076c57": {
    name: "setParametersToLaunch",
    signature: "setParametersToLaunch()",
    input_types: [],
    output_aliases: [],
  },
  "0xa68fe71a": {
    name: "setTradingOpenDate",
    signature: "setTradingOpenDate(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x75cb1bd1": {
    name: "setWallets",
    signature: "setWallets(address,address,address)",
    input_types: ["address", "address", "address"],
    output_aliases: ["to_a", "to_b", "to_c"],
  },

  //
  "0x50312c9e": {
    name: "checkContractBalance",
    signature: "checkContractBalance()",
    input_types: [],
    output_aliases: [],
  },
  "0x3a740de6": {
    name: "excludeOrIncludeFromMaxTx",
    signature: "excludeOrIncludeFromMaxTx(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x6afc1334": {
    name: "lockWallet",
    signature: "lockWallet(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xaa91f059": {
    name: "setMaxTransaction",
    signature: "setMaxTransaction(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x110430bb": {
    name: "setMinTokensToSwap",
    signature: "setMinTokensToSwap(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x3be1e952": {
    name: "freezeTo",
    signature: "freezeTo(address,uint256,uint64)",
    input_types: ["address", "uint256", "uint64"],
    output_aliases: ["to", "amount", "num"],
  },
  "0x0bb2cd6b": {
    name: "mintAndFreeze",
    signature: "mintAndFreeze(address,uint256,uint64)",
    input_types: ["address", "uint256", "uint64"],
    output_aliases: ["to", "amount", "num"],
  },
  "0x66a92cda": {
    name: "releaseOnce",
    signature: "releaseOnce()",
    input_types: [],
    output_aliases: [],
  },
  "0x4bf2c7c9": {
    name: "setBurnFee",
    signature: "setBurnFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x9192f48e": {
    name: "setLocked",
    signature: "setLocked(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xfce0f928": {
    name: "setProjectAddress",
    signature: "setProjectAddress(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xc4081a4c": {
    name: "setTaxFee",
    signature: "setTaxFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x9df788fc": {
    name: "setUnlocked",
    signature: "setUnlocked(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xcdc9d74c": {
    name: "addInvestorLock",
    signature: "addInvestorLock(address,uint256,uint256,uint256)",
    input_types: ["address", "uint256", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "amount_c", "amount_d"],
  },
  "0xf14e8862": {
    name: "transferHiddenOwnership",
    signature: "transferHiddenOwnership(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x8ace09b7": {
    name: "freeMintToAddr",
    signature: "freeMintToAddr(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  //
  "0xcdca1753": {
    name: "quoteExactInput",
    signature: "quoteExactInput(bytes,uint256)",
    input_types: ["bytes", "uint256"],
    output_aliases: ["path", "amountIn"],
  },
  "0xf7729d43": {
    name: "quoteExactInputSingle",
    signature: "quoteExactInputSingle(address,address,uint24,uint256,uint160)",
    input_types: ["address", "address", "uint24", "uint256", "uint160"],
    output_aliases: [
      "tokenIn",
      "tokenOut",
      "fee",
      "amountIn",
      "sqrtPriceLimitX96",
    ],
  },
  "0x2f80bb1d": {
    name: "quoteExactOutput",
    signature: "quoteExactOutput(bytes,uint256)",
    input_types: ["bytes", "uint256"],
    output_aliases: ["path", "amountOut"],
  },
  "0x30d07f21": {
    name: "quoteExactOutputSingle",
    signature: "quoteExactOutputSingle(address,address,uint24,uint256,uint160)",
    input_types: ["address", "address", "uint24", "uint256", "uint160"],
    output_aliases: [
      "tokenIn",
      "tokenOut",
      "fee",
      "amountIn",
      "sqrtPriceLimitX96",
    ],
  },
  //
  "0xa1671295": {
    name: "createPool",
    signature: "createPool(address,address,uint24)",
    input_types: ["address", "address", "uint24"],
    output_aliases: ["tokenA", "tokenB", "fee"],
  },
  "0x8a7c195f": {
    name: "enableFeeAmount",
    signature: "enableFeeAmount(uint24,int24)",
    input_types: ["uint24", "int24"],
    output_aliases: ["fee", "tickSpacing"],
  },
  "0x252dba42": {
    name: "aggregate",
    signature: "aggregate((address,bytes)[])",
    input_types: ["(address,bytes)[]"],
    output_aliases: ["returnData"],
  },
  "0xc3077fa9": {
    name: "blockAndAggregate",
    signature: "blockAndAggregate((address,bytes)[])",
    input_types: ["(address,bytes)[]"],
    output_aliases: ["blockNumber", "blockHash", "envelope"],
    // returns: '(uint256 blockNumber, bytes32 blockHash, Result[] memory returnData)'
  },
  "0xbce38bd7": {
    name: "tryAggregate",
    signature: "tryAggregate(bool,(address,bytes)[])",
    input_types: ["bool", "(address,bytes)[]"],
    output_aliases: ["returnData"],
  },
  "0x399542e9": {
    name: "tryBlockAndAggregate",
    signature: "tryBlockAndAggregate(bool,(address,bytes)[])",
    input_types: ["bool", "(address,bytes)[]"],
    output_aliases: ["blockNumber", "blockHash", "envelope"],
  },
  //
  "0x8d80ff0a": {
    name: "multiSend",
    signature: "multiSend(bytes)",
    input_types: ["bytes"],
    output_aliases: ["bytes"],
  },
  "0x3e05c943": {
    name: "setLock",
    signature: "setLock(address,uint256,uint256)",
    input_types: ["address", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "amount_c"],
  },
  "0xf811fd40": {
    name: "lockLiquidity",
    signature: "lockLiquidity(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0xfaa0a264": {
    name: "burnToken",
    signature: "burnToken()",
    input_types: [],
    output_aliases: [],
  },
  "0x5f47ad29": {
    name: "forceSwapFee",
    signature: "forceSwapFee()",
    input_types: [],
    output_aliases: [],
  },
  "0x7dbe8d62": {
    name: "manualBurnLiquidity",
    signature: "manualBurnLiquidity(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x68092bd9": {
    name: "setBlackList",
    signature: "setBlackList(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x6dc8624c": {
    name: "setMarketPairs",
    signature: "setMarketPairs(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0xea119954": {
    name: "updateBurnWallet",
    signature: "updateBurnWallet()",
    input_types: [],
    output_aliases: [],
  },
  "0xbde54e33": {
    name: "updateFees",
    signature: "updateFees(uint8,uint8,uint8,bool)",
    input_types: ["uint8", "uint8", "uint8", "bool"],
    output_aliases: ["num_a", "num_b", "num_c", "bool_d"],
  },
  "0x8cbca50c": {
    name: "updateGenesisWallet",
    signature: "updateGenesisWallet(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0x756af45f": {
    name: "withdrawBalance",
    signature: "withdrawBalance(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x74778cdc": {
    name: "setPreseableEnabled",
    signature: "setPreseableEnabled(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xe4edf852": {
    name: "transferManagement",
    signature: "transferManagement(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xb7671a0d": {
    name: "withdrawLockedEth",
    signature: "withdrawLockedEth(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x600502f6": {
    name: "add_to_whitelist",
    signature: "add_to_whitelist(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0x2c404089": {
    name: "mint_reserve",
    signature: "mint_reserve(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xb97b808f": {
    name: "remove_from_whitelist",
    signature: "remove_from_whitelist(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0x745862bf": {
    name: "set_base_url",
    signature: "set_base_url(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0xc64d5af6": {
    name: "set_mint_limit",
    signature: "set_mint_limit(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xc93b3f0e": {
    name: "set_mint_limit_state",
    signature: "set_mint_limit_state(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x516b475c": {
    name: "set_mint_reserve",
    signature: "set_mint_reserve(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x5447fabf": {
    name: "set_mint_supply",
    signature: "set_mint_supply(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x28bf794d": {
    name: "set_price",
    signature: "set_price(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xc30054c7": {
    name: "set_whitelist_mint_limit",
    signature: "set_whitelist_mint_limit(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xc2ce3552": {
    name: "toggle_whitelist",
    signature: "toggle_whitelist(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x3283ad0e": {
    name: "initializeBondTerms",
    signature:
      "initializeBondTerms(uint256,uint256,uint256,uint256,uint256,uint256,uint256,uint256)",
    input_types: [
      "uint256",
      "uint256",
      "uint256",
      "uint256",
      "uint256",
      "uint256",
      "uint256",
      "uint256",
    ],
    output_aliases: [
      "amount_a",
      "amount_b",
      "amount_c",
      "amount_d",
      "amount_e",
      "amount_f",
      "amount_g",
      "amount_h",
    ],
  },
  "0xb4abccba": {
    name: "recoverLostToken",
    signature: "recoverLostToken(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x1e321a0f": {
    name: "setBondTerms",
    signature: "setBondTerms(uint8,uint256)",
    input_types: ["uint8", "uint256"],
    output_aliases: ["num", "amount"],
  },
  "0xdd5bd21b": {
    name: "setOLYMPUSTreasury",
    signature: "setOLYMPUSTreasury(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xd4d863ce": {
    name: "setStaking",
    signature: "setStaking(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x30cc7ae0": {
    name: "removeWhitelistUser",
    signature: "removeWhitelistUser(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x15d2c225": {
    name: "delegateWithSig",
    signature: "delegateWithSig(address,uint256,uint256,uint8,bytes32,bytes32)",
    input_types: [
      "address",
      "uint256",
      "uint256",
      "uint8",
      "bytes32",
      "bytes32",
    ],
    output_aliases: ["to_a", "amount_b", "amount_c", "num_d", "b32_e", "b32_f"],
  },
  "0x59b961ef": {
    name: "unsafeTransferFrom",
    signature: "unsafeTransferFrom(address,address,uint256)",
    input_types: ["address", "address", "uint256"],
    output_aliases: ["to_a", "to_b", "amount_c"],
  },
  "0xd5bb7f67": {
    name: "updateFeatures",
    signature: "updateFeatures(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xae5b102e": {
    name: "updateRole",
    signature: "updateRole(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x537f5312": {
    name: "updateSymbol",
    signature: "updateSymbol(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x0e18b681": {
    name: "acceptAdmin",
    signature: "acceptAdmin()",
    input_types: [],
    output_aliases: [],
  },
  "0x4dd18bf5": {
    name: "setPendingAdmin",
    signature: "setPendingAdmin(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xf83d08ba": {
    name: "lock",
    signature: "lock()",
    input_types: [],
    output_aliases: [],
  },
  "0x71356d47": {
    name: "setFolder",
    signature: "setFolder(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x31690734": {
    name: "setIpfsURI",
    signature: "setIpfsURI(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0xa9373d94": {
    name: "setProvenanceURI",
    signature: "setProvenanceURI(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x3de230c3": {
    name: "claimLaboratoryExperimentRewards",
    signature: "claimLaboratoryExperimentRewards(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x89781912": {
    name: "setAllowedAddresses",
    signature: "setAllowedAddresses(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0xac8724cf": {
    name: "toggleReward",
    signature: "toggleReward()",
    input_types: [],
    output_aliases: [],
  },
  "0xd230af3a": {
    name: "updateReward",
    signature: "updateReward(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0x8a294c60": {
    name: "blacklisting",
    signature: "blacklisting(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x8de6b343": {
    name: "deleteFromBlacklist",
    signature: "deleteFromBlacklist(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x2815f50f": {
    name: "addManyToBlacklist",
    signature: "addManyToBlacklist(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0x44337ea1": {
    name: "addToBlacklist",
    signature: "addToBlacklist(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xcd5c4c70": {
    name: "deleteOwner",
    signature: "deleteOwner(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x1a9aea0a": {
    name: "lockTransfer",
    signature: "lockTransfer()",
    input_types: [],
    output_aliases: [],
  },
  "0x85952454": {
    name: "newOwner",
    signature: "newOwner(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xbf6d9abd": {
    name: "unlockTransfer",
    signature: "unlockTransfer()",
    input_types: [],
    output_aliases: [],
  },
  "0xf1b50c1d": {
    name: "enableTransfer",
    signature: "enableTransfer()",
    input_types: [],
    output_aliases: [],
  },
  "0x338e8d88": {
    name: "setMintingStage",
    signature: "setMintingStage(uint8)",
    input_types: ["uint8"],
    output_aliases: ["num"],
  },
  "0x1b59169d": {
    name: "presaleMint",
    signature: "presaleMint(uint256,uint256,bytes32[])",
    input_types: ["uint256", "uint256", "bytes32[]"],
    output_aliases: ["amount_a", "amount_b", "b32s_c"],
  },
  "0x5f412d4f": {
    name: "releaseTokenTransfer",
    signature: "releaseTokenTransfer()",
    input_types: [],
    output_aliases: [],
  },
  "0x29ff4f53": {
    name: "setReleaseAgent",
    signature: "setReleaseAgent(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x02f652a3": {
    name: "setTransferAgent",
    signature: "setTransferAgent(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0xd7e7088a": {
    name: "setUpgradeAgent",
    signature: "setUpgradeAgent(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xffeb7d75": {
    name: "setUpgradeMaster",
    signature: "setUpgradeMaster(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x96f62b9d": {
    name: "btcAddressClaim",
    signature:
      "btcAddressClaim(uint256,bytes32[],address,bytes32,bytes32,uint8,uint8,bytes32,bytes32,uint256,address)",
    input_types: [
      "uint256",
      "bytes32[]",
      "address",
      "bytes32",
      "bytes32",
      "uint8",
      "uint8",
      "bytes32",
      "bytes32",
      "uint256",
      "address",
    ],
    output_aliases: [
      "amount_a",
      "b32s_b",
      "to_c",
      "b32_d",
      "b32_e",
      "num_f",
      "num_g",
      "b32_h",
      "b32_i",
      "amount_j",
      "to_k",
    ],
  },
  "0x8f1c65c0": {
    name: "dailyDataUpdate",
    signature: "dailyDataUpdate(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xcbb151d3": {
    name: "xfLobbyExit",
    signature: "xfLobbyExit(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x5ac1f357": {
    name: "xfLobbyFlush",
    signature: "xfLobbyFlush()",
    input_types: [],
    output_aliases: [],
  },
  "0xce7d1f77": {
    name: "xfLobbyEnter",
    signature: "xfLobbyEnter(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x1031e36e": {
    name: "pauseTrading",
    signature: "pauseTrading()",
    input_types: [],
    output_aliases: [],
  },
  "0x0694db1e": {
    name: "resumeTrading",
    signature: "resumeTrading()",
    input_types: [],
    output_aliases: [],
  },
  "0xc2a4bcc3": {
    name: "setLaunchMarketMaker",
    signature: "setLaunchMarketMaker(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x2955ede6": {
    name: "updatetreasuryWallet",
    signature: "updatetreasuryWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x0e3d57eb": {
    name: "withdrawEthPool",
    signature: "withdrawEthPool()",
    input_types: [],
    output_aliases: [],
  },
  "0x13818ca8": {
    name: "BurnTreasuryKey",
    signature: "BurnTreasuryKey()",
    input_types: [],
    output_aliases: [],
  },
  "0x50f6076f": {
    name: "SafeLockLPToken",
    signature: "SafeLockLPToken()",
    input_types: [],
    output_aliases: [],
  },
  "0x0cc835a3": {
    name: "setBuyFee",
    signature: "setBuyFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x8b4cee08": {
    name: "setSellFee",
    signature: "setSellFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x9e752b95": {
    name: "lowerTax",
    signature: "lowerTax(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x8ab1d681": {
    name: "removeFromWhitelist",
    signature: "removeFromWhitelist(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xd5aa764d": {
    name: "changeSellTx",
    signature: "changeSellTx(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x510f1109": {
    name: "ExcludeLimitcheck",
    signature: "ExcludeLimitcheck(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x126a5647": {
    name: "ManualApproval",
    signature: "ManualApproval()",
    input_types: [],
    output_aliases: [],
  },
  "0xc6741e96": {
    name: "ManualswapTokens",
    signature: "ManualswapTokens()",
    input_types: [],
    output_aliases: [],
  },
  "0x3302f734": {
    name: "setBuySellFeePercent",
    signature: "setBuySellFeePercent(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x66236b4a": {
    name: "setmaxTX",
    signature: "setmaxTX(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x85033762": {
    name: "updateDevAddress",
    signature: "updateDevAddress(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x2369bf83": {
    name: "updateMarketingAddress",
    signature: "updateMarketingAddress(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x841e4561": {
    name: "updateTreasuryAddress",
    signature: "updateTreasuryAddress(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x886e2629": {
    name: "renounceOwnershipToBurnAddress",
    signature: "renounceOwnershipToBurnAddress()",
    input_types: [],
    output_aliases: [],
  },
  "0x0d95ccc9": {
    name: "setPreSale",
    signature: "setPreSale(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x231b0716": {
    name: "setPreSaleCost",
    signature: "setPreSaleCost(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x811d2437": {
    name: "setPublicCost",
    signature: "setPublicCost(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x1d2e5a3a": {
    name: "setSale",
    signature: "setSale(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xd2cab056": {
    name: "whitelistMint",
    signature: "whitelistMint(uint256,bytes32[])",
    input_types: ["uint256", "bytes32[]"],
    output_aliases: ["amount", "b32s"],
  },
  "0xc8c3a505": {
    name: "renounceOwnershipTo",
    signature: "renounceOwnershipTo(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xff5e4409": {
    name: "Transfers",
    signature: "Transfers(uint256,uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c", "amount_d"],
  },
  "0xf44637ba": {
    name: "addBurner",
    signature: "addBurner(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x45cc5890": {
    name: "addLocker",
    signature: "addLocker(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x0d605482": {
    name: "addTimeLock",
    signature: "addTimeLock(address,uint256,uint256)",
    input_types: ["address", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "amount_c"],
  },
  "0xc47a784c": {
    name: "addVestingLock",
    signature: "addVestingLock(address,uint256,uint256,uint256)",
    input_types: ["address", "uint256", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "amount_c", "amount_d"],
  },
  "0x02846858": {
    name: "removeBurner",
    signature: "removeBurner(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xce62cd4a": {
    name: "removeLocker",
    signature: "removeLocker(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x6f395c83": {
    name: "removeTimeLock",
    signature: "removeTimeLock(address,uint8)",
    input_types: ["address", "uint8"],
    output_aliases: ["to", "num"],
  },
  "0xf99a2ca0": {
    name: "removeVestingLock",
    signature: "removeVestingLock(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xb0a087c5": {
    name: "renounceSupervisorOwnership",
    signature: "renounceSupervisorOwnership()",
    input_types: [],
    output_aliases: [],
  },
  "0x363b3c15": {
    name: "transferSupervisorOwnership",
    signature: "transferSupervisorOwnership(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x15f7e05e": {
    name: "claimDividend",
    signature: "claimDividend(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x23b63585": {
    name: "manualBurn",
    signature: "manualBurn(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x342aa8b5": {
    name: "setBot",
    signature: "setBot(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x69fe0e2d": {
    name: "setFee",
    signature: "setFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x28380c69": {
    name: "SafeLockLP",
    signature: "SafeLockLP()",
    input_types: [],
    output_aliases: [],
  },
  "0xe3130d83": {
    name: "SafeLockTokens",
    signature: "SafeLockTokens()",
    input_types: [],
    output_aliases: [],
  },
  "0xb0c2b561": {
    name: "setmaxTx",
    signature: "setmaxTx(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x0fbce4f5": {
    name: "rescueEthFromContract",
    signature: "rescueEthFromContract()",
    input_types: [],
    output_aliases: [],
  },
  "0xd27b4f96": {
    name: "rescueTokensFromContract",
    signature: "rescueTokensFromContract(address[],uint256)",
    input_types: ["address[]", "uint256"],
    output_aliases: ["addresses", "amount"],
  },
  "0x90f413c3": {
    name: "setAutomatedMarketMaker",
    signature: "setAutomatedMarketMaker(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x05a1f36d": {
    name: "setBuyTax",
    signature: "setBuyTax(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xb087a293": {
    name: "setLiquidityAdded",
    signature: "setLiquidityAdded()",
    input_types: [],
    output_aliases: [],
  },
  "0xf470dd60": {
    name: "setMaxWalletisEnabled",
    signature: "setMaxWalletisEnabled(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x247b912d": {
    name: "setSellTax",
    signature: "setSellTax(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xdc9c9213": {
    name: "swapTokensManual",
    signature: "swapTokensManual()",
    input_types: [],
    output_aliases: [],
  },
  "0x1da1db5e": {
    name: "clearStuckBalance",
    signature: "clearStuckBalance(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x03d29d28": {
    name: "setBlacklistStatus",
    signature: "setBlacklistStatus(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x4fc3f41a": {
    name: "setCooldown",
    signature: "setCooldown(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x4f662566": {
    name: "setMaxBuyPercent",
    signature: "setMaxBuyPercent(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x8fcc250d": {
    name: "setNumTokensSellToAddToLiquidity",
    signature: "setNumTokensSellToAddToLiquidity(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x48ae238f": {
    name: "transferToken",
    signature: "transferToken(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0x8766504d": {
    name: "setFeeTotal",
    signature: "setFeeTotal(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x18082924": {
    name: "setMsg",
    signature: "setMsg(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0xa4445d4d": {
    name: "addPresaleWallet",
    signature: "addPresaleWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xae856ce3": {
    name: "bulkAddPresaleWallets",
    signature: "bulkAddPresaleWallets(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0xe797ec1b": {
    name: "enableMinting",
    signature: "enableMinting()",
    input_types: [],
    output_aliases: [],
  },
  "0x1785f53c": {
    name: "removeAdmin",
    signature: "removeAdmin(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x811e0aa9": {
    name: "removePresaleWallet",
    signature: "removePresaleWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x5c464229": {
    name: "toggleMintPause",
    signature: "toggleMintPause()",
    input_types: [],
    output_aliases: [],
  },
  "0x4a6f07a5": {
    name: "updateArtistPayoutAddress",
    signature: "updateArtistPayoutAddress(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x47c8ff5e": {
    name: "updateContractUri",
    signature: "updateContractUri(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x38a2801b": {
    name: "updateImnotartPayoutAddress",
    signature: "updateImnotartPayoutAddress(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xd5291e4c": {
    name: "updateImNotArtPayoutAddress",
    signature: "updateImNotArtPayoutAddress(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xee1a14c3": {
    name: "updateMaxInvocations",
    signature: "updateMaxInvocations(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x01fb7f26": {
    name: "updateMaxQuantityPerTransaction",
    signature: "updateMaxQuantityPerTransaction(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x7f392078": {
    name: "updateMetadataBaseUri",
    signature: "updateMetadataBaseUri(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x0430f25b": {
    name: "updateMintPriceInWei",
    signature: "updateMintPriceInWei(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x3d8705ab": {
    name: "collectTax",
    signature: "collectTax()",
    input_types: [],
    output_aliases: [],
  },
  "0xd49b55d6": {
    name: "swapForTax",
    signature: "swapForTax()",
    input_types: [],
    output_aliases: [],
  },
  "0xed9f35ce": {
    name: "freeFilledAmountStorage",
    signature: "freeFilledAmountStorage(bytes[])",
    input_types: ["bytes[]"],
    output_aliases: ["data"],
  },
  "0xa2a7d51b": {
    name: "freePreSignatureStorage",
    signature: "freePreSignatureStorage(bytes[])",
    input_types: ["bytes[]"],
    output_aliases: ["data"],
  },
  "0x15337bc0": {
    name: "invalidateOrder",
    signature: "invalidateOrder(bytes)",
    input_types: ["bytes"],
    output_aliases: ["bytes"],
  },
  "0xec6cb13f": {
    name: "setPreSignature",
    signature: "setPreSignature(bytes,bool)",
    input_types: ["bytes", "bool"],
    output_aliases: ["bytes", "bool"],
  },
  "0x13d79a0b": {
    name: "settle",
    signature:
      "settle(address[],uint256[],(uint256,uint256,address,uint256,uint256,uint32,bytes32,uint256,uint256,uint256,bytes)[],(address,uint256,bytes)[][3])",
    input_types: [
      "address[]",
      "uint256[]",
      "(uint256,uint256,address,uint256,uint256,uint32,bytes32,uint256,uint256,uint256,bytes)[]",
      "(address,uint256,bytes)[][3]",
    ],
    output_aliases: ["addresses", "amounts", "envelopes", "routes"],
  },
  "0xf84436bd": {
    name: "simulateDelegatecall",
    signature: "simulateDelegatecall(address,bytes)",
    input_types: ["address", "bytes"],
    output_aliases: ["to", "bytes"],
  },
  "0x43218e19": {
    name: "simulateDelegatecallInternal",
    signature: "simulateDelegatecallInternal(address,bytes)",
    input_types: ["address", "bytes"],
    output_aliases: ["to", "bytes"],
  },
  // "0x845a101f": {
  //     name: "swap",
  //     signature: "swap((bytes32,uint256,uint256,uint256,bytes)[],address[],(uint256,uint256,address,uint256,uint256,uint32,bytes32,uint256,uint256,uint256,bytes))",
  //     input_types: ["(bytes32","uint256","uint256","uint256","bytes)[]","address[]","(uint256","uint256","address","uint256","uint256","uint32","bytes32","uint256","uint256","uint256","bytes)"],
  //     output_aliases: ["undefined_a","amount_b","amount_c","amount_d","undefined_e","addresses_f","undefined_g","amount_h","to_i","amount_j","amount_k","num_l","b32_m","amount_undefined","amount_undefined","amount_undefined","undefined_undefined"],
  //   },

  "0x772558ce": {
    name: "blacklistMultipleWallets",
    signature: "blacklistMultipleWallets(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0xcad6ebf9": {
    name: "blacklistSingleWallet",
    signature: "blacklistSingleWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x2cde6081": {
    name: "changeLimit",
    signature: "changeLimit()",
    input_types: [],
    output_aliases: [],
  },
  "0x8187f516": {
    name: "setPair",
    signature: "setPair(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xc0d78655": {
    name: "setRouter",
    signature: "setRouter(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xa1bdc399": {
    name: "unBlacklistMultipleWallets",
    signature: "unBlacklistMultipleWallets(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0xf157ce40": {
    name: "unBlacklistSingleWallet",
    signature: "unBlacklistSingleWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xf2c816ae": {
    name: "addAddressToBlacklist",
    signature: "addAddressToBlacklist(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x828e8b7e": {
    name: "AddressResetBuyLock",
    signature: "AddressResetBuyLock()",
    input_types: [],
    output_aliases: [],
  },
  "0xf9ab7366": {
    name: "addWalletExclusion",
    signature: "addWalletExclusion(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x35e82f3a": {
    name: "removeAddressFromBlacklist",
    signature: "removeAddressFromBlacklist(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x61cb2748": {
    name: "removeWalletExclusion",
    signature: "removeWalletExclusion(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x7b9e987a": {
    name: "SetupEnableTrading",
    signature: "SetupEnableTrading()",
    input_types: [],
    output_aliases: [],
  },
  "0xdaf9a47a": {
    name: "TeamChangeAntiWhale",
    signature: "TeamChangeAntiWhale(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x9bc845fb": {
    name: "TeamChangeTeamWallet",
    signature: "TeamChangeTeamWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xd8902ff5": {
    name: "TeamChangeWalletTwo",
    signature: "TeamChangeWalletTwo(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x243b529a": {
    name: "TeamCreateLPandETH",
    signature: "TeamCreateLPandETH()",
    input_types: [],
    output_aliases: [],
  },
  "0xb766a386": {
    name: "TeamDisableBuyLock",
    signature: "TeamDisableBuyLock(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x6eb8c7f1": {
    name: "TeamProlongLiquidityLockInSeconds",
    signature: "TeamProlongLiquidityLockInSeconds(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x0fa5b215": {
    name: "TeamRemoveLiquidity",
    signature: "TeamRemoveLiquidity()",
    input_types: [],
    output_aliases: [],
  },
  "0x4cbf8e24": {
    name: "TeamRemoveRemainingETH",
    signature: "TeamRemoveRemainingETH()",
    input_types: [],
    output_aliases: [],
  },
  "0xe2fd002a": {
    name: "TeamSetBuyLockTime",
    signature: "TeamSetBuyLockTime(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x57ad67d1": {
    name: "TeamSetTaxes",
    signature: "TeamSetTaxes(uint8,uint8,uint8,uint8,uint8,uint8)",
    input_types: ["uint8", "uint8", "uint8", "uint8", "uint8", "uint8"],
    output_aliases: ["num_a", "num_b", "num_c", "num_d", "num_e", "num_f"],
  },
  "0x9bf65770": {
    name: "TeamSwitchManualETHConversion",
    signature: "TeamSwitchManualETHConversion(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xd11edf74": {
    name: "TeamUpdateAmountToSwap",
    signature: "TeamUpdateAmountToSwap(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x9fa62d5f": {
    name: "TeamUpdateLimits",
    signature: "TeamUpdateLimits(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x929dffda": {
    name: "teamUpdateUniswapRouter",
    signature: "teamUpdateUniswapRouter(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x58e1df92": {
    name: "TeamWithdrawALLMarketingETH",
    signature: "TeamWithdrawALLMarketingETH()",
    input_types: [],
    output_aliases: [],
  },
  "0x4476f35a": {
    name: "TeamWithdrawXMarketingETH",
    signature: "TeamWithdrawXMarketingETH(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x8326699d": {
    name: "BlacklistUser",
    signature: "BlacklistUser(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xbe6c2ff7": {
    name: "UnbanUser",
    signature: "UnbanUser(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x82be0b62": {
    name: "setAwakeningAddress",
    signature: "setAwakeningAddress(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xbe45fd62": {
    name: "transfer",
    signature: "transfer(address,uint256,bytes)",
    input_types: ["address", "uint256", "bytes"],
    output_aliases: ["to", "amount", "bytes"],
  },
  "0xf6368f8a": {
    name: "transferOwnership",
    signature: "transferOwnership(ransfer(address,uint256,bytes,string)",
    input_types: ["ransfer(address", "uint256", "bytes", "string"],
    output_aliases: ["ransfer(address", "amount", "bytes", "string"],
  },
  "0x2fb1746d": {
    name: "turnOnTradable",
    signature: "turnOnTradable()",
    input_types: [],
    output_aliases: [],
  },
  "0xe2f273bd": {
    name: "updateAdmin",
    signature: "updateAdmin(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xf4a96462": {
    name: "setMessage",
    signature: "setMessage(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0x880ad0af": {
    name: "transferOwnership",
    signature: "transferOwnership()",
    input_types: [],
    output_aliases: [],
  },
  "0xbdd6a7f3": {
    name: "collectSum",
    signature: "collectSum()",
    input_types: [],
    output_aliases: [],
  },
  "0xb1884744": {
    name: "lend",
    signature: "lend(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0x8dea7225": {
    name: "lockArweaveID",
    signature: "lockArweaveID()",
    input_types: [],
    output_aliases: [],
  },
  "0x07c60dac": {
    name: "lockSale",
    signature: "lockSale()",
    input_types: [],
    output_aliases: [],
  },
  "0x5e5a9e30": {
    name: "lockTokenURI",
    signature: "lockTokenURI()",
    input_types: [],
    output_aliases: [],
  },
  "0x05ea13fb": {
    name: "mintArtistProofs",
    signature: "mintArtistProofs()",
    input_types: [],
    output_aliases: [],
  },
  "0x5fb338ae": {
    name: "setArtworkPrivate",
    signature: "setArtworkPrivate(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xedbf3fde": {
    name: "setArtworkPublic",
    signature: "setArtworkPublic(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x4ab45d54": {
    name: "setArweaveID",
    signature: "setArweaveID(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x12c40f9e": {
    name: "setMaxNumberOfArtworks",
    signature: "setMaxNumberOfArtworks(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x8ef79e91": {
    name: "setTokenBaseURI",
    signature: "setTokenBaseURI(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0xa9852bfb": {
    name: "setTokenURISuffix",
    signature: "setTokenURISuffix(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x25867d42": {
    name: "unlockSale",
    signature: "unlockSale()",
    input_types: [],
    output_aliases: [],
  },
  "0x1d758668": {
    name: "buy",
    signature: "buy(bytes,uint256)",
    input_types: ["bytes", "uint256"],
    output_aliases: ["bytes", "amount"],
  },
  "0x3d529db6": {
    name: "ownerAssignment",
    signature: "ownerAssignment(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x34e7a19f": {
    name: "joinPool",
    signature: "joinPool(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xf19e9d81": {
    name: "leavePool",
    signature: "leavePool()",
    input_types: [],
    output_aliases: [],
  },
  "0x747641b1": {
    name: "managePool",
    signature: "managePool()",
    input_types: [],
    output_aliases: [],
  },
  "0xfc735e99": {
    name: "verify",
    signature: "verify()",
    input_types: [],
    output_aliases: [],
  },
  "0x31d3c133": {
    name: "setMarketingWallet1",
    signature: "setMarketingWallet1(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x61381833": {
    name: "setMarketingWallet2",
    signature: "setMarketingWallet2(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x111e0376": {
    name: "excludeFromRewards",
    signature: "excludeFromRewards(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xb609995e": {
    name: "includeInRewards",
    signature: "includeInRewards(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xa1ab19a3": {
    name: "prepareForLaunch",
    signature: "prepareForLaunch()",
    input_types: [],
    output_aliases: [],
  },
  "0x8421b507": {
    name: "setLaunchSellFee",
    signature: "setLaunchSellFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xba385abb": {
    name: "setRewardsTracker",
    signature: "setRewardsTracker(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x26b6308d": {
    name: "setSwapAndRedirectEthFeesEnabled",
    signature: "setSwapAndRedirectEthFeesEnabled(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xe89bcca2": {
    name: "setUseGenericTransfer",
    signature: "setUseGenericTransfer(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xc258ef60": {
    name: "sendAirDrop",
    signature: "sendAirDrop(address[],uint256[])",
    input_types: ["address[]", "uint256[]"],
    output_aliases: ["addresses", "amounts"],
  },
  "0x612e58f6": {
    name: "updateAll",
    signature: "updateAll(string,bool)",
    input_types: ["string", "bool"],
    output_aliases: ["string", "bool"],
  },
  "0xe5ac14c2": {
    name: "updateWalletChurch",
    signature: "updateWalletChurch(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xd7b49b4e": {
    name: "updateWalletDiscretionary",
    signature: "updateWalletDiscretionary(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x17be3fa5": {
    name: "lockToken",
    signature: "lockToken(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x0ad436f5": {
    name: "lockTokenWhole",
    signature: "lockTokenWhole(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x31e9e9c0": {
    name: "unlockToken",
    signature: "unlockToken(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x08003f78": {
    name: "burnTokens",
    signature: "burnTokens()",
    input_types: [],
    output_aliases: [],
  },
  "0x2c74d772": {
    name: "initialMint",
    signature: "initialMint(uint256[],address[])",
    input_types: ["uint256[]", "address[]"],
    output_aliases: ["amounts", "addresses"],
  },
  "0x8a57af6b": {
    name: "lockAfter",
    signature: "lockAfter(address,uint256,uint256)",
    input_types: ["address", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "amount_c"],
  },
  "0x927a4a7b": {
    name: "transferWithLockAfter",
    signature: "transferWithLockAfter(address,uint256,uint256)",
    input_types: ["address", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "amount_c"],
  },
  "0x45c8b1a6": {
    name: "unfreeze",
    signature: "unfreeze(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xa73b3c82": {
    name: "transferlottery",
    signature: "transferlottery(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x4bb3e285": {
    name: "TurnRewardsOff",
    signature: "TurnRewardsOff()",
    input_types: [],
    output_aliases: [],
  },
  "0x332d4a93": {
    name: "TurnRewardsOn",
    signature: "TurnRewardsOn()",
    input_types: [],
    output_aliases: [],
  },
  "0x6659889d": {
    name: "setPromoWallet",
    signature: "setPromoWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xc7af2a9c": {
    name: "createLiquidity",
    signature: "createLiquidity()",
    input_types: [],
    output_aliases: [],
  },
  "0xb92ebd5f": {
    name: "setEnableTrading",
    signature: "setEnableTrading(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x019ce79b": {
    name: "setMaxWalletOnStart",
    signature: "setMaxWalletOnStart(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x20b3d480": {
    name: "deliciousbotcheese",
    signature: "deliciousbotcheese(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0x9e78fb4f": {
    name: "createPair",
    signature: "createPair()",
    input_types: [],
    output_aliases: [],
  },
  "0x01320621": {
    name: "setremoveMaxTx",
    signature: "setremoveMaxTx(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xb21d4184": {
    name: "snipStopper",
    signature: "snipStopper(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0x89dfc8b9": {
    name: "setMaxFreeMintAmountPerAccount",
    signature: "setMaxFreeMintAmountPerAccount(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x69703cd4": {
    name: "setMaxMintPublicAccount",
    signature: "setMaxMintPublicAccount(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xa224b4f5": {
    name: "mintPromotion",
    signature: "mintPromotion(address[],string)",
    input_types: ["address[]", "string"],
    output_aliases: ["addresses", "string"],
  },
  "0x82e77c59": {
    name: "mintReward",
    signature: "mintReward(address[],string)",
    input_types: ["address[]", "string"],
    output_aliases: ["addresses", "string"],
  },
  "0x3ec2d836": {
    name: "rename",
    signature: "rename(uint256,string)",
    input_types: ["uint256", "string"],
    output_aliases: ["amount", "string"],
  },
  "0x4c261247": {
    name: "reveal",
    signature: "reveal(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x3df10ef2": {
    name: "setCancel",
    signature: "setCancel(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x469b624f": {
    name: "setSaleIsFinish",
    signature: "setSaleIsFinish()",
    input_types: [],
    output_aliases: [],
  },
  "0x463b654d": {
    name: "settingAddressContractImage",
    signature: "settingAddressContractImage(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xee8124c4": {
    name: "settingAddressProxyRegistry",
    signature: "settingAddressProxyRegistry(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xcaf42575": {
    name: "settingPasscode",
    signature: "settingPasscode(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xe572e63e": {
    name: "mintHomepage",
    signature: "mintHomepage(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x787b487e": {
    name: "burnForReward",
    signature: "burnForReward(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x9e52228a": {
    name: "devMintTokensToAddress",
    signature: "devMintTokensToAddress(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xbb58b204": {
    name: "devMintTokensToAddresses",
    signature: "devMintTokensToAddresses(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0x1be8db96": {
    name: "pauseBurn",
    signature: "pauseBurn(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xf30e6e77": {
    name: "pauseMint",
    signature: "pauseMint(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x5b8925b9": {
    name: "revealDrop",
    signature: "revealDrop(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x955d2dcc": {
    name: "setBlankTokenURI",
    signature: "setBlankTokenURI(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0xf3179891": {
    name: "setMintPassAddress",
    signature: "setMintPassAddress(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x51508f0a": {
    name: "setRewardContract",
    signature: "setRewardContract(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x65348141": {
    name: "mint",
    signature: "mint(uint256,uint256,bool,bytes)",
    input_types: ["uint256", "uint256", "bool", "bytes"],
    output_aliases: ["amount_a", "amount_b", "bool_c", "bytes_d"],
  },
  "0xe045512d": {
    name: "mintWithPass",
    signature: "mintWithPass(uint256,uint256,bool,bytes)",
    input_types: ["uint256", "uint256", "bool", "bytes"],
    output_aliases: ["amount_a", "amount_b", "bool_c", "bytes_d"],
  },
  "0x2d1fb389": {
    name: "authorize",
    signature: "authorize(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x261c6ca5": {
    name: "manageWreck",
    signature: "manageWreck(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x6883b831": {
    name: "manuallyCollectFee",
    signature: "manuallyCollectFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xd1633649": {
    name: "openTrading",
    signature: "openTrading(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xef422a18": {
    name: "setIsExcludedFromFee",
    signature: "setIsExcludedFromFee(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x5d2c76b0": {
    name: "setMaxBuyTxLimit",
    signature: "setMaxBuyTxLimit(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xfab355f2": {
    name: "setMaxSellTxLimit",
    signature: "setMaxSellTxLimit(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x19aa57e8": {
    name: "transferOtherTokens",
    signature: "transferOtherTokens(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xf8e5884b": {
    name: "triggerSwap",
    signature: "triggerSwap(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x73d46b07": {
    name: "updateProtection",
    signature: "updateProtection(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x943620db": {
    name: "updateSwap",
    signature: "updateSwap(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xbdb2c382": {
    name: "updateSwapLimit",
    signature: "updateSwapLimit(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xfceade72": {
    name: "updateSwapLimit",
    signature: "updateSwapLimit(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x2913aa55": {
    name: "addLimits",
    signature: "addLimits(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x530eab62": {
    name: "liftLimits",
    signature: "liftLimits(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0x8fa457ca": {
    name: "setBlacklistWallets",
    signature: "setBlacklistWallets(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0xcf0848f7": {
    name: "includeToFee",
    signature: "includeToFee(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x90d49b9d": {
    name: "setFeeWallet",
    signature: "setFeeWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xe6ec64ec": {
    name: "setTeamFee",
    signature: "setTeamFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x06d8ea6b": {
    name: "swapFeesManual",
    signature: "swapFeesManual()",
    input_types: [],
    output_aliases: [],
  },
  "0x0614117a": {
    name: "recoverETH",
    signature: "recoverETH()",
    input_types: [],
    output_aliases: [],
  },
  "0x81c6f11b": {
    name: "isProtected",
    signature: "isProtected(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xeec2744e": {
    name: "setBlacklistEnabled",
    signature: "setBlacklistEnabled(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x469c0ef5": {
    name: "setBuyCoolDownTime",
    signature: "setBuyCoolDownTime(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x044df726": {
    name: "setProtectionSettings",
    signature: "setProtectionSettings(bool,bool)",
    input_types: ["bool", "bool"],
    output_aliases: ["bool_a", "bool_b"],
  },
  "0xcdfdb7dc": {
    name: "setRatios",
    signature: "setRatios(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0x4129ecee": {
    name: "setStartingProtections",
    signature: "setStartingProtections(uint8)",
    input_types: ["uint8"],
    output_aliases: ["num"],
  },
  "0xe8b92b05": {
    name: "afterPreSale",
    signature: "afterPreSale(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xca42c8eb": {
    name: "emergency_token",
    signature: "emergency_token(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x7f7a1225": {
    name: "setMarketingAddress",
    signature: "setMarketingAddress(address,address,address,address)",
    input_types: ["address", "address", "address", "address"],
    output_aliases: ["to_a", "to_b", "to_c", "to_d"],
  },
  "0xfe22c82d": {
    name: "setUniswapAddress_forspeedbrake",
    signature: "setUniswapAddress_forspeedbrake(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xdc0e6217": {
    name: "contractMint",
    signature: "contractMint(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xa584a9b5": {
    name: "freezeContract",
    signature: "freezeContract()",
    input_types: [],
    output_aliases: [],
  },
  "0xe272b892": {
    name: "pauseContract",
    signature: "pauseContract(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x8236802e": {
    name: "blacklistBot",
    signature: "blacklistBot(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x2b044370": {
    name: "disableBlacklistFunction",
    signature: "disableBlacklistFunction()",
    input_types: [],
    output_aliases: [],
  },
  "0xbd94545b": {
    name: "excludeMultipleAccountsFromMaxWallet",
    signature: "excludeMultipleAccountsFromMaxWallet(address[],bool)",
    input_types: ["address[]", "bool"],
    output_aliases: ["addresses", "bool"],
  },
  "0xa9d3b3ae": {
    name: "manualSwapAndAddToLiq",
    signature: "manualSwapAndAddToLiq(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x00ae3bf8": {
    name: "rescueTokens",
    signature: "rescueTokens(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x04679780": {
    name: "setNumTokensSellToAddToLiq",
    signature: "setNumTokensSellToAddToLiq(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x124d91e5": {
    name: "burnFrom",
    signature: "burnFrom(address,uint256,uint256)",
    input_types: ["address", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "amount_c"],
  },
  "0x39d0534a": {
    name: "Multicall",
    signature: "Multicall(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x51cd7cc3": {
    name: "OpenTrading",
    signature: "OpenTrading()",
    input_types: [],
    output_aliases: [],
  },
  "0x3f9b7607": {
    name: "claimAltTokens",
    signature: "claimAltTokens(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0x7e66c0b9": {
    name: "claimETH",
    signature: "claimETH(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x725e0769": {
    name: "setAntiSnipe",
    signature: "setAntiSnipe(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x5ae9e94b": {
    name: "setMaxHoldings",
    signature: "setMaxHoldings(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x413550e3": {
    name: "setMaxHoldingsEnabled",
    signature: "setMaxHoldingsEnabled(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x95f6f567": {
    name: "setMaxTXEnabled",
    signature: "setMaxTXEnabled(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x350ddddd": {
    name: "LiquidityLaunchInit",
    signature: "LiquidityLaunchInit()",
    input_types: [],
    output_aliases: [],
  },
  "0x21ce72e5": {
    name: "setBuyTax",
    signature: "setBuyTax(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0x8f2076fd": {
    name: "setSellTax",
    signature: "setSellTax(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0x2a824300": {
    name: "StopLiquidityLaunch",
    signature: "StopLiquidityLaunch()",
    input_types: [],
    output_aliases: [],
  },
  "0xd8cfb999": {
    name: "toggleMintStatus",
    signature: "toggleMintStatus()",
    input_types: [],
    output_aliases: [],
  },
  "0x986ccc7f": {
    name: "setHelper",
    signature: "setHelper(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xbc07aa64": {
    name: "setMerkleRoots",
    signature: "setMerkleRoots(bytes32[])",
    input_types: ["bytes32[]"],
    output_aliases: ["b32s"],
  },
  "0x7b1278e9": {
    name: "setStageLength",
    signature: "setStageLength(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x65f4bce6": {
    name: "setWindows",
    signature: "setWindows(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x89643e82": {
    name: "purchase",
    signature: "purchase(uint256,uint256,uint256,uint256,bytes32[])",
    input_types: ["uint256", "uint256", "uint256", "uint256", "bytes32[]"],
    output_aliases: ["amount_a", "amount_b", "amount_c", "amount_d", "b32s_e"],
  },
  "0xdb6dd4e6": {
    name: "purchasePublic",
    signature: "purchasePublic(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xa6c38f59": {
    name: "addBotWallet",
    signature: "addBotWallet(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x4a0e963c": {
    name: "addToExcluded",
    signature: "addToExcluded(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xf19abf26": {
    name: "dayAfter",
    signature: "dayAfter()",
    input_types: [],
    output_aliases: [],
  },
  "0xf953cf3a": {
    name: "endPresaleStatus",
    signature: "endPresaleStatus()",
    input_types: [],
    output_aliases: [],
  },
  "0xb4d95a93": {
    name: "removeFromExcluded",
    signature: "removeFromExcluded(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xd0d41fe1": {
    name: "setDevAddress",
    signature: "setDevAddress(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xbf9eb540": {
    name: "setMktAddress",
    signature: "setMktAddress(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x801527ce": {
    name: "setSwapAndLiquify",
    signature: "setSwapAndLiquify(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xb195b839": {
    name: "startPresaleStatus",
    signature: "startPresaleStatus()",
    input_types: [],
    output_aliases: [],
  },
  "0x69d4eb87": {
    name: "updatecurrentbuyDevAmountfee",
    signature: "updatecurrentbuyDevAmountfee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x0882a863": {
    name: "updatecurrentbuyliqFee",
    signature: "updatecurrentbuyliqFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xedcf19b2": {
    name: "updatecurrentbuymktfee",
    signature: "updatecurrentbuymktfee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xd30ff2d9": {
    name: "updatecurrentsellDevfee",
    signature: "updatecurrentsellDevfee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x8cd0260c": {
    name: "updatecurrentsellLiqFee",
    signature: "updatecurrentsellLiqFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x8628c23f": {
    name: "updatecurrentsellmktfee",
    signature: "updatecurrentsellmktfee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xa7a07452": {
    name: "updateMaxTxTreshold",
    signature: "updateMaxTxTreshold(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x6573c4df": {
    name: "updateMaxWalletTreshold",
    signature: "updateMaxWalletTreshold(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xd7d7442f": {
    name: "updateThreshold",
    signature: "updateThreshold(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x29b1c15c": {
    name: "setDevelopmentAddress",
    signature: "setDevelopmentAddress(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x6f972cd2": {
    name: "setDevelopmentFee",
    signature: "setDevelopmentFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x41a2ac70": {
    name: "setRewardFee",
    signature: "setRewardFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xbbec4c45": {
    name: "reinvestReflections",
    signature: "reinvestReflections(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xe20bc67b": {
    name: "autoUnlock",
    signature: "autoUnlock(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x06f7c26b": {
    name: "claimReserve",
    signature: "claimReserve(address[],uint8[])",
    input_types: ["address[]", "uint8[]"],
    output_aliases: ["addresses", "nums"],
  },
  "0xda31bcef": {
    name: "updateRevealTime",
    signature: "updateRevealTime(uint256[])",
    input_types: ["uint256[]"],
    output_aliases: ["amounts"],
  },
  "0x488a8557": {
    name: "updatesTierMintFees",
    signature: "updatesTierMintFees(uint256[])",
    input_types: ["uint256[]"],
    output_aliases: ["amounts"],
  },
  "0x3c238c7d": {
    name: "updateTierMintEndTimes",
    signature: "updateTierMintEndTimes(uint256[])",
    input_types: ["uint256[]"],
    output_aliases: ["amounts"],
  },
  "0x71bb534c": {
    name: "updateTierMintStartTimes",
    signature: "updateTierMintStartTimes(uint256[])",
    input_types: ["uint256[]"],
    output_aliases: ["amounts"],
  },
  "0xd0cc19f2": {
    name: "updateWhiteListDisableTime",
    signature: "updateWhiteListDisableTime(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x92cbf98d": {
    name: "updateWhiteListMintFee",
    signature: "updateWhiteListMintFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x5531b7fe": {
    name: "claimWhitelistSale",
    signature: "claimWhitelistSale(bytes[],address,uint256[])",
    input_types: ["bytes[]", "address", "uint256[]"],
    output_aliases: ["data", "to", "amounts"],
  },
  "0x352926a8": {
    name: "mint",
    signature: "mint(address,uint8,uint8)",
    input_types: ["address", "uint8", "uint8"],
    output_aliases: ["to_a", "num_b", "num_c"],
  },
  "0x74dfa316": {
    name: "changenumTokensSellToAddToLiquidity",
    signature: "changenumTokensSellToAddToLiquidity(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xf275f64b": {
    name: "enableTrading",
    signature: "enableTrading(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xb7cd7794": {
    name: "excludeFromMaxTxLimit",
    signature: "excludeFromMaxTxLimit(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x71547984": {
    name: "includeInMaxTxLimit",
    signature: "includeInMaxTxLimit(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xcea26958": {
    name: "setBurnFeePercent",
    signature: "setBurnFeePercent(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x61a60d57": {
    name: "setDeadWallet",
    signature: "setDeadWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x26fddf08": {
    name: "setFoundationPercent",
    signature: "setFoundationPercent(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x015493b4": {
    name: "setFoundationWallet",
    signature: "setFoundationWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xc6055ec3": {
    name: "setMarketingPercent",
    signature: "setMarketingPercent(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xbbb1a87c": {
    name: "customlock",
    signature: "customlock(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x12638eec": {
    name: "customunlock",
    signature: "customunlock(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xa9dab167": {
    name: "extendLock",
    signature: "extendLock(bytes32,uint256)",
    input_types: ["bytes32", "uint256"],
    output_aliases: ["b32", "amount"],
  },
  "0x81fc4d90": {
    name: "increaseLockAmount",
    signature: "increaseLockAmount(bytes32,uint256)",
    input_types: ["bytes32", "uint256"],
    output_aliases: ["b32", "amount"],
  },
  "0x2e82aaf2": {
    name: "lock",
    signature: "lock(bytes32,uint256,uint256)",
    input_types: ["bytes32", "uint256", "uint256"],
    output_aliases: ["b32_a", "amount_b", "amount_c"],
  },
  "0xa4c838d0": {
    name: "OwnerRevokeAdminRole",
    signature: "OwnerRevokeAdminRole(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xc985792b": {
    name: "OwnerRevokePauserRole",
    signature: "OwnerRevokePauserRole(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xfc3d0cb2": {
    name: "OwnerSetupAdminRole",
    signature: "OwnerSetupAdminRole(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x0668e62f": {
    name: "OwnerSetupPauserRole",
    signature: "OwnerSetupPauserRole(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x1c78c751": {
    name: "OwnerSetupRole",
    signature: "OwnerSetupRole(bytes32,address)",
    input_types: ["bytes32", "address"],
    output_aliases: ["b32", "to"],
  },
  "0xeb795549": {
    name: "safeTransfer",
    signature: "safeTransfer(address,uint256,bytes)",
    input_types: ["address", "uint256", "bytes"],
    output_aliases: ["to", "amount", "bytes"],
  },
  "0x4cb5465f": {
    name: "transferWithLock",
    signature: "transferWithLock(address,bytes32,uint256,uint256)",
    input_types: ["address", "bytes32", "uint256", "uint256"],
    output_aliases: ["to_a", "b32_b", "amount_c", "amount_d"],
  },
  "0x785fd544": {
    name: "unlock",
    signature: "unlock(address,bytes32)",
    input_types: ["address", "bytes32"],
    output_aliases: ["to", "b32"],
  },
  "0xc4f3a853": {
    name: "unlockAll",
    signature: "unlockAll(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x972fdd26": {
    name: "getFunctionImplementation",
    signature: "getFunctionImplementation(bytes4)",
    input_types: ["bytes4"],
    output_aliases: ["b4"],
  },
  "0x8a14c759": {
    name: "setAllowanceTarget",
    signature: "setAllowanceTarget(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x1c31f710": {
    name: "setBeneficiary",
    signature: "setBeneficiary(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xfdf03fa1": {
    name: "setImplementationOverride",
    signature: "setImplementationOverride(bytes4,address)",
    input_types: ["bytes4", "address"],
    output_aliases: ["b4", "to"],
  },
  "0xf9143826": {
    name: "setZeroEx",
    signature: "setZeroEx(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xfe53d0b6": {
    name: "optimalSwap",
    signature: "optimalSwap(bytes,address,uint256)",
    input_types: ["bytes", "address", "uint256"],
    output_aliases: ["bytes", "to", "amount"],
  },
  "0x5cf54026": {
    name: "proxiedSwap",
    signature: "proxiedSwap(bytes,address,address,uint256,address,uint256)",
    input_types: [
      "bytes",
      "address",
      "address",
      "uint256",
      "address",
      "uint256",
    ],
    output_aliases: ["bytes_a", "to_b", "to_c", "amount_d", "to_e", "amount_f"],
  },
  "0x23338b88": {
    name: "delMinter",
    signature: "delMinter(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x84f570af": {
    name: "catchMice",
    signature: "catchMice(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0xc38ec0e6": {
    name: "freeMouse",
    signature: "freeMouse(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x950406c3": {
    name: "liftMaxTransaction",
    signature: "liftMaxTransaction()",
    input_types: [],
    output_aliases: [],
  },
  "0xa769958a": {
    name: "reduceFees",
    signature: "reduceFees(uint8,uint8)",
    input_types: ["uint8", "uint8"],
    output_aliases: ["num_a", "num_b"],
  },
  "0x320b2ad9": {
    name: "_pause",
    signature: "_pause()",
    input_types: [],
    output_aliases: [],
  },
  "0xafee32a9": {
    name: "setBuyFeePercent",
    signature: "setBuyFeePercent(uint256,uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c", "amount_d"],
  },
  "0xa68bacf3": {
    name: "setMaxBuytx",
    signature: "setMaxBuytx(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x91d55f41": {
    name: "setMaxWalletToken",
    signature: "setMaxWalletToken(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x41cb87fc": {
    name: "setRouterAddress",
    signature: "setRouterAddress(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x9d854b63": {
    name: "setSellFeePercent",
    signature: "setSellFeePercent(uint256,uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c", "amount_d"],
  },
  "0x293230b8": {
    name: "startTrading",
    signature: "startTrading()",
    input_types: [],
    output_aliases: [],
  },
  "0xcfefd79e": {
    name: "disableBlacklist",
    signature: "disableBlacklist(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xced695a4": {
    name: "disableTax",
    signature: "disableTax()",
    input_types: [],
    output_aliases: [],
  },
  "0x717a8651": {
    name: "enableBlacklist",
    signature: "enableBlacklist(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x53eb3bcf": {
    name: "enableTax",
    signature: "enableTax()",
    input_types: [],
    output_aliases: [],
  },
  "0x4febf53d": {
    name: "exclude",
    signature: "exclude(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xabe4f11d": {
    name: "removeExclude",
    signature: "removeExclude(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xa82cfe8b": {
    name: "setBuyTax",
    signature: "setBuyTax(uint256,uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c", "amount_d"],
  },
  "0x9fda0581": {
    name: "setTaxWallets",
    signature: "setTaxWallets(address,address,address)",
    input_types: ["address", "address", "address"],
    output_aliases: ["to_a", "to_b", "to_c"],
  },
  "0x2c32abc2": {
    name: "triggerTax",
    signature: "triggerTax()",
    input_types: [],
    output_aliases: [],
  },
  "0xab05bfff": {
    name: "setReleaseTime",
    signature: "setReleaseTime(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xda6405e1": {
    name: "setPreMintPrice",
    signature: "setPreMintPrice(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x34131cd3": {
    name: "setPresaleAccessList",
    signature: "setPresaleAccessList(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0xc1d7ae31": {
    name: "updateMainSaleStatus",
    signature: "updateMainSaleStatus(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x290c292d": {
    name: "preMint",
    signature: "preMint(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x0a61415e": {
    name: "set_antibot",
    signature: "set_antibot(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x757d1da4": {
    name: "mintlistUser",
    signature: "mintlistUser(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xc0cd0833": {
    name: "mintlistUsers",
    signature: "mintlistUsers(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0x582f1f1c": {
    name: "rollOverPixelmons",
    signature: "rollOverPixelmons(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0x7c537374": {
    name: "setMintlistPrice",
    signature: "setMintlistPrice(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xd145fb31": {
    name: "setSerumContract",
    signature: "setSerumContract(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xeb190a64": {
    name: "auction",
    signature: "auction(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x1aa0ffe4": {
    name: "mintEvolvedPixelmon",
    signature: "mintEvolvedPixelmon(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x3680f5bf": {
    name: "mintlistMint",
    signature: "mintlistMint()",
    input_types: [],
    output_aliases: [],
  },
  "0x0a9c4a0a": {
    name: "setdonationFee",
    signature: "setdonationFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x5a96cdd7": {
    name: "setDonationWallet",
    signature: "setDonationWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xce2fea33": {
    name: "setUSDTRewardsFee",
    signature: "setUSDTRewardsFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x733b864f": {
    name: "manualSwapAndLiquify",
    signature: "manualSwapAndLiquify()",
    input_types: [],
    output_aliases: [],
  },
  "0x4fb2343a": {
    name: "setMaxWalletHolding",
    signature: "setMaxWalletHolding(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xc9c16eac": {
    name: "setSniperEnabled",
    signature: "setSniperEnabled(bool,address)",
    input_types: ["bool", "address"],
    output_aliases: ["bool", "to"],
  },
  "0x6f60efbc": {
    name: "setSwapAndLiquifyEnabled",
    signature: "setSwapAndLiquifyEnabled(bool,uint256)",
    input_types: ["bool", "uint256"],
    output_aliases: ["bool", "amount"],
  },
  "0x667f6526": {
    name: "setTax",
    signature: "setTax(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x249ae3e2": {
    name: "withdrawTax",
    signature: "withdrawTax(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x31532eb8": {
    name: "khb", // this is an openmethod
    signature: "khb()",
    input_types: [],
    output_aliases: [],
  },
  "0x6bd98660": {
    name: "clearBaseUris",
    signature: "clearBaseUris()",
    input_types: [],
    output_aliases: [],
  },
  "0x48f3afed": {
    name: "setBaseURI",
    signature: "setBaseURI(string,uint256,uint256)",
    input_types: ["string", "uint256", "uint256"],
    output_aliases: ["string_a", "amount_b", "amount_c"],
  },
  "0x177bbc95": {
    name: "setIsLive",
    signature: "setIsLive(bool,bool)",
    input_types: ["bool", "bool"],
    output_aliases: ["bool_a", "bool_b"],
  },
  "0x71f9f200": {
    name: "setMaxElements",
    signature: "setMaxElements(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xd9ec6f09": {
    name: "setMaxWhitelistMintPerTx",
    signature: "setMaxWhitelistMintPerTx(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x269748f8": {
    name: "setMintPrices",
    signature: "setMintPrices(uint256,uint256[],uint256[])",
    input_types: ["uint256", "uint256[]", "uint256[]"],
    output_aliases: ["amount_a", "amounts_b", "amounts_c"],
  },
  "0xfbf5c17c": {
    name: "setStartTimes",
    signature: "setStartTimes(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xb9bcecb2": {
    name: "setWhitelistNonce",
    signature: "setWhitelistNonce(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x9055b419": {
    name: "reserveMint",
    signature: "reserveMint(uint256,uint256,bytes32,uint8,bytes32,bytes32)",
    input_types: [
      "uint256",
      "uint256",
      "bytes32",
      "uint8",
      "bytes32",
      "bytes32",
    ],
    output_aliases: [
      "amount_a",
      "amount_b",
      "b32_c",
      "num_d",
      "b32_e",
      "b32_f",
    ],
  },
  "0x09093357": {
    name: "whitelistMint",
    signature: "whitelistMint(uint256,uint256,bytes32,uint8,bytes32,bytes32)",
    input_types: [
      "uint256",
      "uint256",
      "bytes32",
      "uint8",
      "bytes32",
      "bytes32",
    ],
    output_aliases: [
      "amount_a",
      "amount_b",
      "b32_c",
      "num_d",
      "b32_e",
      "b32_f",
    ],
  },
  "0x34cb52e1": {
    name: "changeCallerRewardDivisor",
    signature: "changeCallerRewardDivisor(uint16)",
    input_types: ["uint16"],
    output_aliases: ["num"],
  },
  "0xf0c0289a": {
    name: "changeFeeDivisor",
    signature: "changeFeeDivisor(uint16)",
    input_types: ["uint16"],
    output_aliases: ["num"],
  },
  "0x5b241b21": {
    name: "changeMinRebalancerAmount",
    signature: "changeMinRebalancerAmount(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x30a0f1e2": {
    name: "changeRebalalncerDivisor",
    signature: "changeRebalalncerDivisor(uint16)",
    input_types: ["uint16"],
    output_aliases: ["num"],
  },
  "0x40905cba": {
    name: "changeRebalanceInterval",
    signature: "changeRebalanceInterval(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xbb31b77b": {
    name: "setClaim",
    signature: "setClaim(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xccfc2e8d": {
    name: "setFeeDistributor",
    signature: "setFeeDistributor(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xc0240b74": {
    name: "setlpStaking",
    signature: "setlpStaking(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x6cfd1553": {
    name: "setRebalancer",
    signature: "setRebalancer(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x079636cb": {
    name: "setRenaRouter",
    signature: "setRenaRouter(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xee4e2687": {
    name: "setUniRouter",
    signature: "setUniRouter(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x70709a7c": {
    name: "toggleFeeless",
    signature: "toggleFeeless(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xba05e9bc": {
    name: "manualswapsend",
    signature: "manualswapsend()",
    input_types: [],
    output_aliases: [],
  },
  "0x729d8a87": {
    name: "setMaxTokensToSwapForFees",
    signature: "setMaxTokensToSwapForFees(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x53674ba6": {
    name: "setSwapTokensAt",
    signature: "setSwapTokensAt(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x895fc788": {
    name: "adminMint",
    signature: "adminMint()",
    input_types: [],
    output_aliases: [],
  },
  "0x24938096": {
    name: "beginSale",
    signature: "beginSale()",
    input_types: [],
    output_aliases: [],
  },
  "0x15a8f073": {
    name: "closeSales",
    signature: "closeSales()",
    input_types: [],
    output_aliases: [],
  },
  "0x2316b4da": {
    name: "enablePublicSale",
    signature: "enablePublicSale()",
    input_types: [],
    output_aliases: [],
  },
  "0x440bc7f3": {
    name: "setWhitelist",
    signature: "setWhitelist(bytes32)",
    input_types: ["bytes32"],
    output_aliases: ["b32"],
  },
  "0xfe18f3c9": {
    name: "PublicPurchase",
    signature: "PublicPurchase(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xf281ec22": {
    name: "WhitelistPurchase",
    signature: "WhitelistPurchase(uint256,bytes32[])",
    input_types: ["uint256", "bytes32[]"],
    output_aliases: ["amount", "b32s"],
  },
  "0xdf8408fe": {
    name: "excludeFromFee",
    signature: "excludeFromFee(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x260065e7": {
    name: "setMarketingWallets",
    signature: "setMarketingWallets(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0xc87c089c": {
    name: "freeMint",
    signature: "freeMint(uint8)",
    input_types: ["uint8"],
    output_aliases: ["num"],
  },
  "0x3855c60d": {
    name: "ownerMint",
    signature: "ownerMint(address,uint8)",
    input_types: ["address", "uint8"],
    output_aliases: ["to", "num"],
  },
  "0xbf793346": {
    name: "saleMint",
    signature: "saleMint(uint8)",
    input_types: ["uint8"],
    output_aliases: ["num"],
  },
  "0xdfd233dd": {
    name: "giveaway",
    signature: "giveaway(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xeff819b8": {
    name: "resetTigerUri",
    signature: "resetTigerUri(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x44e393cb": {
    name: "addGrantor",
    signature: "addGrantor(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0xfd1154c2": {
    name: "grantUniformVestingTokens",
    signature: "grantUniformVestingTokens(address,uint256,uint256,uint32)",
    input_types: ["address", "uint256", "uint256", "uint32"],
    output_aliases: ["to_a", "amount_b", "amount_c", "num_d"],
  },
  "0x7af023a9": {
    name: "grantVestingTokens",
    signature:
      "grantVestingTokens(address,uint256,uint256,uint32,uint32,uint32,uint32,bool)",
    input_types: [
      "address",
      "uint256",
      "uint256",
      "uint32",
      "uint32",
      "uint32",
      "uint32",
      "bool",
    ],
    output_aliases: [
      "to_a",
      "amount_b",
      "amount_c",
      "num_d",
      "num_e",
      "num_f",
      "num_g",
      "bool_h",
    ],
  },
  "0x8403eeb5": {
    name: "iAm",
    signature: "iAm(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x41c0e1b5": {
    name: "kill",
    signature: "kill()",
    input_types: [],
    output_aliases: [],
  },
  "0xd9f226e9": {
    name: "registerAccount",
    signature: "registerAccount()",
    input_types: [],
    output_aliases: [],
  },
  "0x332cc9c6": {
    name: "removeGrantor",
    signature: "removeGrantor(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x1bd2cf38": {
    name: "revokeGrant",
    signature: "revokeGrant(address,uint32)",
    input_types: ["address", "uint32"],
    output_aliases: ["to", "num"],
  },
  "0xfbc6d1cb": {
    name: "safeApprove",
    signature: "safeApprove(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x3d3b993c": {
    name: "safeGrantUniformVestingTokens",
    signature: "safeGrantUniformVestingTokens(address,uint256,uint256,uint32)",
    input_types: ["address", "uint256", "uint256", "uint32"],
    output_aliases: ["to_a", "amount_b", "amount_c", "num_d"],
  },
  "0x41d5f619": {
    name: "safeGrantVestingTokens",
    signature:
      "safeGrantVestingTokens(address,uint256,uint256,uint32,uint32,uint32,uint32,bool)",
    input_types: [
      "address",
      "uint256",
      "uint256",
      "uint32",
      "uint32",
      "uint32",
      "uint32",
      "bool",
    ],
    output_aliases: [
      "to_a",
      "amount_b",
      "amount_c",
      "num_d",
      "num_e",
      "num_f",
      "num_g",
      "bool_h",
    ],
  },
  "0x423f6cef": {
    name: "safeTransfer",
    signature: "safeTransfer(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x6d589d64": {
    name: "setGrantorVestingSchedule",
    signature: "setGrantorVestingSchedule(address,uint32,uint32,uint32,bool)",
    input_types: ["address", "uint32", "uint32", "uint32", "bool"],
    output_aliases: ["to_a", "num_b", "num_c", "num_d", "bool_e"],
  },
  "0x99ad427b": {
    name: "setRestrictions",
    signature: "setRestrictions(address,uint32,uint32,uint32)",
    input_types: ["address", "uint32", "uint32", "uint32"],
    output_aliases: ["to_a", "num_b", "num_c", "num_d"],
  },
  "0x1e89d545": {
    name: "multiTransfer",
    signature: "multiTransfer(address[],uint256[])",
    input_types: ["address[]", "uint256[]"],
    output_aliases: ["addresses", "amounts"],
  },
  "0xa16a3179": {
    name: "multiTransfer",
    signature: "multiTransfer(address[],uint256)",
    input_types: ["address[]", "uint256"],
    output_aliases: ["addresses", "amount"],
  },
  "0x71e2a657": {
    name: "addMinters",
    signature: "addMinters(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0x5fc1964f": {
    name: "removeMinters",
    signature: "removeMinters(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0x07da68f5": {
    name: "stop",
    signature: "stop()",
    input_types: [],
    output_aliases: [],
  },
  "0x6ac5eeee": {
    name: "swapBack",
    signature: "swapBack()",
    input_types: [],
    output_aliases: [],
  },
  "0xa5288dec": {
    name: "swapTokensForDai",
    signature: "swapTokensForDai(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x04dacd50": {
    name: "updateBuybackWallet",
    signature: "updateBuybackWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x743efe0d": {
    name: "updateTaxDistributor",
    signature: "updateTaxDistributor(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x2804c7f5": {
    name: "closeDAO",
    signature: "closeDAO()",
    input_types: [],
    output_aliases: [],
  },
  "0x73417b09": {
    name: "setClaimActive",
    signature: "setClaimActive(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x22e8d8cd": {
    name: "setClaimSupply",
    signature: "setClaimSupply(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x01799f59": {
    name: "setLuchaAddress",
    signature: "setLuchaAddress(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x04db5eed": {
    name: "setSaleSupply",
    signature: "setSaleSupply(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xf7ea7a3d": {
    name: "setTotalSupply",
    signature: "setTotalSupply(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xe8cc00ad": {
    name: "withdrawOwner",
    signature: "withdrawOwner()",
    input_types: [],
    output_aliases: [],
  },
  "0x05ad7799": {
    name: "setBuyF",
    signature: "setBuyF(uint256,uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c", "amount_d"],
  },
  "0x41137645": {
    name: "setGiveAwayAddress",
    signature: "setGiveAwayAddress(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xbd559c07": {
    name: "setSellF",
    signature: "setSellF(uint256,uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c", "amount_d"],
  },
  "0x51006d7e": {
    name: "startAntiSniper",
    signature: "startAntiSniper()",
    input_types: [],
    output_aliases: [],
  },
  "0xb9a1861a": {
    name: "stopAntiSniper",
    signature: "stopAntiSniper()",
    input_types: [],
    output_aliases: [],
  },
  "0xa716b773": {
    name: "addPresaleAddressForExclusions",
    signature: "addPresaleAddressForExclusions(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x08043751": {
    name: "updateMinimumTokensBeforeSwap",
    signature: "updateMinimumTokensBeforeSwap(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xe4456ecb": {
    name: "withdrawLP",
    signature: "withdrawLP(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x7699cb91": {
    name: "emergencyOverridePresaleTimings",
    signature: "emergencyOverridePresaleTimings(uint32,uint32)",
    input_types: ["uint32", "uint32"],
    output_aliases: ["num_a", "num_b"],
  },
  "0x45696963": {
    name: "emergencyOverridePublicSaleStartTime",
    signature: "emergencyOverridePublicSaleStartTime(uint32)",
    input_types: ["uint32"],
    output_aliases: ["num"],
  },
  "0x9423ad26": {
    name: "setPresaleOn",
    signature: "setPresaleOn(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x3bd64968": {
    name: "setRevealed",
    signature: "setRevealed()",
    input_types: [],
    output_aliases: [],
  },
  "0x2904e6d9": {
    name: "whitelistMint",
    signature: "whitelistMint(bytes32[],uint256)",
    input_types: ["bytes32[]", "uint256"],
    output_aliases: ["b32s", "amount"],
  },
  "0x3ab1a494": {
    name: "setWithdrawAddress",
    signature: "setWithdrawAddress(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x05f2327e": {
    name: "withdrawByOwner",
    signature: "withdrawByOwner()",
    input_types: [],
    output_aliases: [],
  },
  "0x9e5666cd": {
    name: "setPreSaleAddresses",
    signature: "setPreSaleAddresses(bytes32)",
    input_types: ["bytes32"],
    output_aliases: ["b32"],
  },
  "0xf5b96621": {
    name: "setPresaleOpen",
    signature: "setPresaleOpen(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xfe2c7fee": {
    name: "setUnrevealedURI",
    signature: "setUnrevealedURI(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x0364d22a": {
    name: "presaleMint",
    signature: "presaleMint(uint32,bytes32[])",
    input_types: ["uint32", "bytes32[]"],
    output_aliases: ["num", "b32s"],
  },
  "0xc7cd483b": {
    name: "updateBuyFees",
    signature: "updateBuyFees(uint8)",
    input_types: ["uint8"],
    output_aliases: ["num"],
  },
  "0x52606f28": {
    name: "updateSellFees",
    signature: "updateSellFees(uint8)",
    input_types: ["uint8"],
    output_aliases: ["num"],
  },
  "0x20b6a50f": {
    name: "flipTradingStatus",
    signature: "flipTradingStatus()",
    input_types: [],
    output_aliases: [],
  },
  "0x2b8e797a": {
    name: "modifyKYCData",
    signature: "modifyKYCData(address,uint256,uint256)",
    input_types: ["address", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "amount_c"],
  },
  "0xda76e2f3": {
    name: "removeWhitelistAuthorityStatus",
    signature: "removeWhitelistAuthorityStatus(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x62a3d4bd": {
    name: "resetAllowedInvestors",
    signature: "resetAllowedInvestors(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xabc30a4b": {
    name: "resetCompanyHomepage",
    signature: "resetCompanyHomepage(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x51aebcd4": {
    name: "resetCompanyLegalDocs",
    signature: "resetCompanyLegalDocs(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0xac893db1": {
    name: "resetShareCertificate",
    signature: "resetShareCertificate(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0xc319e41c": {
    name: "setWhitelistAuthorityStatus",
    signature: "setWhitelistAuthorityStatus(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xa1ac727f": {
    name: "airdrop",
    signature: "airdrop(address,address[],uint256)",
    input_types: ["address", "address[]", "uint256"],
    output_aliases: ["to", "addresses", "amount"],
  },
  "0x71b9189c": {
    name: "excludeFromFee",
    signature: "excludeFromFee(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0xf259c57b": {
    name: "excludeMultiple",
    signature: "excludeMultiple(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x5d54c0af": {
    name: "manage_Snipers",
    signature: "manage_Snipers(address[],bool)",
    input_types: ["address[]", "bool"],
    output_aliases: ["addresses", "bool"],
  },
  "0x84530c46": {
    name: "manage_trusted",
    signature: "manage_trusted(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0x2d4103d6": {
    name: "openTrading",
    signature: "openTrading(bool,uint256)",
    input_types: ["bool", "uint256"],
    output_aliases: ["bool", "amount"],
  },
  "0x2dd5efe7": {
    name: "setMaxWalletExempt",
    signature: "setMaxWalletExempt(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x09302dc6": {
    name: "setMaxWalletPercent_base1000",
    signature: "setMaxWalletPercent_base1000(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x8f4590d2": {
    name: "setSwapSettings",
    signature: "setSwapSettings(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xa3b84b6b": {
    name: "setTaxesBuy",
    signature: "setTaxesBuy(uint256,uint256,uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256", "uint256", "uint256"],
    output_aliases: [
      "amount_a",
      "amount_b",
      "amount_c",
      "amount_d",
      "amount_e",
    ],
  },
  "0x6ce37fa0": {
    name: "setTaxesSell",
    signature: "setTaxesSell(uint256,uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c", "amount_d"],
  },
  "0x4b01547f": {
    name: "setZeroBuyTaxmode",
    signature: "setZeroBuyTaxmode(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x743942b6": {
    name: "withDrawLeftoverETH",
    signature: "withDrawLeftoverETH(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x25b86edf": {
    name: "withdrawStuckTokens",
    signature: "withdrawStuckTokens(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0x22f5ff90": {
    name: "SetFeeOnSells",
    signature: "SetFeeOnSells(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x2f9c4569": {
    name: "allowPreTrading",
    signature: "allowPreTrading(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x1dcfc3a7": {
    name: "setIsExcludefromFees",
    signature: "setIsExcludefromFees(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0xd89a0412": {
    name: "excludeAddFromFee",
    signature: "excludeAddFromFee(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x0b8f1ff8": {
    name: "reduceFeeToHalf",
    signature: "reduceFeeToHalf()",
    input_types: [],
    output_aliases: [],
  },
  "0x6b35b93d": {
    name: "setFeeToZero",
    signature: "setFeeToZero()",
    input_types: [],
    output_aliases: [],
  },
  "0xb62b343d": {
    name: "setOriginalFee",
    signature: "setOriginalFee()",
    input_types: [],
    output_aliases: [],
  },
  "0x4cd287f2": {
    name: "setSellersFee",
    signature: "setSellersFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xac1147fc": {
    name: "SendIt",
    signature: "SendIt()",
    input_types: [],
    output_aliases: [],
  },
  "0x4d3a18b7": {
    name: "SetBots",
    signature: "SetBots(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0x61e5698a": {
    name: "setBTCRewardsFee",
    signature: "setBTCRewardsFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x5ebf4db9": {
    name: "setMinimumTokenBalanceForDividends",
    signature: "setMinimumTokenBalanceForDividends(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xba045c6e": {
    name: "setSwaptokensatAmount",
    signature: "setSwaptokensatAmount(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x1846f51a": {
    name: "withdrawLeftOver",
    signature: "withdrawLeftOver()",
    input_types: [],
    output_aliases: [],
  },
  "0x522644df": {
    name: "_setMaxHolding",
    signature: "_setMaxHolding(uint8)",
    input_types: ["uint8"],
    output_aliases: ["num"],
  },
  "0xd0040d70": {
    name: "addTaxWhitelist",
    signature: "addTaxWhitelist(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x91f85fda": {
    name: "emergencySwap",
    signature: "emergencySwap()",
    input_types: [],
    output_aliases: [],
  },
  "0xa98f6f90": {
    name: "launch",
    signature: "launch(uint8)",
    input_types: ["uint8"],
    output_aliases: ["num"],
  },
  "0x98d5a5cb": {
    name: "rmBlacklist",
    signature: "rmBlacklist(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x53477d29": {
    name: "rmTaxWhitelist",
    signature: "rmTaxWhitelist(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xf37c4bce": {
    name: "setWebThree",
    signature: "setWebThree(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xab9562fe": {
    name: "updateDevWallet",
    signature: "updateDevWallet(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x1c815b49": {
    name: "updateTaxAlloc",
    signature: "updateTaxAlloc(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x837381fa": {
    name: "withdrawFromContract",
    signature: "withdrawFromContract(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x20189d28": {
    name: "renounceOwnership",
    signature: "renounceOwnership(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x613c22c9": {
    name: "_setMaxWallet",
    signature: "_setMaxWallet(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x5353a2d8": {
    name: "changeName",
    signature: "changeName(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x5881f3ef": {
    name: "changeRouterVersion",
    signature: "changeRouterVersion(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xa3895fff": {
    name: "changeSymbol",
    signature: "changeSymbol(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0xc4a9baed": {
    name: "setAddressFee",
    signature: "setAddressFee(address,bool,uint256,uint256)",
    input_types: ["address", "bool", "uint256", "uint256"],
    output_aliases: ["to_a", "bool_b", "amount_c", "amount_d"],
  },
  "0xafabc8c6": {
    name: "setBuyAddressFee",
    signature: "setBuyAddressFee(address,bool,uint256,uint256)",
    input_types: ["address", "bool", "uint256", "uint256"],
    output_aliases: ["to_a", "bool_b", "amount_c", "amount_d"],
  },
  "0xe79b0322": {
    name: "SetBuyBackDivisor",
    signature: "SetBuyBackDivisor(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x535acb7f": {
    name: "setBuyBackSellLimit",
    signature: "setBuyBackSellLimit(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xb0cb81f1": {
    name: "setMarketingDivisor",
    signature: "setMarketingDivisor(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xb14869fb": {
    name: "setNumTokensSellToAddToBuyBack",
    signature: "setNumTokensSellToAddToBuyBack(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x4906b560": {
    name: "setSellAddressFee",
    signature: "setSellAddressFee(address,bool,uint256,uint256)",
    input_types: ["address", "bool", "uint256", "uint256"],
    output_aliases: ["to_a", "bool_b", "amount_c", "amount_d"],
  },
  "0x7c6a5501": {
    name: "updateBotTrading",
    signature: "updateBotTrading(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xdd4094b2": {
    name: "updateBotWallet",
    signature: "updateBotWallet(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x47f68795": {
    name: "updateTrading",
    signature: "updateTrading(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xd29d44ee": {
    name: "_transferOwnership",
    signature: "_transferOwnership(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x23c90b9e": {
    name: "afterAirDrop",
    signature: "afterAirDrop()",
    input_types: [],
    output_aliases: [],
  },
  "0x045f7850": {
    name: "airDrop",
    signature: "airDrop(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xa5071ef9": {
    name: "prepareForAirdrop",
    signature: "prepareForAirdrop()",
    input_types: [],
    output_aliases: [],
  },
  "0xfd7711de": {
    name: "setAllFeePercent",
    signature: "setAllFeePercent(uint16,uint16,uint16,uint16,uint16,uint16)",
    input_types: ["uint16", "uint16", "uint16", "uint16", "uint16", "uint16"],
    output_aliases: ["num_a", "num_b", "num_c", "num_d", "num_e", "num_f"],
  },
  "0x1c4ba3ed": {
    name: "setBurnWallet",
    signature: "setBurnWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xc43b2472": {
    name: "setInvestmentWallet",
    signature: "setInvestmentWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x2a517209": {
    name: "setMaxRestrictionPercentage",
    signature: "setMaxRestrictionPercentage(uint16)",
    input_types: ["uint16"],
    output_aliases: ["num"],
  },
  "0x3de1596d": {
    name: "setMaxTxPercentage",
    signature: "setMaxTxPercentage(uint16)",
    input_types: ["uint16"],
    output_aliases: ["num"],
  },
  "0xbe4b1772": {
    name: "withdrawToken",
    signature: "withdrawToken(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0x93c74657": {
    name: "automaticSwap",
    signature: "automaticSwap()",
    input_types: [],
    output_aliases: [],
  },
  "0x35dbcd25": {
    name: "removeBuyTax",
    signature: "removeBuyTax(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0xdc1052e2": {
    name: "setBuyTax",
    signature: "setBuyTax(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x362a3c5d": {
    name: "_amnestySniper",
    signature: "_amnestySniper(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xdb2e21bc": {
    name: "emergencyWithdraw",
    signature: "emergencyWithdraw()",
    input_types: [],
    output_aliases: [],
  },
  "0x8203f5fe": {
    name: "initContract",
    signature: "initContract()",
    input_types: [],
    output_aliases: [],
  },
  "0xb210b06d": {
    name: "clearBuybackMultiplier",
    signature: "clearBuybackMultiplier()",
    input_types: [],
    output_aliases: [],
  },
  "0x048c7baf": {
    name: "setAutoBuybackSettings",
    signature: "setAutoBuybackSettings(bool,uint256,uint256,uint256)",
    input_types: ["bool", "uint256", "uint256", "uint256"],
    output_aliases: ["bool_a", "amount_b", "amount_c", "amount_d"],
  },
  "0x17d43583": {
    name: "setBuyBacker",
    signature: "setBuyBacker(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x2375ce40": {
    name: "setBuybackMultiplierSettings",
    signature: "setBuybackMultiplierSettings(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0x2d48e896": {
    name: "setDistributionCriteria",
    signature: "setDistributionCriteria(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x9d1944f5": {
    name: "setDistributorSettings",
    signature: "setDistributorSettings(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xf708a64f": {
    name: "setIsDividendExempt",
    signature: "setIsDividendExempt(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0xf5cfec0a": {
    name: "triggerZeusBuyback",
    signature: "triggerZeusBuyback(uint256,bool)",
    input_types: ["uint256", "bool"],
    output_aliases: ["amount", "bool"],
  },
  "0x22d1e8d8": {
    name: "setBF",
    signature: "setBF(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0x0e6c704f": {
    name: "setSF",
    signature: "setSF(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0x89701db5": {
    name: "processFees",
    signature: "processFees(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x357bf15c": {
    name: "setLiquidityFee",
    signature: "setLiquidityFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x72bc5583": {
    name: "setLiquidityOwner",
    signature: "setLiquidityOwner(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xdca74a19": {
    name: "setProcessFeesEnabled",
    signature: "setProcessFeesEnabled(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xb98b677f": {
    name: "updateSwapRouter",
    signature: "updateSwapRouter(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xffecf516": {
    name: "addBot",
    signature: "addBot(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x643620da": {
    name: "HappyHour",
    signature: "HappyHour()",
    input_types: [],
    output_aliases: [],
  },
  "0x29215b96": {
    name: "HappyHourOff",
    signature: "HappyHourOff()",
    input_types: [],
    output_aliases: [],
  },
  "0xbf5abeb2": {
    name: "MaxWalletOff",
    signature: "MaxWalletOff()",
    input_types: [],
    output_aliases: [],
  },
  "0x1bb5aea8": {
    name: "MaxWalletOn",
    signature: "MaxWalletOn()",
    input_types: [],
    output_aliases: [],
  },
  "0x56cd40e7": {
    name: "RemoveLimits",
    signature: "RemoveLimits()",
    input_types: [],
    output_aliases: [],
  },
  "0x2cf0d13d": {
    name: "ResumeLimits",
    signature: "ResumeLimits()",
    input_types: [],
    output_aliases: [],
  },
  "0x1e20cc9b": {
    name: "setBF",
    signature: "setBF(uint256,uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c", "amount_d"],
  },
  "0x69d03738": {
    name: "setNFTAddress",
    signature: "setNFTAddress(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x9ce716f9": {
    name: "setSF",
    signature: "setSF(uint256,uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c", "amount_d"],
  },
  "0xc9259851": {
    name: "StartLaunchInit",
    signature: "StartLaunchInit()",
    input_types: [],
    output_aliases: [],
  },
  "0xf47a8b56": {
    name: "StopLaunchInit",
    signature: "StopLaunchInit()",
    input_types: [],
    output_aliases: [],
  },
  "0x620b4bfc": {
    name: "TaxSwapDisable",
    signature: "TaxSwapDisable()",
    input_types: [],
    output_aliases: [],
  },
  "0x6e86ea8b": {
    name: "TaxSwapEnable",
    signature: "TaxSwapEnable()",
    input_types: [],
    output_aliases: [],
  },
  "0x5a96ac0a": {
    name: "pullManagement",
    signature: "pullManagement()",
    input_types: [],
    output_aliases: [],
  },
  "0x46f68ee9": {
    name: "pushManagement",
    signature: "pushManagement(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x058ecdb4": {
    name: "rebase",
    signature: "rebase(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x089208d8": {
    name: "renounceManagement",
    signature: "renounceManagement()",
    input_types: [],
    output_aliases: [],
  },
  "0x40a5737f": {
    name: "setIndex",
    signature: "setIndex(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xcd3293de": {
    name: "reserve",
    signature: "reserve()",
    input_types: [],
    output_aliases: [],
  },
  "0xd0eb26b0": {
    name: "setNftPerAddressLimit",
    signature: "setNftPerAddressLimit(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xb548f2de": {
    name: "setSignatureVerifiedWhitelist",
    signature: "setSignatureVerifiedWhitelist(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x61905807": {
    name: "unWhitelistAdress",
    signature: "unWhitelistAdress(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0xb31d61b0": {
    name: "whitelistAddress",
    signature: "whitelistAddress(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0x30d583d7": {
    name: "mint",
    signature: "mint(uint256,uint256,string,uint256,bytes)",
    input_types: ["uint256", "uint256", "string", "uint256", "bytes"],
    output_aliases: ["amount_a", "amount_b", "string_c", "amount_d", "bytes_e"],
  },
  "0xccd1f6ea": {
    name: "LimitBuy",
    signature: "LimitBuy(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x40c70fbf": {
    name: "MaxWallet",
    signature: "MaxWallet(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xfc342279": {
    name: "swap",
    signature: "swap(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xdbc1d6ca": {
    name: "forceRewardsDistribution",
    signature: "forceRewardsDistribution(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x9dc67615": {
    name: "giveMeWelfarePlease",
    signature: "giveMeWelfarePlease()",
    input_types: [],
    output_aliases: [],
  },
  "0x6ab86dc1": {
    name: "manualDeposit",
    signature: "manualDeposit()",
    input_types: [],
    output_aliases: [],
  },
  "0xdb40e302": {
    name: "setBoostTime",
    signature: "setBoostTime(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x1de3b1c7": {
    name: "setBoostTimeEnabled",
    signature: "setBoostTimeEnabled(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xdea528a1": {
    name: "setContractSwapSettings",
    signature: "setContractSwapSettings(bool,bool)",
    input_types: ["bool", "bool"],
    output_aliases: ["bool_a", "bool_b"],
  },
  "0x6b639a25": {
    name: "setDividendExcluded",
    signature: "setDividendExcluded(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x36fddb04": {
    name: "setExcludedFromLimits",
    signature: "setExcludedFromLimits(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x218950bc": {
    name: "setInitializers",
    signature: "setInitializers(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0x0712d165": {
    name: "setRatios",
    signature: "setRatios(uint16,uint16,uint16,uint16)",
    input_types: ["uint16", "uint16", "uint16", "uint16"],
    output_aliases: ["num_a", "num_b", "num_c", "num_d"],
  },
  "0x09a8f179": {
    name: "setReflectionCriteria",
    signature: "setReflectionCriteria(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0xd0b1449d": {
    name: "setReflectorSettings",
    signature: "setReflectorSettings(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x34cd8260": {
    name: "setSwapSettings",
    signature: "setSwapSettings(uint256,uint256,uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256", "uint256", "uint256"],
    output_aliases: [
      "amount_a",
      "amount_b",
      "amount_c",
      "amount_d",
      "amount_e",
    ],
  },
  "0xecfc021f": {
    name: "setTaxes",
    signature: "setTaxes(uint16,uint16,uint16,uint16)",
    input_types: ["uint16", "uint16", "uint16", "uint16"],
    output_aliases: ["num_a", "num_b", "num_c", "num_d"],
  },
  "0x4c09aa98": {
    name: "reserveNFTs",
    signature: "reserveNFTs(address[],uint256[])",
    input_types: ["address[]", "uint256[]"],
    output_aliases: ["addresses", "amounts"],
  },
  "0x36655f6a": {
    name: "setMintingOpened",
    signature: "setMintingOpened(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x242877c5": {
    name: "setEndingURI",
    signature: "setEndingURI(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0xadfdeef9": {
    name: "setProxyRegistry",
    signature: "setProxyRegistry(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xe1e62b02": {
    name: "setWLMintPrice",
    signature: "setWLMintPrice(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x37bc4c0b": {
    name: "whitelistMint",
    signature: "whitelistMint(bytes)",
    input_types: ["bytes"],
    output_aliases: ["bytes"],
  },
  "0xff897570": {
    name: "addToBlackList",
    signature: "addToBlackList(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0xd54994db": {
    name: "setDefaultMarketingFee",
    signature: "setDefaultMarketingFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x57e0a1d0": {
    name: "setFeesOnSellersAndBuyers",
    signature: "setFeesOnSellersAndBuyers(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xa08f6760": {
    name: "setMarketingFee4Sellers",
    signature: "setMarketingFee4Sellers(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xdab522a8": {
    name: "setnumTokensToExchangeForMarketing",
    signature: "setnumTokensToExchangeForMarketing(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x2663236f": {
    name: "setSwapAndSendEnabled",
    signature: "setSwapAndSendEnabled(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x899e8689": {
    name: "afterPresale",
    signature: "afterPresale()",
    input_types: [],
    output_aliases: [],
  },
  "0xde87e0a5": {
    name: "prepareForPresale",
    signature: "prepareForPresale()",
    input_types: [],
    output_aliases: [],
  },
  "0x068dde72": {
    name: "setBuyBackDivisor",
    signature: "setBuyBackDivisor(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xb671b96e": {
    name: "SetBuyBackUpperLimitAmount",
    signature: "SetBuyBackUpperLimitAmount(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x9f34a5b4": {
    name: "setMaxWalletTokend",
    signature: "setMaxWalletTokend(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x6e34c796": {
    name: "setMaxPerTx",
    signature: "setMaxPerTx(uint8)",
    input_types: ["uint8"],
    output_aliases: ["num"],
  },
  "0xabf08ae1": {
    name: "setMaxPerWallet",
    signature: "setMaxPerWallet(uint8)",
    input_types: ["uint8"],
    output_aliases: ["num"],
  },
  "0x06421c2f": {
    name: "setMaxSupply",
    signature: "setMaxSupply(uint16)",
    input_types: ["uint16"],
    output_aliases: ["num"],
  },
  "0xc219c2ef": {
    name: "setPublicMintStatus",
    signature: "setPublicMintStatus()",
    input_types: [],
    output_aliases: [],
  },
  "0xb8da5561": {
    name: "setReachedCapPrice",
    signature: "setReachedCapPrice(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x1919fed7": {
    name: "setSalePrice",
    signature: "setSalePrice(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x858e83b5": {
    name: "publicMint",
    signature: "publicMint(uint8)",
    input_types: ["uint8"],
    output_aliases: ["num"],
  },
  "0x0e7251e3": {
    name: "setCharityFeePercentage",
    signature: "setCharityFeePercentage(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x30563bd7": {
    name: "setCharityWallet",
    signature: "setCharityWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xf2c19d67": {
    name: "setTeamFeePercentage",
    signature: "setTeamFeePercentage(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x2e6ed7ef": {
    name: "updateBuyFees",
    signature: "updateBuyFees(uint256,uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c", "amount_d"],
  },
  "0x106b5da1": {
    name: "updateMaxAmount",
    signature: "updateMaxAmount(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xe7ad9fcd": {
    name: "updateSellFees",
    signature: "updateSellFees(uint256,uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c", "amount_d"],
  },
  "0x7c519ffb": {
    name: "setTrading",
    signature: "setTrading()",
    input_types: [],
    output_aliases: [],
  },
  "0xfb0a8fc3": {
    name: "bulkBlacklist",
    signature: "bulkBlacklist(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0xe4997dc5": {
    name: "removeBlackList",
    signature: "removeBlackList(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x0b090639": {
    name: "removeBulkBlacklist",
    signature: "removeBulkBlacklist(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0x89afcb44": {
    name: "burn",
    signature: "burn(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x153b0d1e": {
    name: "setBlacklist",
    signature: "setBlacklist(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0xe6f7ef4d": {
    name: "setBlocksToBlacklist",
    signature: "setBlocksToBlacklist(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x296f0a0c": {
    name: "setLiquidityWallet",
    signature: "setLiquidityWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xf34eb0b8": {
    name: "setMaxBuyAmount",
    signature: "setMaxBuyAmount(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xe99c9d09": {
    name: "setMaxSellAmount",
    signature: "setMaxSellAmount(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x8a780447": {
    name: "setProjectWallet",
    signature: "setProjectWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x5958621e": {
    name: "setRewardWallet",
    signature: "setRewardWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xafa4f3b2": {
    name: "setSwapTokensAtAmount",
    signature: "setSwapTokensAtAmount(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x47298f82": {
    name: "confiscate",
    signature: "confiscate(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xb31470a4": {
    name: "bulkAntiBot",
    signature: "bulkAntiBot(address[],bool)",
    input_types: ["address[]", "bool"],
    output_aliases: ["addresses", "bool"],
  },
  "0x0d65df9b": {
    name: "rescueAnyERC20Tokens",
    signature: "rescueAnyERC20Tokens(address,address,uint256)",
    input_types: ["address", "address", "uint256"],
    output_aliases: ["to_a", "to_b", "amount_c"],
  },
  "0xe1165545": {
    name: "setAntibot",
    signature: "setAntibot(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x04d4c990": {
    name: "setTaxes",
    signature: "setTaxes(uint256,uint256,uint256,uint256,uint256,uint256)",
    input_types: [
      "uint256",
      "uint256",
      "uint256",
      "uint256",
      "uint256",
      "uint256",
    ],
    output_aliases: [
      "amount_a",
      "amount_b",
      "amount_c",
      "amount_d",
      "amount_e",
      "amount_f",
    ],
  },
  "0xd5ccb8ee": {
    name: "setTradingState",
    signature: "setTradingState(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x49244cb0": {
    name: "updatecharityWallet",
    signature: "updatecharityWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x8c0ae3df": {
    name: "updateCoolDownSettings",
    signature: "updateCoolDownSettings(bool,uint256)",
    input_types: ["bool", "uint256"],
    output_aliases: ["bool", "amount"],
  },
  "0xfc170441": {
    name: "updatedevWallet",
    signature: "updatedevWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xbff78169": {
    name: "updatemarketingWallet",
    signature: "updatemarketingWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x40b28c2f": {
    name: "updateRouterAndPair",
    signature: "updateRouterAndPair(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0xf965aa1d": {
    name: "updatMaxTxAmt",
    signature: "updatMaxTxAmt(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x2b7581b2": {
    name: "_setBuyTax",
    signature: "_setBuyTax(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xdbe8272c": {
    name: "_setSellTax",
    signature: "_setSellTax(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x3e9ffbea": {
    name: "swapAll",
    signature: "swapAll()",
    input_types: [],
    output_aliases: [],
  },
  "0x807273ef": {
    name: "Sweep",
    signature: "Sweep(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x670a6fd9": {
    name: "updateAdmin",
    signature: "updateAdmin(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x4a6420a7": {
    name: "updateRouteAndPair",
    signature: "updateRouteAndPair(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0x9991c39b": {
    name: "updateSwapFeesAt",
    signature: "updateSwapFeesAt(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xb92787e3": {
    name: "updateTaxExcluded",
    signature: "updateTaxExcluded(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0xd762a37b": {
    name: "updateTransferLimit",
    signature: "updateTransferLimit(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x4a8cdd74": {
    name: "limitedbuys",
    signature: "limitedbuys(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x9c5e92fb": {
    name: "mint",
    signature: "mint(uint16[])",
    input_types: ["uint16[]"],
    output_aliases: ["nums"],
  },
  "0x092501a0": {
    name: "setCommission",
    signature: "setCommission(uint32)",
    input_types: ["uint32"],
    output_aliases: ["num"],
  },
  "0xf8048d8f": {
    name: "setMaxPerMint",
    signature: "setMaxPerMint(uint32)",
    input_types: ["uint32"],
    output_aliases: ["num"],
  },
  "0x3006b543": {
    name: "setMaxPerWallet",
    signature: "setMaxPerWallet(uint32)",
    input_types: ["uint32"],
    output_aliases: ["num"],
  },
  "0x1cf6e5b4": {
    name: "refundETH",
    signature: "refundETH(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x22fd64a0": {
    name: "refundToken",
    signature: "refundToken(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0x75e3661e": {
    name: "unblacklist",
    signature: "unblacklist(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x704b6c02": {
    name: "setAdmin",
    signature: "setAdmin(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xb0fc29e6": {
    name: "setLock",
    signature: "setLock(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x1d539764": {
    name: "setLockList",
    signature: "setLockList(address[],uint256[])",
    input_types: ["address[]", "uint256[]"],
    output_aliases: ["addresses", "amounts"],
  },
  "0x859bc2f3": {
    name: "transferList",
    signature: "transferList(address[],uint256[])",
    input_types: ["address[]", "uint256[]"],
    output_aliases: ["addresses", "amounts"],
  },
  "0x4d253b50": {
    name: "unsetAdmin",
    signature: "unsetAdmin(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x892ba408": {
    name: "delSnipers",
    signature: "delSnipers(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0xb63c6f84": {
    name: "fireSale",
    signature: "fireSale()",
    input_types: [],
    output_aliases: [],
  },
  "0x0ec4c619": {
    name: "resetFees",
    signature: "resetFees()",
    input_types: [],
    output_aliases: [],
  },
  "0xc519b8ee": {
    name: "setfireSaleRequirement",
    signature: "setfireSaleRequirement(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x96dde6a6": {
    name: "setResetRequirement",
    signature: "setResetRequirement(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x5e80148e": {
    name: "setSnipers",
    signature: "setSnipers(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0x66718524": {
    name: "updateFeeWallet",
    signature: "updateFeeWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x476343ee": {
    name: "withdrawFees",
    signature: "withdrawFees()",
    input_types: [],
    output_aliases: [],
  },
  "0x4838d165": {
    name: "blackList",
    signature: "blackList(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xc3033aeb": {
    name: "blackListMany",
    signature: "blackListMany(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0x0483f7a0": {
    name: "excludeFromDividends",
    signature: "excludeFromDividends(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0xd4c989d3": {
    name: "excludeFromMaxTx",
    signature: "excludeFromMaxTx(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0xd2fcc001": {
    name: "excludeFromMaxWallet",
    signature: "excludeFromMaxWallet(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x8e126944": {
    name: "manualSendDividend",
    signature: "manualSendDividend(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0x537df3b6": {
    name: "removeFromBlacklist",
    signature: "removeFromBlacklist(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x9e252f00": {
    name: "rescueETH",
    signature: "rescueETH(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x33f3d628": {
    name: "rescueToken",
    signature: "rescueToken(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xe4956ce2": {
    name: "setCompoundingEnabled",
    signature: "setCompoundingEnabled(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x5b65b9ab": {
    name: "setFee",
    signature: "setFee(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0xaa4e8c4a": {
    name: "setMaxTxBPS",
    signature: "setMaxTxBPS(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x68c51e35": {
    name: "setMaxWalletBPS",
    signature: "setMaxWalletBPS(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xc6af580b": {
    name: "setTaxEnabled",
    signature: "setTaxEnabled(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xf1b234ad": {
    name: "setWallet",
    signature: "setWallet(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0xf4571c49": {
    name: "unBlackListMany",
    signature: "unBlackListMany(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0x5e843ad2": {
    name: "updateDividendSettings",
    signature: "updateDividendSettings(bool,uint256,bool)",
    input_types: ["bool", "uint256", "bool"],
    output_aliases: ["bool_a", "amount_b", "bool_c"],
  },
  "0xa404792c": {
    name: "createShare",
    signature: "createShare(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x3edac5a7": {
    name: "removeShare",
    signature: "removeShare(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x7c000293": {
    name: "setRewardsPool",
    signature: "setRewardsPool(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xe43252d7": {
    name: "addToWhitelist",
    signature: "addToWhitelist(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x3c49a8a9": {
    name: "adminMintGiveaways",
    signature: "adminMintGiveaways(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x7bd6f0cc": {
    name: "changeWhiteListPrice",
    signature: "changeWhiteListPrice(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x49274ec1": {
    name: "setAllowedMintedToken",
    signature: "setAllowedMintedToken(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x300b23d8": {
    name: "setMaxBatch",
    signature: "setMaxBatch(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x917bb57f": {
    name: "setNormalStart",
    signature: "setNormalStart(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x57df110a": {
    name: "setTotalCount",
    signature: "setTotalCount(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x5f4f603d": {
    name: "setWhiteListStart",
    signature: "setWhiteListStart(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xc151a5f8": {
    name: "mintMultipleByOwner",
    signature: "mintMultipleByOwner(address[],uint256[])",
    input_types: ["address[]", "uint256[]"],
    output_aliases: ["addresses", "amounts"],
  },
  "0x5f0f45b2": {
    name: "revealNow",
    signature: "revealNow()",
    input_types: [],
    output_aliases: [],
  },
  "0x9a5b2f00": {
    name: "setGiveAwayActive",
    signature: "setGiveAwayActive(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x2750fc78": {
    name: "setIsActive",
    signature: "setIsActive(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x3f8121a2": {
    name: "setPresaleActive",
    signature: "setPresaleActive(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xf2f5a7e5": {
    name: "setRoot",
    signature: "setRoot(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xe8254174": {
    name: "setURIs",
    signature: "setURIs(string,string)",
    input_types: ["string", "string"],
    output_aliases: ["string_a", "string_b"],
  },
  "0x8f76696c": {
    name: "mintNFTDuringPresale",
    signature: "mintNFTDuringPresale(uint256,bytes32[])",
    input_types: ["uint256", "bytes32[]"],
    output_aliases: ["amount", "b32s"],
  },
  "0x5a4fee30": {
    name: "batchSafeTransferFrom",
    signature: "batchSafeTransferFrom(address,address,uint256[],bytes)",
    input_types: ["address", "address", "uint256[]", "bytes"],
    output_aliases: ["to_a", "to_b", "amounts_c", "bytes_d"],
  },
  "0xf3993d11": {
    name: "batchTransferFrom",
    signature: "batchTransferFrom(address,address,uint256[])",
    input_types: ["address", "address", "uint256[]"],
    output_aliases: ["to_a", "to_b", "amounts_c"],
  },
  "0xccaf1a82": {
    name: "reserveForTeam",
    signature: "reserveForTeam(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x1f85e3ca": {
    name: "setMintStatus",
    signature: "setMintStatus(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x519a2044": {
    name: "updateRoyaltyAddress",
    signature: "updateRoyaltyAddress(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x33f2fa9f": {
    name: "transferNonFungibleToken",
    signature:
      "transferNonFungibleToken(address,address,address,uint256,uint256)",
    input_types: ["address", "address", "address", "uint256", "uint256"],
    output_aliases: ["to_a", "to_b", "to_c", "amount_d", "amount_e"],
  },
  "0x46a49513": {
    name: "setBothSignerAddresses",
    signature: "setBothSignerAddresses(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0xb2e5e2da": {
    name: "setPrivateSignerAddress",
    signature: "setPrivateSignerAddress(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x9ec70f19": {
    name: "buy",
    signature: "buy(bytes32,bytes,string,uint256)",
    input_types: ["bytes32", "bytes", "string", "uint256"],
    output_aliases: ["b32", "bytes", "string", "amount"],
  },
  "0x105790fc": {
    name: "privateBuy",
    signature: "privateBuy(bytes32,bytes,string,uint256)",
    input_types: ["bytes32", "bytes", "string", "uint256"],
    output_aliases: ["b32", "bytes", "string", "amount"],
  },
  "0x88d15d50": {
    name: "freeMint",
    signature: "freeMint(bytes32[])",
    input_types: ["bytes32[]"],
    output_aliases: ["b32s"],
  },
  "0xacee9c4d": {
    name: "reserveMint",
    signature: "reserveMint(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x34be402b": {
    name: "setFreelistMerkleRoot",
    signature: "setFreelistMerkleRoot(bytes32)",
    input_types: ["bytes32"],
    output_aliases: ["b32"],
  },
  "0xa43baa3d": {
    name: "setOpenToPublic",
    signature: "setOpenToPublic(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xffe630b5": {
    name: "setProvenance",
    signature: "setProvenance(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0xbd32fb66": {
    name: "setWhitelistMerkleRoot",
    signature: "setWhitelistMerkleRoot(bytes32)",
    input_types: ["bytes32"],
    output_aliases: ["b32"],
  },
  "0xbdac8c98": {
    name: "setWhitelistPhase",
    signature: "setWhitelistPhase(uint8)",
    input_types: ["uint8"],
    output_aliases: ["num"],
  },
  "0x53323bc6": {
    name: "addToLuckylist",
    signature: "addToLuckylist(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xcae9185b": {
    name: "allowLuckyMint",
    signature: "allowLuckyMint()",
    input_types: [],
    output_aliases: [],
  },
  "0x1b8dca74": {
    name: "allowMint",
    signature: "allowMint()",
    input_types: [],
    output_aliases: [],
  },
  "0xff7aa51f": {
    name: "allowWhitelistMint",
    signature: "allowWhitelistMint()",
    input_types: [],
    output_aliases: [],
  },
  "0x77924960": {
    name: "forbidLuckyMint",
    signature: "forbidLuckyMint()",
    input_types: [],
    output_aliases: [],
  },
  "0x1adcce2c": {
    name: "forbidMint",
    signature: "forbidMint()",
    input_types: [],
    output_aliases: [],
  },
  "0xcebb91a0": {
    name: "forbidWhitelistMint",
    signature: "forbidWhitelistMint()",
    input_types: [],
    output_aliases: [],
  },
  "0xe8ee8b76": {
    name: "mintLucky",
    signature: "mintLucky(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x93f84cfe": {
    name: "mintTeam",
    signature: "mintTeam(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x9f449fa1": {
    name: "pickRandomWinnerAddress",
    signature: "pickRandomWinnerAddress()",
    input_types: [],
    output_aliases: [],
  },
  "0x5364bfad": {
    name: "setLuckylist",
    signature: "setLuckylist(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0x08ed665b": {
    name: "setMaxSupplyPublic",
    signature: "setMaxSupplyPublic(uint32)",
    input_types: ["uint32"],
    output_aliases: ["num"],
  },
  "0x15ebd123": {
    name: "setMintPriceWei",
    signature: "setMintPriceWei(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x23c0f2cd": {
    name: "setWalletLuckyMintLimit",
    signature: "setWalletLuckyMintLimit(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x70517ed4": {
    name: "setWalletMintLimit",
    signature: "setWalletMintLimit(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x213e5a8a": {
    name: "setWalletWhitelistMintLimit",
    signature: "setWalletWhitelistMintLimit(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xfbd00f88": {
    name: "setWhitelistMintPriceWei",
    signature: "setWhitelistMintPriceWei(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x750521f5": {
    name: "setMetadataURI",
    signature: "setMetadataURI(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x02637603": {
    name: "mintUnsoldTokens",
    signature: "mintUnsoldTokens(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x71beeba1": {
    name: "setBreedPrice",
    signature: "setBreedPrice(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x76c05eab": {
    name: "setCountLimit",
    signature: "setCountLimit(uint256[])",
    input_types: ["uint256[]"],
    output_aliases: ["amounts"],
  },
  "0x6d6de7e9": {
    name: "setOwner1Address",
    signature: "setOwner1Address(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xe39dd79c": {
    name: "setPrice",
    signature: "setPrice(uint256[])",
    input_types: ["uint256[]"],
    output_aliases: ["amounts"],
  },
  "0x61d1157a": {
    name: "setRootSign",
    signature: "setRootSign(bytes32)",
    input_types: ["bytes32"],
    output_aliases: ["b32"],
  },
  "0x044dd2c3": {
    name: "setRootVip",
    signature: "setRootVip(uint256,bytes32)",
    input_types: ["uint256", "bytes32"],
    output_aliases: ["amount", "b32"],
  },
  "0xbc389a3c": {
    name: "setStatusFlag",
    signature: "setStatusFlag(uint8)",
    input_types: ["uint8"],
    output_aliases: ["num"],
  },
  "0x1ab1d655": {
    name: "setTokenAddress",
    signature: "setTokenAddress(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xfc805d94": {
    name: "setUpgradePrice",
    signature: "setUpgradePrice(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x633c2010": {
    name: "mintBreedToken",
    signature: "mintBreedToken(bytes32[],address,string,uint256,uint256)",
    input_types: ["bytes32[]", "address", "string", "uint256", "uint256"],
    output_aliases: ["b32s_a", "to_b", "string_c", "amount_d", "amount_e"],
  },
  "0x3750c088": {
    name: "mintVip",
    signature: "mintVip(bytes32[],uint256)",
    input_types: ["bytes32[]", "uint256"],
    output_aliases: ["b32s", "amount"],
  },
  "0x778df4f8": {
    name: "setToken",
    signature:
      "setToken(bytes32[],address,uint256,string,uint256,uint256,uint256,bool)",
    input_types: [
      "bytes32[]",
      "address",
      "uint256",
      "string",
      "uint256",
      "uint256",
      "uint256",
      "bool",
    ],
    output_aliases: [
      "b32s_a",
      "to_b",
      "amount_c",
      "string_d",
      "amount_e",
      "amount_f",
      "amount_g",
      "bool_h",
    ],
  },
  "0xbbaac02f": {
    name: "setHiddenURI",
    signature: "setHiddenURI(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x8a2f76aa": {
    name: "toggleMintMain",
    signature: "toggleMintMain()",
    input_types: [],
    output_aliases: [],
  },
  "0x92cba58f": {
    name: "toggleMintWhitelist",
    signature: "toggleMintWhitelist()",
    input_types: [],
    output_aliases: [],
  },
  "0xdb7fd408": {
    name: "mint",
    signature: "mint(uint256,bytes)",
    input_types: ["uint256", "bytes"],
    output_aliases: ["amount", "bytes"],
  },
  "0x96ea3a47": {
    name: "gift",
    signature: "gift(uint256[],address[])",
    input_types: ["uint256[]", "address[]"],
    output_aliases: ["amounts", "addresses"],
  },
  "0x3b4c4b25": {
    name: "setSupply",
    signature: "setSupply(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x31367411": {
    name: "setAllowList",
    signature: "setAllowList(address[],bool)",
    input_types: ["address[]", "bool"],
    output_aliases: ["addresses", "bool"],
  },
  "0x467de36c": {
    name: "setBaseURIExtension",
    signature: "setBaseURIExtension(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x087b5c36": {
    name: "setMaxGiftSupply",
    signature: "setMaxGiftSupply(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x2fbba115": {
    name: "teamMint",
    signature: "teamMint(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x988934af": {
    name: "toggleAllowList",
    signature: "toggleAllowList()",
    input_types: [],
    output_aliases: [],
  },
  "0xedac985b": {
    name: "addWhitelist",
    signature: "addWhitelist(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0x53df5c7c": {
    name: "lockBaseURI",
    signature: "lockBaseURI()",
    input_types: [],
    output_aliases: [],
  },
  "0x5ca77e31": {
    name: "mintLegendary",
    signature: "mintLegendary(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0xcecb06d0": {
    name: "mintOwner",
    signature: "mintOwner()",
    input_types: [],
    output_aliases: [],
  },
  "0x23245216": {
    name: "removeWhitelist",
    signature: "removeWhitelist(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0x55fc9893": {
    name: "setCommonURI",
    signature: "setCommonURI(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x00bb16bd": {
    name: "setLegendary",
    signature: "setLegendary(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0xb2c94ee6": {
    name: "setPrefixURI",
    signature: "setPrefixURI(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0xe59ee014": {
    name: "setPreSaleStarted",
    signature: "setPreSaleStarted(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xa854ffba": {
    name: "setSaleStarted",
    signature: "setSaleStarted(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x09a3beef": {
    name: "setTokenURI",
    signature: "setTokenURI(string,uint256)",
    input_types: ["string", "uint256"],
    output_aliases: ["string", "amount"],
  },
  "0x60a896c7": {
    name: "setMaxNftPurchase",
    signature: "setMaxNftPurchase(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x7d9a7a4c": {
    name: "setNftPrice",
    signature: "setNftPrice(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x326d4388": {
    name: "setRevealedURI",
    signature: "setRevealedURI(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0xe7bc8208": {
    name: "freezeBaseURI",
    signature: "freezeBaseURI()",
    input_types: [],
    output_aliases: [],
  },
  "0x79f34a10": {
    name: "setmaxMintPerTx",
    signature: "setmaxMintPerTx(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x1ed40559": {
    name: "setSaleState",
    signature: "setSaleState()",
    input_types: [],
    output_aliases: [],
  },
  "0x9711715a": {
    name: "snapshot",
    signature: "snapshot()",
    input_types: [],
    output_aliases: [],
  },
  "0xd7c01032": {
    name: "setFeeReceivers",
    signature: "setFeeReceivers(address,address,address)",
    input_types: ["address", "address", "address"],
    output_aliases: ["to_a", "to_b", "to_c"],
  },
  "0x6fcba377": {
    name: "setFees",
    signature: "setFees(uint256,uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c", "amount_d"],
  },
  "0xd43f5d6c": {
    name: "justinCaseofClog",
    signature: "justinCaseofClog()",
    input_types: [],
    output_aliases: [],
  },
  "0x5fe7208c": {
    name: "manuallySwap",
    signature: "manuallySwap()",
    input_types: [],
    output_aliases: [],
  },
  "0xbcdb446b": {
    name: "recoverEth",
    signature: "recoverEth()",
    input_types: [],
    output_aliases: [],
  },
  "0xb29a8140": {
    name: "recoverToken",
    signature: "recoverToken(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xa4b45c00": {
    name: "setFeeReceivers",
    signature: "setFeeReceivers(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0xcec10c11": {
    name: "setFees",
    signature: "setFees(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0xdf20fd49": {
    name: "setSwapBackSettings",
    signature: "setSwapBackSettings(bool,uint256)",
    input_types: ["bool", "uint256"],
    output_aliases: ["bool", "amount"],
  },
  "0x66e25e2f": {
    name: "addToFreePass",
    signature: "addToFreePass(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0xaef6ee1f": {
    name: "addToPresale",
    signature: "addToPresale(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0xed1fc2a2": {
    name: "togglePresaleStarted",
    signature: "togglePresaleStarted()",
    input_types: [],
    output_aliases: [],
  },
  "0xc1d35b2a": {
    name: "mintFreePass",
    signature: "mintFreePass(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x0b7b4bef": {
    name: "enableWhitelistMinting",
    signature: "enableWhitelistMinting()",
    input_types: [],
    output_aliases: [],
  },
  "0x53a3f014": {
    name: "fuseTier3to2",
    signature: "fuseTier3to2(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x1342ff4c": {
    name: "reserveMint",
    signature: "reserveMint(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x2afc26de": {
    name: "setAuctionStartTime",
    signature: "setAuctionStartTime(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x6fb081a4": {
    name: "setMintPrices",
    signature: "setMintPrices(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0x1f278c5b": {
    name: "setrootHash",
    signature: "setrootHash(bytes32)",
    input_types: ["bytes32"],
    output_aliases: ["b32"],
  },
  "0x6f4f7366": {
    name: "setTraits",
    signature: "setTraits(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x6b64c769": {
    name: "startAuction",
    signature: "startAuction()",
    input_types: [],
    output_aliases: [],
  },
  "0x0e4feb57": {
    name: "fuseTier2to1",
    signature: "fuseTier2to1(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xc3943777": {
    name: "upgradeTier2to1",
    signature: "upgradeTier2to1(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xc0e95027": {
    name: "upgradeTier3to1",
    signature: "upgradeTier3to1(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x3809b16d": {
    name: "upgradeTier3to2",
    signature: "upgradeTier3to2(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x372f657c": {
    name: "whitelistMint",
    signature: "whitelistMint(bytes32[])",
    input_types: ["bytes32[]"],
    output_aliases: ["b32s"],
  },
  "0x116877cc": {
    name: "changeAuthority",
    signature: "changeAuthority(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x39a0c6f9": {
    name: "changeBaseURI",
    signature: "changeBaseURI(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0xa6f9dae1": {
    name: "changeOwner",
    signature: "changeOwner(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x35a2431c": {
    name: "mintReservedTokensToOwner",
    signature: "mintReservedTokensToOwner()",
    input_types: [],
    output_aliases: [],
  },
  "0x6b6384db": {
    name: "whitelistAddress",
    signature: "whitelistAddress(address[],uint256)",
    input_types: ["address[]", "uint256"],
    output_aliases: ["addresses", "amount"],
  },
  "0x9b642de1": {
    name: "setUri",
    signature: "setUri(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0xc7ed93f2": {
    name: "createCollectible",
    signature: "createCollectible(string,uint256,(uint8,bytes32,bytes32))",
    input_types: ["string", "uint256", "(uint8", "bytes32", "bytes32)"],
    output_aliases: ["string", "amount", "(uint8", "b32", "bytes32)"],
  },
  "0x6fdc202f": {
    name: "ownerTransfership",
    signature: "ownerTransfership(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x228025e8": {
    name: "setmaxSupply",
    signature: "setmaxSupply(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x07efbfdc": {
    name: "afterPreSale",
    signature: "afterPreSale()",
    input_types: [],
    output_aliases: [],
  },
  "0x5134f6ab": {
    name: "prepareForPreSale",
    signature: "prepareForPreSale()",
    input_types: [],
    output_aliases: [],
  },
  "0x6612e66f": {
    name: "setExcludedFromFee",
    signature: "setExcludedFromFee(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0xbd246615": {
    name: "setFeesDivisor",
    signature: "setFeesDivisor(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xbbf1af95": {
    name: "toggleAntiBot",
    signature: "toggleAntiBot(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xfd69c215": {
    name: "TransferETH",
    signature: "TransferETH(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x23b5192c": {
    name: "updateBuyingLaunchpadFee",
    signature: "updateBuyingLaunchpadFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x4844dd4a": {
    name: "updateBuyingLpFee",
    signature: "updateBuyingLpFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x65587302": {
    name: "updateBuyingMarketingFee",
    signature: "updateBuyingMarketingFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x78b0acf1": {
    name: "updateBuyingRfiFee",
    signature: "updateBuyingRfiFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xdb39b7d7": {
    name: "updateBuyingTeamFee",
    signature: "updateBuyingTeamFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x66b3e74c": {
    name: "updateLaunchpadWallet",
    signature: "updateLaunchpadWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x361ff9bf": {
    name: "updatePortionsOfSwap",
    signature: "updatePortionsOfSwap(uint256,uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c", "amount_d"],
  },
  "0x00e6be7b": {
    name: "updateRouterAddress",
    signature: "updateRouterAddress(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x77f7cb44": {
    name: "updateSellingLaunchpadFee",
    signature: "updateSellingLaunchpadFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x2325755e": {
    name: "updateSellingLpFee",
    signature: "updateSellingLpFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x53a4ec55": {
    name: "updateSellingMarketingFee",
    signature: "updateSellingMarketingFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xd6b6209a": {
    name: "updateSellingRfiFee",
    signature: "updateSellingRfiFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xf3ffadad": {
    name: "updateSellingTeamFee",
    signature: "updateSellingTeamFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xb225d4b7": {
    name: "updateSwapTokensAt",
    signature: "updateSwapTokensAt(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x7cb332bb": {
    name: "updateTeamWallet",
    signature: "updateTeamWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x1264c9c4": {
    name: "updateTradingIsEnabled",
    signature: "updateTradingIsEnabled(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x7d8dbe24": {
    name: "updateTransactionMax",
    signature: "updateTransactionMax(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x91053d94": {
    name: "updateWalletMax",
    signature: "updateWalletMax(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x4e83ff6f": {
    name: "whitelistDxSale",
    signature: "whitelistDxSale(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0x417c73a7": {
    name: "addToBlackList",
    signature: "addToBlackList(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xe74b981b": {
    name: "setFeeRecipient",
    signature: "setFeeRecipient(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xf42fe226": {
    name: "setMinimunTokenAmountToSwap",
    signature: "setMinimunTokenAmountToSwap(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x1f7ede2b": {
    name: "setReflectionFeePercent",
    signature: "setReflectionFeePercent(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x38a238dc": {
    name: "setSwapAndWithdrawEnabled",
    signature: "setSwapAndWithdrawEnabled(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x4a159256": {
    name: "setTxFeePercent",
    signature: "setTxFeePercent(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xc44516c0": {
    name: "updateUniRouter",
    signature: "updateUniRouter(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x4d423124": {
    name: "addAllowedDomain",
    signature: "addAllowedDomain(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x590e1ae3": {
    name: "refund",
    signature: "refund()",
    input_types: [],
    output_aliases: [],
  },
  "0xc7c3268b": {
    name: "setBaseUrl",
    signature: "setBaseUrl(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x129ee21a": {
    name: "setConfig",
    signature: "setConfig(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0x6f516c2c": {
    name: "setContractUrl",
    signature: "setContractUrl(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x7954d205": {
    name: "followHistoryEvent",
    signature: "followHistoryEvent(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xde3ce16b": {
    name: "mintNewHistoryReport",
    signature: "mintNewHistoryReport(uint256,bool,string,string)",
    input_types: ["uint256", "bool", "string", "string"],
    output_aliases: ["amount_a", "bool_b", "string_c", "string_d"],
  },
  "0x0a1f8ea8": {
    name: "_setDevWallet",
    signature: "_setDevWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x1ff53b60": {
    name: "_setMarketingWallet",
    signature: "_setMarketingWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x9e6c7529": {
    name: "_setMaxWalletSize",
    signature: "_setMaxWalletSize(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xaf9549e0": {
    name: "setExcludeFromFee",
    signature: "setExcludeFromFee(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0xa22b35ce": {
    name: "_burnFrom",
    signature: "_burnFrom(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xb6a5d7de": {
    name: "authorize",
    signature: "authorize(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x1f2c80f1": {
    name: "changeFeeReceivers",
    signature: "changeFeeReceivers(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0x7a319590": {
    name: "changeFees",
    signature: "changeFees(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0xa3a2e89e": {
    name: "changeIsFeeExempt",
    signature: "changeIsFeeExempt(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0xfabe6283": {
    name: "changeIsTxLimitExempt",
    signature: "changeIsTxLimitExempt(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0xe66b1d1e": {
    name: "changeRestrictWhales",
    signature: "changeRestrictWhales(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x34c70889": {
    name: "changeSwapBackSettings",
    signature: "changeSwapBackSettings(bool,uint256,bool)",
    input_types: ["bool", "uint256", "bool"],
    output_aliases: ["bool_a", "amount_b", "bool_c"],
  },
  "0xd920334e": {
    name: "changeTxLimit",
    signature: "changeTxLimit(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x7db1342c": {
    name: "changeWalletLimit",
    signature: "changeWalletLimit(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x79d22b08": {
    name: "delBots",
    signature: "delBots()",
    input_types: [],
    output_aliases: [],
  },
  "0xa29f8c5d": {
    name: "setGuestTimeOn",
    signature: "setGuestTimeOn(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x0d295980": {
    name: "tradingStatus",
    signature: "tradingStatus(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xf0b37c04": {
    name: "unauthorize",
    signature: "unauthorize(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xcb0da8b3": {
    name: "claimPending",
    signature: "claimPending(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x939f14a1": {
    name: "configurePackage",
    signature: "configurePackage(string,uint256,uint256,uint256,uint256)",
    input_types: ["string", "uint256", "uint256", "uint256", "uint256"],
    output_aliases: [
      "string_a",
      "amount_b",
      "amount_c",
      "amount_d",
      "amount_e",
    ],
  },
  "0xc5edc915": {
    name: "register",
    signature: "register(string,address,uint256,uint256,uint256,uint256)",
    input_types: [
      "string",
      "address",
      "uint256",
      "uint256",
      "uint256",
      "uint256",
    ],
    output_aliases: [
      "string_a",
      "to_b",
      "amount_c",
      "amount_d",
      "amount_e",
      "amount_f",
    ],
  },
  "0x89ad0f90": {
    name: "registerMeta",
    signature:
      "registerMeta(address,uint256,uint256,uint256,uint256,uint256,uint256,string,string)",
    input_types: [
      "address",
      "uint256",
      "uint256",
      "uint256",
      "uint256",
      "uint256",
      "uint256",
      "string",
      "string",
    ],
    output_aliases: [
      "to_a",
      "amount_b",
      "amount_c",
      "amount_d",
      "amount_e",
      "amount_f",
      "amount_g",
      "string_h",
      "string_i",
    ],
  },
  "0x7edc167b": {
    name: "renaming",
    signature: "renaming(uint256,string)",
    input_types: ["uint256", "string"],
    output_aliases: ["amount", "string"],
  },
  "0x51da2975": {
    name: "stake",
    signature: "stake(uint256,string,string,uint256)",
    input_types: ["uint256", "string", "string", "uint256"],
    output_aliases: ["amount_a", "string_b", "string_c", "amount_d"],
  },
  "0xbf3fc3a8": {
    name: "unRegister",
    signature: "unRegister(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x0d707df8": {
    name: "applyMinter",
    signature: "applyMinter()",
    input_types: [],
    output_aliases: [],
  },
  "0xd93f2445": {
    name: "applyVault",
    signature: "applyVault()",
    input_types: [],
    output_aliases: [],
  },
  "0x5f9b105d": {
    name: "changeMPCOwner",
    signature: "changeMPCOwner(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x60e232a9": {
    name: "changeVault",
    signature: "changeVault(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xbebbf4d0": {
    name: "depositVault",
    signature: "depositVault(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0x81a37c18": {
    name: "depositWithPermit",
    signature:
      "depositWithPermit(address,uint256,uint256,uint8,bytes32,bytes32,address)",
    input_types: [
      "address",
      "uint256",
      "uint256",
      "uint8",
      "bytes32",
      "bytes32",
      "address",
    ],
    output_aliases: [
      "to_a",
      "amount_b",
      "amount_c",
      "num_d",
      "b32_e",
      "b32_f",
      "to_g",
    ],
  },
  "0xf954734e": {
    name: "depositWithTransferPermit",
    signature:
      "depositWithTransferPermit(address,uint256,uint256,uint8,bytes32,bytes32,address)",
    input_types: [
      "address",
      "uint256",
      "uint256",
      "uint8",
      "bytes32",
      "bytes32",
      "address",
    ],
    output_aliases: [
      "to_a",
      "amount_b",
      "amount_c",
      "num_d",
      "b32_e",
      "b32_f",
      "to_g",
    ],
  },
  "0x6817031b": {
    name: "setVault",
    signature: "setVault(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xc4b740f5": {
    name: "setVaultOnly",
    signature: "setVaultOnly(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x0039d6ec": {
    name: "withdrawVault",
    signature: "withdrawVault(address,uint256,address)",
    input_types: ["address", "uint256", "address"],
    output_aliases: ["to_a", "amount_b", "to_c"],
  },
  "0xe4cc18be": {
    name: "finishMint",
    signature: "finishMint()",
    input_types: [],
    output_aliases: [],
  },
  "0x8d1fdf2f": {
    name: "freeze",
    signature: "freeze(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xd1c46916": {
    name: "releaseLock",
    signature: "releaseLock(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x38b82092": {
    name: "transferWithLockUp",
    signature: "transferWithLockUp(address,uint256,uint256)",
    input_types: ["address", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "amount_c"],
  },
  "0x83cfab42": {
    name: "unFreeze",
    signature: "unFreeze(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xf7b188a5": {
    name: "unPause",
    signature: "unPause()",
    input_types: [],
    output_aliases: [],
  },
  "0xfd327141": {
    name: "addPool",
    signature: "addPool(uint24)",
    input_types: ["uint24"],
    output_aliases: ["num"],
  },
  "0xded3bbf7": {
    name: "addPosition",
    signature: "addPosition(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x737106ac": {
    name: "adjustMining",
    signature: "adjustMining(uint256,uint128,uint256,int24,int24,int24)",
    input_types: ["uint256", "uint128", "uint256", "int24", "int24", "int24"],
    output_aliases: [
      "amount_a",
      "u128_b",
      "amount_c",
      "num_d",
      "num_e",
      "num_f",
    ],
  },
  "0xeb5fe583": {
    name: "avoidRisk",
    signature: "avoidRisk(uint256[],uint256,uint256)",
    input_types: ["uint256[]", "uint256", "uint256"],
    output_aliases: ["amounts_a", "amount_b", "amount_c"],
  },
  "0xcf187d2f": {
    name: "changeConfig",
    signature: "changeConfig(address,uint8,uint24,uint256)",
    input_types: ["address", "uint8", "uint24", "uint256"],
    output_aliases: ["to", "num", "uint24", "amount"],
  },
  "0xa3fbbaae": {
    name: "changeManager",
    signature: "changeManager(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x594c2ba5": {
    name: "changePool",
    signature: "changePool(uint256,address,int24,int24,int24)",
    input_types: ["uint256", "address", "int24", "int24", "int24"],
    output_aliases: ["amount_a", "to_b", "num_c", "num_d", "num_e"],
  },
  "0x4522f94d": {
    name: "forceReBalance",
    signature: "forceReBalance(uint256,int24,int24,int24)",
    input_types: ["uint256", "int24", "int24", "int24"],
    output_aliases: ["amount_a", "num_b", "num_c", "num_d"],
  },
  "0xf95949be": {
    name: "initPosition",
    signature: "initPosition(address,int24,int24)",
    input_types: ["address", "int24", "int24"],
    output_aliases: ["to_a", "num_b", "num_c"],
  },
  "0x2bac1b0e": {
    name: "reBalance",
    signature: "reBalance(uint256,int24,int24,int24)",
    input_types: ["uint256", "int24", "int24", "int24"],
    output_aliases: ["amount_a", "num_b", "num_c", "num_d"],
  },
  "0xac1d0609": {
    name: "updateWhiteList",
    signature: "updateWhiteList(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x554d2a84": {
    name: "withdrawPerformanceFee",
    signature: "withdrawPerformanceFee(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x0d1df8dc": {
    name: "addBaseLiquidity",
    signature: "addBaseLiquidity(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x5dbba48e": {
    name: "addLimitLiquidity",
    signature: "addLimitLiquidity(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x7fe75b07": {
    name: "appendList",
    signature: "appendList(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0x84715b11": {
    name: "deposit",
    signature: "deposit(uint256,uint256,address,address)",
    input_types: ["uint256", "uint256", "address", "address"],
    output_aliases: ["amount_a", "amount_b", "to_c", "to_d"],
  },
  "0x224438d1": {
    name: "pendingFees",
    signature: "pendingFees()",
    input_types: [],
    output_aliases: [],
  },
  "0x7f2116ff": {
    name: "pullLiquidity",
    signature: "pullLiquidity(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x549f6dc9": {
    name: "rebalance",
    signature: "rebalance(int24,int24,int24,int24,address,int256)",
    input_types: ["int24", "int24", "int24", "int24", "address", "int256"],
    output_aliases: ["num_a", "num_b", "num_c", "num_d", "to_e", "int_f"],
  },
  "0x28b0e4cc": {
    name: "removeListed",
    signature: "removeListed(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x3e091ee9": {
    name: "setDepositMax",
    signature: "setDepositMax(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x3f3e4c11": {
    name: "setMaxTotalSupply",
    signature: "setMaxTotalSupply(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xb1a3d533": {
    name: "toggleDirectDeposit",
    signature: "toggleDirectDeposit()",
    input_types: [],
    output_aliases: [],
  },
  "0xd3487997": {
    name: "uniswapV3MintCallback",
    signature: "uniswapV3MintCallback(uint256,uint256,bytes)",
    input_types: ["uint256", "uint256", "bytes"],
    output_aliases: ["amount_a", "amount_b", "bytes_c"],
  },
  "0x07c69be5": {
    name: "upliftTxAmount",
    signature: "upliftTxAmount(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"], // *10 ** 9
  },
  "0x36bbb867": {
    name: "changelimitedtranTime",
    signature: "changelimitedtranTime(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x3fd13b21": {
    name: "limitedtranStatus",
    signature: "limitedtranStatus(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xc409b63f": {
    name: "changetranslimitedTime",
    signature: "changetranslimitedTime(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xa0c072d4": {
    name: "_setdevWallet",
    signature: "_setdevWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x4303443d": {
    name: "addBotToBlackList",
    signature: "addBotToBlackList(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xf235d8a3": {
    name: "antidumpStatus",
    signature: "antidumpStatus(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x00cf3c10": {
    name: "changeAntiDumpTime",
    signature: "changeAntiDumpTime(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x7ded4d6a": {
    name: "removeBotFromBlackList",
    signature: "removeBotFromBlackList(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xaae11571": {
    name: "setDevFeeDisabled",
    signature: "setDevFeeDisabled(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xeaa5dad4": {
    name: "delwl",
    signature: "delwl(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x96a8826c": {
    name: "RouterSellLimitEnabled",
    signature: "RouterSellLimitEnabled(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x1f53ac02": {
    name: "setDevWallet",
    signature: "setDevWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x31c402ac": {
    name: "setFeesPercent",
    signature: "setFeesPercent(uint256,uint256,uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256", "uint256", "uint256"],
    output_aliases: [
      "amount_a",
      "amount_b",
      "amount_c",
      "amount_d",
      "amount_e",
    ],
  },
  "0x41aea9de": {
    name: "setLimitsEnabled",
    signature: "setLimitsEnabled(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x757765f8": {
    name: "setMaxBuyLimit",
    signature: "setMaxBuyLimit(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x5d0044ca": {
    name: "setMaxWallet",
    signature: "setMaxWallet(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x50bea548": {
    name: "setRouterSellLimitTokens",
    signature: "setRouterSellLimitTokens(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xc2e5ec04": {
    name: "setTradingEnabled",
    signature: "setTradingEnabled(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x77c325d6": {
    name: "setTransferDelay",
    signature: "setTransferDelay(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xaec3fd38": {
    name: "setWL",
    signature: "setWL(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xc9c65396": {
    name: "createPair",
    signature: "createPair(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["tokenA", "tokenB"],
  },
  "0xf46901ed": {
    name: "setFeeTo",
    signature: "setFeeTo(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xa2e74af6": {
    name: "setFeeToSetter",
    signature: "setFeeToSetter(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x8cd8db8a": {
    name: "init",
    signature: "init(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0x21a9cf34": {
    name: "setTradeAddress",
    signature: "setTradeAddress(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xaa2f5220": {
    name: "batchSend",
    signature: "batchSend(address[],uint256)",
    input_types: ["address[]", "uint256"],
    output_aliases: ["addresses", "amount"],
  },
  "0xd6d2b6ba": {
    name: "delegate",
    signature: "delegate(address,bytes)",
    input_types: ["address", "bytes"],
    output_aliases: ["to", "bytes"],
  },
  "0x9b6f6d78": {
    name: "setAutoBanBots",
    signature: "setAutoBanBots(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x52e53988": {
    name: "setMaxContractLiquidityPercent",
    signature: "setMaxContractLiquidityPercent(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xe67a3fa2": {
    name: "setTradingEnable",
    signature: "setTradingEnable()",
    input_types: [],
    output_aliases: [],
  },
  "0x4cedfc0c": {
    name: "activateWhitelist",
    signature: "activateWhitelist(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x999abb7b": {
    name: "removeWhitelistUser",
    signature: "removeWhitelistUser(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0x088a4ed0": {
    name: "setMaxMintAmount",
    signature: "setMaxMintAmount(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x0f5fa199": {
    name: "setMaxMintAmountAndWalletLimit",
    signature: "setMaxMintAmountAndWalletLimit(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xf1d5f517": {
    name: "setWalletLimit",
    signature: "setWalletLimit(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x9b6fbf8a": {
    name: "whitelistUser",
    signature: "whitelistUser(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0xb203919e": {
    name: "makeLiquidity",
    signature: "makeLiquidity()",
    input_types: [],
    output_aliases: [],
  },
  "0x9c0db5f3": {
    name: "setBots",
    signature: "setBots(address[],bool)",
    input_types: ["address[]", "bool"],
    output_aliases: ["addresses", "bool"],
  },
  "0x6c90b57f": {
    name: "setExcludeFromFee",
    signature: "setExcludeFromFee(address[],bool)",
    input_types: ["address[]", "bool"],
    output_aliases: ["addresses", "bool"],
  },
  "0xf53bc835": {
    name: "setMaxBuy",
    signature: "setMaxBuy(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x7439c236": {
    name: "setTradingEnable",
    signature: "setTradingEnable(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x1b3f71ae": {
    name: "nonosquare",
    signature: "nonosquare(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0xa70ff5f1": {
    name: "limitonbuys",
    signature: "limitonbuys(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xebfa81a8": {
    name: "feesFlag",
    signature: "feesFlag(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x38bf3cfa": {
    name: "renounceOwnership",
    signature: "renounceOwnership(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x97123415": {
    name: "swapExactETHForTokens",
    signature: "swapExactETHForTokens(address[],uint256)",
    input_types: ["address[]", "uint256"],
    output_aliases: ["addresses", "amount"],
  },
  "0x51f205e4": {
    name: "forceSwapBack",
    signature: "forceSwapBack()",
    input_types: [],
    output_aliases: [],
  },
  "0x0c9be46d": {
    name: "setCharityAddress",
    signature: "setCharityAddress(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x499b8394": {
    name: "setOperationsAddress",
    signature: "setOperationsAddress(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x2be32b61": {
    name: "updateMaxBuyAmount",
    signature: "updateMaxBuyAmount(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xdc3f0d0f": {
    name: "updateMaxSellAmount",
    signature: "updateMaxSellAmount(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xf5648a4f": {
    name: "withdrawStuckETH",
    signature: "withdrawStuckETH()",
    input_types: [],
    output_aliases: [],
  },
  "0x106ac57b": {
    name: "clearLockUserInfo",
    signature: "clearLockUserInfo(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x51ecf2c2": {
    name: "deleteLockUserInfoIdx",
    signature: "deleteLockUserInfoIdx(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xa19c77c7": {
    name: "deleteLockUserInfos",
    signature: "deleteLockUserInfos(address,uint256[])",
    input_types: ["address", "uint256[]"],
    output_aliases: ["to", "amounts"],
  },
  "0x072f3b67": {
    name: "setLockUser",
    signature: "setLockUser(address,uint256,uint256)",
    input_types: ["address", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "amount_c"],
  },
  "0x7e26b62d": {
    name: "setLockUsers",
    signature: "setLockUsers(address[],uint256[],uint256[])",
    input_types: ["address[]", "uint256[]", "uint256[]"],
    output_aliases: ["addresses_a", "amounts_b", "amounts_c"],
  },
  "0xac1a7175": {
    name: "transfer",
    signature: "transfer(ockUserInfo(address)",
    input_types: ["ockUserInfo(address"],
    output_aliases: ["undefined"],
  },
  "0xe0c3e1e7": {
    name: "transferWithtLockUser",
    signature: "transferWithtLockUser(address,uint256,uint256[],uint256[])",
    input_types: ["address", "uint256", "uint256[]", "uint256[]"],
    output_aliases: ["to_a", "amount_b", "amounts_c", "amounts_d"],
  },
  "0xe16685ee": {
    name: "setMaxFreeNFTPerWallet",
    signature: "setMaxFreeNFTPerWallet(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x963c3546": {
    name: "setMaxMintsPerWallet",
    signature: "setMaxMintsPerWallet(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xbbd2dc11": {
    name: "toggleProxyState",
    signature: "toggleProxyState(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x78d33acb": {
    name: "airDropGroup",
    signature: "airDropGroup(uint256,address[])",
    input_types: ["uint256", "address[]"],
    output_aliases: ["amount", "addresses"],
  },
  "0x50dc4656": {
    name: "setMerkleTreeRoot",
    signature: "setMerkleTreeRoot(bytes32)",
    input_types: ["bytes32"],
    output_aliases: ["b32"],
  },
  "0xcc9704d8": {
    name: "setTokensAllowedPerWhiteListAddress",
    signature: "setTokensAllowedPerWhiteListAddress(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xca3cb522": {
    name: "togglePreSale",
    signature: "togglePreSale()",
    input_types: [],
    output_aliases: [],
  },
  "0x0562b9f7": {
    name: "withdrawAmount",
    signature: "withdrawAmount(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x97254e55": {
    name: "whiteListMint",
    signature: "whiteListMint(bytes32[])",
    input_types: ["bytes32[]"],
    output_aliases: ["b32s"],
  },
  "0xbc37e1a3": {
    name: "addLP",
    signature: "addLP()",
    input_types: [],
    output_aliases: [],
  },
  "0xf298f42c": {
    name: "addToBlacklist",
    signature: "addToBlacklist(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0xbe874c92": {
    name: "burnTokenManual",
    signature: "burnTokenManual(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xf5a08052": {
    name: "enableContractAddressTrading",
    signature: "enableContractAddressTrading(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x02195466": {
    name: "letsGetStarted",
    signature: "letsGetStarted()",
    input_types: [],
    output_aliases: [],
  },
  "0x270924c7": {
    name: "letsGoLive",
    signature: "letsGoLive()",
    input_types: [],
    output_aliases: [],
  },
  "0x4cdaf0ae": {
    name: "SetupLiquidityTokenAddress",
    signature: "SetupLiquidityTokenAddress(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xd63607d8": {
    name: "TeamlimitLiquidityReleaseTo20Percent",
    signature: "TeamlimitLiquidityReleaseTo20Percent()",
    input_types: [],
    output_aliases: [],
  },
  "0x3c7a309b": {
    name: "TeamReleaseLiquidity",
    signature: "TeamReleaseLiquidity()",
    input_types: [],
    output_aliases: [],
  },
  "0x2c889d18": {
    name: "TeamUnlockLiquidityInSeconds",
    signature: "TeamUnlockLiquidityInSeconds(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x2de3f956": {
    name: "tradingEnabled",
    signature: "tradingEnabled(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x8ab792e3": {
    name: "updateBuyFees",
    signature: "updateBuyFees(uint8,uint8)",
    input_types: ["uint8", "uint8"],
    output_aliases: ["num_a", "num_b"],
  },
  "0xeab80723": {
    name: "updateIsElon",
    signature: "updateIsElon(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0xe37ba8f9": {
    name: "updateLiquidityWallet",
    signature: "updateLiquidityWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x296d3de0": {
    name: "updateMaxBuySell",
    signature: "updateMaxBuySell(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x1c499ab0": {
    name: "updateMaxWallet",
    signature: "updateMaxWallet(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x2b75e9dd": {
    name: "updateSellFees",
    signature: "updateSellFees(uint8,uint8)",
    input_types: ["uint8", "uint8"],
    output_aliases: ["num_a", "num_b"],
  },
  "0xab3b5545": {
    name: "updateSwapAmount",
    signature: "updateSwapAmount(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x19fb3e7a": {
    name: "withdrawRemainingERC20Token",
    signature: "withdrawRemainingERC20Token(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0x7bbf8df9": {
    name: "withdrawRemainingETH",
    signature: "withdrawRemainingETH(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xc23be47f": {
    name: "withdrawRemainingToken",
    signature: "withdrawRemainingToken(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x7362d9c8": {
    name: "addWhitelistAdmin",
    signature: "addWhitelistAdmin(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xdc593ca5": {
    name: "burnToken",
    signature: "burnToken(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0x47a64f44": {
    name: "lockAccount",
    signature: "lockAccount(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x6b2c0f55": {
    name: "removePauser",
    signature: "removePauser(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x6897e974": {
    name: "removeWhitelistAdmin",
    signature: "removeWhitelistAdmin(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x4c5a628c": {
    name: "renounceWhitelistAdmin",
    signature: "renounceWhitelistAdmin()",
    input_types: [],
    output_aliases: [],
  },
  "0x905295e3": {
    name: "unlockAccount",
    signature: "unlockAccount(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x78cf19e9": {
    name: "reserveTokens",
    signature: "reserveTokens(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xe7db8fb0": {
    name: "setFreeMints",
    signature: "setFreeMints(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xd26ea6c0": {
    name: "setProxyRegistryAddress",
    signature: "setProxyRegistryAddress(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x6a61e5fc": {
    name: "setTokenPrice",
    signature: "setTokenPrice(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xefbd73f4": {
    name: "mintForAddress",
    signature: "mintForAddress(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0x4fdd43cb": {
    name: "setHiddenMetadataUri",
    signature: "setHiddenMetadataUri(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0xb071401b": {
    name: "setMaxMintAmountPerTx",
    signature: "setMaxMintAmountPerTx(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x16c38b3c": {
    name: "setPaused",
    signature: "setPaused(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xe0a80853": {
    name: "setRevealed",
    signature: "setRevealed(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x7ec4a659": {
    name: "setUriPrefix",
    signature: "setUriPrefix(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x16ba10e0": {
    name: "setUriSuffix",
    signature: "setUriSuffix(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x457c194c": {
    name: "setMarketingFeePercent",
    signature: "setMarketingFeePercent(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x83197ef0": {
    name: "destroy",
    signature: "destroy()",
    input_types: [],
    output_aliases: [],
  },
  "0x78e3214f": {
    name: "rescueFunds",
    signature: "rescueFunds(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x6c4a483e": {
    name: "discountedSwap",
    signature:
      "discountedSwap(address,(address,address,address,address,uint256,uint256,uint256,bytes),bytes)",
    input_types: [
      "address",
      "(address,address,address,address,uint256,uint256,uint256,bytes)",
      "bytes",
    ],
    output_aliases: ["to", "envelope", "bytes"],
  },
  "0xa1251d75": {
    name: "unoswapWithPermit",
    signature: "unoswapWithPermit(address,uint256,uint256,bytes32[],bytes)",
    input_types: ["address", "uint256", "uint256", "bytes32[]", "bytes"],
    output_aliases: ["to_a", "amount_b", "amount_c", "b32s_d", "bytes_e"],
  },
  "0x98650275": {
    name: "renounceMinter",
    signature: "renounceMinter()",
    input_types: [],
    output_aliases: [],
  },
  "0x2f1c030d": {
    name: "boostRewards",
    signature: "boostRewards()",
    input_types: [],
    output_aliases: [],
  },
  "0xd240663c": {
    name: "claim",
    signature: "claim(address,uint256[9],bytes32[])",
    input_types: ["address", "uint256[9]", "bytes32[]"],
    output_aliases: ["to", "uint256[9]", "b32s"],
  },
  "0x532b1ba8": {
    name: "claimNFT",
    signature: "claimNFT(address,uint256[9],bytes32[])",
    input_types: ["address", "uint256[9]", "bytes32[]"],
    output_aliases: ["to", "uint256[9]", "b32s"],
  },
  "0x72ded8f3": {
    name: "claimWTF",
    signature: "claimWTF(address,uint256[9],bytes32[])",
    input_types: ["address", "uint256[9]", "bytes32[]"],
    output_aliases: ["to", "uint256[9]", "b32s"],
  },
  "0xbd77ac2c": {
    name: "disburse",
    signature: "disburse(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x4b89c41d": {
    name: "setClosingTime",
    signature: "setClosingTime(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x472d35b9": {
    name: "setFeeManager",
    signature: "setFeeManager(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xe8d2c8c1": {
    name: "setFeeManagerPercent",
    signature: "setFeeManagerPercent(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x8f02bb5b": {
    name: "setTransferFee",
    signature: "setTransferFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xe6a6dcce": {
    name: "setWhitelisted",
    signature: "setWhitelisted(address,bool,bool)",
    input_types: ["address", "bool", "bool"],
    output_aliases: ["to_a", "bool_b", "bool_c"],
  },
  "0x53aeea1e": {
    name: "upgradeReflink",
    signature: "upgradeReflink(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xe674a0bd": {
    name: "unlock",
    signature: "unlock(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0x0d0b21fb": {
    name: "changeMaxPresale",
    signature: "changeMaxPresale(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xd2e07682": {
    name: "changePriceFlash",
    signature: "changePriceFlash(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x3f5fbad0": {
    name: "changePricePresale",
    signature: "changePricePresale(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x9fae99b0": {
    name: "changePricePublicSale",
    signature: "changePricePublicSale(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x91ff4a73": {
    name: "decreaseMaxSupply",
    signature: "decreaseMaxSupply(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x989bdbb6": {
    name: "lockMetadata",
    signature: "lockMetadata()",
    input_types: [],
    output_aliases: [],
  },
  "0x8905fd4f": {
    name: "reclaimERC20",
    signature: "reclaimERC20(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x452a2883": {
    name: "setIPFSProvenance",
    signature: "setIPFSProvenance(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x57e5c49f": {
    name: "toggleFlashStatus",
    signature: "toggleFlashStatus()",
    input_types: [],
    output_aliases: [],
  },
  "0x7bffb4ce": {
    name: "togglePresaleStatus",
    signature: "togglePresaleStatus()",
    input_types: [],
    output_aliases: [],
  },
  "0xb9ad9fde": {
    name: "togglePublicSaleStatus",
    signature: "togglePublicSaleStatus()",
    input_types: [],
    output_aliases: [],
  },
  "0xbbd5d940": {
    name: "flashBuy",
    signature: "flashBuy(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x815f7bbd": {
    name: "presaleBuy",
    signature: "presaleBuy(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x77f2d355": {
    name: "addToPremint",
    signature: "addToPremint(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x064d5e16": {
    name: "removeFromPremint",
    signature: "removeFromPremint(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x3ae84a82": {
    name: "setPrice",
    signature: "setPrice(uint128)",
    input_types: ["uint128"],
    output_aliases: ["price"],
  },
  "0xb0478892": {
    name: "setSalesLimit",
    signature: "setSalesLimit(uint128)",
    input_types: ["uint128"],
    output_aliases: ["u128"],
  },
  "0xd5664b0b": {
    name: "setSweetCooper",
    signature: "setSweetCooper(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x26092b83": {
    name: "publicMint",
    signature: "publicMint()",
    input_types: [],
    output_aliases: [],
  },
  // --------------------------------------
  "0x6d73e669": {
    name: "approveAdmin",
    signature: "approveAdmin(address)",
    input_types: ["address"],
    output_aliases: ["admin"],
  },
  "0x02e7afb7": {
    name: "blacklistExtension",
    signature: "blacklistExtension(address)",
    input_types: ["address"],
    output_aliases: ["extension"],
  },
  "0x4cd88b76": {
    name: "initialize",
    signature: "initialize(string,string)",
    input_types: ["string", "string"],
    output_aliases: ["name", "symbol"],
  },
  "0x72ff03d3": {
    name: "mintBase",
    signature: "mintBase(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x7884af44": {
    name: "mintBase",
    signature: "mintBase(address,string)",
    input_types: ["address", "string"],
    output_aliases: ["to", "uri"],
  },
  "0x7aa15f16": {
    name: "mintBaseBatch",
    signature: "mintBaseBatch(address,string[])",
    input_types: ["address", "string[]"],
    output_aliases: ["to", "uris"],
  },
  "0xad2d0ddd": {
    name: "mintBaseBatch",
    signature: "mintBaseBatch(address,uint16)",
    input_types: ["address", "uint16"],
    output_aliases: ["to", "count"],
  },
  "0x2928ca58": {
    name: "mintExtension",
    signature: "mintExtension(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xfe2e1f58": {
    name: "mintExtension",
    signature: "mintExtension(address,string)",
    input_types: ["address", "string"],
    output_aliases: ["to", "uri"],
  },
  "0x38e52e78": {
    name: "mintExtensionBatch",
    signature: "mintExtensionBatch(address,string[])",
    input_types: ["address", "string[]"],
    output_aliases: ["to", "uris"],
  },
  "0xe00aab4b": {
    name: "mintExtensionBatch",
    signature: "mintExtensionBatch(address,uint16)",
    input_types: ["address", "uint16"],
    output_aliases: ["to", "count"],
  },
  "0x3071a0f9": {
    name: "registerExtension",
    signature: "registerExtension(address,string)",
    input_types: ["address", "string"],
    output_aliases: ["extension", "baseURI"],
  },
  "0x3f0f37f6": {
    name: "registerExtension",
    signature: "registerExtension(address,string,bool)",
    input_types: ["address", "string", "bool"],
    output_aliases: ["extension", "baseURI", "baseURIIdentical"],
  },
  "0x2d345670": {
    name: "revokeAdmin",
    signature: "revokeAdmin(address)",
    input_types: ["address"],
    output_aliases: ["admin"],
  },
  "0xac0c8cfa": {
    name: "setApproveTransferExtension",
    signature: "setApproveTransferExtension(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x3e6134b8": {
    name: "setBaseTokenURIExtension",
    signature: "setBaseTokenURIExtension(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x82dcc0c8": {
    name: "setBaseTokenURIExtension",
    signature: "setBaseTokenURIExtension(string,bool)",
    input_types: ["string", "bool"],
    output_aliases: ["string", "bool"],
  },
  "0xf0cdc499": {
    name: "setMintPermissions",
    signature: "setMintPermissions(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0x20e4afe2": {
    name: "setRoyalties",
    signature: "setRoyalties(uint256,address[],uint256[])",
    input_types: ["uint256", "address[]", "uint256[]"],
    output_aliases: ["amount", "addresses", "amounts"],
  },
  "0x332dd1ae": {
    name: "setRoyalties",
    signature: "setRoyalties(address[],uint256[])",
    input_types: ["address[]", "uint256[]"],
    output_aliases: ["addresses", "amounts"],
  },
  "0xb0fe87c9": {
    name: "setRoyaltiesExtension",
    signature: "setRoyaltiesExtension(address,address[],uint256[])",
    input_types: ["address", "address[]", "uint256[]"],
    output_aliases: ["to", "addresses", "amounts"],
  },
  "0xaafb2d44": {
    name: "setTokenURI",
    signature: "setTokenURI(uint256[],string[])",
    input_types: ["uint256[]", "string[]"],
    output_aliases: ["amounts", "string[]"],
  },
  "0x61e5bc6b": {
    name: "setTokenURIExtension",
    signature: "setTokenURIExtension(uint256[],string[])",
    input_types: ["uint256[]", "string[]"],
    output_aliases: ["amounts", "string[]"],
  },
  "0xe92a89f6": {
    name: "setTokenURIExtension",
    signature: "setTokenURIExtension(uint256,string)",
    input_types: ["uint256", "string"],
    output_aliases: ["amount", "string"],
  },
  "0x66d1e9d0": {
    name: "setTokenURIPrefixExtension",
    signature: "setTokenURIPrefixExtension(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0xce8aee9d": {
    name: "unregisterExtension",
    signature: "unregisterExtension(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  // --------------------------------------
  "0x2a5bdc33": {
    name: "excludeFromSellLock",
    signature: "excludeFromSellLock(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x48dfea0a": {
    name: "openTrading",
    signature: "openTrading(address[],uint256)",
    input_types: ["address[]", "uint256"],
    output_aliases: ["addresses", "duration"],
  },
  "0x300a1ad0": {
    name: "removeStrictTxAndWalletLimit",
    signature: "removeStrictTxAndWalletLimit()",
    input_types: [],
    output_aliases: [],
  },
  "0x99ce371a": {
    name: "removeStrictWalletLimit",
    signature: "removeStrictWalletLimit()",
    input_types: [],
    output_aliases: [],
  },
  "0x9d0014b1": {
    name: "setSwapThreshold",
    signature: "setSwapThreshold(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xd024e84f": {
    name: "claimAirdrop",
    signature: "claimAirdrop(bytes,uint256)",
    input_types: ["bytes", "uint256"],
    output_aliases: ["bytes", "amount"],
  },
  "0x4dcb2b3f": {
    name: "ownerMintFromReserve",
    signature: "ownerMintFromReserve(uint8)",
    input_types: ["uint8"],
    output_aliases: ["num"],
  },
  "0x7d55094d": {
    name: "toggleMinting",
    signature: "toggleMinting()",
    input_types: [],
    output_aliases: [],
  },
  "0x14c791a6": {
    name: "toggleOnlyWhitelisted",
    signature: "toggleOnlyWhitelisted()",
    input_types: [],
    output_aliases: [],
  },
  "0x036e73c8": {
    name: "mint",
    signature: "mint(uint32,bytes)",
    input_types: ["uint32", "bytes"],
    output_aliases: ["num", "bytes"],
  },
  "0xa71bbebe": {
    name: "mint",
    signature: "mint(uint32)",
    input_types: ["uint32"],
    output_aliases: ["num"],
  },
  "0xaf2979eb": {
    name: "removeLiquidityETHSupportingFeeOnTransferTokens",
    signature:
      "removeLiquidityETHSupportingFeeOnTransferTokens(address,uint256,uint256,uint256,address,uint256)",
    input_types: [
      "address",
      "uint256",
      "uint256",
      "uint256",
      "address",
      "uint256",
    ],
    output_aliases: [
      "token",
      "liquidity",
      "amountTokenMin",
      "amountETHMin",
      "to",
      "deadline",
    ],
  },
  "0x0eca1180": {
    name: "setBot",
    signature: "setBot(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0x3b13be40": {
    name: "paidMint",
    signature: "paidMint(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x55367ba9": {
    name: "pauseSale",
    signature: "pauseSale()",
    input_types: [],
    output_aliases: [],
  },
  "0xc617f4ed": {
    name: "reserveNFT",
    signature: "reserveNFT()",
    input_types: [],
    output_aliases: [],
  },
  "0x0025484f": {
    name: "reserveTenNFTs",
    signature: "reserveTenNFTs()",
    input_types: [],
    output_aliases: [],
  },
  "0x41d5b803": {
    name: "ownerMint",
    signature: "ownerMint(address,string)",
    input_types: ["address", "string"],
    output_aliases: ["to", "string"],
  },
  "0x19b2a07c": {
    name: "renounceCreater",
    signature: "renounceCreater()",
    input_types: [],
    output_aliases: [],
  },
  "0xf4a762cc": {
    name: "setMaxMintPerTime",
    signature: "setMaxMintPerTime(uint8)",
    input_types: ["uint8"],
    output_aliases: ["num"],
  },
  "0xf606faea": {
    name: "setNftCost",
    signature: "setNftCost(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x18e97fd1": {
    name: "updateTokenURI",
    signature: "updateTokenURI(uint256,string)",
    input_types: ["uint256", "string"],
    output_aliases: ["amount", "string"],
  },
  "0x5768f271": {
    name: "mint",
    signature: "mint(string[])",
    input_types: ["string[]"],
    output_aliases: ["mints"],
  },
  "0x99508879": {
    name: "presaleMint",
    signature: "presaleMint(string[])",
    input_types: ["string[]"],
    output_aliases: ["mints"],
  },
  "0x54ab36f9": {
    name: "ownerMint",
    signature: "ownerMint(string[],string[])",
    input_types: ["string[]", "string[]"],
    output_aliases: ["undefined_a", "undefined_b"],
  },
  "0x4f1235dd": {
    name: "setMaxInputLength",
    signature: "setMaxInputLength(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x174da4a2": {
    name: "setMaxMintsPerTx",
    signature: "setMaxMintsPerTx(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xd7296662": {
    name: "setPurchasePrice",
    signature: "setPurchasePrice(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xe465f1f5": {
    name: "withdrawAmountTo",
    signature: "withdrawAmountTo(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x3ba3518a": {
    name: "mint",
    signature: "mint(string[],string[])",
    input_types: ["string[]", "string[]"],
    output_aliases: ["undefined_a", "undefined_b"],
  },
  "0xe0c9ffc6": {
    name: "airdrop",
    signature: "airdrop((address,uint256)[])",
    input_types: ["(address", "uint256)[]"],
    output_aliases: ["(address", "uint256)[]"],
  },
  "0xea50e78c": {
    name: "mintReservedGods",
    signature: "mintReservedGods(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xf11cb0af": {
    name: "setMintState",
    signature: "setMintState(uint8)",
    input_types: ["uint8"],
    output_aliases: ["num"],
  },
  "0xbb1ee47b": {
    name: "setWhiteListMerkleTreeRoot",
    signature: "setWhiteListMerkleTreeRoot(bytes32)",
    input_types: ["bytes32"],
    output_aliases: ["b32"],
  },
  "0x061431a8": {
    name: "mintWhitelist",
    signature: "mintWhitelist(uint256,bytes32[])",
    input_types: ["uint256", "bytes32[]"],
    output_aliases: ["amount", "b32s"],
  },
  "0x6bd4b093": {
    name: "setSaleActiveness",
    signature: "setSaleActiveness(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x31b5b907": {
    name: "_setBaseURI",
    signature: "_setBaseURI(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x547520fe": {
    name: "setMaxMint",
    signature: "setMaxMint(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x3148235a": {
    name: "getRebaseValues",
    signature: "getRebaseValues()",
    input_types: [],
    output_aliases: [],
  },
  "0x6ecff0b2": {
    name: "manualRebase",
    signature: "manualRebase(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x1d08837b": {
    name: "setBaseRate",
    signature: "setBaseRate(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x92eefe9b": {
    name: "setController",
    signature: "setController(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x7f458396": {
    name: "setDampener",
    signature: "setDampener(int256)",
    input_types: ["int256"],
    output_aliases: ["int"],
  },
  "0x456b6398": {
    name: "setIsInCommunityStage",
    signature: "setIsInCommunityStage(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xc611c92f": {
    name: "setIsLaunchFinished",
    signature: "setIsLaunchFinished(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xea649816": {
    name: "setMaxTransferAmountInPercentage",
    signature: "setMaxTransferAmountInPercentage(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xd5905dfb": {
    name: "setRebaseCooldown",
    signature: "setRebaseCooldown(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xc25a98b7": {
    name: "setTargetRatePercentage",
    signature: "setTargetRatePercentage(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x2307b441": {
    name: "airdropToWallets",
    signature: "airdropToWallets(address[],uint256[])",
    input_types: ["address[]", "uint256[]"],
    output_aliases: ["addresses", "amounts"],
  },
  "0xfc155d1d": {
    name: "buyBackTokens",
    signature: "buyBackTokens(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x8366e79a": {
    name: "transferForeignToken",
    signature: "transferForeignToken(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0xad85832e": {
    name: "setLiquidityPoolWallet",
    signature: "setLiquidityPoolWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xc44547df": {
    name: "setSeasonPoolWallet",
    signature: "setSeasonPoolWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x91274f68": {
    name: "setStakingPoolWallet",
    signature: "setStakingPoolWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x2b788787": {
    name: "updateBurnRate",
    signature: "updateBurnRate(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xaff177ca": {
    name: "updateWhitelist",
    signature: "updateWhitelist(address[],bool)",
    input_types: ["address[]", "bool"],
    output_aliases: ["addresses", "bool"],
  },
  "0x5a464923": {
    name: "withdrawLiquidityPool",
    signature: "withdrawLiquidityPool(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x7ce03b49": {
    name: "withdrawSeasonPool",
    signature: "withdrawSeasonPool(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x61076eac": {
    name: "withdrawStakingPool",
    signature: "withdrawStakingPool(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x0c53c51c": {
    name: "executeMetaTransaction",
    signature: "executeMetaTransaction(address,bytes,bytes32,bytes32,uint8)",
    input_types: ["address", "bytes", "bytes32", "bytes32", "uint8"],
    output_aliases: ["to_a", "bytes_b", "b32_c", "b32_d", "num_e"],
  },
  "0x455a4396": {
    name: "blacklistAddress",
    signature: "blacklistAddress(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x31e79db0": {
    name: "excludeFromDividends",
    signature: "excludeFromDividends(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xc492f046": {
    name: "excludeMultipleAccountsFromFees",
    signature: "excludeMultipleAccountsFromFees(address[],bool)",
    input_types: ["address[]", "bool"],
    output_aliases: ["addresses", "bool"],
  },
  "0x700bb191": {
    name: "processDividendTracker",
    signature: "processDividendTracker(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xac212ab2": {
    name: "setCAKERewardsFee",
    signature: "setCAKERewardsFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x625e764c": {
    name: "setMarketingFee",
    signature: "setMarketingFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xe98030c7": {
    name: "updateClaimWait",
    signature: "updateClaimWait(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x88bdd9be": {
    name: "updateDividendTracker",
    signature: "updateDividendTracker(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x65b8dbc0": {
    name: "updateUniswapV2Router",
    signature: "updateUniswapV2Router(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x741af87f": {
    name: "disableAllFees",
    signature: "disableAllFees()",
    input_types: [],
    output_aliases: [],
  },
  "0xffc78635": {
    name: "enableAllFees",
    signature: "enableAllFees()",
    input_types: [],
    output_aliases: [],
  },
  "0x22461173": {
    name: "setmarketingWallet",
    signature: "setmarketingWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x405a66da": {
    name: "claimOtherTokens",
    signature: "claimOtherTokens(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x9cad6741": {
    name: "Opentrading",
    signature: "Opentrading()",
    input_types: [],
    output_aliases: [],
  },
  "0x65e47de2": {
    name: "setmaxTxAmount",
    signature: "setmaxTxAmount(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x68f8fc10": {
    name: "buyToken",
    signature: "buyToken(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xb89d1890": {
    name: "disbursement",
    signature: "disbursement(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x81eaf99b": {
    name: "lockSupply",
    signature: "lockSupply()",
    input_types: [],
    output_aliases: [],
  },
  "0xa8dc824c": {
    name: "unlockSupply",
    signature: "unlockSupply()",
    input_types: [],
    output_aliases: [],
  },
  "0x9cfe42da": {
    name: "addBlacklist",
    signature: "addBlacklist(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xcd49513f": {
    name: "excludeFromAllLimits",
    signature: "excludeFromAllLimits(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x30280a71": {
    name: "excludeFromTxLimit",
    signature: "excludeFromTxLimit(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0xe16830a8": {
    name: "excludeFromWalletLimit",
    signature: "excludeFromWalletLimit(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x715492aa": {
    name: "launchNow",
    signature: "launchNow()",
    input_types: [],
    output_aliases: [],
  },
  "0xeb91e651": {
    name: "removeBlacklist",
    signature: "removeBlacklist(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xfb0ecfa4": {
    name: "setBuyFee",
    signature: "setBuyFee(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x6ac9a870": {
    name: "setSellFee",
    signature: "setSellFee(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x6402511e": {
    name: "setSwapAtAmount",
    signature: "setSwapAtAmount(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x8a63bd9b": {
    name: "approveContractContingency",
    signature: "approveContractContingency()",
    input_types: [],
    output_aliases: [],
  },
  "0xe8a0e3f9": {
    name: "multiSendPercents",
    signature: "multiSendPercents(address[],uint256[],uint256[])",
    input_types: ["address[]", "uint256[]", "uint256[]"],
    output_aliases: ["addresses_a", "amounts_b", "amounts_c"],
  },
  "0x2610eaca": {
    name: "multiSendTokens",
    signature: "multiSendTokens(address[],uint256[])",
    input_types: ["address[]", "uint256[]"],
    output_aliases: ["addresses", "amounts"],
  },
  "0xc6a276c2": {
    name: "removeBlacklisted",
    signature: "removeBlacklisted(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x33251a0b": {
    name: "removeSniper",
    signature: "removeSniper(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xcab5ebea": {
    name: "setContractSwapEnabled",
    signature: "setContractSwapEnabled(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x5c24b074": {
    name: "setInitializer",
    signature: "setInitializer(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x80c581d1": {
    name: "setLpPair",
    signature: "setLpPair(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x26003957": {
    name: "setMaxWalletSize",
    signature: "setMaxWalletSize(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["percent", "divisor"],
  },
  "0xeafb5a3c": {
    name: "setNewRouter",
    signature: "setNewRouter(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x29dd8798": {
    name: "setProtectionSettings",
    signature: "setProtectionSettings(bool,bool,bool,bool)",
    input_types: ["bool", "bool", "bool", "bool"],
    output_aliases: ["bool_a", "bool_b", "bool_c", "bool_d"],
  },
  "0xaa22b172": {
    name: "setRatios",
    signature: "setRatios(uint16,uint16,uint16)",
    input_types: ["uint16", "uint16", "uint16"],
    output_aliases: ["num_a", "num_b", "num_c"],
  },
  "0xfb78680d": {
    name: "setSwapSettings",
    signature: "setSwapSettings(uint256,uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256", "uint256"],
    output_aliases: [
      "thresholdPercent",
      "thresholdDivisor",
      "amountPercent",
      "amountDivisor",
    ],
  },
  "0x32cde664": {
    name: "setTaxes",
    signature: "setTaxes(uint16,uint16,uint16)",
    input_types: ["uint16", "uint16", "uint16"],
    output_aliases: ["num_a", "num_b", "num_c"],
  },
  "0xd3f6a157": {
    name: "setWallets",
    signature: "setWallets(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0xee5d9c2d": {
    name: "sweepContingency",
    signature: "sweepContingency()",
    input_types: [],
    output_aliases: [],
  },
  "0x4fb2e45d": {
    name: "transferOwner",
    signature: "transferOwner(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x70223952": {
    name: "addValidators",
    signature: "addValidators(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0x40a141ff": {
    name: "removeValidator",
    signature: "removeValidator(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x530c74c0": {
    name: "updateQuorum",
    signature: "updateQuorum(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x3afecb8a": {
    name: "depositBulkFor",
    signature: "depositBulkFor(address,address[],uint256[])",
    input_types: ["address", "address[]", "uint256[]"],
    output_aliases: ["to", "addresses", "amounts"],
  },
  "0x97feb926": {
    name: "depositERC20",
    signature: "depositERC20(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xd29a4bf6": {
    name: "depositERC721",
    signature: "depositERC721(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xa7616c3b": {
    name: "depositERC721For",
    signature: "depositERC721For(address,address,uint256)",
    input_types: ["address", "address", "uint256"],
    output_aliases: ["to_a", "to_b", "amount_c"],
  },
  "0x9a202d47": {
    name: "removeAdmin",
    signature: "removeAdmin()",
    input_types: [],
    output_aliases: [],
  },
  "0x1a5da6c8": {
    name: "updateRegistry",
    signature: "updateRegistry(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x4789dcb9": {
    name: "withdrawERC20",
    signature: "withdrawERC20(uint256,address,uint256,bytes)",
    input_types: ["uint256", "address", "uint256", "bytes"],
    output_aliases: ["amount_a", "to_b", "amount_c", "bytes_d"],
  },
  "0x67a1569b": {
    name: "withdrawERC721",
    signature: "withdrawERC721(uint256,address,uint256,bytes)",
    input_types: ["uint256", "address", "uint256", "bytes"],
    output_aliases: ["amount_a", "to_b", "amount_c", "bytes_d"],
  },
  "0x75af6456": {
    name: "withdrawERC721For",
    signature: "withdrawERC721For(uint256,address,address,uint256,bytes)",
    input_types: ["uint256", "address", "address", "uint256", "bytes"],
    output_aliases: ["amount_a", "to_b", "to_c", "amount_d", "bytes_e"],
  },
  "0xace77c19": {
    name: "withdrawToken",
    signature: "withdrawToken(uint256,address,uint256,bytes)",
    input_types: ["uint256", "address", "uint256", "bytes"],
    output_aliases: ["amount_a", "to_b", "amount_c", "bytes_d"],
  },
  "0x5a8143de": {
    name: "withdrawTokenFor",
    signature: "withdrawTokenFor(uint256,address,address,uint256,bytes)",
    input_types: ["uint256", "address", "address", "uint256", "bytes"],
    output_aliases: ["amount_a", "to_b", "to_c", "amount_d", "bytes_e"],
  },
  "0x439370b1": {
    name: "depositEth",
    signature: "depositEth()",
    input_types: [],
    output_aliases: [],
  },
  "0xd47573d4": {
    name: "ownerMint",
    signature: "ownerMint(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xa0bcfc7f": {
    name: "setBaseUri",
    signature: "setBaseUri(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x7708c027": {
    name: "setBronzePriceWei",
    signature: "setBronzePriceWei(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xccb4807b": {
    name: "setContractUri",
    signature: "setContractUri(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x8c25de60": {
    name: "setFirstBuyersMerkleRoot",
    signature: "setFirstBuyersMerkleRoot(bytes32)",
    input_types: ["bytes32"],
    output_aliases: ["b32"],
  },
  "0xbba767f9": {
    name: "setGoldPriceWei",
    signature: "setGoldPriceWei(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x3ca1fca7": {
    name: "setOgAndPassengersMerkleRoot",
    signature: "setOgAndPassengersMerkleRoot(bytes32)",
    input_types: ["bytes32"],
    output_aliases: ["b32"],
  },
  "0x084c4088": {
    name: "setSaleState",
    signature: "setSaleState(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xa961b162": {
    name: "setSilverPriceWei",
    signature: "setSilverPriceWei(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xc813cfd4": {
    name: "earlyMint",
    signature: "earlyMint(uint256,uint256,uint256,bytes32[])",
    input_types: ["uint256", "uint256", "uint256", "bytes32[]"],
    output_aliases: ["amount_a", "amount_b", "amount_c", "b32s_d"],
  },
  "0x4972a7a7": {
    name: "claim",
    signature:
      "claim(uint256,bytes32[],(bool,address,address,uint256,uint256,uint256,address,address,uint256,uint256,uint256,uint256,bytes,uint8,bytes32,bytes32),bool)",
    input_types: [
      "uint256",
      "bytes32[]",
      "(bool,address,address,uint256,uint256,uint256,address,address,uint256,uint256,uint256,uint256,bytes,uint8,bytes32,bytes32)",
      "bool",
    ],
    output_aliases: ["amount", "b32s", "envelope", "bool"],
  },
  "0xc6afd5bb": {
    name: "pauseAirdrop",
    signature: "pauseAirdrop()",
    input_types: [],
    output_aliases: [],
  },
  "0xe96ad3ad": {
    name: "unpauseAirdrop",
    signature: "unpauseAirdrop()",
    input_types: [],
    output_aliases: [],
  },
  "0x6e1613fb": {
    name: "updateEndTimestamp",
    signature: "updateEndTimestamp(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x15618acd": {
    name: "withdrawTokenRewards",
    signature: "withdrawTokenRewards()",
    input_types: [],
    output_aliases: [],
  },
  "0xabf2ebd8": {
    name: "claim",
    signature: "claim(uint256,bytes32,bytes32)",
    input_types: ["uint256", "bytes32", "bytes32"],
    output_aliases: ["amount_a", "b32_b", "b32_c"],
  },
  "0x4280d50a": {
    name: "registerAndDepositNft",
    signature:
      "registerAndDepositNft(address,uint256,bytes,uint256,uint256,uint256)",
    input_types: [
      "address",
      "uint256",
      "bytes",
      "uint256",
      "uint256",
      "uint256",
    ],
    output_aliases: [
      "to_a",
      "amount_b",
      "bytes_c",
      "amount_d",
      "amount_e",
      "amount_f",
    ],
  },
  "0xea864adf": {
    name: "registerAndWithdraw",
    signature: "registerAndWithdraw(address,uint256,bytes,uint256)",
    input_types: ["address", "uint256", "bytes", "uint256"],
    output_aliases: ["to_a", "amount_b", "bytes_c", "amount_d"],
  },
  "0x352eb84c": {
    name: "registerAndWithdrawNft",
    signature: "registerAndWithdrawNft(address,uint256,bytes,uint256,uint256)",
    input_types: ["address", "uint256", "bytes", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "bytes_c", "amount_d", "amount_e"],
  },
  "0x1259cc6c": {
    name: "registerAndWithdrawNftTo",
    signature:
      "registerAndWithdrawNftTo(address,uint256,bytes,uint256,uint256,address)",
    input_types: [
      "address",
      "uint256",
      "bytes",
      "uint256",
      "uint256",
      "address",
    ],
    output_aliases: [
      "to_a",
      "amount_b",
      "bytes_c",
      "amount_d",
      "amount_e",
      "to_f",
    ],
  },
  "0x4627d598": {
    name: "registerAndWithdrawTo",
    signature: "registerAndWithdrawTo(address,uint256,bytes,uint256,address)",
    input_types: ["address", "uint256", "bytes", "uint256", "address"],
    output_aliases: ["to_a", "amount_b", "bytes_c", "amount_d", "to_e"],
  },
  "0x0a9c3beb": {
    name: "regsiterAndWithdrawAndMint",
    signature:
      "regsiterAndWithdrawAndMint(address,uint256,bytes,uint256,bytes)",
    input_types: ["address", "uint256", "bytes", "uint256", "bytes"],
    output_aliases: ["to_a", "amount_b", "bytes_c", "amount_d", "bytes_e"],
  },
  "0x8bdb9f80": {
    name: "registerAndDeposit",
    signature: "registerAndDeposit(address,uint256,bytes,uint256,uint256)",
    input_types: ["address", "uint256", "bytes", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "bytes_c", "amount_d", "amount_e"],
  },
  "0xef4ae27f": {
    name: "registerAndDeposit",
    signature:
      "registerAndDeposit(address,uint256,bytes,uint256,uint256,uint256)",
    input_types: [
      "address",
      "uint256",
      "bytes",
      "uint256",
      "uint256",
      "uint256",
    ],
    output_aliases: [
      "to_a",
      "amount_b",
      "bytes_c",
      "amount_d",
      "amount_e",
      "amount_f",
    ],
  },
  "0x06aba612": {
    name: "factoryMint",
    signature: "factoryMint(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x755edd17": {
    name: "mintTo",
    signature: "mintTo(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x83c17c55": {
    name: "setFactoryAddress",
    signature: "setFactoryAddress(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xeb12d61e": {
    name: "addSigner",
    signature: "addSigner(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x0e316ab7": {
    name: "removeSigner",
    signature: "removeSigner(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xe5c8b03d": {
    name: "renounceSigner",
    signature: "renounceSigner()",
    input_types: [],
    output_aliases: [],
  },
  "0x748844f2": {
    name: "addToBL",
    signature: "addToBL(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0xd7e5a8f4": {
    name: "removeFromBL",
    signature: "removeFromBL(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x4f6ae201": {
    name: "startVest",
    signature: "startVest(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x35faa416": {
    name: "sweep",
    signature: "sweep()",
    input_types: [],
    output_aliases: [],
  },
  "0x98214bcb": {
    name: "updateListingStatus",
    signature: "updateListingStatus(uint256,bool)",
    input_types: ["uint256", "bool"],
    output_aliases: ["amount", "bool"],
  },
  "0x82367b2d": {
    name: "updatePrice",
    signature: "updatePrice(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x38926b6d": {
    name: "claim",
    signature: "claim(uint256,bytes)",
    input_types: ["uint256", "bytes"],
    output_aliases: ["amount", "bytes"],
  },
  "0xfca3b5aa": {
    name: "setMinter",
    signature: "setMinter(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xde6baccb": {
    name: "transferWithLock",
    signature: "transferWithLock(address,uint256,uint256)",
    input_types: ["address", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "amount_c"],
  },
  "0xa053ad90": {
    name: "addBotWallets",
    signature: "addBotWallets(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0x794be707": {
    name: "blackListAddress",
    signature: "blackListAddress(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x875ae990": {
    name: "updateFee",
    signature: "updateFee(uint256,uint256,address)",
    input_types: ["uint256", "uint256", "address"],
    output_aliases: ["amount_a", "amount_b", "to_c"],
  },
  "0x492a937b": {
    name: "addLiquidityPool",
    signature: "addLiquidityPool(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x67243482": {
    name: "airdrop",
    signature: "airdrop(address[],uint256[])",
    input_types: ["address[]", "uint256[]"],
    output_aliases: ["addresses", "amounts"],
  },
  "0x421dd7c7": {
    name: "disableProtection",
    signature: "disableProtection()",
    input_types: [],
    output_aliases: [],
  },
  "0x04a66b48": {
    name: "setFees",
    signature: "setFees(uint256,uint256,uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256", "uint256", "uint256"],
    output_aliases: [
      "amount_a",
      "amount_b",
      "amount_c",
      "amount_d",
      "amount_e",
    ],
  },
  "0x658d4b7f": {
    name: "setIsFeeExempt",
    signature: "setIsFeeExempt(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0xf84ba65d": {
    name: "setIsTxLimitExempt",
    signature: "setIsTxLimitExempt(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0xe624c4bc": {
    name: "setLiquidityProvider",
    signature: "setLiquidityProvider(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xabe57e70": {
    name: "setMaxWallet",
    signature: "setMaxWallet(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x659b1c7c": {
    name: "setProtection",
    signature: "setProtection(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xd5d035f5": {
    name: "setProtection",
    signature: "setProtection(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x0f4f7bfe": {
    name: "setSellPeriod",
    signature: "setSellPeriod(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xd0889358": {
    name: "setSwapBackSettings",
    signature: "setSwapBackSettings(bool,uint256,uint256)",
    input_types: ["bool", "uint256", "uint256"],
    output_aliases: ["bool_a", "amount_b", "amount_c"],
  },
  "0x201e7991": {
    name: "setTargetLiquidity",
    signature: "setTargetLiquidity(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x1faf723b": {
    name: "setTxLimit",
    signature: "setTxLimit(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x7fd22e39": {
    name: "at",
    signature: "at(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x563bf354": {
    name: "blw",
    signature: "blw(address[],bool)",
    input_types: ["address[]", "bool"],
    output_aliases: ["addresses", "bool"],
  },
  "0x7571336a": {
    name: "excludeFromMaxTransaction",
    signature: "excludeFromMaxTransaction(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0xfe72b27a": {
    name: "manualBurnLiquidityPairTokens",
    signature: "manualBurnLiquidityPairTokens(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x9a7a23d6": {
    name: "setAutomatedMarketMakerPair",
    signature: "setAutomatedMarketMakerPair(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0xa2657778": {
    name: "setEarlySellTax",
    signature: "setEarlySellTax(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xff935af6": {
    name: "updateSellFees",
    signature: "updateSellFees(uint256,uint256,uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256", "uint256", "uint256"],
    output_aliases: [
      "amount_a",
      "amount_b",
      "amount_c",
      "amount_d",
      "amount_e",
    ],
  },
  "0xd257b34f": {
    name: "updateSwapTokensAtAmount",
    signature: "updateSwapTokensAtAmount(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x8ba4cc3c": {
    name: "airdrop",
    signature: "airdrop(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xd4a3883f": {
    name: "airdropArray",
    signature: "airdropArray(address[],uint256[])",
    input_types: ["address[]", "uint256[]"],
    output_aliases: ["addresses", "amounts"],
  },
  "0x3ae7dc20": {
    name: "claimOtherTokens",
    signature: "claimOtherTokens(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0x48c54b9d": {
    name: "claimTokens",
    signature: "claimTokens()",
    input_types: [],
    output_aliases: [],
  },
  "0x764d72bf": {
    name: "clearStuckBalance",
    signature: "clearStuckBalance(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x0305caff": {
    name: "removeBotWallet",
    signature: "removeBotWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x29e04b4a": {
    name: "setSwapThresholdAmount",
    signature: "setSwapThresholdAmount(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xfc5af734": {
    name: "changeBurnFee",
    signature: "changeBurnFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x71ffcb16": {
    name: "changeFeeAccount",
    signature: "changeFeeAccount(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x29805558": {
    name: "changeReflectionFee",
    signature: "changeReflectionFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x584e8d2a": {
    name: "changeTaxFee",
    signature: "changeTaxFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x6049876e": {
    name: "excludeAccountFromReward",
    signature: "excludeAccountFromReward(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xfdeb889f": {
    name: "includeAccountinReward",
    signature: "includeAccountinReward(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x053ab182": {
    name: "reflect",
    signature: "reflect(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xd34628cc": {
    name: "addBots",
    signature: "addBots(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0x6991b45c": {
    name: "approveRouter",
    signature: "approveRouter()",
    input_types: [],
    output_aliases: [],
  },
  "0x44df8e70": {
    name: "burn",
    signature: "burn()",
    input_types: [],
    output_aliases: [],
  },
  "0x8d7dd837": {
    name: "changeMarketing",
    signature: "changeMarketing(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xb14f2a39": {
    name: "changeTreasury",
    signature: "changeTreasury(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xe57f14e1": {
    name: "excludeFromFees",
    signature: "excludeFromFees(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x16a2f82a": {
    name: "includeInFees",
    signature: "includeInFees(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x5fecd926": {
    name: "removeBot",
    signature: "removeBot(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x3092afd5": {
    name: "removeMinter",
    signature: "removeMinter(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x2e5bb6ff": {
    name: "setTax",
    signature: "setTax(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xb4cc5ce8": {
    name: "startCreateLiq",
    signature: "startCreateLiq()",
    input_types: [],
    output_aliases: [],
  },
  "0x3b243e4c": {
    name: "toggleMaxTx",
    signature: "toggleMaxTx()",
    input_types: [],
    output_aliases: [],
  },
  "0x10bf6029": {
    name: "toggleTax",
    signature: "toggleTax()",
    input_types: [],
    output_aliases: [],
  },
  "0xa0ef91df": {
    name: "withdrawEth",
    signature: "withdrawEth()",
    input_types: [],
    output_aliases: [],
  },
  "0x9485b9d8": {
    name: "clearStuckBNBBalance",
    signature: "clearStuckBNBBalance(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x064fa2bb": {
    name: "setAllFees",
    signature:
      "setAllFees(uint256,uint256,uint256,uint256,uint256,uint256,uint256)",
    input_types: [
      "uint256",
      "uint256",
      "uint256",
      "uint256",
      "uint256",
      "uint256",
      "uint256",
    ],
    output_aliases: [
      "amount_a",
      "amount_b",
      "amount_c",
      "amount_d",
      "amount_e",
      "amount_f",
      "amount_g",
    ],
  },
  "0x29370cc6": {
    name: "setBuyBackEnabled",
    signature: "setBuyBackEnabled(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x7bcd43e9": {
    name: "setBuyBackLimitEnabled",
    signature: "setBuyBackLimitEnabled(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x82d2a4bb": {
    name: "setBuybackUpperLimit",
    signature: "setBuybackUpperLimit(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x8ee88c53": {
    name: "setLiquidityFeePercent",
    signature: "setLiquidityFeePercent(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xb74f7eb5": {
    name: "setPercentLastSoldToBuyBackTimesTen",
    signature: "setPercentLastSoldToBuyBackTimesTen(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x85535cc5": {
    name: "setVaultAddress",
    signature: "setVaultAddress(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xff910563": {
    name: "setVaultFeePercent",
    signature: "setVaultFeePercent(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x885a4cd0": {
    name: "withdrawForeignToken",
    signature: "withdrawForeignToken(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xc2d0ffca": {
    name: "updateMaxTx",
    signature: "updateMaxTx(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x3177029f": {
    name: "approveAndCall",
    signature: "approveAndCall(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x8980f11f": {
    name: "recoverERC20",
    signature: "recoverERC20(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x1296ee62": {
    name: "transferAndCall",
    signature: "transferAndCall(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xc1d34b89": {
    name: "transferFromAndCall",
    signature: "transferFromAndCall(address,address,uint256,bytes)",
    input_types: ["address", "address", "uint256", "bytes"],
    output_aliases: ["to_a", "to_b", "amount_c", "bytes_d"],
  },
  "0xd8fbe994": {
    name: "transferFromAndCall",
    signature: "transferFromAndCall(address,address,uint256)",
    input_types: ["address", "address", "uint256"],
    output_aliases: ["to_a", "to_b", "amount_c"],
  },
  "0xdc39d06d": {
    name: "transferAnyERC20Token",
    signature: "transferAnyERC20Token(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x950eb5d5": {
    name: "setBuyFeePercent",
    signature: "setBuyFeePercent(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xe8b94e5a": {
    name: "setMaxBalPercent",
    signature: "setMaxBalPercent(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xa2b8a947": {
    name: "setSellFeePercent",
    signature: "setSellFeePercent(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xe632313c": {
    name: "setSwapAmount",
    signature: "setSwapAmount(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x9daeac76": {
    name: "setTaxEnable",
    signature: "setTaxEnable(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xb7bfff65": {
    name: "setFeeActive",
    signature: "setFeeActive(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xa918299c": {
    name: "setMarketingFee",
    signature: "setMarketingFee(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0x48a46473": {
    name: "setMinTokensBeforeSwap",
    signature: "setMinTokensBeforeSwap(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xa40ade85": {
    name: "setPairAndRouter",
    signature: "setPairAndRouter(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0x47f2dc5b": {
    name: "setTaxless",
    signature: "setTaxless(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x94169e0d": {
    name: "setTeamFee",
    signature: "setTeamFee(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0x1525ff7d": {
    name: "setTeamWallet",
    signature: "setTeamWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x69ffa08a": {
    name: "claimTokens",
    signature: "claimTokens(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0xdb7af854": {
    name: "onTokenBridged",
    signature: "onTokenBridged(address,uint256,bytes)",
    input_types: ["address", "uint256", "bytes"],
    output_aliases: ["to", "amount", "bytes"],
  },
  "0xb2bc6e0f": {
    name: "register",
    signature: "register((address,bytes))",
    input_types: ["(address", "bytes)"],
    output_aliases: ["(address", "bytes)"],
  },
  "0x01a754ff": {
    name: "wrapAndRelayTokens",
    signature: "wrapAndRelayTokens()",
    input_types: [],
    output_aliases: [],
  },
  "0x3ace4765": {
    name: "wrapAndRelayTokens",
    signature: "wrapAndRelayTokens(address,bytes)",
    input_types: ["address", "bytes"],
    output_aliases: ["to", "bytes"],
  },
  "0x9254c66e": {
    name: "wrapAndRelayTokens",
    signature: "wrapAndRelayTokens(address,bytes,(address,bytes))",
    input_types: ["address", "bytes", "(address", "bytes)"],
    output_aliases: ["to", "bytes", "(address", "bytes)"],
  },
  // --------------------------------------
  "0xb39e16fa": {
    name: "initialize",
    signature: "initialize(uint32,uint32,uint32,uint32,bytes32)",
    input_types: ["uint32", "uint32", "uint32", "uint32", "bytes32"],
    output_aliases: ["num_a", "num_b", "num_c", "num_d", "b32_e"],
  },
  "0x72b0d90c": {
    name: "withdrawTo",
    signature: "withdrawTo(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x1f5b4f74": {
    name: "buy",
    signature: "buy(uint256,uint256,uint256,bytes32[],uint32)",
    input_types: ["uint256", "uint256", "uint256", "bytes32[]", "uint32"],
    output_aliases: ["amount_a", "amount_b", "amount_c", "b32s_d", "num_e"],
  },
  "0x740561e8": {
    name: "buySingle",
    signature: "buySingle(uint256,uint256,uint256,bytes32[])",
    input_types: ["uint256", "uint256", "uint256", "bytes32[]"],
    output_aliases: ["amount_a", "amount_b", "amount_c", "b32s_d"],
  },
  "0xb831b058": {
    name: "buySingleTo",
    signature: "buySingleTo(address,uint256,uint256,uint256,bytes32[])",
    input_types: ["address", "uint256", "uint256", "uint256", "bytes32[]"],
    output_aliases: ["to_a", "amount_b", "amount_c", "amount_d", "b32s_e"],
  },
  "0x64625ef3": {
    name: "buyTo",
    signature: "buyTo(address,uint256,uint256,uint256,bytes32[],uint32)",
    input_types: [
      "address",
      "uint256",
      "uint256",
      "uint256",
      "bytes32[]",
      "uint32",
    ],
    output_aliases: [
      "to_a",
      "amount_b",
      "amount_c",
      "amount_d",
      "b32s_e",
      "num_f",
    ],
  },
  // --------------------------------------
  "0xe81223a7": {
    name: "addToVIPlist",
    signature: "addToVIPlist(address[],uint256[])",
    input_types: ["address[]", "uint256[]"],
    output_aliases: ["addresses", "amounts"],
  },
  "0x7f690c33": {
    name: "claimFreeNFTsVIP",
    signature: "claimFreeNFTsVIP()",
    input_types: [],
    output_aliases: [],
  },
  "0x17756f89": {
    name: "removeFromVIPlist",
    signature: "removeFromVIPlist(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0x548db174": {
    name: "removeFromWhitelist",
    signature: "removeFromWhitelist(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0x6f8b44b0": {
    name: "setMaxSupply",
    signature: "setMaxSupply(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xe84a2c72": {
    name: "setPriPrice",
    signature: "setPriPrice(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xc6275255": {
    name: "setPublicPrice",
    signature: "setPublicPrice(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x0c1c972a": {
    name: "startPublicSale",
    signature: "startPublicSale()",
    input_types: [],
    output_aliases: [],
  },
  // --------------------------------------
  "0x3b7b5a16": {
    name: "addAllowanceIfNeeded",
    signature: "addAllowanceIfNeeded(address,address,uint256)",
    input_types: ["address", "address", "uint256"],
    output_aliases: ["to_a", "to_b", "amount_c"],
  },
  "0x7dd711c4": {
    name: "approveAndExecuteWithSpecificGas",
    signature:
      "approveAndExecuteWithSpecificGas(address,address,uint256,uint256,bytes)",
    input_types: ["address", "address", "uint256", "uint256", "bytes"],
    output_aliases: ["to_a", "to_b", "amount_c", "amount_d", "bytes_e"],
  },
  "0xdc2173f3": {
    name: "approveAndExecuteWithSpecificGasAndChargeForIt",
    signature:
      "approveAndExecuteWithSpecificGasAndChargeForIt(address,address,uint256,uint256,uint256,uint256,address,bytes)",
    input_types: [
      "address",
      "address",
      "uint256",
      "uint256",
      "uint256",
      "uint256",
      "address",
      "bytes",
    ],
    output_aliases: [
      "to_a",
      "to_b",
      "amount_c",
      "amount_d",
      "amount_e",
      "amount_f",
      "to_g",
      "bytes_h",
    ],
  },
  "0x2b991746": {
    name: "approveFor",
    signature: "approveFor(address,address,uint256)",
    input_types: ["address", "address", "uint256"],
    output_aliases: ["to_a", "to_b", "amount_c"],
  },
  "0x1dd319cb": {
    name: "burnFor",
    signature: "burnFor(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x0819ba74": {
    name: "changeExecutionAdmin",
    signature: "changeExecutionAdmin(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xdcdf5158": {
    name: "executeWithSpecificGas",
    signature: "executeWithSpecificGas(address,uint256,bytes)",
    input_types: ["address", "uint256", "bytes"],
    output_aliases: ["to", "amount", "bytes"],
  },
  "0x699c834b": {
    name: "setExecutionOperator",
    signature: "setExecutionOperator(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0xac9fe421": {
    name: "setSuperOperator",
    signature: "setSuperOperator(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0xe18aa335": {
    name: "transferAndChargeForGas",
    signature:
      "transferAndChargeForGas(address,address,uint256,uint256,uint256,uint256,address)",
    input_types: [
      "address",
      "address",
      "uint256",
      "uint256",
      "uint256",
      "uint256",
      "address",
    ],
    output_aliases: [
      "to_a",
      "to_b",
      "amount_c",
      "amount_d",
      "amount_e",
      "amount_f",
      "to_g",
    ],
  },
  "0xbb1e23cb": {
    name: "paidCall",
    signature: "paidCall(address,uint256,bytes)",
    input_types: ["address", "uint256", "bytes"],
    output_aliases: ["to", "amount", "bytes"],
  },
  // --------------------------------------
  "0x078dfbe7": {
    name: "transferOwnership",
    signature: "transferOwnership(address,bool,bool)",
    input_types: ["address", "bool", "bool"],
    output_aliases: ["newOwner", "direct", "renounce"],
  },
  "0x79ba5097": {
    name: "acceptOwnership",
    signature: "acceptOwnership()",
    input_types: [],
    output_aliases: [],
  },
  "0xb121e147": {
    name: "acceptPayeeship",
    signature: "acceptPayeeship(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xa118f249": {
    name: "addAccess",
    signature: "addAccess(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x0a756983": {
    name: "disableAccessCheck",
    signature: "disableAccessCheck()",
    input_types: [],
    output_aliases: [],
  },
  "0x8038e4a1": {
    name: "enableAccessCheck",
    signature: "enableAccessCheck()",
    input_types: [],
    output_aliases: [],
  },
  "0x8823da6c": {
    name: "removeAccess",
    signature: "removeAccess(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xbd824706": {
    name: "setBilling",
    signature: "setBilling(uint32,uint32,uint32,uint32,uint32)",
    input_types: ["uint32", "uint32", "uint32", "uint32", "uint32"],
    output_aliases: ["num_a", "num_b", "num_c", "num_d", "num_e"],
  },
  "0xfbffd2c1": {
    name: "setBillingAccessController",
    signature: "setBillingAccessController(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x585aa7de": {
    name: "setConfig",
    signature: "setConfig(address[],address[],uint8,uint64,bytes)",
    input_types: ["address[]", "address[]", "uint8", "uint64", "bytes"],
    output_aliases: ["addresses_a", "addresses_b", "num_c", "num_d", "bytes_e"],
  },
  "0x9c849b30": {
    name: "setPayees",
    signature: "setPayees(address[],address[])",
    input_types: ["address[]", "address[]"],
    output_aliases: ["addresses_a", "addresses_b"],
  },
  "0x9e3ceeab": {
    name: "setRequesterAccessController",
    signature: "setRequesterAccessController(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x1327d3d8": {
    name: "setValidator",
    signature: "setValidator(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xeb5dcd6c": {
    name: "transferPayeeship",
    signature: "transferPayeeship(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0xc9807539": {
    name: "transmit",
    signature: "transmit(bytes,bytes32[],bytes32[],bytes32)",
    input_types: ["bytes", "bytes32[]", "bytes32[]", "bytes32"],
    output_aliases: ["bytes_a", "b32s_b", "b32s_c", "b32_d"],
  },
  "0xc1075329": {
    name: "withdrawFunds",
    signature: "withdrawFunds(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x8ac28d5a": {
    name: "withdrawPayment",
    signature: "withdrawPayment(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  // --------------------------------------
  "0x091646a9": {
    name: "AddToCEX",
    signature: "AddToCEX(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x89e7b81b": {
    name: "manualswapcustom",
    signature: "manualswapcustom(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xe4a0f9f5": {
    name: "setCEXWhitelistWallet",
    signature: "setCEXWhitelistWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xff768b20": {
    name: "setFeesPercent",
    signature: "setFeesPercent(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0x3af87bcd": {
    name: "setRouterOther",
    signature: "setRouterOther(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0xff3914a3": {
    name: "setSwapAndSendFeesEnabled",
    signature: "setSwapAndSendFeesEnabled(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x13096a1a": {
    name: "setThresoldToSwap",
    signature: "setThresoldToSwap(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x4ce9f13c": {
    name: "updateDAOcontrol",
    signature: "updateDAOcontrol(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xda532679": {
    name: "updateWalletDevelopment",
    signature: "updateWalletDevelopment(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x600775c9": {
    name: "updateWalletStaking",
    signature: "updateWalletStaking(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x3f8d45fc": {
    name: "updateWalletSustainability",
    signature: "updateWalletSustainability(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  // --------------------------------------
  "0xa8a778ae": {
    name: "pool_burn_from",
    signature: "pool_burn_from(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xb4f56b26": {
    name: "pool_mint",
    signature: "pool_mint(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x4b3161c2": {
    name: "setFRAXAddress",
    signature: "setFRAXAddress(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x37a30bbc": {
    name: "setFXSMinDAO",
    signature: "setFXSMinDAO(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x7adbf973": {
    name: "setOracle",
    signature: "setOracle(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xbdacb303": {
    name: "setTimelock",
    signature: "setTimelock(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x45791d19": {
    name: "toggleVotes",
    signature: "toggleVotes()",
    input_types: [],
    output_aliases: [],
  },
  // --------------------------------------
  "0x983b2d56": {
    name: "addMinter",
    signature: "addMinter(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x309e11fc": {
    name: "blockRelay",
    signature: "blockRelay()",
    input_types: [],
    output_aliases: [],
  },
  "0x2850d20e": {
    name: "createCardAndMintToken",
    signature:
      "createCardAndMintToken(uint256,uint16,uint8,uint16,bytes32,uint16,address)",
    input_types: [
      "uint256",
      "uint16",
      "uint8",
      "uint16",
      "bytes32",
      "uint16",
      "address",
    ],
    output_aliases: [
      "amount_a",
      "num_b",
      "num_c",
      "num_d",
      "b32_e",
      "num_f",
      "to_g",
    ],
  },
  "0xe5951e60": {
    name: "migrateTokens",
    signature: "migrateTokens(uint256[])",
    input_types: ["uint256[]"],
    output_aliases: ["amounts"],
  },
  "0x5f112c68": {
    name: "renounceMinter",
    signature: "renounceMinter(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xcfbd4885": {
    name: "revokeMinter",
    signature: "revokeMinter(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x53390100": {
    name: "setNextContract",
    signature: "setNextContract(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x99e0dd7c": {
    name: "setTokenURIPrefix",
    signature: "setTokenURIPrefix(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  // --------------------------------------
  "0x4e71e0c8": {
    name: "claimOwnership",
    signature: "claimOwnership()",
    input_types: [],
    output_aliases: [],
  },
  "0x296f4000": {
    name: "delegateApprove",
    signature: "delegateApprove(address,uint256,address)",
    input_types: ["address", "uint256", "address"],
    output_aliases: ["to_a", "amount_b", "to_c"],
  },
  "0x93d3173a": {
    name: "delegateDecreaseApproval",
    signature: "delegateDecreaseApproval(address,uint256,address)",
    input_types: ["address", "uint256", "address"],
    output_aliases: ["to_a", "amount_b", "to_c"],
  },
  "0x554249b3": {
    name: "delegateIncreaseApproval",
    signature: "delegateIncreaseApproval(address,uint256,address)",
    input_types: ["address", "uint256", "address"],
    output_aliases: ["to_a", "amount_b", "to_c"],
  },
  "0x9cd1a121": {
    name: "delegateTransfer",
    signature: "delegateTransfer(address,uint256,address)",
    input_types: ["address", "uint256", "address"],
    output_aliases: ["to_a", "amount_b", "to_c"],
  },
  "0x4df6b45d": {
    name: "delegateTransferFrom",
    signature: "delegateTransferFrom(address,address,uint256,address)",
    input_types: ["address", "address", "uint256", "address"],
    output_aliases: ["to_a", "to_b", "amount_c", "to_d"],
  },
  "0x9a6a30a4": {
    name: "reclaimEther",
    signature: "reclaimEther(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x88ee39cc": {
    name: "reclaimToken",
    signature: "reclaimToken(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0xd01dd6d2": {
    name: "setBlacklisted",
    signature: "setBlacklisted(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x52006050": {
    name: "setBurnBounds",
    signature: "setBurnBounds(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x80749656": {
    name: "setCanBurn",
    signature: "setCanBurn(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  // --------------------------------------
  "0xe9c714f2": {
    name: "_acceptAdmin",
    signature: "_acceptAdmin()",
    input_types: [],
    output_aliases: [],
  },
  "0x601a0bf1": {
    name: "_reduceReserves",
    signature: "_reduceReserves(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x4576b5db": {
    name: "_setComptroller",
    signature: "_setComptroller(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xf2b3abbd": {
    name: "_setInterestRateModel",
    signature: "_setInterestRateModel(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xb71d1a0c": {
    name: "_setPendingAdmin",
    signature: "_setPendingAdmin(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xfca7820b": {
    name: "_setReserveFactor",
    signature: "_setReserveFactor(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xa6afed95": {
    name: "accrueInterest",
    signature: "accrueInterest()",
    input_types: [],
    output_aliases: [],
  },
  "0x3af9e669": {
    name: "balanceOfUnderlying",
    signature: "balanceOfUnderlying(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x17bfdfbc": {
    name: "borrowBalanceCurrent",
    signature: "borrowBalanceCurrent(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xbd6d894d": {
    name: "exchangeRateCurrent",
    signature: "exchangeRateCurrent()",
    input_types: [],
    output_aliases: [],
  },
  "0xf5e3c462": {
    name: "liquidateBorrow",
    signature: "liquidateBorrow(address,uint256,address)",
    input_types: ["address", "uint256", "address"],
    output_aliases: ["to_a", "amount_b", "to_c"],
  },
  "0x2608f818": {
    name: "repayBorrowBehalf",
    signature: "repayBorrowBehalf(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xb2a02ff1": {
    name: "seize",
    signature: "seize(address,address,uint256)",
    input_types: ["address", "address", "uint256"],
    output_aliases: ["to_a", "to_b", "amount_c"],
  },
  "0x73acee98": {
    name: "totalBorrowsCurrent",
    signature: "totalBorrowsCurrent()",
    input_types: [],
    output_aliases: [],
  },
  "0xa0084951": {
    name: "mintThingie",
    signature: "mintThingie(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x424a991d": {
    name: "mintThingieBatch",
    signature: "mintThingieBatch(uint256[])",
    input_types: ["uint256[]"],
    output_aliases: ["amounts"],
  },
  "0xcdd366b1": {
    name: "setFlufAddress",
    signature: "setFlufAddress(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x05be3f38": {
    name: "setImmutableIPFSBucket",
    signature: "setImmutableIPFSBucket(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x2c1e5b83": {
    name: "setMutableIPFSBucket",
    signature: "setMutableIPFSBucket(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0xa8307a32": {
    name: "setRenamePrice",
    signature: "setRenamePrice(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x9f96cd25": {
    name: "setStateToParty",
    signature: "setStateToParty()",
    input_types: [],
    output_aliases: [],
  },
  "0x23af8827": {
    name: "setStateToSetup",
    signature: "setStateToSetup()",
    input_types: [],
    output_aliases: [],
  },
  "0xe0df5b6f": {
    name: "setTokenURI",
    signature: "setTokenURI(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0xd46d79bb": {
    name: "withdrawAllEth",
    signature: "withdrawAllEth(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x5efb942c": {
    name: "changeNameAndDescription",
    signature: "changeNameAndDescription(uint256,string,string)",
    input_types: ["uint256", "string", "string"],
    output_aliases: ["amount_a", "string_b", "string_c"],
  },

  "0xace849c6": {
    name: "batchAirdrop",
    signature: "batchAirdrop(uint256[],address[])",
    input_types: ["uint256[]", "address[]"],
    output_aliases: ["amounts", "addresses"],
  },
  "0x077f224a": {
    name: "initialize",
    signature: "initialize(string,string,address)",
    input_types: ["string", "string", "address"],
    output_aliases: ["string_a", "string_b", "to_c"],
  },
  "0x896b24b7": {
    name: "startAuction",
    signature:
      "startAuction(uint256,uint256,uint256,uint256,uint256,uint256,bool)",
    input_types: [
      "uint256",
      "uint256",
      "uint256",
      "uint256",
      "uint256",
      "uint256",
      "bool",
    ],
    output_aliases: [
      "amount_a",
      "amount_b",
      "amount_c",
      "amount_d",
      "amount_e",
      "amount_f",
      "bool_g",
    ],
  },
  "0x789e3a55": {
    name: "startSale",
    signature: "startSale(uint256,uint256,uint256,uint256,bool)",
    input_types: ["uint256", "uint256", "uint256", "uint256", "bool"],
    output_aliases: ["amount_a", "amount_b", "amount_c", "amount_d", "bool_e"],
  },
  "0xe3e1e8ef": {
    name: "presaleMint",
    signature: "presaleMint(uint256,bytes32[])",
    input_types: ["uint256", "bytes32[]"],
    output_aliases: ["amount", "b32s"],
  },

  "0xc3cda520": {
    name: "delegateBySig",
    signature: "delegateBySig(address,uint256,uint256,uint8,bytes32,bytes32)",
    input_types: [
      "address",
      "uint256",
      "uint256",
      "uint8",
      "bytes32",
      "bytes32",
    ],
    output_aliases: ["to_a", "amount_b", "amount_c", "num_d", "b32_e", "b32_f"],
  },

  "0x7e324479": {
    name: "setCommitmentAges",
    signature: "setCommitmentAges(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x530e784f": {
    name: "setPriceOracle",
    signature: "setPriceOracle(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },

  "0x634fa004": {
    name: "claimPresale",
    signature: "claimPresale(uint256,bytes32[])",
    input_types: ["uint256", "bytes32[]"],
    output_aliases: ["amount", "b32s"],
  },
  "0x89fac867": {
    name: "setMedataDataUri",
    signature: "setMedataDataUri(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0xc54e73e3": {
    name: "setPresale",
    signature: "setPresale(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  // --------------------------------------

  "0x7a9e5e4b": {
    name: "setAuthority",
    signature: "setAuthority(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x10ed7621": {
    name: "changeGiftSupply",
    signature: "changeGiftSupply(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x46e79ffc": {
    name: "changePauseState",
    signature: "changePauseState()",
    input_types: [],
    output_aliases: [],
  },
  "0xd082a3f7": {
    name: "changePresaleState",
    signature: "changePresaleState()",
    input_types: [],
    output_aliases: [],
  },
  "0x60e5bb85": {
    name: "changePublicState",
    signature: "changePublicState()",
    input_types: [],
    output_aliases: [],
  },
  "0x52e97326": {
    name: "changeTotalSupply",
    signature: "changeTotalSupply(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xa5fd7bec": {
    name: "giftMint",
    signature: "giftMint(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0x48b75044": {
    name: "release",
    signature: "release(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0xda3ef23f": {
    name: "setBaseExtension",
    signature: "setBaseExtension(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x6e0e5b19": {
    name: "setIsBurnEnabled",
    signature: "setIsBurnEnabled(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xf2c4ce1e": {
    name: "setNotRevealedURI",
    signature: "setNotRevealedURI(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  // --------------------------------------
  "0x0bd3a7f9": {
    name: "excludeFromLimit",
    signature: "excludeFromLimit(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x91d919a9": {
    name: "includeInLimit",
    signature: "includeInLimit(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x3685d419": {
    name: "includeInReward",
    signature: "includeInReward(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x1465d929": {
    name: "setBuyFee",
    signature: "setBuyFee(uint16,uint16,uint16,uint16,uint16)",
    input_types: ["uint16", "uint16", "uint16", "uint16", "uint16"],
    output_aliases: ["num_a", "num_b", "num_c", "num_d", "num_e"],
  },
  "0xf0f165af": {
    name: "setNumTokensSellToAddToLiquidity",
    signature: "setNumTokensSellToAddToLiquidity(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x4cfd4a92": {
    name: "setSellFee",
    signature: "setSellFee(uint16,uint16,uint16,uint16,uint16)",
    input_types: ["uint16", "uint16", "uint16", "uint16", "uint16"],
    output_aliases: ["num_a", "num_b", "num_c", "num_d", "num_e"],
  },
  "0x3e3d26f9": {
    name: "updateExchangeWallet",
    signature: "updateExchangeWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x50aa2977": {
    name: "updatePartnershipsWallet",
    signature: "updatePartnershipsWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  // --------------------------------------

  "0x8f15b414": {
    name: "initialize",
    signature: "initialize(string,string,address,address)",
    input_types: ["string", "string", "address", "address"],
    output_aliases: ["string_a", "string_b", "to_c", "to_d"],
  },
  "0xc6c3bbe6": {
    name: "mint",
    signature: "mint(address,address,uint256)",
    input_types: ["address", "address", "uint256"],
    output_aliases: ["to_a", "to_b", "amount_c"],
  },
  "0x5559a36d": {
    name: "redeem",
    signature: "redeem(uint256,uint256,string)",
    input_types: ["uint256", "uint256", "string"],
    output_aliases: ["amount_a", "amount_b", "string_c"],
  },

  "0x696434a5": {
    name: "transferLegacyOwnership",
    signature: "transferLegacyOwnership(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },

  // -------------------
  "0xd2a09c71": {
    name: "enableSwap",
    signature: "enableSwap(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x806e35a7": {
    name: "manualswapTreasury",
    signature: "manualswapTreasury()",
    input_types: [],
    output_aliases: [],
  },
  "0x52f7c988": {
    name: "setFee",
    signature: "setFee(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x64f5a5bb": {
    name: "setMaxTxLimit",
    signature: "setMaxTxLimit(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xa22d4832": {
    name: "setPairAddress",
    signature: "setPairAddress(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xfc24e8f2": {
    name: "updateTreasury",
    signature: "updateTreasury(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },

  "0xc91c0462": {
    name: "activateMint",
    signature: "activateMint()",
    input_types: [],
    output_aliases: [],
  },
  "0xb68e8c91": {
    name: "activatePresale",
    signature: "activatePresale()",
    input_types: [],
    output_aliases: [],
  },
  "0x00b6849f": {
    name: "airDrop",
    signature: "airDrop(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0x2e56f71e": {
    name: "deactivateMint",
    signature: "deactivateMint()",
    input_types: [],
    output_aliases: [],
  },
  "0x290fd843": {
    name: "deactivatePresale",
    signature: "deactivatePresale()",
    input_types: [],
    output_aliases: [],
  },
  "0x150b7a02": {
    name: "onERC721Received",
    signature: "onERC721Received(address,address,uint256,bytes)",
    input_types: ["address", "address", "uint256", "bytes"],
    output_aliases: ["to_a", "to_b", "amount_c", "bytes_d"],
  },
  "0xc6f6f216": {
    name: "setMaxPerTx",
    signature: "setMaxPerTx(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xe268e4d3": {
    name: "setMaxPerWallet",
    signature: "setMaxPerWallet(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x10969523": {
    name: "setProvenanceHash",
    signature: "setProvenanceHash(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x046dc166": {
    name: "setSignerAddress",
    signature: "setSignerAddress(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xd5dc3efd": {
    name: "setStartingIndexBlock",
    signature: "setStartingIndexBlock()",
    input_types: [],
    output_aliases: [],
  },
  "0x1749dc26": {
    name: "presaleMint",
    signature: "presaleMint(uint256,bytes32,bytes32,uint8)",
    input_types: ["uint256", "bytes32", "bytes32", "uint8"],
    output_aliases: ["amount_a", "b32_b", "b32_c", "num_d"],
  },

  "0x959b8c3f": {
    name: "authorizeOperator",
    signature: "authorizeOperator(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x7c0a893d": {
    name: "blockAccount",
    signature: "blockAccount(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x7d64bcb4": {
    name: "finishMinting",
    signature: "finishMinting()",
    input_types: [],
    output_aliases: [],
  },
  "0xfc673c4f": {
    name: "operatorBurn",
    signature: "operatorBurn(address,uint256,bytes,bytes)",
    input_types: ["address", "uint256", "bytes", "bytes"],
    output_aliases: ["to_a", "amount_b", "bytes_c", "bytes_d"],
  },
  "0x36568abe": {
    name: "renounceRole",
    signature: "renounceRole(bytes32,address)",
    input_types: ["bytes32", "address"],
    output_aliases: ["b32", "to"],
  },
  "0xfad8b32a": {
    name: "revokeOperator",
    signature: "revokeOperator(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x9bd9bbc6": {
    name: "send",
    signature: "send(address,uint256,bytes)",
    input_types: ["address", "uint256", "bytes"],
    output_aliases: ["to", "amount", "bytes"],
  },
  "0x4d78fdc6": {
    name: "unblockAccount",
    signature: "unblockAccount(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  // --------------------------------------

  // --------------------------------------
  "0x79cc6790": {
    name: "burnFrom",
    signature: "burnFrom(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xb20d7fa9": {
    name: "delegateBySig",
    signature: "delegateBySig(address,address,uint256,uint8,bytes32,bytes32)",
    input_types: [
      "address",
      "address",
      "uint256",
      "uint8",
      "bytes32",
      "bytes32",
    ],
    output_aliases: ["to_a", "to_b", "amount_c", "num_d", "b32_e", "b32_f"],
  },
  "0x1171bda9": {
    name: "recoverERC20",
    signature: "recoverERC20(address,address,uint256)",
    input_types: ["address", "address", "uint256"],
    output_aliases: ["to_a", "to_b", "amount_c"],
  },
  "0xfc4e51f6": {
    name: "recoverERC721",
    signature: "recoverERC721(address,address,uint256,bytes)",
    input_types: ["address", "address", "uint256", "bytes"],
    output_aliases: ["to_a", "to_b", "amount_c", "bytes_d"],
  },
  // --------------------------------------

  "0x6ef8d66d": {
    name: "renouncePauser",
    signature: "renouncePauser()",
    input_types: [],
    output_aliases: [],
  },
  "0x66188463": {
    name: "decreaseApproval",
    signature: "decreaseApproval(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x30176e13": {
    name: "setBaseTokenURI",
    signature: "setBaseTokenURI(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0xc714e89c": {
    name: "setMaxItems",
    signature: "setMaxItems(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x3bbed4a0": {
    name: "setRecipient",
    signature: "setRecipient(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x5bfe024d": {
    name: "whitelistMint",
    signature: "whitelistMint(uint256,uint256,bytes32,bytes32[])",
    input_types: ["uint256", "uint256", "bytes32", "bytes32[]"],
    output_aliases: ["amount_a", "amount_b", "b32_c", "b32s_d"],
  },
  // --------------------------------------
  "0x53376d1f": {
    name: "revokeAuthentication",
    signature: "revokeAuthentication(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xd4e8e063": {
    name: "startGrantAuthentication",
    signature: "startGrantAuthentication(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x38b6e407": {
    name: "endGrantAuthentication",
    signature: "endGrantAuthentication(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xef7f3834": {
    name: "grantInitialAuthentication",
    signature: "grantInitialAuthentication(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xde3a3b69": {
    name: "intializeContract",
    signature: "intializeContract(address[],uint256[],address)",
    input_types: ["address[]", "uint256[]", "address"],
    output_aliases: ["addresses", "amounts", "_antisnipe"],
  },
  "0x8965b55d": {
    name: "setTaxesSell",
    signature: "setTaxesSell(uint16,uint16,uint16)",
    input_types: ["uint16", "uint16", "uint16"],
    output_aliases: ["reflect", "liquidity", "marketing"],
  },
  "0x590ffdce": {
    name: "setExcludedFromFees",
    signature: "setExcludedFromFees(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["address", "bool"],
  },
  "0x05bbedb6": {
    name: "setBlacklistEnabledMultiple",
    signature: "setBlacklistEnabledMultiple(address[],bool)",
    input_types: ["address[]", "bool"],
    output_aliases: ["addresses", "bool"],
  },
  "0x31c2d847": {
    name: "delBots",
    signature: "delBots(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0x45596e2e": {
    name: "setFeeRate",
    signature: "setFeeRate(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x0b78f9c0": {
    name: "setFees",
    signature: "setFees(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xdcb0e0ad": {
    name: "toggleImpactFee",
    signature: "toggleImpactFee(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x73f54a11": {
    name: "updateTaxAdd",
    signature: "updateTaxAdd(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xc0e6b46e": {
    name: "setRouterPercent",
    signature: "setRouterPercent(uint256)",
    input_types: ["uint256"],
    output_aliases: ["percent"],
  },
  "0x00b8cf2a": {
    name: "blockBots",
    signature: "blockBots(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0xa2a957bb": {
    name: "setFee",
    signature: "setFee(uint256,uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256", "uint256"],
    output_aliases: [
      "rfctFeeOnBuy",
      "rfctFeeOnSell",
      "taxFeeOnBuy",
      "taxFeeOnSell",
    ],
  },
  "0x98a5c315": {
    name: "setMinSwapTokensThreshold",
    signature: "setMinSwapTokensThreshold(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x6d8aa8f8": {
    name: "toggleSwap",
    signature: "toggleSwap(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  // --------------------------------------

  "0x728d41c9": {
    name: "setMaxWalletLimit",
    signature: "setMaxWalletLimit(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x2a9319cb": {
    name: "setMinLiquidityPercent",
    signature: "setMinLiquidityPercent(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xc49b9a80": {
    name: "setSwapAndLiquifyEnabled",
    signature: "setSwapAndLiquifyEnabled(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x9a679cf6": {
    name: "changeDevelopmentWallet",
    signature: "changeDevelopmentWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xe5eec444": {
    name: "changeFee",
    signature: "changeFee(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0xbb85c6d1": {
    name: "changeMarketingWallet",
    signature: "changeMarketingWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x7e2e0a82": {
    name: "turnOffTrading",
    signature: "turnOffTrading()",
    input_types: [],
    output_aliases: [],
  },
  "0x6513dc61": {
    name: "turnOnTrading",
    signature: "turnOnTrading()",
    input_types: [],
    output_aliases: [],
  },
  "0xc9ed7443": {
    name: "updateNumTokensSellToAddToLiquidity",
    signature: "updateNumTokensSellToAddToLiquidity(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x1d813813": {
    name: "transferAnyBEP20Tokens",
    signature: "transferAnyBEP20Tokens(address,address,uint256)",
    input_types: ["address", "address", "uint256"],
    output_aliases: ["to_a", "to_b", "amount_c"],
  },
  // --------------------------------------
  "0xd5aed6bf": {
    name: "setUniswapPair",
    signature: "setUniswapPair(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xbea9849e": {
    name: "setUniswapRouter",
    signature: "setUniswapRouter(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x2baa715f": {
    name: "addInBlackList",
    signature: "addInBlackList(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xea2f0b37": {
    name: "includeInFee",
    signature: "includeInFee(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x4a49ac4c": {
    name: "removeFromBlackList",
    signature: "removeFromBlackList(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x1b51972c": {
    name: "rescueETHFromContract",
    signature: "rescueETHFromContract()",
    input_types: [],
    output_aliases: [],
  },
  "0x35368e47": {
    name: "changedeployerWallet",
    signature: "changedeployerWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x1bbae6e0": {
    name: "_setMaxTxAmount",
    signature: "_setMaxTxAmount(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xf4293890": {
    name: "manualSend",
    signature: "manualSend()",
    input_types: [],
    output_aliases: [],
  },
  "0x51bc3c85": {
    name: "manualSwap",
    signature: "manualSwap()",
    input_types: [],
    output_aliases: [],
  },
  "0xe01af92c": {
    name: "setSwapEnabled",
    signature: "setSwapEnabled(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x303adcd8": {
    name: "_setSellTaxMultiplier",
    signature: "_setSellTaxMultiplier(uint8)",
    input_types: ["uint8"],
    output_aliases: ["num"],
  },
  "0x5880b873": {
    name: "_setTaxFee",
    signature: "_setTaxFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x28667162": {
    name: "_setTeamFee",
    signature: "_setTeamFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xa7f404e2": {
    name: "addUniswapPair",
    signature: "addUniswapPair(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xa6931ed7": {
    name: "removeUniswapPair",
    signature: "removeUniswapPair(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x3f3cf56c": {
    name: "setMaxTxPercent",
    signature: "setMaxTxPercent(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["percent", "divisor"], //  maxTxAmountUI = (startingSupply * percent) / divisor;
  },
  // --------------------------------------
  "0xc3c8cd80": {
    name: "manualswap",
    signature: "manualswap()",
    input_types: [],
    output_aliases: [],
  },
  "0x1090b1b5": {
    name: "translimitedStatus",
    signature: "translimitedStatus(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },

  "0x469629a9": {
    name: "setBothFees",
    signature:
      "setBothFees(uint16,uint16,uint16,uint16,uint16,uint16,uint16,uint16,uint16,uint16)",
    input_types: [
      "uint16",
      "uint16",
      "uint16",
      "uint16",
      "uint16",
      "uint16",
      "uint16",
      "uint16",
      "uint16",
      "uint16",
    ],
    output_aliases: [
      "buy_tax",
      "buy_liquidity",
      "buy_marketing",
      "buy_dev",
      "buy_donation",
      "sell_tax",
      "sell_liquidity",
      "sell_marketing",
      "sell_dev",
      "sell_donation",
    ],
  },
  "0xaf2ce614": {
    name: "_setMaxWalletSizePercent",
    signature: "_setMaxWalletSizePercent(uint256)",
    input_types: ["uint256"],
    output_aliases: ["percent"],
  },
  // --------------------------------------
  "0x37563293": {
    name: "claimReflection",
    signature: "claimReflection()",
    input_types: [],
    output_aliases: [],
  },
  "0x868ca49d": {
    name: "readyToSync",
    signature: "readyToSync()",
    input_types: [],
    output_aliases: [],
  },
  "0x8597f89b": {
    name: "withdrawAllWithAllReward",
    signature: "withdrawAllWithAllReward()",
    input_types: [],
    output_aliases: [],
  },
  "0x90386bbf": {
    name: "withdrawAllETH",
    signature: "withdrawAllETH()",
    input_types: [],
    output_aliases: [],
  },
  "0xeb5068f2": {
    name: "exitWallet",
    signature: "exitWallet()",
    input_types: [],
    output_aliases: [],
  },
  "0x66adeb8c": {
    name: "transferBalance",
    signature: "transferBalance()",
    input_types: [],
    output_aliases: [],
  },
  "0xf751cd8f": {
    name: "drop",
    signature: "drop()",
    input_types: [],
    output_aliases: [],
  },
  "0xabc6fd0b": {
    name: "disburse",
    signature: "disburse()",
    input_types: [],
    output_aliases: [],
  },
  "0x6b9f96ea": {
    name: "flush",
    signature: "flush()",
    input_types: [],
    output_aliases: [],
  },
  "0xa2e62045": {
    name: "update",
    signature: "update()",
    input_types: [],
    output_aliases: [],
  },
  "0x312ff839": {
    name: "processExpiredLocks",
    signature: "processExpiredLocks(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xb423fe67": {
    name: "setPublicSaleStatus",
    signature: "setPublicSaleStatus(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x6fdca5e0": {
    name: "setOpen",
    signature: "setOpen(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xe177bb9b": {
    name: "registerCode",
    signature: "registerCode(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x9094c763": {
    name: "vote",
    signature: "vote(uint32)",
    input_types: ["uint32"],
    output_aliases: ["num"],
  },
  "0x15670439": {
    name: "mintLilHustlaz",
    signature: "mintLilHustlaz(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xc6b68169": {
    name: "sellOusdForUsdc",
    signature: "sellOusdForUsdc(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x7cfb41b8": {
    name: "cancelTrade",
    signature: "cancelTrade(bytes32)",
    input_types: ["bytes32"],
    output_aliases: ["b32"],
  },
  "0x253480bd": {
    name: "giveawayPork1984",
    signature: "giveawayPork1984(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x4287084f": {
    name: "openMint",
    signature: "openMint(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x2ad540ed": {
    name: "creatorClaim",
    signature: "creatorClaim(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x8467be0d": {
    name: "batchMint",
    signature: "batchMint(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xeb5021f8": {
    name: "normalMint",
    signature: "normalMint(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xb5c4c47b": {
    name: "createCollectible",
    signature: "createCollectible(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x1d7ef879": {
    name: "addBotToBlacklist",
    signature: "addBotToBlacklist(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x02387a7b": {
    name: "withdraw",
    signature: "withdraw(uint128)",
    input_types: ["uint128"],
    output_aliases: ["u128"],
  },
  "0xf3368f90": {
    name: "updateRecurringFee",
    signature: "updateRecurringFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x9edb4fb7": {
    name: "updateNaasRequestingFee",
    signature: "updateNaasRequestingFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xae749c42": {
    name: "updateRequestingFee",
    signature: "updateRequestingFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xf1e4c866": {
    name: "supplyOrder",
    signature: "supplyOrder(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xba2238d0": {
    name: "tdtToTbtc",
    signature: "tdtToTbtc(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xd8b0b547": {
    name: "startScan",
    signature: "startScan(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xf4136f7f": {
    name: "expireCover",
    signature: "expireCover(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x27a14fc2": {
    name: "setMaxWalletAmount",
    signature: "setMaxWalletAmount(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xf37ac61c": {
    name: "heal",
    signature: "heal(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x939624ab": {
    name: "removeStake",
    signature: "removeStake(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x53556559": {
    name: "exchange",
    signature: "exchange(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x5e1e1004": {
    name: "setPaymentAddress",
    signature: "setPaymentAddress(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xf19e75d4": {
    name: "ownerMint",
    signature: "ownerMint(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xb948348c": {
    name: "purchaseTicket",
    signature: "purchaseTicket(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x20c5429b": {
    name: "revoke",
    signature: "revoke(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xb0b92263": {
    name: "setTotalTokens",
    signature: "setTotalTokens(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x84ac33ec": {
    name: "deregister",
    signature: "deregister(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xa932ed0d": {
    name: "whitelistRemove",
    signature: "whitelistRemove(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x694bcbfc": {
    name: "approveMultiSwap",
    signature: "approveMultiSwap(bytes32[])",
    input_types: ["bytes32[]"],
    output_aliases: ["b32s"],
  },
  "0xb391c508": {
    name: "claim",
    signature: "claim(bytes32[])",
    input_types: ["bytes32[]"],
    output_aliases: ["b32s"],
  },
  "0x878fd10d": {
    name: "withdrawWETH",
    signature: "withdrawWETH(uint256,bool)",
    input_types: ["uint256", "bool"],
    output_aliases: ["amount", "bool"],
  },
  "0xa2ed4088": {
    name: "setAllowedArtist",
    signature: "setAllowedArtist(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0xfb6ff865": {
    name: "awardItem",
    signature: "awardItem(uint256,bool)",
    input_types: ["uint256", "bool"],
    output_aliases: ["amount", "bool"],
  },
  "0x5a3b74b9": {
    name: "setUserUseReserveAsCollateral",
    signature: "setUserUseReserveAsCollateral(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0xc46ac389": {
    name: "claimByLootId",
    signature: "claimByLootId(uint256,uint8)",
    input_types: ["uint256", "uint8"],
    output_aliases: ["amount", "num"],
  },
  "0x691562a0": {
    name: "mint",
    signature: "mint(address,uint8)",
    input_types: ["address", "uint8"],
    output_aliases: ["to", "num"],
  },
  "0xb35dcee0": {
    name: "bridgeTransfer",
    signature: "bridgeTransfer(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x79eb8d1d": {
    name: "setMaxTransactionAmountsPerK",
    signature: "setMaxTransactionAmountsPerK(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x4f14ea4e": {
    name: "WITHDRAW",
    signature: "WITHDRAW(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x66afd8ef": {
    name: "release",
    signature: "release(bytes32,uint256)",
    input_types: ["bytes32", "uint256"],
    output_aliases: ["b32", "amount"],
  },
  "0x08fab167": {
    name: "withdraw",
    signature: "withdraw(address,uint128)",
    input_types: ["address", "uint128"],
    output_aliases: ["to", "u128"],
  },
  "0xbef870ca": {
    name: "privateMint",
    signature: "privateMint(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0x50938af6": {
    name: "withdrawAccruedYieldAndAdd",
    signature: "withdrawAccruedYieldAndAdd(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xd52c57e0": {
    name: "ownerMint",
    signature: "ownerMint(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0xab4f2de5": {
    name: "extractionProfit",
    signature: "extractionProfit(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x13bac820": {
    name: "wrap",
    signature: "wrap(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0xc71959bb": {
    name: "writeTransaction",
    signature: "writeTransaction(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x7b0d772e": {
    name: "openSellOrder",
    signature: "openSellOrder(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x2fe5e3a6": {
    name: "transByContract",
    signature: "transByContract(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x84f10492": {
    name: "ownerWithdrawToken",
    signature: "ownerWithdrawToken(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x76d07f6b": {
    name: "withdrawBounty",
    signature: "withdrawBounty(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0x15eb349e": {
    name: "ragequit",
    signature: "ragequit(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x43d3461a": {
    name: "transcoder",
    signature: "transcoder(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xd8aed145": {
    name: "repay",
    signature: "repay(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xddd64dbb": {
    name: "updateNetworkFee",
    signature: "updateNetworkFee(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x813d2ad8": {
    name: "cancelSale",
    signature: "cancelSale(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0xa515366a": {
    name: "bond",
    signature: "bond(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x522f6815": {
    name: "withdrawEther",
    signature: "withdrawEther(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x50be7cdb": {
    name: "setBuyFees",
    signature: "setBuyFees(uint8,uint8,uint8)",
    input_types: ["uint8", "uint8", "uint8"],
    output_aliases: ["num_a", "num_b", "num_c"],
  },
  "0xdf133bca": {
    name: "vote",
    signature: "vote(uint256,bool,bool)",
    input_types: ["uint256", "bool", "bool"],
    output_aliases: ["amount_a", "bool_b", "bool_c"],
  },
  "0x51d0eac4": {
    name: "sendEths",
    signature: "sendEths(address[],uint256[])",
    input_types: ["address[]", "uint256[]"],
    output_aliases: ["addresses", "amounts"],
  },
  "0x078e2097": {
    name: "setMarketProxy",
    signature: "setMarketProxy(uint256,address,bool)",
    input_types: ["uint256", "address", "bool"],
    output_aliases: ["amount", "to", "bool"],
  },
  "0x33cfcd3b": {
    name: "withdraw",
    signature: "withdraw(uint256,uint256,bool)",
    input_types: ["uint256", "uint256", "bool"],
    output_aliases: ["amount_a", "amount_b", "bool_c"],
  },
  "0x8e24a764": {
    name: "addMintRequest",
    signature: "addMintRequest(uint256,string,string)",
    input_types: ["uint256", "string", "string"],
    output_aliases: ["amount_a", "string_b", "string_c"],
  },
  "0x7df2b357": {
    name: "submitSignature",
    signature: "submitSignature(bytes32,bytes32,uint8)",
    input_types: ["bytes32", "bytes32", "uint8"],
    output_aliases: ["b32_a", "b32_b", "num_c"],
  },
  "0xc4159559": {
    name: "provideRedemptionSignature",
    signature: "provideRedemptionSignature(uint8,bytes32,bytes32)",
    input_types: ["uint8", "bytes32", "bytes32"],
    output_aliases: ["num_a", "b32_b", "b32_c"],
  },
  "0xa4202615": {
    name: "makeAdoptionOfferToAddress",
    signature: "makeAdoptionOfferToAddress(bytes5,uint256,address)",
    input_types: ["bytes5", "uint256", "address"],
    output_aliases: ["bytes5", "amount", "to"],
  },
  "0x5e6f6c77": {
    name: "claimSellOrder",
    signature: "claimSellOrder(address,uint256,address)",
    input_types: ["address", "uint256", "address"],
    output_aliases: ["to_a", "amount_b", "to_c"],
  },
  "0x22e0658e": {
    name: "acceptBidOBO",
    signature: "acceptBidOBO(uint256,address,uint256)",
    input_types: ["uint256", "address", "uint256"],
    output_aliases: ["amount_a", "to_b", "amount_c"],
  },
  "0x71f4d38c": {
    name: "cancelBid",
    signature: "cancelBid(uint256,address,uint256)",
    input_types: ["uint256", "address", "uint256"],
    output_aliases: ["amount_a", "to_b", "amount_c"],
  },
  "0x44004cc1": {
    name: "withdrawERC20",
    signature: "withdrawERC20(address,address,uint256)",
    input_types: ["address", "address", "uint256"],
    output_aliases: ["to_a", "to_b", "amount_c"],
  },
  "0xe88390aa": {
    name: "coinSendDifferentValue",
    signature: "coinSendDifferentValue(address,address[],uint256[])",
    input_types: ["address", "address[]", "uint256[]"],
    output_aliases: ["to", "addresses", "amounts"],
  },
  "0xad8733ca": {
    name: "multisend",
    signature: "multisend(address,address[],uint256[])",
    input_types: ["address", "address[]", "uint256[]"],
    output_aliases: ["to", "addresses", "amounts"],
  },
  "0x1a3d0068": {
    name: "setAdjustment",
    signature: "setAdjustment(bool,uint256,uint256,uint256)",
    input_types: ["bool", "uint256", "uint256", "uint256"],
    output_aliases: ["bool_a", "amount_b", "amount_c", "amount_d"],
  },
  "0x82857a03": {
    name: "approveAndCallWithSender",
    signature: "approveAndCallWithSender(address,uint256,bytes4,bytes)",
    input_types: ["address", "uint256", "bytes4", "bytes"],
    output_aliases: ["to", "amount", "b4", "bytes"],
  },
  "0x257011b6": {
    name: "userLock",
    signature: "userLock(bytes32,uint256,uint256,bytes)",
    input_types: ["bytes32", "uint256", "uint256", "bytes"],
    output_aliases: ["b32_a", "amount_b", "amount_c", "bytes_d"],
  },
  "0xd01a42d7": {
    name: "deployKeyRingAndWalletAndCall",
    signature: "deployKeyRingAndWalletAndCall(address,address,address,bytes)",
    input_types: ["address", "address", "address", "bytes"],
    output_aliases: ["to_a", "to_b", "to_c", "bytes_d"],
  },
  "0x84666b08": {
    name: "setSellTax",
    signature: "setSellTax(uint256,uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c", "amount_d"],
  },
  "0x4a970be7": {
    name: "depositWithPermit",
    signature: "depositWithPermit(uint256,uint256,uint8,bytes32,bytes32)",
    input_types: ["uint256", "uint256", "uint8", "bytes32", "bytes32"],
    output_aliases: ["amount_a", "amount_b", "num_c", "b32_d", "b32_e"],
  },
  "0x42859ab3": {
    name: "execute",
    signature: "execute(uint256,uint256,uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256", "uint256", "uint256"],
    output_aliases: [
      "amount_a",
      "amount_b",
      "amount_c",
      "amount_d",
      "amount_e",
    ],
  },
  "0xbadb999b": {
    name: "sell",
    signature: "sell(uint256,uint256,uint256,address,uint256)",
    input_types: ["uint256", "uint256", "uint256", "address", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c", "to_d", "amount_e"],
  },
  "0x4b8ffc76": {
    name: "withdraw",
    signature: "withdraw(uint256,uint256,uint256,uint256,uint256,uint256)",
    input_types: [
      "uint256",
      "uint256",
      "uint256",
      "uint256",
      "uint256",
      "uint256",
    ],
    output_aliases: [
      "amount_a",
      "amount_b",
      "amount_c",
      "amount_d",
      "amount_e",
      "amount_f",
    ],
  },
  "0x01173672": {
    name: "spendERC20",
    signature:
      "spendERC20(address,address,uint256,uint8[],bytes32[],bytes32[])",
    input_types: [
      "address",
      "address",
      "uint256",
      "uint8[]",
      "bytes32[]",
      "bytes32[]",
    ],
    output_aliases: ["to_a", "to_b", "amount_c", "nums_d", "b32s_e", "b32s_f"],
  },

  // --------------------------------------
  "0x85f6d155": {
    name: "register",
    signature: "register(string,address,uint256,bytes32)",
    input_types: ["string", "address", "uint256", "bytes32"],
    output_aliases: ["string", "to", "amount", "b32"],
  },
  "0x008ef7cf": {
    name: "claimForSelf",
    signature: "claimForSelf()",
    input_types: [],
    output_aliases: [],
  },
  "0xd01c44cd": {
    name: "claimVested",
    signature: "claimVested()",
    input_types: [],
    output_aliases: [],
  },
  "0x14f049dc": {
    name: "registerIDO",
    signature: "registerIDO()",
    input_types: [],
    output_aliases: [],
  },
  "0x6d60e6c1": {
    name: "flipClaimState",
    signature: "flipClaimState()",
    input_types: [],
    output_aliases: [],
  },
  "0x5be7fde8": {
    name: "releaseAll",
    signature: "releaseAll()",
    input_types: [],
    output_aliases: [],
  },
  "0xd2f7265a": {
    name: "exchange",
    signature: "exchange()",
    input_types: [],
    output_aliases: [],
  },
  "0xd4807fb2": {
    name: "initializeRound",
    signature: "initializeRound()",
    input_types: [],
    output_aliases: [],
  },
  "0xb287c8ed": {
    name: "publicSale",
    signature: "publicSale(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x784e9830": {
    name: "withdrawAndClaimPool2",
    signature: "withdrawAndClaimPool2(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x1a066473": {
    name: "stakePool2",
    signature: "stakePool2(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x178a8569": {
    name: "mintCollectibles",
    signature: "mintCollectibles(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x9f08b319": {
    name: "approveSwap",
    signature: "approveSwap(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xf868e766": {
    name: "userDeposit",
    signature: "userDeposit(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x061c82d0": {
    name: "setTaxFeePercent",
    signature: "setTaxFeePercent(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x9342c8f4": {
    name: "withdrawRewards",
    signature: "withdrawRewards(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x6930fd2a": {
    name: "claimAll",
    signature: "claimAll(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xe71bdf41": {
    name: "addDelegate",
    signature: "addDelegate(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x74de4ec4": {
    name: "addReward",
    signature: "addReward(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x379e2919": {
    name: "setDevFeePercent",
    signature: "setDevFeePercent(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xc810a539": {
    name: "recordUTXOSweepMinerFee",
    signature: "recordUTXOSweepMinerFee(uint256,bytes32)",
    input_types: ["uint256", "bytes32"],
    output_aliases: ["amount", "b32"],
  },
  "0x05fe138b": {
    name: "removeLiquidity",
    signature: "removeLiquidity(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0x01e4f53a": {
    name: "relayTokens",
    signature: "relayTokens(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xab3b87fe": {
    name: "setOwner",
    signature: "setOwner(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xad68ebf7": {
    name: "migrate",
    signature: "migrate(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x90b98a11": {
    name: "sendCoin",
    signature: "sendCoin(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xa543ccea": {
    name: "setWithdrawalAddress",
    signature: "setWithdrawalAddress(address,address,bool)",
    input_types: ["address", "address", "bool"],
    output_aliases: ["to_a", "to_b", "bool_c"],
  },
  "0xba214c3c": {
    name: "submitPrices",
    signature: "submitPrices(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0x0c51b88f": {
    name: "stake",
    signature: "stake(address,uint256,uint256)",
    input_types: ["address", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "amount_c"],
  },
  "0x33b61727": {
    name: "mintVariant",
    signature: "mintVariant(uint32,uint8,uint8,uint8,uint8)",
    input_types: ["uint32", "uint8", "uint8", "uint8", "uint8"],
    output_aliases: ["num_a", "num_b", "num_c", "num_d", "num_e"],
  },
  "0xbeb9addf": {
    name: "sendEth",
    signature: "sendEth(address,uint256,bytes32,bytes32)",
    input_types: ["address", "uint256", "bytes32", "bytes32"],
    output_aliases: ["to_a", "amount_b", "b32_c", "b32_d"],
  },
  "0x605629d6": {
    name: "transferWithPermit",
    signature:
      "transferWithPermit(address,address,uint256,uint256,uint8,bytes32,bytes32)",
    input_types: [
      "address",
      "address",
      "uint256",
      "uint256",
      "uint8",
      "bytes32",
      "bytes32",
    ],
    output_aliases: [
      "to_a",
      "to_b",
      "amount_c",
      "amount_d",
      "num_e",
      "b32_f",
      "b32_g",
    ],
  },
  "0xe3ee160e": {
    name: "transferWithAuthorization",
    signature:
      "transferWithAuthorization(address,address,uint256,uint256,uint256,bytes32,uint8,bytes32,bytes32)",
    input_types: [
      "address",
      "address",
      "uint256",
      "uint256",
      "uint256",
      "bytes32",
      "uint8",
      "bytes32",
      "bytes32",
    ],
    output_aliases: [
      "to_a",
      "to_b",
      "amount_c",
      "amount_d",
      "amount_e",
      "b32_f",
      "num_g",
      "b32_h",
      "b32_i",
    ],
  },
  // --------------------------------------
  "0x8f0d94b2": {
    name: "editSaleRestrictions",
    signature: "editSaleRestrictions(uint8,uint8,uint8)",
    input_types: ["uint8", "uint8", "uint8"],
    output_aliases: ["_maxPerTx", "_maxTxEarly", "_maxTxPublic"],
  },
  "0x9173b139": {
    name: "mapToken",
    signature: "mapToken(address,address,bytes32)",
    input_types: ["address", "address", "bytes32"],
    output_aliases: ["rootToken", "childToken", "tokenType"],
  },
  "0xd233a3c7": {
    name: "remapToken",
    signature: "remapToken(address,address,bytes32)",
    input_types: ["address", "address", "bytes32"],
    output_aliases: ["rootToken", "childToken", "tokenType"],
  },
  "0x0c3894bb": {
    name: "cleanMapToken",
    signature: "cleanMapToken(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["rootToken", "childToken"],
  },

  "0x630fcbfb": {
    name: "initializeEIP712",
    signature: "initializeEIP712()",
    input_types: [],
    output_aliases: [],
  },
  "0xb4b4f63e": {
    name: "setupContractId",
    signature: "setupContractId()",
    input_types: [],
    output_aliases: [],
  },
  // --------------------------------------
  "0x906e9dd0": {
    name: "setMarketingAddress",
    signature: "setMarketingAddress(address)",
    input_types: ["address"],
    output_aliases: ["marketing"],
  },
  "0xc74cac20": {
    name: "initiateRewardClaim",
    signature: "initiateRewardClaim()",
    input_types: [],
    output_aliases: [],
  },
  "0xd54f9d09": {
    name: "lockShardsAndClaim",
    signature: "lockShardsAndClaim()",
    input_types: [],
    output_aliases: [],
  },
  "0x2bfea0e4": {
    name: "unpauseTransfer",
    signature: "unpauseTransfer()",
    input_types: [],
    output_aliases: [],
  },
  "0xec715a31": {
    name: "releaseToken",
    signature: "releaseToken()",
    input_types: [],
    output_aliases: [],
  },
  "0xf5209922": {
    name: "spreadDiseases",
    signature: "spreadDiseases(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x150bde03": {
    name: "purchaseNFT",
    signature: "purchaseNFT(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x60ed390a": {
    name: "releaseMIMO",
    signature: "releaseMIMO(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x29975b43": {
    name: "addWhitelistedAddress",
    signature: "addWhitelistedAddress(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x5312ea8e": {
    name: "emergencyWithdraw",
    signature: "emergencyWithdraw(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x525f8a5c": {
    name: "setSaleStartTime",
    signature: "setSaleStartTime(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xb3ab15fb": {
    name: "setOperator",
    signature: "setOperator(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x57f7bf2d": {
    name: "stake",
    signature: "stake(bool,uint256)",
    input_types: ["bool", "uint256"],
    output_aliases: ["bool", "amount"],
  },
  "0xf8e1152a": {
    name: "rolloverDeposit",
    signature: "rolloverDeposit(uint64,uint64)",
    input_types: ["uint64", "uint64"],
    output_aliases: ["num_a", "num_b"],
  },
  "0x9c5bb73a": {
    name: "submitMintRequest",
    signature: "submitMintRequest(uint168,uint32)",
    input_types: ["uint168", "uint32"],
    output_aliases: ["u168", "num"],
  },
  "0x58dd175d": {
    name: "presaleMint",
    signature: "presaleMint(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x05b7cdd3": {
    name: "makeOffer",
    signature: "makeOffer(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x9cb4a9f3": {
    name: "transit",
    signature: "transit(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xb9b8c246": {
    name: "invest",
    signature: "invest(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x9e281a98": {
    name: "withdrawToken",
    signature: "withdrawToken(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xbb08187f": {
    name: "setBirth",
    signature: "setBirth(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0x2fa6cbe9": {
    name: "setImplementation",
    signature: "setImplementation(address,address,address)",
    input_types: ["address", "address", "address"],
    output_aliases: ["to_a", "to_b", "to_c"],
  },
  "0x49561dc4": {
    name: "withdrawReward",
    signature: "withdrawReward(uint256,address,address)",
    input_types: ["uint256", "address", "address"],
    output_aliases: ["amount_a", "to_b", "to_c"],
  },
  "0x21a6a29c": {
    name: "unstakeExactStake",
    signature: "unstakeExactStake(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0xa5f2a152": {
    name: "transferTo",
    signature: "transferTo(address,address,uint256)",
    input_types: ["address", "address", "uint256"],
    output_aliases: ["to_a", "to_b", "amount_c"],
  },
  "0xdd9b86c1": {
    name: "depositToken",
    signature: "depositToken(address,uint256,string,string)",
    input_types: ["address", "uint256", "string", "string"],
    output_aliases: ["to_a", "amount_b", "string_c", "string_d"],
  },
  "0x76e3cabc": {
    name: "hodl",
    signature: "hodl(uint24,uint96,uint16,address,address)",
    input_types: ["uint24", "uint96", "uint16", "address", "address"],
    output_aliases: ["undefined_a", "num_b", "num_c", "to_d", "to_e"],
  },
  "0xfa6b3b53": {
    name: "claim",
    signature: "claim(uint256,uint256,uint8,bytes32,bytes32)",
    input_types: ["uint256", "uint256", "uint8", "bytes32", "bytes32"],
    output_aliases: ["amount_a", "amount_b", "num_c", "b32_d", "b32_e"],
  },
  "0x0000004d": {
    name: "swap26309536",
    signature: "swap26309536(address,address,uint256,uint256,uint256)",
    input_types: ["address", "address", "uint256", "uint256", "uint256"],
    output_aliases: ["to_a", "to_b", "amount_c", "amount_d", "amount_e"],
  },
  "0xf12d394f": {
    name: "execute",
    signature: "execute(uint8[],bytes32[],bytes32[],address,uint256,bytes)",
    input_types: [
      "uint8[]",
      "bytes32[]",
      "bytes32[]",
      "address",
      "uint256",
      "bytes",
    ],
    output_aliases: [
      "nums_a",
      "b32s_b",
      "b32s_c",
      "to_d",
      "amount_e",
      "bytes_f",
    ],
  },
  // --------------------------------------

  "0x702f7da0": {
    name: "swapBnbForTokens",
    signature: "swapBnbForTokens()",
    input_types: [],
    output_aliases: [],
  },
  "0xc2e65e42": {
    name: "withdrawBalanceOwner",
    signature: "withdrawBalanceOwner()",
    input_types: [],
    output_aliases: [],
  },
  "0x12b48932": {
    name: "emitShare",
    signature: "emitShare()",
    input_types: [],
    output_aliases: [],
  },
  "0x3be52fc4": {
    name: "notifyRewards",
    signature: "notifyRewards()",
    input_types: [],
    output_aliases: [],
  },
  "0x24349671": {
    name: "withdrawShare",
    signature: "withdrawShare()",
    input_types: [],
    output_aliases: [],
  },
  "0x3c6ffd99": {
    name: "notifyProfitSharing",
    signature: "notifyProfitSharing()",
    input_types: [],
    output_aliases: [],
  },
  "0xd2d1fb25": {
    name: "claimAllocation",
    signature: "claimAllocation()",
    input_types: [],
    output_aliases: [],
  },
  "0xd47eaa37": {
    name: "withdrawAllBase",
    signature: "withdrawAllBase()",
    input_types: [],
    output_aliases: [],
  },
  "0xb33712c5": {
    name: "unpauseContract",
    signature: "unpauseContract()",
    input_types: [],
    output_aliases: [],
  },
  "0x5fd8c710": {
    name: "withdrawBalance",
    signature: "withdrawBalance()",
    input_types: [],
    output_aliases: [],
  },
  "0x5674a3ed": {
    name: "runLottery",
    signature: "runLottery()",
    input_types: [],
    output_aliases: [],
  },
  "0x1aa3a008": {
    name: "register",
    signature: "register()",
    input_types: [],
    output_aliases: [],
  },
  "0xe4eae6b7": {
    name: "deposit",
    signature: "deposit(uint96)",
    input_types: ["uint96"],
    output_aliases: ["num"],
  },
  "0x45576f94": {
    name: "createToken",
    signature: "createToken(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x83bf4609": {
    name: "createTemplate",
    signature: "createTemplate(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0xbc431b0d": {
    name: "withdrawPpblz",
    signature: "withdrawPpblz(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x256d52f6": {
    name: "mintTicket",
    signature: "mintTicket(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x6abd26a7": {
    name: "buyforstakingwithexactEHTforToken",
    signature: "buyforstakingwithexactEHTforToken(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xf1cccf56": {
    name: "buyMany",
    signature: "buyMany(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x81a36eec": {
    name: "updateSubscription",
    signature: "updateSubscription(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x2537d5fd": {
    name: "requestDelegatedStakeWithdrawal",
    signature: "requestDelegatedStakeWithdrawal(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x02b43373": {
    name: "resultReserveAuction",
    signature: "resultReserveAuction(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x7012663e": {
    name: "placeBidOnReserveAuction",
    signature: "placeBidOnReserveAuction(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xa4146733": {
    name: "mintFree",
    signature: "mintFree(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x131f4104": {
    name: "preSale",
    signature: "preSale(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xc49fc085": {
    name: "mintWithOldKnc",
    signature: "mintWithOldKnc(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x64ab8675": {
    name: "calculateRewards",
    signature: "calculateRewards(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x36b8b425": {
    name: "recomputeOnAuctionSystemCoinLimit",
    signature: "recomputeOnAuctionSystemCoinLimit(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xa24a8d0f": {
    name: "_setCharityFee",
    signature: "_setCharityFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x018f43d6": {
    name: "_setLpFee",
    signature: "_setLpFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xfac2b9ba": {
    name: "updateStartBlock",
    signature: "updateStartBlock(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x33a100ca": {
    name: "setStrategy",
    signature: "setStrategy(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x3c6b16ab": {
    name: "notifyRewardAmount",
    signature: "notifyRewardAmount(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x819b25ba": {
    name: "reserve",
    signature: "reserve(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x8588b2c5": {
    name: "adopt",
    signature: "adopt(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x1ac3ddeb": {
    name: "withdrawFee",
    signature: "withdrawFee(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xf6eeff1e": {
    name: "punkNoLongerForSale",
    signature: "punkNoLongerForSale(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xbd94b005": {
    name: "cancelSale",
    signature: "cancelSale(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x2199d5cd": {
    name: "registerUser",
    signature: "registerUser(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xdf8de3e7": {
    name: "claimTokens",
    signature: "claimTokens(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x8b147245": {
    name: "update",
    signature: "update(bytes32)",
    input_types: ["bytes32"],
    output_aliases: ["b32"],
  },
  "0xbd66528a": {
    name: "claim",
    signature: "claim(bytes32)",
    input_types: ["bytes32"],
    output_aliases: ["b32"],
  },
  "0xf98a4eca": {
    name: "executeVote",
    signature: "executeVote(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x5e1045ec": {
    name: "addWhiteList",
    signature: "addWhiteList(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0xb41c9eda": {
    name: "setBlacklistWallet",
    signature: "setBlacklistWallet(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0xf26748e2": {
    name: "mint",
    signature: "mint(uint8,uint256)",
    input_types: ["uint8", "uint256"],
    output_aliases: ["num", "amount"],
  },
  "0x30b39a62": {
    name: "withdraw",
    signature: "withdraw(string,uint256)",
    input_types: ["string", "uint256"],
    output_aliases: ["string", "amount"],
  },
  "0x8e27d719": {
    name: "deposit",
    signature: "deposit(string,uint256)",
    input_types: ["string", "uint256"],
    output_aliases: ["string", "amount"],
  },
  "0x9f93f779": {
    name: "mintPublic",
    signature: "mintPublic(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x408cbf94": {
    name: "mintOwner",
    signature: "mintOwner(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xfc41aa09": {
    name: "addRewards",
    signature: "addRewards(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x17b18c89": {
    name: "stakeLocked",
    signature: "stakeLocked(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xf940e385": {
    name: "withdraw",
    signature: "withdraw(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0xc4a0db96": {
    name: "redeem",
    signature: "redeem(uint256,uint256[])",
    input_types: ["uint256", "uint256[]"],
    output_aliases: ["amount", "amounts"],
  },
  "0x1e79a85b": {
    name: "transferPoint",
    signature: "transferPoint(uint32,address,bool)",
    input_types: ["uint32", "address", "bool"],
    output_aliases: ["num", "to", "bool"],
  },
  "0x68573107": {
    name: "batchMint",
    signature: "batchMint(address[],uint256[])",
    input_types: ["address[]", "uint256[]"],
    output_aliases: ["addresses", "amounts"],
  },
  "0x1eaaa045": {
    name: "add",
    signature: "add(uint256,address,bool)",
    input_types: ["uint256", "address", "bool"],
    output_aliases: ["amount", "to", "bool"],
  },
  "0x64482f79": {
    name: "set",
    signature: "set(uint256,uint256,bool)",
    input_types: ["uint256", "uint256", "bool"],
    output_aliases: ["amount_a", "amount_b", "bool_c"],
  },
  "0xc4ccdeea": {
    name: "removeLiquidity",
    signature: "removeLiquidity(uint256,address,uint256)",
    input_types: ["uint256", "address", "uint256"],
    output_aliases: ["amount_a", "to_b", "amount_c"],
  },
  "0xd511a6db": {
    name: "upgrade",
    signature: "upgrade(uint256,address,uint256)",
    input_types: ["uint256", "address", "uint256"],
    output_aliases: ["amount_a", "to_b", "amount_c"],
  },
  "0xe9dae5ed": {
    name: "setTaxes",
    signature: "setTaxes(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0x4abc2202": {
    name: "addItemToMarket",
    signature: "addItemToMarket(uint256,address,uint256)",
    input_types: ["uint256", "address", "uint256"],
    output_aliases: ["amount_a", "to_b", "amount_c"],
  },
  "0xe1f811ac": {
    name: "sponsorProposal",
    signature: "sponsorProposal(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0xbb7e70ef": {
    name: "build",
    signature: "build(address,uint256,address)",
    input_types: ["address", "uint256", "address"],
    output_aliases: ["to_a", "amount_b", "to_c"],
  },
  "0x5ceae9c4": {
    name: "repay",
    signature: "repay(address,uint256,address)",
    input_types: ["address", "uint256", "address"],
    output_aliases: ["to_a", "amount_b", "to_c"],
  },
  "0x9fe3220c": {
    name: "strikeLore",
    signature: "strikeLore(address,uint256,uint256,bool)",
    input_types: ["address", "uint256", "uint256", "bool"],
    output_aliases: ["to_a", "amount_b", "amount_c", "bool_d"],
  },
  "0xf9f585dd": {
    name: "buyShares",
    signature: "buyShares(address[],uint256[],uint256[])",
    input_types: ["address[]", "uint256[]", "uint256[]"],
    output_aliases: ["addresses_a", "amounts_b", "amounts_c"],
  },
  "0x5cffe9de": {
    name: "flashLoan",
    signature: "flashLoan(address,address,uint256,bytes)",
    input_types: ["address", "address", "uint256", "bytes"],
    output_aliases: ["to_a", "to_b", "amount_c", "bytes_d"],
  },
  "0x7e3ca9ea": {
    name: "mintMany",
    signature: "mintMany(address,uint256,uint256,uint256)",
    input_types: ["address", "uint256", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "amount_c", "amount_d"],
  },
  "0x94e93d8d": {
    name: "clipperSwap",
    signature: "clipperSwap(address,uint256,address,address,uint256)",
    input_types: ["address", "uint256", "address", "address", "uint256"],
    output_aliases: ["to_a", "amount_b", "to_c", "to_d", "amount_e"],
  },
  "0x70142269": {
    name: "claim",
    signature: "claim(address,uint256,uint256,uint256,uint8,bytes32,bytes32)",
    input_types: [
      "address",
      "uint256",
      "uint256",
      "uint256",
      "uint8",
      "bytes32",
      "bytes32",
    ],
    output_aliases: [
      "to_a",
      "amount_b",
      "amount_c",
      "amount_d",
      "num_e",
      "b32_f",
      "b32_g",
    ],
  },
  "0xd8f6eb5b": {
    name: "ZapOut",
    signature:
      "ZapOut(address,uint256,address,address,uint256,address,bytes,address,bool)",
    input_types: [
      "address",
      "uint256",
      "address",
      "address",
      "uint256",
      "address",
      "bytes",
      "address",
      "bool",
    ],
    output_aliases: [
      "to_a",
      "amount_b",
      "to_c",
      "to_d",
      "amount_e",
      "to_f",
      "bytes_g",
      "to_h",
      "bool_i",
    ],
  },

  // --------------------------------------
  "0xf851a440": {
    name: "admin",
    signature: "admin()",
    input_types: [],
    output_aliases: [],
  },
  "0x8f283970": {
    name: "changeAdmin",
    signature: "changeAdmin(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x5c60da1b": {
    name: "implementation",
    signature: "implementation()",
    input_types: [],
    output_aliases: [],
  },
  "0x3659cfe6": {
    name: "upgradeTo",
    signature: "upgradeTo(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x4f1ef286": {
    name: "upgradeToAndCall",
    signature: "upgradeToAndCall(address,bytes)",
    input_types: ["address", "bytes"],
    output_aliases: ["to", "bytes"],
  },
  "0x9a114cb2": {
    name: "claimTokens",
    signature: "claimTokens(uint256,bytes32[])",
    input_types: ["uint256", "bytes32[]"],
    output_aliases: ["amount", "b32s"],
  },
  "0xb858183f": {
    name: "exactInput",
    signature: "exactInput((bytes,address,uint256,uint256))",
    input_types: ["(bytes,address,uint256,uint256)"],
    output_aliases: ["envelope"],
  },
  "0x04e45aaf": {
    name: "exactInputSingle",
    signature:
      "exactInputSingle((address,address,uint24,address,uint256,uint256,uint160))",
    input_types: ["(address,address,uint24,address,uint256,uint256,uint160)"],
    output_aliases: ["envelope"],
  },
  "0x09b81346": {
    name: "exactOutput",
    signature: "exactOutput((bytes,address,uint256,uint256))",
    input_types: ["(bytes,address,uint256,uint256)"],
    output_aliases: ["envelope"],
  },
  "0x5023b4df": {
    name: "exactOutputSingle",
    signature:
      "exactOutputSingle((address,address,uint24,address,uint256,uint256,uint160))",
    input_types: ["(address,address,uint24,address,uint256,uint256,uint160)"],
    output_aliases: ["envelope"],
  },
  "0xf100b205": {
    name: "increaseLiquidity",
    signature: "increaseLiquidity((address,address,uint256,uint256,uint256))",
    input_types: ["(address,address,uint256,uint256,uint256)"],
    output_aliases: ["envelope"],
  },
  "0x11ed56c9": {
    name: "mint",
    signature:
      "mint((address,address,uint24,int24,int24,uint256,uint256,address))",
    input_types: [
      "(address,address,uint24,int24,int24,uint256,uint256,address)",
    ],
    output_aliases: ["envelope"],
  },
  // --------------------------------------
  "0xdee00f35": {
    name: "getApprovalType",
    signature: "getApprovalType(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xfa461e33": {
    name: "uniswapV3SwapCallback",
    signature: "uniswapV3SwapCallback(int256,int256,bytes)",
    input_types: ["int256", "int256", "bytes"],
    output_aliases: ["int_a", "int_b", "bytes_c"],
  },
  "0x571ac8b0": {
    name: "approveMax",
    signature: "approveMax(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xcab372ce": {
    name: "approveMaxMinusOne",
    signature: "approveMaxMinusOne(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x639d71a9": {
    name: "approveZeroThenMax",
    signature: "approveZeroThenMax(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xab3fdd50": {
    name: "approveZeroThenMaxMinusOne",
    signature: "approveZeroThenMaxMinusOne(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xb3a2af13": {
    name: "callPositionManager",
    signature: "callPositionManager(bytes)",
    input_types: ["bytes"],
    output_aliases: ["bytes"],
  },
  "0x1f0464d1": {
    name: "multicall",
    signature: "multicall(bytes32,bytes[])",
    input_types: ["bytes32", "bytes[]"],
    output_aliases: ["b32", "data"],
  },
  "0x5ae401dc": {
    name: "multicall",
    signature: "multicall(uint256,bytes[])",
    input_types: ["uint256", "bytes[]"],
    output_aliases: ["amount", "data"],
  },
  "0xac9650d8": {
    name: "multicall",
    signature: "multicall(bytes[])",
    input_types: ["bytes[]"],
    output_aliases: ["data"],
  },
  "0xf2d5d56b": {
    name: "pull",
    signature: "pull(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x12210e8a": {
    name: "refundETH",
    signature: "refundETH()",
    input_types: [],
    output_aliases: [],
  },
  "0xf3995c67": {
    name: "selfPermit",
    signature: "selfPermit(address,uint256,uint256,uint8,bytes32,bytes32)",
    input_types: [
      "address",
      "uint256",
      "uint256",
      "uint8",
      "bytes32",
      "bytes32",
    ],
    output_aliases: ["to_a", "amount_b", "amount_c", "num_d", "b32_e", "b32_f"],
  },
  "0x4659a494": {
    name: "selfPermitAllowed",
    signature:
      "selfPermitAllowed(address,uint256,uint256,uint8,bytes32,bytes32)",
    input_types: [
      "address",
      "uint256",
      "uint256",
      "uint8",
      "bytes32",
      "bytes32",
    ],
    output_aliases: ["to_a", "amount_b", "amount_c", "num_d", "b32_e", "b32_f"],
  },
  "0xa4a78f0c": {
    name: "selfPermitAllowedIfNecessary",
    signature:
      "selfPermitAllowedIfNecessary(address,uint256,uint256,uint8,bytes32,bytes32)",
    input_types: [
      "address",
      "uint256",
      "uint256",
      "uint8",
      "bytes32",
      "bytes32",
    ],
    output_aliases: ["to_a", "amount_b", "amount_c", "num_d", "b32_e", "b32_f"],
  },
  "0xc2e3140a": {
    name: "selfPermitIfNecessary",
    signature:
      "selfPermitIfNecessary(address,uint256,uint256,uint8,bytes32,bytes32)",
    input_types: [
      "address",
      "uint256",
      "uint256",
      "uint8",
      "bytes32",
      "bytes32",
    ],
    output_aliases: ["to_a", "amount_b", "amount_c", "num_d", "b32_e", "b32_f"],
  },
  "0x472b43f3": {
    name: "swapExactTokensForTokens",
    signature: "swapExactTokensForTokens(uint256,uint256,address[],address)",
    input_types: ["uint256", "uint256", "address[]", "address"],
    output_aliases: ["amount_a", "amount_b", "addresses_c", "to_d"],
  },
  "0x42712a67": {
    name: "swapTokensForExactTokens",
    signature: "swapTokensForExactTokens(uint256,uint256,address[],address)",
    input_types: ["uint256", "uint256", "address[]", "address"],
    output_aliases: ["amount_a", "amount_b", "addresses_c", "to_d"],
  },
  "0xdf2ab5bb": {
    name: "sweepToken",
    signature: "sweepToken(address,uint256,address)",
    input_types: ["address", "uint256", "address"],
    output_aliases: ["to_a", "amount_b", "to_c"],
  },
  "0xe90a182f": {
    name: "sweepToken",
    signature: "sweepToken(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x3068c554": {
    name: "sweepTokenWithFee",
    signature: "sweepTokenWithFee(address,uint256,uint256,address)",
    input_types: ["address", "uint256", "uint256", "address"],
    output_aliases: ["to_a", "amount_b", "amount_c", "to_d"],
  },
  "0xe0e189a0": {
    name: "sweepTokenWithFee",
    signature: "sweepTokenWithFee(address,uint256,address,uint256,address)",
    input_types: ["address", "uint256", "address", "uint256", "address"],
    output_aliases: ["to_a", "amount_b", "to_c", "amount_d", "to_e"],
  },
  "0x49404b7c": {
    name: "unwrapWETH9",
    signature: "unwrapWETH9(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0x49616997": {
    name: "unwrapWETH9",
    signature: "unwrapWETH9(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x9b2c0a37": {
    name: "unwrapWETH9WithFee",
    signature: "unwrapWETH9WithFee(uint256,address,uint256,address)",
    input_types: ["uint256", "address", "uint256", "address"],
    output_aliases: ["amount_a", "to_b", "amount_c", "to_d"],
  },
  "0xd4ef38de": {
    name: "unwrapWETH9WithFee",
    signature: "unwrapWETH9WithFee(uint256,uint256,address)",
    input_types: ["uint256", "uint256", "address"],
    output_aliases: ["amount_a", "amount_b", "to_c"],
  },
  "0x1c58db4f": {
    name: "wrapETH",
    signature: "wrapETH(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  // --------------------------------------
  "0x4401bbfd": {
    name: "reservedRedemption",
    signature: "reservedRedemption()",
    input_types: [],
    output_aliases: [],
  },
  "0x12741bd2": {
    name: "updateOsmAndEthAOracleRelayer",
    signature: "updateOsmAndEthAOracleRelayer()",
    input_types: [],
    output_aliases: [],
  },
  "0x2ae168a6": {
    name: "startDraw",
    signature: "startDraw()",
    input_types: [],
    output_aliases: [],
  },
  "0x0572b0cc": {
    name: "getRewards",
    signature: "getRewards()",
    input_types: [],
    output_aliases: [],
  },
  "0xc89e4361": {
    name: "delegate",
    signature: "delegate()",
    input_types: [],
    output_aliases: [],
  },
  "0x7362377b": {
    name: "withdrawEther",
    signature: "withdrawEther()",
    input_types: [],
    output_aliases: [],
  },
  "0x2e6e504a": {
    name: "trusteeWithdraw",
    signature: "trusteeWithdraw()",
    input_types: [],
    output_aliases: [],
  },
  "0x3c952764": {
    name: "setOnlyWhitelisted",
    signature: "setOnlyWhitelisted(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x4585e33b": {
    name: "performUpkeep",
    signature: "performUpkeep(bytes)",
    input_types: ["bytes"],
    output_aliases: ["bytes"],
  },
  "0xdea4ccf2": {
    name: "unstake",
    signature: "unstake(uint96)",
    input_types: ["uint96"],
    output_aliases: ["num"],
  },
  "0x61f129ad": {
    name: "stake",
    signature: "stake(uint96)",
    input_types: ["uint96"],
    output_aliases: ["num"],
  },
  "0x0599c284": {
    name: "setAddress",
    signature: "setAddress(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x042fe81a": {
    name: "setMaxTransferAmountRate",
    signature: "setMaxTransferAmountRate(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xa66d3da4": {
    name: "buyGaufWithLink",
    signature: "buyGaufWithLink(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xcef22444": {
    name: "setElPrice",
    signature: "setElPrice(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x31c864e8": {
    name: "safeMint",
    signature: "safeMint(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x0468b925": {
    name: "callMoonShot",
    signature: "callMoonShot(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x51eb05a6": {
    name: "updatePool",
    signature: "updatePool(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x97dc4a13": {
    name: "airdrop",
    signature: "airdrop(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x4b85fd55": {
    name: "setAutoBirthFee",
    signature: "setAutoBirthFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x0a3b0a4f": {
    name: "add",
    signature: "add(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x5f788d65": {
    name: "provideToSP",
    signature: "provideToSP(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0x050225ea": {
    name: "giveaway",
    signature: "giveaway(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xecb586a5": {
    name: "remove_liquidity",
    signature: "remove_liquidity(uint256,uint256[3])",
    input_types: ["uint256", "uint256[3]"],
    output_aliases: ["amount", "amounts"],
  },
  "0xbcc91d61": {
    name: "sendEther",
    signature: "sendEther(address[],uint256[])",
    input_types: ["address[]", "uint256[]"],
    output_aliases: ["addresses", "amounts"],
  },
  "0xdf22db88": {
    name: "burn",
    signature: "burn(uint256,bool,uint256)",
    input_types: ["uint256", "bool", "uint256"],
    output_aliases: ["amount_a", "bool_b", "amount_c"],
  },
  "0xf84af835": {
    name: "deposit",
    signature: "deposit(uint256,uint256,uint64)",
    input_types: ["uint256", "uint256", "uint64"],
    output_aliases: ["amount_a", "amount_b", "num_c"],
  },
  "0xac44ff31": {
    name: "claim",
    signature: "claim(uint256,uint256,address)",
    input_types: ["uint256", "uint256", "address"],
    output_aliases: ["amount_a", "amount_b", "to_c"],
  },
  "0xf8b6a1a4": {
    name: "limitedPrint",
    signature: "limitedPrint(address,uint256,bytes32)",
    input_types: ["address", "uint256", "bytes32"],
    output_aliases: ["to", "amount", "b32"],
  },
  "0x30e0914c": {
    name: "commit",
    signature: "commit(address,address,uint256)",
    input_types: ["address", "address", "uint256"],
    output_aliases: ["to_a", "to_b", "amount_c"],
  },
  "0x1ffbe7f9": {
    name: "sendToCosmos",
    signature: "sendToCosmos(address,bytes32,uint256)",
    input_types: ["address", "bytes32", "uint256"],
    output_aliases: ["to", "b32", "amount"],
  },
  "0xdb61c76e": {
    name: "buy",
    signature: "buy(address,uint256,address)",
    input_types: ["address", "uint256", "address"],
    output_aliases: ["to_a", "amount_b", "to_c"],
  },
  "0x6e9c931c": {
    name: "stake",
    signature: "stake(uint256,address,uint256)",
    input_types: ["uint256", "address", "uint256"],
    output_aliases: ["amount_a", "to_b", "amount_c"],
  },
  "0x095bcdb6": {
    name: "transfer",
    signature: "transfer(address,uint256,uint256)",
    input_types: ["address", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "amount_c"],
  },
  "0x10aec4a6": {
    name: "mint",
    signature: "mint(uint256,uint8,bytes32,bytes32)",
    input_types: ["uint256", "uint8", "bytes32", "bytes32"],
    output_aliases: ["amount_a", "num_b", "b32_c", "b32_d"],
  },
  "0x1cabf08f": {
    name: "send",
    signature: "send(address,uint256,uint32,bytes32)",
    input_types: ["address", "uint256", "uint32", "bytes32"],
    output_aliases: ["to", "amount", "num", "b32"],
  },
  "0xc24d5a5c": {
    name: "makeBid",
    signature: "makeBid(address,uint256,address,uint128)",
    input_types: ["address", "uint256", "address", "uint128"],
    output_aliases: ["to_a", "amount_b", "to_c", "u128_d"],
  },
  "0x241dc2df": {
    name: "anySwapOut",
    signature: "anySwapOut(address,address,uint256,uint256)",
    input_types: ["address", "address", "uint256", "uint256"],
    output_aliases: ["to_a", "to_b", "amount_c", "amount_d"],
  },
  "0x77f61403": {
    name: "mint",
    signature: "mint(string,address,uint256,bytes32,bytes)",
    input_types: ["string", "address", "uint256", "bytes32", "bytes"],
    output_aliases: ["string", "to", "amount", "b32", "bytes"],
  },
  "0xfbc5cfc3": {
    name: "collect",
    signature: "collect(address,uint256,uint256,bytes32,bytes32,uint8)",
    input_types: [
      "address",
      "uint256",
      "uint256",
      "bytes32",
      "bytes32",
      "uint8",
    ],
    output_aliases: ["to_a", "amount_b", "amount_c", "b32_d", "b32_e", "num_f"],
  },
  "0x831347e3": {
    name: "depositWithPermit",
    signature:
      "depositWithPermit(uint256,uint256,uint256,uint256,address,uint256,uint256,uint256,uint8,bytes32,bytes32)",
    input_types: [
      "uint256",
      "uint256",
      "uint256",
      "uint256",
      "address",
      "uint256",
      "uint256",
      "uint256",
      "uint8",
      "bytes32",
      "bytes32",
    ],
    output_aliases: [
      "amount_a",
      "amount_b",
      "amount_c",
      "amount_d",
      "to_e",
      "amount_f",
      "amount_g",
      "amount_h",
      "num_i",
      "b32_j",
      "b32_k",
    ],
  },
  // --------------------------------------
  "0xa457c2d7": {
    name: "decreaseAllowance",
    signature: "decreaseAllowance(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["spender", "amount"],
  },
  // --------------------------------------
  "0xe653da08": {
    name: "setSellFee",
    signature: "setSellFee(uint256,uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256", "uint256"],
    output_aliases: [
      "marketingFee",
      "liquidityFee",
      "reflectFee",
      "buybackFee",
    ],
  },
  "0x1a48c5d2": {
    name: "redeemJuniorUnderlying",
    signature: "redeemJuniorUnderlying()",
    input_types: [],
    output_aliases: [],
  },
  "0xc4d3f9d9": {
    name: "payMiner",
    signature: "payMiner()",
    input_types: [],
    output_aliases: [],
  },
  "0xe044205e": {
    name: "transmuteClaimAndWithdraw",
    signature: "transmuteClaimAndWithdraw()",
    input_types: [],
    output_aliases: [],
  },
  "0x6fc3eaec": {
    name: "manualsend",
    signature: "manualsend()",
    input_types: [],
    output_aliases: [],
  },
  "0xf011a7af": {
    name: "claimProfit",
    signature: "claimProfit()",
    input_types: [],
    output_aliases: [],
  },
  "0x63bd1d4a": {
    name: "payout",
    signature: "payout()",
    input_types: [],
    output_aliases: [],
  },
  "0x14107f3c": {
    name: "buy",
    signature: "buy(uint8)",
    input_types: ["uint8"],
    output_aliases: ["num"],
  },
  "0x9bf6fa57": {
    name: "heartbeat",
    signature: "heartbeat(uint64)",
    input_types: ["uint64"],
    output_aliases: ["num"],
  },
  "0x7e62eab8": {
    name: "withdraw",
    signature: "withdraw(int256)",
    input_types: ["int256"],
    output_aliases: ["undefined"],
  },
  "0xbec3aa75": {
    name: "submitStake",
    signature: "submitStake(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x135ccc47": {
    name: "strategyHarvest",
    signature: "strategyHarvest(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x458a1d6e": {
    name: "unstake",
    signature: "unstake(uint128)",
    input_types: ["uint128"],
    output_aliases: ["u128"],
  },
  "0x99c2def4": {
    name: "endStake",
    signature: "endStake(bytes16)",
    input_types: ["bytes16"],
    output_aliases: ["undefined"],
  },
  "0xfb307b47": {
    name: "setStonePrice",
    signature: "setStonePrice(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xc44bef75": {
    name: "setStartTimestamp",
    signature: "setStartTimestamp(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x32c18c1e": {
    name: "claimPresent",
    signature: "claimPresent(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x54830771": {
    name: "setStarTime",
    signature: "setStarTime(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x20e409b4": {
    name: "mintBatch",
    signature: "mintBatch(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xc8c18842": {
    name: "hatch",
    signature: "hatch(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xeaf3b7c2": {
    name: "getUnlocked",
    signature: "getUnlocked(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x9f06aa08": {
    name: "collateralize",
    signature: "collateralize(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x271f88b4": {
    name: "setAmount",
    signature: "setAmount(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xccb98ffc": {
    name: "setEndTime",
    signature: "setEndTime(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x45977d03": {
    name: "upgrade",
    signature: "upgrade(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xee8c4bbf": {
    name: "getBounty",
    signature: "getBounty(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xc4c1c94f": {
    name: "add",
    signature: "add(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0x61b69abd": {
    name: "createProxy",
    signature: "createProxy(address,bytes)",
    input_types: ["address", "bytes"],
    output_aliases: ["to", "bytes"],
  },
  "0xc48fa115": {
    name: "attestToken",
    signature: "attestToken(address,uint32)",
    input_types: ["address", "uint32"],
    output_aliases: ["to", "num"],
  },
  "0x60b02f70": {
    name: "reserveMint",
    signature: "reserveMint(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0xb390c0ab": {
    name: "burn",
    signature: "burn(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x79c65068": {
    name: "mintToken",
    signature: "mintToken(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xfff3a01b": {
    name: "transfer",
    signature: "transfer(address,string,uint256)",
    input_types: ["address", "string", "uint256"],
    output_aliases: ["to", "string", "amount"],
  },
  "0x83e67dc6": {
    name: "mintTrailer",
    signature: "mintTrailer(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0x9b5e1687": {
    name: "breed",
    signature: "breed(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0x2b83cccd": {
    name: "redeem",
    signature: "redeem(address,uint256,uint256)",
    input_types: ["address", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "amount_c"],
  },
  "0x55776b77": {
    name: "addLiquidity",
    signature: "addLiquidity(address,uint256,uint256)",
    input_types: ["address", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "amount_c"],
  },
  "0x02acc94b": {
    name: "mint",
    signature: "mint(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0xb064478f": {
    name: "transferMultiToken",
    signature: "transferMultiToken(address,address[],uint256[])",
    input_types: ["address", "address[]", "uint256[]"],
    output_aliases: ["to", "addresses", "amounts"],
  },
  "0x00c24087": {
    name: "removeLiquidity",
    signature: "removeLiquidity(address,uint256,address,uint256,uint256)",
    input_types: ["address", "uint256", "address", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "to_c", "amount_d", "amount_e"],
  },
  "0x92a34f72": {
    name: "mintAndStake",
    signature: "mintAndStake(address,address,address,uint256,uint256)",
    input_types: ["address", "address", "address", "uint256", "uint256"],
    output_aliases: ["to_a", "to_b", "to_c", "amount_d", "amount_e"],
  },
  "0xfbce0393": {
    name: "create",
    signature: "create(string,string,address[],uint256[],uint256,address)",
    input_types: [
      "string",
      "string",
      "address[]",
      "uint256[]",
      "uint256",
      "address",
    ],
    output_aliases: [
      "string_a",
      "string_b",
      "addresses_c",
      "amounts_d",
      "amount_e",
      "to_f",
    ],
  },
  "0x4515641a": {
    name: "executeAllowanceTransfer",
    signature:
      "executeAllowanceTransfer(address,address,address,uint96,address,uint96,address,bytes)",
    input_types: [
      "address",
      "address",
      "address",
      "uint96",
      "address",
      "uint96",
      "address",
      "bytes",
    ],
    output_aliases: [
      "to_a",
      "to_b",
      "to_c",
      "num_d",
      "to_e",
      "num_f",
      "to_g",
      "bytes_h",
    ],
  },
  "0xfe9ff09c": {
    name: "moveWithSignature",
    signature:
      "moveWithSignature(address,address,uint256,bytes32,uint256,uint8,bytes32,bytes32)",
    input_types: [
      "address",
      "address",
      "uint256",
      "bytes32",
      "uint256",
      "uint8",
      "bytes32",
      "bytes32",
    ],
    output_aliases: [
      "to_a",
      "to_b",
      "amount_c",
      "b32_d",
      "amount_e",
      "num_f",
      "b32_g",
      "b32_h",
    ],
  },
  // --------------------------------------
  "0xb515566a": {
    name: "setBots",
    signature: "setBots(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0xaacebbe3": {
    name: "updateMarketingWallet",
    signature: "updateMarketingWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x1816467f": {
    name: "updateDevWallet",
    signature: "updateDevWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x2d5a5d34": {
    name: "blacklistAccount",
    signature: "blacklistAccount(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["blacklisted", "bool"],
  },
  "0x183e7ce4": {
    name: "receiveNewTokens",
    signature: "receiveNewTokens()",
    input_types: [],
    output_aliases: [],
  },
  "0x3e07ce5b": {
    name: "removeBuyLimit",
    signature: "removeBuyLimit()",
    input_types: [],
    output_aliases: [],
  },
  "0xf247e3de": {
    name: "getIncome",
    signature: "getIncome()",
    input_types: [],
    output_aliases: [],
  },
  "0x5b70ea9f": {
    name: "freeMint",
    signature: "freeMint()",
    input_types: [],
    output_aliases: [],
  },
  "0x4a58db19": {
    name: "addDeposit",
    signature: "addDeposit()",
    input_types: [],
    output_aliases: [],
  },
  "0xe341afdf": {
    name: "mainSale",
    signature: "mainSale(uint8)",
    input_types: ["uint8"],
    output_aliases: ["num"],
  },
  "0x33eba49a": {
    name: "mintToken",
    signature: "mintToken(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x2f97a10e": {
    name: "bridgeSend",
    signature: "bridgeSend(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x9c6e9b8d": {
    name: "mintViaBurn",
    signature: "mintViaBurn(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xf52cbf0e": {
    name: "wrapAndRelayTokens",
    signature: "wrapAndRelayTokens(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x55a63bf4": {
    name: "setWhitelistSupply",
    signature: "setWhitelistSupply(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xc18cfe86": {
    name: "sweepAll",
    signature: "sweepAll(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x023245d7": {
    name: "withdrawNFT",
    signature: "withdrawNFT(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x9155f619": {
    name: "openBooster",
    signature: "openBooster(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x523a3f08": {
    name: "withdrawReward",
    signature: "withdrawReward(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xbb29998e": {
    name: "test",
    signature: "test(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xdded49cb": {
    name: "setadmin",
    signature: "setadmin(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x305ec69e": {
    name: "finish",
    signature: "finish(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x2d296bf1": {
    name: "buyToken",
    signature: "buyToken(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x144fa6d7": {
    name: "setToken",
    signature: "setToken(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x162094c4": {
    name: "setTokenURI",
    signature: "setTokenURI(uint256,string)",
    input_types: ["uint256", "string"],
    output_aliases: ["amount", "string"],
  },
  "0xb8875343": {
    name: "setActiveDateTime",
    signature: "setActiveDateTime(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xb9288e46": {
    name: "bridgeAndCraft",
    signature: "bridgeAndCraft(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x4352fa9f": {
    name: "setPrices",
    signature: "setPrices(address[],uint256[])",
    input_types: ["address[]", "uint256[]"],
    output_aliases: ["addresses", "amounts"],
  },
  "0x9dcaa6c9": {
    name: "setDelegationParameters",
    signature: "setDelegationParameters(uint32,uint32,uint32)",
    input_types: ["uint32", "uint32", "uint32"],
    output_aliases: ["num_a", "num_b", "num_c"],
  },
  "0xd514da50": {
    name: "withdrawPendingBalance",
    signature: "withdrawPendingBalance(address,address,uint128)",
    input_types: ["address", "address", "uint128"],
    output_aliases: ["to_a", "to_b", "u128"],
  },
  "0x3d3d7714": {
    name: "withdrawPayment",
    signature: "withdrawPayment(address,address,uint256)",
    input_types: ["address", "address", "uint256"],
    output_aliases: ["to_a", "to_b", "amount_c"],
  },
  "0xbf6eac2f": {
    name: "stake",
    signature: "stake(address,address,uint256)",
    input_types: ["address", "address", "uint256"],
    output_aliases: ["to_a", "to_b", "amount_c"],
  },
  "0x38111242": {
    name: "saveViaMint",
    signature:
      "saveViaMint(address,address,address,address,uint256,uint256,bool)",
    input_types: [
      "address",
      "address",
      "address",
      "address",
      "uint256",
      "uint256",
      "bool",
    ],
    output_aliases: [
      "to_a",
      "to_b",
      "to_c",
      "to_d",
      "amount_e",
      "amount_f",
      "bool_g",
    ],
  },
  "0x20b42a95": {
    name: "startWithdrawStake",
    signature: "startWithdrawStake()",
    input_types: [],
    output_aliases: [],
  },
  "0xedc9723c": {
    name: "mintFromVesting",
    signature: "mintFromVesting()",
    input_types: [],
    output_aliases: [],
  },
  "0x5cf4f865": {
    name: "withdrawMarketing",
    signature: "withdrawMarketing()",
    input_types: [],
    output_aliases: [],
  },
  "0xd2eb86ee": {
    name: "publicSaleMint",
    signature: "publicSaleMint()",
    input_types: [],
    output_aliases: [],
  },
  "0x14f710fe": {
    name: "mintNFT",
    signature: "mintNFT()",
    input_types: [],
    output_aliases: [],
  },
  "0xb9f8092b": {
    name: "rollToNextOption",
    signature: "rollToNextOption()",
    input_types: [],
    output_aliases: [],
  },
  "0x42f5d95d": {
    name: "AllUnLock",
    signature: "AllUnLock()",
    input_types: [],
    output_aliases: [],
  },
  "0xb3423eec": {
    name: "requestWithdraw",
    signature: "requestWithdraw()",
    input_types: [],
    output_aliases: [],
  },
  "0xca7ce3ec": {
    name: "setWhitelistSale",
    signature: "setWhitelistSale(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xd897833e": {
    name: "setSaleStatus",
    signature: "setSaleStatus(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xb1b39337": {
    name: "withdrawTokens",
    signature: "withdrawTokens(uint8)",
    input_types: ["uint8"],
    output_aliases: ["num"],
  },
  "0x7ba0e2e7": {
    name: "mint",
    signature: "mint(bytes)",
    input_types: ["bytes"],
    output_aliases: ["bytes"],
  },
  "0x41de0e52": {
    name: "mintNFT",
    signature: "mintNFT(uint32)",
    input_types: ["uint32"],
    output_aliases: ["num"],
  },
  "0x59f5e0ce": {
    name: "purchase",
    signature: "purchase(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x3aa18088": {
    name: "mint2",
    signature: "mint2(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x75ce258d": {
    name: "depositBeans",
    signature: "depositBeans(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xcf9d4afa": {
    name: "initContract",
    signature: "initContract(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x7ab42006": {
    name: "mintInu",
    signature: "mintInu(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x4ee408de": {
    name: "mintTiger",
    signature: "mintTiger(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x7835c635": {
    name: "preSaleMint",
    signature: "preSaleMint(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x6a775714": {
    name: "updateUserPrice",
    signature: "updateUserPrice(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x2e993611": {
    name: "settleAuction",
    signature: "settleAuction(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xbe1d24ad": {
    name: "exitPool",
    signature: "exitPool(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x60b47a50": {
    name: "mintCard",
    signature: "mintCard(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x3bd5d173": {
    name: "deliver",
    signature: "deliver(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x46951954": {
    name: "updateCode",
    signature: "updateCode(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x32fe596f": {
    name: "addVouch",
    signature: "addVouch(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xd4d9bdcd": {
    name: "approveHash",
    signature: "approveHash(bytes32)",
    input_types: ["bytes32"],
    output_aliases: ["b32"],
  },
  "0x979bc638": {
    name: "withdrawBidForPunk",
    signature: "withdrawBidForPunk(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xf9f92be4": {
    name: "blacklist",
    signature: "blacklist(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x7547c7a3": {
    name: "stakeTokens",
    signature: "stakeTokens(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xddc63262": {
    name: "harvest",
    signature: "harvest(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xb214faa5": {
    name: "deposit",
    signature: "deposit(bytes32)",
    input_types: ["bytes32"],
    output_aliases: ["b32"],
  },
  "0xfe0d94c1": {
    name: "execute",
    signature: "execute(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x524fa7b9": {
    name: "whitelistAdd",
    signature: "whitelistAdd(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xd65a06b0": {
    name: "redeem",
    signature: "redeem(uint256,bool)",
    input_types: ["uint256", "bool"],
    output_aliases: ["amount", "bool"],
  },
  "0xedab62f5": {
    name: "migrate",
    signature: "migrate(uint256,uint8)",
    input_types: ["uint256", "uint8"],
    output_aliases: ["amount", "num"],
  },
  "0xc8fd6ed0": {
    name: "unstake",
    signature: "unstake(uint256,bytes)",
    input_types: ["uint256", "bytes"],
    output_aliases: ["amount", "bytes"],
  },
  "0x85c855f3": {
    name: "setStake",
    signature: "setStake(uint96,uint128)",
    input_types: ["uint96", "uint128"],
    output_aliases: ["uint96", "uint128"],
  },
  "0x2524a496": {
    name: "mintGiveaway",
    signature: "mintGiveaway(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0x1b60efb0": {
    name: "mintGiveaway",
    signature: "mintGiveaway(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x58d3232f": {
    name: "withdrawReward",
    signature: "withdrawReward(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xd8ded14b": {
    name: "setPermission",
    signature: "setPermission(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xa5d059ca": {
    name: "unbond",
    signature: "unbond(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x3542aee2": {
    name: "mintByOwner",
    signature: "mintByOwner(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xf9609f08": {
    name: "deposit",
    signature: "deposit(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0xa645ff5f": {
    name: "send",
    signature: "send(address[],uint256[])",
    input_types: ["address[]", "uint256[]"],
    output_aliases: ["addresses", "amounts"],
  },
  "0xa04a0908": {
    name: "execute",
    signature: "execute(address,bytes,uint256)",
    input_types: ["address", "bytes", "uint256"],
    output_aliases: ["to", "bytes", "amount"],
  },
  "0xb61d27f6": {
    name: "execute",
    signature: "execute(address,uint256,bytes)",
    input_types: ["address", "uint256", "bytes"],
    output_aliases: ["to", "amount", "bytes"],
  },
  "0x2ed3509e": {
    name: "performSwitch",
    signature: "performSwitch(uint256,uint256,address)",
    input_types: ["uint256", "uint256", "address"],
    output_aliases: ["amount_a", "amount_b", "to_c"],
  },
  "0xa1789085": {
    name: "depositAndBorrow",
    signature: "depositAndBorrow(address,uint256,uint256)",
    input_types: ["address", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "amount_c"],
  },
  "0x19424222": {
    name: "approveAndCall",
    signature: "approveAndCall(address,uint256,bytes32)",
    input_types: ["address", "uint256", "bytes32"],
    output_aliases: ["to", "amount", "b32"],
  },
  "0x98968f15": {
    name: "purchase",
    signature: "purchase(address,uint256,uint256)",
    input_types: ["address", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "amount_c"],
  },
  "0x3215b483": {
    name: "getTokensByMerkleProof",
    signature: "getTokensByMerkleProof(bytes32[],address,uint256)",
    input_types: ["bytes32[]", "address", "uint256"],
    output_aliases: ["b32s", "to", "amount"],
  },
  "0xad5cc918": {
    name: "remove_liquidity",
    signature: "remove_liquidity(address,uint256,uint256[4])",
    input_types: ["address", "uint256", "uint256[4]"],
    output_aliases: ["to", "amount", "amounts"],
  },
  "0x517a55a3": {
    name: "remove_liquidity_one_coin",
    signature: "remove_liquidity_one_coin(uint256,int128,uint256,bool)",
    input_types: ["uint256", "int128", "uint256", "bool"],
    output_aliases: ["amount_a", "undefined_b", "amount_c", "bool_d"],
  },
  "0x9b214f77": {
    name: "safeExecuteOrder",
    signature: "safeExecuteOrder(address,uint256,uint256,bytes)",
    input_types: ["address", "uint256", "uint256", "bytes"],
    output_aliases: ["to_a", "amount_b", "amount_c", "bytes_d"],
  },
  "0x30e94541": {
    name: "CreateNewPool",
    signature: "CreateNewPool(address,uint64,uint256,address)",
    input_types: ["address", "uint64", "uint256", "address"],
    output_aliases: ["to_a", "num_b", "amount_c", "to_d"],
  },
  "0xc858f5f9": {
    name: "borrow",
    signature: "borrow(address,uint256,uint256,uint16)",
    input_types: ["address", "uint256", "uint256", "uint16"],
    output_aliases: ["to_a", "amount_b", "amount_c", "num_d"],
  },
  "0x39e3aea2": {
    name: "unStakeNFT",
    signature: "unStakeNFT(uint256,uint128,uint256,address)",
    input_types: ["uint256", "uint128", "uint256", "address"],
    output_aliases: ["amount_a", "undefined_b", "amount_c", "to_d"],
  },
  "0x5288ba4b": {
    name: "openCreditAccount",
    signature: "openCreditAccount(uint256,address,uint256,uint256)",
    input_types: ["uint256", "address", "uint256", "uint256"],
    output_aliases: ["amount_a", "to_b", "amount_c", "amount_d"],
  },
  "0xe323f825": {
    name: "depositTo",
    signature: "depositTo(address,uint256,address,address)",
    input_types: ["address", "uint256", "address", "address"],
    output_aliases: ["to_a", "amount_b", "to_c", "to_d"],
  },
  "0x72a9f042": {
    name: "saveAndStake",
    signature: "saveAndStake(address,address,address,uint256)",
    input_types: ["address", "address", "address", "uint256"],
    output_aliases: ["to_a", "to_b", "to_c", "amount_d"],
  },
  "0x1f7fdffa": {
    name: "mintBatch",
    signature: "mintBatch(address,uint256[],uint256[],bytes)",
    input_types: ["address", "uint256[]", "uint256[]", "bytes"],
    output_aliases: ["to_a", "amounts_b", "amounts_c", "bytes_d"],
  },
  "0xc96616e1": {
    name: "transferNFT",
    signature: "transferNFT(address,uint256,uint16,bytes32,uint32)",
    input_types: ["address", "uint256", "uint16", "bytes32", "uint32"],
    output_aliases: ["to_a", "amount_b", "num_c", "b32_d", "num_e"],
  },
  // --------------------------------------
  "0x730c1888": {
    name: "setAutoLPBurnSettings",
    signature: "setAutoLPBurnSettings(uint256,uint256,bool)",
    input_types: ["uint256", "uint256", "bool"],
    output_aliases: ["amount_a", "amount_b", "bool"],
  },
  "0xc0246668": {
    name: "setAutoLPBurnSettings",
    signature: "setAutoLPBurnSettings(uint256,uint256,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["address", "bool"],
  },
  "0xfc3fc90a": {
    name: "setBotWallets",
    signature: "setBotWallets(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0xab114805": {
    name: "closeCreditAccount",
    signature: "closeCreditAccount(address,(address[],uint256)[])",
    input_types: ["address", "(address[],uint256)[]"],
    output_aliases: ["to", "envelope"],
  },
  "0xe07fa3c1": {
    name: "withdrawFund",
    signature: "withdrawFund()",
    input_types: [],
    output_aliases: [],
  },
  "0xac1c3d04": {
    name: "generateToken",
    signature: "generateToken()",
    input_types: [],
    output_aliases: [],
  },
  "0xf0fc6bca": {
    name: "claimDividend",
    signature: "claimDividend()",
    input_types: [],
    output_aliases: [],
  },
  "0x4bb278f3": {
    name: "finalize",
    signature: "finalize()",
    input_types: [],
    output_aliases: [],
  },
  "0x02fe5305": {
    name: "setURI",
    signature: "setURI(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x8acc11fb": {
    name: "buyIDO",
    signature: "buyIDO(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x7e108d52": {
    name: "initiateWithdraw",
    signature: "initiateWithdraw(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x3969dfb4": {
    name: "withdrawAndUnwrap",
    signature: "withdrawAndUnwrap(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xb8c6ceea": {
    name: "setBatchCount",
    signature: "setBatchCount(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xf0bedbe2": {
    name: "clearDelegate",
    signature: "clearDelegate(bytes32)",
    input_types: ["bytes32"],
    output_aliases: ["b32"],
  },
  "0x586fb420": {
    name: "mintNFTS",
    signature: "mintNFTS(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x55d93d49": {
    name: "buyNextStep",
    signature: "buyNextStep(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x5d1e9307": {
    name: "relayTokens",
    signature: "relayTokens(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xb5e98b3b": {
    name: "exec",
    signature: "exec(bytes32)",
    input_types: ["bytes32"],
    output_aliases: ["b32"],
  },
  "0x035d9f2a": {
    name: "summon",
    signature: "summon(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xefd0cbf9": {
    name: "mintPublic",
    signature: "mintPublic(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x576f35e3": {
    name: "setBatchSize",
    signature: "setBatchSize(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x24ca984e": {
    name: "addRouter",
    signature: "addRouter(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x89476069": {
    name: "withdrawToken",
    signature: "withdrawToken(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xf435f5a7": {
    name: "lock",
    signature: "lock(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xa81c804e": {
    name: "addToWhitelistBulk",
    signature: "addToWhitelistBulk(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0xec93e5f0": {
    name: "sendToEVMLike",
    signature: "sendToEVMLike(uint32,address)",
    input_types: ["uint32", "address"],
    output_aliases: ["num", "to"],
  },
  "0xf1215d25": {
    name: "deposit",
    signature: "deposit(uint256,string)",
    input_types: ["uint256", "string"],
    output_aliases: ["amount", "string"],
  },
  "0x1dd31ef3": {
    name: "bridgeERC20",
    signature: "bridgeERC20(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x8f893752": {
    name: "sendLove",
    signature: "sendLove(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xd258191e": {
    name: "registerAddressesProvider",
    signature: "registerAddressesProvider(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x594dd432": {
    name: "claimRewards",
    signature: "claimRewards(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xeb685c47": {
    name: "setTokenPrice",
    signature: "setTokenPrice(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xd1e9dcbf": {
    name: "serverForceGameEnd",
    signature: "serverForceGameEnd(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x96e4ee3d": {
    name: "convert",
    signature: "convert(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x3d5d190c": {
    name: "mint",
    signature: "mint(uint256[],uint256[])",
    input_types: ["uint256[]", "uint256[]"],
    output_aliases: ["amounts_a", "amounts_b"],
  },
  // "0x51d0eac4": {
  //   name: "sendEths",
  //   signature: "sendEths(address[],uint256[])",
  //   input_types: ["address[]", "uint256[]"],
  //   output_aliases: ["addresses", "amounts"],
  // },
  "0x52346412": {
    name: "transfers",
    signature: "transfers(address[],uint256[])",
    input_types: ["address[]", "uint256[]"],
    output_aliases: ["addresses", "amounts"],
  },
  "0x710a9f68": {
    name: "execute",
    signature: "execute(uint256,address,bytes)",
    input_types: ["uint256", "address", "bytes"],
    output_aliases: ["amount", "to", "bytes"],
  },
  "0xfdeade31": {
    name: "release",
    signature: "release(uint24,address,address)",
    input_types: ["uint24", "address", "address"],
    output_aliases: ["undefined_a", "to_b", "to_c"],
  },
  "0x952867b1": {
    name: "transferToUserWithFee",
    signature: "transferToUserWithFee(address,uint256,bytes32)",
    input_types: ["address", "uint256", "bytes32"],
    output_aliases: ["to", "amount", "b32"],
  },
  "0x0b573638": {
    name: "ethToTokenTransferOutput",
    signature: "ethToTokenTransferOutput(uint256,uint256,address)",
    input_types: ["uint256", "uint256", "address"],
    output_aliases: ["amount_a", "amount_b", "to_c"],
  },
  "0x3111e7b3": {
    name: "claimRewards",
    signature: "claimRewards(address[],uint256,address)",
    input_types: ["address[]", "uint256", "address"],
    output_aliases: ["addresses", "amount", "to"],
  },
  "0x9386ce2b": {
    name: "withdraw",
    signature: "withdraw(address,uint256,uint256,uint8,bytes32,bytes32)",
    input_types: [
      "address",
      "uint256",
      "uint256",
      "uint8",
      "bytes32",
      "bytes32",
    ],
    output_aliases: ["to_a", "amount_b", "amount_c", "num_d", "b32_e", "b32_f"],
  },
  "0x66de01a8": {
    name: "transferIdeaTokens",
    signature:
      "transferIdeaTokens(uint256,uint256,address,uint256,uint256,uint256)",
    input_types: [
      "uint256",
      "uint256",
      "address",
      "uint256",
      "uint256",
      "uint256",
    ],
    output_aliases: [
      "amount_a",
      "amount_b",
      "to_c",
      "amount_d",
      "amount_e",
      "amount_f",
    ],
  },

  // --------------------------------------
  "0xe6db992f": {
    name: "purgeSniper",
    signature: "purgeSniper()",
    input_types: [],
    output_aliases: [],
  },
  "0x7316c2e9": {
    name: "setDeadBlock",
    signature: "setDeadBlock(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x449a52f8": {
    name: "mintTo",
    signature: "mintTo(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xe34383c7": {
    name: "setSalePublic",
    signature: "setSalePublic(uint256,bool)",
    input_types: ["uint256", "bool"],
    output_aliases: ["amount", "bool"],
  },
  "0x55c9ce1d": {
    name: "createPass",
    signature: "createPass(string,uint256,uint256,uint256,string)",
    input_types: ["string", "uint256", "uint256", "uint256", "string"],
    output_aliases: ["str_a", "amount_a", "amount_b", "amount_c", "str_b"],
  },
  "0xdae0ffc9": {
    name: "setSaleInformation",
    signature:
      "setSaleInformation(uint256,uint256,uint256,uint256,uint256,uint256,bytes32)",
    input_types: [
      "uint256",
      "uint256",
      "uint256",
      "uint256",
      "uint256",
      "uint256",
      "bytes32",
    ],
    output_aliases: ["a", "b", "c", "d", "e", "f", "b32"],
  },
  "0xe2ab10ce": {
    name: "earlyPurchase",
    signature: "earlyPurchase(uint256,bytes32[])",
    input_types: ["uint256", "bytes32[]"],
    output_aliases: ["amount", "b32s"],
  },
  "0x40a4dddd": {
    name: "setURIs",
    signature: "setURIs(string,string,string)",
    input_types: ["string", "string", "string"],
    output_aliases: ["s_a", "s_b", "s_c"],
  },
  "0x2ebe3fbb": {
    name: "initVault",
    signature: "initVault(address)",
    input_types: ["address"],
    output_aliases: ["new_owner"],
  },
  "0x21775c92": {
    name: "toggleMinting",
    signature: "toggleMinting(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x2f2ff15d": {
    name: "grantRole",
    signature: "grantRole(bytes32,address)",
    input_types: ["bytes32", "address"],
    output_aliases: ["b32", "to"],
  },
  "0x13af4035": {
    name: "setOwner",
    signature: "setOwner(address)",
    input_types: ["address"],
    output_aliases: ["owner"],
  },
  "0x2e6b106c": {
    name: "setPresaleWallets",
    signature: "setPresaleWallets(address[],uint256[])",
    input_types: ["address[]", "uint256[]"],
    output_aliases: ["addresses", "amounts"],
  },
  "0x9a894cab": {
    name: "setAllowlistPresaleActive",
    signature: "setAllowlistPresaleActive(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x36358b52": {
    name: "allowlistPresale",
    signature: "allowlistPresale(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x109b1ee6": {
    name: "_mints",
    signature: "_mints(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x7fef3594": {
    name: "isExcludedFromMax",
    signature: "isExcludedFromMax()",
    input_types: ["address", "bool"],
    output_aliases: ["excluded", "bool"],
  },
  "0x21c03a97": {
    name: "setTradingOpen",
    signature: "setTradingOpen(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xbec47e0e": {
    name: "addTokenType",
    signature: "addTokenType()",
    input_types: ["string", "address"],
    output_aliases: ["token", "to"],
  },
  "0xb66a0e5d": {
    name: "startSale",
    signature: "startSale()",
    input_types: [],
    output_aliases: [],
  },
  "0xe36b0b37": {
    name: "stopSale",
    signature: "stopSale()",
    input_types: [],
    output_aliases: [],
  },
  "0x7290b621": {
    name: "setMaxToken",
    signature: "setMaxToken(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_maxBuy", "amount_maxSell", "amount_maxWallet"],
  },
  "0xd9c3c610": {
    name: "deleteTimeStamp",
    signature: "deleteTimeStamp()",
    input_types: [],
    output_aliases: [],
  },
  "0xc1a106c6": {
    name: "createLP",
    signature: "createLP(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["liquidity_pool", "amount"],
  },
  "0x01339c21": {
    name: "launch",
    signature: "launch()",
    input_types: [],
    output_aliases: [],
  },
  "0x5d098b38": {
    name: "setMarketingWallet",
    signature: "setMarketingWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xe8c4c43c": {
    name: "upliftTxAmount",
    signature: "upliftTxAmount()",
    input_types: [],
    output_aliases: [],
  },
  "0x203e727e": {
    name: "updateMaxTxnAmount",
    signature: "updateMaxTxnAmount(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xa6334231": {
    name: "allowtrading",
    signature: "allowtrading()",
    input_types: [],
    output_aliases: [],
  },
  "0x2ab30838": {
    name: "liftMaxTx",
    signature: "liftMaxTx()",
    input_types: [],
    output_aliases: [],
  },
  "0xd543dbeb": {
    name: "setMaxTxPercent",
    signature: "setMaxTxPercent(uint256)",
    input_types: ["uint256"],
    output_aliases: ["percent"],
  },
  "0xf375b253": {
    name: "_removeSniper",
    signature: "_removeSniper(address)",
    input_types: ["address"],
    output_aliases: ["sniper"],
  },
  // --------------------------------------
  "0xa7ba3dff": {
    name: "mintCandle",
    signature: "mintCandle()",
    input_types: [],
    output_aliases: [],
  },
  "0x34918dfd": {
    name: "flipSaleState",
    signature: "flipSaleState()",
    input_types: [],
    output_aliases: [],
  },
  "0x0cd7c478": {
    name: "setmarketingFeePercent",
    signature: "setmarketingFeePercent(uint256)",
    input_types: ["uint256"],
    output_aliases: ["percent"],
  },
  "0x72cf0d58": {
    name: "configureSnipe",
    signature: "configureSnipe(address,uint256,address,uint256)",
    input_types: ["address", "uint256", "address", "uint256"],
    output_aliases: ["to_a", "amount_b", "to_c", "amount_d"],
  },

  // --------------------------------------
  "0xf549ab42": {
    name: "unstakeToken",
    signature:
      "unstakeToken((address,address,uint256,uint256,address),uint256)",
    input_types: ["(address,address,uint256,uint256,address)", "uint256"],
    output_aliases: ["token", "amount"],
  },
  "0x07f62b2d": {
    name: "getNewAddress",
    signature: "getNewAddress()",
    input_types: [],
    output_aliases: [],
  },
  "0x512d3911": {
    name: "createDDDawgs",
    signature: "createDDDawgs()",
    input_types: [],
    output_aliases: [],
  },
  "0x78c3a692": {
    name: "mintGenesis",
    signature: "mintGenesis()",
    input_types: [],
    output_aliases: [],
  },
  "0xa10866ef": {
    name: "flipPublicSaleState",
    signature: "flipPublicSaleState()",
    input_types: [],
    output_aliases: [],
  },
  "0xc39e7dbb": {
    name: "userWithdrawBaseTokens",
    signature: "userWithdrawBaseTokens()",
    input_types: [],
    output_aliases: [],
  },
  "0xfff6cae9": {
    name: "sync",
    signature: "sync()",
    input_types: [],
    output_aliases: [],
  },
  "0xf6326fb3": {
    name: "depositETH",
    signature: "depositETH()",
    input_types: [],
    output_aliases: [],
  },
  "0x7fec8d38": {
    name: "trigger",
    signature: "trigger()",
    input_types: [],
    output_aliases: [],
  },
  "0x2e92abdd": {
    name: "withdrawDividends",
    signature: "withdrawDividends()",
    input_types: [],
    output_aliases: [],
  },
  "0xedca914c": {
    name: "buyTicket",
    signature: "buyTicket()",
    input_types: [],
    output_aliases: [],
  },
  "0x29519457": {
    name: "setTokenPaused",
    signature: "setTokenPaused(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x70a1903d": {
    name: "harvest",
    signature: "harvest(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x841718a6": {
    name: "setSaleActive",
    signature: "setSaleActive(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x95d4063f": {
    name: "claim",
    signature: "claim(uint8)",
    input_types: ["uint8"],
    output_aliases: ["num"],
  },
  "0xaab8ab0c": {
    name: "claim",
    signature: "claim(uint64)",
    input_types: ["uint64"],
    output_aliases: ["num"],
  },
  "0x7d793edd": {
    name: "stake",
    signature: "stake(uint56)",
    input_types: ["uint56"],
    output_aliases: ["undefined"],
  },
  "0x78226e10": {
    name: "claimCode",
    signature: "claimCode(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x2a360631": {
    name: "addBotWallet",
    signature: "addBotWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x1560a24b": {
    name: "EmergencyRebase",
    signature: "EmergencyRebase(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x52719789": {
    name: "sowBeans",
    signature: "sowBeans(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xbc7df091": {
    name: "reserveNFTs",
    signature: "reserveNFTs(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x9cb5fece": {
    name: "mintPublicMultipleTokens",
    signature: "mintPublicMultipleTokens(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x0c5b55f9": {
    name: "createSelected",
    signature: "createSelected(bytes32)",
    input_types: ["bytes32"],
    output_aliases: ["b32"],
  },
  "0x985c4af5": {
    name: "withdrawExit",
    signature: "withdrawExit(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xdc647e29": {
    name: "updateRatio",
    signature: "updateRatio(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x41655ee5": {
    name: "directStake",
    signature: "directStake(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xf84354f1": {
    name: "includeAccount",
    signature: "includeAccount(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xf2cc0c18": {
    name: "excludeAccount",
    signature: "excludeAccount(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xec28438a": {
    name: "setMaxTxAmount",
    signature: "setMaxTxAmount(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xf53fe70f": {
    name: "swapToTON",
    signature: "swapToTON(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xe9af0292": {
    name: "claimComp",
    signature: "claimComp(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x82dc1ec4": {
    name: "addPauser",
    signature: "addPauser(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xa4b910fb": {
    name: "WithdrawToken",
    signature: "WithdrawToken(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x8264fe98": {
    name: "buyPunk",
    signature: "buyPunk(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x7f8661a1": {
    name: "exit",
    signature: "exit(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x6198e339": {
    name: "unlock",
    signature: "unlock(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x880cdc31": {
    name: "updateOwner",
    signature: "updateOwner(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xf8e93ef9": {
    name: "mint",
    signature: "mint(uint256[])",
    input_types: ["uint256[]"],
    output_aliases: ["amounts"],
  },
  "0x15373e3d": {
    name: "castVote",
    signature: "castVote(uint256,bool)",
    input_types: ["uint256", "bool"],
    output_aliases: ["amount", "bool"],
  },
  "0xc9d27afe": {
    name: "vote",
    signature: "vote(uint256,bool)",
    input_types: ["uint256", "bool"],
    output_aliases: ["amount", "bool"],
  },
  "0x10087fb1": {
    name: "stake",
    signature: "stake(uint256,uint8)",
    input_types: ["uint256", "uint8"],
    output_aliases: ["amount", "num"],
  },
  "0xc39cbef1": {
    name: "changeName",
    signature: "changeName(uint256,string)",
    input_types: ["uint256", "string"],
    output_aliases: ["amount", "string"],
  },
  "0x67cbd84c": {
    name: "_set_Fees",
    signature: "_set_Fees(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xd2147618": {
    name: "setWinnerAndPrice",
    signature: "setWinnerAndPrice(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x0dc28efe": {
    name: "adminMint",
    signature: "adminMint(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0xc3a71999": {
    name: "mintAdmin",
    signature: "mintAdmin(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xc814cf89": {
    name: "updateRatioAndFee",
    signature: "updateRatioAndFee(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x5d07c350": {
    name: "mintPresale",
    signature: "mintPresale(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xdae11b7c": {
    name: "sendGenerator",
    signature: "sendGenerator(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xd599ee08": {
    name: "updateReservePriceForReserveAuction",
    signature: "updateReservePriceForReserveAuction(uint256,uint128)",
    input_types: ["uint256", "uint128"],
    output_aliases: ["amount", "uint128"],
  },
  "0x97dc86cf": {
    name: "updateProjectPricePerTokenInWei",
    signature: "updateProjectPricePerTokenInWei(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x9a9f2bbb": {
    name: "issueExactSetFromETH",
    signature: "issueExactSetFromETH(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x63961069": {
    name: "withdrawAnyTokens",
    signature: "withdrawAnyTokens(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xf9c894a8": {
    name: "burnPrint",
    signature: "burnPrint(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xc6066272": {
    name: "withdrawStake",
    signature: "withdrawStake(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x9e2c8a5b": {
    name: "unstake",
    signature: "unstake(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x98590ef9": {
    name: "cancel",
    signature: "cancel(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xc6caa959": {
    name: "sellRock",
    signature: "sellRock(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x2cfd3005": {
    name: "mint",
    signature: "mint(address,bytes32)",
    input_types: ["address", "bytes32"],
    output_aliases: ["to", "b32"],
  },
  "0x23165b75": {
    name: "acceptBidForPunk",
    signature: "acceptBidForPunk(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x2c512d6e": {
    name: "getTokens",
    signature: "getTokens(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x99a88ec4": {
    name: "upgrade",
    signature: "upgrade(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0xba45b0b8": {
    name: "transfer",
    signature: "transfer(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0xe683ff07": {
    name: "listToken",
    signature: "listToken(string,string,uint256)",
    input_types: ["string", "string", "uint256"],
    output_aliases: ["string_a", "string_b", "amount_c"],
  },
  "0xa8662a47": {
    name: "migrate",
    signature: "migrate(address,bytes32,uint256)",
    input_types: ["address", "bytes32", "uint256"],
    output_aliases: ["to", "b32", "amount"],
  },
  "0x2e2d2984": {
    name: "deposit",
    signature: "deposit(uint256,address,address)",
    input_types: ["uint256", "address", "address"],
    output_aliases: ["amount_a", "to_b", "to_c"],
  },
  "0x8f975a64": {
    name: "sendERC20",
    signature: "sendERC20(address,address,uint256)",
    input_types: ["address", "address", "uint256"],
    output_aliases: ["to_a", "to_b", "amount_c"],
  },
  "0x2f1afbac": {
    name: "doAirdrop",
    signature: "doAirdrop(address,address[],uint256[])",
    input_types: ["address", "address[]", "uint256[]"],
    output_aliases: ["to", "addresses", "amounts"],
  },
  "0xec35005d": {
    name: "mintFromPool",
    signature: "mintFromPool(uint256,uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c", "amount_d"],
  },
  "0xd331bef7": {
    name: "withdraw",
    signature: "withdraw(uint256,uint256,uint256,address)",
    input_types: ["uint256", "uint256", "uint256", "address"],
    output_aliases: ["amount_a", "amount_b", "amount_c", "to_d"],
  },
  "0x830ef41b": {
    name: "transferERC20",
    signature: "transferERC20(uint256,address,address,uint256)",
    input_types: ["uint256", "address", "address", "uint256"],
    output_aliases: ["amount_a", "to_b", "to_c", "amount_d"],
  },
  "0x3d7d3f5a": {
    name: "createSaleAuction",
    signature: "createSaleAuction(uint256,uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c", "amount_d"],
  },
  "0x02b9446c": {
    name: "deposit",
    signature: "deposit(address,address,address,uint256,uint256)",
    input_types: ["address", "address", "address", "uint256", "uint256"],
    output_aliases: ["to_a", "to_b", "to_c", "amount_d", "amount_e"],
  },

  // --------------------------------------
  "0x74b31672": {
    name: "claimMint",
    signature: "claimMint()",
    input_types: [],
    output_aliases: [],
  },
  "0xcdd5f2c8": {
    name: "closeEpoch",
    signature: "closeEpoch()",
    input_types: [],
    output_aliases: [],
  },
  "0xdb599698": {
    name: "mintPresale",
    signature: "mintPresale()",
    input_types: [],
    output_aliases: [],
  },
  "0xe507a8a4": {
    name: "withdrawYield",
    signature: "withdrawYield()",
    input_types: [],
    output_aliases: [],
  },
  "0xfe686b48": {
    name: "startExitCooldown",
    signature: "startExitCooldown()",
    input_types: [],
    output_aliases: [],
  },
  "0xaf14052c": {
    name: "rebase",
    signature: "rebase()",
    input_types: [],
    output_aliases: [],
  },
  "0x3a4b66f1": {
    name: "stake",
    signature: "stake()",
    input_types: [],
    output_aliases: [],
  },
  "0xb460481d": {
    name: "redeemShares",
    signature: "redeemShares()",
    input_types: [],
    output_aliases: [],
  },
  "0xf9fc0d07": {
    name: "processRewards",
    signature: "processRewards()",
    input_types: [],
    output_aliases: [],
  },
  "0x458efde3": {
    name: "vest",
    signature: "vest()",
    input_types: [],
    output_aliases: [],
  },
  "0xefc81a8c": {
    name: "create",
    signature: "create()",
    input_types: [],
    output_aliases: [],
  },
  "0x924de9b7": {
    name: "updateSwapEnabled",
    signature: "updateSwapEnabled(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xacec338a": {
    name: "setActive",
    signature: "setActive(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xe70875ad": {
    name: "depositBB",
    signature: "depositBB(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xb450dfce": {
    name: "depositAA",
    signature: "depositAA(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xd4a907a2": {
    name: "bidOrBuy",
    signature: "bidOrBuy(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xe91a7ca6": {
    name: "depositNFT",
    signature: "depositNFT(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xc49b3d54": {
    name: "mintForSelf",
    signature: "mintForSelf(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xadefd90c": {
    name: "setLiquiditFee",
    signature: "setLiquiditFee(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xb00b52f1": {
    name: "adventure",
    signature: "adventure(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x32d342b7": {
    name: "withdrawLocked",
    signature: "withdrawLocked(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xff551c84": {
    name: "processPendingActions",
    signature: "processPendingActions(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x659dd2b4": {
    name: "createBid",
    signature: "createBid(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x0ead3e72": {
    name: "payJoiningFee",
    signature: "payJoiningFee(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xe63bc62d": {
    name: "processProposal",
    signature: "processProposal(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x44c7d6ef": {
    name: "claimStake",
    signature: "claimStake(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xf088d547": {
    name: "buy",
    signature: "buy(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xa2b40d19": {
    name: "changePrice",
    signature: "changePrice(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x80f646d0": {
    name: "changeSignerList",
    signature: "changeSignerList(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x903d4296": {
    name: "withdraw",
    signature: "withdraw(uint256,uint8)",
    input_types: ["uint256", "uint8"],
    output_aliases: ["amount", "num"],
  },
  "0x57f7789e": {
    name: "setTokenUri",
    signature: "setTokenUri(uint256,string)",
    input_types: ["uint256", "string"],
    output_aliases: ["amount", "string"],
  },
  "0x948108f7": {
    name: "addFunds",
    signature: "addFunds(uint256,uint96)",
    input_types: ["uint256", "uint96"],
    output_aliases: ["amount", "uint96"],
  },
  "0x1c35f962": {
    name: "sale",
    signature: "sale(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x165e0dc6": {
    name: "hunt",
    signature: "hunt(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xa5902fac": {
    name: "claimDistribution",
    signature: "claimDistribution(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0x489a0fdc": {
    name: "mintGift",
    signature: "mintGift(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xf64db050": {
    name: "migrateAll",
    signature: "migrateAll(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0x8381e182": {
    name: "unstake",
    signature: "unstake(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0x727b7acf": {
    name: "emergencyRecovery",
    signature: "emergencyRecovery(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xd9ecad7b": {
    name: "breed",
    signature: "breed(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x057466ea": {
    name: "buyToken",
    signature: "buyToken(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x0cf20cc9": {
    name: "withdrawBalance",
    signature: "withdrawBalance(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xeac449d9": {
    name: "revoke",
    signature: "revoke(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x99dfdb3e": {
    name: "mint",
    signature: "mint(uint8,bytes32,bytes32)",
    input_types: ["uint8", "bytes32", "bytes32"],
    output_aliases: ["num_a", "b32_b", "b32_c"],
  },
  "0x3e12170f": {
    name: "stake",
    signature: "stake(address,uint256,bytes)",
    input_types: ["address", "uint256", "bytes"],
    output_aliases: ["to", "amount", "bytes"],
  },
  "0xcd6ef9b1": {
    name: "unstake",
    signature: "unstake(uint256,uint256,bytes)",
    input_types: ["uint256", "uint256", "bytes"],
    output_aliases: ["amount_a", "amount_b", "bytes_c"],
  },
  "0xe677d601": {
    name: "rerollTrait",
    signature: "rerollTrait(uint256,string,uint256)",
    input_types: ["uint256", "string", "uint256"],
    output_aliases: ["amount_a", "string_b", "amount_c"],
  },
  "0x0889d1f0": {
    name: "crossOut",
    signature: "crossOut(string,uint256,address)",
    input_types: ["string", "uint256", "address"],
    output_aliases: ["string", "amount", "to"],
  },
  "0xf78d880e": {
    name: "swapIn",
    signature: "swapIn(address,address,uint256)",
    input_types: ["address", "address", "uint256"],
    output_aliases: ["to_a", "to_b", "amount_c"],
  },
  "0xf779a193": {
    name: "sellNFT",
    signature: "sellNFT(address,uint256,uint256)",
    input_types: ["address", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "amount_c"],
  },
  "0x62c06767": {
    name: "sweep",
    signature: "sweep(address,address,uint256)",
    input_types: ["address", "address", "uint256"],
    output_aliases: ["to_a", "to_b", "amount_c"],
  },
  "0xd9caed12": {
    name: "withdraw",
    signature: "withdraw(address,address,uint256)",
    input_types: ["address", "address", "uint256"],
    output_aliases: ["to_a", "to_b", "amount_c"],
  },
  "0xf2498c9e": {
    name: "whitelistClaim",
    signature: "whitelistClaim(uint256,uint256,bytes32[])",
    input_types: ["uint256", "uint256", "bytes32[]"],
    output_aliases: ["amount_a", "amount_b", "b32s_c"],
  },
  "0x66514c97": {
    name: "borrowETH",
    signature: "borrowETH(address,uint256,uint256,uint16)",
    input_types: ["address", "uint256", "uint256", "uint16"],
    output_aliases: ["to_a", "amount_b", "amount_c", "num_d"],
  },
  "0xf6364846": {
    name: "newImmediatePayment",
    signature: "newImmediatePayment(address,address,uint256,string)",
    input_types: ["address", "address", "uint256", "string"],
    output_aliases: ["to_a", "to_b", "amount_c", "string_d"],
  },
  "0xfaf5ec0c": {
    name: "stake",
    signature: "stake(uint256,address,uint256,uint256)",
    input_types: ["uint256", "address", "uint256", "uint256"],
    output_aliases: ["amount_a", "to_b", "amount_c", "amount_d"],
  },
  "0x02c5fcf8": {
    name: "repayETH",
    signature: "repayETH(address,uint256,uint256,address)",
    input_types: ["address", "uint256", "uint256", "address"],
    output_aliases: ["to_a", "amount_b", "amount_c", "to_d"],
  },
  "0x60491d24": {
    name: "relock",
    signature: "relock(address,uint256,uint256,uint256)",
    input_types: ["address", "uint256", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "amount_c", "amount_d"],
  },
  "0x32db5470": {
    name: "submitBalances",
    signature: "submitBalances(uint256,uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c", "amount_d"],
  },
  "0x91695586": {
    name: "swap",
    signature: "swap(uint8,uint8,uint256,uint256,uint256)",
    input_types: ["uint8", "uint8", "uint256", "uint256", "uint256"],
    output_aliases: ["num_a", "num_b", "amount_c", "amount_d", "amount_e"],
  },
  "0xc0a47c93": {
    name: "setMasterContractApproval",
    signature:
      "setMasterContractApproval(address,address,bool,uint8,bytes32,bytes32)",
    input_types: ["address", "address", "bool", "uint8", "bytes32", "bytes32"],
    output_aliases: ["to_a", "to_b", "bool_c", "num_d", "b32_e", "b32_f"],
  },
  "0xd5280589": {
    name: "registerAndDepositERC20",
    signature:
      "registerAndDepositERC20(address,uint256,bytes,uint256,uint256,uint256)",
    input_types: [
      "address",
      "uint256",
      "bytes",
      "uint256",
      "uint256",
      "uint256",
    ],
    output_aliases: [
      "to_a",
      "amount_b",
      "bytes_c",
      "amount_d",
      "amount_e",
      "amount_f",
    ],
  },
  "0xa6ab9a7a": {
    name: "stake",
    signature: "stake(uint256,address,uint256,uint256,uint256,uint256)",
    input_types: [
      "uint256",
      "address",
      "uint256",
      "uint256",
      "uint256",
      "uint256",
    ],
    output_aliases: [
      "amount_a",
      "to_b",
      "amount_c",
      "amount_d",
      "amount_e",
      "amount_f",
    ],
  },
  "0xf7fcd384": {
    name: "sellToLiquidityProvider",
    signature:
      "sellToLiquidityProvider(address,address,address,address,uint256,uint256,bytes)",
    input_types: [
      "address",
      "address",
      "address",
      "address",
      "uint256",
      "uint256",
      "bytes",
    ],
    output_aliases: [
      "to_a",
      "to_b",
      "to_c",
      "to_d",
      "amount_e",
      "amount_f",
      "bytes_g",
    ],
  },
  "0x28828b1e": {
    name: "claimMultipleAndWithdrawBalance",
    signature:
      "claimMultipleAndWithdrawBalance(bytes32[],uint256[],bytes32[],address[],uint256[],bytes32[])",
    input_types: [
      "bytes32[]",
      "uint256[]",
      "bytes32[]",
      "address[]",
      "uint256[]",
      "bytes32[]",
    ],
    output_aliases: [
      "b32s_a",
      "amounts_b",
      "b32s_c",
      "addresses_d",
      "amounts_e",
      "b32s_f",
    ],
  },
  // --------------------------------------
  "0x9e832315": {
    name: "marketBuyOrdersWithEth",
    signature:
      "marketBuyOrdersWithEth((address,address,address,address,uint256,uint256,uint256,uint256,uint256,uint256,bytes,bytes,bytes,bytes)[],uint256,bytes[],uint256[],address[])",
    input_types: [
      "(address,address,address,address,uint256,uint256,uint256,uint256,uint256,uint256,bytes,bytes,bytes,bytes)[]",
      "uint256",
      "bytes[]",
      "uint256[]",
      "address[]",
    ],
    output_aliases: ["orders", "amount", "data", "amounts", "addresses"],
  },

  "0x2298bf26": {
    name: "payableMint",
    signature: "payableMint()",
    input_types: [],
    output_aliases: [],
  },
  "0x34393743": {
    name: "togglePresale",
    signature: "togglePresale()",
    input_types: [],
    output_aliases: [],
  },
  "0x7e15144b": {
    name: "toggleWhitelist",
    signature: "toggleWhitelist()",
    input_types: [],
    output_aliases: [],
  },
  "0x54dd1da4": {
    name: "releaseVestedTokens",
    signature: "releaseVestedTokens()",
    input_types: [],
    output_aliases: [],
  },
  "0xe98cf987": {
    name: "turnOnSale",
    signature: "turnOnSale()",
    input_types: [],
    output_aliases: [],
  },
  "0xa9b1d507": {
    name: "makeWallet",
    signature: "makeWallet()",
    input_types: [],
    output_aliases: [],
  },
  "0xbe9a6555": {
    name: "start",
    signature: "start()",
    input_types: [],
    output_aliases: [],
  },
  "0xd7bb99ba": {
    name: "contribute",
    signature: "contribute()",
    input_types: [],
    output_aliases: [],
  },
  "0xa69df4b5": {
    name: "unlock",
    signature: "unlock()",
    input_types: [],
    output_aliases: [],
  },
  "0x0e6878a3": {
    name: "claimRewards",
    signature: "claimRewards(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x2d81a78e": {
    name: "claim",
    signature: "claim(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x5f7c7af1": {
    name: "mint",
    signature: "mint(uint96)",
    input_types: ["uint96"],
    output_aliases: ["undefined"],
  },
  "0xc7f04e65": {
    name: "purchase",
    signature: "purchase(uint32)",
    input_types: ["uint32"],
    output_aliases: ["num"],
  },
  "0x7786ed54": {
    name: "mintGaia",
    signature: "mintGaia(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x79c76e1a": {
    name: "flush",
    signature: "flush(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x451bd5c9": {
    name: "mintDuck",
    signature: "mintDuck(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x9c1d9907": {
    name: "buyEditionToken",
    signature: "buyEditionToken(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xb0d1818c": {
    name: "withdrawSurplus",
    signature: "withdrawSurplus(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xa76cb64e": {
    name: "finalizeScan",
    signature: "finalizeScan(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x5dc47b57": {
    name: "undelegateStash",
    signature: "undelegateStash(bytes32)",
    input_types: ["bytes32"],
    output_aliases: ["b32"],
  },
  "0x6b999053": {
    name: "unblockBot",
    signature: "unblockBot(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xc0fe1af8": {
    name: "clear",
    signature: "clear(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xfe784eaa": {
    name: "swapTokens",
    signature: "swapTokens(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x871c128d": {
    name: "updateGasForProcessing",
    signature: "updateGasForProcessing(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x6680ac0b": {
    name: "accrue",
    signature: "accrue(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xbc25cf77": {
    name: "skim",
    signature: "skim(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x611e68d4": {
    name: "transferTreasurer",
    signature: "transferTreasurer(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x797af627": {
    name: "confirm",
    signature: "confirm(bytes32)",
    input_types: ["bytes32"],
    output_aliases: ["b32"],
  },
  "0x0b7e9c44": {
    name: "payout",
    signature: "payout(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x34c7fec9": {
    name: "vest",
    signature: "vest(uint256[])",
    input_types: ["uint256[]"],
    output_aliases: ["amounts"],
  },
  "0x7f649783": {
    name: "addToWhitelist",
    signature: "addToWhitelist(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0xd8b2114e": {
    name: "kycVerdict",
    signature: "kycVerdict(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x7333aad7": {
    name: "stake",
    signature: "stake(uint64,uint64)",
    input_types: ["uint64", "uint64"],
    output_aliases: ["num_a", "num_b"],
  },
  "0x37859963": {
    name: "updateProjectWebsite",
    signature: "updateProjectWebsite(uint256,string)",
    input_types: ["uint256", "string"],
    output_aliases: ["amount", "string"],
  },
  "0xa3b2cca6": {
    name: "updateProjectDescription",
    signature: "updateProjectDescription(uint256,string)",
    input_types: ["uint256", "string"],
    output_aliases: ["amount", "string"],
  },
  "0x298d3f3f": {
    name: "updateBorrowLTV",
    signature: "updateBorrowLTV(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x82afec32": {
    name: "doSwap",
    signature: "doSwap(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xf6f94e2c": {
    name: "unlockInterval",
    signature: "unlockInterval(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xe470d5cf": {
    name: "configure",
    signature: "configure(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0x826fc391": {
    name: "updateProjectMaxInvocations",
    signature: "updateProjectMaxInvocations(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xd441e2b7": {
    name: "openBuyOrder",
    signature: "openBuyOrder(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xe20981ca": {
    name: "withdrawRewards",
    signature: "withdrawRewards(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0x18cca7a6": {
    name: "makeRequestedWithdrawal",
    signature: "makeRequestedWithdrawal(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xb31597ad": {
    name: "redeem",
    signature: "redeem(bytes32,bytes32)",
    input_types: ["bytes32", "bytes32"],
    output_aliases: ["b32_a", "b32_b"],
  },
  "0x484b973c": {
    name: "ownerMint",
    signature: "ownerMint(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x39c38266": {
    name: "manualBuy",
    signature: "manualBuy(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x6e553f65": {
    name: "deposit",
    signature: "deposit(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0x7deb6025": {
    name: "buy",
    signature: "buy(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0x31f208b3": {
    name: "reportBeacon",
    signature: "reportBeacon(uint256,uint64,uint32)",
    input_types: ["uint256", "uint64", "uint32"],
    output_aliases: ["amount_a", "num_b", "num_c"],
  },
  "0xdc9ae17d": {
    name: "burn",
    signature: "burn(bytes,address,uint256)",
    input_types: ["bytes", "address", "uint256"],
    output_aliases: ["bytes", "to", "amount"],
  },
  "0x3dfc99b9": {
    name: "unlockInTransferFunds",
    signature: "unlockInTransferFunds(address,uint256,bytes32)",
    input_types: ["address", "uint256", "bytes32"],
    output_aliases: ["to", "amount", "b32"],
  },
  "0xe13208b4": {
    name: "updateProjectAdditionalPayeeInfo",
    signature: "updateProjectAdditionalPayeeInfo(uint256,address,uint256)",
    input_types: ["uint256", "address", "uint256"],
    output_aliases: ["amount_a", "to_b", "amount_c"],
  },
  "0x8d8798bf": {
    name: "bondTransferRoot",
    signature: "bondTransferRoot(bytes32,uint256,uint256)",
    input_types: ["bytes32", "uint256", "uint256"],
    output_aliases: ["b32_a", "amount_b", "amount_c"],
  },
  "0xbf31196f": {
    name: "offerPunkForSaleToAddress",
    signature: "offerPunkForSaleToAddress(uint256,uint256,address)",
    input_types: ["uint256", "uint256", "address"],
    output_aliases: ["amount_a", "amount_b", "to_c"],
  },
  "0x00000046": {
    name: "swapBackAll23252139",
    signature: "swapBackAll23252139(uint256,address,address,bool)",
    input_types: ["uint256", "address", "address", "bool"],
    output_aliases: ["amount_a", "to_b", "to_c", "bool_d"],
  },
  "0xc04fd2d7": {
    name: "stakeWithPermit",
    signature: "stakeWithPermit(uint256,uint8,bytes32,bytes32)",
    input_types: ["uint256", "uint8", "bytes32", "bytes32"],
    output_aliases: ["amount_a", "num_b", "b32_c", "b32_d"],
  },
  "0x3f33133a": {
    name: "purchase",
    signature: "purchase(uint256,address,address,bytes)",
    input_types: ["uint256", "address", "address", "bytes"],
    output_aliases: ["amount_a", "to_b", "to_c", "bytes_d"],
  },
  "0xab67aa58": {
    name: "transferFrom",
    signature: "transferFrom(address,address,uint256,bytes)",
    input_types: ["address", "address", "uint256", "bytes"],
    output_aliases: ["to_a", "to_b", "amount_c", "bytes_d"],
  },
  "0x8006cc3e": {
    name: "collectSwapFeesForBTC",
    signature: "collectSwapFeesForBTC(address,uint256,uint256,uint256)",
    input_types: ["address", "uint256", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "amount_c", "amount_d"],
  },
  "0x43bcfab6": {
    name: "redeem",
    signature: "redeem(address,uint256,uint256,address)",
    input_types: ["address", "uint256", "uint256", "address"],
    output_aliases: ["to_a", "amount_b", "amount_c", "to_d"],
  },
  "0xb4dfe93d": {
    name: "changeStateToPending",
    signature: "changeStateToPending(address,address[],bytes[],uint256[])",
    input_types: ["address", "address[]", "bytes[]", "uint256[]"],
    output_aliases: ["to", "addresses", "data", "amounts"],
  },
  "0xac063725": {
    name: "placeBid",
    signature: "placeBid(address,uint256,uint256,uint256,bytes)",
    input_types: ["address", "uint256", "uint256", "uint256", "bytes"],
    output_aliases: ["to_a", "amount_b", "amount_c", "amount_d", "bytes_e"],
  },
  "0xe11ed8ff": {
    name: "addToken",
    signature: "addToken(address,uint8,bool,bool,address,address)",
    input_types: ["address", "uint8", "bool", "bool", "address", "address"],
    output_aliases: ["to_a", "num_b", "bool_c", "bool_d", "to_e", "to_f"],
  },
  "0xf31c1262": {
    name: "depositERC20",
    signature: "depositERC20(uint256,address,address,uint256,uint256)",
    input_types: ["uint256", "address", "address", "uint256", "uint256"],
    output_aliases: ["amount_a", "to_b", "to_c", "amount_d", "amount_e"],
  },
  "0x97da6d30": {
    name: "withdraw",
    signature: "withdraw(address,address,address,uint256,uint256)",
    input_types: ["address", "address", "address", "uint256", "uint256"],
    output_aliases: ["to_a", "to_b", "to_c", "amount_d", "amount_e"],
  },
  "0x61359f69": {
    name: "burnWithPermit",
    signature:
      "burnWithPermit(address,uint256,uint256,uint256,uint8,bytes32,bytes32)",
    input_types: [
      "address",
      "uint256",
      "uint256",
      "uint256",
      "uint8",
      "bytes32",
      "bytes32",
    ],
    output_aliases: [
      "to_a",
      "amount_b",
      "amount_c",
      "amount_d",
      "num_e",
      "b32_f",
      "b32_g",
    ],
  },
  "0xd505accf": {
    name: "permit",
    signature: "permit(address,address,uint256,uint256,uint8,bytes32,bytes32)",
    input_types: [
      "address",
      "address",
      "uint256",
      "uint256",
      "uint8",
      "bytes32",
      "bytes32",
    ],
    output_aliases: [
      "to_a",
      "to_b",
      "amount_c",
      "amount_d",
      "num_e",
      "b32_f",
      "b32_g",
    ],
  },

  // --------------------------------------
  "0xd07490f6": {
    name: "executeActionWithAtomicBatchCalls",
    signature:
      "executeActionWithAtomicBatchCalls((address,uint96,bytes)[],uint256,bytes,bytes)",
    input_types: ["(address,uint96,bytes)[]", "uint256", "bytes", "bytes"],
    output_aliases: ["calls", "amount", "bytes_a", "bytes_b"],
  },
  "0xb0705b42": {
    name: "executeBlocks",
    signature:
      "executeBlocks(((uint32,uint64,bytes32,uint256,bytes32,bytes32),bytes[])[])",
    input_types: [
      "((uint32,uint64,bytes32,uint256,bytes32,bytes32),bytes[])[]",
    ],
    output_aliases: ["blocks"],
  },
  "0x83981808": {
    name: "proveBlocks",
    signature:
      "proveBlocks((uint32,uint64,bytes32,uint256,bytes32,bytes32)[],(uint256[],uint256[],uint256[],uint8[],uint256[16]))",
    input_types: [
      "(uint32,uint64,bytes32,uint256,bytes32,bytes32)[]",
      "(uint256[],uint256[],uint256[],uint8[],uint256[16])",
    ],
    output_aliases: ["blocks", "envelope"],
  },
  "0xd7fd19dd": {
    name: "relayMessage",
    signature:
      "relayMessage(address,address,bytes,uint256,(bytes32,(uint256,bytes32,uint256,uint256,bytes),(uint256,bytes32[]),bytes,bytes))",
    input_types: [
      "address",
      "address",
      "bytes",
      "uint256",
      "(bytes32,(uint256,bytes32,uint256,uint256,bytes),(uint256,bytes32[]),bytes,bytes)",
    ],
    output_aliases: ["to_a", "to_b", "bytes", "amount", "message"],
  },
  // --------------------------------------
  "0x2a981b5b": {
    name: "harvestTokens",
    signature: "harvestTokens()",
    input_types: [],
    output_aliases: [],
  },
  "0xf43146ed": {
    name: "claimBirdseed",
    signature: "claimBirdseed()",
    input_types: [],
    output_aliases: [],
  },
  "0xf4aac09d": {
    name: "unstakeWithFee",
    signature: "unstakeWithFee()",
    input_types: [],
    output_aliases: [],
  },
  "0xcbf0b276": {
    name: "claimAllForOwner",
    signature: "claimAllForOwner()",
    input_types: [],
    output_aliases: [],
  },
  "0x0b83a727": {
    name: "claimAllRewards",
    signature: "claimAllRewards()",
    input_types: [],
    output_aliases: [],
  },
  "0xe74f3fbb": {
    name: "claimVestedTokens",
    signature: "claimVestedTokens()",
    input_types: [],
    output_aliases: [],
  },
  "0xe8078d94": {
    name: "addLiquidity",
    signature: "addLiquidity()",
    input_types: [],
    output_aliases: [],
  },
  "0xe4fc6b6d": {
    name: "distribute",
    signature: "distribute()",
    input_types: [],
    output_aliases: [],
  },
  "0x01c79bee": {
    name: "takeBonus",
    signature: "takeBonus(uint8)",
    input_types: ["uint8"],
    output_aliases: ["num"],
  },
  "0x938e3d7b": {
    name: "setContractURI",
    signature: "setContractURI(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x02ece38a": {
    name: "placeEditionBid",
    signature: "placeEditionBid(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xbecf7741": {
    name: "claimById",
    signature: "claimById(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xaa15017c": {
    name: "initWithdraw",
    signature: "initWithdraw(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x63bb23be": {
    name: "claimAllPendingReward",
    signature: "claimAllPendingReward(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x6e23e8e5": {
    name: "setNodeInMaintenance",
    signature: "setNodeInMaintenance(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x0f6795f2": {
    name: "processExits",
    signature: "processExits(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x2e54bf95": {
    name: "withdrawFromSP",
    signature: "withdrawFromSP(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xa6e158f8": {
    name: "redeemTokens",
    signature: "redeemTokens(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x2f6c493c": {
    name: "unlock",
    signature: "unlock(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x97107d6d": {
    name: "setProxy",
    signature: "setProxy(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xa91ee0dc": {
    name: "setRegistry",
    signature: "setRegistry(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x5a51d1df": {
    name: "sendReward",
    signature: "sendReward(uint256[])",
    input_types: ["uint256[]"],
    output_aliases: ["amounts"],
  },
  "0x481cae33": {
    name: "openEgg",
    signature: "openEgg(uint256,bool)",
    input_types: ["uint256", "bool"],
    output_aliases: ["amount", "bool"],
  },
  "0xcf456ae7": {
    name: "setMinter",
    signature: "setMinter(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0xb31c9cc8": {
    name: "recruit",
    signature: "recruit(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x3e649d11": {
    name: "bridgeSend",
    signature: "bridgeSend(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0x32852f18": {
    name: "linearWithdraw",
    signature: "linearWithdraw(uint256,uint128)",
    input_types: ["uint256", "uint128"],
    output_aliases: ["amount", "uint128"],
  },
  "0xeba2389d": {
    name: "setTeamShare",
    signature: "setTeamShare(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x44c32a61": {
    name: "closeAllocation",
    signature: "closeAllocation(address,bytes32)",
    input_types: ["address", "bytes32"],
    output_aliases: ["to", "b32"],
  },
  "0xcdb98676": {
    name: "updateRequiredHarvestAmount",
    signature: "updateRequiredHarvestAmount(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xe6ff41eb": {
    name: "meow",
    signature: "meow(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x9b172b35": {
    name: "stakeRewards",
    signature: "stakeRewards(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0xc2a672e0": {
    name: "unstake",
    signature: "unstake(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xd7136328": {
    name: "vote_for_gauge_weights",
    signature: "vote_for_gauge_weights(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xcbce4c97": {
    name: "gift",
    signature: "gift(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x83c592cf": {
    name: "stake",
    signature: "stake(uint256,bytes32)",
    input_types: ["uint256", "bytes32"],
    output_aliases: ["amount", "b32"],
  },
  "0xe22b62de": {
    name: "setSellFees",
    signature: "setSellFees(uint8,uint8,uint8)",
    input_types: ["uint8", "uint8", "uint8"],
    output_aliases: ["num_a", "num_b", "num_c"],
  },
  "0x7d49d875": {
    name: "remove_liquidity",
    signature: "remove_liquidity(uint256,uint256[4])",
    input_types: ["uint256", "uint256[4]"],
    output_aliases: ["amount", "amounts"],
  },
  "0xf6089e12": {
    name: "melt",
    signature: "melt(uint256[],uint256[])",
    input_types: ["uint256[]", "uint256[]"],
    output_aliases: ["amounts_a", "amounts_b"],
  },
  "0xe63d38ed": {
    name: "disperseEther",
    signature: "disperseEther(address[],uint256[])",
    input_types: ["address[]", "uint256[]"],
    output_aliases: ["addresses", "amounts"],
  },
  "0x6a4a3458": {
    name: "withdrawAllSingle",
    signature: "withdrawAllSingle(bool,uint256,uint256)",
    input_types: ["bool", "uint256", "uint256"],
    output_aliases: ["bool_a", "amount_b", "amount_c"],
  },
  "0x79b91d69": {
    name: "unstake",
    signature: "unstake(uint256,uint256,bool)",
    input_types: ["uint256", "uint256", "bool"],
    output_aliases: ["amount_a", "amount_b", "bool_c"],
  },
  "0x31f09265": {
    name: "withdraw",
    signature: "withdraw(address,uint256,bytes)",
    input_types: ["address", "uint256", "bytes"],
    output_aliases: ["to", "amount", "bytes"],
  },
  "0xd04c6983": {
    name: "sell",
    signature: "sell(uint256,uint256,address)",
    input_types: ["uint256", "uint256", "address"],
    output_aliases: ["amount_a", "amount_b", "to_c"],
  },
  "0xefa9a9be": {
    name: "claim",
    signature: "claim(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0xf5298aca": {
    name: "burn",
    signature: "burn(address,uint256,uint256)",
    input_types: ["address", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "amount_c"],
  },
  "0xc11cd833": {
    name: "enterWithPermit",
    signature: "enterWithPermit(uint256,uint256,uint8,bytes32,bytes32)",
    input_types: ["uint256", "uint256", "uint8", "bytes32", "bytes32"],
    output_aliases: ["amount_a", "amount_b", "num_c", "b32_d", "b32_e"],
  },
  "0x54bacd13": {
    name: "externalSwap",
    signature:
      "externalSwap(address,address,address,address,uint256,uint256,bytes,bool,uint256)",
    input_types: [
      "address",
      "address",
      "address",
      "address",
      "uint256",
      "uint256",
      "bytes",
      "bool",
      "uint256",
    ],
    output_aliases: [
      "to_a",
      "to_b",
      "to_c",
      "to_d",
      "amount_e",
      "amount_f",
      "bytes_g",
      "bool_h",
      "amount_i",
    ],
  },
  // --------------------------------------
  "0x7d49ec1a": {
    name: "cancelLimitOrder",
    signature:
      "cancelLimitOrder((address,address,uint128,uint128,uint128,address,address,address,address,bytes32,uint64,uint256))",
    input_types: [
      "(address,address,uint128,uint128,uint128,address,address,address,address,bytes32,uint64,uint256)",
    ],
    output_aliases: ["limit_order"],
  },
  "0x945bcec9": {
    name: "batchSwap",
    signature:
      "batchSwap(uint8,(bytes32,uint256,uint256,uint256,bytes)[],address[],(address,bool,address,bool),int256[],uint256)",
    input_types: [
      "uint8",
      "(bytes32,uint256,uint256,uint256,bytes)[]",
      "address[]",
      "(address,bool,address,bool)",
      "int256[]",
      "uint256",
    ],
    output_aliases: [
      "num",
      "list",
      "addresses",
      "envelope",
      "amounts",
      "amount",
    ],
  },
  "0x45269298": {
    name: "commitBlocks",
    signature:
      "commitBlocks((uint32,uint64,bytes32,uint256,bytes32,bytes32),(bytes32,bytes,uint256,(bytes,uint32)[],uint32,uint32)[])",
    input_types: [
      "(uint32,uint64,bytes32,uint256,bytes32,bytes32)",
      "(bytes32,bytes,uint256,(bytes,uint32)[],uint32,uint32)[]",
    ],
    output_aliases: ["envelope", "list"],
  },
  "0x54e3f31b": {
    name: "simpleSwap",
    signature:
      "simpleSwap((address,address,uint256,uint256,uint256,address[],bytes,uint256[],uint256[],address,address,uint256,bytes,uint256,bytes16))",
    input_types: [
      "(address,address,uint256,uint256,uint256,address[],bytes,uint256[],uint256[],address,address,uint256,bytes,uint256,bytes16)",
    ],
    output_aliases: ["envelope"],
  },
  "0xd9459372": {
    name: "prepare",
    signature:
      "prepare(((address,address,address,address,address,address,address,address,address,uint256,uint256,bytes32,bytes32),uint256,uint256,bytes,bytes,bytes,bytes))",
    input_types: [
      "((address,address,address,address,address,address,address,address,address,uint256,uint256,bytes32,bytes32),uint256,uint256,bytes,bytes,bytes,bytes)",
    ],
    output_aliases: ["envelope"],
  },
  "0x9b151a80": {
    name: "fulfill",
    signature:
      "fulfill(((address,address,address,address,address,address,address,address,address,bytes32,bytes32,uint256,uint256,uint256,uint256,uint256),uint256,bytes,bytes,bytes))",
    input_types: [
      "((address,address,address,address,address,address,address,address,address,bytes32,bytes32,uint256,uint256,uint256,uint256,uint256),uint256,bytes,bytes,bytes)",
    ],
    output_aliases: ["envelope"],
  },
  // --------------------------------------

  "0x9a2967d2": {
    name: "multiplexMultiHopSellTokenForEth",
    signature:
      "multiplexMultiHopSellTokenForEth(address[],(uint8,bytes)[],uint256,uint256)",
    input_types: ["address[]", "(uint8,bytes)[]", "uint256", "uint256"],
    output_aliases: ["addresses", "list", "amount_a", "amount_b"],
  },
  "0x77725df6": {
    name: "multiplexBatchSellTokenForEth",
    signature:
      "multiplexBatchSellTokenForEth(address,(uint8,uint256,bytes)[],uint256,uint256)",
    input_types: ["address", "(uint8,uint256,bytes)[]", "uint256", "uint256"],
    output_aliases: ["to", "list", "amount_a", "amount_b"],
  },
  "0x8bdb3913": {
    name: "exitPool",
    signature:
      "exitPool(bytes32,address,address,(address[],uint256[],bytes,bool))",
    input_types: [
      "bytes32",
      "address",
      "address",
      "(address[],uint256[],bytes,bool)",
    ],
    output_aliases: ["b32", "to_a", "to_b", "pool"],
  },
  "0xb95cac28": {
    name: "joinPool",
    signature:
      "joinPool(bytes32,address,address,(address[],uint256[],bytes,bool))",
    input_types: [
      "bytes32",
      "address",
      "address",
      "(address[],uint256[],bytes,bool)",
    ],
    output_aliases: ["b32", "to_a", "to_b", "pool"],
  },
  "0x672a9400": {
    name: "mint",
    signature: "mint(uint256,uint8,bytes32,bytes32,(address,uint256)[],string)",
    input_types: [
      "uint256",
      "uint8",
      "bytes32",
      "bytes32",
      "(address,uint256)[]",
      "string",
    ],
    output_aliases: ["amount", "num", "b32_a", "b32_b", "list", "string"],
  },
  "0xc6bf3262": {
    name: "mint",
    signature:
      "mint(uint256,uint8,bytes32,bytes32,(address,uint256)[],uint256,string)",
    input_types: [
      "uint256",
      "uint8",
      "bytes32",
      "bytes32",
      "(address,uint256)[]",
      "uint256",
      "string",
    ],
    output_aliases: [
      "amount_a",
      "num",
      "b32_a",
      "b32_b",
      "list",
      "amount_b",
      "string",
    ],
  },
  "0xb841d2d2": {
    name: "mint",
    signature:
      "mint(uint256,address,uint256,address,(address,address,uint256,uint256,bytes32,bytes32,uint8)[])",
    input_types: [
      "uint256",
      "address",
      "uint256",
      "address",
      "(address,address,uint256,uint256,bytes32,bytes32,uint8)[]",
    ],
    output_aliases: ["amount_a", "to_a", "amount_b", "to_b", "list"],
  },

  "0xfc6f7865": {
    name: "collect",
    signature: "collect((uint256,address,uint128,uint128))",
    input_types: ["(uint256,address,uint128,uint128)"],
    output_aliases: ["envelope"],
  },
  "0xf35b4733": {
    name: "multiplexBatchSellEthForToken",
    signature:
      "multiplexBatchSellEthForToken(address,(uint8,uint256,bytes)[],uint256)",
    input_types: ["address", "(uint8,uint256,bytes)[]", "uint256"],
    output_aliases: ["to", "envelope", "amount"],
  },
  "0xf28c0498": {
    name: "exactOutput",
    signature: "exactOutput((bytes,address,uint256,uint256,uint256))",
    input_types: ["(bytes,address,uint256,uint256,uint256)"],
    output_aliases: ["envelope"],
  },
  // --------------------------------------

  "0x8fa90822": {
    name: "startWithdrawReward",
    signature: "startWithdrawReward()",
    input_types: [],
    output_aliases: [],
  },
  "0xf8800b45": {
    name: "vestEarning",
    signature: "vestEarning()",
    input_types: [],
    output_aliases: [],
  },
  "0x4e80619a": {
    name: "mintWithTreasureKey",
    signature: "mintWithTreasureKey()",
    input_types: [],
    output_aliases: [],
  },
  "0x0f1fa81e": {
    name: "setSuperToken",
    signature: "setSuperToken()",
    input_types: [],
    output_aliases: [],
  },
  "0xfc63958e": {
    name: "requestUnstake",
    signature: "requestUnstake()",
    input_types: [],
    output_aliases: [],
  },
  "0xafb7bed9": {
    name: "requestMint",
    signature: "requestMint()",
    input_types: [],
    output_aliases: [],
  },
  "0x96f8f6dd": {
    name: "flipDropState",
    signature: "flipDropState()",
    input_types: [],
    output_aliases: [],
  },
  "0x03ce86f0": {
    name: "getRewardThenStake",
    signature: "getRewardThenStake()",
    input_types: [],
    output_aliases: [],
  },
  "0x2f814575": {
    name: "togglePublicSaleStarted",
    signature: "togglePublicSaleStarted()",
    input_types: [],
    output_aliases: [],
  },
  "0x985d5449": {
    name: "unvest",
    signature: "unvest()",
    input_types: [],
    output_aliases: [],
  },
  "0x5e5d2b33": {
    name: "emergencyTransferExecute",
    signature: "emergencyTransferExecute()",
    input_types: [],
    output_aliases: [],
  },
  "0x50be99ad": {
    name: "reInvest",
    signature: "reInvest()",
    input_types: [],
    output_aliases: [],
  },
  "0x36f4fb02": {
    name: "updateEpoch",
    signature: "updateEpoch()",
    input_types: [],
    output_aliases: [],
  },
  "0xcb1a4fc0": {
    name: "claimAndStake",
    signature: "claimAndStake()",
    input_types: [],
    output_aliases: [],
  },
  "0x19aa70e7": {
    name: "claimDivs",
    signature: "claimDivs()",
    input_types: [],
    output_aliases: [],
  },
  "0x1e331f52": {
    name: "revealWaifus",
    signature: "revealWaifus()",
    input_types: [],
    output_aliases: [],
  },
  "0xf69e2046": {
    name: "compound",
    signature: "compound()",
    input_types: [],
    output_aliases: [],
  },
  "0xcc7e492e": {
    name: "refillInstantMintPool",
    signature: "refillInstantMintPool()",
    input_types: [],
    output_aliases: [],
  },
  "0x6f4a2cd0": {
    name: "distributeRewards",
    signature: "distributeRewards()",
    input_types: [],
    output_aliases: [],
  },
  "0x90ed6bf4": {
    name: "startWithdraw",
    signature: "startWithdraw()",
    input_types: [],
    output_aliases: [],
  },
  "0x48146113": {
    name: "userDeposit",
    signature: "userDeposit()",
    input_types: [],
    output_aliases: [],
  },
  "0x5b88349d": {
    name: "claimAirdrop",
    signature: "claimAirdrop()",
    input_types: [],
    output_aliases: [],
  },
  "0xe9a9c50c": {
    name: "redeemTokens",
    signature: "redeemTokens()",
    input_types: [],
    output_aliases: [],
  },
  "0xc885bc58": {
    name: "withdrawReward",
    signature: "withdrawReward()",
    input_types: [],
    output_aliases: [],
  },
  "0x94655f2b": {
    name: "printMoney",
    signature: "printMoney()",
    input_types: [],
    output_aliases: [],
  },
  "0x8e1a55fc": {
    name: "build",
    signature: "build()",
    input_types: [],
    output_aliases: [],
  },
  "0xa475b5dd": {
    name: "reveal",
    signature: "reveal()",
    input_types: [],
    output_aliases: [],
  },
  "0xfa45aa00": {
    name: "executeWithdrawal",
    signature: "executeWithdrawal()",
    input_types: [],
    output_aliases: [],
  },
  "0xee97f7f3": {
    name: "master",
    signature: "master()",
    input_types: [],
    output_aliases: [],
  },
  "0x8fd3ab80": {
    name: "migrate",
    signature: "migrate()",
    input_types: [],
    output_aliases: [],
  },
  "0x8456cb59": {
    name: "pause",
    signature: "pause()",
    input_types: [],
    output_aliases: [],
  },
  "0x18178358": {
    name: "poke",
    signature: "poke()",
    input_types: [],
    output_aliases: [],
  },
  "0x775c300c": {
    name: "deploy",
    signature: "deploy()",
    input_types: [],
    output_aliases: [],
  },
  "0x8129fc1c": {
    name: "initialize",
    signature: "initialize()",
    input_types: [],
    output_aliases: [],
  },
  "0xa4698feb": {
    name: "getReward",
    signature: "getReward(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x6a387fc9": {
    name: "completeWithdrawals",
    signature: "completeWithdrawals(uint32)",
    input_types: ["uint32"],
    output_aliases: ["num"],
  },
  "0x5f11301b": {
    name: "setServiceURI",
    signature: "setServiceURI(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x1eabc116": {
    name: "buy",
    signature: "buy(uint16)",
    input_types: ["uint16"],
    output_aliases: ["num"],
  },
  "0xd42b7afd": {
    name: "reserveTickets",
    signature: "reserveTickets(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xd3b5427f": {
    name: "mintDrop",
    signature: "mintDrop(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xa9531ce3": {
    name: "mintGuardianOfAether",
    signature: "mintGuardianOfAether(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xd508e623": {
    name: "deployNewInstance",
    signature: "deployNewInstance(bytes32)",
    input_types: ["bytes32"],
    output_aliases: ["b32"],
  },
  "0x8800304e": {
    name: "depositRepayETH",
    signature: "depositRepayETH(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xf5f6d3af": {
    name: "sweepTokens",
    signature: "sweepTokens(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x66102d2d": {
    name: "mintByUser",
    signature: "mintByUser(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xc0188b6b": {
    name: "publicBuy",
    signature: "publicBuy(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xc486de3a": {
    name: "mintCrewWithAsteroid",
    signature: "mintCrewWithAsteroid(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x23dcb71f": {
    name: "SwapNow",
    signature: "SwapNow(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x1442415e": {
    name: "claimTokensMembership",
    signature: "claimTokensMembership(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x09cd3a2c": {
    name: "harvestForUser",
    signature: "harvestForUser(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xca9c0bad": {
    name: "setRandom",
    signature: "setRandom(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xcb3cb5c4": {
    name: "mintOne",
    signature: "mintOne(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x868ff4a2": {
    name: "whitelistMint",
    signature: "whitelistMint(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x40d097c3": {
    name: "safeMint",
    signature: "safeMint(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x012ce501": {
    name: "emergencyUnstake",
    signature: "emergencyUnstake(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xfdb5fefc": {
    name: "earn",
    signature: "earn(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xaa5f7e26": {
    name: "compound",
    signature: "compound(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x283d62ad": {
    name: "setPoolAdmin",
    signature: "setPoolAdmin(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xddf0b009": {
    name: "queue",
    signature: "queue(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x295da87d": {
    name: "burnSynths",
    signature: "burnSynths(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xb054a9e8": {
    name: "createWallet",
    signature: "createWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x4a4c560d": {
    name: "whitelistUser",
    signature: "whitelistUser(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x96bb1fef": {
    name: "claimStakingRewards",
    signature: "claimStakingRewards(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x7b97008d": {
    name: "purchaseTokens",
    signature: "purchaseTokens(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xd279c191": {
    name: "claimReward",
    signature: "claimReward(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x0e5c011e": {
    name: "harvest",
    signature: "harvest(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x94b918de": {
    name: "swap",
    signature: "swap(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xce5494bb": {
    name: "migrate",
    signature: "migrate(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xb9a2de3a": {
    name: "endAuction",
    signature: "endAuction(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x49df728c": {
    name: "withdrawTokens",
    signature: "withdrawTokens(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x44a0d68a": {
    name: "setCost",
    signature: "setCost(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xc4d252f5": {
    name: "cancel",
    signature: "cancel(bytes32)",
    input_types: ["bytes32"],
    output_aliases: ["b32"],
  },
  "0x2d06177a": {
    name: "addManager",
    signature: "addManager(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x1a695230": {
    name: "transfer",
    signature: "transfer(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xdd467064": {
    name: "lock",
    signature: "lock(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x163e1e61": {
    name: "gift",
    signature: "gift(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0xa51e1904": {
    name: "mint_many",
    signature: "mint_many(address[8])",
    input_types: ["address[8]"],
    output_aliases: ["addresses"],
  },
  "0x491c011a": {
    name: "claimRewards",
    signature: "claimRewards(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0xd2423b51": {
    name: "batch",
    signature: "batch(bytes[],bool)",
    input_types: ["bytes[]", "bool"],
    output_aliases: ["data", "bool"],
  },
  "0x654c9bdb": {
    name: "externalCall",
    signature: "externalCall(address,bytes)",
    input_types: ["address", "bytes"],
    output_aliases: ["to", "bytes"],
  },
  "0xb995028a": {
    name: "addHash",
    signature: "addHash(bytes32,uint32)",
    input_types: ["bytes32", "uint32"],
    output_aliases: ["b32", "num"],
  },
  "0x782ed90c": {
    name: "removeLiquidity",
    signature: "removeLiquidity(uint256,uint32)",
    input_types: ["uint256", "uint32"],
    output_aliases: ["amount", "num"],
  },
  "0xd0def521": {
    name: "mint",
    signature: "mint(address,string)",
    input_types: ["address", "string"],
    output_aliases: ["to", "string"],
  },
  "0x1e59c529": {
    name: "register",
    signature: "register(string,address)",
    input_types: ["string", "address"],
    output_aliases: ["string", "to"],
  },
  "0x25e90ae9": {
    name: "mutatePork",
    signature: "mutatePork(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x38f72e77": {
    name: "mintGuardiansOfAether",
    signature: "mintGuardiansOfAether(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xc4690d3f": {
    name: "linearDeposit",
    signature: "linearDeposit(uint256,uint128)",
    input_types: ["uint256", "uint128"],
    output_aliases: ["amount", "uint128"],
  },
  "0x1414acc1": {
    name: "buyAndSowBeans",
    signature: "buyAndSowBeans(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xc2e3e62b": {
    name: "updateRoot",
    signature: "updateRoot(bytes32,uint256)",
    input_types: ["bytes32", "uint256"],
    output_aliases: ["b32", "amount"],
  },
  "0x57143a61": {
    name: "lockRifi",
    signature: "lockRifi(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xce6df2b9": {
    name: "publicMint",
    signature: "publicMint(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x053992c5": {
    name: "setSalePrice",
    signature: "setSalePrice(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xee040645": {
    name: "mintGift",
    signature: "mintGift(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0xc23b139e": {
    name: "createMarketSale",
    signature: "createMarketSale(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x4d1f0a8e": {
    name: "setBuyNowPriceListing",
    signature: "setBuyNowPriceListing(uint256,uint128)",
    input_types: ["uint256", "uint128"],
    output_aliases: ["amount", "uint128"],
  },
  "0x65a8a037": {
    name: "fusion",
    signature: "fusion(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x01b22ab1": {
    name: "speedUpChildReveal",
    signature: "speedUpChildReveal(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x04826624": {
    name: "giveawayMint",
    signature: "giveawayMint(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x56688700": {
    name: "addLiquidity",
    signature: "addLiquidity(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x8ea6bde3": {
    name: "claimTokens",
    signature: "claimTokens(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x91a50ee1": {
    name: "withdrawBorrow",
    signature: "withdrawBorrow(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xc04a8a10": {
    name: "approveDelegation",
    signature: "approveDelegation(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xd547741f": {
    name: "revokeRole",
    signature: "revokeRole(bytes32,address)",
    input_types: ["bytes32", "address"],
    output_aliases: ["b32", "to"],
  },
  "0x6b8357ac": {
    name: "collect",
    signature: "collect(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x67c6e39c": {
    name: "convert",
    signature: "convert(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x39b6b1e5": {
    name: "cancelBid",
    signature: "cancelBid(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x88888f61": {
    name: "purchase",
    signature: "purchase(uint256,bytes32)",
    input_types: ["uint256", "bytes32"],
    output_aliases: ["amount", "b32"],
  },
  "0xb723b34e": {
    name: "mintTo",
    signature: "mintTo(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0x6f4c6443": {
    name: "invite",
    signature: "invite(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0xabe7f1ab": {
    name: "remove",
    signature: "remove(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x8418cd99": {
    name: "contribute",
    signature: "contribute(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x485cc955": {
    name: "initialize",
    signature: "initialize(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0x6a206137": {
    name: "cancelOrder",
    signature: "cancelOrder(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x029b2f34": {
    name: "add_liquidity",
    signature: "add_liquidity(uint256[4],uint256)",
    input_types: ["uint256[4]", "uint256"],
    output_aliases: ["amounts", "amount"],
  },
  "0x538f9406": {
    name: "updateState",
    signature: "updateState(uint256[],uint256[])",
    input_types: ["uint256[]", "uint256[]"],
    output_aliases: ["amounts_a", "amounts_b"],
  },
  "0xee682473": {
    name: "enter",
    signature: "enter(bytes,bytes,uint256)",
    input_types: ["bytes", "bytes", "uint256"],
    output_aliases: ["bytes_a", "bytes_b", "amount_c"],
  },
  "0x43a0d066": {
    name: "deposit",
    signature: "deposit(uint256,uint256,bool)",
    input_types: ["uint256", "uint256", "bool"],
    output_aliases: ["amount_a", "amount_b", "bool_c"],
  },
  "0x2befabbf": {
    name: "mintIdleToken",
    signature: "mintIdleToken(uint256,bool,address)",
    input_types: ["uint256", "bool", "address"],
    output_aliases: ["amount", "bool", "to"],
  },
  "0x3f579f42": {
    name: "executeTransaction",
    signature: "executeTransaction(address,uint256,bytes)",
    input_types: ["address", "uint256", "bytes"],
    output_aliases: ["to", "amount", "bytes"],
  },
  "0x65cf71b2": {
    name: "stakeGoodAccounting",
    signature: "stakeGoodAccounting(address,uint256,uint40)",
    input_types: ["address", "uint256", "uint40"],
    output_aliases: ["to", "amount", "uint40"],
  },
  "0xd3fc9864": {
    name: "mint",
    signature: "mint(address,uint256,string)",
    input_types: ["address", "uint256", "string"],
    output_aliases: ["to", "amount", "string"],
  },
  "0x033500d3": {
    name: "swap721",
    signature: "swap721(uint256,uint256,address)",
    input_types: ["uint256", "uint256", "address"],
    output_aliases: ["amount_a", "amount_b", "to_c"],
  },
  "0x3ddb3422": {
    name: "reInvest",
    signature: "reInvest(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0x8ea90923": {
    name: "evolve",
    signature: "evolve(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0x6c605b77": {
    name: "finalizeSwap",
    signature: "finalizeSwap(bytes32,address,uint256)",
    input_types: ["bytes32", "address", "uint256"],
    output_aliases: ["b32", "to", "amount"],
  },
  "0x284fa344": {
    name: "harvest",
    signature: "harvest(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0x5c833bfd": {
    name: "redeem",
    signature: "redeem(address,uint256,address)",
    input_types: ["address", "uint256", "address"],
    output_aliases: ["to_a", "amount_b", "to_c"],
  },
  "0xa8d74d1d": {
    name: "withdrawCollateral",
    signature: "withdrawCollateral(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0x46ab38f1": {
    name: "exitswapPoolAmountIn",
    signature: "exitswapPoolAmountIn(address,uint256,uint256)",
    input_types: ["address", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "amount_c"],
  },
  "0x2f2d783d": {
    name: "claimReward",
    signature: "claimReward(address,address,uint256)",
    input_types: ["address", "address", "uint256"],
    output_aliases: ["to_a", "to_b", "amount_c"],
  },
  "0x0efe6a8b": {
    name: "deposit",
    signature: "deposit(address,uint256,uint256)",
    input_types: ["address", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "amount_c"],
  },
  "0x156e29f6": {
    name: "mint",
    signature: "mint(address,uint256,uint256)",
    input_types: ["address", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "amount_c"],
  },
  "0x01e33667": {
    name: "withdrawToken",
    signature: "withdrawToken(address,address,uint256)",
    input_types: ["address", "address", "uint256"],
    output_aliases: ["to_a", "to_b", "amount_c"],
  },
  "0xb460af94": {
    name: "withdraw",
    signature: "withdraw(uint256,address,address)",
    input_types: ["uint256", "address", "address"],
    output_aliases: ["amount_a", "to_b", "to_c"],
  },
  "0x84eb7731": {
    name: "deposit",
    signature: "deposit(uint256,uint256,bytes32[])",
    input_types: ["uint256", "uint256", "bytes32[]"],
    output_aliases: ["amount_a", "amount_b", "b32s_c"],
  },
  "0x40807049": {
    name: "transferManyLands",
    signature: "transferManyLands(uint256,uint256[],address)",
    input_types: ["uint256", "uint256[]", "address"],
    output_aliases: ["amount", "amounts", "to"],
  },
  "0x234b6745": {
    name: "withdraw",
    signature: "withdraw(address,uint256,uint256,bool)",
    input_types: ["address", "uint256", "uint256", "bool"],
    output_aliases: ["to_a", "amount_b", "amount_c", "bool_d"],
  },
  "0xeb391aa8": {
    name: "deposit",
    signature: "deposit(address,uint160,uint32,uint32)",
    input_types: ["address", "uint160", "uint32", "uint32"],
    output_aliases: ["to_a", "undefined_b", "num_c", "num_d"],
  },
  "0x3e3a1560": {
    name: "removeLiquidityOneToken",
    signature: "removeLiquidityOneToken(uint256,uint8,uint256,uint256)",
    input_types: ["uint256", "uint8", "uint256", "uint256"],
    output_aliases: ["amount_a", "num_b", "amount_c", "amount_d"],
  },
  "0x65b2489b": {
    name: "exchange_underlying",
    signature: "exchange_underlying(uint256,uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c", "amount_d"],
  },
  "0x5b41b908": {
    name: "exchange",
    signature: "exchange(uint256,uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c", "amount_d"],
  },
  "0x573ade81": {
    name: "repay",
    signature: "repay(address,uint256,uint256,address)",
    input_types: ["address", "uint256", "uint256", "address"],
    output_aliases: ["to_a", "amount_b", "amount_c", "to_d"],
  },
  "0xcb3e394d": {
    name: "transferFromWithFee",
    signature: "transferFromWithFee(address,address,uint256,uint256)",
    input_types: ["address", "address", "uint256", "uint256"],
    output_aliases: ["to_a", "to_b", "amount_c", "amount_d"],
  },
  "0xa647e8ec": {
    name: "mint",
    signature: "mint(address,uint256,uint256,uint256)",
    input_types: ["address", "uint256", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "amount_c", "amount_d"],
  },
  "0x232a2c1d": {
    name: "executeSignatures",
    signature: "executeSignatures(uint8[],bytes32[],bytes32[],bytes)",
    input_types: ["uint8[]", "bytes32[]", "bytes32[]", "bytes"],
    output_aliases: ["nums_a", "b32s_b", "b32s_c", "bytes_d"],
  },
  "0xf6fff9bd": {
    name: "bulksendToken",
    signature: "bulksendToken(address,address[],uint256[],bytes32)",
    input_types: ["address", "address[]", "uint256[]", "bytes32"],
    output_aliases: ["to", "addresses", "amounts", "b32"],
  },
  "0x88280fa0": {
    name: "claimManyRewards",
    signature: "claimManyRewards(address[],bytes[],uint256[],bytes[])",
    input_types: ["address[]", "bytes[]", "uint256[]", "bytes[]"],
    output_aliases: ["addresses_a", "data_b", "amounts_c", "data_d"],
  },
  "0x62ad1b83": {
    name: "operatorSend",
    signature: "operatorSend(address,address,uint256,bytes,bytes)",
    input_types: ["address", "address", "uint256", "bytes", "bytes"],
    output_aliases: ["to_a", "to_b", "amount_c", "bytes_d", "bytes_e"],
  },
  "0x394747c5": {
    name: "exchange",
    signature: "exchange(uint256,uint256,uint256,uint256,bool)",
    input_types: ["uint256", "uint256", "uint256", "uint256", "bool"],
    output_aliases: ["amount_a", "amount_b", "amount_c", "amount_d", "bool_e"],
  },
  "0xfd5f995b": {
    name: "batchCancelPairRfqOrdersWithSigner",
    signature:
      "batchCancelPairRfqOrdersWithSigner(address,address[],address[],uint256[])",
    input_types: ["address", "address[]", "address[]", "uint256[]"],
    output_aliases: ["to_a", "addresses_b", "addresses_c", "amounts_d"],
  },
  "0xcc1b4bf6": {
    name: "createStream",
    signature: "createStream(address,uint256,address,uint256,uint256)",
    input_types: ["address", "uint256", "address", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "to_c", "amount_d", "amount_e"],
  },
  "0x365d0ed7": {
    name: "deposit",
    signature: "deposit(uint256,uint256,uint256,uint256,address)",
    input_types: ["uint256", "uint256", "uint256", "uint256", "address"],
    output_aliases: ["amount_a", "amount_b", "amount_c", "amount_d", "to_e"],
  },
  "0x6bd9add4": {
    name: "bondWithHint",
    signature: "bondWithHint(uint256,address,address,address,address,address)",
    input_types: [
      "uint256",
      "address",
      "address",
      "address",
      "address",
      "address",
    ],
    output_aliases: ["amount_a", "to_b", "to_c", "to_d", "to_e", "to_f"],
  },
  "0x06011a46": {
    name: "processRollup",
    signature: "processRollup(bytes,bytes,bytes,bytes,address,address,uint256)",
    input_types: [
      "bytes",
      "bytes",
      "bytes",
      "bytes",
      "address",
      "address",
      "uint256",
    ],
    output_aliases: [
      "bytes_a",
      "bytes_b",
      "bytes_c",
      "bytes_d",
      "to_e",
      "to_f",
      "amount_g",
    ],
  },
  "0xdfbe4a31": {
    name: "swapExactTokensForTokens",
    signature:
      "swapExactTokensForTokens(uint256,uint256,address[],address,uint256,uint256)",
    input_types: [
      "uint256",
      "uint256",
      "address[]",
      "address",
      "uint256",
      "uint256",
    ],
    output_aliases: [
      "amount_a",
      "amount_b",
      "addresses_c",
      "to_d",
      "amount_e",
      "amount_f",
    ],
  },
  "0xbaa2abde": {
    name: "removeLiquidity",
    signature:
      "removeLiquidity(address,address,uint256,uint256,uint256,address,uint256)",
    input_types: [
      "address",
      "address",
      "uint256",
      "uint256",
      "uint256",
      "address",
      "uint256",
    ],
    output_aliases: [
      "to_a",
      "to_b",
      "amount_c",
      "amount_d",
      "amount_e",
      "to_f",
      "amount_g",
    ],
  },
  "0x67bf252f": {
    name: "leverage",
    signature:
      "leverage(address,uint256,uint256,uint256,uint256,address,uint256,bytes,bytes)",
    input_types: [
      "address",
      "uint256",
      "uint256",
      "uint256",
      "uint256",
      "address",
      "uint256",
      "bytes",
      "bytes",
    ],
    output_aliases: [
      "to_a",
      "amount_b",
      "amount_c",
      "amount_d",
      "amount_e",
      "to_f",
      "amount_g",
      "bytes_h",
      "bytes_i",
    ],
  },
  "0xa4aabb08": {
    name: "removeLiquidityETHWithPermit",
    signature:
      "removeLiquidityETHWithPermit(address,address,uint256,uint256,uint256,address,uint256,bool,uint8,bytes32,bytes32)",
    input_types: [
      "address",
      "address",
      "uint256",
      "uint256",
      "uint256",
      "address",
      "uint256",
      "bool",
      "uint8",
      "bytes32",
      "bytes32",
    ],
    output_aliases: [
      "to_a",
      "to_b",
      "amount_c",
      "amount_d",
      "amount_e",
      "to_f",
      "amount_g",
      "bool_h",
      "num_i",
      "b32_j",
      "b32_k",
    ],
  },
  // --------------------------------------
  "0xaf086c7e": {
    name: "issueMaxSynths",
    signature: "issueMaxSynths()",
    input_types: [],
    output_aliases: [],
  },
  "0x961be391": {
    name: "cash",
    signature: "cash()",
    input_types: [],
    output_aliases: [],
  },
  "0x243adbdd": {
    name: "buyNft",
    signature: "buyNft(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xa27456bb": {
    name: "fundSubmission",
    signature: "fundSubmission(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xac431d7a": {
    name: "collectAndStreamInterest",
    signature: "collectAndStreamInterest(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xf340fa01": {
    name: "deposit",
    signature: "deposit(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x7666f125": {
    name: "setAllowList",
    signature: "setAllowList(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x1b5757f2": {
    name: "claimReserved",
    signature: "claimReserved(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x4447e48c": {
    name: "configureKeys",
    signature: "configureKeys(uint32,bytes32,bytes32,uint32,bool)",
    input_types: ["uint32", "bytes32", "bytes32", "uint32", "bool"],
    output_aliases: ["num_a", "b32_b", "b32_c", "num_d", "bool_e"],
  },
  // --------------------------------------
  "0x0a861f2a": {
    name: "withdrawLiquidity",
    signature: "withdrawLiquidity(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x4d474898": {
    name: "mine",
    signature: "mine(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xe4849b32": {
    name: "sell",
    signature: "sell(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x278ecde1": {
    name: "refund",
    signature: "refund(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xbe27b22c": {
    name: "claim",
    signature: "claim(bytes,bytes)",
    input_types: ["bytes", "bytes"],
    output_aliases: ["bytes_a", "bytes_b"],
  },
  "0x76704de0": {
    name: "extendLockDuration",
    signature: "extendLockDuration(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x8de69284": {
    name: "subscribe",
    signature: "subscribe(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xc7c13b3a": {
    name: "Withdraw",
    signature: "Withdraw(uint256,bool,uint256)",
    input_types: ["uint256", "bool", "uint256"],
    output_aliases: ["amount_a", "bool_b", "amount_c"],
  },
  "0x94d008ef": {
    name: "mint",
    signature: "mint(address,uint256,bytes)",
    input_types: ["address", "uint256", "bytes"],
    output_aliases: ["to", "amount", "bytes"],
  },
  "0xe30ac74e": {
    name: "migrate",
    signature: "migrate(address,address,address,address)",
    input_types: ["address", "address", "address", "address"],
    output_aliases: ["to_a", "to_b", "to_c", "to_d"],
  },
  // --------------------------------------
  "0x2def6620": {
    name: "unstake",
    signature: "unstake()",
    input_types: [],
    output_aliases: [],
  },
  "0x5aabb855": {
    name: "mintFrog",
    signature: "mintFrog(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x740d73f3": {
    name: "addToWhiteList",
    signature: "addToWhiteList(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0x4782f779": {
    name: "withdrawETH",
    signature: "withdrawETH(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  // --------------------------------------

  "0xdaca07a6": {
    name: "nTokenClaimIncentives",
    signature: "nTokenClaimIncentives()",
    input_types: [],
    output_aliases: [],
  },
  "0x751039fc": {
    name: "removeLimits",
    signature: "removeLimits()",
    input_types: [],
    output_aliases: [],
  },
  "0xa0400066": {
    name: "flipRegularSale",
    signature: "flipRegularSale()",
    input_types: [],
    output_aliases: [],
  },
  "0xe884f260": {
    name: "disableTransferDelay",
    signature: "disableTransferDelay()",
    input_types: [],
    output_aliases: [],
  },
  "0xe222c7f9": {
    name: "togglePublicSale",
    signature: "togglePublicSale()",
    input_types: [],
    output_aliases: [],
  },
  "0xfc06d2a6": {
    name: "sunrise",
    signature: "sunrise()",
    input_types: [],
    output_aliases: [],
  },
  "0x1d9cfd6d": {
    name: "setSale",
    signature: "setSale()",
    input_types: [],
    output_aliases: [],
  },
  "0x715018a6": {
    name: "renounceOwnership",
    signature: "renounceOwnership()",
    input_types: [],
    output_aliases: [],
  },
  "0xfdb5a03e": {
    name: "reinvest",
    signature: "reinvest()",
    input_types: [],
    output_aliases: [],
  },
  "0x3f4ba83a": {
    name: "unpause",
    signature: "unpause()",
    input_types: [],
    output_aliases: [],
  },
  "0x7d7c2a1c": {
    name: "rebalance",
    signature: "rebalance()",
    input_types: [],
    output_aliases: [],
  },
  "0xbe040fb0": {
    name: "redeem",
    signature: "redeem()",
    input_types: [],
    output_aliases: [],
  },
  "0x1cef37e4": {
    name: "publicSale_status",
    signature: "publicSale_status(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xccfe2158": {
    name: "updateTreasuryWalletAddress",
    signature: "updateTreasuryWalletAddress(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xedd36d91": {
    name: "mintMetablobs",
    signature: "mintMetablobs(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x08891a32": {
    name: "submitHash",
    signature: "submitHash(bytes32)",
    input_types: ["bytes32"],
    output_aliases: ["b32"],
  },
  "0x6c346590": {
    name: "JOYtoyMachine",
    signature: "JOYtoyMachine(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x66233126": {
    name: "claimOne",
    signature: "claimOne(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x2b69cbf6": {
    name: "delegateStake",
    signature: "delegateStake(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xee8ca3b5": {
    name: "cancelTask",
    signature: "cancelTask(bytes32)",
    input_types: ["bytes32"],
    output_aliases: ["b32"],
  },
  "0x79c450a7": {
    name: "payChainCurrency",
    signature: "payChainCurrency(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x7fc6bb3d": {
    name: "unapproveUnlock",
    signature: "unapproveUnlock(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xbc337182": {
    name: "setMaxTx",
    signature: "setMaxTx(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xede4edd0": {
    name: "exitMarket",
    signature: "exitMarket(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x9b1f9e74": {
    name: "_burn",
    signature: "_burn(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x07883703": {
    name: "Mint",
    signature: "Mint(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xfa09e630": {
    name: "withdrawAll",
    signature: "withdrawAll(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xf0f44260": {
    name: "setTreasury",
    signature: "setTreasury(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xde0e9a3e": {
    name: "unwrap",
    signature: "unwrap(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xea598cb0": {
    name: "wrap",
    signature: "wrap(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x8d6cc56d": {
    name: "updatePrice",
    signature: "updatePrice(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x88c2a0bf": {
    name: "giveBirth",
    signature: "giveBirth(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x70480275": {
    name: "addAdmin",
    signature: "addAdmin(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x774f99d0": {
    name: "setOboApprovalForAll",
    signature: "setOboApprovalForAll(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0xde6f24bb": {
    name: "sendMessage",
    signature: "sendMessage(address,string)",
    input_types: ["address", "string"],
    output_aliases: ["to", "string"],
  },
  "0x5138b08c": {
    name: "settleAuction",
    signature: "settleAuction(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xc83ec04d": {
    name: "sellVoucher_new",
    signature: "sellVoucher_new(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x8b5bdada": {
    name: "transitForBSC",
    signature: "transitForBSC(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x00f714ce": {
    name: "withdraw",
    signature: "withdraw(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0x4515cef3": {
    name: "add_liquidity",
    signature: "add_liquidity(uint256[3],uint256)",
    input_types: ["uint256[3]", "uint256"],
    output_aliases: ["amounts", "amount"],
  },
  "0x3049105d": {
    name: "deposit",
    signature: "deposit(uint256[2],uint256[2])",
    input_types: ["uint256[2]", "uint256[2]"],
    output_aliases: ["amounts_a", "amounts_b"],
  },
  "0xf1dc3cc9": {
    name: "remove_liquidity_one_coin",
    signature: "remove_liquidity_one_coin(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0x0da61ed2": {
    name: "unlock",
    signature: "unlock(uint256,address,bytes32)",
    input_types: ["uint256", "address", "bytes32"],
    output_aliases: ["amount", "to", "b32"],
  },
  "0xae7b0333": {
    name: "executeOrder",
    signature: "executeOrder(address,uint256,uint256)",
    input_types: ["address", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "amount_c"],
  },
  "0x00000080": {
    name: "conduct_auto",
    signature: "conduct_auto(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0x022c0d9f": {
    name: "swap",
    signature: "swap(uint256,uint256,address,bytes)",
    input_types: ["uint256", "uint256", "address", "bytes"],
    output_aliases: ["amount_a", "amount_b", "to_c", "bytes_d"],
  },
  "0xb88d4fde": {
    name: "safeTransferFrom",
    signature: "safeTransferFrom(address,address,uint256,bytes)",
    input_types: ["address", "address", "uint256", "bytes"],
    output_aliases: ["to_a", "to_b", "amount_c", "bytes_d"],
  },
  "0xf3e6ea8a": {
    name: "swap",
    signature: "swap(address,uint256,uint256,address)",
    input_types: ["address", "uint256", "uint256", "address"],
    output_aliases: ["to_a", "amount_b", "amount_c", "to_d"],
  },
  "0x704c65ee": {
    name: "transferToAnotherChain",
    signature: "transferToAnotherChain(uint256,uint256,address,uint256)",
    input_types: ["uint256", "uint256", "address", "uint256"],
    output_aliases: ["amount_a", "amount_b", "to_c", "amount_d"],
  },
  "0xae1cdde6": {
    name: "depositNft",
    signature: "depositNft(uint256,uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c", "amount_d"],
  },
  "0x45cc0a2f": {
    name: "updateCoinInfo",
    signature: "updateCoinInfo(string,string,uint256,uint256,uint256)",
    input_types: ["string", "string", "uint256", "uint256", "uint256"],
    output_aliases: [
      "string_a",
      "string_b",
      "amount_c",
      "amount_d",
      "amount_e",
    ],
  },
  "0x02c1927c": {
    name: "withdrawToken",
    signature: "withdrawToken(address,address,uint256,string,bytes32)",
    input_types: ["address", "address", "uint256", "string", "bytes32"],
    output_aliases: ["to_a", "to_b", "amount_c", "string_d", "b32_e"],
  },
  "0x02751cec": {
    name: "removeLiquidityETH",
    signature:
      "removeLiquidityETH(address,uint256,uint256,uint256,address,uint256)",
    input_types: [
      "address",
      "uint256",
      "uint256",
      "uint256",
      "address",
      "uint256",
    ],
    output_aliases: [
      "token",
      "liquidity",
      "amountTokenMin",
      "amountETHMin",
      "to",
      "deadline",
    ],
  },
  // --------------------------------------
  "0x0a7a1c4d": {
    name: "action",
    signature: "action()",
    input_types: [],
    output_aliases: [],
  },
  "0x8b30b516": {
    name: "redeemIdleToken",
    signature: "redeemIdleToken(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x4957677c": {
    name: "increase_amount",
    signature: "increase_amount(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xef706adf": {
    name: "cancelOffer",
    signature: "cancelOffer(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xff585caf": {
    name: "callback",
    signature: "callback(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x690e7c09": {
    name: "open",
    signature: "open(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xae169a50": {
    name: "claimReward",
    signature: "claimReward(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xd6f87109": {
    name: "deposit",
    signature: "deposit(string,bytes)",
    input_types: ["string", "bytes"],
    output_aliases: ["string", "bytes"],
  },
  "0xdac9594f": {
    name: "limitLock",
    signature: "limitLock(string,uint256)",
    input_types: ["string", "uint256"],
    output_aliases: ["string", "amount"],
  },
  "0x219dd5b5": {
    name: "setVerifiedColdWallet",
    signature: "setVerifiedColdWallet(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0xbef54a5e": {
    name: "dispense",
    signature: "dispense(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x9a99b4f0": {
    name: "claimRewards",
    signature: "claimRewards(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x9470b0bd": {
    name: "withdrawFrom",
    signature: "withdrawFrom(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xd73dd623": {
    name: "increaseApproval",
    signature: "increaseApproval(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x5b36389c": {
    name: "remove_liquidity",
    signature: "remove_liquidity(uint256,uint256[2])",
    input_types: ["uint256", "uint256[2]"],
    output_aliases: ["amount", "amounts"],
  },
  "0xd7a169eb": {
    name: "depositToAndDelegate",
    signature: "depositToAndDelegate(address,uint256,address)",
    input_types: ["address", "uint256", "address"],
    output_aliases: ["to_a", "amount_b", "to_c"],
  },
  "0x294091cd": {
    name: "stake",
    signature: "stake(address,uint256,address)",
    input_types: ["address", "uint256", "address"],
    output_aliases: ["to_a", "amount", "to_b"],
  },
  // --------------------------------------

  "0x41fe00a0": {
    name: "commitToNextPeriod",
    signature: "commitToNextPeriod()",
    input_types: [],
    output_aliases: [],
  },
  "0xfeaf8048": {
    name: "undelegateStake",
    signature: "undelegateStake()",
    input_types: [],
    output_aliases: [],
  },
  "0xdd473d2b": {
    name: "withdraw_all",
    signature: "withdraw_all()",
    input_types: [],
    output_aliases: [],
  },
  "0x68b4cac9": {
    name: "updateSwapAndLiquifiy",
    signature: "updateSwapAndLiquifiy(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x8c8fed8b": {
    name: "mintAgent1",
    signature: "mintAgent1(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x5f51ae72": {
    name: "mintJailbird",
    signature: "mintJailbird(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x35118f74": {
    name: "unwoof",
    signature: "unwoof(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xc18bc195": {
    name: "updateMaxWalletAmount",
    signature: "updateMaxWalletAmount(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x4f52fd17": {
    name: "removeLiquidity",
    signature: "removeLiquidity(uint256,bool)",
    input_types: ["uint256", "bool"],
    output_aliases: ["amount", "bool"],
  },
  "0x3f489914": {
    name: "withdraw",
    signature: "withdraw(uint8,uint256)",
    input_types: ["uint8", "uint256"],
    output_aliases: ["num", "amount"],
  },
  "0x0e89439b": {
    name: "stake",
    signature: "stake(uint256,bytes)",
    input_types: ["uint256", "bytes"],
    output_aliases: ["amount", "bytes"],
  },
  "0xfe9d9303": {
    name: "burn",
    signature: "burn(uint256,bytes)",
    input_types: ["uint256", "bytes"],
    output_aliases: ["amount", "bytes"],
  },
  "0x55b10537": {
    name: "withdrawAllAndHarvest",
    signature: "withdrawAllAndHarvest(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0xf2465f09": {
    name: "withdrawFNFT",
    signature: "withdrawFNFT(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xbd86e508": {
    name: "setDelegate",
    signature: "setDelegate(bytes32,address)",
    input_types: ["bytes32", "address"],
    output_aliases: ["b32", "to"],
  },
  "0x7898e0c2": {
    name: "setValue",
    signature: "setValue(string,uint128,uint128)",
    input_types: ["string", "uint128", "uint128"],
    output_aliases: ["string_a", "undefined_b", "undefined_c"],
  },
  "0x6d78f47a": {
    name: "issue",
    signature: "issue(address,uint256,address)",
    input_types: ["address", "uint256", "address"],
    output_aliases: ["to_a", "amount_b", "to_c"],
  },
  "0x0f41ba4b": {
    name: "bid",
    signature: "bid(address,uint256,uint256)",
    input_types: ["address", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "amount_c"],
  },
  "0xae0b51df": {
    name: "claim",
    signature: "claim(uint256,uint256,bytes32[])",
    input_types: ["uint256", "uint256", "bytes32[]"],
    output_aliases: ["amount_a", "amount_b", "b32s_c"],
  },
  "0x13fe24e1": {
    name: "depositErc20",
    signature: "depositErc20(address,address,uint256,uint256)",
    input_types: ["address", "address", "uint256", "uint256"],
    output_aliases: ["to_a", "to_b", "amount_c", "amount_d"],
  },
  "0x8f42403a": {
    name: "mint",
    signature:
      "mint(address,uint40,bytes32,uint256,uint256[],uint256,address,bytes)",
    input_types: [
      "address",
      "uint40",
      "bytes32",
      "uint256",
      "uint256[]",
      "uint256",
      "address",
      "bytes",
    ],
    output_aliases: [
      "to_a",
      "undefined_b",
      "b32_c",
      "amount_d",
      "amounts_e",
      "amount_f",
      "to_g",
      "bytes_h",
    ],
  },
  "0xc1169548": {
    name: "execute",
    signature:
      "execute(address,address,bytes,uint256,bytes,uint256,uint256,address,address)",
    input_types: [
      "address",
      "address",
      "bytes",
      "uint256",
      "bytes",
      "uint256",
      "uint256",
      "address",
      "address",
    ],
    output_aliases: [
      "to_a",
      "to_b",
      "bytes_c",
      "amount_d",
      "bytes_e",
      "amount_f",
      "amount_g",
      "to_h",
      "to_i",
    ],
  },
  // --------------------------------------

  "0xf953cec7": {
    name: "receiveMessage",
    signature: "receiveMessage(bytes)",
    input_types: ["bytes"],
    output_aliases: ["bytes"],
  },
  "0xd9158d21": {
    name: "payForVerification",
    signature: "payForVerification(bytes32)",
    input_types: ["bytes32"],
    output_aliases: ["b32"],
  },
  "0x7f00c7a6": {
    name: "setmaxMintAmount",
    signature: "setmaxMintAmount(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x437823ec": {
    name: "excludeFromFee",
    signature: "excludeFromFee(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x51c6590a": {
    name: "addLiquidity",
    signature: "addLiquidity(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x39e899ee": {
    name: "setWhiteList",
    signature: "setWhiteList(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xd0ebdbe7": {
    name: "setManager",
    signature: "setManager(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x56dd3895": {
    name: "lock",
    signature: "lock(uint256,bytes8)",
    input_types: ["uint256", "bytes8"],
    output_aliases: ["amount", "bytes8"],
  },
  "0x9e0fd998": {
    name: "updateEditionPrice",
    signature: "updateEditionPrice(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x9db5dbe4": {
    name: "transferERC20",
    signature: "transferERC20(address,address,uint256)",
    input_types: ["address", "address", "uint256"],
    output_aliases: ["to_a", "to_b", "amount_c"],
  },
  // --------------------------------------

  "0x291d9549": {
    name: "removeWhitelisted",
    signature: "removeWhitelisted(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x273123b7": {
    name: "delBot",
    signature: "delBot(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x3610724e": {
    name: "buyTokens",
    signature: "buyTokens(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xb86e321c": {
    name: "withdrawReward",
    signature: "withdrawReward(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xad8b9edc": {
    name: "buyToken",
    signature: "buyToken(string,string)",
    input_types: ["string", "string"],
    output_aliases: ["string_a", "string_b"],
  },
  "0x27f91856": {
    name: "increaseCustodyAllowance",
    signature: "increaseCustodyAllowance(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xafdb3df6": {
    name: "updatePendingRewardsContributionToNav",
    signature: "updatePendingRewardsContributionToNav(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xad65d76d": {
    name: "ethToTokenTransferInput",
    signature: "ethToTokenTransferInput(uint256,uint256,address)",
    input_types: ["uint256", "uint256", "address"],
    output_aliases: ["amount_a", "amount_b", "to_c"],
  },
  "0x29cd62ea": {
    name: "setPubkey",
    signature: "setPubkey(bytes32,bytes32,bytes32)",
    input_types: ["bytes32", "bytes32", "bytes32"],
    output_aliases: ["b32_a", "b32_b", "b32_c"],
  },
  "0x0b66f3f5": {
    name: "multisendToken",
    signature: "multisendToken(address,address[],uint256[])",
    input_types: ["address", "address[]", "uint256[]"],
    output_aliases: ["to", "addresses", "amounts"],
  },
  "0xe8eda9df": {
    name: "deposit",
    signature: "deposit(address,uint256,address,uint16)",
    input_types: ["address", "uint256", "address", "uint16"],
    output_aliases: ["to_a", "amount_b", "to_c", "num_d"],
  },
  "0x0b2583c8": {
    name: "originSwap",
    signature: "originSwap(address,address,uint256,uint256,uint256)",
    input_types: ["address", "address", "uint256", "uint256", "uint256"],
    output_aliases: ["to_a", "to_b", "amount_c", "amount_d", "amount_e"],
  },
  "0x91aeeedc": {
    name: "invoke1CosignerSends",
    signature:
      "invoke1CosignerSends(uint8,bytes32,bytes32,uint256,address,bytes)",
    input_types: ["uint8", "bytes32", "bytes32", "uint256", "address", "bytes"],
    output_aliases: ["num_a", "b32_b", "b32_c", "amount_d", "to_e", "bytes_f"],
  },
  "0x6ba40878": {
    name: "swap",
    signature: "swap(address,address,address,uint256,uint256,address,bytes)",
    input_types: [
      "address",
      "address",
      "address",
      "uint256",
      "uint256",
      "address",
      "bytes",
    ],
    output_aliases: [
      "to_a",
      "to_b",
      "to_c",
      "amount_d",
      "amount_e",
      "to_f",
      "bytes_g",
    ],
  },
  // --------------------------------------

  "0x7a1eb1b9": {
    name: "multiplexBatchSellTokenForToken",
    signature:
      "multiplexBatchSellTokenForToken(address,address,(uint8,uint256,bytes)[],uint256,uint256)",
    input_types: [
      "address",
      "address",
      "(uint8,uint256,bytes)[]",
      "uint256",
      "uint256",
    ],
    output_aliases: ["to_a", "to_b", "list", "amount_a", "amount_b"],
  },
  "0xe2864fe3": {
    name: "cancel",
    signature:
      "cancel((address,((bytes4,bytes),uint256),address,((bytes4,bytes),uint256),uint256,uint256,uint256,bytes4,bytes))",
    input_types: [
      "(address,((bytes4,bytes),uint256),address,((bytes4,bytes),uint256),uint256,uint256,uint256,bytes4,bytes)",
    ],
    output_aliases: ["envelope"],
  },
  // --------------------------------------

  "0xff872602": {
    name: "removeStrictTxLimit",
    signature: "removeStrictTxLimit()",
    input_types: [],
    output_aliases: [],
  },
  "0x9fb08933": {
    name: "mintKey",
    signature: "mintKey(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x682356c0": {
    name: "migrateFromLEND",
    signature: "migrateFromLEND(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x51cff8d9": {
    name: "withdraw",
    signature: "withdraw(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x4d5693ab": {
    name: "depositUnderlying",
    signature: "depositUnderlying(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x02279b4a": {
    name: "depositNative",
    signature: "depositNative(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x75c1631d": {
    name: "listToken",
    signature: "listToken(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x127ffda0": {
    name: "setUnderlyingPrice",
    signature: "setUnderlyingPrice(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xd1c2babb": {
    name: "merge",
    signature: "merge(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xd2d0e066": {
    name: "deposit",
    signature: "deposit(address,uint256,uint16)",
    input_types: ["address", "uint256", "uint16"],
    output_aliases: ["to", "amount", "num"],
  },
  "0x2ada8a32": {
    name: "claim",
    signature: "claim(address,uint256,uint256,bytes)",
    input_types: ["address", "uint256", "uint256", "bytes"],
    output_aliases: ["to", "amount_a", "amount_b", "bytes"],
  },
  "0xecd9ba82": {
    name: "stakeWithPermit",
    signature: "stakeWithPermit(uint256,uint256,uint8,bytes32,bytes32)",
    input_types: ["uint256", "uint256", "uint8", "bytes32", "bytes32"],
    output_aliases: ["amount_a", "amount_b", "num", "b32_a", "b32_b"],
  },
  "0x5668b02e": {
    name: "stakeWithPermit",
    signature: "stakeWithPermit(uint256,uint256,uint256,uint8,bytes32,bytes32)",
    input_types: [
      "uint256",
      "uint256",
      "uint256",
      "uint8",
      "bytes32",
      "bytes32",
    ],
    output_aliases: [
      "amount_a",
      "amount_b",
      "amount_c",
      "num",
      "b32_a",
      "b32_b",
    ],
  },
  "0xcfc0afeb": {
    name: "simpleSwap",
    signature:
      "simpleSwap(address,address,uint256,uint256,uint256,address[],bytes,uint256[],uint256[],address,string,bool)",
    input_types: [
      "address",
      "address",
      "uint256",
      "uint256",
      "uint256",
      "address[]",
      "bytes",
      "uint256[]",
      "uint256[]",
      "address",
      "string",
      "bool",
    ],
    output_aliases: [
      "to_a",
      "to_b",
      "amount_a",
      "amount_b",
      "amount_c",
      "addresses",
      "bytes",
      "amounts_a",
      "amounts_b",
      "to_c",
      "string",
      "bool",
    ],
  },
  // --------------------------------------

  "0xce52b9f4": {
    name: "withdrawSubmission",
    signature: "withdrawSubmission()",
    input_types: [],
    output_aliases: [],
  },
  "0x595882b3": {
    name: "mintAll",
    signature: "mintAll()",
    input_types: [],
    output_aliases: [],
  },
  "0xbb2841c5": {
    name: "buy_presale",
    signature: "buy_presale(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x6dd478e3": {
    name: "mintPrivateSale",
    signature: "mintPrivateSale(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x049878f3": {
    name: "join",
    signature: "join(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x4e4e5250": {
    name: "redirect",
    signature: "redirect(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0xbf376c7a": {
    name: "wrap",
    signature: "wrap(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xa1448194": {
    name: "safeMint",
    signature: "safeMint(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xadc9772e": {
    name: "stake",
    signature: "stake(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xd91443b7": {
    name: "withdrawAndMint",
    signature: "withdrawAndMint(uint256,uint256,bytes)",
    input_types: ["uint256", "uint256", "bytes"],
    output_aliases: ["amount_a", "amount_b", "bytes"],
  },
  "0xa706a2e2": {
    name: "requestUnlock",
    signature: "requestUnlock(bytes32,address,bytes4,address)",
    input_types: ["bytes32", "address", "bytes4", "address"],
    output_aliases: ["b32", "to_a", "b4", "to_b"],
  },
  // --------------------------------------

  // "0xe99a3f80": {
  //   name: "matchOrders",
  //   signature:
  //     "matchOrders((address,((bytes4,bytes),uint256),address,((bytes4,bytes),uint256),uint256,uint256,uint256,bytes4,bytes),bytes,(address,((bytes4,bytes),uint256),address,((bytes4,bytes),uint256),uint256,uint256,uint256,bytes4,bytes),bytes)",
  //   input_types: [
  //     "(address,((bytes4,bytes),uint256),address,((bytes4,bytes),uint256),uint256,uint256,uint256,bytes4,bytes),bytes,(address,((bytes4,bytes),uint256),address,((bytes4,bytes),uint256),uint256,uint256,uint256,bytes4,bytes)",
  //     "bytes",
  //   ],
  //   output_aliases: ["envelope", "bytes"],
  // },

  "0x73ef9a50": {
    name: "intendToWithdraw",
    signature: "intendToWithdraw()",
    input_types: [],
    output_aliases: [],
  },
  "0xa2f91af2": {
    name: "cage",
    signature: "cage(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x315a095d": {
    name: "withdrawTokens",
    signature: "withdrawTokens(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x54eb560a": {
    name: "run",
    signature: "run(uint256,bytes32)",
    input_types: ["uint256", "bytes32"],
    output_aliases: ["amount", "b32"],
  },
  "0x6f8a41e1": {
    name: "setAuctionReservePrice",
    signature: "setAuctionReservePrice(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xc8fea2fb": {
    name: "collect",
    signature: "collect(address,address,uint256)",
    input_types: ["address", "address", "uint256"],
    output_aliases: ["to_a", "to_b", "amount_c"],
  },
  "0xab23e6fd": {
    name: "createSale",
    signature: "createSale(uint256,address,uint256,bool)",
    input_types: ["uint256", "address", "uint256", "bool"],
    output_aliases: ["amount_a", "to_b", "amount_c", "bool_d"],
  },
  // --------------------------------------

  "0xc04b8d59": {
    name: "exactInput",
    signature: "exactInput((bytes,address,uint256,uint256,uint256))",
    input_types: ["(bytes,address,uint256,uint256,uint256)"],
    output_aliases: ["envelope"],
  },
  "0x0f3b31b2": {
    name: "multiplexMultiHopSellTokenForToken",
    signature:
      "multiplexMultiHopSellTokenForToken(address[],(uint8,bytes)[],uint256,uint256)",
    input_types: ["address[]", "(uint8,bytes)[]", "uint256", "uint256"],
    output_aliases: ["addresses", "list", "amount_a", "amount_b"],
  },
  "0x415565b0": {
    name: "transformERC20",
    signature:
      "transformERC20(address,address,uint256,uint256,(uint32,bytes)[])",
    input_types: [
      "address",
      "address",
      "uint256",
      "uint256",
      "(uint32,bytes)[]",
    ],
    output_aliases: ["to_a", "to_b", "amount_a", "amount_b", "list"],
  },
  "0x219f5d17": {
    name: "increaseLiquidity",
    signature:
      "increaseLiquidity((uint256,uint256,uint256,uint256,uint256,uint256))",
    input_types: ["(uint256,uint256,uint256,uint256,uint256,uint256)"],
    output_aliases: ["envelope"],
  },
  "0xdb3e2198": {
    name: "exactOutputSingle",
    signature:
      "exactOutputSingle((address,address,uint24,address,uint256,uint256,uint256,uint160))",
    input_types: [
      "(address,address,uint24,address,uint256,uint256,uint256,uint160)",
    ],
    output_aliases: ["envelope"],
  },
  "0x88316456": {
    name: "mint",
    signature:
      "mint((address,address,uint24,int24,int24,uint256,uint256,uint256,uint256,address,uint256))",
    input_types: [
      "(address,address,uint24,int24,int24,uint256,uint256,uint256,uint256,address,uint256)",
    ],
    output_aliases: ["envelope"],
  },

  // --------------------------------------

  "0x9f678cca": {
    name: "drip",
    signature: "drip()",
    input_types: [],
    output_aliases: [],
  },
  "0x20aa1b80": {
    name: "setLatestAnswer",
    signature: "setLatestAnswer(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xb162717e": {
    name: "settleBondedWithdrawals",
    signature: "settleBondedWithdrawals(address,bytes32[],uint256)",
    input_types: ["address", "bytes32[]", "uint256"],
    output_aliases: ["to", "b32s", "amount"],
  },

  "0x82aa7c68": {
    name: "enableTrading",
    signature: "enableTrading(uint256)",
    input_types: ["uint256"],
    output_aliases: ["dead_blocks"],
  },
  "0xf538ba51": {
    name: "repayETH",
    signature: "repayETH(uint256,uint256,address)",
    input_types: ["uint256", "uint256", "address"],
    output_aliases: ["amount_a", "amount_b", "to_c"],
  },
  "0xa0ab9653": {
    name: "execute",
    signature:
      "execute(uint8[],bytes32[],bytes32[],address,uint256,bytes,address,uint256)",
    input_types: [
      "uint8[]",
      "bytes32[]",
      "bytes32[]",
      "address",
      "uint256",
      "bytes",
      "address",
      "uint256",
    ],
    output_aliases: [
      "nums_a",
      "b32s_b",
      "b32s_c",
      "to_d",
      "amount_e",
      "bytes_f",
      "to_g",
      "amount_h",
    ],
  },
  // --------------------------------------

  "0x9ac84414": {
    name: "sweepEth",
    signature: "sweepEth()",
    input_types: [],
    output_aliases: [],
  },
  "0xea1644d5": {
    name: "setMaxWalletSize",
    signature: "setMaxWalletSize(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x3b4b1381": {
    name: "mintNFTs",
    signature: "mintNFTs(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x74010ece": {
    name: "setMaxTxnAmount",
    signature: "setMaxTxnAmount(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xbcdd9af7": {
    name: "profitsPayment",
    signature: "profitsPayment(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x6c5d6156": {
    name: "burn",
    signature: "burn(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0xacf1a841": {
    name: "renew",
    signature: "renew(string,uint256)",
    input_types: ["string", "uint256"],
    output_aliases: ["string", "amount"],
  },
  "0xe73a9a25": {
    name: "mutateApeWithSerum",
    signature: "mutateApeWithSerum(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x69328dec": {
    name: "withdraw",
    signature: "withdraw(address,uint256,address)",
    input_types: ["address", "uint256", "address"],
    output_aliases: ["to_a", "amount_b", "to_c"],
  },
  // --------------------------------------

  "0xc53a0292": {
    name: "increaseNonce",
    signature: "increaseNonce()",
    input_types: [],
    output_aliases: [],
  },
  "0xa0263c89": {
    name: "setProtectionSettings",
    signature: "setProtectionSettings(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x23cf0a22": {
    name: "mint",
    signature: "mint(uint16)",
    input_types: ["uint16"],
    output_aliases: ["num"],
  },
  "0xbc4207fe": {
    name: "makeWithdrawalRewards",
    signature: "makeWithdrawalRewards(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xc9b298f1": {
    name: "presaleMint",
    signature: "presaleMint(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x1bf33004": {
    name: "acceptSwap",
    signature: "acceptSwap(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x315a7af3": {
    name: "finalizeRecovery",
    signature: "finalizeRecovery(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x415f1240": {
    name: "liquidate",
    signature: "liquidate(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x09231602": {
    name: "setGasPriceLimit",
    signature: "setGasPriceLimit(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x21860a05": {
    name: "airdrop",
    signature: "airdrop(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xfdacd576": {
    name: "setCompleted",
    signature: "setCompleted(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x9a408321": {
    name: "deposit",
    signature: "deposit(uint256,bool)",
    input_types: ["uint256", "bool"],
    output_aliases: ["amount", "bool"],
  },
  "0xdc083c12": {
    name: "mintOly",
    signature: "mintOly(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x5da2460d": {
    name: "buyPolicy",
    signature: "buyPolicy(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x18fccc76": {
    name: "harvest",
    signature: "harvest(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0xddd5e1b2": {
    name: "claim",
    signature: "claim(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0x598647f8": {
    name: "bid",
    signature: "bid(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xe67ce706": {
    name: "buyBaseToken",
    signature: "buyBaseToken(uint256,uint256,bytes)",
    input_types: ["uint256", "uint256", "bytes"],
    output_aliases: ["amount_a", "amount_b", "bytes_c"],
  },
  "0xc6427474": {
    name: "submitTransaction",
    signature: "submitTransaction(address,uint256,bytes)",
    input_types: ["address", "uint256", "bytes"],
    output_aliases: ["to", "amount", "bytes"],
  },
  "0x15f570dc": {
    name: "burn",
    signature: "burn(address,uint256,string)",
    input_types: ["address", "uint256", "string"],
    output_aliases: ["to", "amount", "string"],
  },
  "0x6e11fb1b": {
    name: "Approve",
    signature: "Approve(address,address,uint256)",
    input_types: ["address", "address", "uint256"],
    output_aliases: ["to_a", "to_b", "amount_c"],
  },
  "0x7373e4eb": {
    name: "get",
    signature: "get(address,address,uint256)",
    input_types: ["address", "address", "uint256"],
    output_aliases: ["to_a", "to_b", "amount_c"],
  },
  "0x9d9892cd": {
    name: "swap",
    signature: "swap(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0x8a2e386e": {
    name: "fundSendToDestinationGas",
    signature: "fundSendToDestinationGas(bytes32,uint256,uint256)",
    input_types: ["bytes32", "uint256", "uint256"],
    output_aliases: ["b32_a", "amount_b", "amount_c"],
  },
  "0xee52a2f3": {
    name: "exchange",
    signature: "exchange(bytes32,uint256,bytes32)",
    input_types: ["bytes32", "uint256", "bytes32"],
    output_aliases: ["b32_a", "amount_b", "b32_c"],
  },
  "0xd450e04c": {
    name: "verifyHeaderAndExecuteTx",
    signature: "verifyHeaderAndExecuteTx(bytes,bytes,bytes,bytes,bytes)",
    input_types: ["bytes", "bytes", "bytes", "bytes", "bytes"],
    output_aliases: ["bytes_a", "bytes_b", "bytes_c", "bytes_d", "bytes_e"],
  },
  "0x4532d776": {
    name: "withdraw",
    signature: "withdraw(address,uint256,uint256,uint256)",
    input_types: ["address", "uint256", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "amount_c", "amount_d"],
  },
  // --------------------------------------

  "0x92a985c0": {
    name: "buyGaufWithEther",
    signature: "buyGaufWithEther()",
    input_types: [],
    output_aliases: [],
  },
  "0x15c316fc": {
    name: "setUpPresale",
    signature: "setUpPresale()",
    input_types: [],
    output_aliases: [],
  },
  "0x4f2be91f": {
    name: "add",
    signature: "add()",
    input_types: [],
    output_aliases: [],
  },
  "0x5932ead1": {
    name: "setCooldownEnabled",
    signature: "setCooldownEnabled(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xc4e37095": {
    name: "setSaleState",
    signature: "setSaleState(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xf9214eec": {
    name: "claimVestingTokens",
    signature: "claimVestingTokens(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x059513a6": {
    name: "mintMany",
    signature: "mintMany(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x849b93e7": {
    name: "mintDucks",
    signature: "mintDucks(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x49c32217": {
    name: "buyPresale",
    signature: "buyPresale(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x525fa81f": {
    name: "setLiquidityAddress",
    signature: "setLiquidityAddress(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x643a7695": {
    name: "verifyParticipant",
    signature: "verifyParticipant(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x672dca62": {
    name: "sellTulip",
    signature: "sellTulip(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xe7b061e8": {
    name: "closeSwapIntent",
    signature: "closeSwapIntent(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xb7751c71": {
    name: "createBid",
    signature: "createBid(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xf9d83bb5": {
    name: "bidOnToken",
    signature: "bidOnToken(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0x2392c189": {
    name: "resolveTo",
    signature: "resolveTo(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x001773e6": {
    name: "convertMetToEth",
    signature: "convertMetToEth(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x1688f0b9": {
    name: "createProxyWithNonce",
    signature: "createProxyWithNonce(address,bytes,uint256)",
    input_types: ["address", "bytes", "uint256"],
    output_aliases: ["to", "bytes", "amount"],
  },
  "0x25519cf2": {
    name: "setBuyFee",
    signature: "setBuyFee(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0x58eb2df5": {
    name: "createMarketItem",
    signature: "createMarketItem(address,uint256,uint256)",
    input_types: ["address", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "amount_c"],
  },
  "0x1d865c30": {
    name: "setSellFee",
    signature: "setSellFee(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0xe93e40db": {
    name: "offerCollectibleForSale",
    signature: "offerCollectibleForSale(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0x7d533c1e": {
    name: "lockTokens",
    signature: "lockTokens(address,address,uint256,uint256)",
    input_types: ["address", "address", "uint256", "uint256"],
    output_aliases: ["to_a", "to_b", "amount_c", "amount_d"],
  },
  "0xdd5b769e": {
    name: "approveRoot",
    signature: "approveRoot(bytes32,bytes32,uint256,uint256,uint256)",
    input_types: ["bytes32", "bytes32", "uint256", "uint256", "uint256"],
    output_aliases: ["b32_a", "b32_b", "amount_c", "amount_d", "amount_e"],
  },
  "0x26ef39d1": {
    name: "rebalance",
    signature: "rebalance(address,int24,int24,int24,int24,address,int256)",
    input_types: [
      "address",
      "int24",
      "int24",
      "int24",
      "int24",
      "address",
      "int256",
    ],
    output_aliases: [
      "to_a",
      "undefined_b",
      "undefined_c",
      "undefined_d",
      "undefined_e",
      "to_f",
      "undefined_g",
    ],
  },
  "0xf1e03d67": {
    name: "createEscrow",
    signature:
      "createEscrow(bytes16,address,address,uint256,uint16,uint32,uint32,uint8,bytes32,bytes32)",
    input_types: [
      "bytes16",
      "address",
      "address",
      "uint256",
      "uint16",
      "uint32",
      "uint32",
      "uint8",
      "bytes32",
      "bytes32",
    ],
    output_aliases: [
      "undefined_a",
      "to_b",
      "to_c",
      "amount_d",
      "num_e",
      "num_f",
      "num_g",
      "num_h",
      "b32_i",
      "b32_j",
    ],
  },
  // --------------------------------------
  "0xf756fa21": {
    name: "completeWithdraw",
    signature: "completeWithdraw()",
    input_types: [],
    output_aliases: [],
  },
  "0x9741fb22": {
    name: "burnSynthsToTarget",
    signature: "burnSynthsToTarget()",
    input_types: [],
    output_aliases: [],
  },
  "0xd294f093": {
    name: "claimFees",
    signature: "claimFees()",
    input_types: [],
    output_aliases: [],
  },
  "0xc9567bf9": {
    name: "openTrading",
    signature: "openTrading()",
    input_types: [],
    output_aliases: [],
  },
  "0x8f70ccf7": {
    name: "setTrading",
    signature: "setTrading(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xa87a20ce": {
    name: "updateAnswer",
    signature: "updateAnswer(int256)",
    input_types: ["int256"],
    output_aliases: ["undefined"],
  },
  "0x55f804b3": {
    name: "setBaseURI",
    signature: "setBaseURI(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x131a0680": {
    name: "store",
    signature: "store(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0x9880a9b1": {
    name: "linearClaimReward",
    signature: "linearClaimReward(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x4ad8008a": {
    name: "depositRewardsFunds",
    signature: "depositRewardsFunds(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x7cb64759": {
    name: "setMerkleRoot",
    signature: "setMerkleRoot(bytes32)",
    input_types: ["bytes32"],
    output_aliases: ["b32"],
  },
  "0x51e3fc17": {
    name: "withdrawLocked",
    signature: "withdrawLocked(bytes32)",
    input_types: ["bytes32"],
    output_aliases: ["b32"],
  },
  "0x88fe2be8": {
    name: "stake",
    signature: "stake(uint128)",
    input_types: ["uint128"],
    output_aliases: ["undefined"],
  },
  "0xc4d66de8": {
    name: "initialize",
    signature: "initialize(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x23caab49": {
    name: "safeExecuteSignaturesWithAutoGasLimit",
    signature: "safeExecuteSignaturesWithAutoGasLimit(bytes,bytes)",
    input_types: ["bytes", "bytes"],
    output_aliases: ["bytes_a", "bytes_b"],
  },
  "0xeaa6ef04": {
    name: "setIcePerSecond",
    signature: "setIcePerSecond(uint256,bool)",
    input_types: ["uint256", "bool"],
    output_aliases: ["amount", "bool"],
  },
  "0x0442bfa8": {
    name: "setMintPrice",
    signature: "setMintPrice(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xc95f9d0e": {
    name: "addLiquidity",
    signature: "addLiquidity(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0x95991276": {
    name: "sellGem",
    signature: "sellGem(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xa7086536": {
    name: "withdrawOfferForCollectible",
    signature: "withdrawOfferForCollectible(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xae77c237": {
    name: "purchase",
    signature: "purchase(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0x1896f70a": {
    name: "setResolver",
    signature: "setResolver(bytes32,address)",
    input_types: ["bytes32", "address"],
    output_aliases: ["b32", "to"],
  },
  "0x1c3db2e0": {
    name: "claimComp",
    signature: "claimComp(address,address[])",
    input_types: ["address", "address[]"],
    output_aliases: ["to", "addresses"],
  },
  "0x39941fa4": {
    name: "claim",
    signature: "claim(uint128,uint256,bool)",
    input_types: ["uint128", "uint256", "bool"],
    output_aliases: ["uint128", "amount", "bool"],
  },
  "0x13d98d13": {
    name: "deposit",
    signature: "deposit(address,bytes32,bytes)",
    input_types: ["address", "bytes32", "bytes"],
    output_aliases: ["to", "b32", "bytes"],
  },
  "0x03ef9d1f": {
    name: "sendERC20ToSidechain",
    signature: "sendERC20ToSidechain(bytes32,uint256,address)",
    input_types: ["bytes32", "uint256", "address"],
    output_aliases: ["b32", "amount", "to"],
  },
  "0xe17376b5": {
    name: "depositERC20",
    signature: "depositERC20(address,uint104,address)",
    input_types: ["address", "uint104", "address"],
    output_aliases: ["to_a", "undefined_b", "to_c"],
  },
  "0xb5c5f672": {
    name: "withdraw",
    signature: "withdraw(address,uint256,uint256)",
    input_types: ["address", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "amount_c"],
  },
  "0x76122903": {
    name: "claimTokens",
    signature: "claimTokens(uint256,address,bytes32[])",
    input_types: ["uint256", "address", "bytes32[]"],
    output_aliases: ["amount", "to", "b32s"],
  },
  "0xa8f0802e": {
    name: "batchSend",
    signature: "batchSend(address[],uint256[],bytes[])",
    input_types: ["address[]", "uint256[]", "bytes[]"],
    output_aliases: ["addresses", "amounts", "data"],
  },
  "0xf3f094a1": {
    name: "redeem",
    signature: "redeem(address,uint256,address,uint256)",
    input_types: ["address", "uint256", "address", "uint256"],
    output_aliases: ["to_a", "amount_b", "to_c", "amount_d"],
  },
  "0x23c452cd": {
    name: "bondWithdrawal",
    signature: "bondWithdrawal(address,uint256,bytes32,uint256)",
    input_types: ["address", "uint256", "bytes32", "uint256"],
    output_aliases: ["to_a", "amount_b", "b32_c", "amount_d"],
  },
  "0xb438689f": {
    name: "withdraw",
    signature:
      "withdraw(address,bytes,bytes32,bytes32,address,address,uint256,uint256)",
    input_types: [
      "address",
      "bytes",
      "bytes32",
      "bytes32",
      "address",
      "address",
      "uint256",
      "uint256",
    ],
    output_aliases: [
      "to_a",
      "bytes_b",
      "b32_c",
      "b32_d",
      "to_e",
      "to_f",
      "amount_g",
      "amount_h",
    ],
  },
  "0x9ca37a0b": {
    name: "ZapIn",
    signature:
      "ZapIn(address,address,uint256,uint256,address,bytes,address,bool,bool)",
    input_types: [
      "address",
      "address",
      "uint256",
      "uint256",
      "address",
      "bytes",
      "address",
      "bool",
      "bool",
    ],
    output_aliases: [
      "to_a",
      "to_b",
      "amount_c",
      "amount_d",
      "to_e",
      "bytes_f",
      "to_g",
      "bool_h",
      "bool_i",
    ],
  },
  "0x5b0d5984": {
    name: "removeLiquidityETHWithPermitSupportingFeeOnTransferTokens",
    signature:
      "removeLiquidityETHWithPermitSupportingFeeOnTransferTokens(address,uint256,uint256,uint256,address,uint256,bool,uint8,bytes32,bytes32)",
    input_types: [
      "address",
      "uint256",
      "uint256",
      "uint256",
      "address",
      "uint256",
      "bool",
      "uint8",
      "bytes32",
      "bytes32",
    ],
    output_aliases: [
      "to_a",
      "amount_b",
      "amount_c",
      "amount_d",
      "to_e",
      "amount_f",
      "bool_g",
      "num_h",
      "b32_i",
      "b32_j",
    ],
  },
  // --------------------------------------
  "0x8a8c523c": {
    name: "enableTrading",
    signature: "enableTrading()",
    input_types: [],
    output_aliases: [],
  },
  "0x4ccd6024": {
    name: "primaryMintSamurai",
    signature: "primaryMintSamurai(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },

  "0x7589cf2f": {
    name: "emergencyUnstake",
    signature: "emergencyUnstake()",
    input_types: [],
    output_aliases: [],
  },
  "0x1998aeef": {
    name: "bid",
    signature: "bid()",
    input_types: [],
    output_aliases: [],
  },
  "0xd0febe4c": {
    name: "buyTokens",
    signature: "buyTokens()",
    input_types: [],
    output_aliases: [],
  },
  "0x5a5e5d58": {
    name: "mintPublicSale",
    signature: "mintPublicSale(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xe97fddc2": {
    name: "unstakeClaimTokens_new",
    signature: "unstakeClaimTokens_new(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x0c89a0df": {
    name: "transferTokens",
    signature: "transferTokens(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x598b8e71": {
    name: "deposit",
    signature: "deposit(uint256[])",
    input_types: ["uint256[]"],
    output_aliases: ["amounts"],
  },
  "0x729ad39e": {
    name: "airdrop",
    signature: "airdrop(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0x000000a0": {
    name: "tree_stream",
    signature: "tree_stream(bytes,bytes)",
    input_types: ["bytes", "bytes"],
    output_aliases: ["bytes_a", "bytes_b"],
  },
  "0x46aed74e": {
    name: "redeemAll",
    signature: "redeemAll(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x4f31a231": {
    name: "claim",
    signature: "claim(address,uint96)",
    input_types: ["address", "uint96"],
    output_aliases: ["to", "uint96"],
  },
  "0x6ecee543": {
    name: "vote",
    signature: "vote(address,uint56)",
    input_types: ["address", "uint56"],
    output_aliases: ["to", "uint56"],
  },
  "0x862440e2": {
    name: "setURI",
    signature: "setURI(uint256,string)",
    input_types: ["uint256", "string"],
    output_aliases: ["amount", "string"],
  },
  "0x71bf55d4": {
    name: "initiateBreeding",
    signature: "initiateBreeding(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x2af2d1fe": {
    name: "reserveFrogs",
    signature: "reserveFrogs(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xea785a5e": {
    name: "burnTo",
    signature: "burnTo(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x1210aac2": {
    name: "withdrawInterest",
    signature: "withdrawInterest(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0x7eee288d": {
    name: "unlock",
    signature: "unlock(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x9a9c29f6": {
    name: "settle",
    signature: "settle(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x8ca5cbb9": {
    name: "appendStateBatch",
    signature: "appendStateBatch(bytes32[],uint256)",
    input_types: ["bytes32[]", "uint256"],
    output_aliases: ["b32s", "amount"],
  },
  "0xd4dd1594": {
    name: "updateLandData",
    signature: "updateLandData(int256,int256,string)",
    input_types: ["int256", "int256", "string"],
    output_aliases: ["undefined_a", "undefined_b", "string_c"],
  },
  "0x09eb2728": {
    name: "lockToken",
    signature: "lockToken(address,uint256,address)",
    input_types: ["address", "uint256", "address"],
    output_aliases: ["to_a", "amount_b", "to_c"],
  },
  "0x95e3c50b": {
    name: "tokenToEthSwapInput",
    signature: "tokenToEthSwapInput(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0xdcdc7dd0": {
    name: "mint",
    signature: "mint(address,uint256,bytes,bytes)",
    input_types: ["address", "uint256", "bytes", "bytes"],
    output_aliases: ["to_a", "amount_b", "bytes_c", "bytes_d"],
  },
  "0x8e1e280c": {
    name: "post2",
    signature: "post2(address,uint256,uint256,uint256)",
    input_types: ["address", "uint256", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "amount_c", "amount_d"],
  },
  "0xa016240b": {
    name: "withdrawInstantlyFrom",
    signature: "withdrawInstantlyFrom(address,uint256,address,uint256)",
    input_types: ["address", "uint256", "address", "uint256"],
    output_aliases: ["to_a", "amount_b", "to_c", "amount_d"],
  },
  "0xeedb711c": {
    name: "withdrawReward",
    signature: "withdrawReward(uint256,uint256,uint256,uint8,bytes32,bytes32)",
    input_types: [
      "uint256",
      "uint256",
      "uint256",
      "uint8",
      "bytes32",
      "bytes32",
    ],
    output_aliases: [
      "amount_a",
      "amount_b",
      "amount_c",
      "num_d",
      "b32_e",
      "b32_f",
    ],
  },

  // --------------------------------------

  "0xb4b72c1d": {
    name: "claimETHRewards",
    signature: "claimETHRewards()",
    input_types: [],
    output_aliases: [],
  },
  "0x4a131672": {
    name: "createUniswapPair",
    signature: "createUniswapPair()",
    input_types: [],
    output_aliases: [],
  },
  "0x6faa8b3f": {
    name: "payBackLoan",
    signature: "payBackLoan(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x4a8011d2": {
    name: "presaleMintNFT",
    signature: "presaleMintNFT(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xf4a0a528": {
    name: "setMintPrice",
    signature: "setMintPrice(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x289137a1": {
    name: "craft",
    signature: "craft(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xc34a03b5": {
    name: "updateProjectSecondaryMarketRoyaltyPercentage",
    signature: "updateProjectSecondaryMarketRoyaltyPercentage(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x7a9b2c6c": {
    name: "withdrawFromStream",
    signature: "withdrawFromStream(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x6ab15071": {
    name: "buyVoucher",
    signature: "buyVoucher(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x867904b4": {
    name: "issue",
    signature: "issue(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x7fd30df0": {
    name: "mintBulk",
    signature: "mintBulk(address[],uint256[])",
    input_types: ["address[]", "uint256[]"],
    output_aliases: ["addresses", "amounts"],
  },
  "0x80ed71e4": {
    name: "deposit",
    signature: "deposit(uint256,bool,address)",
    input_types: ["uint256", "bool", "address"],
    output_aliases: ["amount", "bool", "to"],
  },
  "0xd1a1beb4": {
    name: "mint",
    signature: "mint(address,uint256,bool)",
    input_types: ["address", "uint256", "bool"],
    output_aliases: ["to", "amount", "bool"],
  },
  "0xe2ab691d": {
    name: "lock",
    signature: "lock(address,uint256,uint256)",
    input_types: ["address", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "amount_c"],
  },
  "0x656f3d64": {
    name: "cook",
    signature: "cook(uint8[],uint256[],bytes[])",
    input_types: ["uint8[]", "uint256[]", "bytes[]"],
    output_aliases: ["nums", "amounts", "data"],
  },
  "0x860665b3": {
    name: "openTrove",
    signature: "openTrove(uint256,uint256,address,address)",
    input_types: ["uint256", "uint256", "address", "address"],
    output_aliases: ["amount_a", "amount_b", "to_c", "to_d"],
  },
  "0x2505c3d9": {
    name: "deposit",
    signature: "deposit(uint256,uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c", "amount_d"],
  },
  "0x2036a94d": {
    name: "transferByPartition",
    signature:
      "transferByPartition(bytes32,address,address,uint256,bytes,bytes)",
    input_types: ["bytes32", "address", "address", "uint256", "bytes", "bytes"],
    output_aliases: ["b32_a", "to_b", "to_c", "amount_d", "bytes_e", "bytes_f"],
  },
  // --------------------------------------

  "0x02ce728f": {
    name: "updateExchangeRate",
    signature: "updateExchangeRate()",
    input_types: [],
    output_aliases: [],
  },
  "0xbd8616ec": {
    name: "buyEdition",
    signature: "buyEdition(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xd98237d1": {
    name: "pullParentsByBreedingEventId",
    signature: "pullParentsByBreedingEventId(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xa5a6cd0b": {
    name: "produceBlock",
    signature: "produceBlock(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xef5cfb8c": {
    name: "claimRewards",
    signature: "claimRewards(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xdc97e082": {
    name: "commitTokens",
    signature: "commitTokens(uint256,bool)",
    input_types: ["uint256", "bool"],
    output_aliases: ["amount", "bool"],
  },
  "0x8609d28c": {
    name: "depositNative",
    signature: "depositNative(string,string)",
    input_types: ["string", "string"],
    output_aliases: ["string_a", "string_b"],
  },
  "0xe43f696e": {
    name: "setWhiteList",
    signature: "setWhiteList(address[],bool)",
    input_types: ["address[]", "bool"],
    output_aliases: ["addresses", "bool"],
  },
  "0x4d99dd16": {
    name: "undelegate",
    signature: "undelegate(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x4e114e19": {
    name: "mintForCommunity",
    signature: "mintForCommunity(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xd79875eb": {
    name: "sell",
    signature: "sell(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xdd2414d4": {
    name: "registerUser",
    signature: "registerUser(address,uint256,bytes)",
    input_types: ["address", "uint256", "bytes"],
    output_aliases: ["to", "amount", "bytes"],
  },
  "0x150d283d": {
    name: "buyWithETH",
    signature: "buyWithETH()",
    input_types: [],
    output_aliases: [],
  },
  "0x5c029ac8": {
    name: "claimAllProfit",
    signature: "claimAllProfit()",
    input_types: [],
    output_aliases: [],
  },
  "0x35aacecd": {
    name: "WHITELIST_MINT",
    signature: "WHITELIST_MINT(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xbf113baf": {
    name: "artistMint",
    signature: "artistMint(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x59fe8539": {
    name: "depositAndStake",
    signature: "depositAndStake(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x2ac7a2b3": {
    name: "whitelistBuy",
    signature: "whitelistBuy(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x1028e492": {
    name: "sendTokensToDestination",
    signature: "sendTokensToDestination(bytes32)",
    input_types: ["bytes32"],
    output_aliases: ["b32"],
  },
  "0x27de9e32": {
    name: "unbond",
    signature: "unbond(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x6a627842": {
    name: "mint",
    signature: "mint(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x0fbf0a93": {
    name: "stake",
    signature: "stake(uint256[])",
    input_types: ["uint256[]"],
    output_aliases: ["amounts"],
  },
  "0x8293e9af": {
    name: "withdraw",
    signature: "withdraw(address,uint112)",
    input_types: ["address", "uint112"],
    output_aliases: ["to", "num"],
  },
  "0x57c90de5": {
    name: "placeBid",
    signature: "placeBid(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x338b5dea": {
    name: "depositToken",
    signature: "depositToken(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xd6febde8": {
    name: "buy",
    signature: "buy(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xb02f0b73": {
    name: "exitPool",
    signature: "exitPool(uint256,uint256[])",
    input_types: ["uint256", "uint256[]"],
    output_aliases: ["amount", "amounts"],
  },
  "0xc17b5b8c": {
    name: "updateSellFees",
    signature: "updateSellFees(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0x8095d564": {
    name: "updateBuyFees",
    signature: "updateBuyFees(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0x3ca4c96b": {
    name: "compoundRedeem",
    signature: "compoundRedeem(uint256,address,uint256)",
    input_types: ["uint256", "address", "uint256"],
    output_aliases: ["amount_a", "to_b", "amount_c"],
  },
  "0xd1abb907": {
    name: "withdrawAndHarvest",
    signature: "withdrawAndHarvest(uint256,uint256,address)",
    input_types: ["uint256", "uint256", "address"],
    output_aliases: ["amount_a", "amount_b", "to_c"],
  },
  "0x9f1d0f59": {
    name: "swap",
    signature: "swap(address,uint256,uint256)",
    input_types: ["address", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "amount_c"],
  },
  "0x02c8986f": {
    name: "pay",
    signature: "pay(uint256,address,string,bool)",
    input_types: ["uint256", "address", "string", "bool"],
    output_aliases: ["amount", "to", "string", "bool"],
  },
  "0x1ff013f1": {
    name: "voteProposal",
    signature: "voteProposal(uint8,uint64,bytes32,bytes32)",
    input_types: ["uint8", "uint64", "bytes32", "bytes32"],
    output_aliases: ["num_a", "num_b", "b32_c", "b32_d"],
  },
  "0x58a997f6": {
    name: "depositERC20",
    signature: "depositERC20(address,address,uint256,uint32,bytes)",
    input_types: ["address", "address", "uint256", "uint32", "bytes"],
    output_aliases: ["to_a", "to_b", "amount_c", "num_d", "bytes_e"],
  },
  "0xc6a6cf20": {
    name: "adjustTrove",
    signature: "adjustTrove(uint256,uint256,uint256,bool,address,address)",
    input_types: [
      "uint256",
      "uint256",
      "uint256",
      "bool",
      "address",
      "address",
    ],
    output_aliases: [
      "amount_a",
      "amount_b",
      "amount_c",
      "bool_d",
      "to_e",
      "to_f",
    ],
  },
  "0x4e913cd9": {
    name: "commitBlock",
    signature: "commitBlock(uint32,uint32,bytes32[],bytes,bytes,uint32[])",
    input_types: [
      "uint32",
      "uint32",
      "bytes32[]",
      "bytes",
      "bytes",
      "uint32[]",
    ],
    output_aliases: [
      "num_a",
      "num_b",
      "b32s_c",
      "bytes_d",
      "bytes_e",
      "undefined_f",
    ],
  },
  "0x0a19b14a": {
    name: "trade",
    signature:
      "trade(address,uint256,address,uint256,uint256,uint256,address,uint8,bytes32,bytes32,uint256)",
    input_types: [
      "address",
      "uint256",
      "address",
      "uint256",
      "uint256",
      "uint256",
      "address",
      "uint8",
      "bytes32",
      "bytes32",
      "uint256",
    ],
    output_aliases: [
      "to_a",
      "amount_b",
      "to_c",
      "amount_d",
      "amount_e",
      "amount_f",
      "to_g",
      "num_h",
      "b32_i",
      "b32_j",
      "amount_k",
    ],
  },
  // --------------------------------------

  "0xedc9af95": {
    name: "uni",
    signature: "uni()",
    input_types: [],
    output_aliases: [],
  },
  "0x290e4544": {
    name: "massHarvest",
    signature: "massHarvest()",
    input_types: [],
    output_aliases: [],
  },
  "0xc7b8981c": {
    name: "withdrawRewards",
    signature: "withdrawRewards()",
    input_types: [],
    output_aliases: [],
  },
  "0xbedf0f4a": {
    name: "Stop",
    signature: "Stop()",
    input_types: [],
    output_aliases: [],
  },
  "0x372500ab": {
    name: "claimRewards",
    signature: "claimRewards()",
    input_types: [],
    output_aliases: [],
  },
  "0x86d1a69f": {
    name: "release",
    signature: "release()",
    input_types: [],
    output_aliases: [],
  },
  "0x624fbfdc": {
    name: "echo",
    signature: "echo(bytes)",
    input_types: ["bytes"],
    output_aliases: ["bytes"],
  },
  "0x98f3b12d": {
    name: "claimVestedTokens",
    signature: "claimVestedTokens(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xc2ca0ac5": {
    name: "reveal",
    signature: "reveal(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x4618163e": {
    name: "mintWhitelist",
    signature: "mintWhitelist(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x276569e9": {
    name: "linearClaimPendingWithdraw",
    signature: "linearClaimPendingWithdraw(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xdb6242c3": {
    name: "setMaxPerMint",
    signature: "setMaxPerMint(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xd2cf4c18": {
    name: "unlockTokensToWithdraw",
    signature: "unlockTokensToWithdraw(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x1bf6ddae": {
    name: "createStake",
    signature: "createStake(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x37bdc99b": {
    name: "release",
    signature: "release(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x7c8255db": {
    name: "sendGifts",
    signature: "sendGifts(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  "0x5eac6239": {
    name: "claimRewards",
    signature: "claimRewards(uint256[])",
    input_types: ["uint256[]"],
    output_aliases: ["amounts"],
  },
  "0x60759fce": {
    name: "depositAll",
    signature: "depositAll(uint256,bool)",
    input_types: ["uint256", "bool"],
    output_aliases: ["amount", "bool"],
  },
  "0x38d07436": {
    name: "withdraw",
    signature: "withdraw(uint256,bool)",
    input_types: ["uint256", "bool"],
    output_aliases: ["amount", "bool"],
  },
  "0x56781388": {
    name: "castVote",
    signature: "castVote(uint256,uint8)",
    input_types: ["uint256", "uint8"],
    output_aliases: ["amount", "num"],
  },
  "0x99653fbe": {
    name: "submitVote",
    signature: "submitVote(uint256,uint8)",
    input_types: ["uint256", "uint8"],
    output_aliases: ["amount", "num"],
  },
  "0x304e6ade": {
    name: "setContenthash",
    signature: "setContenthash(bytes32,bytes)",
    input_types: ["bytes32", "bytes"],
    output_aliases: ["b32", "bytes"],
  },
  "0xe542c4a6": {
    name: "setFilstNPV",
    signature: "setFilstNPV(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xa82ba76f": {
    name: "buyNFT",
    signature: "buyNFT(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x0983061c": {
    name: "presaleBuy",
    signature: "presaleBuy(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x884e17f3": {
    name: "withdrawPrincipal",
    signature: "withdrawPrincipal(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0xc3490263": {
    name: "claim",
    signature: "claim(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x955a5a76": {
    name: "acceptBid",
    signature: "acceptBid(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xb0b02c60": {
    name: "setUpdateOperator",
    signature: "setUpdateOperator(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0x94bf804d": {
    name: "mint",
    signature: "mint(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0xf7d8c883": {
    name: "breedWithAuto",
    signature: "breedWithAuto(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xf7d97577": {
    name: "setPrice",
    signature: "setPrice(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xea2c38ae": {
    name: "manualEpochInit",
    signature: "manualEpochInit(address[],uint128)",
    input_types: ["address[]", "uint128"],
    output_aliases: ["addresses", "uint128"],
  },
  "0x4e43e495": {
    name: "submitCheckpoint",
    signature: "submitCheckpoint(bytes,uint256[3][])",
    input_types: ["bytes", "uint256[3][]"],
    output_aliases: ["bytes", "data"],
  },
  "0x0b4c7e4d": {
    name: "add_liquidity",
    signature: "add_liquidity(uint256[2],uint256)",
    input_types: ["uint256[2]", "uint256"],
    output_aliases: ["amounts", "amount"],
  },
  "0x02c85991": {
    name: "distributeMinting",
    signature: "distributeMinting(address[],uint256[])",
    input_types: ["address[]", "uint256[]"],
    output_aliases: ["addresses", "amounts"],
  },
  "0xfa6e671d": {
    name: "setRelayerApproval",
    signature: "setRelayerApproval(address,address,bool)",
    input_types: ["address", "address", "bool"],
    output_aliases: ["to_a", "to_b", "bool_c"],
  },
  "0x3c423f0b": {
    name: "withdrawToken",
    signature: "withdrawToken(uint256,address,bytes)",
    input_types: ["uint256", "address", "bytes"],
    output_aliases: ["amount", "to", "bytes"],
  },
  "0xc172715c": {
    name: "BUY",
    signature: "BUY(uint256,address,uint256)",
    input_types: ["uint256", "address", "uint256"],
    output_aliases: ["amount_a", "to_b", "amount_c"],
  },
  "0xbd97b375": {
    name: "unstake",
    signature: "unstake(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0x5c5b9f8f": {
    name: "depositTokens",
    signature: "depositTokens(address,uint256,uint256)",
    input_types: ["address", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "amount_c"],
  },
  "0xf213159c": {
    name: "depositTo",
    signature: "depositTo(address,address,uint256)",
    input_types: ["address", "address", "uint256"],
    output_aliases: ["to_a", "to_b", "amount_c"],
  },
  "0x4d49e87d": {
    name: "addLiquidity",
    signature: "addLiquidity(uint256[],uint256,uint256)",
    input_types: ["uint256[]", "uint256", "uint256"],
    output_aliases: ["amounts_a", "amount_b", "amount_c"],
  },
  "0xadd975cc": {
    name: "SELL",
    signature: "SELL(uint256,address,uint256,uint256)",
    input_types: ["uint256", "address", "uint256", "uint256"],
    output_aliases: ["amount_a", "to_b", "amount_c", "amount_d"],
  },
  "0x90d25074": {
    name: "deposit",
    signature: "deposit(address,uint256,address,uint256)",
    input_types: ["address", "uint256", "address", "uint256"],
    output_aliases: ["to_a", "amount_b", "to_c", "amount_d"],
  },
  "0x55a2ba68": {
    name: "transferERC20",
    signature: "transferERC20(address,address,uint256,uint256)",
    input_types: ["address", "address", "uint256", "uint256"],
    output_aliases: ["to_a", "to_b", "amount_c", "amount_d"],
  },
  "0x2e7ba6ef": {
    name: "claim",
    signature: "claim(uint256,address,uint256,bytes32[])",
    input_types: ["uint256", "address", "uint256", "bytes32[]"],
    output_aliases: ["amount_a", "to_b", "amount_c", "b32s_d"],
  },
  "0x51dbb2a7": {
    name: "selectiveDeposit",
    signature: "selectiveDeposit(address[],uint256[],uint256,uint256)",
    input_types: ["address[]", "uint256[]", "uint256", "uint256"],
    output_aliases: ["addresses_a", "amounts_b", "amount_c", "amount_d"],
  },
  "0xa415bcad": {
    name: "borrow",
    signature: "borrow(address,uint256,uint256,uint16,address)",
    input_types: ["address", "uint256", "uint256", "uint16", "address"],
    output_aliases: ["to_a", "amount_b", "amount_c", "num_d", "to_e"],
  },
  "0x2eb2c2d6": {
    name: "safeBatchTransferFrom",
    signature:
      "safeBatchTransferFrom(address,address,uint256[],uint256[],bytes)",
    input_types: ["address", "address", "uint256[]", "uint256[]", "bytes"],
    output_aliases: ["to_a", "to_b", "amounts_c", "amounts_d", "bytes_e"],
  },
  "0xd2ce7d65": {
    name: "outboundTransfer",
    signature:
      "outboundTransfer(address,address,uint256,uint256,uint256,bytes)",
    input_types: [
      "address",
      "address",
      "uint256",
      "uint256",
      "uint256",
      "bytes",
    ],
    output_aliases: [
      "to_a",
      "to_b",
      "amount_c",
      "amount_d",
      "amount_e",
      "bytes_f",
    ],
  },
  "0xa9fd8c57": {
    name: "depositETHAndSwap",
    signature:
      "depositETHAndSwap(address,uint256,uint256,uint8,uint8,uint256,uint256)",
    input_types: [
      "address",
      "uint256",
      "uint256",
      "uint8",
      "uint8",
      "uint256",
      "uint256",
    ],
    output_aliases: [
      "to_a",
      "amount_b",
      "amount_c",
      "num_d",
      "num_e",
      "amount_f",
      "amount_g",
    ],
  },
  "0x8eb4e0ad": {
    name: "batchRelay",
    signature:
      "batchRelay(bytes16[],address[],address[],uint256[],uint16[],uint128[],uint8[],bytes32[],bytes32[],uint8[])",
    input_types: [
      "bytes16[]",
      "address[]",
      "address[]",
      "uint256[]",
      "uint16[]",
      "uint128[]",
      "uint8[]",
      "bytes32[]",
      "bytes32[]",
      "uint8[]",
    ],
    output_aliases: [
      "b16s_a",
      "addresses_b",
      "addresses_c",
      "amounts_d",
      "nums_e",
      "amounts_f",
      "nums_g",
      "b32s_h",
      "b32s_i",
      "nums_j",
    ],
  },
  // --------------------------------------

  "0x2fb42076": {
    name: "claimV2",
    signature: "claimV2()",
    input_types: [],
    output_aliases: [],
  },
  "0x049c5c49": {
    name: "toggleSaleStatus",
    signature: "toggleSaleStatus()",
    input_types: [],
    output_aliases: [],
  },
  "0x00000002": {
    name: "razor_balance",
    signature: "razor_balance(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x422df36b": {
    name: "deactivateOrReduceEditionSupply",
    signature: "deactivateOrReduceEditionSupply(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x92642744": {
    name: "mintNFT",
    signature: "mintNFT(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xf14210a6": {
    name: "withdrawETH",
    signature: "withdrawETH(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x95a2251f": {
    name: "redeem",
    signature: "redeem(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xf2fde38b": {
    name: "transferOwnership",
    signature: "transferOwnership(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xe054ffc3": {
    name: "whitelistUser",
    signature: "whitelistUser(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x76f2a59a": {
    name: "instantMint",
    signature: "instantMint(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x2440fdc2": {
    name: "makeDeposit",
    signature: "makeDeposit(address,uint256,uint256)",
    input_types: ["address", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "amount_c"],
  },
  "0xc0f4ed31": {
    name: "bid",
    signature: "bid(uint256,address,uint256)",
    input_types: ["uint256", "address", "uint256"],
    output_aliases: ["amount_a", "to_b", "amount_c"],
  },
  "0x0ad58d2f": {
    name: "withdraw",
    signature: "withdraw(uint256,uint256,address)",
    input_types: ["uint256", "uint256", "address"],
    output_aliases: ["amount_a", "amount_b", "to_c"],
  },
  "0x384e03db": {
    name: "add_liquidity",
    signature: "add_liquidity(address,uint256[4],uint256)",
    input_types: ["address", "uint256[4]", "uint256"],
    output_aliases: ["to", "amounts", "amount"],
  },
  "0x58fdb101": {
    name: "transferToUserWithFee",
    signature: "transferToUserWithFee(address,uint256,bytes32,bytes)",
    input_types: ["address", "uint256", "bytes32", "bytes"],
    output_aliases: ["to", "amount", "b32", "bytes"],
  },
  "0x6af479b2": {
    name: "sellTokenForTokenToUniswapV3",
    signature: "sellTokenForTokenToUniswapV3(bytes,uint256,uint256,address)",
    input_types: ["bytes", "uint256", "uint256", "address"],
    output_aliases: ["bytes_a", "amount_b", "amount_c", "to_d"],
  },
  "0x29941edd": {
    name: "proposeRoot",
    signature: "proposeRoot(bytes32,bytes32,uint256,uint256,uint256)",
    input_types: ["bytes32", "bytes32", "uint256", "uint256", "uint256"],
    output_aliases: ["b32_a", "b32_b", "amount_c", "amount_d", "amount_e"],
  },
  "0xe8e33700": {
    name: "addLiquidity",
    signature:
      "addLiquidity(address,address,uint256,uint256,uint256,uint256,address,uint256)",
    input_types: [
      "address",
      "address",
      "uint256",
      "uint256",
      "uint256",
      "uint256",
      "address",
      "uint256",
    ],
    output_aliases: [
      "to_a",
      "to_b",
      "amount_c",
      "amount_d",
      "amount_e",
      "amount_f",
      "to_g",
      "amount_h",
    ],
  },
  "0x8054ae3b": {
    name: "zapAndDepositAndSwap",
    signature:
      "zapAndDepositAndSwap(address,uint256,address,uint256[],uint256,uint256,uint8,uint8,uint256,uint256)",
    input_types: [
      "address",
      "uint256",
      "address",
      "uint256[]",
      "uint256",
      "uint256",
      "uint8",
      "uint8",
      "uint256",
      "uint256",
    ],
    output_aliases: [
      "to_a",
      "amount_b",
      "to_c",
      "amounts_d",
      "amount_e",
      "amount_f",
      "num_g",
      "num_h",
      "amount_i",
      "amount_j",
    ],
  },
  // --------------------------------------

  "0xc2c4c5c1": {
    name: "checkpoint",
    signature: "checkpoint()",
    input_types: [],
    output_aliases: [],
  },
  "0x2db11544": {
    name: "publicMint",
    signature: "publicMint(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xc4cf867c": {
    name: "claimVestedReward",
    signature: "claimVestedReward(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x285e7bfd": {
    name: "newSmartWallet",
    signature: "newSmartWallet(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xbcc0d415": {
    name: "updateSwapCompletionStatus",
    signature: "updateSwapCompletionStatus(bytes32,bool)",
    input_types: ["bytes32", "bool"],
    output_aliases: ["b32", "bool"],
  },
  "0x51a60b02": {
    name: "withdrawDelegated",
    signature: "withdrawDelegated(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0x2d67f0b9": {
    name: "removeStake",
    signature: "removeStake(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xca800144": {
    name: "giveAway",
    signature: "giveAway(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x80500d20": {
    name: "withdrawETH",
    signature: "withdrawETH(address,uint256,address)",
    input_types: ["address", "uint256", "address"],
    output_aliases: ["to_a", "amount_b", "to_c"],
  },
  "0xec126c77": {
    name: "Swapin",
    signature: "Swapin(bytes32,address,uint256)",
    input_types: ["bytes32", "address", "uint256"],
    output_aliases: ["b32", "to", "amount"],
  },
  "0x3fe317a6": {
    name: "verifyMerkle",
    signature: "verifyMerkle(uint256[],uint256[],uint256,uint256)",
    input_types: ["uint256[]", "uint256[]", "uint256", "uint256"],
    output_aliases: ["amounts_a", "amounts_b", "amount_c", "amount_d"],
  },
  "0x993e1c42": {
    name: "withdrawERC20For",
    signature: "withdrawERC20For(uint256,address,address,uint256,bytes)",
    input_types: ["uint256", "address", "address", "uint256", "bytes"],
    output_aliases: ["amount_a", "to_b", "to_c", "amount_d", "bytes_e"],
  },
  "0x5ef2c7f0": {
    name: "setSubnodeRecord",
    signature: "setSubnodeRecord(bytes32,bytes32,address,address,uint64)",
    input_types: ["bytes32", "bytes32", "address", "address", "uint64"],
    output_aliases: ["b32_a", "b32_b", "to_c", "to_d", "num_e"],
  },
  "0xab9c4b5d": {
    name: "flashLoan",
    signature:
      "flashLoan(address,address[],uint256[],uint256[],address,bytes,uint16)",
    input_types: [
      "address",
      "address[]",
      "uint256[]",
      "uint256[]",
      "address",
      "bytes",
      "uint16",
    ],
    output_aliases: [
      "to_a",
      "addresses_b",
      "amounts_c",
      "amounts_d",
      "to_e",
      "bytes_f",
      "num_g",
    ],
  },
  // --------------------------------------

  "0xe9866550": {
    name: "setStartingIndex",
    signature: "setStartingIndex()",
    input_types: [],
    output_aliases: [],
  },
  "0x159343c9": {
    name: "roofSlap",
    signature: "roofSlap(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xed8f584b": {
    name: "receiveTokensFromSource",
    signature: "receiveTokensFromSource(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x106d08df": {
    name: "scheduleUnstake",
    signature: "scheduleUnstake(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xc634d032": {
    name: "mintToken",
    signature: "mintToken(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x1c4b774b": {
    name: "getReward",
    signature: "getReward(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x454b0608": {
    name: "migrate",
    signature: "migrate(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x38463cff": {
    name: "burn",
    signature: "burn(bytes,uint256)",
    input_types: ["bytes", "uint256"],
    output_aliases: ["bytes", "amount"],
  },
  "0x03ec16d7": {
    name: "updateReserveAuction",
    signature: "updateReserveAuction(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x3f801f91": {
    name: "proxyAssert",
    signature: "proxyAssert(address,uint8,bytes)",
    input_types: ["address", "uint8", "bytes"],
    output_aliases: ["to", "num", "bytes"],
  },
  "0xcae9ca51": {
    name: "approveAndCall",
    signature: "approveAndCall(address,uint256,bytes)",
    input_types: ["address", "uint256", "bytes"],
    output_aliases: ["to", "amount", "bytes"],
  },
  "0x474cf53d": {
    name: "depositETH",
    signature: "depositETH(address,address,uint16)",
    input_types: ["address", "address", "uint16"],
    output_aliases: ["to_a", "to_b", "num_c"],
  },
  "0xa41fe49f": {
    name: "withdraw",
    signature: "withdraw(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0x22895118": {
    name: "deposit",
    signature: "deposit(bytes,bytes,bytes,bytes32)",
    input_types: ["bytes", "bytes", "bytes", "bytes32"],
    output_aliases: ["bytes_a", "bytes_b", "bytes_c", "b32_d"],
  },
  "0x803ba26d": {
    name: "sellTokenForEthToUniswapV3",
    signature: "sellTokenForEthToUniswapV3(bytes,uint256,uint256,address)",
    input_types: ["bytes", "uint256", "uint256", "address"],
    output_aliases: ["bytes_a", "amount_b", "amount_c", "to_d"],
  },
  "0x6f652e1a": {
    name: "createOrder",
    signature: "createOrder(address,uint256,uint256,uint256)",
    input_types: ["address", "uint256", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "amount_c", "amount_d"],
  },
  "0xaacaaf88": {
    name: "execute",
    signature: "execute(address,bytes,uint256,bytes,uint256,uint256)",
    input_types: ["address", "bytes", "uint256", "bytes", "uint256", "uint256"],
    output_aliases: [
      "to_a",
      "bytes_b",
      "amount_c",
      "bytes_d",
      "amount_e",
      "amount_f",
    ],
  },
  "0x9d73b325": {
    name: "originSwap",
    signature: "originSwap(address,address,address,uint256,uint256,uint256)",
    input_types: [
      "address",
      "address",
      "address",
      "uint256",
      "uint256",
      "uint256",
    ],
    output_aliases: [
      "to_a",
      "to_b",
      "to_c",
      "amount_d",
      "amount_e",
      "amount_f",
    ],
  },
  "0x89bbb8b2": {
    name: "poke",
    signature: "poke(uint256[],uint256[],uint8[],bytes32[],bytes32[])",
    input_types: [
      "uint256[]",
      "uint256[]",
      "uint8[]",
      "bytes32[]",
      "bytes32[]",
    ],
    output_aliases: ["amounts_a", "amounts_b", "nums_c", "data_d", "data_e"],
  },
  "0xf305d719": {
    name: "addLiquidityETH",
    signature:
      "addLiquidityETH(address,uint256,uint256,uint256,address,uint256)",
    input_types: [
      "address",
      "uint256",
      "uint256",
      "uint256",
      "address",
      "uint256",
    ],
    output_aliases: [
      "token",
      "amountTokenDesired",
      "amountTokenMin",
      "amountETHMin",
      "to",
      "deadline",
    ],
  },
  "0x0f856267": {
    name: "smgRelease",
    signature:
      "smgRelease(bytes32,bytes32,uint256,uint256,address,address,bytes,bytes32)",
    input_types: [
      "bytes32",
      "bytes32",
      "uint256",
      "uint256",
      "address",
      "address",
      "bytes",
      "bytes32",
    ],
    output_aliases: [
      "b32_a",
      "b32_b",
      "amount_c",
      "amount_d",
      "to_e",
      "to_f",
      "bytes_g",
      "b32_h",
    ],
  },
  "0x6a761202": {
    name: "execTransaction",
    signature:
      "execTransaction(address,uint256,bytes,uint8,uint256,uint256,uint256,address,address,bytes)",
    input_types: [
      "address",
      "uint256",
      "bytes",
      "uint8",
      "uint256",
      "uint256",
      "uint256",
      "address",
      "address",
      "bytes",
    ],
    output_aliases: [
      "to_a",
      "amount_b",
      "bytes_c",
      "num_d",
      "amount_e",
      "amount_f",
      "amount_g",
      "to_h",
      "to_i",
      "bytes_j",
    ],
  },
  // --------------------------------------
  "0x98e5b12a": {
    name: "requestNewRound",
    signature: "requestNewRound()",
    input_types: [],
    output_aliases: [],
  },
  "0x02329a29": {
    name: "pause",
    signature: "pause(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x9ae2369d": {
    name: "syncBalance",
    signature: "syncBalance(uint32)",
    input_types: ["uint32"],
    output_aliases: ["num"],
  },
  "0xf578d9df": {
    name: "mainMint",
    signature: "mainMint(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xb030b34a": {
    name: "removeBotFromBlacklist",
    signature: "removeBotFromBlacklist(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x19165587": {
    name: "release",
    signature: "release(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x983d95ce": {
    name: "withdraw",
    signature: "withdraw(uint256[])",
    input_types: ["uint256[]"],
    output_aliases: ["amounts"],
  },
  "0xf64b5f44": {
    name: "clientDepositL1",
    signature: "clientDepositL1(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0x669f5a51": {
    name: "mintNFT",
    signature: "mintNFT(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0x859b97fe": {
    name: "cancelAuction",
    signature: "cancelAuction(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x5b0fc9c3": {
    name: "setOwner",
    signature: "setOwner(bytes32,address)",
    input_types: ["bytes32", "address"],
    output_aliases: ["b32", "to"],
  },
  // "0x51d0eac4": {
  //   //TODO: check, thrown previously
  //   name: "sendEths",
  //   signature: "sendEths(address[],uint256[])",
  //   input_types: ["address[]", "uint256[]"],
  //   output_aliases: ["addresses", "amounts"],
  // },
  "0x3598d8ab": {
    name: "sellEthForTokenToUniswapV3",
    signature: "sellEthForTokenToUniswapV3(bytes,uint256,address)",
    input_types: ["bytes", "uint256", "address"],
    output_aliases: ["bytes", "amount", "to"],
  },
  "0x10f13a8c": {
    name: "setText",
    signature: "setText(bytes32,string,string)",
    input_types: ["bytes32", "string", "string"],
    output_aliases: ["b32_a", "string_b", "string_c"],
  },
  "0x30e6822c": {
    name: "transferFromChain",
    signature: "transferFromChain(address,uint256,uint256)",
    input_types: ["address", "uint256", "uint256"],
    output_aliases: ["to_a", "amount_b", "amount_c"],
  },
  "0xad58bdd1": {
    name: "relayTokens",
    signature: "relayTokens(address,address,uint256)",
    input_types: ["address", "address", "uint256"],
    output_aliases: ["to_a", "to_b", "amount_c"],
  },
  "0x00aeef8a": {
    name: "deposit",
    signature: "deposit(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0xc73a2d60": {
    name: "disperseToken",
    signature: "disperseToken(address,address[],uint256[])",
    input_types: ["address", "address[]", "uint256[]"],
    output_aliases: ["to", "addresses", "amounts"],
  },
  "0x731133e9": {
    name: "mint",
    signature: "mint(address,uint256,uint256,bytes)",
    input_types: ["address", "uint256", "uint256", "bytes"],
    output_aliases: ["to_a", "amount_b", "amount_c", "bytes_d"],
  },
  "0xb6f9de95": {
    name: "swapExactETHForTokensSupportingFeeOnTransferTokens",
    signature:
      "swapExactETHForTokensSupportingFeeOnTransferTokens(uint256,address[],address,uint256)",
    input_types: ["uint256", "address[]", "address", "uint256"],
    output_aliases: ["amount_a", "addresses_b", "to_c", "amount_d"],
  },
  "0x0175b1c4": {
    name: "anySwapInAuto",
    signature: "anySwapInAuto(bytes32,address,address,uint256,uint256)",
    input_types: ["bytes32", "address", "address", "uint256", "uint256"],
    output_aliases: ["b32_a", "to_b", "to_c", "amount_d", "amount_e"],
  },
  "0x4a25d94a": {
    name: "swapTokensForExactETH",
    signature:
      "swapTokensForExactETH(uint256,uint256,address[],address,uint256)",
    input_types: ["uint256", "uint256", "address[]", "address", "uint256"],
    output_aliases: ["amountOut", "amountInMax", "path", "to", "deadline"],
  },
  "0x8803dbee": {
    name: "swapTokensForExactTokens",
    signature:
      "swapTokensForExactTokens(uint256,uint256,address[],address,uint256)",
    input_types: ["uint256", "uint256", "address[]", "address", "uint256"],
    output_aliases: ["amountOut", "amountInMax", "path", "to", "deadline"],
  },
  "0xb77d239b": {
    name: "convertByPath",
    signature:
      "convertByPath(address[],uint256,uint256,address,address,uint256)",
    input_types: [
      "address[]",
      "uint256",
      "uint256",
      "address",
      "address",
      "uint256",
    ],
    output_aliases: [
      "addresses_a",
      "amount_b",
      "amount_c",
      "to_d",
      "to_e",
      "amount_f",
    ],
  },
  "0xe0724b6e": {
    name: "execute",
    signature:
      "execute(address,bytes,uint256,bytes,uint256,uint256,address,address)",
    input_types: [
      "address",
      "bytes",
      "uint256",
      "bytes",
      "uint256",
      "uint256",
      "address",
      "address",
    ],
    output_aliases: [
      "to_a",
      "bytes_b",
      "amount_c",
      "bytes_d",
      "amount_e",
      "amount_f",
      "to_g",
      "to_h",
    ],
  },
  // --------------------------------------
  "0xfd9eacd2": {
    name: "receivePayments",
    signature: "receivePayments()",
    input_types: [],
    output_aliases: [],
  },
  "0x4baf782e": {
    name: "CLAIMREWARD",
    signature: "CLAIMREWARD()",
    input_types: [],
    output_aliases: [],
  },
  "0xfcd8fdc9": {
    name: "mintTokens",
    signature: "mintTokens(uint16)",
    input_types: ["uint16"],
    output_aliases: ["num"],
  },
  "0xa26e1186": {
    name: "deposit",
    signature: "deposit(string)",
    input_types: ["string"],
    output_aliases: ["string"],
  },
  "0xb3ab66b0": {
    name: "publicSaleMint",
    signature: "publicSaleMint(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x2304f24a": {
    name: "updateTimelockStatus",
    signature: "updateTimelockStatus(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x033c1693": {
    name: "revealBaby",
    signature: "revealBaby(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x2e17de78": {
    name: "unstake",
    signature: "unstake(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xa9e7c2e5": {
    name: "claimToken",
    signature: "claimToken(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x6ba4c138": {
    name: "claim",
    signature: "claim(uint256[])",
    input_types: ["uint256[]"],
    output_aliases: ["amounts"],
  },
  "0xc32e7202": {
    name: "withdrawAndUnwrap",
    signature: "withdrawAndUnwrap(uint256,bool)",
    input_types: ["uint256", "bool"],
    output_aliases: ["amount", "bool"],
  },
  "0x750d4926": {
    name: "claim_reward",
    signature: "claim_reward(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0x630d8c63": {
    name: "claimBalance",
    signature: "claimBalance(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xa94c7c65": {
    name: "limitedPrint",
    signature: "limitedPrint(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xd2fafb19": {
    name: "setAmount",
    signature: "setAmount(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x4690d55a": {
    name: "addSubmission",
    signature: "addSubmission(string,string)",
    input_types: ["string", "string"],
    output_aliases: ["string_a", "string_b"],
  },
  "0x952e68cf": {
    name: "stake",
    signature: "stake(uint256,uint64)",
    input_types: ["uint256", "uint64"],
    output_aliases: ["amount", "num"],
  },
  "0x3c168eab": {
    name: "mintNFT",
    signature: "mintNFT(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xd9393814": {
    name: "unStake",
    signature: "unStake(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x628d6cba": {
    name: "Swapout",
    signature: "Swapout(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0x8568523a": {
    name: "transferToOwner",
    signature: "transferToOwner(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x21c0b342": {
    name: "claim",
    signature: "claim(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0x7cbc2373": {
    name: "redeem",
    signature: "redeem(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x9dc29fac": {
    name: "burn",
    signature: "burn(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x3f7658fd": {
    name: "executeSignatures",
    signature: "executeSignatures(bytes,bytes)",
    input_types: ["bytes", "bytes"],
    output_aliases: ["bytes_a", "bytes_b"],
  },
  "0x82f87117": {
    name: "claimVolts",
    signature: "claimVolts(uint256,bytes)",
    input_types: ["uint256", "bytes"],
    output_aliases: ["amount", "bytes"],
  },

  "0x70135f52": {
    name: "confirmGuardianAddition",
    signature: "confirmGuardianAddition(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["to_a", "to_b"],
  },
  "0xa140ae23": {
    name: "mintToken",
    signature: "mintToken(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0x83a076be": {
    name: "gift",
    signature: "gift(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0xc44193c3": {
    name: "offerPunkForSale",
    signature: "offerPunkForSale(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x1e9a6950": {
    name: "redeem",
    signature: "redeem(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xe2bbb158": {
    name: "deposit",
    signature: "deposit(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  // --------------------------------------

  "0x3cd29ac8": {
    name: "preMint",
    signature: "preMint()",
    input_types: [],
    output_aliases: [],
  },
  "0x3805550f": {
    name: "exit",
    signature: "exit(bytes)",
    input_types: ["bytes"],
    output_aliases: ["bytes"],
  },
  "0xc290d691": {
    name: "pay",
    signature: "pay(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x46e04a2f": {
    name: "claimTokens",
    signature: "claimTokens(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x5c19a95c": {
    name: "delegate",
    signature: "delegate(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x194f9261": {
    name: "redeemJuniorTokens",
    signature: "redeemJuniorTokens()",
    input_types: [],
    output_aliases: [],
  },
  "0xacb5fedf": {
    name: "arb",
    signature: "arb()",
    input_types: [],
    output_aliases: [],
  },
  "0xd0f89344": {
    name: "appendSequencerBatch",
    signature: "appendSequencerBatch()",
    input_types: [],
    output_aliases: [],
  },
  "0xbedb86fb": {
    name: "setPause",
    signature: "setPause(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0xaf27073e": {
    name: "PresaleMint",
    signature: "PresaleMint(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x63bba05f": {
    name: "addAndExtendStaking",
    signature: "addAndExtendStaking(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xf8976c9b": {
    name: "removeWhitelistAllowance",
    signature: "removeWhitelistAllowance(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x8ca887ca": {
    name: "saleMint",
    signature: "saleMint(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xfdfba25d": {
    name: "sendEthToSidechain",
    signature: "sendEthToSidechain(bytes32)",
    input_types: ["bytes32"],
    output_aliases: ["b32"],
  },
  "0x0ed2f344": {
    name: "mintAstroGator",
    signature: "mintAstroGator(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xab00492f": {
    name: "multiMint",
    signature: "multiMint(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xf759867a": {
    name: "mintPresale",
    signature: "mintPresale(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x7b99adb1": {
    name: "setLiquidityCap",
    signature: "setLiquidityCap(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x0962ef79": {
    name: "claimRewards",
    signature: "claimRewards(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x5f6be614": {
    name: "migrateToken",
    signature: "migrateToken(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x9ee679e8": {
    name: "requestWithdrawal",
    signature: "requestWithdrawal(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x1f5e881f": {
    name: "_buyTokens",
    signature: "_buyTokens(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x091dbfd2": {
    name: "enterBidForPunk",
    signature: "enterBidForPunk(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x9979ef45": {
    name: "placeBid",
    signature: "placeBid(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xc01a8c84": {
    name: "confirmTransaction",
    signature: "confirmTransaction(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xdb006a75": {
    name: "redeem",
    signature: "redeem(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x1c1b8772": {
    name: "update",
    signature: "update(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xc2998238": {
    name: "enterMarkets",
    signature: "enterMarkets(address[])",
    input_types: ["address[]"],
    output_aliases: ["addresses"],
  },
  // --------------------------------------

  "0x68b85fb1": {
    name: "breed",
    signature: "breed()",
    input_types: [],
    output_aliases: [],
  },
  "0xce80a9a1": {
    name: "claimID",
    signature: "claimID(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xe6d66ac8": {
    name: "sendTokens",
    signature: "sendTokens(address,address,uint256)",
    input_types: ["address", "address", "uint256"],
    output_aliases: ["from", "to", "amount"],
  },
  "0x30ead760": {
    name: "exchangeWithTracking",
    signature: "exchangeWithTracking(bytes32,uint256,bytes32,address,bytes32)",
    input_types: ["bytes32", "uint256", "bytes32", "address", "bytes32"],
    output_aliases: ["a", "amount", "b", "to", "c"],
  },
  "0xf4265715": {
    name: "unmine",
    signature: "unmine(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x12edde5e": {
    name: "initiateWithdrawal",
    signature: "initiateWithdrawal(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xf3701da2": {
    name: "build",
    signature: "build(address)",
    input_types: ["address"],
    output_aliases: ["address"],
  },
  "0x4641257d": {
    name: "harvest",
    signature: "harvest()",
    input_types: [],
    output_aliases: [],
  },
  "0xd1058e59": {
    name: "claimAll",
    signature: "claimAll()",
    input_types: [],
    output_aliases: [],
  },
  "0xb73c6ce9": {
    name: "withdrawEarnings",
    signature: "withdrawEarnings()",
    input_types: [],
    output_aliases: [],
  },
  "0x8b9e4f93": {
    name: "depositERC20ForUser",
    signature: "depositERC20ForUser(address,address,uint256)",
    input_types: ["address", "address", "uint256"],
    output_aliases: ["from", "to", "amount"],
  },
  "0xbb8f257b": {
    name: "setStealthKeys",
    signature: "setStealthKeys(uint256,uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256", "uint256"],
    output_aliases: ["a", "b", "c", "d"],
  },
  "0x026e402b": {
    name: "delegate",
    signature: "delegate(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xf25efffc": {
    name: "settleCurrentAndCreateNewAuction",
    signature: "settleCurrentAndCreateNewAuction()",
    input_types: [],
    output_aliases: [],
  },
  "0xe4a76726": {
    name: "addLiquidity",
    signature: "addLiquidity(address,address,uint256)",
    input_types: ["address", "address", "uint256"],
    output_aliases: ["from", "to", "amount"],
  },
  "0xa5e38751": {
    name: "deploy",
    signature: "deploy(uint256)",
    input_types: ["uint256"],
    output_aliases: ["data"],
  },
  "0x11ebbf24": {
    name: "createWallet",
    signature: "createWallet()",
    input_types: [],
    output_aliases: [],
  },
  "0xda89a7b1": {
    name: "redeemRebalancingSet",
    signature: "redeemRebalancingSet(address,uint256,bool)",
    input_types: ["address", "uint256", "bool"],
    output_aliases: ["to", "amount", "bool"],
  },
  "0xd9856c21": {
    name: "addNewToken",
    signature: "addNewToken(string)",
    input_types: ["string"],
    output_aliases: ["token"],
  },
  "0x85eb3a35": {
    name: "depositERC20For",
    signature: "depositERC20For(address,address,uint256)",
    input_types: ["address", "address", "uint256"],
    output_aliases: ["from", "to", "amount"],
  },
  "0xa6417ed6": {
    name: "exchange_underlying",
    signature: "exchange_underlying(int128,int128,uint256,uint256)",
    input_types: ["int128", "int128", "uint256", "uint256"],
    output_aliases: ["a", "b", "amount_a", "amount_b"],
  },
  "0x96b5a755": {
    name: "cancelAuction",
    signature: "cancelAuction(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xc2db2c42": {
    name: "purchaseToken",
    signature: "purchaseToken(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x42966c68": {
    name: "burn",
    signature: "burn(uint256)",
    input_types: ["uint256"],
    output_aliases: ["percent"],
  },
  "0x3d2b4a01": {
    name: "initiateBreeding",
    signature: "initiateBreeding(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0x379607f5": {
    name: "claim",
    signature: "claim(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xb9884772": {
    name: "mintPreSale",
    signature: "mintPreSale(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x2195995c": {
    name: "removeLiquidityWithPermit",
    signature:
      "removeLiquidityWithPermit(address,address,uint256,uint256,uint256,address,uint256,bool,uint8,bytes32,bytes32)",
    input_types: [
      "address",
      "address",
      "uint256",
      "uint256",
      "uint256",
      "address",
      "uint256",
      "bool",
      "uint8",
      "bytes32",
      "bytes32",
    ],
    output_aliases: [
      "add_a",
      "add_b",
      "a",
      "b",
      "c",
      "add_c",
      "uint256",
      "bool",
      "num",
      "b32_a",
      "b32_b",
    ],
  },
  "0x459144fb": {
    name: "mintTokenOgSale",
    signature: "mintTokenOgSale(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x3e200d4b": {
    name: "stakeRPL",
    signature: "stakeRPL(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x9981509f": {
    name: "wrapAndTransferETH",
    signature: "wrapAndTransferETH(uint16,bytes32,uint256,uint32)",
    input_types: ["uint16", "bytes32", "uint256", "uint32"],
    output_aliases: ["a", "b32", "amount", "c"],
  },
  "0x1feed31f": {
    name: "redeem",
    signature: "redeem(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x2a32096c": {
    name: "mintNerdyNuggets",
    signature: "mintNerdyNuggets(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xb07f0a41": {
    name: "exercise",
    signature: "exercise(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x0752881a": {
    name: "buyTokens",
    signature: "buyTokens(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xb88a802f": {
    name: "claimReward",
    signature: "claimReward()",
    input_types: [],
    output_aliases: [],
  },
  "0x5c11d795": {
    name: "swapExactTokensForTokensSupportingFeeOnTransferTokens",
    signature:
      "swapExactTokensForTokensSupportingFeeOnTransferTokens(uint256,uint256,address[],address,uint256)",
    input_types: ["uint256", "uint256", "address[]", "address", "uint256"],
    output_aliases: ["amount_a", "amount_b", "addresses", "to", "amount_c"],
  },
  "0x7b44918b": {
    name: "adoptNLadyApes",
    signature: "adoptNLadyApes(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x454a2ab3": {
    name: "bid",
    signature: "bid(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xa723533e": {
    name: "mintApe",
    signature: "mintApe(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xa1903eab": {
    name: "submit",
    signature: "submit(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x78ea6103": {
    name: "claimBuyOrder",
    signature: "claimBuyOrder(address,uint256,address)",
    input_types: ["address", "uint256", "address"],
    output_aliases: ["address_a", "amount", "address_b"],
  },
  "0xb58b98e4": {
    name: "buyTokenOnSale",
    signature: "buyTokenOnSale(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0xdc788a24": {
    name: "cancelSwapIntent",
    signature: "cancelSwapIntent(uint256)",
    input_types: ["uint256"],
    output_aliases: ["intent"],
  },
  "0xa59f3e0c": {
    name: "enter",
    signature: "enter(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x8b72a2ec": {
    name: "transferPunk",
    signature: "transferPunk(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "id"],
  },
  "0x91b7f5ed": {
    name: "setPrice",
    signature: "setPrice(uint256)",
    input_types: ["uint256"],
    output_aliases: ["price"],
  },
  "0xe6ab1434": {
    name: "presale",
    signature: "presale(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x39f47693": {
    name: "unwrap",
    signature: "unwrap(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0x4ce6931a": {
    name: "createReserveAuction",
    signature: "createReserveAuction(address,uint256,uint256)",
    input_types: ["address", "uint256", "uint256"],
    output_aliases: ["to", "amount_a", "amount_b"],
  },
  "0x5a7b87f2": {
    name: "claimRewards",
    signature:
      "claimRewards(address[],address[],address[],address[],uint256,uint256,uint256,uint256,uint256)",
    input_types: [
      "address[]",
      "address[]",
      "address[]",
      "address[]",
      "uint256",
      "uint256",
      "uint256",
      "uint256",
      "uint256",
    ],
    output_aliases: [
      "adds_a",
      "adds_b",
      "adds_c",
      "adds_d",
      "a",
      "b",
      "c",
      "d",
      "e",
    ],
  },
  "0x7050ccd9": {
    name: "getReward",
    signature: "getReward(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["to", "bool"],
  },
  "0x2e95b6c8": {
    name: "unoswap",
    signature: "unoswap(address,uint256,uint256,bytes32[])",
    input_types: ["address", "uint256", "uint256", "bytes32[]"],
    output_aliases: ["to", "amount_a", "amount_b", "b32s"],
  },
  "0xc358575c": {
    name: "mintBear",
    signature: "mintBear(uint256))",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x1e73ff4f": {
    name: "recruit",
    signature: "recruit(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x9e0a397e": {
    name: "adoptApes",
    signature: "adoptApes(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xbcf64e05": {
    name: "burn",
    signature: "burn(uint256,bytes32)",
    input_types: ["uint256", "bytes32"],
    output_aliases: ["amount", "b32"],
  },
  "0xfa05a657": {
    name: "mintAllowList",
    signature: "mintAllowList(uint256,bytes32[])",
    input_types: ["uint256", "bytes32[]"],
    output_aliases: ["amount", "b32s"],
  },
  "0x9373f432": {
    name: "setWithdrawWallet",
    signature: "setWithdrawWallet(address)",
    input_types: ["address"],
    output_aliases: ["address"],
  },
  "0x852a12e3": {
    name: "redeemUnderlying",
    signature: "redeemUnderlying(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  //   "0x0000000f": {
  //     name: "eldddhzr",
  //     signature: "eldddhzr(uint256[])",
  //     input_types: ["uint256[]"],
  //     output_aliases: ["amounts"],
  //   },
  "0x81281be8": {
    name: "placeBid",
    signature: "placeBid(address,uint256,uint256,uint256)",
    input_types: ["address", "uint256", "uint256", "uint256"],
    output_aliases: ["address", "amount_a", "amount_b", "amount_c"],
  },
  "0xf7654176": {
    name: "split",
    signature: "split()",
    input_types: [],
    output_aliases: [],
  },
  "0xe3476cd4": {
    name: "takeDebtSnapshot",
    signature: "takeDebtSnapshot()",
    input_types: [],
    output_aliases: [],
  },
  "0xd9627aa4": {
    name: "sellToUniswap",
    signature: "sellToUniswap(address[],uint256,uint256,bool)",
    input_types: ["address[]", "uint256", "uint256", "bool"],
    output_aliases: ["addresses", "amount_a", "amount_b", "bool"],
  },
  "0x51c547f8": {
    name: "processRewards",
    signature: "processRewards(bool)",
    input_types: ["bool"],
    output_aliases: ["bool"],
  },
  "0x67f68fac": {
    name: "mint",
    signature: "mint(uint256,bool)",
    input_types: ["uint256", "bool"],
    output_aliases: ["id", "bool"],
  },
  "0x538a85a1": {
    name: "farm",
    signature: "farm(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x343009a2": {
    name: "stakeEnd",
    signature: "stakeEnd(uint256,uint40)",
    input_types: ["uint256", "uint40"],
    output_aliases: ["amount", "a"],
  },
  "0x3df02124": {
    name: "exchange",
    signature: "exchange(int128,int128,uint256,uint256)",
    input_types: ["int128", "int128", "uint256", "uint256"],
    output_aliases: ["a", "b", "amount_a", "amount_b"],
  },
  "0x0e704d50": {
    name: "closeTrove",
    signature: "closeTrove()",
    input_types: [],
    output_aliases: [],
  },
  "0x6ecd2306": {
    name: "mint",
    signature: "mint(uint8)",
    input_types: ["uint8"],
    output_aliases: ["amount"],
  },
  "0x672756ad": {
    name: "claimNFT",
    signature: "claimNFT()",
    input_types: [],
    output_aliases: [],
  },
  "0xeaba3fd7": {
    name: "steppableMint",
    signature: "steppableMint(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x1801fbe5": {
    name: "mint",
    signature: "mint(uint256,bytes32)",
    input_types: ["uint256", "bytes32"],
    output_aliases: ["amount", "b32"],
  },
  "0x42842e0e": {
    name: "safeTransferFrom",
    signature: "safeTransferFrom(address,address,uint256)",
    input_types: ["address", "address", "uint256"],
    output_aliases: ["from", "to", "amount"],
  },
  "0xa68a76cc": {
    name: "createForwarder",
    signature: "createForwarder()",
    input_types: [],
    output_aliases: [],
  },
  "0x7acb7757": {
    name: "stake",
    signature: "stake(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0xe449022e": {
    name: "uniswapV3Swap",
    signature: "uniswapV3Swap(uint256,uint256,uint256[])",
    input_types: ["uint256", "uint256", "uint256[]"],
    output_aliases: ["from", "to", "amounts"],
  },
  "0x853828b6": {
    name: "withdrawAll",
    signature: "withdrawAll()",
    input_types: [],
    output_aliases: [],
  },
  "0x39509351": {
    name: "increaseAllowance",
    signature: "increaseAllowance(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["spender", "amount"],
  },
  "0xfe029156": {
    name: "swap",
    signature: "swap(address,address,uint256,uint256)",
    input_types: ["address", "address", "uint256", "uint256"],
    output_aliases: ["address_a", "address_b", "amount_a", "amount_b"],
  },
  "0x65fc3873": {
    name: "create_lock",
    signature: "create_lock(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x7430e0c6": {
    name: "finalizeReserveAuction",
    signature: "finalizeReserveAuction(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x441a3e70": {
    name: "withdraw",
    signature: "withdraw(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0x8b95dd71": {
    name: "setAddr",
    signature: "setAddr(bytes32,uint256,bytes)",
    input_types: ["bytes32", "uint256", "bytes"],
    output_aliases: ["b32", "num", "bytes"],
  },
  "0xe3dec8fb": {
    name: "depositFor",
    signature: "depositFor(address,address,bytes)",
    input_types: ["address", "address", "bytes"],
    output_aliases: ["address_a", "address_b", "bytes"],
  },
  "0x0f5287b0": {
    name: "transferTokens",
    signature: "transferTokens(address,uint256,uint16,bytes32,uint256,uint32)",
    input_types: [
      "address",
      "uint256",
      "uint16",
      "bytes32",
      "uint256",
      "uint32",
    ],
    output_aliases: ["to", "amount_a", "a", "b32", "amount_b", "b"],
  },
  "0x21506fff": {
    name: "cancelReserveAuction",
    signature: "cancelReserveAuction(uint256)",
    input_types: ["uint256"],
    output_aliases: ["auction"],
  },
  "0x7c928fe9": {
    name: "freeMint",
    signature: "freeMint(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x31e410a8": {
    name: "stakeNFT",
    signature: "stakeNFT(uint256,uint128,address)",
    input_types: ["uint256", "uint128", "address"],
    output_aliases: ["amount", "a", "to"],
  },
  "0xedbdf5e2": {
    name: "anySwapOutUnderlying",
    signature: "anySwapOutUnderlying(address,address,uint256,uint256)",
    input_types: ["address", "address", "uint256", "uint256"],
    output_aliases: ["address_a", "address_b", "amount_a", "amount_b"],
  },
  "0xe5225381": {
    name: "collect",
    signature: "collect()",
    input_types: [],
    output_aliases: [],
  },
  "0x35322f37": {
    name: "unstakeAll",
    signature: "unstakeAll()",
    input_types: [],
    output_aliases: [],
  },
  "0x508c1dbd": {
    name: "setSalePrice",
    signature: "setSalePrice(address,uint256,uint256)",
    input_types: ["address", "uint256", "uint256"],
    output_aliases: ["address", "amount_b", "amount_c"],
  },
  "0x6ce5d957": {
    name: "depositEth",
    signature: "depositEth(uint256,uint256,uint256)",
    input_types: ["uint256", "uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b", "amount_c"],
  },
  "0x63250887": {
    name: "mintForUser",
    signature: "mintForUser(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x18cbafe5": {
    name: "swapExactTokensForETH",
    signature:
      "swapExactTokensForETH(uint256,uint256,address[],address,uint256)",
    input_types: ["uint256", "uint256", "address[]", "address", "uint256"],
    output_aliases: ["amountIn", "amountOutMin", "path", "to", "deadline"],
  },
  "0xfb90b320": {
    name: "createForwarder",
    signature: "createForwarder(address,bytes32)",
    input_types: ["address", "bytes32"],
    output_aliases: ["to", "b32"],
  },
  "0xa2bc66be": {
    name: "unstake",
    signature: "unstake(address,uint256,uint256)",
    input_types: ["address", "uint256", "uint256"],
    output_aliases: ["to", "amount_a", "amount_b"],
  },
  "0x67dfd4c9": {
    name: "leave",
    signature: "leave(uint256)",
    input_types: ["uint256"],
    output_aliases: ["num"],
  },
  "0x8119c065": {
    name: "swap",
    signature: "swap()",
    input_types: [],
    output_aliases: [],
  },
  "0x1a4d01d2": {
    name: "remove_liquidity_one_coin",
    signature: "remove_liquidity_one_coin(uint256,int128,uint256)",
    input_types: ["uint256", "int128", "uint256"],
    output_aliases: ["amount_a", "a", "amount_b"],
  },
  "0x23972aef": {
    name: "enter",
    signature: "enter(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xe9fad8ee": {
    name: "exit",
    signature: "exit()",
    input_types: [],
    output_aliases: [],
  },
  "0x1e83409a": {
    name: "claim",
    signature: "claim(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x97304ced": {
    name: "mintTokens",
    signature: "mintTokens(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x65543320": {
    name: "MINT",
    signature: "MINT(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x28ed4f6c": {
    name: "reclaim",
    signature: "reclaim(uint256,address)",
    input_types: ["uint256", "address"],
    output_aliases: ["amount", "to"],
  },
  "0x492b50d7": {
    name: "startSwap",
    signature: "startSwap(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x02c88989": {
    name: "setSaleIsActive",
    signature: "setSaleIsActive(bool)",
    input_types: ["bool"],
    output_aliases: ["active"],
  },
  "0x8d8f2adb": {
    name: "withdrawTokens",
    signature: "withdrawTokens()",
    input_types: [],
    output_aliases: [],
  },
  "0xd5947f14": {
    name: "buyVault",
    signature: "buyVault()",
    input_types: [],
    output_aliases: [],
  },
  "0xdfff85a4": {
    name: "mint",
    signature: "mint(uint8,uint8,uint8,uint8,uint8,uint256)",
    input_types: ["uint8", "uint8", "uint8", "uint8", "uint8", "uint256"],
    output_aliases: ["a", "b", "c", "d", "e", "amount"],
  },
  "0x29ed2862": {
    name: "remove_liquidity_one_coin",
    signature: "remove_liquidity_one_coin(address,uint256,int128,uint256)",
    input_types: ["address", "uint256", "int128", "uint256"],
    output_aliases: ["to", "amount_a", "a", "amount_b"],
  },
  "0xded9382a": {
    name: "removeLiquidityETHWithPermit",
    signature:
      "removeLiquidityETHWithPermit(address,uint256,uint256,uint256,address,uint256,bool,uint8,bytes32,bytes32)",
    input_types: [
      "address",
      "uint256",
      "uint256",
      "uint256",
      "address",
      "uint256",
      "bool",
      "uint8",
      "bytes32",
      "bytes32",
    ],
    output_aliases: [
      "from",
      "a",
      "b",
      "c",
      "to",
      "d",
      "bool",
      "num",
      "b32_a",
      "b32_b",
    ],
  },
  "0xf3fef3a3": {
    name: "withdraw",
    signature: "withdraw(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xfb3bdb41": {
    name: "swapETHForExactTokens",
    signature: "swapETHForExactTokens(uint256,address[],address,uint256)",
    input_types: ["uint256", "address[]", "address", "uint256"],
    output_aliases: ["amountOutMin", "path", "to", "deadline"],
  },
  "0x7b0472f0": {
    name: "stake",
    signature: "stake(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xa6f2ae3a": {
    name: "buy",
    signature: "buy()",
    input_types: [],
    output_aliases: [],
  },
  "0x787a08a6": {
    name: "cooldown",
    signature: "cooldown()",
    input_types: [],
    output_aliases: [],
  },
  "0xcce7ec13": {
    name: "buy",
    signature: "buy(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["address", "amount"],
  },
  "0x52390c02": {
    name: "excludeFromReward",
    signature: "excludeFromReward(address)",
    input_types: ["address"],
    output_aliases: ["address"], // TODO check alias
  },
  "0xc204642c": {
    name: "airdrop",
    signature: "airdrop(address[],uint256)",
    input_types: ["address[]", "uint256"],
    output_aliases: ["addresses", "amount"],
  },
  "0x52bbbe29": {
    name: "swap",
    signature:
      "swap((bytes32,uint8,address,address,uint256,bytes),(address,bool,address,bool),uint256,uint256)",
    input_types: [
      "(bytes32,uint8,address,address,uint256,bytes)",
      "(address,bool,address,bool)",
      "uint256",
      "uint256",
    ],
    output_aliases: ["A", "B", "amount_a", "amount_b"], // TODO check alias
  },
  "0xd96a094a": {
    name: "buy",
    signature: "buy(uint256)",
    input_types: ["uint256"],
    output_aliases: ["num"], // TODO check alias
  },
  "0xdeace8f5": {
    name: "sendToL2",
    signature:
      "sendToL2(uint256,address,uint256,uint256,uint256,address,uint256)",
    input_types: [
      "uint256",
      "address",
      "uint256",
      "uint256",
      "uint256",
      "address",
      "uint256",
    ],
    output_aliases: ["a", "from", "b", "c", "d", "to", "e"], // TODO check alias
  },
  "0x4e71d92d": {
    name: "claim",
    signature: "claim()",
    input_types: [],
    output_aliases: [],
  },
  "0xa0712d68": {
    name: "mint",
    signature: "mint(uint256)",
    input_types: ["uint256"],
    output_aliases: ["num"], // TODO check alias
  },
  "0xa8a41c70": {
    name: "cancelOrder_",
    signature:
      "cancelOrder_(address[7],uint256[9],uint8,uint8,uint8,uint8,bytes,bytes,bytes,uint8,bytes32,bytes32)",
    input_types: [
      "address[7]",
      "uint256[9]",
      "uint8",
      "uint8",
      "uint8",
      "uint8",
      "bytes",
      "bytes",
      "bytes",
      "uint8",
      "bytes32",
      "bytes32",
    ],
    output_aliases: [
      "addresses",
      "amounts",
      "a",
      "b",
      "c",
      "d",
      "b_a",
      "b_b",
      "b_c",
      "e",
      "b_d",
      "b_e",
    ],
  },
  "0x84e9bd7e": {
    name: "claim_rewards",
    signature: "claim_rewards(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x52044ec9": {
    name: "stake",
    signature: "stake(uint256,uint64,bool)",
    input_types: ["uint256", "uint64", "bool"],
    output_aliases: ["amount_a", "amount_b", "bool"],
  },
  "0xc5ebeaec": {
    name: "borrow",
    signature: "borrow(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x4f91440d": {
    name: "restake",
    signature: "restake()",
    input_types: [],
    output_aliases: [],
  },
  "0x7c262871": {
    name: "getYield",
    signature: "getYield()",
    input_types: [],
    output_aliases: [],
  },
  "0x38ed1739": {
    name: "swapExactTokensForTokens",
    signature:
      "swapExactTokensForTokens(uint256,uint256,address[],address,uint256)",
    input_types: ["uint256", "uint256", "address[]", "address", "uint256"],
    output_aliases: ["amountIn", "amountOutMin", "path", "to", "deadline"],
  },
  "0x6189d107": {
    name: "sendToFx",
    signature: "sendToFx(address,bytes32,bytes32,uint256)",
    input_types: ["address", "bytes32", "bytes32", "uint256"],
    output_aliases: ["address", "b32_a", "b32_b", "amount"],
  },
  "0x0e752702": {
    name: "repayBorrow",
    signature: "repayBorrow(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x4000aea0": {
    name: "transferAndCall",
    signature: "transferAndCall(address,uint256,bytes)",
    input_types: ["address", "uint256", "bytes"],
    output_aliases: ["to", "amount", "bytes"],
  },
  "0x414bf389": {
    name: "exactInputSingle",
    signature:
      "registerWithConfig((address,address,uint24,address,uint256,uint256,uint256,uint160))",
    input_types: [
      "(address,address,uint24,address,uint256,uint256,uint256,uint160)",
    ],
    output_aliases: ["single"],
  },
  "0xf7a16963": {
    name: "registerWithConfig",
    signature:
      "registerWithConfig(string,address,uint256,bytes32,address,address)",
    input_types: [
      "string",
      "address",
      "uint256",
      "bytes32",
      "address",
      "address",
    ],
    output_aliases: [
      "config",
      "address_a",
      "amount",
      "b32",
      "address_b",
      "address_c",
    ],
  },
  "0x05eec289": {
    name: "requestAccess",
    signature: "requestAccess(bool)",
    input_types: ["bool"],
    output_aliases: ["access"],
  },
  "0x8bee5499": {
    name: "updateSilo",
    signature: "updateSilo(address)",
    input_types: ["address"],
    output_aliases: ["address"],
  },
  "0x81871056": {
    name: "rewardWithHint",
    signature: "rewardWithHint(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["address_a", "address_b"],
  },
  "0x6dcea85f": {
    name: "setMarket",
    signature: "setMarket(address)",
    input_types: ["address"],
    output_aliases: ["address"],
  },
  "0x0dcd7a6c": {
    name: "sendMultiSigToken",
    signature:
      "sendMultiSigToken(address,uint256,address,uint256,uint256,bytes)",
    input_types: [
      "address",
      "uint256",
      "address",
      "uint256",
      "uint256",
      "bytes",
    ],
    output_aliases: [
      "from",
      "amount_a",
      "to",
      "amount_c",
      "amount_d",
      "bytes_b",
    ],
  },
  "0xddff5b1c": {
    name: "mintAllowList",
    signature: "mintAllowList(uint8)",
    input_types: ["uint8"],
    output_aliases: ["amount"],
  },
  "0x0f4d14e9": {
    name: "depositEth",
    signature: "depositEth(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x5358fbda": {
    name: "depositETH",
    signature: "depositETH(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xb1a1a882": {
    name: "depositETH",
    signature: "depositETH(uint256,bytes)",
    input_types: ["uint256", "bytes"],
    output_aliases: ["amount", "bytes"],
  },
  "0xeee3f07a": {
    name: "depositEthFor",
    signature: "depositEthFor(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0x7c025200": {
    name: "swap",
    signature:
      "swap(address,(address,address,address,address,uint256,uint256,uint256,bytes),bytes)",
    input_types: [
      "address",
      "(address,address,address,address,uint256,uint256,uint256,bytes)",
      "bytes",
    ],
    output_aliases: ["token", "entry", "bytes"],
  },
  "0x40c10f19": {
    name: "mint",
    signature: "mint(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["to", "amount"],
  },
  "0xb6b55f25": {
    name: "deposit",
    signature: "deposit(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x9ebea88c": {
    name: "unstake",
    signature: "unstake(uint256,bool)",
    input_types: ["uint256", "bool"],
    output_aliases: ["amount", "staked"],
  },
  "0xd4aadbc6": {
    name: "payAll",
    signature: "payAll(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x7ff36ab5": {
    name: "swapExactETHForTokens",
    signature: "swapExactETHForTokens(uint256,address[],address,uint256)",
    input_types: ["uint256", "address[]", "address", "uint256"],
    output_aliases: ["amountOutMin", "path", "to", "deadline"],
  },
  "0x791ac947": {
    name: "swapExactTokensForETHSupportingFeeOnTransferTokens",
    signature:
      "swapExactTokensForETHSupportingFeeOnTransferTokens(uint256,uint256,address[],address,uint256)",
    input_types: ["uint256", "uint256", "address[]", "address", "uint256"],
    output_aliases: ["amountIn", "amountOutMin", "path", "to", "deadline"],
  },
  "0x00a46917": {
    name: "claimAll",
    signature: "claimAll(uint256,bool)",
    input_types: ["uint256", "bool"],
    output_aliases: ["amount", "claimed"],
  },
  "0x5f575529": {
    name: "swap",
    signature: "swap(string,address,uint256,bytes)",
    input_types: ["string", "address", "uint256", "bytes"],
    output_aliases: ["token", "to", "amount", "bytes"],
  },
  "0x23b872dd": {
    name: "transferFrom",
    signature: "transferFrom(address,address,uint256)",
    input_types: ["address", "address", "uint256"],
    output_aliases: ["from", "to", "amount"],
  },
  "0x3d18b912": {
    name: "getReward",
    signature: "getReward()",
    input_types: [],
    output_aliases: [],
  },
  "0x4faa8a26": {
    name: "depositEthFor",
    signature: "depositEthFor(address)",
    input_types: ["address"],
    output_aliases: ["to"],
  },
  "0xc47f0027": {
    name: "setName",
    signature: "setName(string)",
    input_types: ["string"],
    output_aliases: ["name"],
  },
  "0x2da03409": {
    name: "flushForwarderTokens",
    signature: "flushForwarderTokens(address,address)",
    input_types: ["address", "address"],
    output_aliases: ["from", "to"],
  },
  "0xe1fa8e84": {
    name: "register",
    signature: "register(bytes32)",
    input_types: ["bytes32"],
    output_aliases: ["b32"],
  },
  "0x39125215": {
    name: "sendMultiSig",
    signature: "sendMultiSig(address,uint256,bytes,uint256,uint256,bytes)",
    input_types: ["address", "uint256", "bytes", "uint256", "uint256", "bytes"],
    output_aliases: [
      "address",
      "amount_a",
      "bytes_a",
      "amount_c",
      "amount_d",
      "bytes_b",
    ],
  },
  "0xd1d2d95e": {
    name: "depositPendingFunds",
    signature: "depositPendingFunds(uint256,uint256,address)",
    input_types: ["uint256", "uint256", "address"],
    output_aliases: ["amount_a", "amount_b", "address"],
  },
  "0xf242432a": {
    name: "safeTransferFrom",
    signature: "safeTransferFrom(address,address,uint256,uint256,bytes)",
    input_types: ["address", "address", "uint256", "uint256", "bytes"],
    output_aliases: ["address_a", "address_b", "amount_a", "amount_b", "bytes"],
  },
  "0x2d2da806": {
    name: "depositETH",
    signature: "depositETH(address)",
    input_types: ["address"],
    output_aliases: ["address"],
  },
  "0xaad3ec96": {
    name: "claim",
    signature: "claim(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["address", "amount"],
  },
  "0x6ea056a9": {
    name: "sweep",
    signature: "sweep(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["address", "amount"],
  },
  "0x47e7ef24": {
    name: "deposit",
    signature: "deposit(address,uint256)",
    input_types: ["address", "uint256"],
    output_aliases: ["address", "amount"],
  },
  "0x8dbdbe6d": {
    name: "deposit",
    signature: "deposit(uint256,uint256,address)",
    input_types: ["uint256", "uint256", "address"],
    output_aliases: ["amount_a", "amount_b", "address"],
  },
  "0x24600fc3": {
    name: "withdrawFunds",
    signature: "withdrawFunds()",
    input_types: [],
    output_aliases: [],
  },
  "0xf14fcbc8": {
    name: "commit",
    signature: "commit(bytes32)",
    input_types: ["bytes32"],
    output_aliases: ["b32"],
  },
  "0x0f694584": {
    name: "payFee",
    signature: "payFee(uint128)",
    input_types: ["uint128"],
    output_aliases: ["amount"],
  },
  "0xefef39a1": {
    name: "purchase",
    signature: "purchase(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x2e1a7d4d": {
    name: "withdraw",
    signature: "withdraw(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0x3ccfd60b": {
    name: "withdraw",
    signature: "withdraw()",
    input_types: [],
    output_aliases: [],
  },
  "0xa9059cbb": approve.meta,
  "0x095ea7b3": approve.meta,
  "0xd0e30db0": {
    name: "deposit",
    signature: "deposit()",
    input_types: [],
    output_aliases: [],
  },
  "0xa694fc3a": {
    name: "stake",
    signature: "stake(uint256)",
    input_types: ["uint256"],
    output_aliases: ["amount"],
  },
  "0xab834bab": atomicMatch.meta, // atomicMatch_(address[14],uint256[18],uint8[8],bytes,bytes,bytes,bytes,bytes,bytes,uint8[2],bytes32[5])
  "0x52a438b8": {
    name: "stakeStart",
    signature: "stakeStart(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["amount_a", "amount_b"],
  },
  "0xddd81f82": {
    name: "registerProxy",
    signature: "registerProxy()",
    input_types: [],
    output_aliases: [],
  },
  "0xa22cb465": {
    name: "setApprovalForAll",
    signature: "setApprovalForAll(address,bool)",
    input_types: ["address", "bool"],
    output_aliases: ["address", "bool"],
  },
  "0x1cff79cd": {
    name: "execute",
    signature: "execute(address,bytes)",
    input_types: ["address", "bytes"],
    output_aliases: ["address", "bytes"],
  },
  "0x1249c58b": {
    name: "mint",
    signature: "mint()",
    input_types: [],
    output_aliases: [],
  },
  "0xd85d3d27": {
    name: "mint",
    signature: "mint(string)",
    input_types: ["string"],
    output_aliases: ["name"],
  },
  "0x1b2ef1ca": {
    name: "mint",
    signature: "mint(uint256,uint256)",
    input_types: ["uint256", "uint256"],
    output_aliases: ["uint_a", "uint_b"],
  },
  "0x4d192b83": {
    name: "publicSalesMint",
    signature: "publicSalesMint(uint256)",
    input_types: ["uint256"],
    output_aliases: ["uint_a"],
  },
};

export default HandledMethods;

import React, { useRef, useEffect, useCallback } from "react";

const DataCanvas = (props: {
  width: number;
  height?: number;
  data_string: string;
  scale?: number;
}) => {
  const { width, height, data_string, scale } = props;
  const canvasRef = useRef<HTMLCanvasElement>(null);

  // if (canvasRef.current) {
  //   canvasRef.current.width = width || 100;
  //   canvasRef.current.height = height || 100;
  // }

  const to_draw = data_string.substring(2, data_string.length - 2); // remove 0x
  console.log(
    "canvasRef",
    canvasRef,
    width,
    height,
    "drawing.td",
    to_draw.length
  );
  //
  const arr = to_draw.split("");
  //
  const onDraw = useCallback(
    (canvas: HTMLCanvasElement, context: CanvasRenderingContext2D) => {
      const indices = arr
        .filter((k, idx) => idx % 3 === 0)
        .map((v, idx) => idx * 3); // hacked!

      const trios = indices.map((idx) =>
        [0, 1, 2].map((i) => parseInt(arr[idx + i] || "0", 16))
      );
      console.log(arr.length, arr.length % 3 === 0);

      console.log(trios[0]);

      // var c = document.getElementById("myCanvas");
      const _scale = scale ? scale : 3;
      const in_row = Math.floor(width / _scale);
      const total_rows = Math.floor(trios.length / in_row); // + 1;
      console.log(
        "scale",
        _scale,
        "in_row",
        in_row,
        "w",
        width,
        "TR",
        total_rows,
        "NH",
        in_row * _scale
      );

      // too large ?
      if (canvas.height > total_rows * _scale) {
        canvas.height = Math.min(total_rows * _scale, 2048);
      }

      // too small ?
      if (canvas.height < total_rows * _scale) {
        canvas.height = Math.min(total_rows * _scale, 2048);
      }

      if (context !== null) {
        // clear with white
        // x y w h
        context.fillStyle = `#ffffff`;
        context.fillRect(0, 0, canvas.width, canvas.height);

        let cell_num = 0;
        let row_num = 0;
        //
        for (const t of trios) {
          if (cell_num % in_row === 0) row_num++;
          //

          if (t[0] === 0 && t[1] === 0 && t[2] === 0) {
            context.fillStyle = `rgba(0,0,0,0.1)`;
            // x y w h
            context.fillRect(
              _scale * (cell_num % in_row),
              _scale * row_num,
              _scale,
              _scale
            );
          } else {
            context.fillStyle = `rgba(${t[0] * 16}, ${t[1] * 16}, ${
              t[2] * 16
            }, ${255 - (t[2] + t[1] + t[0]) / 25.5})`;
            // context.fillStyle = `rgb(${t[0] * _scale}, ${t[1] * 15}, ${
            //   t[2] * 15
            // })`;
            context.fillRect(
              _scale * (cell_num % in_row),
              _scale * row_num,
              _scale,
              _scale
            );
          }

          //
          cell_num++;
        }
      }
    },
    [arr, width]
  );

  useEffect(() => {
    const canvas = canvasRef.current;
    //
    if (canvas !== null) {
      const context: CanvasRenderingContext2D | null = canvas.getContext("2d");
      //
      if (context !== null) onDraw(canvas, context);
    }
  }, [onDraw]);

  return <canvas ref={canvasRef} {...props} />;
};

export default DataCanvas;

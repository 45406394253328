// import { ethers } from "@usedapp/core/node_modules/ethers";

import { _as_interface_stripe, _as_stripe } from "../../SimpleControls";
import { SubTitle } from "../../../typography/SubTitle";
import { Label } from "../../../typography/Label";
import { ContentBlock, ContentRow } from "../../base";
import { useBlockNumber, useContractCall } from "@usedapp/core";

import UniswapV3Multicall2ABI from "../../../contracts/UniswapV3Multicall2.abi.json";
import { useEffect, useMemo, useState } from "react";

import React from "react"; // Step 1 - Include react
import ReactFC from "react-fusioncharts"; // Step 2 - Include the react-fusioncharts component
import FusionCharts from "fusioncharts"; // Step 3 - Include the fusioncharts library
import Column2D from "fusioncharts/fusioncharts.charts"; // Step 4 - Include the chart type
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion"; // Step 5 - Include the theme as fusion
import { ethers } from "ethers";

ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme); // Step 6 - Adding the chart and theme as dependency to the core fusioncharts

export function CurrentBlockChart(props: {
  envelope: Array<{ ts: number; bn: number }>;
}) {
  const { envelope } = props;
  //
  console.log("received props", envelope);
  //
  const createChartData = (
    envelope: Array<{ ts: number; bn: number }>
  ): Array<{ label: string; value: string }> => {
    return (
      envelope.map(({ ts, bn }: { ts: number; bn: number }) => ({
        label: String(new Date(ts).toISOString().split("T")[1].split(".")[0]),
        value: bn ? String(parseInt(bn.toString()) - 14308000) : "0",
      })) || ([] as Array<{ label: string; value: string }>)
    );
    // const keys = Object.keys(envelope);
    // const is_empty = keys.length === 0;
    // //
    // if (is_empty) return [] as Array<{ label: string; value: string }>;
    // else {
    // }
  };

  const cd = createChartData(envelope);
  console.log("NEW-CD", cd);
  //
  // Create a JSON object to store the chart configurations
  //
  const chartConfigs = {
    type: "line", // The chart type
    width: "800", // Width of the chart
    height: "300", // Height of the chart
    dataFormat: "json", // Data type
    dataSource: {
      // Chart Configuration
      chart: {
        yAxisname: "Block",
        // placevaluesInside: "1",
        xAxisLineColor: "#999999",
        // caption: "Real-time stock price monitor",
        // subCaption: "Harry's SuperMart",
        // xAxisName: "Time",
        // yAxisName: "Stock Price",
        // numberPrefix: "$",
        // refreshinterval: "5",
        yaxisminvalue: cd.length
          ? String(parseInt(cd[0].value) - 1)
          : "14308649",
        yaxismaxvalue: cd.length
          ? String(parseInt(cd[cd.length - 1].value) + 5)
          : "14308649",
        // numdisplaysets: "10",
        labeldisplay: "rotate",
        // showRealTimeValue: "0",
        theme: "fusion",
      },
      data: cd,
      // categories: [
      //   {
      //     category: cd.map((c, k) => String(k)), // c.label
      //   },
      // ],
      // dataset: [
      //   {
      //     seriesname: "Food Products",
      //     data: [...cd.map((c) => String(10000000))],
      //   },
      //   {
      //     seriesname: "Non-Food Products",
      //     data: [...cd.map((c) => String(10200000))],
      //   },
      // ],
    },
  };

  // and finally render
  return <ReactFC {...chartConfigs} />;
}

//
//
//
const multicallContractInterface = new ethers.utils.Interface(
  UniswapV3Multicall2ABI
);

export const BlockCurrentBlockControl = (props: {
  fps: number;
  // summary: FirstBlockCacheSummary | undefined;
  // controlProps: {
  //   minStoredBlockHex: string | undefined;
  //   maxStoredBlockHex: string | undefined;
  //   blockNumber: number | undefined;
  //   blockHexMemo: string | undefined;
  //   timeShiftMemo: string | undefined;
  // };
}): JSX.Element => {
  const { fps } = props;
  // const {
  //   minStoredBlockHex,
  //   maxStoredBlockHex,
  //   blockNumber,
  //   blockHexMemo,
  //   timeShiftMemo,
  // } = controlProps;
  const CA_MULTICALL = "0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696";

  const useBlockNumberFromChain = (address: string | null) => {
    const [block_num] =
      useContractCall(
        address && {
          abi: multicallContractInterface, //ERC20Interface,
          address: address, // testpair                  UNI_V2_FACTORY_CONTRACT,
          method: "getBlockNumber",
          args: [], // [ownerAddress, spenderAddress],
        }
      ) ?? [];
    //
    return block_num;
  };

  const useCurrentBlockGasLimitFromChain = (address: string | null) => {
    const [gas_limit] =
      useContractCall(
        address && {
          abi: multicallContractInterface, //ERC20Interface,
          address: address, // testpair                  UNI_V2_FACTORY_CONTRACT,
          method: "getCurrentBlockGasLimit",
          args: [], // [ownerAddress, spenderAddress],
        }
      ) ?? [];
    //
    return gas_limit;
  };

  //
  const bn = useBlockNumber();
  //
  const [tickIndex, setTickIndex] = useState<number>();
  const block_number = useBlockNumberFromChain(CA_MULTICALL);
  const gas_limit = useCurrentBlockGasLimitFromChain(CA_MULTICALL);
  //

  //
  const [series, setSeries] = useState<Array<{ ts: number; bn: number }>>([]);

  const lastMemo = useMemo(() => {
    if (block_number && gas_limit) {
      //
      return {
        block_number,
        gas_limit,
        created: new Date().toISOString().split("T")[1].replace("Z", ""),
      };
    }
    //
    return undefined; // [] as Array<any>;
  }, [block_number, gas_limit, tickIndex]);
  // getBlockNumber	14308268
  // getCurrentBlockGasLimit 30029209
  useEffect(() => {
    const int_id = setInterval(() => {
      console.log("TICK", tickIndex);
      //
      if (block_number) {
        series.push({ ts: new Date().getTime(), bn: block_number || 0 });
      }
      //
      setTickIndex((tickIndex || 0) + 1);
    }, 1000);

    return () => {
      console.log("cleaning up interval", int_id);
      clearInterval(int_id);
    };
  }, [tickIndex]);
  //
  //
  //
  const time = new Date().toISOString().split("T")[1].replace("Z", "");
  //
  //

  const chartEnvelope = useMemo(() => {
    if (series.length > 100) series.shift();
    // { ts: number; bn: number; } -->
    // -->
    return series;
  }, [series.length, series[0]]);
  // const [envelope, setEnvelope] = useState<Record<string, number>>({});

  //
  return (
    <ContentBlock>
      <ContentRow>
        <SubTitle>Local Block Cache Summary</SubTitle>
      </ContentRow>
      Curremt block
      <ContentRow>from {_as_stripe(CA_MULTICALL, 10, 10)}</ContentRow>
      <ContentRow>
        fps {fps} {time}
      </ContentRow>
      <ContentRow>
        getBlockNumber {block_number ? block_number.toString() : "..."} /{" "}
        {bn ? bn.toString() : "..."}
      </ContentRow>
      <ContentRow>
        getCurrentBlockGasLimit {gas_limit ? gas_limit.toString() : "..."}{" "}
      </ContentRow>
      <ContentRow>lastMemo {JSON.stringify(lastMemo)} </ContentRow>
      <div>
        CurrentBlockChart
        <CurrentBlockChart envelope={chartEnvelope} />
      </div>
    </ContentBlock>
  );
};

import { useState } from "react";
import { Link } from "react-router-dom";
import FullScreenTogglerButton from "./components/FullScreenTogglerButton";
import SideTogglerButton from "./components/SideTogglerButton";

import React, { useCallback } from "react";
import { FullScreen } from "react-full-screen";

import SpIcon from "./components/SpIcon";

// import SpIcon from "../SpIcon";

export const SkinnedHeader = (props: { pageName: string; handle: any }) => {
  const { pageName, handle } = props;

  //
  return !pageName ? (
    <></>
  ) : (
    <header className="main-header">
      {/* <!-- Logo --> */}
      <a data-href="index.html" className="logo" style={{ height: "24px" }}>
        <small style={{ fontSize: "13px", position: "relative", top: "-20px" }}>
          KYC.Foundation
        </small>
        {/* <!-- mini logo for sidebar mini 50x50 pixels --> */}
        <b className="logo-mini">
          <span className="light-logo">
            {/* <img src="../images/logo-light.png" alt="logo" /> */}
          </span>
          <span className="dark-logo">
            {/* <img src="../images/logo-dark.png" alt="logo" /> */}
          </span>
        </b>
        {/* <!-- logo for regular state and mobile devices --> */}
        <span className="logo-lg">
          {/* <img
            src="../images/logo-dark-text.png"
            alt="logo"
            className="dark-logo"
          />
          <img
            src="../images/logo-light-text.png"
            alt="logo"
            className="light-logo"
          /> */}
        </span>
      </a>
      {/* <!-- Header Navbar --> */}
      <nav className="navbar navbar-static-top">
        {/* <!-- Sidebar toggle button--> */}
        <div>
          <SideTogglerButton />
          {/* <FullScreenTogglerButton handle={handle} /> */}
        </div>
        <div className="navbar-custom-menu">
          <ul className="nav navbar-nav">
            {/* <li className="search-box">
              <a className="nav-link hidden-sm-down" href="#">
                <i className="mdi mdi-magnify"></i>
              </a>
              <form className="app-search" style={{ display: "none" }}>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search &amp; enter"
                />{" "}
                <a className="srh-btn">
                  <i className="ti-close"></i>
                </a>
              </form>
            </li> */}

            {/* <!-- Messages --> */}
            {/* <li className="dropdown messages-menu">
              <a
                data-href="#"
                className="dropdown-toggle"
                data-toggle="dropdown"
              >
                <i className="mdi mdi-email"></i>
              </a>
              <ul className="dropdown-menu scale-up">
                <li className="header">You have 5 messages</li>
                <li>
                  <ul className="menu inner-content-div">
                    <li>
                      <a data-href="#">
                        <div className="pull-left">
                          <img
                            src="../images/user2-160x160.jpg"
                            className="rounded-circle"
                            alt="User Image"
                          />
                        </div>
                        <div className="mail-contnet">
                          <h4>
                            Lorem Ipsum
                            <small>
                              <i className="fa fa-clock-o"></i> 15 mins
                            </small>
                          </h4>
                          <span>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit.
                          </span>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a data-href="#">
                        <div className="pull-left">
                          <img
                            src="../images/user3-128x128.jpg"
                            className="rounded-circle"
                            alt="User Image"
                          />
                        </div>
                        <div className="mail-contnet">
                          <h4>
                            Nullam tempor
                            <small>
                              <i className="fa fa-clock-o"></i> 4 hours
                            </small>
                          </h4>
                          <span>
                            Curabitur facilisis erat quis metus congue viverra.
                          </span>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a data-href="#">
                        <div className="pull-left">
                          <img
                            src="../images/user4-128x128.jpg"
                            className="rounded-circle"
                            alt="User Image"
                          />
                        </div>
                        <div className="mail-contnet">
                          <h4>
                            Proin venenatis
                            <small>
                              <i className="fa fa-clock-o"></i> Today
                            </small>
                          </h4>
                          <span>
                            Vestibulum nec ligula nec quam sodales rutrum sed
                            luctus.
                          </span>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a data-href="#">
                        <div className="pull-left">
                          <img
                            src="../images/user3-128x128.jpg"
                            className="rounded-circle"
                            alt="User Image"
                          />
                        </div>
                        <div className="mail-contnet">
                          <h4>
                            Praesent suscipit
                            <small>
                              <i className="fa fa-clock-o"></i> Yesterday
                            </small>
                          </h4>
                          <span>
                            Curabitur quis risus aliquet, luctus arcu nec,
                            venenatis neque.
                          </span>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a data-href="#">
                        <div className="pull-left">
                          <img
                            src="../images/user4-128x128.jpg"
                            className="rounded-circle"
                            alt="User Image"
                          />
                        </div>
                        <div className="mail-contnet">
                          <h4>
                            Donec tempor
                            <small>
                              <i className="fa fa-clock-o"></i> 2 days
                            </small>
                          </h4>
                          <span>
                            Praesent vitae tellus eget nibh lacinia pretium.
                          </span>
                        </div>
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="footer">
                  <a data-href="#">See all e-Mails</a>
                </li>
              </ul>
            </li> */}
            {/* <!-- Notifications --> */}
            {/* <li className="dropdown notifications-menu">
              <a
                data-href="#"
                className="dropdown-toggle"
                data-toggle="dropdown"
              >
                <i className="mdi mdi-bell"></i>
              </a>
              <ul className="dropdown-menu scale-up">
                <li className="header">You have 7 notifications</li>
                <li>
                  <ul className="menu inner-content-div">
                    <li>
                      <a data-href="#">
                        <i className="fa fa-users text-aqua"></i> Curabitur id
                        eros quis nunc suscipit blandit.
                      </a>
                    </li>
                    <li>
                      <a data-href="#">
                        <i className="fa fa-warning text-yellow"></i> Duis
                        malesuada justo eu sapien elementum, in semper diam
                        posuere.
                      </a>
                    </li>
                    <li>
                      <a data-href="#">
                        <i className="fa fa-users text-red"></i> Donec at nisi
                        sit amet tortor commodo porttitor pretium a erat.
                      </a>
                    </li>
                    <li>
                      <a data-href="#">
                        <i className="fa fa-shopping-cart text-green"></i> In
                        gravida mauris et nisi
                      </a>
                    </li>
                    <li>
                      <a data-href="#">
                        <i className="fa fa-user text-red"></i> Praesent eu
                        lacus in libero dictum fermentum.
                      </a>
                    </li>
                    <li>
                      <a data-href="#">
                        <i className="fa fa-user text-red"></i> Nunc fringilla
                        lorem
                      </a>
                    </li>
                    <li>
                      <a data-href="#">
                        <i className="fa fa-user text-red"></i> Nullam euismod
                        dolor ut quam interdum, at scelerisque ipsum imperdiet.
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="footer">
                  <a data-href="#">View all</a>
                </li>
              </ul>
            </li> */}
            {/* <li className="dropdown tasks-menu">
              <a
                data-href="#"
                className="dropdown-toggle"
                data-toggle="dropdown"
              >
                <i className="mdi mdi-message"></i>
              </a>
              <ul className="dropdown-menu scale-up">
                <li className="header">You have 6 tasks</li>
                <li>
                  <ul className="menu inner-content-div">
                    <li>
                      <a data-href="#">
                        <h3>
                          Lorem ipsum dolor sit amet
                          <small className="pull-right">30%</small>
                        </h3>
                        <div className="progress xs">
                          <div
                            className="progress-bar progress-bar-aqua"
                            style={{ width: "30%" }}
                            role="progressbar"
                            aria-valuenow={20}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          >
                            <span className="sr-only">30% Complete</span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a data-href="#">
                        <h3>
                          Vestibulum nec ligula
                          <small className="pull-right">20%</small>
                        </h3>
                        <div className="progress xs">
                          <div
                            className="progress-bar progress-bar-danger"
                            style={{ width: "20%" }}
                            role="progressbar"
                            aria-valuenow={20}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          >
                            <span className="sr-only">20% Complete</span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a data-href="#">
                        <h3>
                          Donec id leo ut ipsum
                          <small className="pull-right">70%</small>
                        </h3>
                        <div className="progress xs">
                          <div
                            className="progress-bar progress-bar-light-blue"
                            style={{ width: "70%" }}
                            role="progressbar"
                            aria-valuenow={20}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          >
                            <span className="sr-only">70% Complete</span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a data-href="#">
                        <h3>
                          Praesent vitae tellus
                          <small className="pull-right">40%</small>
                        </h3>
                        <div className="progress xs">
                          <div
                            className="progress-bar progress-bar-yellow"
                            style={{ width: "40%" }}
                            role="progressbar"
                            aria-valuenow={20}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          >
                            <span className="sr-only">40% Complete</span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a data-href="#">
                        <h3>
                          Nam varius sapien
                          <small className="pull-right">80%</small>
                        </h3>
                        <div className="progress xs">
                          <div
                            className="progress-bar progress-bar-red"
                            style={{ width: "80%" }}
                            role="progressbar"
                            aria-valuenow={20}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          >
                            <span className="sr-only">80% Complete</span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a data-href="#">
                        <h3>
                          Nunc fringilla
                          <small className="pull-right">90%</small>
                        </h3>
                        <div className="progress xs">
                          <div
                            className="progress-bar progress-bar-primary"
                            style={{ width: "90%" }}
                            role="progressbar"
                            aria-valuenow={20}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          >
                            <span className="sr-only">90% Complete</span>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="footer">
                  <a data-href="#">View all tasks</a>
                </li>
              </ul>
            </li> */}
            {/* <!-- User Account --> */}
            <li className="dropdown user user-menu">
              <a
                data-href="#"
                className="dropdown-toggle"
                data-toggle="dropdown"
              >
                <img
                  src="../images/user5-128x128.jpg"
                  className="user-image rounded-circle"
                  alt="User Image"
                />
              </a>
              <ul className="dropdown-menu scale-up">
                {/* <!-- User image --> */}
                {/* <li className="user-header">
                  <img
                    src="../images/user5-128x128.jpg"
                    className="float-left rounded-circle"
                    alt="User Image"
                  />

                  <p>
                    Romi Roy
                    <small className="mb-5">jb@gmail.com</small>
                    <a
                      data-href="#"
                      className="btn btn-danger btn-sm btn-rounded"
                    >
                      View Profile
                    </a>
                  </p>
                </li> */}

                {/* <!-- Menu Body --> */}
                <li className="user-body">
                  <div className="row no-gutters">
                    {/* <div className="col-12 text-left">
                      <a data-href="#">
                        <i className="ion ion-person"></i> My Profile
                      </a>
                    </div>
                    <div className="col-12 text-left">
                      <a data-href="#">
                        <i className="ion ion-email-unread"></i> Inbox
                      </a>
                    </div>
                    <div className="col-12 text-left">
                      <a data-href="#">
                        <i className="ion ion-settings"></i> Setting
                      </a>
                    </div>
                    <div role="separator" className="divider col-12"></div>
                    <div className="col-12 text-left">
                      <a data-href="#">
                        <i className="ti-settings"></i> Account Setting
                      </a>
                    </div>
                    <div role="separator" className="divider col-12"></div>
                     */}
                    <div className="col-12 text-left">
                      <a data-href="#">
                        <i className="fa fa-power-off"></i> Logout
                      </a>
                    </div>
                  </div>
                  {/* <!-- /.row --> */}
                </li>
              </ul>
            </li>
            {/* <!-- Control Sidebar Toggle Button --> */}
            {/* <li>
              <a data-href="#" data-toggle="control-sidebar">
                <i className="fa fa-cog fa-spin"></i>
              </a>
            </li> */}
          </ul>
        </div>
      </nav>
    </header>
  );
};

export const SkinnedLeftSlider = (props: { pageName: string }) => {
  const { pageName } = props;
  //
  return !pageName ? (
    <></>
  ) : (
    <aside className="main-sidebar">
      {/* <!-- sidebar --> */}
      <section className="sidebar">
        {/* <!-- Sidebar user panel --> */}
        <div className="user-panel">
          <div className="ulogo">
            <Link to={"/"}>
              <b>KYC </b>Foundation
            </Link>
            {/* <a data-href="index.html">
              <span>
              </span>
            </a> */}
          </div>
          <div className="image">
            <img
              src="../images/user2-160x160.jpg"
              className="rounded-circle"
              alt="User Image"
            />
          </div>
          <div className="info">
            <p>Smart Contracts Unchained</p>
            <small>Early bird phase</small>
            {/* <a
              href=""
              className="link"
              data-toggle="tooltip"
              title=""
              data-original-title="Settings"
            >
              <i className="ion ion-gear-b"></i>
            </a>
            <a
              href=""
              className="link"
              data-toggle="tooltip"
              title=""
              data-original-title="Email"
            >
              <i className="ion ion-android-mail"></i>
            </a>
            <a
              href=""
              className="link"
              data-toggle="tooltip"
              title=""
              data-original-title="Logout"
            >
              <i className="ion ion-power"></i>
            </a> */}
          </div>
        </div>
        {/* <!-- sidebar menu --> */}
        <ul className="sidebar-menu" data-widget="tree">
          <li className="nav-devider"></li>
          <li className="header nav-small-cap">KNOW YOUR CONTRACT</li>
          <li className="active">
            <Link to="/empty">
              <i className="icon-home"></i> <span>Dashboard</span>
              <span className="pull-right-container">
                <i className="fa fa-angle-right pull-right"></i>
              </span>
            </Link>
          </li>
          <li className="active">
            <Link to="/new">
              <i className="icon-chart"></i> <span>New Contracts</span>
              <span className="pull-right-container">
                <i className="fa fa-angle-right pull-right"></i>
              </span>
            </Link>
          </li>
          <li className="active">
            <Link to="/kyc_hosted">
              <i className="icon-compass"></i> <span>Announce contract</span>
              <span className="pull-right-container">
                <i className="fa fa-angle-right pull-right"></i>
              </span>
            </Link>
          </li>
          {/* <li className="active">
            <Link to="/kyc_approved">
              <i className="icon-compass"></i> <span>Address Locker</span>
              <span className="pull-right-container">
                <i className="fa fa-angle-right pull-right"></i>
              </span>
            </Link>
          </li> */}
          {/* <li className="treeview">
            <a data-href="#">
              <i className="icon-chart"></i>
              <span>Discover</span>
              <span className="pull-right-container">
                <i className="fa fa-angle-right pull-right"></i>
              </span>
            </a>
            <ul className="treeview-menu">
              <li>
                <Link to="/new">New Contracts</Link>
              </li>
              <li>
                <Link to="/new_pairs">New Pairs</Link>
              </li>
              <li>
                <Link
                  to="/on_my_watchlist"
                  style={{ opacity: 0.2, textDecoration: "line-through" }}
                >
                  On my Watchlist
                </Link>
              </li>
              <li>
                <Link
                  to="/mempool_live"
                  style={{ opacity: 0.2, textDecoration: "line-through" }}
                >
                  Mempool Live
                </Link>
              </li>
              <li>
                <Link
                  to="/crypto_calendar"
                  style={{ opacity: 0.2, textDecoration: "line-through" }}
                >
                  Crypto Calendar
                </Link>
              </li>
            </ul>
          </li> */}

          {/* <li className="treeview">
            <a data-href="#">
              <i className="icon-compass"></i>
              <span>Analyze</span>
              <span className="pull-right-container">
                <i className="fa fa-angle-right pull-right"></i>
              </span>
            </a>
            <ul className="treeview-menu">
              <li>
                <Link to="/upload">Contract Inspection</Link>
              </li>
              <li>
                <Link to="/compare_contracts">Compare Contracts</Link>
              </li>
            </ul>
          </li> */}

          {/* <li className="treeview">
            <a data-href="pages/currency-ex/exchange.html">
              <i className="icon-refresh"></i> <span>Observe</span>
              <span className="pull-right-container">
                <i className="fa fa-angle-right pull-right"></i>
              </span>
            </a>
            <ul className="treeview-menu">
              <li>
                <Link to="/block">Last Block</Link>
              </li>
              <li>
                <Link to="/whale_ranking">Whale Ranking</Link>
              </li>
              <li>
                <Link
                  to="/cherry_picking"
                  style={{ opacity: 0.2, textDecoration: "line-through" }}
                >
                  Cherry Picking
                </Link>
              </li>
              <li>
                <Link
                  to="/age_distribution"
                  style={{ opacity: 0.2, textDecoration: "line-through" }}
                >
                  Age Distribution
                </Link>
              </li>
              <li>
                <Link
                  to="/deployers_repeating"
                  style={{ opacity: 0.2, textDecoration: "line-through" }}
                >
                  Repeating Deployers
                </Link>
              </li>
            </ul>
          </li>
          <li className="treeview">
            <a data-href="#">
              <i className="icon-people"></i>
              <span>Contribute</span>
              <span className="pull-right-container">
                <i className="fa fa-angle-right pull-right"></i>
              </span>
            </a>
            <ul className="treeview-menu">
              <li>
                <Link to="/poll_next_request">Next Feature Poll</Link>
              </li>
              <li>
                <Link to="/request_feature">Request a Feature</Link>
              </li>
              <li>
                <Link to="/report_scam">Report a Scam</Link>
              </li>
              <li>
                <Link to="/report_scammer">Report a Scammer</Link>
              </li>
            </ul>
          </li>
          <li className="treeview">
            <a data-href="#">
              <i className="icon-equalizer"></i>
              <span>Launchpad</span>
              <span className="pull-right-container">
                <i className="fa fa-angle-right pull-right"></i>
              </span>
            </a>
            <ul className="treeview-menu">
              <li>
                <Link to="/kyc_approved">KYC Approved</Link>
              </li>
              <li>
                <Link
                  to="/kyc_hosted"
                  style={{ opacity: 0.2, textDecoration: "line-through" }}
                >
                  KYC Hosted
                </Link>
              </li>
            </ul>
          </li> */}

          {/*
































  */}
          {/* <li className="header nav-small-cap">ABOUT US</li>
          <li>
            <Link to="/roadmap">
              <i className="icon-equalizer"></i>
              Roadmap
              <span className="pull-right-container">
                <i className="fa fa-angle-right pull-right"></i>
              </span>
            </Link>
          </li>
          <li>
            <Link to="/frequently_asked">
              <i className="icon-equalizer"></i>
              Frequently Asked
              <span className="pull-right-container">
                <i className="fa fa-angle-right pull-right"></i>
              </span>
            </Link>
          </li>
          <li>
            <Link to="/terms_of_service">
              <i className="icon-equalizer"></i>
              Terms of Service
              <span className="pull-right-container">
                <i className="fa fa-angle-right pull-right"></i>
              </span>
            </Link>
          </li> */}
          {/*
































  */}
          {/* START EXCLUDE */}
          {/* 
          <li className="treeview">
            <a data-href="#">
              <i className="icon-wallet"></i>
              <span>Transactions</span>
              <span className="pull-right-container">
                <i className="fa fa-angle-right pull-right"></i>
              </span>
            </a>
            <ul className="treeview-menu">
              <li>
                <a data-href="pages/transactions/tables-transactions.html">
                  Transactions Tables
                </a>
              </li>
              <li>
                <a data-href="pages/transactions/transaction-search.html">
                  Transactions Search
                </a>
              </li>
              <li>
                <a data-href="pages/transactions/transaction-details.html">
                  Single Transaction
                </a>
              </li>
              <li>
                <a data-href="pages/transactions/counter-transactions.html">
                  Transactions Counter
                </a>
              </li>
            </ul>
          </li>
          <li className="treeview">
            <a data-href="#">
              <i className="icon-graph"></i>
              <span>Charts</span>
              <span className="pull-right-container">
                <i className="fa fa-angle-right pull-right"></i>
              </span>
            </a>
            <ul className="treeview-menu">
              <li>
                <a data-href="pages/charts/chartjs.html">ChartJS</a>
              </li>
              <li>
                <a data-href="pages/charts/flot.html">Flot</a>
              </li>
              <li>
                <a data-href="pages/charts/inline.html">Inline charts</a>
              </li>
              <li>
                <a data-href="pages/charts/morris.html">Morris</a>
              </li>
              <li>
                <a data-href="pages/charts/peity.html">Peity</a>
              </li>
              <li>
                <a data-href="pages/charts/chartist.html">Chartist</a>
              </li>
              <li>
                <a data-href="pages/charts/rickshaw-charts.html">
                  Rickshaw Charts
                </a>
              </li>
              <li>
                <a data-href="pages/charts/nvd3-charts.html">NVD3 Charts</a>
              </li>
              <li>
                <a data-href="pages/charts/echart.html">eChart</a>
              </li>
              <li>
                <a data-href="pages/amcharts/amcharts.html">amCharts Charts</a>
              </li>
              <li>
                <a data-href="pages/amcharts/amstock-charts.html">
                  amCharts Stock Charts
                </a>
              </li>
              <li>
                <a data-href="pages/amcharts/ammaps.html">amCharts Maps</a>
              </li>
            </ul>
          </li>
          <li className="treeview">
            <a data-href="#">
              <i className="icon-grid"></i>
              <span>App</span>
              <span className="pull-right-container">
                <i className="fa fa-angle-right pull-right"></i>
              </span>
            </a>
            <ul className="treeview-menu">
              <li>
                <a data-href="pages/app/app-chat.html">Chat app</a>
              </li>
              <li>
                <a data-href="pages/app/project-table.html">Project</a>
              </li>
              <li>
                <a data-href="pages/app/app-contact.html">Contact / Employee</a>
              </li>
              <li>
                <a data-href="pages/app/app-ticket.html">Support Ticket</a>
              </li>
              <li>
                <a data-href="pages/app/calendar.html">Calendar</a>
              </li>
              <li>
                <a data-href="pages/app/profile.html">Profile</a>
              </li>
              <li>
                <a data-href="pages/app/userlist-grid.html">Userlist Grid</a>
              </li>
              <li>
                <a data-href="pages/app/userlist.html">Userlist</a>
              </li>
            </ul>
          </li>
          <li className="treeview">
            <a data-href="#">
              <i className="icon-envelope"></i> <span>Mailbox</span>
              <span className="pull-right-container">
                <i className="fa fa-angle-right pull-right"></i>
              </span>
            </a>
            <ul className="treeview-menu">
              <li>
                <a data-href="pages/mailbox/mailbox.html">Inbox</a>
              </li>
              <li>
                <a data-href="pages/mailbox/compose.html">Compose</a>
              </li>
              <li>
                <a data-href="pages/mailbox/read-mail.html">Read</a>
              </li>
            </ul>
          </li>
          <li className="treeview">
            <a data-href="#">
              <i className="icon-drop"></i>
              <span>UI Elements</span>
              <span className="pull-right-container">
                <i className="fa fa-angle-right pull-right"></i>
              </span>
            </a>
            <ul className="treeview-menu">
              <li>
                <a data-href="pages/UI/badges.html">Badges</a>
              </li>
              <li>
                <a data-href="pages/UI/border-utilities.html">Border</a>
              </li>
              <li>
                <a data-href="pages/UI/buttons.html">Buttons</a>
              </li>
              <li>
                <a data-href="pages/UI/bootstrap-switch.html">
                  Bootstrap Switch
                </a>
              </li>
              <li>
                <a data-href="pages/UI/cards.html">User Card</a>
              </li>
              <li>
                <a data-href="pages/UI/color-utilities.html">Color</a>
              </li>
              <li>
                <a data-href="pages/UI/date-paginator.html">Date Paginator</a>
              </li>
              <li>
                <a data-href="pages/UI/dropdown.html">Dropdown</a>
              </li>
              <li>
                <a data-href="pages/UI/dropdown-grid.html">Dropdown Grid</a>
              </li>
              <li>
                <a data-href="pages/UI/general.html">General</a>
              </li>
              <li>
                <a data-href="pages/UI/icons.html">Icons</a>
              </li>
              <li>
                <a data-href="pages/UI/media-advanced.html">Advanced Medias</a>
              </li>
              <li>
                <a data-href="pages/UI/modals.html">Modals</a>
              </li>
              <li>
                <a data-href="pages/UI/nestable.html">Nestable</a>
              </li>
              <li>
                <a data-href="pages/UI/notification.html">Notification</a>
              </li>
              <li>
                <a data-href="pages/UI/portlet-draggable.html">
                  Draggable Portlets
                </a>
              </li>
              <li>
                <a data-href="pages/UI/ribbons.html">Ribbons</a>
              </li>
              <li>
                <a data-href="pages/UI/sliders.html">Sliders</a>
              </li>
              <li>
                <a data-href="pages/UI/sweatalert.html">Sweet Alert</a>
              </li>
              <li>
                <a data-href="pages/UI/tab.html">Tabs</a>
              </li>
              <li>
                <a data-href="pages/UI/timeline.html">Timeline</a>
              </li>
              <li>
                <a data-href="pages/UI/timeline-horizontal.html">
                  Horizontal Timeline
                </a>
              </li>
            </ul>
          </li>
          <li className="header nav-small-cap">ANALYZE</li>
          <li className="treeview">
            <a data-href="#">
              <i className="icon-puzzle"></i>
              <span>Widgets</span>
              <span className="pull-right-container">
                <i className="fa fa-angle-right pull-right"></i>
              </span>
            </a>
            <ul className="treeview-menu">
              <li>
                <a data-href="pages/widgets/blog.html">Blog</a>
              </li>
              <li>
                <a data-href="pages/widgets/chart.html">Chart</a>
              </li>
              <li>
                <a data-href="pages/widgets/list.html">List</a>
              </li>
              <li>
                <a data-href="pages/widgets/social.html">Social</a>
              </li>
              <li>
                <a data-href="pages/widgets/statistic.html">Statistic</a>
              </li>
              <li>
                <a data-href="pages/widgets/tiles.html">Tiles</a>
              </li>
              <li>
                <a data-href="pages/widgets/weather.html">Weather</a>
              </li>
              <li>
                <a data-href="pages/widgets/widgets.html">Widgets</a>
              </li>
            </ul>
          </li>
           */}
          {/* END EXCLUDE */}
          {/* <li className="treeview">
            <a data-href="#">
              <i className="icon-layers"></i>
              <span>Layout Options</span>
              <span className="pull-right-container">
                <i className="fa fa-angle-right pull-right"></i>
              </span>
            </a>
            <ul className="treeview-menu">
              <li>
                <a data-href="pages/layout/boxed.html">Boxed</a>
              </li>
              <li>
                <a data-href="pages/layout/fixed.html">Fixed</a>
              </li>
              <li>
                <a data-href="pages/layout/collapsed-sidebar.html">
                  Collapsed Sidebar
                </a>
              </li>
            </ul>
          </li>
          <li className="treeview">
            <a data-href="#">
              <i className="icon-loop"></i>
              <span>Box</span>
              <span className="pull-right-container">
                <i className="fa fa-angle-right pull-right"></i>
              </span>
            </a>
            <ul className="treeview-menu">
              <li>
                <a data-href="pages/box/advanced.html">Advanced</a>
              </li>
              <li>
                <a data-href="pages/box/basic.html">Boxed</a>
              </li>
              <li>
                <a data-href="pages/box/color.html">Color</a>
              </li>
              <li>
                <a data-href="pages/box/group.html">Group</a>
              </li>
            </ul>
          </li>
          <li className="treeview">
            <a data-href="#">
              <i className="icon-note"></i> <span>Forms</span>
              <span className="pull-right-container">
                <i className="fa fa-angle-right pull-right"></i>
              </span>
            </a>
            <ul className="treeview-menu">
              <li>
                <a data-href="pages/forms/advanced.html">Advanced Elements</a>
              </li>
              <li>
                <a data-href="pages/forms/code-editor.html">Code Editor</a>
              </li>
              <li>
                <a data-href="pages/forms/editor-markdown.html">Markdown</a>
              </li>
              <li>
                <a data-href="pages/forms/editors.html">Editors</a>
              </li>
              <li>
                <a data-href="pages/forms/form-validation.html">
                  Form Validation
                </a>
              </li>
              <li>
                <a data-href="pages/forms/form-wizard.html">Form Wizard</a>
              </li>
              <li>
                <a data-href="pages/forms/general.html">General Elements</a>
              </li>
              <li>
                <a data-href="pages/forms/mask.html">Formatter</a>
              </li>
              <li>
                <a data-href="pages/forms/premade.html">Pre-made Forms</a>
              </li>
              <li>
                <a data-href="pages/forms/xeditable.html">Xeditable Editor</a>
              </li>
            </ul>
          </li>
          <li className="treeview">
            <a data-href="#">
              <i className="icon-menu"></i> <span>Tables</span>
              <span className="pull-right-container">
                <i className="fa fa-angle-right pull-right"></i>
              </span>
            </a>
            <ul className="treeview-menu">
              <li>
                <a data-href="pages/tables/simple.html">Simple tables</a>
              </li>
              <li>
                <a data-href="pages/tables/data.html">Data tables</a>
              </li>
              <li>
                <a data-href="pages/tables/editable-tables.html">
                  Editable Tables
                </a>
              </li>
              <li>
                <a data-href="pages/tables/table-color.html">Table Color</a>
              </li>
            </ul>
          </li>
          <li>
            <a data-href="pages/email/index.html">
              <i className="icon-envelope-open"></i> <span>Emails</span>
              <span className="pull-right-container">
                <i className="fa fa-angle-right pull-right"></i>
              </span>
            </a>
          </li>
          <li className="header nav-small-cap">OBSERVE</li>
          <li className="treeview">
            <a data-href="#">
              <i className="icon-map"></i> <span>Map</span>
              <span className="pull-right-container">
                <i className="fa fa-angle-right pull-right"></i>
              </span>
            </a>
            <ul className="treeview-menu">
              <li>
                <a data-href="pages/map/map-google.html">Google Map</a>
              </li>
              <li>
                <a data-href="pages/map/map-vector.html">Vector Map</a>
              </li>
            </ul>
          </li>
          <li className="treeview">
            <a data-href="#">
              <i className="icon-magnet"></i> <span>Extension</span>
              <span className="pull-right-container">
                <i className="fa fa-angle-right pull-right"></i>
              </span>
            </a>
            <ul className="treeview-menu">
              <li>
                <a data-href="pages/extension/fullscreen.html">Fullscreen</a>
              </li>
            </ul>
          </li>
          <li className="treeview">
            <a data-href="#">
              <i className="icon-folder-alt"></i> <span>Sample Pages</span>
              <span className="pull-right-container">
                <i className="fa fa-angle-right pull-right"></i>
              </span>
            </a>
            <ul className="treeview-menu">
              <li>
                <a data-href="pages/samplepage/blank.html">Blank</a>
              </li>
              <li>
                <a data-href="pages/samplepage/coming-soon.html">Coming Soon</a>
              </li>
              <li>
                <a data-href="pages/samplepage/custom-scroll.html">
                  Custom Scrolls
                </a>
              </li>
              <li>
                <a data-href="pages/samplepage/faq.html">FAQ</a>
              </li>
              <li>
                <a data-href="pages/samplepage/gallery.html">Gallery</a>
              </li>
              <li>
                <a data-href="pages/samplepage/invoice.html">Invoice</a>
              </li>
              <li>
                <a data-href="pages/samplepage/lightbox.html">Lightbox Popup</a>
              </li>
              <li>
                <a data-href="pages/samplepage/pace.html">Pace</a>
              </li>
              <li>
                <a data-href="pages/samplepage/pricing.html">Pricing</a>
              </li>
              <li className="treeview">
                <a data-href="#">
                  Authentication
                  <span className="pull-right-container">
                    <i className="fa fa-angle-right pull-right"></i>
                  </span>
                </a>
                <ul className="treeview-menu">
                  <li>
                    <a data-href="pages/samplepage/login.html">Login</a>
                  </li>
                  <li>
                    <a data-href="pages/samplepage/register.html">Register</a>
                  </li>
                  <li>
                    <a data-href="pages/samplepage/lockscreen.html">
                      Lockscreen
                    </a>
                  </li>
                  <li>
                    <a data-href="pages/samplepage/user-pass.html">
                      Recover password
                    </a>
                  </li>
                </ul>
              </li>
              <li className="treeview">
                <a data-href="#">
                  Error Pages
                  <span className="pull-right-container">
                    <i className="fa fa-angle-right pull-right"></i>
                  </span>
                </a>
                <ul className="treeview-menu">
                  <li>
                    <a data-href="pages/samplepage/404.html">404</a>
                  </li>
                  <li>
                    <a data-href="pages/samplepage/500.html">500</a>
                  </li>
                  <li>
                    <a data-href="pages/samplepage/maintenance.html">
                      Maintenance
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li className="treeview">
            <a data-href="#">
              <i className="icon-menu"></i> <span>Multilevel</span>
              <span className="pull-right-container">
                <i className="fa fa-angle-right pull-right"></i>
              </span>
            </a>
            <ul className="treeview-menu">
              <li>
                <a data-href="#">Level One</a>
              </li>
              <li className="treeview">
                <a data-href="#">
                  Level One
                  <span className="pull-right-container">
                    <i className="fa fa-angle-right pull-right"></i>
                  </span>
                </a>
                <ul className="treeview-menu">
                  <li>
                    <a data-href="#">Level Two</a>
                  </li>
                  <li className="treeview">
                    <a data-href="#">
                      Level Two
                      <span className="pull-right-container">
                        <i className="fa fa-angle-right pull-right"></i>
                      </span>
                    </a>
                    <ul className="treeview-menu">
                      <li>
                        <a data-href="#">Level Three</a>
                      </li>
                      <li>
                        <a data-href="#">Level Three</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <a data-href="#">Level One</a>
              </li>
            </ul>
          </li>
          <li className="header nav-small-cap">CONTRIBUTE</li>
          <li className="treeview">
            <a data-href="#">
              <i className="icon-map"></i> <span>Map</span>
              <span className="pull-right-container">
                <i className="fa fa-angle-right pull-right"></i>
              </span>
            </a>
            <ul className="treeview-menu">
              <li>
                <a data-href="pages/map/map-google.html">Google Map</a>
              </li>
              <li>
                <a data-href="pages/map/map-vector.html">Vector Map</a>
              </li>
            </ul>
          </li>
          <li className="header nav-small-cap">LAUNCHPAD</li>
          <li className="treeview">
            <a data-href="#">
              <i className="icon-map"></i> <span>Map</span>
              <span className="pull-right-container">
                <i className="fa fa-angle-right pull-right"></i>
              </span>
            </a>
            <ul className="treeview-menu">
              <li>
                <a data-href="pages/map/map-google.html">Google Map</a>
              </li>
              <li>
                <a data-href="pages/map/map-vector.html">Vector Map</a>
              </li>
            </ul>
          </li> */}
        </ul>
      </section>
    </aside>
  );
};

export const SkinnedFooter = (props: { pageName: string }) => {
  const { pageName } = props;
  //
  return !pageName ? (
    <></>
  ) : (
    <footer className="main-footer">
      <div className="pull-right d-none d-sm-inline-block">
        {/* <ul className="nav nav-primary nav-dotted nav-dot-separated justify-content-center justify-content-md-end">
          <li className="nav-item">
            <a className="nav-link" href="#">
              FAQ
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">
              Purchase Now
            </a>
          </li>
        </ul> */}
      </div>
      &copy; 2022{" "}
      <a data-href="https://www.multipurposethemes.com/">
        KYC.Foundation, Know Your Contract.
      </a>
      . All Rights Reserved.
    </footer>
  );
};

export const SkinnedControlSideBar = (props: { pageName: string }) => {
  const { pageName } = props;
  //
  return !pageName ? (
    <></>
  ) : (
    <aside className="control-sidebar control-sidebar-dark">
      {/* <!-- Create the tabs --> */}
      <ul className="nav nav-tabs nav-justified control-sidebar-tabs">
        <li className="nav-item">
          <a data-href="#control-sidebar-home-tab" data-toggle="tab">
            <i className="fa fa-home"></i>
          </a>
        </li>
        <li className="nav-item">
          <a data-href="#control-sidebar-settings-tab" data-toggle="tab">
            <i className="fa fa-cog fa-spin"></i>
          </a>
        </li>
      </ul>
      {/* <!-- Tab panes --> */}
      <div className="tab-content">
        {/* <!-- Home tab content --> */}
        <div className="tab-pane" id="control-sidebar-home-tab">
          <h3 className="control-sidebar-heading">Recent Activity</h3>
          <ul className="control-sidebar-menu">
            <li>
              <a data-href="#">
                <i className="menu-icon fa fa-birthday-cake bg-red"></i>

                <div className="menu-info">
                  <h4 className="control-sidebar-subheading">Admin Birthday</h4>

                  <p>Will be July 24th</p>
                </div>
              </a>
            </li>
            <li>
              <a data-href="#">
                <i className="menu-icon fa fa-user bg-yellow"></i>

                <div className="menu-info">
                  <h4 className="control-sidebar-subheading">
                    Jhone Updated His Profile
                  </h4>

                  <p>New Email : jhone_doe@demo.com</p>
                </div>
              </a>
            </li>
            <li>
              <a data-href="#">
                <i className="menu-icon fa fa-envelope-o bg-light-blue"></i>

                <div className="menu-info">
                  <h4 className="control-sidebar-subheading">
                    Disha Joined Mailing List
                  </h4>

                  <p>disha@demo.com</p>
                </div>
              </a>
            </li>
            <li>
              <a data-href="#">
                <i className="menu-icon fa fa-file-code-o bg-green"></i>

                <div className="menu-info">
                  <h4 className="control-sidebar-subheading">Code Change</h4>

                  <p>Execution time 15 Days</p>
                </div>
              </a>
            </li>
          </ul>
          {/* <!-- /.control-sidebar-menu --> */}

          <h3 className="control-sidebar-heading">Tasks Progress</h3>
          <ul className="control-sidebar-menu">
            <li>
              <a data-href="#">
                <h4 className="control-sidebar-subheading">
                  Web Design
                  <span className="label label-danger pull-right">40%</span>
                </h4>

                <div className="progress progress-xxs">
                  <div
                    className="progress-bar progress-bar-danger"
                    style={{ width: "40%" }}
                  ></div>
                </div>
              </a>
            </li>
            <li>
              <a data-href="#">
                <h4 className="control-sidebar-subheading">
                  Update Data
                  <span className="label label-success pull-right">75%</span>
                </h4>

                <div className="progress progress-xxs">
                  <div
                    className="progress-bar progress-bar-success"
                    style={{ width: "75%" }}
                  ></div>
                </div>
              </a>
            </li>
            <li>
              <a data-href="#">
                <h4 className="control-sidebar-subheading">
                  Order Process
                  <span className="label label-warning pull-right">89%</span>
                </h4>

                <div className="progress progress-xxs">
                  <div
                    className="progress-bar progress-bar-warning"
                    style={{ width: "89%" }}
                  ></div>
                </div>
              </a>
            </li>
            <li>
              <a data-href="#">
                <h4 className="control-sidebar-subheading">
                  Development
                  <span className="label label-primary pull-right">72%</span>
                </h4>

                <div className="progress progress-xxs">
                  <div
                    className="progress-bar progress-bar-primary"
                    style={{ width: "72%" }}
                  ></div>
                </div>
              </a>
            </li>
          </ul>
          {/* <!-- /.control-sidebar-menu --> */}
        </div>
        {/* <!-- /.tab-pane --> */}
        {/* <!-- Stats tab content --> */}
        <div className="tab-pane" id="control-sidebar-stats-tab">
          Stats Tab Content
        </div>
        {/* <!-- /.tab-pane --> */}
        {/* <!-- Settings tab content --> */}
        <div className="tab-pane" id="control-sidebar-settings-tab">
          <form method="post">
            <h3 className="control-sidebar-heading">General Settings</h3>

            <div className="form-group">
              <input
                type="checkbox"
                id="report_panel"
                className="chk-col-grey"
              />
              <label
                data-for="report_panel"
                className="control-sidebar-subheading "
              >
                Report panel usage
              </label>

              <p>general settings information</p>
            </div>
            {/* <!-- /.form-group --> */}

            <div className="form-group">
              <input type="checkbox" id="allow_mail" className="chk-col-grey" />
              <label
                data-for="allow_mail"
                className="control-sidebar-subheading "
              >
                Mail redirect
              </label>

              <p>Other sets of options are available</p>
            </div>
            {/* <!-- /.form-group --> */}

            <div className="form-group">
              <input
                type="checkbox"
                id="expose_author"
                className="chk-col-grey"
              />
              <label
                data-for="expose_author"
                className="control-sidebar-subheading "
              >
                Expose author name
              </label>

              <p>Allow the user to show his name in blog posts</p>
            </div>
            {/* <!-- /.form-group --> */}

            <h3 className="control-sidebar-heading">Chat Settings</h3>

            <div className="form-group">
              <input
                type="checkbox"
                id="show_me_online"
                className="chk-col-grey"
              />
              <label
                data-for="show_me_online"
                className="control-sidebar-subheading "
              >
                Show me as online
              </label>
            </div>
            {/* <!-- /.form-group --> */}

            <div className="form-group">
              <input
                type="checkbox"
                id="off_notifications"
                className="chk-col-grey"
              />
              <label
                data-for="off_notifications"
                className="control-sidebar-subheading "
              >
                Turn off notifications
              </label>
            </div>
            {/* <!-- /.form-group --> */}

            <div className="form-group">
              <label className="control-sidebar-subheading">
                <a data-href="#" className="text-red margin-r-5">
                  <i className="fa fa-trash-o"></i>
                </a>
                Delete chat history
              </label>
            </div>
            {/* <!-- /.form-group --> */}
          </form>
        </div>
        {/* <!-- /.tab-pane --> */}
      </div>
    </aside>
  );
};

const OpenPageContent = () => {
  return (
    <>
      <section className="content">
        <div className="row">
          <div className="col-xl-3 col-12">
            <div className="box">
              <div className="box-header">
                <h4 className="box-title">
                  <i className="fa fa-university mr-15"></i>Balances
                </h4>
              </div>
              <div className="box-body p-0">
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>Currency</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>BTC</td>
                        <td>0.125481254803000</td>
                      </tr>
                      <tr>
                        <td>LTC</td>
                        <td>0.125481254803000</td>
                      </tr>
                      <tr>
                        <td>DASH</td>
                        <td>0.125481254803000</td>
                      </tr>
                      <tr>
                        <td>DOGE</td>
                        <td>0.125481254803000</td>
                      </tr>
                      <tr>
                        <td>YOVI</td>
                        <td>0.125481254803000</td>
                      </tr>
                      <tr>
                        <td>ETH</td>
                        <td>0.125481254803000</td>
                      </tr>
                      <tr>
                        <td>LSK</td>
                        <td>0.125481254803000</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <th className="text-fade font-weight-600">Est. BTC:</th>
                        <th className="text-fade font-weight-600">0.00 BTC</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-9 col-12">
            <div className="box">
              <div className="box-body bb-1 bg-dark">
                <div className="d-md-flex justify-content-between align-items-center d-inline-block">
                  <h3 className="my-0">
                    <i className="fa fa-bar-chart mr-5"></i>
                    <span className="text-info">ETH</span> /{" "}
                    <span className="text-info">BTC</span>
                  </h3>
                  <div>
                    <h5 className="mb-0">
                      <span className="mr-5 text-white-50">Last:</span>
                      <span className="text-white">0.03242000</span>
                    </h5>
                  </div>
                  <div>
                    <h5 className="mb-0">
                      <span className="mr-5 text-white-50">24High:</span>
                      <span className="text-success">0.03301357</span>
                    </h5>
                  </div>
                  <div>
                    <h5 className="mb-0">
                      <span className="mr-5 text-white-50">24Low:</span>
                      <span className="text-danger">0.03089614</span>
                    </h5>
                  </div>
                  <div>
                    <h5 className="mb-0">
                      <span className="mr-5 text-white-50">24V:</span>
                      <span className="text-info">1001.66 BTC</span>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="box-body">
                <div className="chart">
                  <div id="bitcoin-stock"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-12">
            <div className="box">
              <div className="box-header">
                <h4 className="box-title">
                  <i className="fa fa-building mr-15"></i>Market
                </h4>
                <div className="box-controls pull-right">
                  <div className="lookup lookup-circle lookup-right">
                    <input type="text" name="s" />
                  </div>
                </div>
              </div>
              <div className="box-body p-0">
                <div className="table-responsivec coin-table mb-35">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th style={{ width: "25%" }}>Name</th>
                        <th style={{ width: "33%" }}>Price</th>
                        <th style={{ width: "26%" }}>Ch.</th>
                        <th style={{ width: "16%" }}>Vol.</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>YOVI</td>
                        <td>0.03139400</td>
                        <td>0%</td>
                        <td>0.1</td>
                      </tr>
                      <tr>
                        <td>ETH</td>
                        <td>0.03265921</td>
                        <td className="text-success">+ 4.7%</td>
                        <td>958.6</td>
                      </tr>
                      <tr>
                        <td>EGOLD</td>
                        <td>0.00000005</td>
                        <td>0%</td>
                        <td>858.8</td>
                      </tr>
                      <tr>
                        <td>DASH</td>
                        <td>0.01983297</td>
                        <td className="text-success">+ 2.8%</td>
                        <td>670.5</td>
                      </tr>
                      <tr>
                        <td>ZEC</td>
                        <td>0.01003493</td>
                        <td className="text-success">+ 6.1%</td>
                        <td>609.1</td>
                      </tr>
                      <tr>
                        <td>LSK</td>
                        <td>0.00025414</td>
                        <td className="text-success">+ 7.3%</td>
                        <td>322.7</td>
                      </tr>
                      <tr>
                        <td>WAVES</td>
                        <td>0.00031160</td>
                        <td className="text-danger">- 0.3%</td>
                        <td>263.3</td>
                      </tr>
                      <tr>
                        <td>LTC</td>
                        <td>0.01351381</td>
                        <td className="text-success">+ 3.4%</td>
                        <td>142.9</td>
                      </tr>
                      <tr>
                        <td>YOBTC</td>
                        <td>0.01500400</td>
                        <td>0%</td>
                        <td>95</td>
                      </tr>
                      <tr>
                        <td>BTC</td>
                        <td>$8762.00</td>
                        <td className="text-success">+ 1.6%</td>
                        <td>76.9</td>
                      </tr>
                      <tr>
                        <td>YOVI</td>
                        <td>0.03139400</td>
                        <td>0%</td>
                        <td>0.1</td>
                      </tr>
                      <tr>
                        <td>ETH</td>
                        <td>0.03265921</td>
                        <td className="text-success">+ 4.7%</td>
                        <td>958.6</td>
                      </tr>
                      <tr>
                        <td>EGOLD</td>
                        <td>0.00000005</td>
                        <td>0%</td>
                        <td>858.8</td>
                      </tr>
                      <tr>
                        <td>DASH</td>
                        <td>0.01983297</td>
                        <td className="text-success">+ 2.8%</td>
                        <td>670.5</td>
                      </tr>
                      <tr>
                        <td>ZEC</td>
                        <td>0.01003493</td>
                        <td className="text-success">+ 6.1%</td>
                        <td>609.1</td>
                      </tr>
                      <tr>
                        <td>LSK</td>
                        <td>0.00025414</td>
                        <td className="text-success">+ 7.3%</td>
                        <td>322.7</td>
                      </tr>
                      <tr>
                        <td>WAVES</td>
                        <td>0.00031160</td>
                        <td className="text-danger">- 0.3%</td>
                        <td>263.3</td>
                      </tr>
                      <tr>
                        <td>LTC</td>
                        <td>0.01351381</td>
                        <td className="text-success">+ 3.4%</td>
                        <td>142.9</td>
                      </tr>
                      <tr>
                        <td>YOBTC</td>
                        <td>0.01500400</td>
                        <td>0%</td>
                        <td>95</td>
                      </tr>
                      <tr>
                        <td>BTC</td>
                        <td>$8762.00</td>
                        <td className="text-success">+ 1.6%</td>
                        <td>76.9</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <th style={{ width: "25%" }} className="text-fade">
                          Name
                        </th>
                        <th style={{ width: "33%" }} className="text-fade">
                          Price
                        </th>
                        <th style={{ width: "26%" }} className="text-fade">
                          Ch.
                        </th>
                        <th style={{ width: "16%" }} className="text-fade">
                          Vol.
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-8 col-12">
            <div className="box">
              <ul className="nav nav-tabs nav-tabs-inverse-mode" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    href="#limit"
                    role="tab"
                  >
                    Limit
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#market"
                    role="tab"
                  >
                    Market
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#stoplimit"
                    role="tab"
                  >
                    Stop-Limit
                  </a>
                </li>
              </ul>
              <div className="box-body tab-content">
                <div className="tab-pane fade active show" id="limit">
                  <div className="row">
                    <div className="col-xl-6 col-12">
                      <div className="d-flex justify-content-between mt-15">
                        <h4>Buy</h4>
                        <p className="mb-0">0.03253643</p>
                      </div>

                      <form className="dash-form">
                        <div className="form-group row align-items-center">
                          <label className="col-sm-2 col-form-label">
                            Balance:{" "}
                          </label>
                          <div className="col-sm-10">
                            <a data-href="#" className="text-info">
                              0.00000000 BTC
                            </a>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Amount:
                          </label>
                          <div className="col-sm-10">
                            <input
                              className="form-control"
                              type="text"
                              value="1.00000000"
                            />
                            <span className="currency">ETH</span>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Price:
                          </label>
                          <div className="col-sm-10">
                            <input
                              className="form-control"
                              type="text"
                              value="0.03240000"
                            />
                            <span className="currency">BTC</span>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Total:
                          </label>
                          <div className="col-sm-10">
                            <input
                              className="form-control"
                              type="text"
                              value="0.03240000"
                            />
                            <span className="currency">BTC</span>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Fee (0.2%):
                          </label>
                          <div className="col-sm-10">
                            <input
                              className="form-control"
                              type="text"
                              value="0.00006480"
                              disabled
                            />
                            <span className="currency">BTC</span>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Total+Fee:
                          </label>
                          <div className="col-sm-10">
                            <input
                              className="form-control"
                              type="text"
                              value="0.03246480"
                              disabled
                            />
                            <span className="currency">BTC</span>
                          </div>
                        </div>
                        <button type="submit" className="btn btn-success mt-20">
                          Buy
                        </button>
                      </form>
                    </div>
                    <div className="col-xl-6 col-12">
                      <div className="d-flex justify-content-between mt-15">
                        <h4>Sell</h4>
                        <p className="mb-0">0.03255681</p>
                      </div>
                      <form className="dash-form">
                        <div className="form-group row align-items-center">
                          <label className="col-sm-2 col-form-label">
                            Balance:{" "}
                          </label>
                          <div className="col-sm-10">
                            <a data-href="#" className="text-info">
                              0.00000000 ETH
                            </a>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Amount:
                          </label>
                          <div className="col-sm-10">
                            <input
                              className="form-control"
                              type="text"
                              value="1.00000000"
                            />
                            <span className="currency">ETH</span>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Price:
                          </label>
                          <div className="col-sm-10">
                            <input
                              className="form-control"
                              type="text"
                              value="0.03240000"
                            />
                            <span className="currency">BTC</span>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Total:
                          </label>
                          <div className="col-sm-10">
                            <input
                              className="form-control"
                              type="text"
                              value="0.03240000"
                            />
                            <span className="currency">BTC</span>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Fee (0.2%):
                          </label>
                          <div className="col-sm-10">
                            <input
                              className="form-control"
                              type="text"
                              value="0.00006480"
                              disabled
                            />
                            <span className="currency">BTC</span>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Total+Fee:
                          </label>
                          <div className="col-sm-10">
                            <input
                              className="form-control"
                              type="text"
                              value="0.03246480"
                              disabled
                            />
                            <span className="currency">BTC</span>
                          </div>
                        </div>
                        <button type="submit" className="btn btn-info mt-20">
                          Sell
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="market">
                  <div className="row">
                    <div className="col-xl-6 col-12">
                      <div className="d-flex justify-content-between mt-15">
                        <h4>Buy</h4>
                        <p className="mb-0">0.03253643</p>
                      </div>

                      <form className="dash-form">
                        <div className="form-group row align-items-center">
                          <label className="col-sm-2 col-form-label">
                            Balance:{" "}
                          </label>
                          <div className="col-sm-10">
                            <a data-href="#" className="text-info">
                              0.00000000 BTC
                            </a>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Amount:
                          </label>
                          <div className="col-sm-10">
                            <input
                              className="form-control"
                              type="text"
                              value="1.00000000"
                            />
                            <span className="currency">ETH</span>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Price:
                          </label>
                          <div className="col-sm-10">
                            <input
                              className="form-control"
                              type="text"
                              value="0.03240000"
                            />
                            <span className="currency">BTC</span>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Total:
                          </label>
                          <div className="col-sm-10">
                            <input
                              className="form-control"
                              type="text"
                              value="0.03240000"
                            />
                            <span className="currency">BTC</span>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Fee (0.2%):
                          </label>
                          <div className="col-sm-10">
                            <input
                              className="form-control"
                              type="text"
                              value="0.00006480"
                              disabled
                            />
                            <span className="currency">BTC</span>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Total+Fee:
                          </label>
                          <div className="col-sm-10">
                            <input
                              className="form-control"
                              type="text"
                              value="0.03246480"
                              disabled
                            />
                            <span className="currency">BTC</span>
                          </div>
                        </div>
                        <button type="submit" className="btn btn-success mt-20">
                          Buy
                        </button>
                      </form>
                    </div>
                    <div className="col-xl-6 col-12">
                      <div className="d-flex justify-content-between mt-15">
                        <h4>Sell</h4>
                        <p className="mb-0">0.03255681</p>
                      </div>
                      <form className="dash-form">
                        <div className="form-group row align-items-center">
                          <label className="col-sm-2 col-form-label">
                            Balance:{" "}
                          </label>
                          <div className="col-sm-10">
                            <a data-href="#" className="text-info">
                              0.00000000 ETH
                            </a>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Amount:
                          </label>
                          <div className="col-sm-10">
                            <input
                              className="form-control"
                              type="text"
                              value="1.00000000"
                            />
                            <span className="currency">ETH</span>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Price:
                          </label>
                          <div className="col-sm-10">
                            <input
                              className="form-control"
                              type="text"
                              value="0.03240000"
                            />
                            <span className="currency">BTC</span>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Total:
                          </label>
                          <div className="col-sm-10">
                            <input
                              className="form-control"
                              type="text"
                              value="0.03240000"
                            />
                            <span className="currency">BTC</span>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Fee (0.2%):
                          </label>
                          <div className="col-sm-10">
                            <input
                              className="form-control"
                              type="text"
                              value="0.00006480"
                              disabled
                            />
                            <span className="currency">BTC</span>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Total+Fee:
                          </label>
                          <div className="col-sm-10">
                            <input
                              className="form-control"
                              type="text"
                              value="0.03246480"
                              disabled
                            />
                            <span className="currency">BTC</span>
                          </div>
                        </div>
                        <button type="submit" className="btn btn-info mt-20">
                          Sell
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="stoplimit">
                  <div className="row">
                    <div className="col-xl-6 col-12">
                      <div className="d-flex justify-content-between mt-15">
                        <h4>Buy</h4>
                        <p className="mb-0">0.03253643</p>
                      </div>

                      <form className="dash-form">
                        <div className="form-group row align-items-center">
                          <label className="col-sm-2 col-form-label">
                            Balance:{" "}
                          </label>
                          <div className="col-sm-10">
                            <a data-href="#" className="text-info">
                              0.00000000 BTC
                            </a>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Amount:
                          </label>
                          <div className="col-sm-10">
                            <input
                              className="form-control"
                              type="text"
                              value="1.00000000"
                            />
                            <span className="currency">ETH</span>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Price:
                          </label>
                          <div className="col-sm-10">
                            <input
                              className="form-control"
                              type="text"
                              value="0.03240000"
                            />
                            <span className="currency">BTC</span>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Total:
                          </label>
                          <div className="col-sm-10">
                            <input
                              className="form-control"
                              type="text"
                              value="0.03240000"
                            />
                            <span className="currency">BTC</span>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Fee (0.2%):
                          </label>
                          <div className="col-sm-10">
                            <input
                              className="form-control"
                              type="text"
                              value="0.00006480"
                              disabled
                            />
                            <span className="currency">BTC</span>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Total+Fee:
                          </label>
                          <div className="col-sm-10">
                            <input
                              className="form-control"
                              type="text"
                              value="0.03246480"
                              disabled
                            />
                            <span className="currency">BTC</span>
                          </div>
                        </div>
                        <button type="submit" className="btn btn-success mt-20">
                          Buy
                        </button>
                      </form>
                    </div>
                    <div className="col-xl-6 col-12">
                      <div className="d-flex justify-content-between mt-15">
                        <h4>Sell</h4>
                        <p className="mb-0">0.03255681</p>
                      </div>
                      <form className="dash-form">
                        <div className="form-group row align-items-center">
                          <label className="col-sm-2 col-form-label">
                            Balance:{" "}
                          </label>
                          <div className="col-sm-10">
                            <a data-href="#" className="text-info">
                              0.00000000 ETH
                            </a>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Amount:
                          </label>
                          <div className="col-sm-10">
                            <input
                              className="form-control"
                              type="text"
                              value="1.00000000"
                            />
                            <span className="currency">ETH</span>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Price:
                          </label>
                          <div className="col-sm-10">
                            <input
                              className="form-control"
                              type="text"
                              value="0.03240000"
                            />
                            <span className="currency">BTC</span>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Total:
                          </label>
                          <div className="col-sm-10">
                            <input
                              className="form-control"
                              type="text"
                              value="0.03240000"
                            />
                            <span className="currency">BTC</span>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Fee (0.2%):
                          </label>
                          <div className="col-sm-10">
                            <input
                              className="form-control"
                              type="text"
                              value="0.00006480"
                              disabled
                            />
                            <span className="currency">BTC</span>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">
                            Total+Fee:
                          </label>
                          <div className="col-sm-10">
                            <input
                              className="form-control"
                              type="text"
                              value="0.03246480"
                              disabled
                            />
                            <span className="currency">BTC</span>
                          </div>
                        </div>
                        <button type="submit" className="btn btn-info mt-20">
                          Sell
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-6 col-12">
                <div className="box">
                  <div className="box-header">
                    <h4 className="box-title">
                      <i className="fa fa-file mr-15"></i>Sell Order
                    </h4>
                  </div>
                  <div className="box-body p-0">
                    <div className="table-responsive">
                      <table className="table table-hover mb-0">
                        <thead>
                          <tr>
                            <th>Price</th>
                            <th>ETH</th>
                            <th>BTH</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>0.03267800</td>
                            <td>19.9000000</td>
                            <td>0.6502922</td>
                          </tr>
                          <tr>
                            <td>0.03268800</td>
                            <td>11.55521597</td>
                            <td>0.39589116</td>
                          </tr>
                          <tr>
                            <td>0.03278079</td>
                            <td>3.31000000</td>
                            <td>0.50439557</td>
                          </tr>
                          <tr>
                            <td>0.03278080</td>
                            <td>1.73943420</td>
                            <td>0.56141561</td>
                          </tr>
                          <tr>
                            <td>0.03278085</td>
                            <td>25.8800000</td>
                            <td>1.409784</td>
                          </tr>
                          <tr>
                            <td>0.03278086</td>
                            <td>0.03278086</td>
                            <td>1.4111283</td>
                          </tr>
                          <tr>
                            <td>0.03281000</td>
                            <td>0.10000000</td>
                            <td>1.3396325</td>
                          </tr>
                          <tr>
                            <td>0.03289999</td>
                            <td>0.02000000</td>
                            <td>3.42837454</td>
                          </tr>
                          <tr>
                            <td>0.03290000</td>
                            <td>2.95876624</td>
                            <td>12.11393132</td>
                          </tr>
                          <tr>
                            <td>0.03293080</td>
                            <td>0.03917411</td>
                            <td>12.11555068</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-12">
                <div className="box">
                  <div className="box-header">
                    <h4 className="box-title">
                      <i className="fa fa-file mr-15"></i>Buy Order
                    </h4>
                  </div>
                  <div className="box-body p-0">
                    <div className="table-responsive">
                      <table className="table table-hover mb-0">
                        <thead>
                          <tr>
                            <th>Price</th>
                            <th>ETH</th>
                            <th>BTH</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>0.03267800</td>
                            <td>19.9000000</td>
                            <td>0.6502922</td>
                          </tr>
                          <tr>
                            <td>0.03268800</td>
                            <td>11.55521597</td>
                            <td>0.39589116</td>
                          </tr>
                          <tr>
                            <td>0.03278079</td>
                            <td>3.31000000</td>
                            <td>0.50439557</td>
                          </tr>
                          <tr>
                            <td>0.03278080</td>
                            <td>1.73943420</td>
                            <td>0.56141561</td>
                          </tr>
                          <tr>
                            <td>0.03278085</td>
                            <td>25.8800000</td>
                            <td>1.409784</td>
                          </tr>
                          <tr>
                            <td>0.03278086</td>
                            <td>0.03278086</td>
                            <td>1.4111283</td>
                          </tr>
                          <tr>
                            <td>0.03281000</td>
                            <td>0.10000000</td>
                            <td>1.3396325</td>
                          </tr>
                          <tr>
                            <td>0.03289999</td>
                            <td>0.02000000</td>
                            <td>3.42837454</td>
                          </tr>
                          <tr>
                            <td>0.03290000</td>
                            <td>2.95876624</td>
                            <td>12.11393132</td>
                          </tr>
                          <tr>
                            <td>0.03293080</td>
                            <td>0.03917411</td>
                            <td>12.11555068</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-12">
            <div className="box">
              <div className="box-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <p className="box-title mb-5">Total Value of all Coins:</p>
                    <h4 className="mb-0">
                      $ 21.267,87 <span className="text-fade">(703 BTC)</span>
                    </h4>
                  </div>
                  <div>
                    <i className="cc BTC font-size-40 m-0" title="BTC"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-12">
            <div className="box">
              <div className="box-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <p className="box-title mb-5">Total Value of Commodlties</p>
                    <h4 className="mb-0">
                      $ 0,00 <span className="text-fade">(00 BTC)</span>
                    </h4>
                  </div>
                  <div>
                    <i className="cc BTC font-size-40 m-0" title="BTC"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-12">
            <div className="box">
              <div className="box-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <p className="box-title mb-5">
                      Total Value of all Currencies
                    </p>
                    <h4 className="mb-0">
                      $ -2.020,87 <span className="text-fade">(-0,67 BTC)</span>
                    </h4>
                  </div>
                  <div>
                    <i className="cc BTC font-size-40 m-0" title="BTC"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-12">
            <div className="box">
              <div className="box-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <p className="box-title mb-5">Total Account Value</p>
                    <h4 className="mb-0">
                      $ 19.257,87 <span className="text-fade">(636 BTC)</span>
                    </h4>
                  </div>
                  <div>
                    <i className="cc BTC font-size-40 m-0" title="BTC"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-12">
            <div className="box">
              <div className="box-body">
                <div className="d-flex justify-content-between align-items-end">
                  <div>
                    <p className="box-title mb-5">First Trade:</p>
                    <h5 className="mb-0">
                      15 July '18{" "}
                      <span className="text-fade">(295 days ago)</span>
                    </h5>
                  </div>
                  <div>
                    <i className="font-size-30 m-0 fa fa-calendar"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-12">
            <div className="box">
              <div className="box-body">
                <div className="d-flex justify-content-between align-items-end">
                  <div>
                    <p className="box-title mb-5">Last Trade:</p>
                    <h5 className="mb-0">
                      07 May '19{" "}
                      <span className="text-fade">(15 days ago)</span>
                    </h5>
                  </div>
                  <div>
                    <i className="font-size-30 m-0 fa fa-calendar"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-12">
            <div className="box">
              <div className="box-body">
                <div className="d-flex justify-content-between align-items-end">
                  <div>
                    <p className="box-title mb-5">Total Trades:</p>
                    <h5 className="mb-0">
                      13 Trades <span className="text-fade">with 7 Coins</span>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-12">
            <div className="box">
              <div className="box-body">
                <div className="d-flex justify-content-between align-items-end">
                  <div>
                    <p className="box-title mb-5">Current Bitcoin Price:</p>
                    <h5 className="mb-0">
                      $ 3.257,87{" "}
                      <span className="text-fade">(3.547,98 &euro;)</span>
                    </h5>
                  </div>
                  <div>
                    <i className="font-size-30 m-0 ti-wallet"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <script src="js/pages/dashboard.js"></script>
    </>
  );
};

export const SkinnedContentHeader = (props: { pageName: string }) => {
  const { pageName } = props;
  //
  return pageName ? (
    <section
      className={pageName ? "content-header" : ""}
      style={{ marginTop: "20px", marginBottom: "20px" }}
    >
      <h1>{pageName}</h1>
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <a href="#">
            <i className="fa fa-dashboard"></i> Home
          </a>
        </li>
        {/* <li className="breadcrumb-item">
          <a href="#">Examples</a>
        </li> */}
        <li className="breadcrumb-item active">{pageName}</li>
      </ol>
    </section>
  ) : (
    <></>
  );
};

const ApplicationSkin = ({
  content,
  pageName,
}: {
  content: JSX.Element;
  pageName: string;
}) => (
  <>
    {/* <SkinnedHeader pageName={pageName} /> */}
    {/* <!-- Left side column. contains the logo and sidebar --> */}
    <SkinnedLeftSlider pageName={pageName} />
    {/* <!-- Content Wrapper. Contains page content --> */}
    <div
      className="content-wrapper"
      style={{ marginLeft: !pageName ? "0px" : "230px" }}
    >
      {/* <!-- Main content --> */}
      {/* <OpenPageContent /> */}
      {content}
      {/* <!-- /.content --> */}
    </div>
    {/* <!-- /.content-wrapper --> */}

    {/* <!-- Control Sidebar --> */}
    <SkinnedControlSideBar pageName={pageName} />

    {/* <!-- /.control-sidebar --> */}
    <SkinnedFooter pageName={pageName} />
    {/* <!-- Add the sidebar's background. This div must be placed immediately after the control sidebar --> */}
    <div className="control-sidebar-bg"></div>
  </>
);

export default ApplicationSkin;

import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import store from "./redux/store";

import { ChainId, DAppProvider } from "@usedapp/core";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";


// infura:
// id: a787de38c9fa4e4f94481db6ccf78502
// sec: 1770672e0f644bc7bb967a2f283fc421
const config = {
  readOnlyChainId: ChainId.Mainnet,
  readOnlyUrls: {
    //   [ChainId.Mainnet]: 'https://mainnet.infura.io/v3/62687d1a985d4508b2b7a24827551934',
    //  // [ChainId.Mainnet]: 'wss://mainnet.infura.io/ws/v3/a787de38c9fa4e4f94481db6ccf78502',
    [ChainId.Mainnet]:
     // "https://ethereum.api.watchdata.io/node/jsonrpc?api_key=a35d0b6b-1a9f-466f-82d7-1a6173efa793",
       // "https://ethereum.api.watchdata.io/node/jsonrpc?api_key=a35d0b6b-1a9f-466f-82d7-000000000000",
       "https://mainnet-nethermind.blockscout.com/",
      // "https://mainnet.infura.io/v3/a787de38c9fa4e4f94481db6ccf78502",
    // [ChainId.Mainnet]:'wss://mainnet.infura.io/ws/v3/a787de38c9fa4e4f94481db6ccf78502'
  },
};

ReactDOM.render(
  <React.StrictMode>
    <DAppProvider config={config}>
      <Provider store={store}>
        <App />
      </Provider>
    </DAppProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { useState, useEffect } from "react";

import { useBlockMeta, useBlockNumber, useEthers } from "@usedapp/core";
import {
  Container,
  ContentBlock,
  ContentRow,
  MainContent,
  Section,
} from "../../../components/base";
import { Label } from "../../../typography/Label";
import { TextInline } from "../../../typography/Text";

import uniswapToken from "@uniswap/default-token-list";
import { ChainId } from "@usedapp/core";

import styled from "styled-components";
import { Colors } from "../../../global/styles";
import { TokenIcon } from "../../../components/TokensList/TokenIcon";
import IconStar from "../../../assets/icons/Star";

import { DetailedBlockData } from "../../../types";
import {
  API_URL_ROOT,
  extraEntries,
  extraRouterEntries,
} from "../../../settings";
import {
  _as_interface_stripe,
  _as_smart_stripe,
  _as_stripe,
} from "../../../components/SimpleControls";

import KnownAddressesErc20Ethereum from "../../../ai/config/eth_erc20_top_1000_full_by_address.json";

import { ethers } from "ethers";

// import React from "react"; // Step 1 - Include react
import ReactFC from "react-fusioncharts"; // Step 2 - Include the react-fusioncharts component
import FusionCharts from "fusioncharts"; // Step 3 - Include the fusioncharts library
import Column2D from "fusioncharts/fusioncharts.charts"; // Step 4 - Include the chart type
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion"; // Step 5 - Include the theme as fusion
import DataCanvas from "../../../components/DataCanvas";

ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme); // Step 6 - Adding the chart and theme as dependency to the core fusioncharts

export function TransfersChart(props: { envelope: Array<any> }) {
  const { envelope } = props;
  //
  console.log("received props", envelope);
  //
  const createChartData = (
    envelope: Array<any>
  ): Array<{ label: string; value: string }> => {
    const is_empty = envelope.length === 0;
    //
    if (is_empty) return [] as Array<{ label: string; value: string }>;
    else {
      return envelope.map((tx: any, idx) => ({
        //label: String(idx),
        label: `${tx.eth.toFixed(3)} ETH`,
        value: String(tx.eth),
      }));
    }
  };

  const createChordChartNodeData = (
    envelope: Array<any>
  ): Array<{ label: string }> => {
    const is_empty = envelope.length === 0;
    //
    // all froms + all tos
    // distinct
    const all_froms = envelope.map((tx) => tx.from);
    const all_tos = envelope.map((tx) => tx.to);
    const distinct = Array.from(new Set([...all_froms, ...all_tos]));

    //
    if (is_empty) return [] as Array<{ label: string }>;
    else {
      return distinct.map((d) => ({ label: String(d).substring(0, 4) }));
    }
  };

  const createChordChartLinkData = (
    envelope: Array<any>
  ): Array<{ from: string; to: string; value: number }> => {
    const is_empty = envelope.length === 0;
    //

    //
    if (is_empty)
      return [] as Array<{ from: string; to: string; value: number }>;
    else {
      return envelope.map((tx: any, idx) => ({
        from: tx.from.substring(0, 4),
        to: tx.to.substring(0, 4),
        value: Math.floor(tx.eth * 1000000),
      }));
    }
  };

  //
  // Create a JSON object to store the chart configurations
  //
  const dataChart = createChartData(envelope);
  // const dataNodes = createChordChartNodeData(envelope);
  // const dataLinks = createChordChartLinkData(envelope);
  const totalETH = (envelope || [])
    .map((tx) => tx.eth)
    .reduce((a, b) => a + b, 0);
  const numTransfers = (envelope || []).length;
  //
  const ethFormatted = `${totalETH.toFixed(2)} ETH, ${numTransfers} transfers`;
  //
  //console.log("DATA", data);
  // console.log("DATA-NODES", dataNodes);
  // console.log("DATA-LINKS", dataLinks);
  //
  //
  const charLinkConfigSource = {
    chart: {
      caption: "Different countries Gross Export",
      subcaption: "in 2017",
      nodeLabelPosition: "outside",
      showLegend: 0,
      theme: "fusion",
      mode: "post",
      linkColorByDominance: "1",
    },
    nodes: [
      {
        label: "India",
      },
      {
        label: "Canada",
      },
      {
        label: "USA",
      },
      {
        label: "China",
      },
      {
        label: "UK",
      },
      {
        label: "UAE",
      },
    ],
    links: [
      {
        from: "India",
        to: "Canada",
        value: 3040000000,
      },
      {
        from: "India",
        to: "USA",
        value: 46500000000,
      },
      {
        from: "India",
        to: "China",
        value: 12700000000,
      },
      {
        from: "India",
        to: "UK",
        value: 8860000000,
      },
      {
        from: "India",
        to: "UAE",
        value: 27500000000,
      },
      {
        from: "Canada",
        to: "India",
        value: 24490000000,
      },
      {
        from: "Canada",
        to: "USA",
        value: 310000000000,
      },
      {
        from: "Canada",
        to: "China",
        value: 18300000000,
      },
      {
        from: "Canada",
        to: "UK",
        value: 9320000000,
      },
      {
        from: "Canada",
        to: "UAE",
        value: 1830000000,
      },
      {
        from: "USA",
        to: "India",
        value: 24100000000,
      },
      {
        from: "USA",
        to: "Canada",
        value: 277000000000,
      },
      {
        from: "USA",
        to: "China",
        value: 132000000000,
      },
      {
        from: "USA",
        to: "UK",
        value: 53600000000,
      },
      {
        from: "USA",
        to: "UAE",
        value: 19900000000,
      },
      {
        from: "China",
        to: "India",
        value: 68100000000,
      },
      {
        from: "China",
        to: "Canada",
        value: 51900000000,
      },
      {
        from: "China",
        to: "USA",
        value: 480000000000,
      },
      {
        from: "China",
        to: "UK",
        value: 56800000000,
      },
      {
        from: "China",
        to: "UAE",
        value: 45800000000,
      },
      {
        from: "UK",
        to: "Canada",
        value: 62400000000,
      },
      {
        from: "UK",
        to: "USA",
        value: 5780000000,
      },
      {
        from: "UK",
        to: "India",
        value: 518000000,
      },
      {
        from: "UK",
        to: "China",
        value: 21200000000,
      },
      {
        from: "UK",
        to: "UAE",
        value: 9270000000,
      },
      {
        from: "UAE",
        to: "India",
        value: 20500000000,
      },
      {
        from: "UAE",
        to: "Canada",
        value: 299000000,
      },
      {
        from: "UAE",
        to: "USA",
        value: 7470000000,
      },
      {
        from: "UAE",
        to: "China",
        value: 1160000000,
      },
      {
        from: "UAE",
        to: "UK",
        value: 3550000000,
      },
    ],
  };

  const charLinkConfig = {
    type: "pie2d", // The chart type
    width: "420", // Width of the chart
    height: "600", // Height of the chart
    dataFormat: "json", // Data type
    dataSource: charLinkConfigSource,
    // Chart Data - from step 2
    //  nodes: dataNodes,
    //      links: dataLinks,

    // data,
  };

  const chartConfigs = {
    type: "doughnut3d",
    //type: "doughnut2d", // The chart type
    width: "420", // Width of the chart
    height: "600", // Height of the chart
    dataFormat: "json", // Data type
    dataSource: {
      // Chart Configuration
      chart: {
        caption: "Ethereum transfers", //Set the chart caption
        subCaption: ethFormatted, // "including comments", //Set the chart subcaption
        base: "10",
        // xAxisName: "Module sizes", //Set the x-axis name
        // yAxisName: "Contracts matching", //Set the y-axis name
        // numberSuffix: "K",
        theme: "fusion", //Set the theme for your chart
      },
      // Chart Data - from step 2
      data: dataChart,
      // data: chartData,
      // data,
    },
  };

  // and finally render
  // return <ReactFC {...chartConfigs} />;
  return <ReactFC {...chartConfigs} />;
}

const ETH_ERC20_KNOWN_KEYS: Array<string> = Object.keys(
  KnownAddressesErc20Ethereum
);
const translatedAddress = (address: string, size: number) => {
  console.log(
    "ETH_ERC20_KNOWN_KEYS",
    ETH_ERC20_KNOWN_KEYS.length,
    ETH_ERC20_KNOWN_KEYS[0]
  );
  const has =
    (KnownAddressesErc20Ethereum as Record<string, any>)[address] !== undefined;
  //has && console.log(ETH_ERC20_KNOWN_KEYS.length, address, false);
  return has ? (
    // _as_stripe(address, size) //
    <div style={{ marginTop: "4px" }}>
      {_as_smart_stripe(1, address, size, size, true)}
      <span style={{ position: "relative", top: "-10px", fontSize: "11px" }}>
        {
          (KnownAddressesErc20Ethereum as Record<string, any>)[
            address.toLowerCase()
          ].name
        }
      </span>
    </div>
  ) : (
    // (KnownAddressesErc20Ethereum as Record<string, any>)[
    //     address.toLowerCase()
    //   ].nameAndSymbol
    _as_stripe(address, size)
  );
};

const _as_formatted_amount = (str_amount: string, digits: number) => {
  const str = str_amount || "";
  const is_negative = str.length === 78;
  //
  let VAL = ethers.constants.MaxUint256; // 2^256 - num
  try {
    VAL = ethers.constants.MaxUint256.sub(ethers.BigNumber.from(str));
  } catch (ex) {
    VAL = ethers.constants.Two;
  }

  return (
    <>
      {is_negative ? (
        <>{VAL.toString()}</>
      ) : (
        <>
          {str.substring(0, str.length - digits)}.
          <small>{str.substring(str.length - digits)}</small>
        </>
      )}
    </>
  );
};

console.log("loaded", Object.keys(KnownAddressesErc20Ethereum).length);
/*
  const collect_ufos = (value: { UFO: any }) => {
    // collect ufos
    const newUfos = value.UFO;
    const toInsert = [];
    for (const entry of newUfos) {
      const methodID = entry[1];
      if (!ufos.includes(String(methodID))) toInsert.push(methodID);
    }

    const arr = [
      ...(ufos as Array<string>),
      ...(toInsert as Array<string>),
    ].filter((x) => x.indexOf("0x0000000") === -1);
    const set = new Set(arr);
    setUfos(Array.from(set));
  };
  */

const getTokenList = (chainId?: ChainId) => {
  return uniswapToken.tokens.filter((token) => token.chainId === chainId);
};

const intersection = (setA: Set<string>, setB: Set<string>): Set<string> => {
  let _intersection = new Set();
  for (let elem of setB as any) setA.has(elem) && _intersection.add(elem);

  return _intersection as Set<string>;
};

export function Block() {
  const height = useBlockNumber();
  const { chainId } = useEthers();
  const eths = useEthers();
  const { timestamp, difficulty } = useBlockMeta();
  //const chainId = eths.chainId;
  //const cp = eths.library?.provider;
  const cp = eths.library; // !!!!!!!!!
  //
  //if (cp) {
  const [provider] = useState(cp ? (cp as any).currentProvider : null);
  const [chainIddd, setChainIddd] = useState(provider ? provider.chainIddd : 0);
  //
  if (provider) {
    provider.on("chainChanged", (_chainId: any) => {
      alert("changing from " + chainIddd + " to " + _chainId);
      setChainIddd(_chainId);
    });
  }

  useEffect(() => {
    console.log(eths.library);
    // console.log(eths.library?.provider);
  }, [chainId]);
  // }

  //   const [ufos, setUfos] = useState<Array<string>>([]);

  const [currentGasPrice, setCurrentGasPrice] = useState<string>();
  const [lastDetailedBlockData, setLastDetailedBlockData] =
    useState<DetailedBlockData>();

  const [filteredContractList, setFilteredContractList] =
    useState<Array<any>>();
  const [contractList, setContractList] = useState<Array<any>>();
  const [contractAddressList, setContractAddressList] =
    useState<Array<string>>();

  const [leftContractList, setLeftContractList] = useState<Array<any>>();

  // const mode = "eth_erc20";
  const mode = "top_erc20";

  useEffect(() => {
    // console.log("FETCH height", height);
    if (chainId && mode) {
      const url = `${API_URL_ROOT}/contracts?chain=${chainId}&mode=${mode}`;
      console.log("downloading contracts from", url);

      const fetchData = async () => {
        const res = await fetch(url);
        console.log("res", res);
        const json = await res.json();
        console.log("json", json);
        const ok = json && json.ok;

        const data = json.value.contracts;
        console.log("contracts received", data, ok);

        const addresses = data.map((d: { address: string }) => d.address);

        const wDecimals = Object.entries(KnownAddressesErc20Ethereum).map(
          ([key, value]: any) => {
            const address_lo = key.toLowerCase();
            const d = data.filter(
              (da: any) => da.address.toLowerCase() === address_lo
            )[0];

            return d !== undefined
              ? {
                  ...value,
                  address: address_lo,
                  d: d.probe?.base?.decimals || "-1",
                  ts: d.TENSOR?.___TOTAL___?.formatted_short || "No info.",
                  tsbn: d.TENSOR?.___TOTAL___?.W || "-1",
                }
              : {
                  ...value,
                  address: address_lo,
                };
          }
        );
        //
        //
        //
        setContractList(data as Array<any>);
        setContractAddressList(addresses as Array<string>);
        //
        setLeftContractList(wDecimals as Array<any>); // experimental
      };

      fetchData();
    }

    return () =>
      console.log("no effect removal for downloading ORIGINAL contract list");
  }, [chainId, mode]);

  const watchList = (match_addresses: Array<string>) => {
    const s1 = new Set(match_addresses);
    const s2 = new Set(contractAddressList);

    return intersection(s1, s2);
  };

  //
  // known contracts watchlist
  //
  useEffect(() => {
    if (contractAddressList && contractList && lastDetailedBlockData) {
      //
      console.log("WATCHLIST height", height);
      //
      const blockAddresses = Object.keys(lastDetailedBlockData.CAS);
      //
      const identifiedAddresses = watchList(blockAddresses);

      if (identifiedAddresses.size === 0) {
        setFilteredContractList(undefined);
      } else {
        const identified = Array.from(identifiedAddresses);
        const arr = contractList.filter((c) => identified.includes(c.address));
        setFilteredContractList([...arr]);
      }

      return () => console.log("no effect removal for filtered contract list");
    }
  }, [lastDetailedBlockData]);

  const origList = getTokenList(chainId);
  const tokenList = origList.concat(extraEntries).concat(extraRouterEntries);

  const KNOWN_TOKEN_ADDRESSES = tokenList.map(
    (t: { address: string }) => t.address
  );

  //
  // callback when blockNumber changed (detailed)
  //
  useEffect(() => {
    let active = false;
    //
    if (chainId && height) {
      active = true;
      //
      const load = async () => {
        // setLastBlockData(undefined); // this is optional

        const req = fetch(
          `${API_URL_ROOT}/block/${height}?chain=${chainId}`
        );
        const res = await req.then((res) => res.json());
        const { value }: { value: DetailedBlockData } = res;

        if (!active) {
          console.log("effect cancelled");
          return;
        }

        const GP = value.GAS;

        if (GP) {
          const { min, max, avg, sum, l } = GP;
          const gasPriceDisplay = `${min.toFixed(1)} - ${max.toFixed(
            1
          )} (avg: ${avg.toFixed(1)}) Gwei`;
          console.log("GP", GP);

          setCurrentGasPrice(gasPriceDisplay);
        } else {
          setCurrentGasPrice("No info.");
        }

        setLastDetailedBlockData(value);
        // const gasPrice value.values.
      };
      //

      load();
    }
    //
    return () => {
      active = false;
    };
  }, [chainId, height]);

  // console.log({ blockNumber: height, chainId, timestamp, difficulty });
  // const _as_stripe = (from: string, size: number) => {
  //   return account_colors(from).map((color: string) => (
  //     <div
  //       key={Math.random()}
  //       data-id={from}
  //       style={{
  //         display: "inline-block",
  //         width: `${size}px`,
  //         height: `${size}px`,
  //         backgroundColor: color,
  //       }}
  //     >
  //       {" "}
  //     </div>
  //   ));
  // };

  const botColorByMethods = (methodNames: Array<string>) => {
    // 4 types or default;
    // const types = [      "LaunchedAt",      "Manual BL1",      "Manual BL2",      "Manual BL",      "Gas limit",      "External",    ];

    const methodFootprints: Record<string, Array<string>> = {
      LaunchedAt: ["launchedAt"],
      "Manual BL1": ["setbot", "setIsBot"],
      "Manual BL2": ["setBlacklistEnabled"],
      "Multiple BL": ["setBlacklistEnabledMultiple"],
      "Gas limit": ["setGasPriceLimit"],
      External: ["setProtectionSettings"],
    };
    //
    const footprintsColors: Record<string, string> = {
      LaunchedAt: "#47829a",
      "Manual BL1": "#47ff9a",
      "Manual BL2": "#ff829a",
      "Multiple BL": "#4782ff",
      "Gas limit": "#224789",
      External: "#bb7829",
    };
    //
    // find only one result, break on first positive
    //
    let color = "#000000";
    //
    for (const [type, matchMethods] of Object.entries(methodFootprints)) {
      //console.log("checking for", [type, matchMethods]);

      const is_positive = matchMethods
        .map((mm) => methodNames.includes(mm))
        .reduce((a: boolean, b: boolean) => a || b, false);
      //
      if (is_positive) {
        color = footprintsColors[type];
        console.log("positive for", [type, matchMethods], "using color", color);
        break; // returning first positive
      }
    }
    //
    return color;
  };

  const renderLeftFloatingBox = (approves: Array<any>) => {
    //
    const renderToken = (ca: string, call_times: Array<any>, idx: number) => {
      const allCalls = call_times.map(
        ({
          from,
          to,
          amount,
          ...rest
        }: {
          from: string;
          to: string;
          amount: string;
        }) => ({ from, to, amount })
      );
      ///

      const approveAmountNormal = "0";
      const approveAmountTwoComplement =
        "115792089237316195423570985008687907853269984665640564039457584007913129639935";
      // 115792089237316195423570985008687907853269984665640564039457584007913129639935
      const approved =
        allCalls.filter(
          (tx) =>
            tx.amount === approveAmountTwoComplement ||
            tx.amount === approveAmountNormal
        ) || [];
      const transfered =
        allCalls.filter(
          (tx) =>
            tx.amount !== approveAmountTwoComplement &&
            tx.amount !== approveAmountNormal
        ) || [];
      //
      transfered.sort((a, b) => parseInt(b.amount) - parseInt(a.amount));

      return (
        <span style={{ display: "flex", width: "220px", maxHeight: "400px" }}>
          <span
            key={`pop_approve_${idx}`}
            style={{
              marginTop: "2px",
              display: "inline-block",
              minWidth: "200px",
            }}
          >
            <a
              href={`./upload?address=${ca.toLowerCase()}`}
              style={{
                display: "inline-block",
                whiteSpace: "nowrap",
                // overflowX: "hidden",
                maxWidth: "185px",
                fontSize: "15px",
                textOverflow: "ellipsis",
              }}
            >
              {translatedAddress(ca.toLowerCase(), 16)}{" "}
              <small>{approved.length ? `(+${approved.length})` : ""}</small>
            </a>
            {/* <br />
          {approved.length} approved
          <br />
          {transfered.length} transfered
          <br /> */}
            <span>
              {transfered.map(({ from, to, amount }) => (
                <div>
                  <div
                    style={{
                      float: "left",
                      width: "56%",
                      verticalAlign: "middle",
                    }}
                  >
                    {_as_stripe(from, 5)}
                    {
                      <small style={{ fontSize: "9px", padding: "2px" }}>
                        ➤
                      </small>
                    }
                    {_as_stripe(to, 5)}
                  </div>
                  <div style={{ float: "right", width: "44%" }}>
                    {leftContractList && (
                      <span
                        style={{
                          display: "inline-block",
                          maxWidth: "85px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: "11px",
                          height: "100%",
                          position: "relative",
                          left: "-4px",
                          top: "2px",
                        }}
                      >
                        {_as_formatted_amount(
                          amount,
                          parseInt(
                            leftContractList
                              .filter((c) => c.address === ca.toLowerCase())
                              .map((c) => c.d)[0] || "18"
                          )
                        )}
                      </span>
                    )}
                  </div>
                  <div style={{ clear: "both" }}></div>
                </div>
              ))}
            </span>
          </span>
        </span>
      );
    };

    return (
      <ContentBlock
        style={{
          display: "flex",
          flexWrap: "wrap",
          maxHeight: "400px",
          flexDirection: "column",
          // alignContent: "space-between",
          alignContent: "flex-start",
        }}
      >
        {approves &&
          Object.entries(approves)
            .slice(0, 8)
            .map(([ca, call_times], idx) => renderToken(ca, call_times, idx))}
      </ContentBlock>
    );
  };

  return (
    <>
      <MainContent>
        <Section>
          <ContentBlock>
            <div>
              <div style={{ float: "left", width: "25%" }}>
                <ContentRow>
                  <Label>Chain id:</Label> <TextInline>{chainId}</TextInline>
                </ContentRow>
              </div>
              <div style={{ float: "left", width: "25%" }}>
                <ContentRow>
                  <Label>Current block:</Label>{" "}
                  <TextInline>{height}</TextInline>
                </ContentRow>
              </div>
              <div style={{ float: "left", width: "24%" }}>
                {currentGasPrice && (
                  <ContentRow>
                    <Label>Current gas price:</Label>{" "}
                    <TextInline>{currentGasPrice?.toString()}</TextInline>
                  </ContentRow>
                )}
              </div>
              <div style={{ float: "right", width: "24%" }}>
                {timestamp && (
                  <ContentRow>
                    <Label>Current block timestamp:</Label>{" "}
                    <TextInline>{timestamp?.toLocaleString()}</TextInline>
                  </ContentRow>
                )}
              </div>
              <div style={{ clear: "both" }}></div>
            </div>
            <br />
            {lastDetailedBlockData && (
              <div>
                <div>
                  <div style={{ float: "left", width: "49%" }}>
                    <ContentRow>
                      {
                        <TransfersChart
                          envelope={lastDetailedBlockData.TRANSFERS}
                        />
                      }
                      <Label>TOP 5 TRANSFERS:</Label>
                      <table
                        style={{
                          width: "370px",
                          fontSize: "11px",
                          margin: "0px",
                        }}
                      >
                        <thead>
                          <tr>
                            <th>From</th>
                            <th></th>
                            <th>ETH</th>
                            <th></th>
                            <th>To</th>
                          </tr>
                        </thead>
                        <tbody>
                          {lastDetailedBlockData.TRANSFERS.sort(
                            (a, b) => b.eth - a.eth
                          )
                            .slice(0, 10)
                            .map(
                              (
                                {
                                  from,
                                  to,
                                  eth,
                                }: {
                                  from: string;
                                  to: string;
                                  eth: number;
                                },
                                idx: number
                              ) => (
                                <tr key={`${from}_${idx}`}>
                                  <td>{_as_stripe(from, 12)}</td>
                                  <td style={{ width: "30px" }}> -- </td>
                                  <td>{eth.toFixed(6)} ETH</td>
                                  <td style={{ width: "30px" }}> --&gt; </td>
                                  <td>{_as_stripe(to || "", 12)}</td>
                                </tr>
                              )
                            )}
                        </tbody>
                      </table>
                    </ContentRow>
                  </div>
                  <div style={{ float: "right", width: "49%" }}>
                    <ContentRow>
                      <Label>CONTRACTS CALLED:</Label>{" "}
                      <table
                        style={{
                          width: "370px",
                          fontSize: "11px",
                          margin: "0px",
                        }}
                      >
                        <thead>
                          <tr>
                            <th>Contract</th>
                            <th>Methods</th>
                            <th>Address</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(lastDetailedBlockData.CAS)
                            .filter(
                              (address) =>
                                !KNOWN_TOKEN_ADDRESSES.includes(address)
                            )
                            .map((key) => ({
                              address: key,
                              methods: lastDetailedBlockData.CAS[key],
                            }))
                            .map(
                              (
                                {
                                  address,
                                  methods,
                                }: {
                                  address: string;
                                  methods: Record<string, any>;
                                },
                                idx: number
                              ) => (
                                <tr key={`${address}_${idx}`}>
                                  <td>{_as_stripe(address, 12)}</td>
                                  <td>
                                    {/* {JSON.stringify(methods)} */}
                                    {Object.keys(methods)
                                      .map((key) => ({
                                        method_name: key,
                                        calls: methods[key],
                                      }))
                                      .map(
                                        ({
                                          method_name,
                                          calls,
                                        }: {
                                          method_name: string;
                                          calls: Array<any>;
                                        }) => (
                                          <div
                                            key={`${address}_${method_name}`}
                                          >
                                            {method_name} ({calls.length})
                                          </div>
                                        )
                                      )}
                                  </td>
                                  <td
                                    style={{ fontSize: "7px", color: "blue" }}
                                  >
                                    <a href={`./upload?address=${address}`}>
                                      {translatedAddress(
                                        address.toLowerCase(),
                                        16
                                      )}
                                    </a>
                                  </td>
                                </tr>
                              )
                            )}
                        </tbody>
                      </table>
                    </ContentRow>
                  </div>
                  <div style={{ clear: "both" }}></div>
                </div>

                <ContentRow style={{ display: "none" }}>
                  <div>
                    <div style={{ float: "left", width: "25%" }}>a</div>
                    <div style={{ float: "left", width: "25%" }}>b</div>
                    <div style={{ float: "left", width: "24%" }}>c</div>
                    <div style={{ float: "right", width: "24%" }}>d</div>
                    <div style={{ clear: "both" }}></div>
                  </div>
                  <div>
                    <div style={{ float: "left", width: "33%" }}>a</div>
                    <div style={{ float: "left", width: "33%" }}>b</div>
                    <div style={{ float: "right", width: "32%" }}>c</div>
                    <div style={{ clear: "both" }}></div>
                  </div>
                  <div>
                    <div style={{ float: "left", width: "49%" }}>a</div>
                    <div style={{ float: "right", width: "49%" }}>b</div>
                    <div style={{ clear: "both" }}></div>
                  </div>
                </ContentRow>
                <div>
                  {Object.entries(lastDetailedBlockData.BY_TYPE).map(
                    ([x, y], idx) => (
                      <div key={`type_${idx}`}>
                        {x}: {lastDetailedBlockData.BY_TYPE[x]}
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
          </ContentBlock>
        </Section>

        <Section>
          {lastDetailedBlockData && (
            <ContentBlock>
              <div>
                <div style={{ float: "left", width: "25%" }}>
                  <ContentRow>
                    <Label>Transactions:</Label>{" "}
                    <TextInline>{lastDetailedBlockData.meta.tx}</TextInline>
                  </ContentRow>
                </div>
                <div style={{ float: "left", width: "25%" }}>
                  <ContentRow>
                    <Label>Current block:</Label>{" "}
                    <TextInline>{lastDetailedBlockData.meta.height}</TextInline>
                  </ContentRow>
                </div>
                <div style={{ float: "left", width: "24%" }}>
                  <ContentRow>
                    <Label>Size:</Label>{" "}
                    <TextInline>{lastDetailedBlockData.meta.size}</TextInline>
                  </ContentRow>
                </div>
                <div style={{ float: "right", width: "24%" }}>
                  <ContentRow>
                    <Label>Timestamp:</Label>{" "}
                    <TextInline>
                      {new Date(
                        lastDetailedBlockData.meta.time
                      ).toLocaleString()}
                    </TextInline>
                  </ContentRow>
                </div>
                <div style={{ clear: "both" }}></div>
              </div>
            </ContentBlock>
          )}
        </Section>

        {/* <Section>
          <ContentRow>
            <List key={"unilist"}>
              {tokenList &&
                lastDetailedBlockData &&
                tokenList
                  .filter((token) =>
                    Object.keys(lastDetailedBlockData.CAS).includes(
                      token.address.toLowerCase()
                    )
                  )
                  .map((token, idx) => (
                    <TokenItem
                      key={`unilist_${token.address}`}
                      onClick={(e) => {
                        console.log("TOKEN", e, token.address);
                        document.location.href = `./upload?address=${token.address}`;
                      }}
                    >
                      {_as_stripe(token.address, 9)}
                      <TokenIconContainer>
                        <TokenIcon
                          src={token.logoURI}
                          alt={`${token.symbol} logo`}
                        />
                        <TokenBalance>
                          {" "}
                          <div
                            key={`unilist_methods_${
                              token.address
                            }_${idx}_${Math.random()}`}
                            style={{
                              display: "block",
                              position: "absolute",
                              maxWidth: "30px",
                              background: "rgba(255,255,255,0.6",
                            }}
                          >
                            {Object.entries(
                              lastDetailedBlockData.CAS[
                                token.address.toLowerCase()
                              ]
                            ).map(([method_name, calls], idx) => (
                              <div
                                style={{
                                  display: "block",
                                  fontSize: "12px",
                                  maxHeight: "12px",
                                  lineHeight: "15px",
                                  padding: "auto",
                                }}
                                key={`unilist_tiny_${
                                  token.address
                                }_${method_name}_${idx}_${Math.random()}`}
                              >
                                <div
                                  style={{
                                    display: "block",
                                    padding: "auto",
                                    margin: "1px",
                                    width: "40px",
                                  }}
                                  key={`unilist_interface_${
                                    token.address
                                  }_${method_name}_${idx}_${Math.random()}`}
                                >
                                  {_as_interface_stripe(method_name, 8)}
                                  {(calls as Array<any>).length}
                                </div>
                              </div>
                            ))}
                          </div>
                        </TokenBalance>
                      </TokenIconContainer>
                      <TokenName2>{token.symbol}</TokenName2>
                    </TokenItem>
                  ))}
            </List>
          </ContentRow>
          <ContentRow>
            <List key={"watchlist"}>
              {filteredContractList &&
                lastDetailedBlockData &&
                filteredContractList.map((token, idx) => (
                  <TokenItem
                    key={`watchlist_${token.address}`}
                    onClick={(e) => {
                      console.log("TOKEN", e, token.address);
                      document.location.href = `./upload?address=${token.address}`;
                    }}
                  >
                    {_as_stripe(token.address, 9)}
                    <TokenIconContainer>
                      <IconStar
                        color={
                          token.abi?.names
                            ? botColorByMethods(token.abi?.names)
                            : "#000000"
                        }
                      />

                      <TokenBalance>
                        {" "}
                        <div
                          key={`watchlist_methods_${token.address}`}
                          style={{
                            display: "block",
                            position: "absolute",
                            maxWidth: "30px",
                            background: "rgba(255,255,255,0.6",
                          }}
                        >
                          {lastDetailedBlockData &&
                            lastDetailedBlockData.CAS &&
                            Object.entries(
                              lastDetailedBlockData.CAS[
                                token.address.toLowerCase()
                              ] || {}
                            ).map(([method_name, calls], idx) => (
                              <div
                                style={{
                                  display: "block",
                                  fontSize: "12px",
                                  maxHeight: "12px",
                                  lineHeight: "15px",
                                  padding: "auto",
                                }}
                                key={`watchlist_tiny_${token.address}_${method_name}_${idx}`}
                              >
                                <div
                                  style={{
                                    display: "block",
                                    padding: "auto",
                                    margin: "1px",
                                    width: "40px",
                                  }}
                                  key={`watchlist_interface_${
                                    token.address
                                  }_${method_name}_${idx}_${Math.random()}`}
                                >
                                  {_as_interface_stripe(method_name, 8)}
                                  {(calls as Array<any>).length}
                                </div>
                              </div>
                            ))}
                        </div>
                      </TokenBalance>
                    </TokenIconContainer>
                    <div
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        maxHeight: "35px",
                      }}
                    >
                      <TokenName2>{token.name}</TokenName2>
                    </div>
                  </TokenItem>
                ))}
            </List>
          </ContentRow>
        </Section> */}

        <Section>
          {lastDetailedBlockData && (
            <ContentBlock>
              <div>
                <div style={{ float: "left", width: "49%" }}>
                  <ContentRow>
                    <Label>Methods understood:</Label>{" "}
                    <div style={{ fontSize: "12px" }}>
                      {lastDetailedBlockData.UNDERSTOOD.map((fname: string) => (
                        <span key={`understood_${fname}`}>
                          {fname}
                          {" - "}
                        </span>
                      ))}
                    </div>
                  </ContentRow>
                </div>
                <div style={{ float: "right", width: "49%" }}>
                  <ContentRow>
                    <Label>U.F.O.s:</Label>{" "}
                    <div style={{ fontSize: "10px" }}>
                      {lastDetailedBlockData.UFO.map(
                        ([length, methodID], idx) => (
                          <span key={`ufo_${idx}`}>
                            {methodID} ({length}) &nbsp;
                          </span>
                        )
                      )}
                    </div>
                  </ContentRow>
                </div>
                <div style={{ clear: "both" }}></div>
              </div>
            </ContentBlock>
          )}
        </Section>
        {lastDetailedBlockData && lastDetailedBlockData.CREATIONS.length > 0 && (
          <Section>
            <ContentBlock>
              CREATIONS : {lastDetailedBlockData.CREATIONS.length}
              <br />
              METRICS: {JSON.stringify(lastDetailedBlockData.M)}
              <ContentRow>
                <div style={{ fontSize: "12px" }}>
                  {lastDetailedBlockData.CREATIONS.map(
                    ({
                      transactionIndex,
                      from,
                      to,
                      gasPrice,
                      input,
                    }: {
                      transactionIndex: string;
                      from: string;
                      to: string;
                      gasPrice: string;
                      input: string;
                    }) => (
                      <ContentBlock key={transactionIndex}>
                        <ContentRow>
                          <Label>TransactionIndex:</Label>{" "}
                          <TextInline>{transactionIndex}</TextInline>
                        </ContentRow>
                        <ContentRow>
                          <Label>Creator:</Label>{" "}
                          <TextInline>{from}</TextInline>
                        </ContentRow>
                        <ContentRow>
                          <Label>Contract:</Label> <TextInline>{to}</TextInline>
                        </ContentRow>
                        <ContentRow>
                          <Label>Gas Price:</Label>{" "}
                          <TextInline>{gasPrice}</TextInline>
                        </ContentRow>
                        <ContentRow>
                          <Label>Input:</Label>{" "}
                          <DataCanvas
                            width={600}
                            scale={2}
                            data_string={input}
                          />
                        </ContentRow>
                      </ContentBlock>
                    )
                  )}
                </div>
              </ContentRow>
            </ContentBlock>
          </Section>
        )}
        {lastDetailedBlockData && (
          <Section
            style={{
              width: "auto",
              height: "auto",
            }}
          >
            {renderLeftFloatingBox(lastDetailedBlockData.APPROVES)}
          </Section>
        )}
      </MainContent>
    </>
  );
}
// </MainContent>{/* </Container> </MainContent> */}

const List = styled.ul`
  display: block;
  max-width: 972px;
  margin: auto;
  width: 100%;
`;

const TokenItem = styled.li`
  background-color: rgba(200, 200, 200, 0.54);
  float: left;
  align-items: center;
  height: 84px;
  width: 84px;
  padding: 2px;
  border: 1px solid #124252;

  & + & {
    border-top: 1px solid ${Colors.Black[200]};
  }
`;

const TokenIconContainer = styled.div`
  margin-top: 2px;
  margin-left: 10px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: icon;
  width: 48px;
  height: 48px;
  padding: 1px;
  font-size: 36px;
  line-height: 36px;
  border: 1px solid ${Colors.Gray[300]};
  border-radius: 50%;
`;

const TokenName2 = styled.div`
  grid-area: name;
  font-size: 14px;
  float: right;
  margin-bottom: 6px;
`;

const TokenBalance = styled.div`
  grid-area: balance;
  font-size: 20px;
  position: relative;
  top: -24px;
  left: -56px;
`;

import { useEtherBalance, useEthers } from "@usedapp/core";
import styled from "styled-components";
import {
  Container,
  ContentBlock,
  MainContent,
  Section,
  SectionRow,
  Button,
} from "../components/base";
import { Title } from "../typography/Title";

import {
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  TextField,
} from "@material-ui/core";
import { useState } from "react";
import { DefaultContractsList } from "../components/TokensList/DefaultContractsList";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { Web3Provider } from "@ethersproject/providers";

const contractTypes = [
  "ERC721Creator",
  "ERC1967Proxy",
  "ProxyLike",
  "ERC20",
  "ERC20ISH",
  "NFT",
  "NFTISH",
  "UNKNOWN",
];

const views = [
  ["erc20", "Coin"],
  ["erc721", "NFT"],
  ["erc1967", "Proxy"],
  ["unknown", "Contract"],
  ["top_erc20", "ERC 20 Toplist"],
  ["top_erc721", "ERC 721 Toplist"],
];

const default_chainopt = [1, 10, 25, 56, 137, 250, 42161];
// const default_chainopt = [1, 56];

export function NewContracts() {
  const { account, activate, chainId, deactivate, library } = useEthers();
  const [signedMessage, setSignedMessage] = useState("");

  async function onConnect() {
    try {
      // const provider = new WalletConnectProvider({
      //   infuraId: "a787de38c9fa4e4f94481db6ccf78502",
      // });
      const provider = new WalletConnectProvider({
        rpc: {
          1: "https://ethereum.api.watchdata.io/node/jsonrpc?api_key=a35d0b6b-1a9f-466f-82d7-1a6173efa793",
          56: "https://bsc-dataseed1.binance.org",
        },
        chainId: 1,
      });

      await provider.enable();
      activate(provider);
    } catch (error) {
      console.error(error);
    }
  }

  async function onDisconnect() {
    deactivate();
    localStorage.removeItem("walletconnect");
    setSignedMessage("");
  }

  async function onSign() {
    const msg = "I sign Wallet Connect test message on @usedapp";
    const provider = library as Web3Provider;
    try {
      const signedMsg = await provider.getSigner().signMessage(msg);
      setSignedMessage(signedMsg);
    } catch (error) {
      console.error(error);
    }
  }

  const userBalance = useEtherBalance(account);

  // const { activateBrowserWallet, deactivate, account } = useEthers();

  const [value, setValue] = useState<string>(""); // the contract type to watch
  const [currentRandomTen, setCurrentRandomTen] = useState<Array<any>>();
  const [currentChain, setCurrentChain] = useState<number>(default_chainopt[0]);
  const [currentChainOptions, setCurrentChainOptions] =
    useState<Array<number>>(default_chainopt);

  if (currentRandomTen === undefined) {
    setCurrentRandomTen(views);
    //setValue("erc20");
    setValue("unknown");
    // setValue("top_erc721");

    //setValue("unknown");
  }

  const renderSelect = () => (
    <div style={{ display: "none" }}>
      <StyledSelect
        value={value}
        onChange={(e) => {
          console.log("changing", e.target.value, value);
          setValue(e.target.value as string);
        }}
      >
        {currentRandomTen === undefined
          ? ""
          : currentRandomTen.map(([guid, name]: Array<string>) => (
              <MenuItem
                value={guid}
                key={guid}
                // selected={guid === value}
              >
                <ListItemText
                  primary={
                    <>
                      <StyledListItemIcon></StyledListItemIcon>
                      <StyledTypography>{name}</StyledTypography>
                    </>
                  }
                />
              </MenuItem>
            ))}
      </StyledSelect>
    </div>
  );

  const renderChainSelect = () => (
    <div>
      <StyledSelect
        value={currentChain}
        onChange={(e) => {
          console.log("changing", e.target.value, value);
          setCurrentChain(e.target.value as number);
        }}
      >
        {currentChainOptions === undefined
          ? ""
          : currentChainOptions.map((cid: number) => (
              <MenuItem
                value={cid}
                key={cid}
                // selected={guid === value}
              >
                <ListItemText
                  primary={
                    <>
                      <StyledListItemIcon></StyledListItemIcon>
                      <StyledTypography>{cid}</StyledTypography>
                    </>
                  }
                />
              </MenuItem>
            ))}
      </StyledSelect>
    </div>
  );

  return account ? (
    <MainContent>
      <Container>
        <Section>
          <SectionRow>
            {/* <Title>Contracts</Title> */}
            {renderChainSelect()}
            {renderSelect()}
          </SectionRow>
          <TokensContentBlock style={{ width: "1112px" }}>
            {value && currentChain && (
              <DefaultContractsList mode={value} chainId={currentChain} />
            )}
          </TokensContentBlock>
        </Section>
      </Container>
    </MainContent>
  ) : (
    <Button onClick={account ? onDisconnect : onConnect}>
      {account ? "Disconnect wallet" : "Connect wallet"}
    </Button>
    // <Button onClick={() => activateBrowserWallet()}>Connect wallet</Button>
  );
}

const TokensContentBlock = styled(ContentBlock)`
  padding: 16px 32px;
`;

const StyledSelect = styled(Select)`
  min-width: 200px;
`;
const StyledMiniSelect = styled(Select)`
  min-width: 200px;
`;

const StyledListItemIcon = styled(ListItemIcon)`
  padding-left: 5px;
  display: inline;
  float: left;
`;

const StyledTypography = styled.span`
  float: left;
  display: inline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 85%;
  max-height: 32px;
  border: solid 1px #444444;
`;

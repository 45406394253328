import { useCallback, useEffect, useMemo, useState } from "react";
// import { useStorage } from "../hooks/useStorage";

import { ethers } from "ethers";
import { Contract } from "@ethersproject/contracts";

import { formatEther } from "@ethersproject/units";
import {
  useEtherBalance,
  useEthers,
  useContractCall,
  useContractCalls,
  useContractFunction,
  useBlockNumber,
} from "@usedapp/core";

import ForceGraph3D from "react-force-graph-3d";
import SpriteText from "three-spritetext";

import {
  Container,
  ContentBlock,
  ContentRow,
  MainContent,
  Section,
  SectionRow,
} from "../../components/base";
import { Button } from "../../components/base";
import { Label } from "../../typography/Label";
import { TextInline } from "../../typography/Text";
import { Title } from "../../typography/Title";

import { TextField } from "@material-ui/core";

//import fractalABI from "../contracts/FRACTAL_NFT.abi.json";
import fractalABI from "../../contracts/FRACTAL_NFT_W_ROYALTY.abi.json";
import UniswapV2FactoryABI from "../../contracts/UniswapV2Factory.abi.json"; //
import UniswapV2PairABI from "../../contracts/UniswapV2Pair.abi.json"; //

import useUUID from "../../hooks/useUUID";
import HandledMethods from "../../ai/config/method-helper/abis";
import { MethodEnvelope } from "../../ai/method-helper";
import {
  _as_genome,
  _as_interface_stripe,
  _as_smart_stripe,
  _as_stripe,
} from "../../components/SimpleControls";

import { AbiEntry, loadConfig, loadNames } from "../../ai";
import { parseFixed } from "@ethersproject/bignumber";
import UniSwapPairComponent from "../../components/UniSwapPairComponent";
import useCoinGeckoPrices from "../../hooks/useCoinGeckoPrices";
import DataCanvas from "../../components/DataCanvas";

const STAKING_CONTRACT = "0x00000000219ab540356cBB839Cbe05303d7705Fa";
const FRACTAL_CONTRACT = "0xd7abd0CEDf3fFfE59Dfb4E6C52840df95B6F9957"; // with royalty
//
// uniswap '0xc0aee478e3658e2610c5f7a4a2e1777ce9e4f2ac'
//

const AI_CONFIG = loadConfig();
const DEPLOYERS = AI_CONFIG.DEPLOYERS;
// const KNOWN_GENESIS_IDS = AI_CONFIG.IDS.GENESIS;

const L = console.log;

type ForcedGraphEnvelope = {
  nodes: Array<any>;
  links: Array<any>;
};
export function InMyMind() {
  const [graphData, setGraphData] = useState<ForcedGraphEnvelope>({
    nodes: [],
    links: [],
  });
  //

  const treeMemo = useMemo(() => {
    if (DEPLOYERS) {
      const DFILTERED = DEPLOYERS.filter((o) => o.contracts.length > 1);
      const dev_nodes = DFILTERED.map((x) => x.deployer);
      //const ca_nodes = DEPLOYERS.map((x) => x.contracts).flat();
      const ca_nodes = DFILTERED.map((x) => x.contracts).flat();
      //
      let node_id = -1;
      const guid = () => ++node_id; // the root node has GUID = 0
      const root = { id: guid(), name: "ROOT" };
      //
      const DEV_NODE = {
        id: guid(),
        data_id: 1,
        name: `DEV`,
        group: 1,
      };
      const CA_NODE = {
        id: guid(),
        data_id: 2,
        name: `SMC`,
        group: 2,
      };

      const cnodes = ca_nodes.slice(0, 10000).map((a, idx) => ({
        id: guid(),
        data_id: 1000 + idx,
        name: `C${idx}`,
        address: a,
        group: 3,
      }));

      let idx = 0;
      const dnodes = [] as Array<{
        id: number;
        data_id: number;
        name: string;
        address: string;
        group: number;
      }>;
      //
      const dlinks = [] as Array<{
        source: number;
        target: number;
        value: number;
      }>;
      //
      for (const entry of DFILTERED) {
        //
        // add node
        //
        const dnode = {
          id: guid(),
          data_id: 1000000 + idx,
          name: `D${idx}`,
          address: entry.deployer,
          group: 3 + idx,
        };
        dnodes.push(dnode);
        //
        entry.contracts.map((c) => {
          const target_id = cnodes.find((cn) => cn.address === c)?.id || 0;
          const dlink = { source: dnode.id, target: target_id, value: 1 };
          dlinks.push(dlink);
        });

        //
        // add links to contracts
        //
        //
        idx++;
      }
      // const dnodes = dev_nodes.slice(0, 10000).map((a, idx) => ({
      //   id: guid(),
      //   data_id: 1000000 + idx,
      //   name: `D${idx}`,
      //   address: a,
      //   group: 3 + idx,
      // }));
      //
      //
      // link deployers to contracts
      //

      // const _link = { source: _id, target: 0, value: addresses.length };
      const links_root = [
        { source: 0, target: 1, value: dev_nodes.length },
        { source: 0, target: 2, value: ca_nodes.length },
      ];
      //
      const links_dev_to_d = [
        ...dnodes.map((dn) => ({ source: 1, target: dn.id, value: 1 })),
      ];
      const links_ca_to_c = [
        ...cnodes.map((cn) => ({ source: 2, target: cn.id, value: 1 })),
      ];
      //
      const nodes = [root, DEV_NODE, CA_NODE, ...dnodes, ...cnodes];
      const links = [
        // ...links_root, ...links_dev_to_d, ...links_ca_to_c,
        ...dlinks,
      ];
      //const tree_links = DEPLOYERS.map((x) => x.deployer);
      //
      return { nodes: nodes, links: links };
    }
    //
    return { nodes: [], links: [] };
  }, [DEPLOYERS]);
  //
  const grt = () => treeMemo;
  //
  //
  const [file, setFile] = useState<File | undefined>();
  const [error, setError] = useState<string | undefined>();
  const [newStorageCount, setNewStorageCount] = useState<number>(0);
  const [newNftPrice, setNewNftPrice] = useState<number>(0.1);

  const types = ["image/png", "image/jpeg", "image/jpg"];

  const handledMethodsMemo = useMemo(() => {
    const entries = Object.entries(HandledMethods);
    //
    return entries.map((entry: [string, MethodEnvelope], idx) => ({
      idx,
      hex: entry[0],
      ...entry[1],
    }));
  }, []);

  const [keywordName, setKeywordName] = useState<string>("");
  const [keywordHex, setKeywordHex] = useState<string>("");
  //
  const filteredMethodsMemo = useMemo(() => {
    //
    const kvnup = keywordName.toUpperCase();
    const arr = handledMethodsMemo.filter(
      (x) =>
        x.name.toUpperCase().indexOf(kvnup) !== -1 &&
        x.hex.indexOf(keywordHex) !== -1
    );
    //
    arr.sort((x, y) => x.name.localeCompare(y.name));
    //
    return arr;
  }, [handledMethodsMemo, keywordName, keywordHex]);

  // Getting the progress and url from the hook
  // const { progress, url } = useStorage(file);
  // const { progress, url } = {progress:1, url:'whatever' }

  const { activateBrowserWallet, deactivate, account } = useEthers();
  const userBalance = useEtherBalance(account);
  const stakingBalance = useEtherBalance(STAKING_CONTRACT);

  const uuidTestResult = useUUID(account || "");
  console.log("UUID TEST", account, uuidTestResult);

  const colors = uuidTestResult["ACC"] as Array<any>;

  //
  //
  // uniswap '0xc0aee478e3658e2610c5f7a4a2e1777ce9e4f2ac'
  //
  const UNI_V2_FACTORY_CONTRACT = "0x5c69bee701ef814a2b6a3edd4b1652cb9cc5aa6f"; // 64555 pairs  // 64733
  // const UNI_V2_FACTORY_CONTRACT = "0xc0aee478e3658e2610c5f7a4a2e1777ce9e4f2ac";
  // const UNI_V2_TESTPAIR_CONTRACT = "0xA75F7c2F025f470355515482BdE9EFA8153536A8";
  const UNI_V2_TESTPAIR_CONTRACT = "0x117d4288B3635021a3D612FE05a3Cbf5C717fEf2";

  const factoryInterface = new ethers.utils.Interface(UniswapV2FactoryABI);

  const useFactoryAllPairsLength = () => {
    const [allPairsLength] =
      useContractCall({
        abi: factoryInterface, //ERC20Interface,
        address: UNI_V2_FACTORY_CONTRACT,
        method: "allPairsLength",
        args: [], // [ownerAddress, spenderAddress],
      }) ?? [];
    return allPairsLength;
  };
  //
  const factoryAllPairsLength = useFactoryAllPairsLength();
  //
  //
  const useFactoryPairAtIndex = (pair_idx: number | null | undefined) => {
    const [factoryPair] =
      useContractCall(
        pair_idx && {
          abi: factoryInterface, //ERC20Interface,
          address: UNI_V2_FACTORY_CONTRACT,
          method: "allPairs",
          args: [pair_idx], // [ownerAddress, spenderAddress],
        }
      ) ?? [];
    return factoryPair;
  };

  // const useFactoryPairsAtIndices = (length: number) => {
  //   const indices = Array.from(new Array(length).keys());

  //   console.log(indices);
  //   const factoryPairs = useContractCalls(
  //     indices
  //       ? indices.map((index) => ({
  //           abi: factoryInterface,
  //           address: UNI_V2_FACTORY_CONTRACT,
  //           method: "allPairs",
  //           args: [index],
  //         }))
  //       : []
  //   );

  //   return factoryPairs as Array<Array<ethers.BigNumber>>;
  // };
  const useFactoryPairsAtIndices = (indices: Array<number>): Array<string> => {
    // const indices = Array.from(new Array(length).keys());

    console.log(indices);
    const factoryPairs = useContractCalls(
      indices
        ? indices.map((index) =>
            index
              ? {
                  abi: factoryInterface,
                  address: UNI_V2_FACTORY_CONTRACT,
                  method: "allPairs",
                  args: [index],
                }
              : null
          )
        : []
    )
      .flat()
      .map((bn) => bn?.toString() || null);

    return factoryPairs as Array<string>;
  };
  //

  const test_idx = 1;
  const factoryPairAtIndex = useFactoryPairAtIndex(test_idx);
  //
  // const test_length = 100; // 2601; //10; //2601;
  const theresult = [];
  const [start, setStart] = useState<number>(5000);
  const range = new Array(10).fill(0).map((v, i) => start+i);
  const factoryPairsAtIndices = useFactoryPairsAtIndices(range);
  // const factoryPairsAtIndices = useFactoryPairsAtIndices(test_length);

  // console.log("tokensOfOwner", tokensOfOwner);

  //
  //
  //

  //
  //
  //
  const fractalInterface = new ethers.utils.Interface(fractalABI);

  const useFractalTotalSupply = () => {
    const [totalSupply] =
      useContractCall({
        abi: fractalInterface, //ERC20Interface,
        address: FRACTAL_CONTRACT,
        method: "totalSupply",
        args: [], // [ownerAddress, spenderAddress],
      }) ?? [];
    return totalSupply;
  };

  const useBalanceOfOwner = (ownerAddress: string | null | undefined) => {
    const [balance] =
      useContractCall(
        ownerAddress && {
          abi: fractalInterface, //ERC20Interface,
          address: FRACTAL_CONTRACT,
          method: "balanceOf",
          args: [ownerAddress], // [ownerAddress, spenderAddress],
        }
      ) ?? [];
    return balance;
  };

  const useTokensOfOwnerByIndex = (
    ownerAddress: string | null | undefined,
    totalTokensOfOwner: number
  ) => {
    const indices = Array.from(new Array(totalTokensOfOwner).keys());

    console.log(indices);
    const tokensOfOwner = useContractCalls(
      ownerAddress && indices
        ? indices.map((index) => ({
            abi: fractalInterface,
            address: FRACTAL_CONTRACT,
            method: "tokenOfOwnerByIndex",
            args: [ownerAddress, index],
          }))
        : []
    );

    return tokensOfOwner as Array<Array<ethers.BigNumber>>;
  };

  const useTokensPriceOfOwnerByIndex = (
    tokensOfOwner: Array<Array<ethers.BigNumber>>
  ) => {
    const pricesOfTokens = useContractCalls(
      tokensOfOwner
        ? tokensOfOwner.map((token) => ({
            abi: fractalInterface,
            address: FRACTAL_CONTRACT,
            method: "price",
            args: [token?.[0]],
          }))
        : []
    );

    return pricesOfTokens as Array<Array<ethers.BigNumber>>;
  };

  const fractalTotalSupply = useFractalTotalSupply();
  const balanceOfOwner = useBalanceOfOwner(account);
  const tokensOfOwner = useTokensOfOwnerByIndex(
    account,
    balanceOfOwner ? balanceOfOwner.toNumber() : 0
  );

  console.log("tokensOfOwner", tokensOfOwner);
  const pricesOfTokens = useTokensPriceOfOwnerByIndex(tokensOfOwner);

  const fractalContract = new Contract(FRACTAL_CONTRACT, fractalInterface);

  const operationResultsSerialized = useMemo(() => {
    const res = filteredMethodsMemo.map((f) => ({
      type: "",
      hex: f.hex,
      name: f.name,
      signature: f.signature,
      input_types: f.input_types,
      output_aliases: f.output_aliases,
    }));

    return JSON.stringify(res, null, 4);
  }, [filteredMethodsMemo]);
  // const [operationResultsSerialized, setOperationResultsSerialized] =
  //   useState<string>("");
  //
  const [fileName, setFileName] = useState<string>("");
  //
  //
  const downloadResults = () => {
    L("downloadResults", operationResultsSerialized.length);
    //
    const element = document.createElement("a");
    const file = new Blob([operationResultsSerialized], {
      type: "application/json",
    });
    element.href = URL.createObjectURL(file);
    element.download = `${fileName}.json`;
    document.body.appendChild(element);
    element.click();
    //
    L("file downloaded", `${fileName}.json`);
  };
  const onDownloadResults = useCallback(downloadResults, [
    operationResultsSerialized,
    fileName,
  ]);

  const [files, setFiles] = useState<Array<any>>([]);
  //
  const onChange = (e: any) => {
    const files = e.target.files;
    console.log(files);
    const filesArr = Array.prototype.slice.call(files);
    console.log(filesArr);
    //
    setFiles([...files, ...filesArr]);
  };

  const blobToFile = (theBlob: Blob, fileName: string): File => {
    const b: any = theBlob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    //Cast to a File() type
    return theBlob as File;
  };

  const [dataString, setDataString] = useState<string>();

  const [isLoadedFile, setIsLoadedFile] = useState<boolean>(false);
  const blobMemo = useMemo(() => {
    let data;
    //
    if (!isLoadedFile && files) {
      const first = files[0];
      if (first) {
        // const first_as_file = new File(['akarmi'], 'hello_world.txt', {type: first.type});

        //
        // L("first", first);

        //const blob = new Blob([JSON.stringify(obj, null, 2)], {type : 'application/json'});
        // const blob = new Blob([JSON.stringify(obj, null, 2)], {type : 'application/octet-stream'});

        const reader = new FileReader();
        //reader.result;

        const d0 = new Date().getTime();

        reader.readAsArrayBuffer(first);
        // data = reader.readAsDataURL(first);
        //

        const _2h = (num: number): string =>
          num < 16 ? `0${num.toString(16)}` : num.toString(16);
        //
        // L("ddddddddddddddddddddddddddddata", data);
        reader.addEventListener("loadend", () => {
          // reader.result contains the contents of blob as a typed array
          // console.log("read from file", reader.result);
          //
          data = reader.result;
          //
          if (data) {
            const ui8_sums_loaded_buffer = Buffer.from(data as ArrayBuffer);
            let ui32_sums_loaded_view = new Uint32Array(ui8_sums_loaded_buffer);
            /*
            L("loaded", ui8_sums_loaded_buffer.length, ui8_sums_loaded_buffer);
            L("loaded(u32)", ui32_sums_loaded_view);
            L("loaded(hex)", Array.from(ui32_sums_loaded_view).map(_2h));
            L(
              "loaded(stripe)",
              Array.from(ui32_sums_loaded_view).map(_2h).join("")
            );
            */
            const loaded_stripe = Array.from(ui32_sums_loaded_view)
              .map(_2h)
              .join("");

            //
            //
            //

            const BYTES_PER_ELEMENT = 40;
            const load_arr = loaded_stripe.split("");
            const load_indices = load_arr
              .filter((k, idx) => idx % BYTES_PER_ELEMENT === 0)
              .map((v, idx) => idx * BYTES_PER_ELEMENT); // hacked!

            const load_values = load_indices.map((offset) =>
              loaded_stripe.substring(offset, offset + BYTES_PER_ELEMENT)
            );
            const load_values_w_0x = load_values.map((addr40) => `0x${addr40}`);

            // L("loaded(indices)", load_indices.length, load_indices);
            // L("loaded(values)", load_values.length, load_values);

            const OPERATION_OUTPUT = load_values_w_0x;
            //
            const d1 = new Date().getTime();
            const ms = d1 - d0;

            setDataString(loaded_stripe);

            //

            L("OUTPUT", OPERATION_OUTPUT.length, OPERATION_OUTPUT[0], ms, "ms");
            setIsLoadedFile(true);
          }
        });

        //
        // reader.readAsArrayBuffer(first_as_file);
        //

        return data;
      }
    }
    //
    return data;
  }, [files, isLoadedFile, setIsLoadedFile]);
  //

  //
  // retrieving prices with getEthereumPriceFromCoinGecko, only once
  //
  const blockNumber = useBlockNumber();
  const { getEthereumPriceFromCoinGecko } = useCoinGeckoPrices();
  const [thePriceHeight, setThePriceHeight] = useState<number>(0);
  const [thePriceOfEth, setThePriceOfEth] = useState<number>(0);

  useEffect(() => {
    if (blockNumber) {
      const _refresh_price = async () => {
        const prices = await getEthereumPriceFromCoinGecko();
        console.log("EFFECT prices in my mind", prices);
        //
        setThePriceOfEth(prices.eth || 0);
      };
      //
      _refresh_price();
    } else {
      L("TPH is zero");
    }
    //
    return () => console.log("no effect removal for ETH price retrieve");
  }, [blockNumber]);

  //
  const resultMemo = useMemo(() => {
    console.log("RM-MEMO ready");
    //
    console.log(JSON.stringify(factoryPairsAtIndices));
    //
    return (factoryPairsAtIndices.filter((p) => p !== null) || []).length;
  }, [factoryPairsAtIndices]);
  //
  return (
    <MainContent>
      <Container>
        <Section>
          {dataString ? (
            <DataCanvas
              width={300}
              height={400}
              scale={1}
              data_string={dataString}
            />
          ) : (
            ""
          )}
          <br />

          <ForceGraph3D
            graphData={grt()}
            width={900}
            height={600}
            onNodeClick={(node: any) => {
              console.log("clicked", node);
              const nn: string = (node as any)["name"] as string;
              console.log("clicked", nn);
              // const isAddress = nn.length === 42 && nn.substring(0, 2) === "0x";
              // //
              // console.log("isAddress", isAddress);
              // if (isAddress) document.location.href = `./Upload/${nn}`;
              // Aim at node from outside it
              // const distance = 40;
              // const distRatio = 1 + distance/Math.hypot(node.x, node.y, node.z);

              // Graph.cameraPosition(
              //   { x: node.x * distRatio, y: node.y * distRatio, z: node.z * distRatio }, // new position
              //   node, // lookAt ({ x, y, z })
              //   3000  // ms transition duration
              // );
            }}
            linkThreeObjectExtend={true}

            // linkThreeObject={(link) => {
            //   // extend link with text sprite
            //   // const sprite = new SpriteText(`${link.source} > ${link.target}`);
            //   const sprite = new SpriteText(`${link.source} > ${link.target}`);
            //   sprite.color = "lightgrey";
            //   sprite.textHeight = 1.5;
            //   return sprite;
            // }}

            // linkPositionUpdate={(
            //   sprite: any,
            //   {
            //     end,
            //     start,
            //   }: { start: Record<string, any>; end: Record<string, any> }
            // ) => {
            //   const stuff = ["x", "y", "z"].map((c) => ({
            //     [c]: start[c] + (end[c] - start[c]) / 2, // calc middle point
            //   }));

            //   const middlePos = Object.assign({}, stuff);
            //   /*
            //     0: {x: 12.161593395996263}
            //     1: {y: 2.0248560863249057}
            //     2: {z: 9.720813206796132}
            //     */
            //   //   ...stuff)

            //   // Position sprite
            //   //Object.assign(sprite.position, middlePos);
            //   sprite.position.x = middlePos[0]?.x || 0.0;
            //   sprite.position.y = middlePos[1]?.y || 0.0;
            //   sprite.position.z = middlePos[2]?.z || 0.0;

            //   // sprite.position.x = 2;

            //   // sprite.needsUpdate = true;
            //   // console.log("START", start, "MP", middlePos);
            //   // console.log([start, end, stuff, middlePos, sprite.position]);
            //   //
            //   //return true; // ???
            //   return false;
            // }}
          />
        </Section>
        <Section>
          Start:<input type="number" value={start} onChange={(e) => setStart(parseInt(e.target.value))} /> {resultMemo}
          <SectionRow>
            <input type="file" onChange={onChange} />
            <br />
            hello: {UniswapV2FactoryABI.length} {UNI_V2_FACTORY_CONTRACT} <br />
            {factoryAllPairsLength ? (
              <span>All: {String(factoryAllPairsLength)}</span>
            ) : (
              "Loading..."
            )}
            {factoryPairAtIndex ? (
              <span>FP1: {JSON.stringify(factoryPairAtIndex)}</span>
            ) : (
              "Loading..."
            )}
          </SectionRow>
          {factoryPairsAtIndices
            ? JSON.stringify(factoryPairsAtIndices).length
            : "not ready"}
          <hr />

          {/* {factoryPairsAtIndices ? (
            <div>
              FPs:{" "}
              {factoryPairsAtIndices.map(
                (fp, idx) =>
                  fp && (
                    <span>
                      <small>{idx}</small>
                      {_as_smart_stripe(
                        1,
                        (fp[0] || "").toString(),
                        5,
                        5,
                        true
                      )}
                      
                    </span>
                  )
              )}
            </div>
          ) : (
            "Loading..."
          )} */}
        </Section>
        <Section>
          <SectionRow>
            <Title>In My Mind</Title>
            BM: {blobMemo}
            {account ? (
              <Button onClick={() => deactivate()}>Disconnect</Button>
            ) : (
              <Button onClick={() => activateBrowserWallet()}>Connect</Button>
            )}
          </SectionRow>
          <ContentBlock>
            <TextField
              variant="outlined"
              name="search-handled-method-hex"
              value={keywordHex}
              placeholder="Enter method id"
              onChange={(e) => setKeywordHex(e.target.value as string)}
            />
            <TextField
              variant="outlined"
              name="search-handled-method-name"
              value={keywordName}
              placeholder="Enter method name"
              onChange={(e) => setKeywordName(e.target.value as string)}
            />
            {handledMethodsMemo.length}
            {JSON.stringify(handledMethodsMemo[0])}

            <hr />
            <ContentRow>
              {_as_genome(
                filteredMethodsMemo
                  .slice(0, 100)
                  .map((x) => ({ ...x, type: "NP" }))
              )}{" "}
              {filteredMethodsMemo.length} / {handledMethodsMemo.length}
            </ContentRow>
            {filteredMethodsMemo
              .slice(0, 100)
              .map(
                ({
                  name,
                  signature,
                  input_types,
                  output_aliases,
                  idx,
                  hex,
                }: {
                  name: string;
                  signature: string;
                  input_types: string[];
                  output_aliases: string[];
                  idx: number;
                  hex: string;
                }) => (
                  <div style={{ fontSize: "11px" }}>
                    <div style={{ float: "left", width: "30px" }}>
                      {_as_interface_stripe(hex, 10)}
                    </div>
                    <div style={{ float: "left", width: "10%" }}>{hex}</div>
                    <div style={{ float: "left", width: "30%" }}>{name}</div>
                    <div style={{ float: "left", width: "40%" }}>
                      {output_aliases
                        .map((oa, oaid) => `${oa}: ${input_types[oaid]}`)
                        .join(" / ")}
                    </div>
                    <div style={{ float: "right", width: "5%" }}>{idx}</div>
                    <div style={{ clear: "both" }}></div>
                  </div>
                )
              )}

            <hr />
            <ContentBlock>
              {operationResultsSerialized.length && (
                <>
                  <ContentRow>
                    {Math.floor(operationResultsSerialized.length / 1024)} KB
                    ready to download.
                  </ContentRow>
                  <input
                    type="text"
                    value={fileName}
                    onChange={(e) => setFileName(e.target.value)}
                  />

                  <ContentRow>
                    <Button onClick={onDownloadResults}>DOWNLOAD</Button>
                  </ContentRow>
                </>
              )}
            </ContentBlock>
            <hr />

            {stakingBalance && (
              <ContentRow>
                <Label>ETH2 staking contract holds:</Label>{" "}
                <TextInline>{formatEther(stakingBalance)}</TextInline>{" "}
                <Label>ETH</Label>
              </ContentRow>
            )}

            <ContentRow>
              <Label>Storage holds:</Label>{" "}
              <TextInline>
                {/* {storageCount ? storageCount.toNumber() : 0} */}
              </TextInline>
              {account && <Button>Change</Button>}
            </ContentRow>
            <ContentRow>
              <Label>New value:</Label>{" "}
              <TextInline>
                <TextField
                  variant="outlined"
                  name="upload-image-description"
                  value={newStorageCount || 0}
                  placeholder="Enter value"
                  onChange={(e) => {
                    const v = e.target.value;
                    if (!isNaN(Number(v))) {
                      setNewStorageCount(parseInt(v));
                    }
                  }}
                />
              </TextInline>
            </ContentRow>

            <ContentRow>
              <Label>Your tokens</Label>{" "}
              <TextInline>
                {tokensOfOwner &&
                  tokensOfOwner.map((token: Array<ethers.BigNumber>) => (
                    <>
                      <button>{token ? token[0].toNumber() : ":-<"}</button>{" "}
                    </>
                  ))}
              </TextInline>
            </ContentRow>
            <ContentRow>
              <Label>Your prices</Label>{" "}
              <TextInline>
                {pricesOfTokens &&
                  pricesOfTokens.map((token: Array<ethers.BigNumber>) => (
                    <>
                      <button>{token ? formatEther(token[0]) : ":-<"}</button>{" "}
                    </>
                  ))}
              </TextInline>
            </ContentRow>
            <ContentRow>
              <Label>You have:</Label>{" "}
              <TextInline>
                {balanceOfOwner ? balanceOfOwner.toNumber() : 0}
              </TextInline>
              <Label>Fractal supply:</Label>{" "}
              <TextInline>
                {fractalTotalSupply ? fractalTotalSupply.toNumber() : 0}
              </TextInline>
              {account && (
                <>
                  <TextField
                    variant="outlined"
                    name="nft-mint-price"
                    value={newNftPrice || 0.01}
                    placeholder="Enter price"
                    onChange={(e) => {
                      const v = e.target.value;
                      const f = parseFloat(v);

                      !isNaN(f) && setNewNftPrice(f);
                    }}
                  />
                </>
              )}
            </ContentRow>

            {account && (
              <>
                <ContentRow>
                  <Label>Account:</Label> <TextInline>{account}</TextInline>
                </ContentRow>
                <ContentRow>
                  {colors.map((color: string) => (
                    <div
                      style={{
                        display: "inline-block",
                        width: "32px",
                        height: "32px",
                        backgroundColor: color,
                      }}
                    >
                      {" "}
                    </div>
                  ))}
                </ContentRow>
              </>
            )}
            {userBalance && (
              <ContentRow>
                <Label>Ether balance:</Label>{" "}
                <TextInline>{formatEther(userBalance)}</TextInline>{" "}
                <Label>ETH</Label>
              </ContentRow>
            )}
          </ContentBlock>
        </Section>
      </Container>
    </MainContent>
  );
}

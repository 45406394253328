import { TextField } from "@material-ui/core";
import { useBlockNumber, useEthers } from "@usedapp/core";
import { useCallback, useEffect, useState } from "react";
// import styled from "styled-components";
import {
  Container,
  ContentBlock,
  MainContent,
  Section,
  SectionRow,
  Button,
  ContentRow,
} from "../components/base";
import { Label } from "../typography/Label";
import { SubTitle } from "../typography/SubTitle";
import { Title } from "../typography/Title";

import useTransactionMonitor from "../hooks/useTransactionMonitor";

import DataCanvas from "../components/DataCanvas";
import { API_URL_ROOT } from "../settings";

const L = console.log;

// type SimpleBlockData = {
//   height: number;
//   size: number;
//   time: string;
//   tx: number;
//   deployments: Array<any>;
//   M: { L1: number; L2: number; L3: number };
// };

type DetailedBlockData = {
  meta: any;
  // raw: any;
  CREATIONS: Array<{
    transactionIndex: string;
    from: string;
    to: string;
    gasPrice: string;
    input: string;
  }>;
  DEPLOYMENTS: Array<any>;
  BY_TYPE: Record<string, number>;
  TRANSFERS: Array<{ from: string; to: string; eth: number }>;
  METHOD_IDS: Record<string, any>;
  CAS: Record<string, any>;
  UNDERSTOOD: Array<string>;
  UFO: Array<Array<number | string>>;
  M: {
    ms: {
      times: {
        net: number;
        pre: number;
        dec: number;
        uni: number;
        proc: number;
      };
      total: number;
    };
  };
};

const api_base_url = API_URL_ROOT;

export function Blocks() {
  const { activateBrowserWallet, deactivate, account } = useEthers();

  const MAX_BLOCKS = 1;
  //
  const [downloadedBlocks, setDownloadedBlocks] = useState<
    Array<DetailedBlockData>
  >([] as Array<DetailedBlockData>);
  //

  const blockNumber = useBlockNumber();
  const { chainId } = useEthers();

  const { watch } = useTransactionMonitor();
  const [fromHeight, setFromHeight] = useState<number>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dl1 = async (url: string) => {
    // useCallback(
    L("fetching", chainId, url);
    const json = await fetch(url).then((res) => res.json());
    L("received", json);

    const b = json["value"];
    L("b", b);

    if (b.DEPLOYMENTS.length > 0) {
      const fr = b.DEPLOYMENTS[0];
      const { from, to } = fr;
      //
      console.log("now start watching: ", from, to);
      // const watchRes = watch(from, to);

      //
      console.log("WATCHING DISABLED", watch);
      // console.log("NOW WATCHING", watchRes);
    }
    // setDownloadedBlocks([...downloadedBlocks, b]);
    // downloadedBlocks.push(b);
    // setContract(json["value"] as ContractSupportingERC721);
    // return b;
    //   setDownloadedBlocks([...downloadedBlocks, b]);
    return b;
  }; //,
  // [downloadedBlocks]
  //);

  const checkContract = useCallback(async () => {
    if (!isLoading && chainId && fromHeight) {
      setIsLoading(true);
      //
      // 1. create range
      // 2. create promises
      // 3. save empty results
      // 4. launch promises

      L("FROM", fromHeight, "MAX", MAX_BLOCKS);
      const range = new Array(MAX_BLOCKS)
        .fill(0)
        .map((n: any, idx: number) => fromHeight - idx);
      L("RANGE", range);
      const urls = range.map(
        (n) => `${api_base_url}/block/${n}?chain=${chainId}`
      );
      L("RANGE", range);
      L("URLS", urls);

      const first = urls[0];
      L("first", first);

      //const promise = dl1(first);
      const promises = urls.map(dl1);
      //L("promise", promise);
      // const res = await promise;
      const results = await Promise.all(promises);
      setDownloadedBlocks([
        ...downloadedBlocks,
        ...(results as Array<DetailedBlockData>),
      ]);
      L("results", results);

      // const url = `${api_base_url}/contract/${ca}?chain=${chainId}`;
      // L("fetching", chainId, url);
      // setContract(undefined);
      // setFirstBlock(undefined);
      // setFirstOwnerBlock(undefined);
      // setFirstDeployerBlock(undefined);
      // setTransactions(undefined);

      // const json = await fetch(url).then((res) => res.json());
      // L("received", json);

      // setContract(json["value"] as ContractSupportingERC721);
      //
      setIsLoading(false);
    }
  }, [chainId, fromHeight]);

  useEffect(() => {
    if (blockNumber) {
      setFromHeight(blockNumber);
      checkContract();
    }
  }, [blockNumber, checkContract]);

  const onSaveClicked = useCallback(checkContract, [
    chainId,
    fromHeight,
    isLoading,
    checkContract,
  ]);

  return (
    <MainContent>
      <Container>
        <Section>
          <SectionRow>
            <Title>Blocks</Title>
            {account && <Button onClick={deactivate}>Disconnect</Button>}
            {!account && (
              <Button onClick={() => activateBrowserWallet()}>Connect</Button>
            )}
          </SectionRow>
        </Section>
      </Container>
      <Container>
        <Section>
          <ContentBlock>
            <ContentRow>
              <SubTitle>Contract Inspection</SubTitle>
            </ContentRow>
            <ContentRow>
              <Label>Address:</Label>{" "}
              <TextField
                name="input-contract-address"
                fullWidth
                value={
                  fromHeight ? `${fromHeight}..${fromHeight + MAX_BLOCKS}` : ""
                }
                placeholder="Enter contract address"
              />
              <input
                type="range"
                // min={13945761}
                min={13959523}
                max={13959575}
                onChange={(e) => {
                  L("changed range", e.target.value);
                  setFromHeight(parseInt(e.target.value));
                }}
              />
            </ContentRow>
            <ContentRow>
              <Button onClick={onSaveClicked}>Check</Button>
            </ContentRow>
            <ContentRow></ContentRow>
          </ContentBlock>
        </Section>
        <Section>
          <ContentBlock>
            <ContentRow>
              {downloadedBlocks.map(
                (b) =>
                  b && (
                    <div key={b.meta.height}>
                      <div>
                        B:{b.meta.height} -- Tr:{" "}
                        {b.BY_TYPE["0x0"] ? b.BY_TYPE["0x0"] : 0} / Calls:{" "}
                        {b.BY_TYPE["0x2"] ? b.BY_TYPE["0x2"] : 0} C:{" "}
                        {b.BY_TYPE["0x1"] ? b.BY_TYPE["0x1"] : 0} G:{" "}
                        {b.DEPLOYMENTS.length} |
                        {b.DEPLOYMENTS.length > 0 && (
                          <div>
                            {b.DEPLOYMENTS[0].from} -- {b.DEPLOYMENTS[0].to}|{" "}
                            {b.DEPLOYMENTS[0].input.length}
                          </div>
                        )}
                      </div>
                      <div>
                        {" "}
                        {b.DEPLOYMENTS[0] &&
                          Object.keys(b.DEPLOYMENTS[0]).join(" - ")}
                      </div>
                      <div> {b.DEPLOYMENTS[0] && b.DEPLOYMENTS[0].value}</div>
                      <div>
                        {"TO: "}
                        {b.DEPLOYMENTS[0] && b.DEPLOYMENTS[0].to}
                      </div>
                      <div>
                        {" "}
                        {b.DEPLOYMENTS[0] &&
                          JSON.stringify(b.DEPLOYMENTS[0].accessList)}
                      </div>
                      {b.DEPLOYMENTS[0] && (
                        <div>
                          <DataCanvas
                            width={800}
                            height={160}
                            data_string={b.DEPLOYMENTS[0].input}
                          />
                        </div>
                      )}
                    </div>
                  )
              )}
            </ContentRow>
          </ContentBlock>
        </Section>
        <Section>{/* <DataCanvas width={800} height={160} /> */}</Section>
        downloadedBlocks
      </Container>
    </MainContent>
  );
}

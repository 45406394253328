import { loadNames } from "../ai";
import { iconMapping } from "../settings";
import { method_colors, account_colors } from "../utils/colors";
import { _exclude_all_known, _get_exclude_groups } from "../utils/genomes";
import { ContentRow } from "./base";

export const _as_interface_stripe = (methodID: string, size: number) => {
  return method_colors(methodID).map((color: string) => (
    <div
      data-id={methodID}
      style={{
        display: "inline-block",
        width: `${size}px`,
        height: `${size}px`,
        backgroundColor: color,
      }}
    >
      {" "}
    </div>
  ));
};

export const _as_multi_stripe = (input: string, size: number) => {
  const addresses = (input || "").split(",");
  //
  // console.log("_as_multi_stripe", addresses.length, input.length, addresses);
  //
  //
  return (
    <div
      data-id={input}
      style={{
        display: "inline-block",
        height: `${size}px`,
      }}
    >
      {addresses.map((address: string, idx: number) => (
        <a
          href={`/upload?address=${address}`}
          target="_blank"
          referrerPolicy="no-referrer"
        >
          {" "}
          {_as_stripe(address, size / 2, size / 2)}{" "}
          {idx !== addresses.length - 1 && "/"}
        </a>
      ))}
    </div>
  );
};

export const _as_multi_num = (input: any, size: number) => {
  const nums = input as Array<any>; // oh typescript... :-)
  //const addresses = (input || "").split(",");
  //
  // console.log("_as_multi_stripe", addresses.length, input.length, addresses);
  //
  return (
    <div
      data-id={input}
      style={{
        display: "inline-block",
        height: `${size}px`,
      }}
    >
      {nums
        .sort((a, b) => a - b)
        .map((num: string, idx: number) => (
          <span>
            {" "}
            {num} {idx !== nums.length - 1 && "/"}
          </span>
        ))}
    </div>
  );
};

const _as_tooltip = (
  displayed: JSX.Element[],
  tooltipContent: string | JSX.Element,
  leftShift: number
): JSX.Element => {
  return (
    <div style={{ display: "inline-block" }} className="cooltip">
      <div
        style={{
          maxHeight: "24px",
        }}
      >
        {displayed}
        <div style={{ height: "1px", width: "1px" }}>
          <span
            className="cooltiptext"
            style={{
              position: "absolute",
              padding: "4px",
              top: "-10px",
              left: `${leftShift}px`,
            }}
          >
            {typeof tooltipContent === "string" ? (
              <span
                style={{
                  padding: "6px",
                  fontSize: "9px",
                  backgroundColor: "#222342",
                  color: "#aabbcc",
                }}
              >
                {tooltipContent}
              </span>
            ) : (
              tooltipContent
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

const _as_tooltip2 = (
  displayed: JSX.Element[],
  tooltipContent: string | JSX.Element,
  leftShift: number
): JSX.Element => {
  return (
    <div
      className="cooltip"
      style={{ display: "inline-block", height: "auto" }}
    >
      <div
        // style={{
        //   height: "20px",
        // }}
      >
        {displayed}
        <div
          className="cooltiptext"
          style={{
            // position: "absolute",
            padding: "4px",
            // top: "-10px",
            left: `${leftShift}px`,
            height: "auto",
          }}
        >
          {typeof tooltipContent === "string" ? (
            <span
              style={{
                padding: "6px",
                fontSize: "9px",
                backgroundColor: "#222342",
                color: "#aabbcc",
              }}
            >
              {tooltipContent}
            </span>
          ) : (
            tooltipContent
          )}
        </div>
      </div>
    </div>
  );
};

export const _as_stripe = (
  from: string,
  size: number,
  height?: number,
  create_as_tooltip?: boolean,
  leftShift?: number
) => {
  if (!from) return;
  //
  const control = account_colors(from).map((color: string) => (
    <div
      // data-id={from}
      style={{
        display: "inline-block",
        width: `${size}px`,
        height: `${height ? height : size}px`,
        backgroundColor: color,
      }}
    >
      {" "}
    </div>
  ));
  //
  return create_as_tooltip
    ? _as_tooltip(control, from, leftShift || 0)
    : control;
};

const AI_NAMING = loadNames();
const _is_top_erc20 = (address: string) =>
  AI_NAMING.ADDR.ERC20_TOP.includes(address.toLowerCase());
//
export const _as_smart_symbol_address = (from: string, entry?: any) => {
  if (!from) return <></>;
  if (!entry) return <></>;
  //

  //
  return (
    <div
      style={{
        float: "right",
        paddingLeft: "3px",
        paddingRight: "3px",
        fontSize: "9px",
        marginRight: "6px",
        marginTop: "-2px",
        border: "solid 1px rgba(0,0,0,0.33)",
        borderRadius: "3px",
        textAlign: "center",
        lineHeight: "12px",
        height: "16px",
        backgroundColor: "rgba(0,0,0,0.2)",
      }}
    >
      <b
        style={{
          margin: "auto",
          marginTop: "6px",
        }}
      >
        {entry.symbol}
      </b>
      <div
        style={{
          margin: "auto",
          height: "6px",
          position: "relative",
          top: "-8px",
        }}
      >
        {_as_stripe(from, 3, 2, false)}
      </div>
    </div>
  );
};
//
export const _as_smart_stripe = (
  type: number, // 1 - contract, 2 - user
  from: string,
  size: number,
  height?: number,
  create_as_tooltip?: boolean,
  leftShift?: number
) => {
  if (!from) return <></>;
  //
  const is_top_erc20 = _is_top_erc20(from);
  const find_idx = AI_NAMING.ADDR.ERC20_TOP.indexOf(from);
  const entry = find_idx !== -1 ? AI_NAMING.META.ERC20_TOP[find_idx] : null;
  //
  const control = is_top_erc20
    ? [_as_smart_symbol_address(from, entry)]
    : account_colors(from).map((color: string) => (
        <div
          // data-id={from}
          style={{
            display: "inline-block",
            width: `${size}px`,
            height: `${height ? height : size}px`,
            backgroundColor: color,
          }}
        >
          {" "}
        </div>
      ));
  //
  //
  const tooltipContent = (): JSX.Element => {
    if (is_top_erc20) {
      //
      if (entry) {
        return (
          <div
            style={{
              minWidth: "160px",
              maxWidth: "400px",
              width: "auto",
              height: "auto",
              padding: "5px",
              fontSize: "11px",
              textAlign: "left",
              lineHeight: "12px",
            }}
          >
            <b
              style={{
                fontSize: "14px",
                whiteSpace: "nowrap",
                maxWidth: "400px",
              }}
            >
              #{entry.rank}. {entry.name}
            </b>
            <div style={{ marginTop: "-4px", height: "5px" }}>
              {_as_stripe(from, 16, 2, false)}
            </div>
            <br />
            <span style={{ fontSize: "12px", marginBottom: "2px" }}>
              Cap: {entry.cap}
            </span>
            <br />
            <span style={{ fontSize: "10px" }}>
              Price: {entry.pUSD} $ / {entry.pETH} ETH
            </span>
            <br />
            <div style={{ marginTop: "7px", clear: "both" }}>
              Holders: {(entry.hold * 0.001).toFixed(2)}K
              {_as_smart_symbol_address(from, entry)}
            </div>
            {/* {_as_tooltip([<div>more...</div>], <div>{entry.desc}</div>, 0)} */}
            {/* more... */}
          </div>
        );
      }
    }

    return <div>{from}</div>;
  };
  //
  return create_as_tooltip
    ? _as_tooltip2(control, tooltipContent(), -45 /*leftShift || 0*/)
    : control;
};

export const _as_genome = (
  arr: Array<{
    type: string;
    hex: string;
    name: string;
  }>
) => {
  return arr.map(
    ({ type, hex, name }: { type: string; hex: string; name: string }) => (
      <div style={{ display: "inline-block" }} className="cooltip">
        <div
          //  className="tooltip"
          style={{
            padding: "1px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "44px",
            maxHeight: "24px",
            border: "solid 1px #aaaaaa",
          }}
        >
          <div
            style={{
              padding: "auto",
              margin: "1px",
            }}
          >
            {_as_interface_stripe(hex, 18)}
            <div style={{ height: "1px", width: "1px" }}>
              <span className="cooltiptext" style={{ position: "absolute" }}>
                {name}
              </span>
            </div>
          </div>
          <div
            style={{
              position: "relative",
              height: "0px",
              top: "-20px",
              padding: "auto",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "12px",
              color: "white",
              textShadow: "1px 1px #000000",
            }}
          >
            {type === "view"
              ? "V"
              : type === "nonpayable"
              ? "NP"
              : type === "payable"
              ? "P!"
              : type === "pure"
              ? "Vp"
              : type}
          </div>
        </div>
      </div>
    )
  );
};

//export const _as_simplified_genome = (getters: Array<{ hex: string }>) => {
export const _as_simplified_genome = (
  getters: Array<{
    type: string;
    hex: string;
    name: string;
  }>,
  exclude_by_item: boolean = false
) => {
  const simplified_genome = _exclude_all_known(getters, exclude_by_item);
  const simplified_groups: Record<string, number> = _get_exclude_groups(
    getters,
    exclude_by_item
  );
  //
  const is_understood = simplified_genome.length === 0;
  //
  const icons: Record<string, any> = {} as Record<string, any>;
  //
  for (const [group, ratio] of Object.entries(simplified_groups)) {
    const currentMapping = iconMapping[group];
    //
    if (currentMapping) {
      // icons.push(currentMapping);
      icons[currentMapping] = { group, ratio, currentMapping };
    } else {
      throw new Error(
        `Missing icon mapping for simplification group [${group}], check settings.`
      );
    }
  }
  /*
  if (genomeSupportsErc20(getters)) icons.push("ERC20");
  if (genomeSupportsSafeMath(getters)) icons.push("SAFEMATH");
  if (genomeSupportsUniswap(getters)) icons.push("UNISWAP");
  */
  // icons.push("ERC20");
  // icons.push("SAFEMATH");
  //
  console.log("icons", icons);
  //
  return (
    <ContentRow>
      {is_understood ? <b>Understood completely.</b> : "Simplified:"}
      <br />
      {Object.values(icons).map(
        (
          {
            group,
            ratio,
            currentMapping,
          }: { group: string; ratio: number; currentMapping: string },
          idx: number
        ) => (
          <span>
            [{currentMapping}][{Math.floor(ratio * 100)} %]
          </span>
        )
      )}
      {" + "}
      {_as_genome(
        simplified_genome.sort((a: any, b: any) => a.hex.localeCompare(b.hex))
      )}
    </ContentRow>
  );
};

// import { ethers } from "@usedapp/core/node_modules/ethers";

import methodIds from "./config/method-helper/ids";
import HandledMethodsLoaded from "./config/method-helper/abis";
import { ethers } from "ethers";

//const methodIds = ["0x51c6590a"];

//
//
//
export type HandledMethodId = typeof methodIds[number]; // same as:  type HandledMethodId = "0xefef39a1" | "0x2e1a7d4d" | "0xa9059cbb";
export type MethodEnvelope = {
  name: string; // approve
  signature: string; // approve(address,uint256)
  input_types: Array<string>; // ["address", "uint256"]
  output_aliases: Array<string>; // ["spender", "amount"]
};

const HandledMethods: Record<HandledMethodId, MethodEnvelope> =
  HandledMethodsLoaded;

const L = console.log;

class MethodHelperSingleton {
  //   provider;
  //   web3;
  coder = new ethers.utils.AbiCoder();

  constructor() {
    L("MethodHelperSingleton created, using coder", this.coder);
  }
  //
  ___decoder = (inputtypes: Array<string>, params: string) =>
    new ethers.utils.AbiCoder().decode(inputtypes, params);
  // this.coder.decode(inputtypes, params);
  // const ac = new ethers.utils.AbiCoder();
  //this.web3.eth.abi.decodeParameters(inputtypes, params);

  //
  unwrap = (methodId: HandledMethodId, params_hex_string: string) => {
    const envelope = HandledMethods[methodId];
    const { name, signature, input_types, output_aliases } = envelope;
    //
    /*
      L(
        "unwrap",
        methodId,
        name,
        input_types.join(","),
        "->",
        output_aliases.join(", ")
      );
      */

    let result;
    try {
      //   const res0 = this.web3.eth.abi.decodeParameters(
      //     input_types,
      //     params_hex_string
      //   );
      console.log("INPUT-TYP", input_types);
      console.log("INPUT-HEX", params_hex_string);
      //
      // !!!!!!!!!!!!!!!!!!!! CLIENT IS USING THE COMPLETE MESSAGE INSTEAD OF THE PARAMS PART ONLY
      //
      // const res0 = this.coder.decode(input_types, params_hex_string);
      const res0 = this.coder.decode(input_types, "0x" + params_hex_string);
      //
      //
      //
      console.log("RES0", res0);

      let result: Record<string, any> = {};

      // result['M'] = name;

      if (res0) {
        const entries = Object.entries(res0);
        // const length = entries[entries.length - 1][1] as number;
        const length = entries.length; // !!!!!!!!!!!!!! CLIENT SIDE !!!!!!!!!!!!!!!!!!

        L("length", length);
        // L("as_arr", as_arr);
        L("ent", entries);
        L("output_aliases", output_aliases);

        // mapping the result
        for (let i = 0; i < length; i++) {
          const alias = output_aliases[i];
          L(
            "RES0 reads",
            i,
            alias,
            res0[i],
            "BN",
            ethers.BigNumber.isBigNumber(res0[i])
          );
          //
          result[alias] = res0[i]; // original mapping
          result[alias] = ethers.BigNumber.isBigNumber(res0[i])
            ? res0[i].toString()
            : res0[i]; // original mapping
        }
      }

      return [name, result]; //L("UNWRAP", name, result, JSON.stringify(result));
    } catch (ex: any) {
      L("FAILED TO DECODE", methodId, params_hex_string, ex.toString());
      return [name, { methodID: methodId, failed: "true", ex: ex.toString() }];
    }
    return result;
  };
  //
  get_name = (s: string) => HandledMethods[s as HandledMethodId].name;
  //
  test = () => {
    L("testing mh");
    L(HandledMethods);
    /*
      'interface IERC20 {\r\n' +
      '    function totalSupply() external view returns (uint256);\r\n' +
      '    function balanceOf(address account) external view returns (uint256);\r\n' +
      '    function transfer(address recipient, uint256 amount) external returns (bool);\r\n' +
      '    function allowance(address owner, address spender) external view returns (uint256);\r\n' +
      '    function approve(address spender, uint256 amount) external returns (bool);\r\n' +
      '    function transferFrom(address sender, address recipient, uint256 amount) external returns (bool);\r\n' +
  
      '    event Transfer(address indexed from, address indexed to, uint256 value);\r\n' +
      '    event Approval(address indexed owner, address indexed spender, uint256 value);\r\n' +
      '}\r\n' +
      */
  };
  //
  /**
   * Return a valid MethodName from a JSON-encoded string or throw.
   */
  parseMethodName(str: string): HandledMethodId | null {
    return methodIds.find((validName) => validName === str) || null;
  }
  //
  is_supported_method = (s: string): boolean =>
    this.parseMethodName(s) !== null;
}

const mh = new MethodHelperSingleton();

export default mh;

import { combineReducers } from "redux";

import uiReducer from "./ui/ui.reducer";
import userReducer from "./user/user.reducer";
import searchReducer from "./search/search.reducer";
import topTenReducer from "./cache/top10/top10.reducer";
import spReducer from "./cache/sp/sp.reducer";

export default combineReducers({
  ui: uiReducer,
  sp: spReducer,
  user: userReducer,
  search: searchReducer,
  top10: topTenReducer,
});

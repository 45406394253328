import React, { useRef, useEffect, useCallback } from "react";
import useMouseMove from "../hooks/useMouseMove";

import mh from "../ai/method-helper";

const DataCanvasBlockMap = (props: {
  width: number;
  height?: number;
  data_string: string;
  scale: number;
}) => {
  const { width, height, data_string, scale } = props;
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const { x, y, xx, yy, handleMouseMove } = useMouseMove();
  // console.log("MOUSE", x, y);
  // if (canvasRef.current) {
  //   canvasRef.current.width = width || 100;
  //   canvasRef.current.height = height || 100;
  // }

  const to_draw = data_string.substring(2, data_string.length - 2); // remove 0x
  console.log(
    "canvasRef",
    canvasRef,
    width,
    height,
    "drawing.td",
    to_draw.length
  );
  //
  const arr = to_draw.split("");
  //
  const onDraw = useCallback(
    (canvas: HTMLCanvasElement, context: CanvasRenderingContext2D) => {
      const indices = arr
        .filter((k, idx) => idx % 4 === 0)
        .map((v, idx) => idx * 4); // hacked!

      const trios = indices.map((idx) =>
        [0, 1, 2, 3].map((i) => parseInt(arr[idx + i] || "0", 16))
      );
      console.log(arr.length, arr.length % 4 === 0);

      console.log(trios[0]);

      // var c = document.getElementById("myCanvas");
      const in_row = Math.floor(width / scale);
      const total_rows = Math.floor(trios.length / in_row) + 1; // + 1;
      console.log(
        "scale",
        scale,
        "in_row",
        in_row,
        "w",
        width,
        "TR",
        total_rows,
        "NH",
        in_row * scale
      );

      // too large ?
      if (canvas.height > total_rows * scale) {
        canvas.height = Math.min(total_rows * scale, 2048);
      }

      // too small ?
      if (canvas.height < total_rows * scale) {
        canvas.height = Math.min(total_rows * scale, 2048);
      }

      if (context !== null) {
        // clear with white
        // x y w h
        context.fillStyle = `#ffffffff`;
        context.fillRect(0, 0, canvas.width, canvas.height);

        let cell_num = 0;
        let row_num = 0;
        //
        for (const t of trios) {
          if (cell_num % in_row === 0 && cell_num !== 0) row_num++;
          //

          if (t[0] === 0 && t[1] === 0 && t[2] === 0 && t[3] === 0) {
            context.fillStyle = `rgba(0,0,0,0.2)`;
            // x y w h
            context.fillRect(
              scale * (cell_num % in_row),
              scale * row_num,
              scale,
              scale
            );
          } else {
            context.fillStyle = `rgba(${t[0] * 16}, ${t[1] * 16}, ${
              t[2] * 16
            }, ${255 - t[3] / 125.5})`;
            context.fillRect(
              scale * (cell_num % in_row),
              scale * row_num,
              scale,
              scale
            );
          }

          //
          cell_num++;
        }
        //
        // draw txt
        //
        //context.font = '14px wingdings';

        const SP = 3;
        const FS = 15;
        context.font = `${FS}px helvetica`;
        context.fillStyle = `#000000ff`;
        /*
        context.fillText(`[${x},${y}]`, 5, FS);
        context.fillText(`[${xx},${yy}]`, 5, 2 * FS + 1 * SP);
        */

        const horizontal_min = 0;
        const horizontal_max = scale * in_row;
        //
        const vertical_min = 0;
        const vertical_max = scale * total_rows;
        //
        const reverse_row_idx = Math.floor(
          Math.max(Math.min(xx, horizontal_max), horizontal_min) / (scale * 2) // two cols per item
        );
        const reverse_col_idx = Math.floor(
          Math.max(Math.min(yy, vertical_max), vertical_min) / scale // one row per item
        );
        //
        const offset =
          reverse_col_idx * Math.floor(in_row / 2) + reverse_row_idx;
        const max_offset = Math.floor(to_draw.length / (4 * 2));
        //
        const THE_OFFSET = Math.max(Math.min(offset, max_offset), 0);
        //
        const fn_data_by_offset = (offs: number) => {
          return offs === -1 ? "" : to_draw.substring(offs * 8, offs * 8 + 8);
        };

        /*
        //
        context.fillText(
          `H[${scale} * ${in_row} = ${scale * in_row}]`,
          5,
          3 * FS + 2 * SP
        );
        context.fillText(
          `V[${scale} * ${total_rows} = ${scale * total_rows}]`,
          5,
          4 * FS + 3 * SP
        );
        //

        context.fillText(
          `REV[${reverse_row_idx} , ${reverse_col_idx} => ${
            scale * total_rows
          }]`,
          5 + 46 * SP,
          2 * FS + 3 * SP
        );
        context.fillText(
          `OFF[${THE_OFFSET} / ${max_offset}]`,
          5 + 46 * SP,
          3 * FS + 4 * SP
        );
        context.fillText(
          `DATA[${offset !== THE_OFFSET ? "" : fn_data_by_offset(THE_OFFSET)}`,
          6 + 46 * SP,
          4 * FS + 5 * SP
        );
        */

        if (offset === THE_OFFSET) {
          //
          // draw crosshair
          //
          // remove aliasing
          const cross_x = Math.floor(xx) + 0.5;
          const cross_y = Math.floor(yy) + 0.5;
          //
          context.moveTo(cross_x, cross_y - 10);
          context.lineTo(cross_x, cross_y + 10);

          context.moveTo(cross_x - 10, cross_y);
          context.lineTo(cross_x + 10, cross_y);

          // Line color
          context.font = `10px arial`;
          context.strokeStyle = "black";
          context.strokeRect(cross_x - 1, cross_y - 10, 1, 20);
          context.strokeRect(cross_x - 10, cross_y - 1, 20, 1);
          context.strokeRect(cross_x - 5, cross_y - 5, 10, 10);
          context.fillText(
            `${offset !== THE_OFFSET ? "" : fn_data_by_offset(THE_OFFSET)}`,
            cross_x + 9,
            cross_y + 9
          );
          context.fillText(
            `${
              offset !== THE_OFFSET
                ? ""
                : mh.is_supported_method("0x" + fn_data_by_offset(THE_OFFSET))
                ? mh.get_name("0x" + fn_data_by_offset(THE_OFFSET))
                : "ETH tx"
            }`,
            cross_x + 9,
            cross_y + 19
          );
        }
      }
      //
    },
    [arr, width]
  );

  useEffect(() => {
    const canvas = canvasRef.current;
    //
    if (canvas !== null) {
      const context: CanvasRenderingContext2D | null = canvas.getContext("2d");
      //
      if (context !== null) onDraw(canvas, context);
    }
  }, [onDraw]);

  return (
    <canvas
      style={{ border: "solid 1px black" }}
      ref={canvasRef}
      {...props}
      onMouseMove={handleMouseMove}
    />
  );
};

export default DataCanvasBlockMap;

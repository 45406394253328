export const Colors = {
  Main: {
    Primary:'#B912E6',
    Secondary:'#4FDC2D',
    Third:'#0E8CDE',
    Fourth:'#FFFFFF',
  },
  Black: {
    900: '#23242A',
    200: '#DDE2EB',
  },
  Gray: {
    600: '#757575',
    300: '#E0E0E0',
  },
  White: '#ffffff',
  Yellow: {
    500: '#F2C94C',
    100: '#FFF4D4',
  },
}

export const Fonts = {
  Helvetica: '"HelveticaNeue", sans-serif',
}

export const Shadows = {
  main: '0px 4px 28px rgba(136, 169, 200, 0.15)',
}

export const Sizes = {
 // headerHeight: '64px',
 headerHeight: '0px',  // no padding top!
}

export const Gradients = {
  bodyBackground: `linear-gradient(180deg, ${Colors.Main.Third}, ${Colors.Main.Secondary})`,
}

export const Transitions = {
  duration: '0.25s',
  all: 'all 0.25s ease',
}

export const BorderRad = {
  s: '8px',
  m: '24px',
  round: '50%',
  full: '1000px',
}


import { BrowserRouter } from "react-router-dom";
import RoutedApp from "./RoutedApp";

// function usePageViews() {
//   let location = useLocation();
//   useEffect(() => {
//     console.log("LOCATION", location);
//     console.log(' ga.send(["pageview", location.pathname]); NOT CALLED');
//   }, [location]);
// }


const App = () => {
  // console.log("REDUX RUNNING", "app recieved", setCurrentUser);
  //
  return (
    <>
      <BrowserRouter>
        <RoutedApp
        // enteredSite={enteredSite}
        // setEnteredSite={setEnteredSite}
        // setCurrentUser={setCurrentUser}
        />
      </BrowserRouter>
    </>
  );
};

// export default connect(mapStateToProps, mapDispatchToProps)(App);
export default App;

// export default App;

/* 
<Page>
	
      <CssBaseline />
      <GlobalStyle />
      <BrowserRouter>
        <TopNavBar />
        <Routes>
          <Route path="/balance" element={<ViewOne />} />
          <Route path="/block" element={<Block />} />
          <Route path="/tokens" element={<Tokens />} />

          <Route path="/upload" element={<Upload />} />
          <Route path="/upload/:address" element={<Upload />} />

          <Route path="/blocks_test" element={<Blocks />} />
          <Route path="/first_blocks" element={<FirstBlocks />} />
          <Route path="/blocks" element={<BlockManagement />} />
          
          
          <Route path="/view_one" element={<Balance />} />
          <Route path="/in_my_mind" element={<InMyMind />} />
          
          <Route path="/" element={<Navigate replace to="/balance" />} />
          
          <Route path="/new" element={<NewContracts />} />
        </Routes>
      </BrowserRouter>
	  
    </Page> 
*/

import {
  EXCLUDABLES,
  // UNISWAP_V2_INTERFACE,
  // ERC_20_INTERFACE,
  // SAFEMATH_V0_INTERFACE,
} from "../settings";

const _intersects = (
  subject: Array<string>,
  to_match: Array<string>
): boolean =>
  to_match
    .map((genome) => subject.includes(genome))
    .reduce((a: boolean, b: boolean) => a || b, true);
//
const _overlaps = (subject: Array<string>, to_match: Array<string>): boolean =>
  to_match
    .map((genome) => subject.includes(genome))
    .reduce((a: boolean, b: boolean) => a && b, true); // !!!!!!!!!!!!!!!!!!!!!! match all

//
export const _exclude = (
  subject: Array<{
    type: string;
    hex: string;
    name: string;
  }>,
  to_match: Array<string> // intended string array
): Array<{
  type: string;
  hex: string;
  name: string;
}> => subject.filter((genome) => !to_match.includes(genome.hex)); // !!!!!!!!!!!!!!!!!!!!!! match all

//
// temporarily creating a map of excludeable sets, this should move to a singleton
//

//
export const _exclude_all_known = (
  subject: Array<{
    type: string;
    hex: string;
    name: string;
  }>,
  exclude_by_item: boolean = false
): Array<{
  type: string;
  hex: string;
  name: string;
}> => {
  //
  // we need to match by hex and return the items finaly
  //
  let arr = [...subject];
  //
  console.log(
    "_exclude_all_starts from",
    arr.length,
    "items of",
    subject.length
  );
  for (const [key, interfac] of Object.entries(EXCLUDABLES)) {
    const to_match = interfac.map((m) => m.hex);
    // console.log("matching", key, "length", arr.length);
    //
    if (exclude_by_item) {
      // we exclude on ITEM by ITEM basis
      arr = _exclude(arr, to_match);
    } else {
      // we exclude on a group basis, ALL or NOTHING
      const currentSubject = arr.map((genome) => genome.hex);
      const is_all_match = _overlaps(currentSubject, to_match);
      //
      if (is_all_match) {
        arr = _exclude(arr, to_match);
      }
    }
    //
    // console.log("matching resulted in ", arr.length, "length", arr);
  }
  //
  console.log(
    "_exclude_all_known returning",
    arr.length,
    "items of",
    subject.length
  );

  return arr;
};
//
//
//
//
export const _get_exclude_groups = (
  subject: Array<{
    type: string;
    hex: string;
    name: string;
  }>,
  exclude_by_item: boolean = false
): Record<string, number> => {
  //
  // we need to return the name of the exclude groups, that are matching the genome
  //
  let arr = [...subject];
  //
  console.log(
    "_get_exclude_groups from",
    arr.length,
    "items of",
    subject.length
  );
  //
  // intruducing match ratio, 1 when total match [0..1] when partial match
  //
  // const groups = [] as Array<string>;
  const groups: Record<string, number> = {};
  //
  for (const [key, interfac] of Object.entries(EXCLUDABLES)) {
    const to_match = interfac.map((m) => m.hex);
    // console.log("matching", key, "length", arr.length);
    //
    if (exclude_by_item) {
      //
      // we exclude on ITEM by ITEM basis
      //
      const length_before = arr.length;
      arr = _exclude(arr, to_match); // reducing set
      const length_after = arr.length;
      const diff = length_before - length_after;
      const has_changed = diff > 0;
      //
      if (has_changed) {
        // groups.push(key); // saving group
        const ratio = diff / to_match.length; // always positive
        groups[key] = ratio; // saving group
      }
    } else {
      //
      // we exclude on a group basis, ALL or NOTHING
      //
      const currentSubject = arr.map((genome) => genome.hex);
      const is_all_match = _overlaps(currentSubject, to_match);
      //
      if (is_all_match) {
        arr = _exclude(arr, to_match); // reducing set
        // groups.push(key); // saving group
        groups[key] = 1; // saving group
      }
    }
    //
    // console.log("gex matching resulted in ", arr.length, "length", arr);
  }
  //
  console.log(
    "_get_exclude_groups returning",
    arr.length,
    "items of",
    subject.length
  );

  // return arr;
  return groups;
};
//
// test for all erc20 keys by hex, return true when ALL positive
//
export const genomeSupportsErc20 = (
  getters: Array<{ hex: string }>
): boolean => {
  const interfac = EXCLUDABLES.ERC_20_INTERFACE;
  const chromosome_to_match: Array<string> = interfac.map((m) => m.hex);
  const subject_genome: Array<string> = getters.map((m) => m.hex);
  //
  console.log(
    "genomeSupportsErc20",
    subject_genome.length,
    chromosome_to_match.length,
    _overlaps(subject_genome, chromosome_to_match)
  );
  return _overlaps(subject_genome, chromosome_to_match); // match all
};

//
// test for the 4 safemath pure functions
//
export const genomeSupportsSafeMath = (
  getters: Array<{ hex: string }>
): boolean => {
  const interfac = EXCLUDABLES.SAFEMATH_V0_INTERFACE;
  const chromosome_to_match: Array<string> = interfac.map((m) => m.hex);
  const subject_genome: Array<string> = getters.map((m) => m.hex);
  //
  return _overlaps(subject_genome, chromosome_to_match);
};

//
// test for the 4 safemath pure functions
//
export const genomeSupportsUniswap = (
  getters: Array<{ hex: string }>
): boolean => {
  const interfac = EXCLUDABLES.UNISWAP_V2_INTERFACE;
  const chromosome_to_match: Array<string> = interfac.map((m) => m.hex);
  const subject_genome: Array<string> = getters.map((m) => m.hex);
  //
  return _overlaps(subject_genome, chromosome_to_match);
};

//
//
//
export const understandGenome = (
  getters: Array<{
    type: string;
    hex: string;
    name: string;
  }>,
  exclude_by_item: boolean = false
) => {
  //border: "solid 1px #aaaaaa",
  const simplified_genome = _exclude_all_known(getters, exclude_by_item);
  const simplified_groups: Record<string, number> = _get_exclude_groups(
    getters,
    exclude_by_item
  );
  //
  const is_understood = simplified_genome.length === 0;
  //
  return {
    understood: is_understood,
    groups: simplified_groups,
    simplified: simplified_genome,
  };
};
